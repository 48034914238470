/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyPaymentMethodItem
 */
export interface CompanyPaymentMethodItem {
    /**
     * 
     * @type {string}
     * @memberof CompanyPaymentMethodItem
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPaymentMethodItem
     */
    cardholderName: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPaymentMethodItem
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPaymentMethodItem
     */
    lastFourDigits: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyPaymentMethodItem
     */
    companyDefault: boolean;
}

/**
 * Check if a given object implements the CompanyPaymentMethodItem interface.
 */
export function instanceOfCompanyPaymentMethodItem(value: object): boolean {
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('cardholderName' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('lastFourDigits' in value)) return false;
    if (!('companyDefault' in value)) return false;
    return true;
}

export function CompanyPaymentMethodItemFromJSON(json: any): CompanyPaymentMethodItem {
    return CompanyPaymentMethodItemFromJSONTyped(json, false);
}

export function CompanyPaymentMethodItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPaymentMethodItem {
    if (json == null) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'cardholderName': json['cardholderName'],
        'brand': json['brand'],
        'lastFourDigits': json['lastFourDigits'],
        'companyDefault': json['companyDefault'],
    };
}

export function CompanyPaymentMethodItemToJSON(value?: CompanyPaymentMethodItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'cardholderName': value['cardholderName'],
        'brand': value['brand'],
        'lastFourDigits': value['lastFourDigits'],
        'companyDefault': value['companyDefault'],
    };
}

