/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectQuotePlanAllowedToSwitch
 */
export interface SelectQuotePlanAllowedToSwitch {
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlanAllowedToSwitch
     */
    allowedToSwitch: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectQuotePlanAllowedToSwitch
     */
    allowedToSwitchReason: string;
}

/**
 * Check if a given object implements the SelectQuotePlanAllowedToSwitch interface.
 */
export function instanceOfSelectQuotePlanAllowedToSwitch(value: object): boolean {
    if (!('allowedToSwitch' in value)) return false;
    if (!('allowedToSwitchReason' in value)) return false;
    return true;
}

export function SelectQuotePlanAllowedToSwitchFromJSON(json: any): SelectQuotePlanAllowedToSwitch {
    return SelectQuotePlanAllowedToSwitchFromJSONTyped(json, false);
}

export function SelectQuotePlanAllowedToSwitchFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectQuotePlanAllowedToSwitch {
    if (json == null) {
        return json;
    }
    return {
        
        'allowedToSwitch': json['allowedToSwitch'],
        'allowedToSwitchReason': json['allowedToSwitchReason'],
    };
}

export function SelectQuotePlanAllowedToSwitchToJSON(value?: SelectQuotePlanAllowedToSwitch | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowedToSwitch': value['allowedToSwitch'],
        'allowedToSwitchReason': value['allowedToSwitchReason'],
    };
}

