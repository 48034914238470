import { ShipmentState } from "../generated-openapi-client";

export function emojiForShipmentState(state: ShipmentState): string {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return "🟡";
    case ShipmentState.BookingFailed:
      return "🛑";
    // If we are stuck in Booking Requested State something bad happened
    case ShipmentState.BookingRequested:
      return "🛑";
    case ShipmentState.Cancelled:
      return "⚫️";
    case ShipmentState.Lost:
      return "⚫️";
    case ShipmentState.Delivered:
      return "🟢";
    case ShipmentState.InTransit:
      return "🔵";
    // If we are stuck in Quote Requested State something bad happened
    case ShipmentState.QuoteRequested:
      return "🛑";
    case ShipmentState.Quoted:
      return "⚪️";
    case ShipmentState.OnHold:
      return "🟣️";
  }
}
