/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ApolloMapLocationFilterGroups = {
    Countries: 'Countries',
    TimeZones: 'TimeZones',
    CanadianRegions: 'CanadianRegions',
    UsaRegions: 'USARegions',
    CanadianProvinces: 'CanadianProvinces',
    UsaStates: 'USAStates'
} as const;
export type ApolloMapLocationFilterGroups = typeof ApolloMapLocationFilterGroups[keyof typeof ApolloMapLocationFilterGroups];


export function instanceOfApolloMapLocationFilterGroups(value: any): boolean {
    return Object.values(ApolloMapLocationFilterGroups).includes(value);
}

export function ApolloMapLocationFilterGroupsFromJSON(json: any): ApolloMapLocationFilterGroups {
    return ApolloMapLocationFilterGroupsFromJSONTyped(json, false);
}

export function ApolloMapLocationFilterGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapLocationFilterGroups {
    return json as ApolloMapLocationFilterGroups;
}

export function ApolloMapLocationFilterGroupsToJSON(value?: ApolloMapLocationFilterGroups | null): any {
    return value as any;
}

