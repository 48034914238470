/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceAuditProblemType = {
    PickupAddressChange: 'pickup_address_change',
    PickupLocationTypeChange: 'pickup_location_type_change',
    LiftgateAtPickup: 'liftgate_at_pickup',
    InsidePickup: 'inside_pickup',
    PickupAppointment: 'pickup_appointment',
    ResidentialPickup: 'residential_pickup',
    TradeshowPickup: 'tradeshow_pickup',
    DeliveryAddressChange: 'delivery_address_change',
    DeliveryLocationTypeChange: 'delivery_location_type_change',
    LiftgateAtDelivery: 'liftgate_at_delivery',
    InsideDelivery: 'inside_delivery',
    DeliveryAppointment: 'delivery_appointment',
    ResidentialDelivery: 'residential_delivery',
    TradeshowDelivery: 'tradeshow_delivery',
    WeightChange: 'weight_change',
    DimensionChange: 'dimension_change',
    NumberHandlingUnitsChange: 'number_handling_units_change',
    ConsideredNonStackable: 'considered_non_stackable',
    Dangerous: 'dangerous',
    FreightClassChange: 'freight_class_change',
    CubingChange: 'cubing_change',
    LinearFeetChange: 'linear_feet_change',
    CubeToHeightRule: 'cube_to_height_rule',
    ProtectFromFreezing: 'protect_from_freezing',
    TemperatureControlled: 'temperature_controlled',
    Tarp: 'tarp',
    PickupStraightTruck: 'pickup_straight_truck',
    PickupFlatbed: 'pickup_flatbed',
    PickupOtherEquipment: 'pickup_other_equipment',
    MissedPickup: 'missed_pickup',
    DetentionPickup: 'detention_pickup',
    DetentionWithoutPowerPickup: 'detention_without_power_pickup',
    AfterHoursPickup: 'after_hours_pickup',
    LimitedAccessPickup: 'limited_access_pickup',
    RuralRoutePickup: 'rural_route_pickup',
    PalletJackAtPickup: 'pallet_jack_at_pickup',
    DeliveryStraightTruck: 'delivery_straight_truck',
    DeliveryFlatbed: 'delivery_flatbed',
    DeliveryOtherEquipment: 'delivery_other_equipment',
    Redelivery: 'redelivery',
    DetentionDelivery: 'detention_delivery',
    DetentionWithoutPowerDelivery: 'detention_without_power_delivery',
    AfterHoursDelivery: 'after_hours_delivery',
    LimitedAccessDelivery: 'limited_access_delivery',
    DeliveryNotificationFee: 'delivery_notification_fee',
    RuralRouteDelivery: 'rural_route_delivery',
    PalletJackAtDelivery: 'pallet_jack_at_delivery',
    Storage: 'storage',
    CustomsCharge: 'customs_charge',
    Reconsignment: 'reconsignment',
    AdditionalCarrierConverageRequested: 'additional_carrier_converage_requested',
    BillOfLadingChange: 'bill_of_lading_change',
    FreightsimpleEnteredIncorrectCurrency: 'freightsimple_entered_incorrect_currency',
    FreightsimpleEnteredIncorrectAmount: 'freightsimple_entered_incorrect_amount',
    FreightsimpleTaxIncorrectlyApplied: 'freightsimple_tax_incorrectly_applied',
    SkidSpotsIncorrect: 'skid_spots_incorrect',
    FuelSurchargeIncorrect: 'fuel_surcharge_incorrect',
    IncorrectRateUsed: 'incorrect_rate_used',
    QuoteNotApplied: 'quote_not_applied',
    CodFee: 'cod_fee',
    LumperFee: 'lumper_fee',
    SwamperFee: 'swamper_fee',
    DocumentFee: 'document_fee',
    CrossDockFee: 'cross_dock_fee',
    DropTrailerFee: 'drop_trailer_fee',
    ExclusiveUseFee: 'exclusive_use_fee',
    ExtraLaborFee: 'extra_labor_fee',
    OverlengthFee: 'overlength_fee',
    SortationAndSegragationFee: 'sortation_and_segragation_fee',
    PackagingFee: 'packaging_fee',
    WeekendPickupFee: 'weekend_pickup_fee',
    WeekendDeliveryFee: 'weekend_delivery_fee',
    HolidayPickupFee: 'holiday_pickup_fee',
    HolidayDeliveryFee: 'holiday_delivery_fee',
    CrossBorderFee: 'cross_border_fee',
    PermitFee: 'permit_fee',
    Other: 'other'
} as const;
export type InvoiceAuditProblemType = typeof InvoiceAuditProblemType[keyof typeof InvoiceAuditProblemType];


export function instanceOfInvoiceAuditProblemType(value: any): boolean {
    return Object.values(InvoiceAuditProblemType).includes(value);
}

export function InvoiceAuditProblemTypeFromJSON(json: any): InvoiceAuditProblemType {
    return InvoiceAuditProblemTypeFromJSONTyped(json, false);
}

export function InvoiceAuditProblemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditProblemType {
    return json as InvoiceAuditProblemType;
}

export function InvoiceAuditProblemTypeToJSON(value?: InvoiceAuditProblemType | null): any {
    return value as any;
}

