/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailTemplateGroup = {
    Basic: 'basic',
    Leads: 'leads',
    GettingStarted: 'getting_started',
    Accounts: 'accounts',
    Quoting: 'quoting',
    Booking: 'booking',
    Tracking: 'tracking',
    GeneralInfo: 'general_info',
    Reengage: 'reengage',
    Finance: 'finance'
} as const;
export type EmailTemplateGroup = typeof EmailTemplateGroup[keyof typeof EmailTemplateGroup];


export function instanceOfEmailTemplateGroup(value: any): boolean {
    return Object.values(EmailTemplateGroup).includes(value);
}

export function EmailTemplateGroupFromJSON(json: any): EmailTemplateGroup {
    return EmailTemplateGroupFromJSONTyped(json, false);
}

export function EmailTemplateGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplateGroup {
    return json as EmailTemplateGroup;
}

export function EmailTemplateGroupToJSON(value?: EmailTemplateGroup | null): any {
    return value as any;
}

