/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NoteType = {
    DashboardAction: 'dashboard_action',
    ApolloAction: 'apollo_action',
    Email: 'email',
    MondayComment: 'monday_comment',
    Intercom: 'intercom',
    SalesforceCall: 'salesforce_call',
    ShipmentModified: 'shipment_modified',
    ReferenceNumberChanged: 'reference_number_changed',
    ShipmentCancelled: 'shipment_cancelled',
    Dialpad: 'dialpad',
    SystemMessage: 'system_message',
    SystemErrorUnused: 'system_error_unused',
    InternalNote: 'internal_note'
} as const;
export type NoteType = typeof NoteType[keyof typeof NoteType];


export function instanceOfNoteType(value: any): boolean {
    return Object.values(NoteType).includes(value);
}

export function NoteTypeFromJSON(json: any): NoteType {
    return NoteTypeFromJSONTyped(json, false);
}

export function NoteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteType {
    return json as NoteType;
}

export function NoteTypeToJSON(value?: NoteType | null): any {
    return value as any;
}

