/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyStats
 */
export interface CompanyStats {
    /**
     * 
     * @type {string}
     * @memberof CompanyStats
     */
    firstQuotedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyStats
     */
    firstBookedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyStats
     */
    lastQuotedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyStats
     */
    lastBookedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    quotesRan: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    shipmentsBooked: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    totalCadSales: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    acceptanceRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    totalIssuedInvoicesCad: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    totalOverdueIssuedInvoicesCad: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyStats
     */
    totalSettlementPendingInvoicesCad: number;
}

/**
 * Check if a given object implements the CompanyStats interface.
 */
export function instanceOfCompanyStats(value: object): boolean {
    if (!('quotesRan' in value)) return false;
    if (!('shipmentsBooked' in value)) return false;
    if (!('totalCadSales' in value)) return false;
    if (!('totalIssuedInvoicesCad' in value)) return false;
    if (!('totalOverdueIssuedInvoicesCad' in value)) return false;
    if (!('totalSettlementPendingInvoicesCad' in value)) return false;
    return true;
}

export function CompanyStatsFromJSON(json: any): CompanyStats {
    return CompanyStatsFromJSONTyped(json, false);
}

export function CompanyStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyStats {
    if (json == null) {
        return json;
    }
    return {
        
        'firstQuotedDate': json['firstQuotedDate'] == null ? undefined : json['firstQuotedDate'],
        'firstBookedDate': json['firstBookedDate'] == null ? undefined : json['firstBookedDate'],
        'lastQuotedDate': json['lastQuotedDate'] == null ? undefined : json['lastQuotedDate'],
        'lastBookedDate': json['lastBookedDate'] == null ? undefined : json['lastBookedDate'],
        'quotesRan': json['quotesRan'],
        'shipmentsBooked': json['shipmentsBooked'],
        'totalCadSales': json['totalCadSales'],
        'acceptanceRate': json['acceptanceRate'] == null ? undefined : json['acceptanceRate'],
        'totalIssuedInvoicesCad': json['totalIssuedInvoicesCad'],
        'totalOverdueIssuedInvoicesCad': json['totalOverdueIssuedInvoicesCad'],
        'totalSettlementPendingInvoicesCad': json['totalSettlementPendingInvoicesCad'],
    };
}

export function CompanyStatsToJSON(value?: CompanyStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstQuotedDate': value['firstQuotedDate'],
        'firstBookedDate': value['firstBookedDate'],
        'lastQuotedDate': value['lastQuotedDate'],
        'lastBookedDate': value['lastBookedDate'],
        'quotesRan': value['quotesRan'],
        'shipmentsBooked': value['shipmentsBooked'],
        'totalCadSales': value['totalCadSales'],
        'acceptanceRate': value['acceptanceRate'],
        'totalIssuedInvoicesCad': value['totalIssuedInvoicesCad'],
        'totalOverdueIssuedInvoicesCad': value['totalOverdueIssuedInvoicesCad'],
        'totalSettlementPendingInvoicesCad': value['totalSettlementPendingInvoicesCad'],
    };
}

