import { useState } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components/macro";
import { useStatsApi } from "../Apis/Apis";
import { ApolloMenuBarData } from "../generated-openapi-client/models/ApolloMenuBarData";
import useInterval from "../Hooks/useInterval";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import Colors from "./Colors";
import HorizontalStack from "./HorizontalStack";
import Stack from "./Stack";

interface SimpleStatProps {
  title: string;
  value: number | string;
  prefix?: string;
}

function SimpleStat(props: SimpleStatProps) {
  return (
    <HorizontalStack
      verticalAlign="middle"
      style={{ fontSize: "11px", color: Colors.VeryLightText }}
    >
      <div>{props.title}: </div>
      <Spacer width={2} />
      <NumberFormat
        value={props.value}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
        fixedDecimalScale={true}
        prefix={props.prefix}
      />
    </HorizontalStack>
  );
}

const StatsSectionTitle = styled.div`
  width: 100px;
  color: white;
  font-weight: 500;
  text-align: right;
  font-size: 11px;
`;

export function SimpleStats() {
  const createStatsApi = useStatsApi();
  const [stats, setStats] = useState<ApolloMenuBarData | undefined>();

  async function refresh() {
    console.log(`refreshing menubar`);
    const statsApi = await createStatsApi();
    const response = await statsApi.getMenubarStats();
    setStats(response);
  }

  useInterval(refresh, 60 * 1000);
  useOnce(refresh);

  if (stats === undefined) {
    return <></>;
  }

  return (
    <Stack align="left" style={{ marginRight: "8px" }}>
      <HorizontalStack spacing={8} style={{ height: "22px" }}>
        <StatsSectionTitle>Today</StatsSectionTitle>
        <SimpleStat title="Booked" value={stats.numberShipmentsBookedToday} />
        <SimpleStat title="Rev" value={stats.revenueToday} prefix="$" />
        <SimpleStat
          title="Sig Calls"
          value={stats.numberSignificantCallsToday}
        />
        {/* <HorizontalStack verticalAlign="middle">
        
        
        
      </HorizontalStack> */}
      </HorizontalStack>
      <HorizontalStack spacing={8} style={{ height: "22px" }}>
        <StatsSectionTitle>Support</StatsSectionTitle>
        <SimpleStat
          title="Intercom"
          value={stats.numberOpenIntercomConversations}
        />
        <SimpleStat title="Email" value={stats.supportInboxCount} />
      </HorizontalStack>
    </Stack>
  );
}
