import { Button, message, Tooltip } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { CarrierInvoice } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

interface MarkAllProblemsInInvoiceIdentifiedButtonProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function MarkAllProblemsInInvoiceIdentifiedButton(
  props: MarkAllProblemsInInvoiceIdentifiedButtonProps
) {
  const [loading, setLoading] = useState(false);
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function onClick() {
    setLoading(true);
    try {
      const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markCarrierInvoiceProblemsIdentified({
        carrierInvoiceId,
        problemsIdentified: true,
      });
      message.success("Marked All Problems Identified in Invoice");
      await props.onRefresh();
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
    setLoading(false);
  }

  if (props.carrierInvoice.problemsIdentified) {
    return <></>;
  }

  function generateDisabledMessage(): string | undefined {
    if (
      props.shipmentData.shipment.shipment.invoiceAuditProblems?.length === 0
    ) {
      return "You should identify some problems";
    }
  }

  const disabledMessage = generateDisabledMessage();

  return (
    <Tooltip title={disabledMessage}>
      {" "}
      <Button
        disabled={disabledMessage !== undefined}
        onClick={onClick}
        loading={loading}
      >
        ✅ Mark All Problems in this Invoice Identified
      </Button>
    </Tooltip>
  );
}

export function MarkProblemsNotIdentifiedMenuItem(
  props: MarkAllProblemsInInvoiceIdentifiedButtonProps
) {
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function onClick() {
    try {
      const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markCarrierInvoiceProblemsIdentified({
        carrierInvoiceId,
        problemsIdentified: false,
      });
      message.success("Marked Problems Not Identified");
      await props.onRefresh();
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
  }

  if (!props.carrierInvoice.problemsIdentified) {
    return <></>;
  }

  return <MenuItem onClick={onClick}>Mark Problems Not Identified</MenuItem>;
}
