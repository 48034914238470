/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyPaymentMethodItem } from './CompanyPaymentMethodItem';
import {
    CompanyPaymentMethodItemFromJSON,
    CompanyPaymentMethodItemFromJSONTyped,
    CompanyPaymentMethodItemToJSON,
} from './CompanyPaymentMethodItem';

/**
 * 
 * @export
 * @interface CompanyPaymentMethodInformation
 */
export interface CompanyPaymentMethodInformation {
    /**
     * 
     * @type {Array<CompanyPaymentMethodItem>}
     * @memberof CompanyPaymentMethodInformation
     */
    paymentMethods: Array<CompanyPaymentMethodItem>;
}

/**
 * Check if a given object implements the CompanyPaymentMethodInformation interface.
 */
export function instanceOfCompanyPaymentMethodInformation(value: object): boolean {
    if (!('paymentMethods' in value)) return false;
    return true;
}

export function CompanyPaymentMethodInformationFromJSON(json: any): CompanyPaymentMethodInformation {
    return CompanyPaymentMethodInformationFromJSONTyped(json, false);
}

export function CompanyPaymentMethodInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPaymentMethodInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentMethods': ((json['paymentMethods'] as Array<any>).map(CompanyPaymentMethodItemFromJSON)),
    };
}

export function CompanyPaymentMethodInformationToJSON(value?: CompanyPaymentMethodInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentMethods': ((value['paymentMethods'] as Array<any>).map(CompanyPaymentMethodItemToJSON)),
    };
}

