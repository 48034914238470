import { Button, Menu } from "antd";
import { ButtonType } from "antd/lib/button";
import { Link } from "react-router-dom";

interface ViewShipmentButtonProps {
  shipmentId: string;
  openInNewTab?: boolean;
  type?: ButtonType;
  label?: string;
}

export function ViewShipmentButton(props: ViewShipmentButtonProps) {
  const url = `/view-shipment?shipmentId=${props.shipmentId}`;

  function onClick() {
    window.open(url);
  }

  if (props.openInNewTab) {
    return (
      <Button onClick={onClick} type="primary">
        {props.label || `🚚 View Shipment`}
      </Button>
    );
  } else {
    return (
      <Link to={url}>
        <Button type={props.type || "primary"}>
          {props.label || `🚚 View Shipment`}
        </Button>
      </Link>
    );
  }
}

export function ViewShipmentMenuItem(props: ViewShipmentButtonProps) {
  const url = `/view-shipment?shipmentId=${props.shipmentId}`;

  return (
    <Menu.Item key={url}>
      <a target="_blank" href={url} rel="noreferrer">
        🚚 View Shipment
      </a>
    </Menu.Item>
  );
}
