/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApolloMenuBarData
 */
export interface ApolloMenuBarData {
    /**
     * 
     * @type {number}
     * @memberof ApolloMenuBarData
     */
    supportInboxCount: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMenuBarData
     */
    numberOpenIntercomConversations: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMenuBarData
     */
    numberShipmentsBookedToday: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMenuBarData
     */
    revenueToday: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMenuBarData
     */
    numberSignificantCallsToday: number;
}

/**
 * Check if a given object implements the ApolloMenuBarData interface.
 */
export function instanceOfApolloMenuBarData(value: object): boolean {
    if (!('supportInboxCount' in value)) return false;
    if (!('numberOpenIntercomConversations' in value)) return false;
    if (!('numberShipmentsBookedToday' in value)) return false;
    if (!('revenueToday' in value)) return false;
    if (!('numberSignificantCallsToday' in value)) return false;
    return true;
}

export function ApolloMenuBarDataFromJSON(json: any): ApolloMenuBarData {
    return ApolloMenuBarDataFromJSONTyped(json, false);
}

export function ApolloMenuBarDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMenuBarData {
    if (json == null) {
        return json;
    }
    return {
        
        'supportInboxCount': json['supportInboxCount'],
        'numberOpenIntercomConversations': json['numberOpenIntercomConversations'],
        'numberShipmentsBookedToday': json['numberShipmentsBookedToday'],
        'revenueToday': json['revenueToday'],
        'numberSignificantCallsToday': json['numberSignificantCallsToday'],
    };
}

export function ApolloMenuBarDataToJSON(value?: ApolloMenuBarData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'supportInboxCount': value['supportInboxCount'],
        'numberOpenIntercomConversations': value['numberOpenIntercomConversations'],
        'numberShipmentsBookedToday': value['numberShipmentsBookedToday'],
        'revenueToday': value['revenueToday'],
        'numberSignificantCallsToday': value['numberSignificantCallsToday'],
    };
}

