import {
  Company,
  LogSalesCallInput,
  QualificationStatus,
} from "../../../generated-openapi-client";
import { LogSalesCallFormValues } from "./LogSalesCallForm";

export function buildLogSalesCallInput(
  data: LogSalesCallFormValues,
  company: Company
): LogSalesCallInput {
  const isLead = company.qualificationStatus !== QualificationStatus.Qualified;

  function calculateShouldQualify(): boolean {
    if (!isLead) {
      return false;
    }
    return !(
      data.controlFreight === "close lead" ||
      data.controlFreight === "unknown" ||
      data.controlFreight === undefined
    );
  }

  function recap(): string {
    return data.significant
      ? data.notes
      : [data.whatHappend, data.notes].filter((o) => o).join(" : ");
  }

  function calculateTemperature(): string | undefined {
    if (!isLead) {
      return data.temperature;
    }

    return data.controlFreight === "close lead" ||
      data.controlFreight === "unknown" ||
      data.controlFreight === undefined
      ? ""
      : data.controlFreight;
  }

  function calculateShouldDisqualify() {
    if (!isLead) {
      return false;
    }
    return data.controlFreight === "close lead";
  }

  return {
    salesContactId: data.salesContactId,
    companyId: company.companyId!,
    significant: data.significant,
    recap: recap(),
    temperature: calculateTemperature(),
    shipmentFrequency: data.shipmentFrequency,
    shouldQualify: calculateShouldQualify(),
    shouldDisqualify: calculateShouldDisqualify(),
  };
}
