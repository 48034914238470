/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MondayStatus } from './MondayStatus';
import {
    MondayStatusFromJSON,
    MondayStatusFromJSONTyped,
    MondayStatusToJSON,
} from './MondayStatus';

/**
 * 
 * @export
 * @interface ShipmentMondayStatus
 */
export interface ShipmentMondayStatus {
    /**
     * 
     * @type {string}
     * @memberof ShipmentMondayStatus
     */
    missedPickupMondayId?: string;
    /**
     * 
     * @type {MondayStatus}
     * @memberof ShipmentMondayStatus
     */
    missedPickupMondayStatus?: MondayStatus;
    /**
     * 
     * @type {string}
     * @memberof ShipmentMondayStatus
     */
    missedDeliveryMondayId?: string;
    /**
     * 
     * @type {MondayStatus}
     * @memberof ShipmentMondayStatus
     */
    missedDeliveryMondayStatus?: MondayStatus;
    /**
     * 
     * @type {string}
     * @memberof ShipmentMondayStatus
     */
    deliveryDateMondayId?: string;
    /**
     * 
     * @type {MondayStatus}
     * @memberof ShipmentMondayStatus
     */
    deliveryDateMondayStatus?: MondayStatus;
    /**
     * 
     * @type {string}
     * @memberof ShipmentMondayStatus
     */
    trackingNotUpdatedMondayId?: string;
    /**
     * 
     * @type {MondayStatus}
     * @memberof ShipmentMondayStatus
     */
    trackingNotUpdatedMondayStatus?: MondayStatus;
    /**
     * 
     * @type {string}
     * @memberof ShipmentMondayStatus
     */
    bookPickupMondayId?: string;
    /**
     * 
     * @type {MondayStatus}
     * @memberof ShipmentMondayStatus
     */
    bookPickupMondayStatus?: MondayStatus;
}

/**
 * Check if a given object implements the ShipmentMondayStatus interface.
 */
export function instanceOfShipmentMondayStatus(value: object): boolean {
    return true;
}

export function ShipmentMondayStatusFromJSON(json: any): ShipmentMondayStatus {
    return ShipmentMondayStatusFromJSONTyped(json, false);
}

export function ShipmentMondayStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentMondayStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'missedPickupMondayId': json['missedPickupMondayId'] == null ? undefined : json['missedPickupMondayId'],
        'missedPickupMondayStatus': json['missedPickupMondayStatus'] == null ? undefined : MondayStatusFromJSON(json['missedPickupMondayStatus']),
        'missedDeliveryMondayId': json['missedDeliveryMondayId'] == null ? undefined : json['missedDeliveryMondayId'],
        'missedDeliveryMondayStatus': json['missedDeliveryMondayStatus'] == null ? undefined : MondayStatusFromJSON(json['missedDeliveryMondayStatus']),
        'deliveryDateMondayId': json['deliveryDateMondayId'] == null ? undefined : json['deliveryDateMondayId'],
        'deliveryDateMondayStatus': json['deliveryDateMondayStatus'] == null ? undefined : MondayStatusFromJSON(json['deliveryDateMondayStatus']),
        'trackingNotUpdatedMondayId': json['trackingNotUpdatedMondayId'] == null ? undefined : json['trackingNotUpdatedMondayId'],
        'trackingNotUpdatedMondayStatus': json['trackingNotUpdatedMondayStatus'] == null ? undefined : MondayStatusFromJSON(json['trackingNotUpdatedMondayStatus']),
        'bookPickupMondayId': json['bookPickupMondayId'] == null ? undefined : json['bookPickupMondayId'],
        'bookPickupMondayStatus': json['bookPickupMondayStatus'] == null ? undefined : MondayStatusFromJSON(json['bookPickupMondayStatus']),
    };
}

export function ShipmentMondayStatusToJSON(value?: ShipmentMondayStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'missedPickupMondayId': value['missedPickupMondayId'],
        'missedPickupMondayStatus': MondayStatusToJSON(value['missedPickupMondayStatus']),
        'missedDeliveryMondayId': value['missedDeliveryMondayId'],
        'missedDeliveryMondayStatus': MondayStatusToJSON(value['missedDeliveryMondayStatus']),
        'deliveryDateMondayId': value['deliveryDateMondayId'],
        'deliveryDateMondayStatus': MondayStatusToJSON(value['deliveryDateMondayStatus']),
        'trackingNotUpdatedMondayId': value['trackingNotUpdatedMondayId'],
        'trackingNotUpdatedMondayStatus': MondayStatusToJSON(value['trackingNotUpdatedMondayStatus']),
        'bookPickupMondayId': value['bookPickupMondayId'],
        'bookPickupMondayStatus': MondayStatusToJSON(value['bookPickupMondayStatus']),
    };
}

