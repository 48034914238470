import { Form, Menu, Modal, Tag, message } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { AutochargeFrequencyDropdown } from "../../Components/AutochargeFrequencyDropdown";
import Spacer from "../../Spacer";
import { CustomerInvoice } from "../../generated-openapi-client";

interface SetAutochargeFrequencyMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function SetAutochargeFrequencyMenuItem(
  props: SetAutochargeFrequencyMenuItemProps
) {
  const title = `Set Autocharge Frequency`;
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [autochargeFrequency, setAutochargeFrequency] = useState(
    props.customerInvoice.autochargeFrequency!!
  );
  const [loading, setLoading] = useState(false);

  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function handleOk() {
    setLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.setAutoChargeFrequency({
        customerInvoiceId,
        autochargeFrequency,
      });
      await props.onRefresh();
      message.success("Autocharge Frequency changed");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
    props.onCloseParent();
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        confirmLoading={loading}
        width={800}
      >
        <div>
          Normally we will try to autocharge an invoice daily. Sometimes we want
          to change that frequency. Eg for some things that we have been
          struggling to collect for months
        </div>
        <Spacer height={32} />
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item
            label="Autocharge Frequency"
            style={{ marginBottom: "48px" }}
          >
            <AutochargeFrequencyDropdown
              value={autochargeFrequency}
              setValue={setAutochargeFrequency}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>
        <Tag color="red">Advanced</Tag> {title}
      </Menu.Item>
    </>
  );
}
