/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NoteCategory = {
    Tracking: 'tracking',
    General: 'general',
    Booking: 'booking',
    Quoting: 'quoting',
    Billing: 'billing',
    Claim: 'claim'
} as const;
export type NoteCategory = typeof NoteCategory[keyof typeof NoteCategory];


export function instanceOfNoteCategory(value: any): boolean {
    return Object.values(NoteCategory).includes(value);
}

export function NoteCategoryFromJSON(json: any): NoteCategory {
    return NoteCategoryFromJSONTyped(json, false);
}

export function NoteCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteCategory {
    return json as NoteCategory;
}

export function NoteCategoryToJSON(value?: NoteCategory | null): any {
    return value as any;
}

