/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuoteLog
 */
export interface QuoteLog {
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    quoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    serviceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    quoteGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteLog
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuoteLog
     */
    message?: string;
}

/**
 * Check if a given object implements the QuoteLog interface.
 */
export function instanceOfQuoteLog(value: object): boolean {
    return true;
}

export function QuoteLogFromJSON(json: any): QuoteLog {
    return QuoteLogFromJSONTyped(json, false);
}

export function QuoteLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteLog {
    if (json == null) {
        return json;
    }
    return {
        
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
        'quoteGroup': json['quoteGroup'] == null ? undefined : json['quoteGroup'],
        'success': json['success'] == null ? undefined : json['success'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function QuoteLogToJSON(value?: QuoteLog | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quoteId': value['quoteId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
        'quoteGroup': value['quoteGroup'],
        'success': value['success'],
        'message': value['message'],
    };
}

