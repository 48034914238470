/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailTemplateGroup } from './EmailTemplateGroup';
import {
    EmailTemplateGroupFromJSON,
    EmailTemplateGroupFromJSONTyped,
    EmailTemplateGroupToJSON,
} from './EmailTemplateGroup';

/**
 * 
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    emailTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    body?: string;
    /**
     * 
     * @type {EmailTemplateGroup}
     * @memberof EmailTemplate
     */
    emailTemplateGroup?: EmailTemplateGroup;
}

/**
 * Check if a given object implements the EmailTemplate interface.
 */
export function instanceOfEmailTemplate(value: object): boolean {
    return true;
}

export function EmailTemplateFromJSON(json: any): EmailTemplate {
    return EmailTemplateFromJSONTyped(json, false);
}

export function EmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'emailTemplateId': json['emailTemplateId'] == null ? undefined : json['emailTemplateId'],
        'title': json['title'] == null ? undefined : json['title'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'body': json['body'] == null ? undefined : json['body'],
        'emailTemplateGroup': json['emailTemplateGroup'] == null ? undefined : EmailTemplateGroupFromJSON(json['emailTemplateGroup']),
    };
}

export function EmailTemplateToJSON(value?: EmailTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailTemplateId': value['emailTemplateId'],
        'title': value['title'],
        'subject': value['subject'],
        'body': value['body'],
        'emailTemplateGroup': EmailTemplateGroupToJSON(value['emailTemplateGroup']),
    };
}

