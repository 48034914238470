import { Form, Input, InputNumber, Menu, message, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { CarrierFilter } from "../../Components/CarrierFilter";
import { CurrencyCode } from "../../generated-openapi-client";
import { CurrencySelector } from "../CurrencySelector";
import { TabProps } from "./TabProps";

export function GenerateXeroClaimInvoicesMenuItem(props: TabProps) {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const shipmentId = props.shipmentData.shipmentId;
  const [carrierIdentifier, setCarrierIdentifier] = useState(
    props.shipmentData.shipment.quote.carrierIdentifier
  );
  const [moneyInAmount, setMoneyInAmount] = useState(0);
  const [moneyOutAmount, setMoneyOutAmount] = useState(0);
  const [moneyInCurrencyCode, setMoneyInCurrencyCode] =
    useState<CurrencyCode>();
  const [moneyOutCurrencyCode, setMoneyOutCurrencyCode] =
    useState<CurrencyCode>();
  const [claimInvoiceNumber, setClaimInvoiceNumber] = useState("1");

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (moneyInAmount == 0) {
      message.warn("Missing moneyInAmount");
      return;
    }
    if (moneyOutAmount == 0) {
      message.warn("Missing moneyOutAmount");
      return;
    }

    if (carrierIdentifier === undefined) {
      message.warn("Missing carrier");
      return;
    }

    if (moneyOutCurrencyCode === undefined) {
      message.warn("Missing moneyOutCurrencyCode");
      return;
    }

    if (moneyInCurrencyCode === undefined) {
      message.warn("Missing moneyInCurrencyCode");
      return;
    }

    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.generateXeroClaimInvoices({
        shipmentId,
        moneyInAmount,
        moneyOutAmount,
        moneyOutCurrencyCode,
        moneyInCurrencyCode,
        claimInvoiceNumber,
        carrierIdentifier,
      });
    } catch (e) {
      message.error("Ooops. Something went wrong");
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Generate Xero Claim Invoices"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1000}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "900px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12, offset: 2 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label={`Claim Money In Amount`}>
            <InputNumber
              prefix="$"
              value={moneyInAmount}
              onChange={setMoneyInAmount}
              style={{ width: "150px" }}
            />
          </Form.Item>
          <Form.Item label="Money In Currency">
            <CurrencySelector
              currency={moneyInCurrencyCode}
              setCurrency={setMoneyInCurrencyCode}
            />
          </Form.Item>

          <Form.Item label={`Claim Money Out Amount`}>
            <InputNumber
              prefix="$"
              value={moneyOutAmount}
              onChange={setMoneyOutAmount}
              style={{ width: "150px" }}
            />
          </Form.Item>
          <Form.Item label="Money Out Currency">
            <CurrencySelector
              currency={moneyOutCurrencyCode}
              setCurrency={setMoneyOutCurrencyCode}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                <Tooltip overlay="Only set this if there are already some invoices created and you need a higher number">
                  Claim Invoice Number
                </Tooltip>
              </>
            }
          >
            <Input
              value={claimInvoiceNumber}
              onChange={function (e) {
                setClaimInvoiceNumber(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Carrier">
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              allowClear={false}
              onFilter={function (e) {
                if (e !== undefined) {
                  setCarrierIdentifier(e);
                }
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>Generate Xero Claim Invoices</Menu.Item>
    </>
  );
}
