export function formatPhoneNumberWithExtension(
  phoneNumber: string | undefined,
  extension: string | undefined,
  extensionSeparator = "Ext. "
) {
  if (extension === undefined) {
    return formatPhoneNumber(phoneNumber);
  } else {
    return `${formatPhoneNumber(
      phoneNumber
    )} ${extensionSeparator}${extension}`;
  }
}

export function formatPhoneNumber(phoneNumber: string | undefined): string {
  if (phoneNumber === undefined || phoneNumber === "") {
    return "";
  }

  const rawDigits = phoneNumber.replace(/\D/g, "");

  // We want to format a number like either
  // +1 (778) 563-568
  // (778) 563-5684

  if (rawDigits.startsWith("1")) {
    const countryPart = rawDigits.substring(0, 1);
    const areaPart = rawDigits.substring(1, 4);
    const numberPart1 = rawDigits.substring(4, 7);
    const numberPart2 = rawDigits.substring(7, 11);

    if (areaPart.length === 0) {
      return `+${countryPart}`;
    } else if (numberPart1.length === 0) {
      return `+${countryPart} (${areaPart})`;
    } else if (numberPart2.length === 0) {
      return `+${countryPart} (${areaPart}) ${numberPart1}`;
    } else {
      return `+${countryPart} (${areaPart}) ${numberPart1}-${numberPart2}`;
    }
  } else {
    const areaPart = rawDigits.substring(0, 3);
    const numberPart1 = rawDigits.substring(3, 6);
    const numberPart2 = rawDigits.substring(6, 10);

    if (numberPart1.length === 0) {
      return `(${areaPart})`;
    } else if (numberPart2.length === 0) {
      return `(${areaPart}) ${numberPart1}`;
    } else {
      return `(${areaPart}) ${numberPart1}-${numberPart2}`;
    }
  }
}
