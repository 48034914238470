import { message } from "antd";
import Search from "antd/lib/input/Search";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLookupApi } from "../Apis/Apis";
import { LookupResultType } from "../generated-openapi-client/models/LookupResultType";

export function useLookup() {
  const createLookupApi = useLookupApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function onSearch(query: string) {
    setLoading(true);
    try {
      const lookupApi = await createLookupApi();
      const result = await lookupApi.lookup({ query });
      console.log(`result`, { result });
      if (result.resultType === LookupResultType.Shipment) {
        navigate(`/view-shipment?shipmentId=${result.resultId}`);
      } else if (result.resultType === LookupResultType.Company) {
        navigate(`/view-company?companyId=${result.resultId}`);
      }
    } catch (e: any) {
      message.error(`Nothing found`);
    }
    setLoading(false);
  }

  return { onSearch, loading };
}

export function SearchInput() {
  const { onSearch, loading } = useLookup();
  return (
    <Search
      placeholder="Quick Lookup"
      onSearch={onSearch}
      style={{ width: 200 }}
      loading={loading}
    />
  );
}
