/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarrierRequestAudit
 */
export interface CarrierRequestAudit {
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    auditId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    quoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    auditType?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    subcommand?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    httpStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    requestString?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    responseString?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierRequestAudit
     */
    serviceIdentifier?: string;
}

/**
 * Check if a given object implements the CarrierRequestAudit interface.
 */
export function instanceOfCarrierRequestAudit(value: object): boolean {
    return true;
}

export function CarrierRequestAuditFromJSON(json: any): CarrierRequestAudit {
    return CarrierRequestAuditFromJSONTyped(json, false);
}

export function CarrierRequestAuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierRequestAudit {
    if (json == null) {
        return json;
    }
    return {
        
        'auditId': json['auditId'] == null ? undefined : json['auditId'],
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'auditType': json['auditType'] == null ? undefined : json['auditType'],
        'subcommand': json['subcommand'] == null ? undefined : json['subcommand'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'url': json['url'] == null ? undefined : json['url'],
        'httpStatus': json['httpStatus'] == null ? undefined : json['httpStatus'],
        'requestString': json['requestString'] == null ? undefined : json['requestString'],
        'responseString': json['responseString'] == null ? undefined : json['responseString'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
    };
}

export function CarrierRequestAuditToJSON(value?: CarrierRequestAudit | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'auditId': value['auditId'],
        'quoteId': value['quoteId'],
        'auditType': value['auditType'],
        'subcommand': value['subcommand'],
        'shipmentId': value['shipmentId'],
        'companyId': value['companyId'],
        'createdAt': value['createdAt'],
        'url': value['url'],
        'httpStatus': value['httpStatus'],
        'requestString': value['requestString'],
        'responseString': value['responseString'],
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
    };
}

