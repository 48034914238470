import { Button, Form, message, Modal } from "antd";
import { useState } from "react";
import { useDocumentsApi } from "../Apis/Apis";
import {
  CreateFromExistingDocumentRequest,
  DocumentType,
} from "../generated-openapi-client";
import { DocumentTypeDropdown } from "./DocumentTypeDropdown";
import { CopyOutlined } from "@ant-design/icons";

interface ExtractPageFromPdfButtonProps {
  documentS3Path: string;
  shipmentId: string;
  companyId: string;
  pageNumber: number;
  onRefresh?: () => Promise<void>;
}

export function ExtractPageFromPdfButton(props: ExtractPageFromPdfButtonProps) {
  const createDocumentsApi = useDocumentsApi();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [documentType, setDocumentType] = useState<DocumentType | undefined>();

  async function onExtractPage() {
    if (documentType === undefined) {
      message.warn("No document type selected");
      return false;
    }

    setUploading(true);
    try {
      const documentsApi = await createDocumentsApi();

      const request: CreateFromExistingDocumentRequest = {
        pages: [props.pageNumber],
        shipmentId: props.shipmentId,
        companyId: props.companyId,
        documentS3Path: props.documentS3Path,
        documentType: documentType,
      };

      await documentsApi.createFromExistingDocument(request);

      if (props.onRefresh) {
        await props.onRefresh();
      }

      setIsModalVisible(false);
      message.success("Uploaded");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setUploading(false);
  }

  return (
    <>
      <Modal
        title="Extract Page Into New Document"
        visible={isModalVisible}
        okText="Upload"
        onOk={onExtractPage}
        confirmLoading={uploading}
        onCancel={() => setIsModalVisible(false)}
        width={800}
        destroyOnClose
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="New Document Type">
            <DocumentTypeDropdown
              value={documentType}
              setValue={setDocumentType}
            />
          </Form.Item>
        </Form>
      </Modal>

      {
        <Button
          type="link"
          onClick={() => setIsModalVisible(true)}
          icon={<CopyOutlined />}
        >
          Extract Page
        </Button>
      }
    </>
  );
}
