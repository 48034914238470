import { Form, Menu, message, Modal } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { NonOptionalDocumentTypeDropdown } from "../../Components/DocumentTypeDropdown";
import { ShipmentDocument } from "../../generated-openapi-client/models/ShipmentDocument";

interface ChangeDocumentTypeMenuItemProps {
  document: ShipmentDocument;
  onRefresh: () => Promise<void>;
}

export function ChangeDocumentTypeMenuItem(
  props: ChangeDocumentTypeMenuItemProps
) {
  const title = "Change Document Type";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentType, setDocumentType] = useState(
    props.document.documentType!!
  );
  const [loading, setLoading] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const shipmentDocumentId = props.document.shipmentDocumentId!!;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.changeDocumentType({
        shipmentDocumentId,
        documentType,
      });
      await props.onRefresh();
      message.success("Saved");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        destroyOnClose={true}
        width={800}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Document Type">
            <NonOptionalDocumentTypeDropdown
              value={documentType}
              setValue={function (v) {
                if (v) {
                  setDocumentType(v);
                }
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>{title}</Menu.Item>
    </>
  );
}
