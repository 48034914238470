import { message, Tag } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItemWithTextArea } from "../../Components/ConfirmButtonWithTextArea";
import {
  CustomerInvoice,
  CustomerInvoiceState,
} from "../../generated-openapi-client";

interface VoidPaymentPendingCustomerInvoiceMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function VoidPaymentPendingCustomerInvoiceMenuItem(
  props: VoidPaymentPendingCustomerInvoiceMenuItemProps
) {
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;

  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function handleOk(reason: string) {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.voidSettlementPendingInvoice({
        customerInvoiceId,
        reason,
      });
      await props.onRefresh();
      message.success("Invoice marked settled");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    // This is needed becasue the invoice pdf doesn't reload, so would be stale otherwise
    props.onCloseParent();
  }

  if (
    props.customerInvoice.customerInvoiceState !==
    CustomerInvoiceState.SettlementPending
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItemWithTextArea
      onConfirm={handleOk}
      placeholder="Enter reason"
      question={
        "Are you sure you want to mark the settlement pending invoice as void? We should only do this when something went really wrong. eg a bug"
      }
      okText={"Yes, void it"}
      tooltip={
        "This will then regenerate the customer invoice showing the bill is voided"
      }
    >
      <Tag color="red">Advanced</Tag> Void Settlement Pending Customer Invoice
    </ConfirmMenuItemWithTextArea>
  );
}
