/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FreightClaimCondition = {
    New: 'new_',
    Used: 'used'
} as const;
export type FreightClaimCondition = typeof FreightClaimCondition[keyof typeof FreightClaimCondition];


export function instanceOfFreightClaimCondition(value: any): boolean {
    return Object.values(FreightClaimCondition).includes(value);
}

export function FreightClaimConditionFromJSON(json: any): FreightClaimCondition {
    return FreightClaimConditionFromJSONTyped(json, false);
}

export function FreightClaimConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreightClaimCondition {
    return json as FreightClaimCondition;
}

export function FreightClaimConditionToJSON(value?: FreightClaimCondition | null): any {
    return value as any;
}

