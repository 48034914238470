/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyNoteType } from './CompanyNoteType';
import {
    CompanyNoteTypeFromJSON,
    CompanyNoteTypeFromJSONTyped,
    CompanyNoteTypeToJSON,
} from './CompanyNoteType';

/**
 * 
 * @export
 * @interface CompanyNote
 */
export interface CompanyNote {
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    companyNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    companyId?: string;
    /**
     * 
     * @type {CompanyNoteType}
     * @memberof CompanyNote
     */
    type?: CompanyNoteType;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyNote
     */
    significant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    salesContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    legacySalesforceTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    legacySalesforceContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    legacySalesforceAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    cachedSalesContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    subMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    recordingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    metadata?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyNote
     */
    origin?: string;
}

/**
 * Check if a given object implements the CompanyNote interface.
 */
export function instanceOfCompanyNote(value: object): boolean {
    return true;
}

export function CompanyNoteFromJSON(json: any): CompanyNote {
    return CompanyNoteFromJSONTyped(json, false);
}

export function CompanyNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyNote {
    if (json == null) {
        return json;
    }
    return {
        
        'companyNoteId': json['companyNoteId'] == null ? undefined : json['companyNoteId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'type': json['type'] == null ? undefined : CompanyNoteTypeFromJSON(json['type']),
        'subject': json['subject'] == null ? undefined : json['subject'],
        'body': json['body'] == null ? undefined : json['body'],
        'significant': json['significant'] == null ? undefined : json['significant'],
        'salesContactId': json['salesContactId'] == null ? undefined : json['salesContactId'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'legacySalesforceTaskId': json['legacySalesforceTaskId'] == null ? undefined : json['legacySalesforceTaskId'],
        'legacySalesforceContactId': json['legacySalesforceContactId'] == null ? undefined : json['legacySalesforceContactId'],
        'legacySalesforceAccountId': json['legacySalesforceAccountId'] == null ? undefined : json['legacySalesforceAccountId'],
        'author': json['author'] == null ? undefined : json['author'],
        'cachedSalesContactName': json['cachedSalesContactName'] == null ? undefined : json['cachedSalesContactName'],
        'subMessage': json['subMessage'] == null ? undefined : json['subMessage'],
        'recordingUrl': json['recordingUrl'] == null ? undefined : json['recordingUrl'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'origin': json['origin'] == null ? undefined : json['origin'],
    };
}

export function CompanyNoteToJSON(value?: CompanyNote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyNoteId': value['companyNoteId'],
        'createdAt': value['createdAt'],
        'companyId': value['companyId'],
        'type': CompanyNoteTypeToJSON(value['type']),
        'subject': value['subject'],
        'body': value['body'],
        'significant': value['significant'],
        'salesContactId': value['salesContactId'],
        'shipmentId': value['shipmentId'],
        'legacySalesforceTaskId': value['legacySalesforceTaskId'],
        'legacySalesforceContactId': value['legacySalesforceContactId'],
        'legacySalesforceAccountId': value['legacySalesforceAccountId'],
        'author': value['author'],
        'cachedSalesContactName': value['cachedSalesContactName'],
        'subMessage': value['subMessage'],
        'recordingUrl': value['recordingUrl'],
        'metadata': value['metadata'],
        'origin': value['origin'],
    };
}

