/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShipmentDocumentSource = {
    EmailAttachment: 'email_attachment',
    ManuallyUploaded: 'manually_uploaded',
    Api: 'api',
    WebsiteScraped: 'website_scraped'
} as const;
export type ShipmentDocumentSource = typeof ShipmentDocumentSource[keyof typeof ShipmentDocumentSource];


export function instanceOfShipmentDocumentSource(value: any): boolean {
    return Object.values(ShipmentDocumentSource).includes(value);
}

export function ShipmentDocumentSourceFromJSON(json: any): ShipmentDocumentSource {
    return ShipmentDocumentSourceFromJSONTyped(json, false);
}

export function ShipmentDocumentSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentDocumentSource {
    return json as ShipmentDocumentSource;
}

export function ShipmentDocumentSourceToJSON(value?: ShipmentDocumentSource | null): any {
    return value as any;
}

