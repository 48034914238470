import { Select } from "antd";
import { useState } from "react";
import { useCarriersApi } from "../Apis/Apis";
import { isPhone } from "../Helpers/isPhone";
import { useOnce } from "../Hooks/useOnce";
import { CarrierNameAndIdentifier } from "../generated-openapi-client/models/CarrierNameAndIdentifier";
import CarrierLogo from "./CarrierLogo";
import HorizontalStack from "./HorizontalStack";

const { Option } = Select;

interface CarrierFilterProps {
  onFilter: (carrierIdentifier: string | undefined) => void;
  allowClear?: boolean;
  carrierIdentifier: string | null | undefined;
  disabled?: boolean;
}

export function CarrierFilter(props: CarrierFilterProps) {
  const createCarrierApi = useCarriersApi();
  const [carriers, setCarriers] = useState<
    CarrierNameAndIdentifier[] | undefined
  >();

  useOnce(async function () {
    const carrierApi = await createCarrierApi();
    const response = await carrierApi.listCarriers();
    setCarriers(response);
  });

  if (carriers === undefined) {
    return <></>;
  }

  return (
    <Select
      allowClear={props.allowClear === undefined ? true : props.allowClear}
      showSearch
      disabled={props.disabled}
      style={{ width: isPhone ? 170 : 220 }}
      value={props.carrierIdentifier}
      placeholder="Carrier"
      optionFilterProp="children"
      onSelect={function (carrierIdentifier: string) {
        props.onFilter(carrierIdentifier);
      }}
      onClear={function () {
        props.onFilter(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-ignore
        option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-ignore
        optionA.value
          // @ts-ignore
          .toLowerCase()
          // @ts-ignore
          .localeCompare(optionB.value.toLowerCase())
      }
    >
      {carriers.map(function (c) {
        return (
          <Option key={c.identifier} value={c.identifier}>
            <HorizontalStack verticalAlign="middle" align="center">
              <div style={{ padding: "8px" }}>
                <CarrierLogo
                  carrierIdentifier={c.identifier}
                  brokeredCarrierIdentifier={undefined}
                  width={100}
                  height={20}
                  notClickable
                />
              </div>
            </HorizontalStack>
          </Option>
        );
      })}
    </Select>
  );
}
