/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceAuditProblemFault = {
    Customer: 'customer',
    Carrier: 'carrier',
    Freightsimple: 'freightsimple',
    Unknown: 'unknown'
} as const;
export type InvoiceAuditProblemFault = typeof InvoiceAuditProblemFault[keyof typeof InvoiceAuditProblemFault];


export function instanceOfInvoiceAuditProblemFault(value: any): boolean {
    return Object.values(InvoiceAuditProblemFault).includes(value);
}

export function InvoiceAuditProblemFaultFromJSON(json: any): InvoiceAuditProblemFault {
    return InvoiceAuditProblemFaultFromJSONTyped(json, false);
}

export function InvoiceAuditProblemFaultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditProblemFault {
    return json as InvoiceAuditProblemFault;
}

export function InvoiceAuditProblemFaultToJSON(value?: InvoiceAuditProblemFault | null): any {
    return value as any;
}

