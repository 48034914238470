import { Button, Modal, Typography } from "antd";

import { useState } from "react";
import HorizontalStack from "../../Components/HorizontalStack";
import { Pdf } from "../../Components/Pdf";
import Stack from "../../Components/Stack";
import { capitalizeFirstLetter } from "../../Helpers/capitalizeFirstLetter";
import Spacer from "../../Spacer";
import {
  DocumentType,
  ShipmentDocument,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { LineItemsTable } from "./LineItemsTable";
import { LineItemsSummary } from "./ManagePickupTab";

const { Title } = Typography;

interface ViewDocumentButtonProps {
  document: ShipmentDocument;
  shipmentData: ViewShipmentDataForApollo;
  onRefresh?: () => Promise<void>;
  includeTypeInButtonName?: boolean;
}

export function ViewDocumentButton(props: ViewDocumentButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function buttonName() {
    if (props.includeTypeInButtonName === undefined) {
      return `View Document`;
    } else {
      return `View ${capitalizeFirstLetter(
        props.document.documentType
      )} Document`;
    }
  }

  console.log("ViewDocumentButton", props);

  const filename = `${props.document.documentType}-${props.shipmentData.shipment.shipment.billOfLadingNumber}.pdf`;

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="View Document"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
        destroyOnClose={true}
      >
        {props.document.documentType === DocumentType.CarrierWeightReport && (
          <>
            <div style={{ border: "1px solid #ccc", padding: "16px" }}>
              <Stack align="left">
                <HorizontalStack width="100%" align="spread">
                  <Title level={4}>Line Items</Title>
                  <HorizontalStack>
                    <LineItemsSummary
                      shipment={props.shipmentData.shipment.shipment}
                    />
                    <Spacer width={16} />
                  </HorizontalStack>
                </HorizontalStack>
                <LineItemsTable
                  lineItems={props.shipmentData.shipment.shipment.lineItems!!}
                />
              </Stack>
            </div>
            <Spacer height={32} />
          </>
        )}
        <Pdf
          documentS3Path={`${props.document.shipmentDocumentId}.pdf`}
          filename={filename}
          companyId={props.shipmentData.shipment.company.companyId}
          shipmentId={props.shipmentData.shipmentId}
          onRefresh={props.onRefresh}
        />
      </Modal>
      <Button onClick={showModal}>{buttonName()}</Button>
    </>
  );
}
