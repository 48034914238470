/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServiceLevel = {
    Rail: 'Rail',
    Road: 'Road',
    Standard: 'Standard',
    Expedited: 'Expedited',
    SameDay: 'SameDay',
    Guaranteed: 'Guaranteed',
    FullTruck: 'FullTruck',
    Intermodal: 'Intermodal',
    Intermodal40: 'Intermodal40',
    Flatbed: 'Flatbed',
    Air: 'Air'
} as const;
export type ServiceLevel = typeof ServiceLevel[keyof typeof ServiceLevel];


export function instanceOfServiceLevel(value: any): boolean {
    return Object.values(ServiceLevel).includes(value);
}

export function ServiceLevelFromJSON(json: any): ServiceLevel {
    return ServiceLevelFromJSONTyped(json, false);
}

export function ServiceLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevel {
    return json as ServiceLevel;
}

export function ServiceLevelToJSON(value?: ServiceLevel | null): any {
    return value as any;
}

