import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

interface DateRangePickerProps {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  onChange: (startDate: string, endDate: string) => void;
  onClear: () => void;
}

export function DateRangePicker(props: DateRangePickerProps) {
  return (
    <RangePicker
      defaultValue={[moment(props.startDate), moment(props.endDate)]}
      allowClear={false}
      ranges={{
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 days": [moment().subtract(7, "days"), moment()],
        "Last 30 days": [moment().subtract(30, "days"), moment()],
        "All Time": [moment("2021-01-01"), moment("2031-12-31")],
        "2021": [moment("2021-01-01"), moment("2021-12-31")],
        "2022": [moment("2022-01-01"), moment("2022-12-31")],
        "2023": [moment("2023-01-01"), moment("2023-12-31")],
        "2024": [moment("2024-01-01"), moment("2024-12-31")],
        "2021 - Q1": [moment("2021-01-01"), moment("2021-03-31")],
        "2021 - Q2": [moment("2021-04-01"), moment("2021-06-30")],
        "2021 - Q3": [moment("2021-07-01"), moment("2021-09-30")],
        "2021 - Q4": [moment("2021-10-01"), moment("2021-12-31")],
        "2022 - Q1": [moment("2022-01-01"), moment("2022-03-31")],
        "2022 - Q2": [moment("2022-04-01"), moment("2022-06-30")],
        "2022 - Q3": [moment("2022-07-01"), moment("2022-09-30")],
        "2022 - Q4": [moment("2022-10-01"), moment("2022-12-31")],
        "2023 - Q1": [moment("2023-01-01"), moment("2023-03-31")],
        "2023 - Q2": [moment("2023-04-01"), moment("2023-06-30")],
        "2023 - Q3": [moment("2023-07-01"), moment("2023-09-30")],
        "2023 - Q4": [moment("2023-10-01"), moment("2023-12-31")],
        "2024 - Q1": [moment("2024-01-01"), moment("2024-03-31")],
        "2024 - Q2": [moment("2024-04-01"), moment("2024-06-30")],
        "2024 - Q3": [moment("2024-07-01"), moment("2024-09-30")],
        "2024 - Q4": [moment("2024-10-01"), moment("2024-12-31")],
      }}
      onChange={function (e) {
        if (e === null || e[0] === null || e[1] === null) {
          props.onClear();
        } else {
          const startDate = e[0].format("YYYY-MM-DD");
          const endDate = e[1].format("YYYY-MM-DD");
          props.onChange(startDate, endDate);
        }
      }}
    />
  );
}
