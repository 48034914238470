/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApolloMapLocationFilterType } from './ApolloMapLocationFilterType';
import {
    ApolloMapLocationFilterTypeFromJSON,
    ApolloMapLocationFilterTypeFromJSONTyped,
    ApolloMapLocationFilterTypeToJSON,
} from './ApolloMapLocationFilterType';

/**
 * 
 * @export
 * @interface ApolloMapLocationFilter
 */
export interface ApolloMapLocationFilter {
    /**
     * 
     * @type {string}
     * @memberof ApolloMapLocationFilter
     */
    description: string;
    /**
     * 
     * @type {ApolloMapLocationFilterType}
     * @memberof ApolloMapLocationFilter
     */
    type: ApolloMapLocationFilterType;
}

/**
 * Check if a given object implements the ApolloMapLocationFilter interface.
 */
export function instanceOfApolloMapLocationFilter(value: object): boolean {
    if (!('description' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function ApolloMapLocationFilterFromJSON(json: any): ApolloMapLocationFilter {
    return ApolloMapLocationFilterFromJSONTyped(json, false);
}

export function ApolloMapLocationFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapLocationFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'type': ApolloMapLocationFilterTypeFromJSON(json['type']),
    };
}

export function ApolloMapLocationFilterToJSON(value?: ApolloMapLocationFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'type': ApolloMapLocationFilterTypeToJSON(value['type']),
    };
}

