/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierCreditNoteState } from './CarrierCreditNoteState';
import {
    CarrierCreditNoteStateFromJSON,
    CarrierCreditNoteStateFromJSONTyped,
    CarrierCreditNoteStateToJSON,
} from './CarrierCreditNoteState';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 * 
 * @export
 * @interface CarrierCreditNote
 */
export interface CarrierCreditNote {
    /**
     * 
     * @type {string}
     * @memberof CarrierCreditNote
     */
    carrierCreditNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierCreditNote
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierCreditNote
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierCreditNote
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {CarrierCreditNoteState}
     * @memberof CarrierCreditNote
     */
    carrierCreditNoteState?: CarrierCreditNoteState;
    /**
     * 
     * @type {number}
     * @memberof CarrierCreditNote
     */
    amount?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof CarrierCreditNote
     */
    currency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof CarrierCreditNote
     */
    xeroCreditNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierCreditNote
     */
    relatedCarrierInvoiceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierCreditNote
     */
    includesTax?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarrierCreditNote
     */
    amountWithoutTax?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierCreditNote
     */
    refundRequested?: boolean;
}

/**
 * Check if a given object implements the CarrierCreditNote interface.
 */
export function instanceOfCarrierCreditNote(value: object): boolean {
    return true;
}

export function CarrierCreditNoteFromJSON(json: any): CarrierCreditNote {
    return CarrierCreditNoteFromJSONTyped(json, false);
}

export function CarrierCreditNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierCreditNote {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierCreditNoteId': json['carrierCreditNoteId'] == null ? undefined : json['carrierCreditNoteId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'carrierCreditNoteState': json['carrierCreditNoteState'] == null ? undefined : CarrierCreditNoteStateFromJSON(json['carrierCreditNoteState']),
        'amount': json['amount'] == null ? undefined : json['amount'],
        'currency': json['currency'] == null ? undefined : CurrencyCodeFromJSON(json['currency']),
        'xeroCreditNoteId': json['xeroCreditNoteId'] == null ? undefined : json['xeroCreditNoteId'],
        'relatedCarrierInvoiceId': json['relatedCarrierInvoiceId'] == null ? undefined : json['relatedCarrierInvoiceId'],
        'includesTax': json['includesTax'] == null ? undefined : json['includesTax'],
        'amountWithoutTax': json['amountWithoutTax'] == null ? undefined : json['amountWithoutTax'],
        'refundRequested': json['refundRequested'] == null ? undefined : json['refundRequested'],
    };
}

export function CarrierCreditNoteToJSON(value?: CarrierCreditNote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierCreditNoteId': value['carrierCreditNoteId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'carrierCreditNoteState': CarrierCreditNoteStateToJSON(value['carrierCreditNoteState']),
        'amount': value['amount'],
        'currency': CurrencyCodeToJSON(value['currency']),
        'xeroCreditNoteId': value['xeroCreditNoteId'],
        'relatedCarrierInvoiceId': value['relatedCarrierInvoiceId'],
        'includesTax': value['includesTax'],
        'amountWithoutTax': value['amountWithoutTax'],
        'refundRequested': value['refundRequested'],
    };
}

