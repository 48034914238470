/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FailedPaymentSupportEmailType = {
    GenericCreditCard: 'GenericCreditCard',
    GenericBankAccount: 'GenericBankAccount',
    InsufficientFundsCreditCard: 'InsufficientFundsCreditCard'
} as const;
export type FailedPaymentSupportEmailType = typeof FailedPaymentSupportEmailType[keyof typeof FailedPaymentSupportEmailType];


export function instanceOfFailedPaymentSupportEmailType(value: any): boolean {
    return Object.values(FailedPaymentSupportEmailType).includes(value);
}

export function FailedPaymentSupportEmailTypeFromJSON(json: any): FailedPaymentSupportEmailType {
    return FailedPaymentSupportEmailTypeFromJSONTyped(json, false);
}

export function FailedPaymentSupportEmailTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailedPaymentSupportEmailType {
    return json as FailedPaymentSupportEmailType;
}

export function FailedPaymentSupportEmailTypeToJSON(value?: FailedPaymentSupportEmailType | null): any {
    return value as any;
}

