/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MissingInvoiceComparisonDay } from './MissingInvoiceComparisonDay';
import {
    MissingInvoiceComparisonDayFromJSON,
    MissingInvoiceComparisonDayFromJSONTyped,
    MissingInvoiceComparisonDayToJSON,
} from './MissingInvoiceComparisonDay';

/**
 * 
 * @export
 * @interface MissingInvoiceStrategy
 */
export interface MissingInvoiceStrategy {
    /**
     * 
     * @type {MissingInvoiceComparisonDay}
     * @memberof MissingInvoiceStrategy
     */
    comparisonDay: MissingInvoiceComparisonDay;
    /**
     * 
     * @type {number}
     * @memberof MissingInvoiceStrategy
     */
    numberOfDaysToConsiderOverdue: number;
    /**
     * 
     * @type {boolean}
     * @memberof MissingInvoiceStrategy
     */
    shouldEmailCarrierToRequestInvoice: boolean;
}

/**
 * Check if a given object implements the MissingInvoiceStrategy interface.
 */
export function instanceOfMissingInvoiceStrategy(value: object): boolean {
    if (!('comparisonDay' in value)) return false;
    if (!('numberOfDaysToConsiderOverdue' in value)) return false;
    if (!('shouldEmailCarrierToRequestInvoice' in value)) return false;
    return true;
}

export function MissingInvoiceStrategyFromJSON(json: any): MissingInvoiceStrategy {
    return MissingInvoiceStrategyFromJSONTyped(json, false);
}

export function MissingInvoiceStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingInvoiceStrategy {
    if (json == null) {
        return json;
    }
    return {
        
        'comparisonDay': MissingInvoiceComparisonDayFromJSON(json['comparisonDay']),
        'numberOfDaysToConsiderOverdue': json['numberOfDaysToConsiderOverdue'],
        'shouldEmailCarrierToRequestInvoice': json['shouldEmailCarrierToRequestInvoice'],
    };
}

export function MissingInvoiceStrategyToJSON(value?: MissingInvoiceStrategy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comparisonDay': MissingInvoiceComparisonDayToJSON(value['comparisonDay']),
        'numberOfDaysToConsiderOverdue': value['numberOfDaysToConsiderOverdue'],
        'shouldEmailCarrierToRequestInvoice': value['shouldEmailCarrierToRequestInvoice'],
    };
}

