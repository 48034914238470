/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { FreightClaimLineItem } from './FreightClaimLineItem';
import {
    FreightClaimLineItemFromJSON,
    FreightClaimLineItemFromJSONTyped,
    FreightClaimLineItemToJSON,
} from './FreightClaimLineItem';
import type { FreightClaimState } from './FreightClaimState';
import {
    FreightClaimStateFromJSON,
    FreightClaimStateFromJSONTyped,
    FreightClaimStateToJSON,
} from './FreightClaimState';

/**
 * 
 * @export
 * @interface FreightClaim
 */
export interface FreightClaim {
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    freightClaimId?: string;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    companyId?: string;
    /**
     * 
     * @type {FreightClaimState}
     * @memberof FreightClaim
     */
    freightClaimState?: FreightClaimState;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    proNumber?: string;
    /**
     * 
     * @type {Array<FreightClaimLineItem>}
     * @memberof FreightClaim
     */
    freightClaimLineItems?: Array<FreightClaimLineItem>;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    claimDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof FreightClaim
     */
    claimInternalNotes?: string;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof FreightClaim
     */
    claimCurrency?: CurrencyCode;
    /**
     * 
     * @type {Array<string>}
     * @memberof FreightClaim
     */
    originalInvoiceFiles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FreightClaim
     */
    photoFiles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FreightClaim
     */
    otherDocumentFiles?: Array<string>;
}

/**
 * Check if a given object implements the FreightClaim interface.
 */
export function instanceOfFreightClaim(value: object): boolean {
    return true;
}

export function FreightClaimFromJSON(json: any): FreightClaim {
    return FreightClaimFromJSONTyped(json, false);
}

export function FreightClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreightClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'freightClaimId': json['freightClaimId'] == null ? undefined : json['freightClaimId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'freightClaimState': json['freightClaimState'] == null ? undefined : FreightClaimStateFromJSON(json['freightClaimState']),
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'freightClaimLineItems': json['freightClaimLineItems'] == null ? undefined : ((json['freightClaimLineItems'] as Array<any>).map(FreightClaimLineItemFromJSON)),
        'claimDescription': json['claimDescription'] == null ? undefined : json['claimDescription'],
        'claimInternalNotes': json['claimInternalNotes'] == null ? undefined : json['claimInternalNotes'],
        'claimCurrency': json['claimCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['claimCurrency']),
        'originalInvoiceFiles': json['originalInvoiceFiles'] == null ? undefined : json['originalInvoiceFiles'],
        'photoFiles': json['photoFiles'] == null ? undefined : json['photoFiles'],
        'otherDocumentFiles': json['otherDocumentFiles'] == null ? undefined : json['otherDocumentFiles'],
    };
}

export function FreightClaimToJSON(value?: FreightClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'freightClaimId': value['freightClaimId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'companyId': value['companyId'],
        'freightClaimState': FreightClaimStateToJSON(value['freightClaimState']),
        'carrierIdentifier': value['carrierIdentifier'],
        'proNumber': value['proNumber'],
        'freightClaimLineItems': value['freightClaimLineItems'] == null ? undefined : ((value['freightClaimLineItems'] as Array<any>).map(FreightClaimLineItemToJSON)),
        'claimDescription': value['claimDescription'],
        'claimInternalNotes': value['claimInternalNotes'],
        'claimCurrency': CurrencyCodeToJSON(value['claimCurrency']),
        'originalInvoiceFiles': value['originalInvoiceFiles'],
        'photoFiles': value['photoFiles'],
        'otherDocumentFiles': value['otherDocumentFiles'],
    };
}

