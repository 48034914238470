/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarrierServiceType
 */
export interface CarrierServiceType {
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceType
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceType
     */
    carrierDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceType
     */
    serviceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceType
     */
    serviceDisplayName: string;
}

/**
 * Check if a given object implements the CarrierServiceType interface.
 */
export function instanceOfCarrierServiceType(value: object): boolean {
    if (!('carrierIdentifier' in value)) return false;
    if (!('carrierDisplayName' in value)) return false;
    if (!('serviceIdentifier' in value)) return false;
    if (!('serviceDisplayName' in value)) return false;
    return true;
}

export function CarrierServiceTypeFromJSON(json: any): CarrierServiceType {
    return CarrierServiceTypeFromJSONTyped(json, false);
}

export function CarrierServiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierServiceType {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierIdentifier': json['carrierIdentifier'],
        'carrierDisplayName': json['carrierDisplayName'],
        'serviceIdentifier': json['serviceIdentifier'],
        'serviceDisplayName': json['serviceDisplayName'],
    };
}

export function CarrierServiceTypeToJSON(value?: CarrierServiceType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierIdentifier': value['carrierIdentifier'],
        'carrierDisplayName': value['carrierDisplayName'],
        'serviceIdentifier': value['serviceIdentifier'],
        'serviceDisplayName': value['serviceDisplayName'],
    };
}

