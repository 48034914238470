/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface IndivualShipmentLine
 */
export interface IndivualShipmentLine {
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    pickupDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndivualShipmentLine
     */
    booked: boolean;
    /**
     * 
     * @type {ShipmentState}
     * @memberof IndivualShipmentLine
     */
    status: ShipmentState;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    pickupCity: string;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    pickupStateOrProvince: string;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    pickupPostalCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof IndivualShipmentLine
     */
    pickupCountryCode: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    deliveryCity: string;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    deliveryStateOrProvince: string;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    deliveryPostalCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof IndivualShipmentLine
     */
    deliveryCountryCode: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof IndivualShipmentLine
     */
    weight: string;
}

/**
 * Check if a given object implements the IndivualShipmentLine interface.
 */
export function instanceOfIndivualShipmentLine(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('booked' in value)) return false;
    if (!('status' in value)) return false;
    if (!('pickupCity' in value)) return false;
    if (!('pickupStateOrProvince' in value)) return false;
    if (!('pickupPostalCode' in value)) return false;
    if (!('pickupCountryCode' in value)) return false;
    if (!('deliveryCity' in value)) return false;
    if (!('deliveryStateOrProvince' in value)) return false;
    if (!('deliveryPostalCode' in value)) return false;
    if (!('deliveryCountryCode' in value)) return false;
    if (!('weight' in value)) return false;
    return true;
}

export function IndivualShipmentLineFromJSON(json: any): IndivualShipmentLine {
    return IndivualShipmentLineFromJSONTyped(json, false);
}

export function IndivualShipmentLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndivualShipmentLine {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'pickupDate': json['pickupDate'],
        'booked': json['booked'],
        'status': ShipmentStateFromJSON(json['status']),
        'pickupCity': json['pickupCity'],
        'pickupStateOrProvince': json['pickupStateOrProvince'],
        'pickupPostalCode': json['pickupPostalCode'],
        'pickupCountryCode': CountryCodeFromJSON(json['pickupCountryCode']),
        'deliveryCity': json['deliveryCity'],
        'deliveryStateOrProvince': json['deliveryStateOrProvince'],
        'deliveryPostalCode': json['deliveryPostalCode'],
        'deliveryCountryCode': CountryCodeFromJSON(json['deliveryCountryCode']),
        'weight': json['weight'],
    };
}

export function IndivualShipmentLineToJSON(value?: IndivualShipmentLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'pickupDate': value['pickupDate'],
        'booked': value['booked'],
        'status': ShipmentStateToJSON(value['status']),
        'pickupCity': value['pickupCity'],
        'pickupStateOrProvince': value['pickupStateOrProvince'],
        'pickupPostalCode': value['pickupPostalCode'],
        'pickupCountryCode': CountryCodeToJSON(value['pickupCountryCode']),
        'deliveryCity': value['deliveryCity'],
        'deliveryStateOrProvince': value['deliveryStateOrProvince'],
        'deliveryPostalCode': value['deliveryPostalCode'],
        'deliveryCountryCode': CountryCodeToJSON(value['deliveryCountryCode']),
        'weight': value['weight'],
    };
}

