import { Button, message, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { CompanyNote } from "../../generated-openapi-client";
import { useDisplay } from "../../Hooks/useDisplay";

interface DeleteSalesCallNoteProps {
  note: CompanyNote;
  onRefresh: () => Promise<void>;
}
export function DeleteSalesCallNoteBodyButton({
  note,
  onRefresh,
}: DeleteSalesCallNoteProps) {
  const display = useDisplay();
  const [loading, setLoading] = useState(false);

  const createCompaniesApi = useCompaniesApi();

  async function handleOk() {
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.clearSalesCall({
        companyNoteId: note.companyNoteId!,
      });
      await onRefresh();
      display.hide();
    } catch (error) {
      message.error("Oops");
    }
    setLoading(false);
  }

  return (
    <>
      <Tooltip title="Delete Call Notes">
        <Button danger type="text" onClick={display.show}>
          Delete notes
        </Button>
      </Tooltip>

      <Modal
        title={"Delete Sales Call Note"}
        visible={display.status}
        onOk={handleOk}
        onCancel={display.hide}
        destroyOnClose={true}
        width={600}
        confirmLoading={loading}
      >
        Are you sure you want the sales call notes and updates?
      </Modal>
    </>
  );
}
