/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CustomerInvoiceState = {
    Issued: 'issued',
    SettlementPending: 'settlement_pending',
    Settled: 'settled',
    Reconciled: 'reconciled',
    Voided: 'voided',
    Deleted: 'deleted'
} as const;
export type CustomerInvoiceState = typeof CustomerInvoiceState[keyof typeof CustomerInvoiceState];


export function instanceOfCustomerInvoiceState(value: any): boolean {
    return Object.values(CustomerInvoiceState).includes(value);
}

export function CustomerInvoiceStateFromJSON(json: any): CustomerInvoiceState {
    return CustomerInvoiceStateFromJSONTyped(json, false);
}

export function CustomerInvoiceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerInvoiceState {
    return json as CustomerInvoiceState;
}

export function CustomerInvoiceStateToJSON(value?: CustomerInvoiceState | null): any {
    return value as any;
}

