/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyShipmentCarrierInvoice } from './CompanyShipmentCarrierInvoice';
import {
    CompanyShipmentCarrierInvoiceFromJSON,
    CompanyShipmentCarrierInvoiceFromJSONTyped,
    CompanyShipmentCarrierInvoiceToJSON,
} from './CompanyShipmentCarrierInvoice';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';

/**
 * 
 * @export
 * @interface CarrierInvoiceData
 */
export interface CarrierInvoiceData {
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof CarrierInvoiceData
     */
    shipmentsWithoutInvoices: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof CarrierInvoiceData
     */
    shipmentsOverdueAnInvoice: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentCarrierInvoice>}
     * @memberof CarrierInvoiceData
     */
    issuedInvoices: Array<CompanyShipmentCarrierInvoice>;
    /**
     * 
     * @type {Array<CompanyShipmentCarrierInvoice>}
     * @memberof CarrierInvoiceData
     */
    approvedInvoices: Array<CompanyShipmentCarrierInvoice>;
    /**
     * 
     * @type {Array<CompanyShipmentCarrierInvoice>}
     * @memberof CarrierInvoiceData
     */
    paymentPendingInvoices: Array<CompanyShipmentCarrierInvoice>;
    /**
     * 
     * @type {Array<CompanyShipmentCarrierInvoice>}
     * @memberof CarrierInvoiceData
     */
    invoicesReadyToPay: Array<CompanyShipmentCarrierInvoice>;
}

/**
 * Check if a given object implements the CarrierInvoiceData interface.
 */
export function instanceOfCarrierInvoiceData(value: object): boolean {
    if (!('shipmentsWithoutInvoices' in value)) return false;
    if (!('shipmentsOverdueAnInvoice' in value)) return false;
    if (!('issuedInvoices' in value)) return false;
    if (!('approvedInvoices' in value)) return false;
    if (!('paymentPendingInvoices' in value)) return false;
    if (!('invoicesReadyToPay' in value)) return false;
    return true;
}

export function CarrierInvoiceDataFromJSON(json: any): CarrierInvoiceData {
    return CarrierInvoiceDataFromJSONTyped(json, false);
}

export function CarrierInvoiceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInvoiceData {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentsWithoutInvoices': ((json['shipmentsWithoutInvoices'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'shipmentsOverdueAnInvoice': ((json['shipmentsOverdueAnInvoice'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'issuedInvoices': ((json['issuedInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceFromJSON)),
        'approvedInvoices': ((json['approvedInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceFromJSON)),
        'paymentPendingInvoices': ((json['paymentPendingInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceFromJSON)),
        'invoicesReadyToPay': ((json['invoicesReadyToPay'] as Array<any>).map(CompanyShipmentCarrierInvoiceFromJSON)),
    };
}

export function CarrierInvoiceDataToJSON(value?: CarrierInvoiceData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentsWithoutInvoices': ((value['shipmentsWithoutInvoices'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'shipmentsOverdueAnInvoice': ((value['shipmentsOverdueAnInvoice'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'issuedInvoices': ((value['issuedInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceToJSON)),
        'approvedInvoices': ((value['approvedInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceToJSON)),
        'paymentPendingInvoices': ((value['paymentPendingInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceToJSON)),
        'invoicesReadyToPay': ((value['invoicesReadyToPay'] as Array<any>).map(CompanyShipmentCarrierInvoiceToJSON)),
    };
}

