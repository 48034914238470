/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApolloMapCompany,
  ApolloMapLocationFilterGroup,
  ApolloMapQuery,
  ShipmentReport,
} from '../models/index';
import {
    ApolloMapCompanyFromJSON,
    ApolloMapCompanyToJSON,
    ApolloMapLocationFilterGroupFromJSON,
    ApolloMapLocationFilterGroupToJSON,
    ApolloMapQueryFromJSON,
    ApolloMapQueryToJSON,
    ShipmentReportFromJSON,
    ShipmentReportToJSON,
} from '../models/index';

export interface GenerateMapRequest {
    apolloMapQuery: ApolloMapQuery;
}

/**
 * 
 */
export class MapApi extends runtime.BaseAPI {

    /**
     */
    async generateMapRaw(requestParameters: GenerateMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShipmentReport>>> {
        if (requestParameters['apolloMapQuery'] == null) {
            throw new runtime.RequiredError(
                'apolloMapQuery',
                'Required parameter "apolloMapQuery" was null or undefined when calling generateMap().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/map/generate-map`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApolloMapQueryToJSON(requestParameters['apolloMapQuery']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShipmentReportFromJSON));
    }

    /**
     */
    async generateMap(requestParameters: GenerateMapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShipmentReport>> {
        const response = await this.generateMapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApolloMapCompany>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/map/get-companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApolloMapCompanyFromJSON));
    }

    /**
     */
    async getCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApolloMapCompany>> {
        const response = await this.getCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getLocationFilterOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApolloMapLocationFilterGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/map/get-location-filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApolloMapLocationFilterGroupFromJSON));
    }

    /**
     */
    async getLocationFilterOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApolloMapLocationFilterGroup>> {
        const response = await this.getLocationFilterOptionsRaw(initOverrides);
        return await response.value();
    }

}
