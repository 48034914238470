import { Timeline } from "antd";
import { ReactNode } from "react";
import styled from "styled-components/macro";

interface StyledTimelineWrapperProps {
  fromLeft: string;
  contentWidth: string;
}

const StyledTimelineWrapper = styled.div<StyledTimelineWrapperProps>`
  width: 100%;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px !important;
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: ${(p) => p.fromLeft} !important;
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: calc(${(p) => p.fromLeft} + 10px) !important;
  }

  ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: ${(p) => p.fromLeft} !important;
  }

  .ant-timeline-item-content {
    left: ${(p) => p.fromLeft} !important;
    width: ${(p) => p.contentWidth} !important;
    /* original css  */
    /* left: calc(50%) */
  }

  .ant-timeline {
    margin: 0;
    padding: 0;
  }
`;

interface LeftAlignedTimlineProps extends Partial<StyledTimelineWrapperProps> {
  children: ReactNode;
}

export function LeftAlignedTimeline({
  children,
  fromLeft = "300px",
  contentWidth = "calc(100% - 350px)",
}: LeftAlignedTimlineProps) {
  return (
    <StyledTimelineWrapper fromLeft={fromLeft} contentWidth={contentWidth}>
      <Timeline mode="left" style={{ width: "100%" }}>
        {/*
          This hidden Timeline.Item fixes the css/style 
          Without out this the rest of Timeline.Item elements render incorrectly 
          This hidden Timeline.Item will prevent that (does not affect anything visually)
      */}
        <Timeline.Item
          key={"hidden"}
          label={"hidden"}
          style={{ height: "0px", visibility: "hidden" }}
        />
        {children}
      </Timeline>
    </StyledTimelineWrapper>
  );
}
