/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigurationData
 */
export interface ConfigurationData {
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurationData
     */
    carrierPickupNumberSameAsProNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurationData
     */
    supportsAutomaticPickup: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationData
     */
    mainTrackingNumberName: string;
}

/**
 * Check if a given object implements the ConfigurationData interface.
 */
export function instanceOfConfigurationData(value: object): boolean {
    if (!('carrierPickupNumberSameAsProNumber' in value)) return false;
    if (!('supportsAutomaticPickup' in value)) return false;
    if (!('mainTrackingNumberName' in value)) return false;
    return true;
}

export function ConfigurationDataFromJSON(json: any): ConfigurationData {
    return ConfigurationDataFromJSONTyped(json, false);
}

export function ConfigurationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationData {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierPickupNumberSameAsProNumber': json['carrierPickupNumberSameAsProNumber'],
        'supportsAutomaticPickup': json['supportsAutomaticPickup'],
        'mainTrackingNumberName': json['mainTrackingNumberName'],
    };
}

export function ConfigurationDataToJSON(value?: ConfigurationData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierPickupNumberSameAsProNumber': value['carrierPickupNumberSameAsProNumber'],
        'supportsAutomaticPickup': value['supportsAutomaticPickup'],
        'mainTrackingNumberName': value['mainTrackingNumberName'],
    };
}

