/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NoteIntercomType = {
    AdminReplied: 'admin_replied',
    UserCreated: 'user_created',
    UserReplied: 'user_replied',
    AdminNoted: 'admin_noted'
} as const;
export type NoteIntercomType = typeof NoteIntercomType[keyof typeof NoteIntercomType];


export function instanceOfNoteIntercomType(value: any): boolean {
    return Object.values(NoteIntercomType).includes(value);
}

export function NoteIntercomTypeFromJSON(json: any): NoteIntercomType {
    return NoteIntercomTypeFromJSONTyped(json, false);
}

export function NoteIntercomTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteIntercomType {
    return json as NoteIntercomType;
}

export function NoteIntercomTypeToJSON(value?: NoteIntercomType | null): any {
    return value as any;
}

