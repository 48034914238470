/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * 
 * @export
 * @interface Broker
 */
export interface Broker {
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    businessName?: string;
    /**
     * 
     * @type {Address}
     * @memberof Broker
     */
    address?: Address;
    /**
     * 
     * @type {Contact}
     * @memberof Broker
     */
    contact?: Contact;
}

/**
 * Check if a given object implements the Broker interface.
 */
export function instanceOfBroker(value: object): boolean {
    return true;
}

export function BrokerFromJSON(json: any): Broker {
    return BrokerFromJSONTyped(json, false);
}

export function BrokerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Broker {
    if (json == null) {
        return json;
    }
    return {
        
        'businessName': json['businessName'] == null ? undefined : json['businessName'],
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
        'contact': json['contact'] == null ? undefined : ContactFromJSON(json['contact']),
    };
}

export function BrokerToJSON(value?: Broker | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'businessName': value['businessName'],
        'address': AddressToJSON(value['address']),
        'contact': ContactToJSON(value['contact']),
    };
}

