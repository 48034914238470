/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuotingStrategyType } from './QuotingStrategyType';
import {
    QuotingStrategyTypeFromJSON,
    QuotingStrategyTypeFromJSONTyped,
    QuotingStrategyTypeToJSON,
} from './QuotingStrategyType';

/**
 * 
 * @export
 * @interface QuotingStrategy
 */
export interface QuotingStrategy {
    /**
     * 
     * @type {QuotingStrategyType}
     * @memberof QuotingStrategy
     */
    strategyType: QuotingStrategyType;
    /**
     * 
     * @type {boolean}
     * @memberof QuotingStrategy
     */
    visibleToCustomer?: boolean;
}

/**
 * Check if a given object implements the QuotingStrategy interface.
 */
export function instanceOfQuotingStrategy(value: object): boolean {
    if (!('strategyType' in value)) return false;
    return true;
}

export function QuotingStrategyFromJSON(json: any): QuotingStrategy {
    return QuotingStrategyFromJSONTyped(json, false);
}

export function QuotingStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuotingStrategy {
    if (json == null) {
        return json;
    }
    return {
        
        'strategyType': QuotingStrategyTypeFromJSON(json['strategyType']),
        'visibleToCustomer': json['visibleToCustomer'] == null ? undefined : json['visibleToCustomer'],
    };
}

export function QuotingStrategyToJSON(value?: QuotingStrategy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'strategyType': QuotingStrategyTypeToJSON(value['strategyType']),
        'visibleToCustomer': value['visibleToCustomer'],
    };
}

