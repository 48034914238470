/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentDocumentVisibilty } from './ShipmentDocumentVisibilty';
import {
    ShipmentDocumentVisibiltyFromJSON,
    ShipmentDocumentVisibiltyFromJSONTyped,
    ShipmentDocumentVisibiltyToJSON,
} from './ShipmentDocumentVisibilty';

/**
 * 
 * @export
 * @interface DocumentsTabData
 */
export interface DocumentsTabData {
    /**
     * 
     * @type {Array<ShipmentDocumentVisibilty>}
     * @memberof DocumentsTabData
     */
    documents: Array<ShipmentDocumentVisibilty>;
}

/**
 * Check if a given object implements the DocumentsTabData interface.
 */
export function instanceOfDocumentsTabData(value: object): boolean {
    if (!('documents' in value)) return false;
    return true;
}

export function DocumentsTabDataFromJSON(json: any): DocumentsTabData {
    return DocumentsTabDataFromJSONTyped(json, false);
}

export function DocumentsTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentsTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(ShipmentDocumentVisibiltyFromJSON)),
    };
}

export function DocumentsTabDataToJSON(value?: DocumentsTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': ((value['documents'] as Array<any>).map(ShipmentDocumentVisibiltyToJSON)),
    };
}

