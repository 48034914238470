/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyShipment } from './CompanyShipment';
import {
    CompanyShipmentFromJSON,
    CompanyShipmentFromJSONTyped,
    CompanyShipmentToJSON,
} from './CompanyShipment';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';

/**
 * 
 * @export
 * @interface ActiveShipments
 */
export interface ActiveShipments {
    /**
     * 
     * @type {Array<CompanyShipment>}
     * @memberof ActiveShipments
     */
    manuallyQuoting: Array<CompanyShipment>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    unfinishedBooking: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    onHold: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    pickingUpSoon: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    pickupToday: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    pickupIsLate: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    inTransit: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof ActiveShipments
     */
    delivered: Array<CompanyShipmentQuote>;
}

/**
 * Check if a given object implements the ActiveShipments interface.
 */
export function instanceOfActiveShipments(value: object): boolean {
    if (!('manuallyQuoting' in value)) return false;
    if (!('unfinishedBooking' in value)) return false;
    if (!('onHold' in value)) return false;
    if (!('pickingUpSoon' in value)) return false;
    if (!('pickupToday' in value)) return false;
    if (!('pickupIsLate' in value)) return false;
    if (!('inTransit' in value)) return false;
    if (!('delivered' in value)) return false;
    return true;
}

export function ActiveShipmentsFromJSON(json: any): ActiveShipments {
    return ActiveShipmentsFromJSONTyped(json, false);
}

export function ActiveShipmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveShipments {
    if (json == null) {
        return json;
    }
    return {
        
        'manuallyQuoting': ((json['manuallyQuoting'] as Array<any>).map(CompanyShipmentFromJSON)),
        'unfinishedBooking': ((json['unfinishedBooking'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'onHold': ((json['onHold'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'pickingUpSoon': ((json['pickingUpSoon'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'pickupToday': ((json['pickupToday'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'pickupIsLate': ((json['pickupIsLate'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'inTransit': ((json['inTransit'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'delivered': ((json['delivered'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
    };
}

export function ActiveShipmentsToJSON(value?: ActiveShipments | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'manuallyQuoting': ((value['manuallyQuoting'] as Array<any>).map(CompanyShipmentToJSON)),
        'unfinishedBooking': ((value['unfinishedBooking'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'onHold': ((value['onHold'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'pickingUpSoon': ((value['pickingUpSoon'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'pickupToday': ((value['pickupToday'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'pickupIsLate': ((value['pickupIsLate'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'inTransit': ((value['inTransit'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'delivered': ((value['delivered'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
    };
}

