/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SupportAgent = {
    Amy: 'Amy',
    Olivia: 'Olivia',
    Luke: 'Luke',
    Thomas: 'Thomas',
    Richard: 'Richard',
    Scott: 'Scott',
    Jennifer: 'Jennifer',
    Lucia: 'Lucia',
    Andrea: 'Andrea'
} as const;
export type SupportAgent = typeof SupportAgent[keyof typeof SupportAgent];


export function instanceOfSupportAgent(value: any): boolean {
    return Object.values(SupportAgent).includes(value);
}

export function SupportAgentFromJSON(json: any): SupportAgent {
    return SupportAgentFromJSONTyped(json, false);
}

export function SupportAgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportAgent {
    return json as SupportAgent;
}

export function SupportAgentToJSON(value?: SupportAgent | null): any {
    return value as any;
}

