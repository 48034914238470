import {
  Button,
  Form,
  InputNumber,
  message,
  Modal,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { CarrierInvoiceState } from "../../generated-openapi-client";
import { CarrierInvoice } from "../../generated-openapi-client/models/CarrierInvoice";
import { CurrencyCode } from "../../generated-openapi-client/models/CurrencyCode";
import Spacer from "../../Spacer";
import { CurrencySelector } from "../CurrencySelector";
import { TabProps } from "./TabProps";

const { Text } = Typography;

interface AddCarrierCreditNoteButtonProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function AddCarrierCreditNoteButton(
  props: AddCarrierCreditNoteButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCode | undefined>();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [storing, setStoring] = useState(false);
  const carrierInvoice = props.carrierInvoice;
  const carrierInvoiceId = carrierInvoice.carrierInvoiceId!!;

  useEffect(
    function () {
      if (isModalVisible) {
        setAmount(0);
        setCurrency(undefined);
      }
    },
    [isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    console.log(`handleOk`);
    const carrierInvoiceApi = await createCarrierInvoiceApi();

    if (currency === undefined) {
      return;
    }

    setStoring(true);

    try {
      console.log(`handleOk - call api`);

      await carrierInvoiceApi.createCarrierCreditNote({
        carrierInvoiceId,
        amount,
        currency,
      });

      await props.onRefresh();
      setIsModalVisible(false);

      message.success("Created!");

      setStoring(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
      setStoring(false);
    }
    console.log(`handleOk - done`);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const description = `If the carrier overcharged us, and we are owed a refund, create a credit note. This will allow us to reconcile a credit card refund in Xero, or a cheque that we are sent. It also helps the books balance.`;

  if (
    carrierInvoice.invoiceState !== CarrierInvoiceState.PaymentConfirmed &&
    carrierInvoice.invoiceState !== CarrierInvoiceState.PaymentPending
  ) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Add Carrier Credit Note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add Carrier Credit Note"
        confirmLoading={storing}
        destroyOnClose={true}
        width={800}
      >
        <Text>{description}</Text>
        <Spacer height={32} />
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label={`Credit Note Amount ${amount}`}>
            <InputNumber
              prefix="$"
              value={amount}
              onChange={setAmount}
              style={{ width: "150px" }}
            />
          </Form.Item>
          <Form.Item label="Credit Note Currency">
            <CurrencySelector currency={currency} setCurrency={setCurrency} />
          </Form.Item>
        </Form>
      </Modal>
      <Tooltip placement="left" title={description}>
        <Button onClick={showModal}>Add Carrier Credit Note</Button>
      </Tooltip>
    </>
  );
}
