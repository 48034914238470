/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonthlyChartLine
 */
export interface MonthlyChartLine {
    /**
     * 
     * @type {string}
     * @memberof MonthlyChartLine
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof MonthlyChartLine
     */
    count: number;
}

/**
 * Check if a given object implements the MonthlyChartLine interface.
 */
export function instanceOfMonthlyChartLine(value: object): boolean {
    if (!('month' in value)) return false;
    if (!('count' in value)) return false;
    return true;
}

export function MonthlyChartLineFromJSON(json: any): MonthlyChartLine {
    return MonthlyChartLineFromJSONTyped(json, false);
}

export function MonthlyChartLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyChartLine {
    if (json == null) {
        return json;
    }
    return {
        
        'month': json['month'],
        'count': json['count'],
    };
}

export function MonthlyChartLineToJSON(value?: MonthlyChartLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'month': value['month'],
        'count': value['count'],
    };
}

