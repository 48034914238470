import { HTMLAttributes, ReactNode } from "react";
import Colors from "../Colors";

interface NoteDetailsBoxProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export function NoteDetailsBox({
  children,
  style,
  ...rest
}: NoteDetailsBoxProps) {
  return (
    <div
      {...rest}
      style={{
        whiteSpace: "pre-wrap",
        background: "white",
        border: "1px solid lightgray",
        borderColor: Colors.Gray[200],
        // background: Colors.Gray[50],
        paddingBlock: "15px",
        paddingInline: "20px",
        borderRadius: "10px",
        display: "inline-block",
        marginTop: "10px",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

NoteDetailsBox.LeftRightDetails = ({
  left,
  right,
}: {
  left: ReactNode;
  right: ReactNode;
}) => {
  return (
    <div
      style={{
        paddingBlock: "4px",
        display: "flex",
        columnGap: "40px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <span style={{ fontSize: "20", fontWeight: "500" }}>{left}</span>
      </div>
      <div>{right}</div>
    </div>
  );
};
