import { Tag } from "antd";
import { CarrierInvoiceApprovalType } from "../generated-openapi-client";
import Colors from "./Colors";

function describe(state: CarrierInvoiceApprovalType) {
  switch (state) {
    case CarrierInvoiceApprovalType.Approved:
      return "Approved";
    case CarrierInvoiceApprovalType.NotApprovedTooExpensive:
      return "Not Approved Too Expensive";
    case CarrierInvoiceApprovalType.NotApprovedInvalidCurrency:
      return "Not Approved Invalid Currency";
    case CarrierInvoiceApprovalType.Duplicate:
      return "Duplicate";
    case CarrierInvoiceApprovalType.Zero:
      return "Zero";
    case CarrierInvoiceApprovalType.NoInvoices:
      return "No Invoices";
    case CarrierInvoiceApprovalType.TaxMismatch:
      return "Tax Mismatch";
    case CarrierInvoiceApprovalType.NotApprovedCancelled:
      return "Not Approved Cancelled";
    case CarrierInvoiceApprovalType.NotApprovedChallengeAllInvoices:
      return "Not Approved Callenge All Invoices";
  }
}

function color(state: CarrierInvoiceApprovalType): string {
  switch (state) {
    case CarrierInvoiceApprovalType.Approved:
      return Colors.DarkGreen;
    case CarrierInvoiceApprovalType.NotApprovedTooExpensive:
      return Colors.Orange;
    case CarrierInvoiceApprovalType.NotApprovedInvalidCurrency:
      return Colors.Red;
    case CarrierInvoiceApprovalType.Duplicate:
      return Colors.LightGray;
    case CarrierInvoiceApprovalType.Zero:
      return Colors.DarkGray;
    case CarrierInvoiceApprovalType.NoInvoices:
      return Colors.White;
    case CarrierInvoiceApprovalType.TaxMismatch:
      return Colors.Gold;
    case CarrierInvoiceApprovalType.NotApprovedCancelled:
      return Colors.DarkGray;
    case CarrierInvoiceApprovalType.NotApprovedChallengeAllInvoices:
      return Colors.LightGold;
  }
}

function foregroundColor(state: CarrierInvoiceApprovalType) {
  switch (state) {
    case CarrierInvoiceApprovalType.Approved:
      return "white";
    case CarrierInvoiceApprovalType.NotApprovedTooExpensive:
      return "white";
    case CarrierInvoiceApprovalType.NotApprovedInvalidCurrency:
      return "white";
    case CarrierInvoiceApprovalType.Duplicate:
      return "white";
    case CarrierInvoiceApprovalType.Zero:
      return "white";
    case CarrierInvoiceApprovalType.NoInvoices:
      return "white";
    case CarrierInvoiceApprovalType.TaxMismatch:
      return "black";
    case CarrierInvoiceApprovalType.NotApprovedCancelled:
      return "white";
    case CarrierInvoiceApprovalType.NotApprovedChallengeAllInvoices:
      return "black";
  }
}

interface CarrierInoviceApprovalTypeTagProps {
  approvalType: CarrierInvoiceApprovalType;
}
export function CarrierInvoiceApprovalTypeTag(
  props: CarrierInoviceApprovalTypeTagProps
) {
  return (
    <Tag color={color(props.approvalType)}>
      <div style={{ color: foregroundColor(props.approvalType) }}>
        {describe(props.approvalType)}
      </div>
    </Tag>
  );
}
