import { Alert, Button, Menu, Modal, Statistic, Table, Typography } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import { CopyMenuItem } from "../../Components/CopyMenuItem";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { StatisticText } from "../../Components/StatisticText";
import { ViewRawMenuItem } from "../../Components/ViewRawButton";
import { safeColumns } from "../../Helpers/safeColumns";
import { sum } from "../../Helpers/sum";
import Spacer from "../../Spacer";
import {
  FreightClaim,
  FreightClaimLineItem,
  FreightClaimType,
  ShipmentDocumentVisibilty,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { DocumentType } from "../../generated-openapi-client/models/DocumentType";
import { CarrierClaimSupportEmailButton } from "./CarrierClaimSupportEmailButton";
import { DocumentSourceTag } from "./DocumentSourceTag";
import { DocumentTypeTag } from "./DocumentTypeTag";
import { ViewDocumentButton } from "./ViewDocumentButton";

const { Title } = Typography;

interface DocumentsTableProps {
  freightClaim: FreightClaim;
  data: ViewShipmentDataForApollo;
}

function DocumentsTable(props: DocumentsTableProps) {
  const relevantDocuments = props.data.documentsTab.documents.filter(function (
    d
  ) {
    if (d.shipmentDocument.documentType === DocumentType.ProofOfDelivery) {
      return true;
    }

    if (
      props.freightClaim.originalInvoiceFiles?.includes(
        d.shipmentDocument.shipmentDocumentId!!
      )
    ) {
      return true;
    }

    if (
      props.freightClaim.otherDocumentFiles?.includes(
        d.shipmentDocument.shipmentDocumentId!!
      )
    ) {
      return true;
    }

    if (
      props.freightClaim.photoFiles?.includes(
        d.shipmentDocument.shipmentDocumentId!!
      )
    ) {
      return true;
    }

    return false;
  });
  return (
    <Table
      columns={safeColumns<ShipmentDocumentVisibilty>([
        {
          title: "Document Type",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <DocumentTypeTag documentType={o.shipmentDocument.documentType!!} />
          ),
        },
        {
          title: "Source",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <DocumentSourceTag source={o.shipmentDocument.source!!} />
          ),
        },

        {
          title: "Created At",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <CreatedAt timestamp={o.shipmentDocument.createdAt} />
          ),
        },
        {
          title: "Actions",
          render: function (_, document: ShipmentDocumentVisibilty) {
            return (
              <ButtonRow>
                <ViewDocumentButton
                  document={document.shipmentDocument}
                  shipmentData={props.data}
                />
              </ButtonRow>
            );
          },
        },
      ])}
      dataSource={relevantDocuments}
      pagination={false}
    />
  );
}

interface ClaimLineItemsTableProps {
  freightClaim: FreightClaim;
}

function ClaimLineItemsTable(props: ClaimLineItemsTableProps) {
  const columns: DataTableColumn<FreightClaimLineItem>[] = [
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "Total Weight",
      render: (o) => (
        <div>{o.weightPerHandlingUnit!! * o.numberHandlingUnits!!}lb</div>
      ),
    },
    {
      title: "Condition",
      render: (o) => <div>{o.condition}</div>,
    },
    {
      title: "Pieces Affected",
      render: (o) => <div>{o.piecesAffected}</div>,
    },
    {
      title: "Pieces Total",
      render: (o) => <div>{o.piecesTotal}</div>,
    },
    {
      title: "Unit Cost",
      render: (o) => (
        <div>
          ${o.unitCost} {props.freightClaim.claimCurrency}
        </div>
      ),
    },
    {
      title: "Claim Type",
      render: (o) => <div>{o.claimType}</div>,
    },
  ];
  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.freightClaim.freightClaimLineItems!!}
    />
  );
}

interface ViewClaimButtonProps {
  freightClaim: FreightClaim;
  data: ViewShipmentDataForApollo;
}

export function calculateFreightClaimTotal(fc: FreightClaim) {
  if (fc.freightClaimLineItems === undefined) {
    return 0;
  }

  const lostOrDamagedFreight = fc.freightClaimLineItems.filter(
    (f) =>
      f.claimType === FreightClaimType.Damage ||
      f.claimType === FreightClaimType.Loss
  );

  return sum(lostOrDamagedFreight, (li) => li.piecesAffected!! * li.unitCost!!);
}

export function ViewClaimButton(props: ViewClaimButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`View Claim`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <HorizontalStack>
          <StatisticText
            title="Carrier"
            value={
              <CarrierLogo
                carrierIdentifier={props.freightClaim.carrierIdentifier}
                brokeredCarrierIdentifier={undefined}
                width={60}
                height={40}
              />
            }
            style={{ marginRight: "32px" }}
          />
          <StatisticText
            title="Pro Number"
            value={<div>{props.freightClaim.proNumber}</div>}
            style={{ marginRight: "32px" }}
          />
          <StatisticText
            title="Created At"
            value={
              <CreatedAt
                days
                timestamp={props.freightClaim.createdAt}
              ></CreatedAt>
            }
            style={{ marginRight: "32px" }}
          />
          <StatisticText
            title="State"
            value={<div>{props.freightClaim.freightClaimState}</div>}
            style={{ marginRight: "32px" }}
          />
          <Statistic
            title="Total Amount"
            value={calculateFreightClaimTotal(props.freightClaim)}
            prefix="$"
            suffix={props.freightClaim.claimCurrency}
            style={{ marginRight: "32px" }}
          />
        </HorizontalStack>
        <Spacer height={16} />
        <ButtonRow
          extrasMenu={
            <Menu>
              <CopyMenuItem
                title="Copy Freight Claim Id"
                value={props.freightClaim.freightClaimId!!}
              />
              <ViewRawMenuItem data={props.freightClaim} />
            </Menu>
          }
        >
          <CarrierClaimSupportEmailButton
            freightClaimId={props.freightClaim.freightClaimId!!}
            data={props.data}
          />
        </ButtonRow>
        <Spacer height={16} />
        <Alert
          message={
            <Stack align="left">
              <div>{props.freightClaim.claimDescription}</div>
              {props.freightClaim.claimInternalNotes !== "" && (
                <>
                  <Spacer height={16} />
                  <div>
                    Internal Notes: {props.freightClaim.claimInternalNotes}
                  </div>
                </>
              )}
            </Stack>
          }
          type="info"
        />
        <Spacer height={16} />
        <Title level={4}>Line Items</Title>
        <ClaimLineItemsTable freightClaim={props.freightClaim} />
        <Spacer height={32} />
        <Title level={4}>Documents</Title>
        <div>
          Blank Claim Forms are available in One Drive in the 'Claim Forms'
          Folder
        </div>
        <Spacer height={8} />
        <DocumentsTable data={props.data} freightClaim={props.freightClaim} />
      </Modal>
      <Button onClick={showModal}>View Claim</Button>
    </>
  );
}
