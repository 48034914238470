import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import { groupBy } from "../../Helpers/groupBy";
import Spacer from "../../Spacer";

import { ShipmentReport } from "../../generated-openapi-client";
import { MapScreenLocationFrequencyTable } from "./MapScreenLocationFrequencyTable";

const { Text } = Typography;

interface MapScreenHistogramsButtonProps {
  shipments: ShipmentReport[];
}

export function MapScreenHistogramsButton(
  props: MapScreenHistogramsButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pickupFrequencyData = groupBy(props.shipments, function (shipment) {
    return `${shipment.pickupCity}, ${shipment.pickupState}`;
  })
    .map(function (o) {
      return { location: o.key, count: o.value.length };
    })
    .sort(function (a, b) {
      return b.count - a.count;
    });

  const deliveryFrequencyData = groupBy(props.shipments, function (shipment) {
    return `${shipment.deliveryCity}, ${shipment.deliveryState}`;
  })
    .map(function (o) {
      return { location: o.key, count: o.value.length };
    })
    .sort(function (a, b) {
      return b.count - a.count;
    });

  const companyFrequencyData = groupBy(props.shipments, function (shipment) {
    return `${shipment.companyName}`;
  })
    .map(function (o) {
      return { location: o.key, count: o.value.length };
    })
    .sort(function (a, b) {
      return b.count - a.count;
    });

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Shipments"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <>
          <Text>Pickup Frequency</Text>
          <MapScreenLocationFrequencyTable data={pickupFrequencyData} />
          <Spacer height={16} />
          <Text>Delivery Frequency</Text>
          <MapScreenLocationFrequencyTable data={deliveryFrequencyData} />
          <Text>Company Frequency</Text>
          <MapScreenLocationFrequencyTable data={companyFrequencyData} />
        </>
      </Modal>
      <Button type="link" onClick={showModal}>
        View Histograms
      </Button>
    </>
  );
}
