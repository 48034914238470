import { Select } from "antd";
import moment from "moment-timezone";
import { PeriodFormats } from "../Screens/FinanceComponents/generatePeriodInfo";

interface MonthSelectProps {
  month: string | undefined;
  setMonth: (_: string | undefined) => void;
  counter?: (month: string) => number;
}

export function MonthSelect(props: MonthSelectProps) {
  const { month, setMonth } = props;

  const startingMonth = moment().startOf("month");

  const months = [
    startingMonth.clone(),
    startingMonth.clone().subtract(1, "month"),
    startingMonth.clone().subtract(2, "month"),
    startingMonth.clone().subtract(3, "month"),
    startingMonth.clone().subtract(4, "month"),
    startingMonth.clone().subtract(5, "month"),
    startingMonth.clone().subtract(6, "month"),
    startingMonth.clone().subtract(7, "month"),
    startingMonth.clone().subtract(8, "month"),
    startingMonth.clone().subtract(9, "month"),
    startingMonth.clone().subtract(10, "month"),
    startingMonth.clone().subtract(11, "month"),
  ];

  console.log({ months, startingMonth });

  function count(month: string) {
    if (props.counter === undefined) {
      return "";
    } else {
      return `(${props.counter(month)})`;
    }
  }

  return (
    <Select
      allowClear
      value={month}
      style={{ width: 220 }}
      onChange={function (e) {
        setMonth(e);
      }}
      placeholder="Filter by month"
    >
      {months.map(function (m) {
        return (
          <Select.Option value={m.format(PeriodFormats.Monthly)}>
            {m.format("MMM YYYY")} {count(m.format(PeriodFormats.Monthly))}
          </Select.Option>
        );
      })}
    </Select>
  );
}
