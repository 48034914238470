import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../../generated-openapi-client/models/SupportAgent";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailButton } from "./SupportEmailButton";

interface CarrierClaimSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  freightClaimId: string;
}

export function CarrierClaimSupportEmailButton(
  props: CarrierClaimSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  const freightClaimId = props.freightClaimId;

  const input = {
    extraText,
    freightClaimId,
  };

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewCarrierClaimEmail({
        ...input,
        shipmentId,
        supportAgent,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    if (attachmentDocumentIds.length === 0) {
      message.warn(
        `No attachments. Please attach the claim/invoice/any other relevant docs`
      );
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendCarrierClaimEmail({
        ...input,
        shipmentId,
        supportAgent,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="✉️ Email Claim to Carrier"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
    />
  );
}
