import { Form, Input, Menu, message, Modal, Tag } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";

interface SetCarrierAuditTurnMenuItemProps {
  invoiceAuditCarrierFrontConversationId: string | undefined;
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function SetCarrierAuditFrontConversationIdMenuItem(
  props: SetCarrierAuditTurnMenuItemProps
) {
  const title = `Set Carrier Audit Front Conversation Id`;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [frontConversationId, setFrontConversationId] = useState<
    string | undefined
  >(props.invoiceAuditCarrierFrontConversationId);
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);
  const shipmentId = props.shipmentId;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);

    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.setInvoiceAuditCarrierFrontConversationId({
        shipmentId,
        frontConversationId,
      });
      await props.onRefresh();
      message.success("Success");
      setIsModalVisible(false);
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Front Conversation Id">
            <Input
              value={frontConversationId}
              onChange={function (e) {
                setFrontConversationId(e.target.value);
              }}
              style={{ width: "500px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>
        <Tag color="red">Advanced</Tag> {title}
      </Menu.Item>
    </>
  );
}
