/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookPickupOutput
 */
export interface BookPickupOutput {
    /**
     * 
     * @type {boolean}
     * @memberof BookPickupOutput
     */
    successfullyRan: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookPickupOutput
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof BookPickupOutput
     */
    proNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BookPickupOutput
     */
    carrierPickupNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookPickupOutput
     */
    isPickupBooked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BookPickupOutput
     */
    pickupDateChanged: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookPickupOutput
     */
    newPickupDate?: string;
}

/**
 * Check if a given object implements the BookPickupOutput interface.
 */
export function instanceOfBookPickupOutput(value: object): boolean {
    if (!('successfullyRan' in value)) return false;
    if (!('message' in value)) return false;
    if (!('isPickupBooked' in value)) return false;
    if (!('pickupDateChanged' in value)) return false;
    return true;
}

export function BookPickupOutputFromJSON(json: any): BookPickupOutput {
    return BookPickupOutputFromJSONTyped(json, false);
}

export function BookPickupOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookPickupOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'successfullyRan': json['successfullyRan'],
        'message': json['message'],
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'carrierPickupNumber': json['carrierPickupNumber'] == null ? undefined : json['carrierPickupNumber'],
        'isPickupBooked': json['isPickupBooked'],
        'pickupDateChanged': json['pickupDateChanged'],
        'newPickupDate': json['newPickupDate'] == null ? undefined : json['newPickupDate'],
    };
}

export function BookPickupOutputToJSON(value?: BookPickupOutput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'successfullyRan': value['successfullyRan'],
        'message': value['message'],
        'proNumber': value['proNumber'],
        'carrierPickupNumber': value['carrierPickupNumber'],
        'isPickupBooked': value['isPickupBooked'],
        'pickupDateChanged': value['pickupDateChanged'],
        'newPickupDate': value['newPickupDate'],
    };
}

