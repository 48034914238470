import { Button, Modal } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { Json } from "../../Components/Json";
import { ShipmentReport } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

export function ViewShipmentReportButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const [report, setReport] = useState<ShipmentReport>();

  async function showModal() {
    const shipmentApi = await createShipmentsApi();
    const response = await shipmentApi.getShipmentReportById({
      shipmentId: props.shipmentData.shipmentId,
    });
    setReport(response);
    setIsModalVisible(true);
  }

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="View Shiment Report"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Json data={report} />
      </Modal>
      <Button onClick={showModal}>View Shipment Report</Button>
    </>
  );
}
