/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CarrierInvoice,
  CarrierInvoiceApprovalType,
  CarrierInvoiceData,
  CarrierPaymentProperties,
  CarrierRequestAudit,
  CurrencyCode,
  EmailDescription,
  HistoricLineItem,
  InvoiceChallengeType,
  MismatchedInvoices,
} from '../models/index';
import {
    CarrierInvoiceFromJSON,
    CarrierInvoiceToJSON,
    CarrierInvoiceApprovalTypeFromJSON,
    CarrierInvoiceApprovalTypeToJSON,
    CarrierInvoiceDataFromJSON,
    CarrierInvoiceDataToJSON,
    CarrierPaymentPropertiesFromJSON,
    CarrierPaymentPropertiesToJSON,
    CarrierRequestAuditFromJSON,
    CarrierRequestAuditToJSON,
    CurrencyCodeFromJSON,
    CurrencyCodeToJSON,
    EmailDescriptionFromJSON,
    EmailDescriptionToJSON,
    HistoricLineItemFromJSON,
    HistoricLineItemToJSON,
    InvoiceChallengeTypeFromJSON,
    InvoiceChallengeTypeToJSON,
    MismatchedInvoicesFromJSON,
    MismatchedInvoicesToJSON,
} from '../models/index';

export interface AddCarrierInvoiceRequest {
    shipmentId: string;
    carrierIdentifier: string;
    invoiceIdentifier: string;
    amount: number;
    currency: CurrencyCode;
    isAdditional: boolean;
    body: string;
}

export interface CheckIfCarrierCreditNoteIsPaidRequest {
    carrierCreditNoteId: string;
}

export interface CheckIfCarrierInvoiceIsPaidRequest {
    carrierInvoiceId: string;
}

export interface ClearChequeSendAttemptedRequest {
    carrierInvoiceId: string;
}

export interface CreateCarrierCreditNoteRequest {
    carrierInvoiceId: string;
    amount: number;
    currency: CurrencyCode;
}

export interface CreateMissingInvoiceMondayAndEmailCarrierRequest {
    shipmentId: string;
}

export interface GetCarrierInvoiceByChequeNumberRequest {
    chequeNumber: string;
}

export interface GetCarrierRequestAuditForQuoteRequest {
    shipmentId: string;
    quoteId: string;
}

export interface GetCarrierRequestAuditForSelectedQuoteRequest {
    shipmentId: string;
}

export interface GetHistoricLineItemsRequest {
    companyId: string;
}

export interface GetPaymentPropertiesRequest {
    carrierInvoiceId: string;
}

export interface MarkCarrierCreditNoteRefundRequestedRequest {
    carrierCreditNoteId: string;
    refundRequested: boolean;
}

export interface MarkCarrierInvoiceApprovedRequest {
    carrierInvoiceId: string;
    approvalReason: string;
}

export interface MarkCarrierInvoicePaymentPendingRequest {
    carrierInvoiceId: string;
}

export interface MarkCarrierInvoiceProblemsIdentifiedRequest {
    carrierInvoiceId: string;
    problemsIdentified: boolean;
}

export interface MarkCarrierInvoiceVoidedRequest {
    carrierInvoiceId: string;
    voidReason: string;
}

export interface MarkPaymentConfirmedCarrierInvoiceVoidedRequest {
    carrierInvoiceId: string;
    reason: string;
}

export interface MarkPaymentPendingCarrierInvoiceApprovedRequest {
    carrierInvoiceId: string;
}

export interface PayCarrierInvoiceByChequeRequest {
    carrierInvoiceId: string;
}

export interface PreviewEmailForCarrierInvoiceRequest {
    carrierInvoiceId: string;
    invoiceChallengeType: InvoiceChallengeType;
    extraText: string;
}

export interface PreviewEmailForOverchargeClaimRequest {
    carrierCreditNoteId: string;
    extraText: string;
}

export interface RequestPayCarrierInvoiceRequest {
    carrierInvoiceId: string;
}

export interface RetryProcessPaymentRequest {
    carrierInvoiceId: string;
}

export interface SendEmailAskingToChargeCardOnFileForCarrierInvoiceRequest {
    carrierInvoiceId: string;
}

export interface SendOverchargeClaimEmailRequest {
    carrierCreditNoteId: string;
    extraText: string;
}

export interface VoidCarrierCreditNoteRequest {
    carrierCreditNoteId: string;
}

/**
 * 
 */
export class CarrierInvoiceApi extends runtime.BaseAPI {

    /**
     */
    async addCarrierInvoiceRaw(requestParameters: AddCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CarrierInvoiceApprovalType>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling addCarrierInvoice().'
            );
        }

        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling addCarrierInvoice().'
            );
        }

        if (requestParameters['invoiceIdentifier'] == null) {
            throw new runtime.RequiredError(
                'invoiceIdentifier',
                'Required parameter "invoiceIdentifier" was null or undefined when calling addCarrierInvoice().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling addCarrierInvoice().'
            );
        }

        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling addCarrierInvoice().'
            );
        }

        if (requestParameters['isAdditional'] == null) {
            throw new runtime.RequiredError(
                'isAdditional',
                'Required parameter "isAdditional" was null or undefined when calling addCarrierInvoice().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling addCarrierInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        if (requestParameters['invoiceIdentifier'] != null) {
            queryParameters['invoiceIdentifier'] = requestParameters['invoiceIdentifier'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['currency'] != null) {
            queryParameters['currency'] = requestParameters['currency'];
        }

        if (requestParameters['isAdditional'] != null) {
            queryParameters['isAdditional'] = requestParameters['isAdditional'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/pdf';

        const response = await this.request({
            path: `/apollo/carrier-invoice/add-carrier-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CarrierInvoiceApprovalTypeFromJSON(jsonValue));
    }

    /**
     */
    async addCarrierInvoice(requestParameters: AddCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CarrierInvoiceApprovalType> {
        const response = await this.addCarrierInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkIfCarrierCreditNoteIsPaidRaw(requestParameters: CheckIfCarrierCreditNoteIsPaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierCreditNoteId'] == null) {
            throw new runtime.RequiredError(
                'carrierCreditNoteId',
                'Required parameter "carrierCreditNoteId" was null or undefined when calling checkIfCarrierCreditNoteIsPaid().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierCreditNoteId'] != null) {
            queryParameters['carrierCreditNoteId'] = requestParameters['carrierCreditNoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/check-if-carrier-credit-note-is-paid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async checkIfCarrierCreditNoteIsPaid(requestParameters: CheckIfCarrierCreditNoteIsPaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkIfCarrierCreditNoteIsPaidRaw(requestParameters, initOverrides);
    }

    /**
     */
    async checkIfCarrierInvoiceIsPaidRaw(requestParameters: CheckIfCarrierInvoiceIsPaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling checkIfCarrierInvoiceIsPaid().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/check-if-carrier-invoice-is-paid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async checkIfCarrierInvoiceIsPaid(requestParameters: CheckIfCarrierInvoiceIsPaidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkIfCarrierInvoiceIsPaidRaw(requestParameters, initOverrides);
    }

    /**
     */
    async checkIfInvoicesArePaidRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/check-if-invoices-are-paid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async checkIfInvoicesArePaid(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkIfInvoicesArePaidRaw(initOverrides);
    }

    /**
     */
    async clearChequeSendAttemptedRaw(requestParameters: ClearChequeSendAttemptedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling clearChequeSendAttempted().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/clear-cheque-send-attempted`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clearChequeSendAttempted(requestParameters: ClearChequeSendAttemptedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearChequeSendAttemptedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createCarrierCreditNoteRaw(requestParameters: CreateCarrierCreditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling createCarrierCreditNote().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling createCarrierCreditNote().'
            );
        }

        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling createCarrierCreditNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['currency'] != null) {
            queryParameters['currency'] = requestParameters['currency'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/create-carrier-credit-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createCarrierCreditNote(requestParameters: CreateCarrierCreditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCarrierCreditNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createMissingInvoiceMondayAndEmailCarrierRaw(requestParameters: CreateMissingInvoiceMondayAndEmailCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling createMissingInvoiceMondayAndEmailCarrier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/create-missing-invoice-monday-and-email-carrier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createMissingInvoiceMondayAndEmailCarrier(requestParameters: CreateMissingInvoiceMondayAndEmailCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createMissingInvoiceMondayAndEmailCarrierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getCarrierInvoiceByChequeNumberRaw(requestParameters: GetCarrierInvoiceByChequeNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CarrierInvoice>> {
        if (requestParameters['chequeNumber'] == null) {
            throw new runtime.RequiredError(
                'chequeNumber',
                'Required parameter "chequeNumber" was null or undefined when calling getCarrierInvoiceByChequeNumber().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['chequeNumber'] != null) {
            queryParameters['chequeNumber'] = requestParameters['chequeNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/get-carrier-invoice-by-cheque-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CarrierInvoiceFromJSON(jsonValue));
    }

    /**
     */
    async getCarrierInvoiceByChequeNumber(requestParameters: GetCarrierInvoiceByChequeNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CarrierInvoice> {
        const response = await this.getCarrierInvoiceByChequeNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCarrierRequestAuditForQuoteRaw(requestParameters: GetCarrierRequestAuditForQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierRequestAudit>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getCarrierRequestAuditForQuote().'
            );
        }

        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling getCarrierRequestAuditForQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/get-carrier-request-audit-for-quote`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierRequestAuditFromJSON));
    }

    /**
     */
    async getCarrierRequestAuditForQuote(requestParameters: GetCarrierRequestAuditForQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierRequestAudit>> {
        const response = await this.getCarrierRequestAuditForQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCarrierRequestAuditForSelectedQuoteRaw(requestParameters: GetCarrierRequestAuditForSelectedQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierRequestAudit>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getCarrierRequestAuditForSelectedQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/get-carrier-request-audit-for-selected-quote`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierRequestAuditFromJSON));
    }

    /**
     */
    async getCarrierRequestAuditForSelectedQuote(requestParameters: GetCarrierRequestAuditForSelectedQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierRequestAudit>> {
        const response = await this.getCarrierRequestAuditForSelectedQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHistoricLineItemsRaw(requestParameters: GetHistoricLineItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HistoricLineItem>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getHistoricLineItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/get-historic-line-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HistoricLineItemFromJSON));
    }

    /**
     */
    async getHistoricLineItems(requestParameters: GetHistoricLineItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HistoricLineItem>> {
        const response = await this.getHistoricLineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMismatchedCarrierInvoicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MismatchedInvoices>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/get-mismatched-carrier-invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MismatchedInvoicesFromJSON(jsonValue));
    }

    /**
     */
    async getMismatchedCarrierInvoices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MismatchedInvoices> {
        const response = await this.getMismatchedCarrierInvoicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPaymentPropertiesRaw(requestParameters: GetPaymentPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CarrierPaymentProperties>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling getPaymentProperties().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/get-payment-properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CarrierPaymentPropertiesFromJSON(jsonValue));
    }

    /**
     */
    async getPaymentProperties(requestParameters: GetPaymentPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CarrierPaymentProperties> {
        const response = await this.getPaymentPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoiceChallengeTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceChallengeType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/invoice-challenge-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceChallengeTypeFromJSON(jsonValue));
    }

    /**
     */
    async invoiceChallengeTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceChallengeType> {
        const response = await this.invoiceChallengeTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async markCarrierCreditNoteRefundRequestedRaw(requestParameters: MarkCarrierCreditNoteRefundRequestedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierCreditNoteId'] == null) {
            throw new runtime.RequiredError(
                'carrierCreditNoteId',
                'Required parameter "carrierCreditNoteId" was null or undefined when calling markCarrierCreditNoteRefundRequested().'
            );
        }

        if (requestParameters['refundRequested'] == null) {
            throw new runtime.RequiredError(
                'refundRequested',
                'Required parameter "refundRequested" was null or undefined when calling markCarrierCreditNoteRefundRequested().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierCreditNoteId'] != null) {
            queryParameters['carrierCreditNoteId'] = requestParameters['carrierCreditNoteId'];
        }

        if (requestParameters['refundRequested'] != null) {
            queryParameters['refundRequested'] = requestParameters['refundRequested'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-carrier-credit-note-refund-requested`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markCarrierCreditNoteRefundRequested(requestParameters: MarkCarrierCreditNoteRefundRequestedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markCarrierCreditNoteRefundRequestedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markCarrierInvoiceApprovedRaw(requestParameters: MarkCarrierInvoiceApprovedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling markCarrierInvoiceApproved().'
            );
        }

        if (requestParameters['approvalReason'] == null) {
            throw new runtime.RequiredError(
                'approvalReason',
                'Required parameter "approvalReason" was null or undefined when calling markCarrierInvoiceApproved().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['approvalReason'] != null) {
            queryParameters['approvalReason'] = requestParameters['approvalReason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-carrier-invoice-approved`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markCarrierInvoiceApproved(requestParameters: MarkCarrierInvoiceApprovedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markCarrierInvoiceApprovedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markCarrierInvoicePaymentPendingRaw(requestParameters: MarkCarrierInvoicePaymentPendingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling markCarrierInvoicePaymentPending().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-carrier-invoice-payment-pending`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markCarrierInvoicePaymentPending(requestParameters: MarkCarrierInvoicePaymentPendingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markCarrierInvoicePaymentPendingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markCarrierInvoiceProblemsIdentifiedRaw(requestParameters: MarkCarrierInvoiceProblemsIdentifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling markCarrierInvoiceProblemsIdentified().'
            );
        }

        if (requestParameters['problemsIdentified'] == null) {
            throw new runtime.RequiredError(
                'problemsIdentified',
                'Required parameter "problemsIdentified" was null or undefined when calling markCarrierInvoiceProblemsIdentified().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['problemsIdentified'] != null) {
            queryParameters['problemsIdentified'] = requestParameters['problemsIdentified'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-carrier-invoice-problems-identified`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markCarrierInvoiceProblemsIdentified(requestParameters: MarkCarrierInvoiceProblemsIdentifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markCarrierInvoiceProblemsIdentifiedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markCarrierInvoiceVoidedRaw(requestParameters: MarkCarrierInvoiceVoidedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling markCarrierInvoiceVoided().'
            );
        }

        if (requestParameters['voidReason'] == null) {
            throw new runtime.RequiredError(
                'voidReason',
                'Required parameter "voidReason" was null or undefined when calling markCarrierInvoiceVoided().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['voidReason'] != null) {
            queryParameters['voidReason'] = requestParameters['voidReason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-carrier-invoice-voided`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markCarrierInvoiceVoided(requestParameters: MarkCarrierInvoiceVoidedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markCarrierInvoiceVoidedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markPaymentConfirmedCarrierInvoiceVoidedRaw(requestParameters: MarkPaymentConfirmedCarrierInvoiceVoidedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling markPaymentConfirmedCarrierInvoiceVoided().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling markPaymentConfirmedCarrierInvoiceVoided().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-payment-confirmed-carrier-invoice-voided`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markPaymentConfirmedCarrierInvoiceVoided(requestParameters: MarkPaymentConfirmedCarrierInvoiceVoidedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markPaymentConfirmedCarrierInvoiceVoidedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markPaymentPendingCarrierInvoiceApprovedRaw(requestParameters: MarkPaymentPendingCarrierInvoiceApprovedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling markPaymentPendingCarrierInvoiceApproved().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/mark-payment-pending-carrier-invoice-approved`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markPaymentPendingCarrierInvoiceApproved(requestParameters: MarkPaymentPendingCarrierInvoiceApprovedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markPaymentPendingCarrierInvoiceApprovedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async payCarrierInvoiceByChequeRaw(requestParameters: PayCarrierInvoiceByChequeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling payCarrierInvoiceByCheque().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/pay-carrier-invoice-by-cheque`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async payCarrierInvoiceByCheque(requestParameters: PayCarrierInvoiceByChequeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payCarrierInvoiceByChequeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async previewEmailForCarrierInvoiceRaw(requestParameters: PreviewEmailForCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling previewEmailForCarrierInvoice().'
            );
        }

        if (requestParameters['invoiceChallengeType'] == null) {
            throw new runtime.RequiredError(
                'invoiceChallengeType',
                'Required parameter "invoiceChallengeType" was null or undefined when calling previewEmailForCarrierInvoice().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewEmailForCarrierInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['invoiceChallengeType'] != null) {
            queryParameters['invoiceChallengeType'] = requestParameters['invoiceChallengeType'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/preview-email-for-carrier-invoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewEmailForCarrierInvoice(requestParameters: PreviewEmailForCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewEmailForCarrierInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewEmailForOverchargeClaimRaw(requestParameters: PreviewEmailForOverchargeClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['carrierCreditNoteId'] == null) {
            throw new runtime.RequiredError(
                'carrierCreditNoteId',
                'Required parameter "carrierCreditNoteId" was null or undefined when calling previewEmailForOverchargeClaim().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewEmailForOverchargeClaim().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierCreditNoteId'] != null) {
            queryParameters['carrierCreditNoteId'] = requestParameters['carrierCreditNoteId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/preview-email-for-overcharge-claim`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewEmailForOverchargeClaim(requestParameters: PreviewEmailForOverchargeClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewEmailForOverchargeClaimRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async requestPayCarrierInvoiceRaw(requestParameters: RequestPayCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling requestPayCarrierInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/request-pay-carrier-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestPayCarrierInvoice(requestParameters: RequestPayCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestPayCarrierInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async retryProcessPaymentRaw(requestParameters: RetryProcessPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling retryProcessPayment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/retry-process-payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async retryProcessPayment(requestParameters: RetryProcessPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.retryProcessPaymentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendEmailAskingToChargeCardOnFileForCarrierInvoiceRaw(requestParameters: SendEmailAskingToChargeCardOnFileForCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling sendEmailAskingToChargeCardOnFileForCarrierInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/send-email-asking-to-charge-card-on-file-for-carrier-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendEmailAskingToChargeCardOnFileForCarrierInvoice(requestParameters: SendEmailAskingToChargeCardOnFileForCarrierInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmailAskingToChargeCardOnFileForCarrierInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendOverchargeClaimEmailRaw(requestParameters: SendOverchargeClaimEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierCreditNoteId'] == null) {
            throw new runtime.RequiredError(
                'carrierCreditNoteId',
                'Required parameter "carrierCreditNoteId" was null or undefined when calling sendOverchargeClaimEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendOverchargeClaimEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierCreditNoteId'] != null) {
            queryParameters['carrierCreditNoteId'] = requestParameters['carrierCreditNoteId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/send-overcharge-claim-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendOverchargeClaimEmail(requestParameters: SendOverchargeClaimEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendOverchargeClaimEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async viewCarrierInvoicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CarrierInvoiceData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/view-carrier-invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CarrierInvoiceDataFromJSON(jsonValue));
    }

    /**
     */
    async viewCarrierInvoices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CarrierInvoiceData> {
        const response = await this.viewCarrierInvoicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async voidCarrierCreditNoteRaw(requestParameters: VoidCarrierCreditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierCreditNoteId'] == null) {
            throw new runtime.RequiredError(
                'carrierCreditNoteId',
                'Required parameter "carrierCreditNoteId" was null or undefined when calling voidCarrierCreditNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierCreditNoteId'] != null) {
            queryParameters['carrierCreditNoteId'] = requestParameters['carrierCreditNoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice/void-carrier-credit-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async voidCarrierCreditNote(requestParameters: VoidCarrierCreditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voidCarrierCreditNoteRaw(requestParameters, initOverrides);
    }

}
