import { message } from "antd";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { TabProps } from "./TabProps";

export function CreateMondayMissingInvoiceAndEmailCarrierMenuItem(
  props: TabProps
) {
  const shipmentId = props.shipmentData.shipmentId;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.createMissingInvoiceMondayAndEmailCarrier({
        shipmentId,
      });
      message.success("Email sent. Invoice requested.");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question="Are you sure you want to request a missing invoice from the carrier? If there isn't a monday task open for this already, this will open one and email the carrier asking for the missing invoice. You generally shouldn't need to do this as the system will automatically do it."
      okText={"Yes, request invoice"}
      tooltip={
        "If the carrier hasn't provided an invoice, this will email them asking for one. This will only work if there is no Monday task open."
      }
    >
      Request Missing Invoice
    </ConfirmMenuItem>
  );
}
