/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CarrierComparisonReportLine,
  CompanySalesReport,
  GenerateProfitLossReportFilterOptions,
  KpiGraphsReport,
  ProfitLossReport,
} from '../models/index';
import {
    CarrierComparisonReportLineFromJSON,
    CarrierComparisonReportLineToJSON,
    CompanySalesReportFromJSON,
    CompanySalesReportToJSON,
    GenerateProfitLossReportFilterOptionsFromJSON,
    GenerateProfitLossReportFilterOptionsToJSON,
    KpiGraphsReportFromJSON,
    KpiGraphsReportToJSON,
    ProfitLossReportFromJSON,
    ProfitLossReportToJSON,
} from '../models/index';

export interface GenerateCarrierComparisonReportRequest {
    carrierIdentifier: string;
}

export interface GenerateCarrierComparisonReportCsvRequest {
    carrierIdentifier: string;
}

export interface GenerateProfitLossReportRequest {
    generateProfitLossReportFilterOptions: GenerateProfitLossReportFilterOptions;
}

export interface GetTodaysBookingsRequest {
    date: string;
}

/**
 * 
 */
export class ProfitLossApi extends runtime.BaseAPI {

    /**
     */
    async generateCarrierComparisonReportRaw(requestParameters: GenerateCarrierComparisonReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierComparisonReportLine>>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling generateCarrierComparisonReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/profit-loss/generate-carrier-comparison-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierComparisonReportLineFromJSON));
    }

    /**
     */
    async generateCarrierComparisonReport(requestParameters: GenerateCarrierComparisonReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierComparisonReportLine>> {
        const response = await this.generateCarrierComparisonReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateCarrierComparisonReportCsvRaw(requestParameters: GenerateCarrierComparisonReportCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling generateCarrierComparisonReportCsv().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/profit-loss/generate-carrier-comparison-report-csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateCarrierComparisonReportCsv(requestParameters: GenerateCarrierComparisonReportCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateCarrierComparisonReportCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateKpiGraphsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiGraphsReport>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/profit-loss/generate-kpi-graphs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiGraphsReportFromJSON(jsonValue));
    }

    /**
     */
    async generateKpiGraphs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiGraphsReport> {
        const response = await this.generateKpiGraphsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async generateProfitLossReportRaw(requestParameters: GenerateProfitLossReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfitLossReport>> {
        if (requestParameters['generateProfitLossReportFilterOptions'] == null) {
            throw new runtime.RequiredError(
                'generateProfitLossReportFilterOptions',
                'Required parameter "generateProfitLossReportFilterOptions" was null or undefined when calling generateProfitLossReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/profit-loss/generate-profit-loss-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateProfitLossReportFilterOptionsToJSON(requestParameters['generateProfitLossReportFilterOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfitLossReportFromJSON(jsonValue));
    }

    /**
     */
    async generateProfitLossReport(requestParameters: GenerateProfitLossReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfitLossReport> {
        const response = await this.generateProfitLossReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTodaysBookingsRaw(requestParameters: GetTodaysBookingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfitLossReport>> {
        if (requestParameters['date'] == null) {
            throw new runtime.RequiredError(
                'date',
                'Required parameter "date" was null or undefined when calling getTodaysBookings().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/profit-loss/get-todays-bookings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfitLossReportFromJSON(jsonValue));
    }

    /**
     */
    async getTodaysBookings(requestParameters: GetTodaysBookingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfitLossReport> {
        const response = await this.getTodaysBookingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recentCompanySalesReportRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySalesReport>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/profit-loss/recent-company-sales-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySalesReportFromJSON(jsonValue));
    }

    /**
     */
    async recentCompanySalesReport(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySalesReport> {
        const response = await this.recentCompanySalesReportRaw(initOverrides);
        return await response.value();
    }

}
