/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { EmailQuoteRequestState } from './EmailQuoteRequestState';
import {
    EmailQuoteRequestStateFromJSON,
    EmailQuoteRequestStateFromJSONTyped,
    EmailQuoteRequestStateToJSON,
} from './EmailQuoteRequestState';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { RunQuoteInputLocation } from './RunQuoteInputLocation';
import {
    RunQuoteInputLocationFromJSON,
    RunQuoteInputLocationFromJSONTyped,
    RunQuoteInputLocationToJSON,
} from './RunQuoteInputLocation';

/**
 * 
 * @export
 * @interface EmailQuoteRequest
 */
export interface EmailQuoteRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    emailQuoteRequestId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    requestedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailQuoteRequest
     */
    toAddresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    extraText?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    body?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailQuoteRequest
     */
    pickupAccessorials?: Array<string>;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof EmailQuoteRequest
     */
    lineItems?: Array<LineItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailQuoteRequest
     */
    deliveryAccessorials?: Array<string>;
    /**
     * 
     * @type {RunQuoteInputLocation}
     * @memberof EmailQuoteRequest
     */
    pickupLocation?: RunQuoteInputLocation;
    /**
     * 
     * @type {RunQuoteInputLocation}
     * @memberof EmailQuoteRequest
     */
    deliveryLocation?: RunQuoteInputLocation;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    pickupLocationType?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    deliveryLocationType?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailQuoteRequest
     */
    declaredValue?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof EmailQuoteRequest
     */
    declaredValueCurrency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteRequest
     */
    serviceIdentifier?: string;
    /**
     * 
     * @type {EmailQuoteRequestState}
     * @memberof EmailQuoteRequest
     */
    state?: EmailQuoteRequestState;
    /**
     * 
     * @type {EquipmentType}
     * @memberof EmailQuoteRequest
     */
    equipmentType?: EquipmentType;
    /**
     * 
     * @type {boolean}
     * @memberof EmailQuoteRequest
     */
    exclusiveUseNeeded?: boolean;
}

/**
 * Check if a given object implements the EmailQuoteRequest interface.
 */
export function instanceOfEmailQuoteRequest(value: object): boolean {
    return true;
}

export function EmailQuoteRequestFromJSON(json: any): EmailQuoteRequest {
    return EmailQuoteRequestFromJSONTyped(json, false);
}

export function EmailQuoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailQuoteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'emailQuoteRequestId': json['emailQuoteRequestId'] == null ? undefined : json['emailQuoteRequestId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'requestedBy': json['requestedBy'] == null ? undefined : json['requestedBy'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'toAddresses': json['toAddresses'] == null ? undefined : json['toAddresses'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'extraText': json['extraText'] == null ? undefined : json['extraText'],
        'body': json['body'] == null ? undefined : json['body'],
        'pickupAccessorials': json['pickupAccessorials'] == null ? undefined : json['pickupAccessorials'],
        'lineItems': json['lineItems'] == null ? undefined : ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'deliveryAccessorials': json['deliveryAccessorials'] == null ? undefined : json['deliveryAccessorials'],
        'pickupLocation': json['pickupLocation'] == null ? undefined : RunQuoteInputLocationFromJSON(json['pickupLocation']),
        'deliveryLocation': json['deliveryLocation'] == null ? undefined : RunQuoteInputLocationFromJSON(json['deliveryLocation']),
        'pickupLocationType': json['pickupLocationType'] == null ? undefined : json['pickupLocationType'],
        'deliveryLocationType': json['deliveryLocationType'] == null ? undefined : json['deliveryLocationType'],
        'declaredValue': json['declaredValue'] == null ? undefined : json['declaredValue'],
        'declaredValueCurrency': json['declaredValueCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['declaredValueCurrency']),
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
        'state': json['state'] == null ? undefined : EmailQuoteRequestStateFromJSON(json['state']),
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
        'exclusiveUseNeeded': json['exclusiveUseNeeded'] == null ? undefined : json['exclusiveUseNeeded'],
    };
}

export function EmailQuoteRequestToJSON(value?: EmailQuoteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailQuoteRequestId': value['emailQuoteRequestId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'requestedBy': value['requestedBy'],
        'carrierIdentifier': value['carrierIdentifier'],
        'toAddresses': value['toAddresses'],
        'subject': value['subject'],
        'extraText': value['extraText'],
        'body': value['body'],
        'pickupAccessorials': value['pickupAccessorials'],
        'lineItems': value['lineItems'] == null ? undefined : ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'deliveryAccessorials': value['deliveryAccessorials'],
        'pickupLocation': RunQuoteInputLocationToJSON(value['pickupLocation']),
        'deliveryLocation': RunQuoteInputLocationToJSON(value['deliveryLocation']),
        'pickupLocationType': value['pickupLocationType'],
        'deliveryLocationType': value['deliveryLocationType'],
        'declaredValue': value['declaredValue'],
        'declaredValueCurrency': CurrencyCodeToJSON(value['declaredValueCurrency']),
        'serviceIdentifier': value['serviceIdentifier'],
        'state': EmailQuoteRequestStateToJSON(value['state']),
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'exclusiveUseNeeded': value['exclusiveUseNeeded'],
    };
}

