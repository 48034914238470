import { Menu, message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ShipmentState } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

export function MarkPickupConfirmedMenuItem(props: TabProps) {
  const createShipmentApi = useShipmentsApi();

  if (
    props.shipmentData.shipment.shipment.state !==
    ShipmentState.BookingConfirmed
  ) {
    return <></>;
  }

  async function markPickupBooked() {
    const shipmentApi = await createShipmentApi();
    const shipmentId = props.shipmentData.shipmentId;
    try {
      await shipmentApi.markPickupBooked({
        shipmentId,
      });
      await props.onRefresh();

      message.success(
        props.shipmentData.shipment.shipment.deleted ? "Undeleted" : "Deleted"
      );
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return <Menu.Item onClick={markPickupBooked}>Mark Pickup Booked</Menu.Item>;
}
