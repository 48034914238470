/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MissingInvoiceComparisonDay = {
    Pickup: 'Pickup',
    Delivery: 'Delivery'
} as const;
export type MissingInvoiceComparisonDay = typeof MissingInvoiceComparisonDay[keyof typeof MissingInvoiceComparisonDay];


export function instanceOfMissingInvoiceComparisonDay(value: any): boolean {
    return Object.values(MissingInvoiceComparisonDay).includes(value);
}

export function MissingInvoiceComparisonDayFromJSON(json: any): MissingInvoiceComparisonDay {
    return MissingInvoiceComparisonDayFromJSONTyped(json, false);
}

export function MissingInvoiceComparisonDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingInvoiceComparisonDay {
    return json as MissingInvoiceComparisonDay;
}

export function MissingInvoiceComparisonDayToJSON(value?: MissingInvoiceComparisonDay | null): any {
    return value as any;
}

