/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSalesContactInput
 */
export interface CreateSalesContactInput {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    phoneExtension: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    mobileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactInput
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSalesContactInput
     */
    makePrimary: boolean;
}

/**
 * Check if a given object implements the CreateSalesContactInput interface.
 */
export function instanceOfCreateSalesContactInput(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('phoneNumber' in value)) return false;
    if (!('phoneExtension' in value)) return false;
    if (!('mobileNumber' in value)) return false;
    if (!('email' in value)) return false;
    if (!('description' in value)) return false;
    if (!('makePrimary' in value)) return false;
    return true;
}

export function CreateSalesContactInputFromJSON(json: any): CreateSalesContactInput {
    return CreateSalesContactInputFromJSONTyped(json, false);
}

export function CreateSalesContactInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSalesContactInput {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'name': json['name'],
        'phoneNumber': json['phoneNumber'],
        'phoneExtension': json['phoneExtension'],
        'mobileNumber': json['mobileNumber'],
        'email': json['email'],
        'description': json['description'],
        'makePrimary': json['makePrimary'],
    };
}

export function CreateSalesContactInputToJSON(value?: CreateSalesContactInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'name': value['name'],
        'phoneNumber': value['phoneNumber'],
        'phoneExtension': value['phoneExtension'],
        'mobileNumber': value['mobileNumber'],
        'email': value['email'],
        'description': value['description'],
        'makePrimary': value['makePrimary'],
    };
}

