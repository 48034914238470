/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * No query to get these. It's simply the text value
 * @export
 */
export const MondayStatus = {
    Empty: 'Empty',
    New: 'New',
    WorkingOnIt: 'WorkingOnIt',
    Stuck: 'Stuck',
    WaitingForCarrier: 'WaitingForCarrier',
    WaitingForCustomer: 'WaitingForCustomer',
    WaitingForVerify: 'WaitingForVerify',
    Escalate: 'Escalate',
    Escalated: 'Escalated',
    NoResponseFromCarrier: 'NoResponseFromCarrier',
    NewResponseFromCarrier: 'NewResponseFromCarrier',
    Monitoring: 'Monitoring',
    AdditionalChargeConfirmation: 'AdditionalChargeConfirmation',
    NeedToSubmitClaim: 'NeedToSubmitClaim',
    WaitingForClaim: 'WaitingForClaim',
    NoClaimNumber: 'NoClaimNumber',
    WillNotPay: 'WillNotPay',
    Done: 'Done'
} as const;
export type MondayStatus = typeof MondayStatus[keyof typeof MondayStatus];


export function instanceOfMondayStatus(value: any): boolean {
    return Object.values(MondayStatus).includes(value);
}

export function MondayStatusFromJSON(json: any): MondayStatus {
    return MondayStatusFromJSONTyped(json, false);
}

export function MondayStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MondayStatus {
    return json as MondayStatus;
}

export function MondayStatusToJSON(value?: MondayStatus | null): any {
    return value as any;
}

