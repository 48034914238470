import { Button, Form, message, Modal, Typography } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import HorizontalStack from "../../Components/HorizontalStack";
import { LinkButton } from "../../Components/LinkButton";
import Stack from "../../Components/Stack";
import { CurrencyCode } from "../../generated-openapi-client";
import { useCopyToClipboard } from "../../Hooks/useCopyToClipboard";
import Spacer from "../../Spacer";
import { CurrencySelector } from "../CurrencySelector";
import { TabProps } from "./TabProps";

const { Text } = Typography;

export function CalculateAdditionalChargeButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCode>(CurrencyCode.Cad);
  const [result, setResult] = useState("");

  const shipmentId = props.shipmentData.shipmentId;
  const onCopy = useCopyToClipboard(result || "");

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function onCalculate() {
    if (currency === undefined) {
      message.warn("Missing currency");
      return;
    }
    if (amount === 0) {
      message.warn("Missing amount");
      return;
    }
    const customerInvoiceApi = await createCustomerInvoiceApi();

    const _result = await customerInvoiceApi.calculateAdditionalCharge({
      amount,
      currency,
      shipmentId,
    });
    setResult(
      `$${_result} ${props.shipmentData.shipment.company.currency} (Including Taxes)`
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Calculate Additional Charge"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Ok"
        width={800}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            Use this when a carrier tells you there will be an additional charge
            for a particular service, and you want to quickly quote a number to
            the customer. eg Carrier says liftgate will be $60 USD.
          </Text>
          <Spacer height={32} />
          <Form
            style={{ width: "1000px" }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Carrier Amount">
              <CurrencyInput
                amount={amount}
                setAmount={setAmount}
                currency={currency}
              />
            </Form.Item>
            <Form.Item label="Invoice Currency">
              <CurrencySelector currency={currency} setCurrency={setCurrency} />
            </Form.Item>
            <Form.Item
              label={
                <LinkButton onClick={onCalculate}>
                  Calculate Additional Charge
                </LinkButton>
              }
            >
              <HorizontalStack>
                <div>{result}</div>
                <Spacer width={16} />
                <LinkButton onClick={onCopy}>Copy to clipboard</LinkButton>
              </HorizontalStack>
            </Form.Item>
          </Form>
        </Stack>
      </Modal>
      <Button onClick={showModal}>🧮 Calculate Additional Charge</Button>
    </>
  );
}
