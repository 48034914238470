/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdditionalChargeState } from './AdditionalChargeState';
import {
    AdditionalChargeStateFromJSON,
    AdditionalChargeStateFromJSONTyped,
    AdditionalChargeStateToJSON,
} from './AdditionalChargeState';
import type { AdditionalChargeWaivedCause } from './AdditionalChargeWaivedCause';
import {
    AdditionalChargeWaivedCauseFromJSON,
    AdditionalChargeWaivedCauseFromJSONTyped,
    AdditionalChargeWaivedCauseToJSON,
} from './AdditionalChargeWaivedCause';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 * 
 * @export
 * @interface AdditionalCharge
 */
export interface AdditionalCharge {
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    additionalChargeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    companyId?: string;
    /**
     * 
     * @type {AdditionalChargeState}
     * @memberof AdditionalCharge
     */
    state?: AdditionalChargeState;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    waivedReason?: string;
    /**
     * 
     * @type {AdditionalChargeWaivedCause}
     * @memberof AdditionalCharge
     */
    waivedCause?: AdditionalChargeWaivedCause;
    /**
     * 
     * @type {number}
     * @memberof AdditionalCharge
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    approvedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    waivedBy?: string;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof AdditionalCharge
     */
    currency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    customerInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    calculationExplanation?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalCharge
     */
    associatedInvoiceAuditProblemIds?: Array<string>;
}

/**
 * Check if a given object implements the AdditionalCharge interface.
 */
export function instanceOfAdditionalCharge(value: object): boolean {
    return true;
}

export function AdditionalChargeFromJSON(json: any): AdditionalCharge {
    return AdditionalChargeFromJSONTyped(json, false);
}

export function AdditionalChargeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalCharge {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalChargeId': json['additionalChargeId'] == null ? undefined : json['additionalChargeId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'state': json['state'] == null ? undefined : AdditionalChargeStateFromJSON(json['state']),
        'reason': json['reason'] == null ? undefined : json['reason'],
        'waivedReason': json['waivedReason'] == null ? undefined : json['waivedReason'],
        'waivedCause': json['waivedCause'] == null ? undefined : AdditionalChargeWaivedCauseFromJSON(json['waivedCause']),
        'amount': json['amount'] == null ? undefined : json['amount'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'approvedBy': json['approvedBy'] == null ? undefined : json['approvedBy'],
        'waivedBy': json['waivedBy'] == null ? undefined : json['waivedBy'],
        'currency': json['currency'] == null ? undefined : CurrencyCodeFromJSON(json['currency']),
        'customerInvoiceId': json['customerInvoiceId'] == null ? undefined : json['customerInvoiceId'],
        'calculationExplanation': json['calculationExplanation'] == null ? undefined : json['calculationExplanation'],
        'associatedInvoiceAuditProblemIds': json['associatedInvoiceAuditProblemIds'] == null ? undefined : json['associatedInvoiceAuditProblemIds'],
    };
}

export function AdditionalChargeToJSON(value?: AdditionalCharge | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'additionalChargeId': value['additionalChargeId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'companyId': value['companyId'],
        'state': AdditionalChargeStateToJSON(value['state']),
        'reason': value['reason'],
        'waivedReason': value['waivedReason'],
        'waivedCause': AdditionalChargeWaivedCauseToJSON(value['waivedCause']),
        'amount': value['amount'],
        'createdBy': value['createdBy'],
        'approvedBy': value['approvedBy'],
        'waivedBy': value['waivedBy'],
        'currency': CurrencyCodeToJSON(value['currency']),
        'customerInvoiceId': value['customerInvoiceId'],
        'calculationExplanation': value['calculationExplanation'],
        'associatedInvoiceAuditProblemIds': value['associatedInvoiceAuditProblemIds'],
    };
}

