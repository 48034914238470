import { ShipmentDocumentSource } from "../../generated-openapi-client/models/ShipmentDocumentSource";

function describeShipmentDocumentSource(source: ShipmentDocumentSource) {
  switch (source) {
    case ShipmentDocumentSource.ManuallyUploaded:
      return "Manually Uploaded";
    case ShipmentDocumentSource.EmailAttachment:
      return "Email Attachment";
    case ShipmentDocumentSource.Api:
      return "API";
    case ShipmentDocumentSource.WebsiteScraped:
      return "Website Scraped";
  }
}

interface DocumentSourceTagProps {
  source: ShipmentDocumentSource;
}

export function DocumentSourceTag(props: DocumentSourceTagProps) {
  return <div>{describeShipmentDocumentSource(props.source)}</div>;
}
