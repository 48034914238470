import { message } from "antd";
import { useEffect, useState } from "react";
import { useTechnicalApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { ConfirmButton } from "../Components/ConfirmButton";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { EmailTemplate, EmailTemplateImage } from "../generated-openapi-client";
import {
  AddEmailTemplateButton,
  describeEmailTemplateGroup,
} from "./AddEmailTemplateButton";
import { AddEmailTemplateImageButton } from "./AddEmailTemplateImageButton";

interface EmailTemplatesTableProps {
  emailTemplates: EmailTemplate[];
  onRefresh: () => Promise<void>;
  images: EmailTemplateImage[];
}

export function EmailTemplatesTable(props: EmailTemplatesTableProps) {
  const columns: DataTableColumn<EmailTemplate>[] = [];

  columns.push({
    title: "Title",
    render: (o) => <div>{o.title}</div>,
  });

  columns.push({
    title: "Subject",
    render: (o) => <div>{o.subject}</div>,
  });

  columns.push({
    title: "Group",
    render: (o) => (
      <div>{describeEmailTemplateGroup(o.emailTemplateGroup!!)}</div>
    ),
  });

  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <AddEmailTemplateButton
          images={props.images}
          existingEmailTemplate={o}
          onRefresh={props.onRefresh}
        />
        <DeleteEmailTemplateButton
          emailTemplateId={o.emailTemplateId!!}
          onRefresh={props.onRefresh}
        />
      </ButtonRow>
    ),
  });

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.emailTemplates}
    />
  );
}

interface EmailTemplateImagesTableProps {
  emailTemplateImages: EmailTemplateImage[];
  onRefresh: () => Promise<void>;
}

interface DeleteEmailTemplateImageButtonProps {
  emailTemplateImageId: string;
  onRefresh: () => Promise<void>;
}

function DeleteEmailTemplateImageButton(
  props: DeleteEmailTemplateImageButtonProps
) {
  const createTechnicalApi = useTechnicalApi();
  const emailTemplateImageId = props.emailTemplateImageId;

  async function onConfirm() {
    setLoading(true);
    try {
      const technicalApi = await createTechnicalApi();
      await technicalApi.deleteEmailTemplateImage({
        emailTemplateImageId,
      });
      await props.onRefresh();
      message.success(`Deleted!`);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const [loading, setLoading] = useState(false);
  return (
    <ConfirmButton
      type="default"
      loading={loading}
      tooltip="Will delete the image"
      okText="Yes, do it."
      onConfirm={onConfirm}
      question="Are you sure you delete this image"
    >
      Delete
    </ConfirmButton>
  );
}

interface DeleteEmailTemplateButtonProps {
  emailTemplateId: string;
  onRefresh: () => Promise<void>;
}

function DeleteEmailTemplateButton(props: DeleteEmailTemplateButtonProps) {
  const createTechnicalApi = useTechnicalApi();
  const emailTemplateId = props.emailTemplateId;

  async function onConfirm() {
    setLoading(true);
    try {
      const technicalApi = await createTechnicalApi();
      await technicalApi.deleteEmailTemplate({
        emailTemplateId,
      });
      await props.onRefresh();
      message.success(`Deleted!`);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const [loading, setLoading] = useState(false);
  return (
    <ConfirmButton
      type="default"
      loading={loading}
      tooltip="Will delete the template"
      okText="Yes, do it."
      onConfirm={onConfirm}
      question="Are you sure you delete this template"
    >
      Delete
    </ConfirmButton>
  );
}

export function EmailTemplateImagesTable(props: EmailTemplateImagesTableProps) {
  const columns: DataTableColumn<EmailTemplateImage>[] = [];

  columns.push({
    title: "Title",
    render: (o) => <div>{o.title}</div>,
  });

  columns.push({
    title: "URL",
    render: (o) => (
      <Stack align="left">
        {" "}
        <div>{o.url}</div>
        <img src={o.url} style={{ width: "400px", marginTop: "10px" }} />
      </Stack>
    ),
  });

  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <DeleteEmailTemplateImageButton
          emailTemplateImageId={o.emailTemplateImageId!!}
          onRefresh={props.onRefresh}
        />
      </ButtonRow>
    ),
  });

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.emailTemplateImages}
    />
  );
}

export function EmailTemplatesScreen() {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>();
  const [emailTemplateImages, setEmailTemplateImages] =
    useState<EmailTemplateImage[]>();
  const [errorHappened, setErrorHappened] = useState(false);
  const createTechnicalApi = useTechnicalApi();

  async function refresh() {
    try {
      const technicalApi = await createTechnicalApi();
      const response = await technicalApi.getEmailTemplates();
      setEmailTemplates(response.emailTemplates);
      setEmailTemplateImages(response.emailTemplateImages);
    } catch (e) {
      message.error(`Something went wrong : ${e}`);
      setErrorHappened(true);
    }
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Page
        title={`Email Templates`}
        tags={[]}
        stats={[]}
        extra={[
          <ButtonRow>
            {emailTemplateImages && (
              <AddEmailTemplateButton
                onRefresh={refresh}
                images={emailTemplateImages}
              />
            )}
            <AddEmailTemplateImageButton onRefresh={refresh} />
          </ButtonRow>,
        ]}
      >
        <PageTitle>{`Email Templates`}</PageTitle>
        {errorHappened && <div>❌ Ooops error happened</div>}
        {emailTemplateImages !== undefined && emailTemplates !== undefined && (
          <>
            <h1 style={{ marginTop: "24px" }}>Templates</h1>
            <EmailTemplatesTable
              images={emailTemplateImages}
              emailTemplates={emailTemplates}
              onRefresh={refresh}
            />
          </>
        )}
        {emailTemplateImages !== undefined && (
          <>
            <h1 style={{ marginTop: "24px" }}>Images</h1>
            <EmailTemplateImagesTable
              emailTemplateImages={emailTemplateImages}
              onRefresh={refresh}
            />
          </>
        )}
      </Page>
    </>
  );
}
