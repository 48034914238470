/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierInvoiceApprovalMethod } from './CarrierInvoiceApprovalMethod';
import {
    CarrierInvoiceApprovalMethodFromJSON,
    CarrierInvoiceApprovalMethodFromJSONTyped,
    CarrierInvoiceApprovalMethodToJSON,
} from './CarrierInvoiceApprovalMethod';
import type { CarrierInvoiceApprovalType } from './CarrierInvoiceApprovalType';
import {
    CarrierInvoiceApprovalTypeFromJSON,
    CarrierInvoiceApprovalTypeFromJSONTyped,
    CarrierInvoiceApprovalTypeToJSON,
} from './CarrierInvoiceApprovalType';
import type { CarrierInvoiceState } from './CarrierInvoiceState';
import {
    CarrierInvoiceStateFromJSON,
    CarrierInvoiceStateFromJSONTyped,
    CarrierInvoiceStateToJSON,
} from './CarrierInvoiceState';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 * 
 * @export
 * @interface CarrierInvoice
 */
export interface CarrierInvoice {
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    carrierInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    shipmentId?: string;
    /**
     * 
     * @type {number}
     * @memberof CarrierInvoice
     */
    amount?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof CarrierInvoice
     */
    currency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    s3Key?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    contentHash?: string;
    /**
     * 
     * @type {CarrierInvoiceState}
     * @memberof CarrierInvoice
     */
    invoiceState?: CarrierInvoiceState;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    xeroInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    mondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    invoiceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    paymentMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    source?: string;
    /**
     * 
     * @type {CarrierInvoiceApprovalMethod}
     * @memberof CarrierInvoice
     */
    approvalMethod?: CarrierInvoiceApprovalMethod;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    approvalReason?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    approverEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    approvedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    paymentPendingTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    paymentConfirmedTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierInvoice
     */
    chequeSendAttempted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierInvoice
     */
    chequeSent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarrierInvoice
     */
    chequeBatchId?: number;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof CarrierInvoice
     */
    chequeErrorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeCompany?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeSendBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierInvoice
     */
    chequeUploadStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierInvoice
     */
    includesTax?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarrierInvoice
     */
    amountWithoutTax?: number;
    /**
     * 
     * @type {CarrierInvoiceApprovalType}
     * @memberof CarrierInvoice
     */
    approvalType?: CarrierInvoiceApprovalType;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierInvoice
     */
    problemsIdentified?: boolean;
}

/**
 * Check if a given object implements the CarrierInvoice interface.
 */
export function instanceOfCarrierInvoice(value: object): boolean {
    return true;
}

export function CarrierInvoiceFromJSON(json: any): CarrierInvoice {
    return CarrierInvoiceFromJSONTyped(json, false);
}

export function CarrierInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInvoice {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierInvoiceId': json['carrierInvoiceId'] == null ? undefined : json['carrierInvoiceId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'currency': json['currency'] == null ? undefined : CurrencyCodeFromJSON(json['currency']),
        's3Key': json['s3Key'] == null ? undefined : json['s3Key'],
        'contentHash': json['contentHash'] == null ? undefined : json['contentHash'],
        'invoiceState': json['invoiceState'] == null ? undefined : CarrierInvoiceStateFromJSON(json['invoiceState']),
        'xeroInvoiceId': json['xeroInvoiceId'] == null ? undefined : json['xeroInvoiceId'],
        'mondayId': json['mondayId'] == null ? undefined : json['mondayId'],
        'invoiceIdentifier': json['invoiceIdentifier'] == null ? undefined : json['invoiceIdentifier'],
        'paymentMondayId': json['paymentMondayId'] == null ? undefined : json['paymentMondayId'],
        'source': json['source'] == null ? undefined : json['source'],
        'approvalMethod': json['approvalMethod'] == null ? undefined : CarrierInvoiceApprovalMethodFromJSON(json['approvalMethod']),
        'approvalReason': json['approvalReason'] == null ? undefined : json['approvalReason'],
        'approverEmail': json['approverEmail'] == null ? undefined : json['approverEmail'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'approvedTime': json['approvedTime'] == null ? undefined : json['approvedTime'],
        'paymentPendingTime': json['paymentPendingTime'] == null ? undefined : json['paymentPendingTime'],
        'paymentConfirmedTime': json['paymentConfirmedTime'] == null ? undefined : json['paymentConfirmedTime'],
        'chequeSendAttempted': json['chequeSendAttempted'] == null ? undefined : json['chequeSendAttempted'],
        'chequeSent': json['chequeSent'] == null ? undefined : json['chequeSent'],
        'chequeBatchId': json['chequeBatchId'] == null ? undefined : json['chequeBatchId'],
        'chequeNumber': json['chequeNumber'] == null ? undefined : json['chequeNumber'],
        'chequeErrorCode': json['chequeErrorCode'] == null ? undefined : json['chequeErrorCode'],
        'chequeErrorMessage': json['chequeErrorMessage'] == null ? undefined : json['chequeErrorMessage'],
        'chequeCompany': json['chequeCompany'] == null ? undefined : json['chequeCompany'],
        'chequeAccountNumber': json['chequeAccountNumber'] == null ? undefined : json['chequeAccountNumber'],
        'chequeAddress': json['chequeAddress'] == null ? undefined : json['chequeAddress'],
        'chequeTransactionId': json['chequeTransactionId'] == null ? undefined : json['chequeTransactionId'],
        'chequeAmount': json['chequeAmount'] == null ? undefined : json['chequeAmount'],
        'chequeSendBy': json['chequeSendBy'] == null ? undefined : json['chequeSendBy'],
        'chequeUploadStatus': json['chequeUploadStatus'] == null ? undefined : json['chequeUploadStatus'],
        'includesTax': json['includesTax'] == null ? undefined : json['includesTax'],
        'amountWithoutTax': json['amountWithoutTax'] == null ? undefined : json['amountWithoutTax'],
        'approvalType': json['approvalType'] == null ? undefined : CarrierInvoiceApprovalTypeFromJSON(json['approvalType']),
        'problemsIdentified': json['problemsIdentified'] == null ? undefined : json['problemsIdentified'],
    };
}

export function CarrierInvoiceToJSON(value?: CarrierInvoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierInvoiceId': value['carrierInvoiceId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'amount': value['amount'],
        'currency': CurrencyCodeToJSON(value['currency']),
        's3Key': value['s3Key'],
        'contentHash': value['contentHash'],
        'invoiceState': CarrierInvoiceStateToJSON(value['invoiceState']),
        'xeroInvoiceId': value['xeroInvoiceId'],
        'mondayId': value['mondayId'],
        'invoiceIdentifier': value['invoiceIdentifier'],
        'paymentMondayId': value['paymentMondayId'],
        'source': value['source'],
        'approvalMethod': CarrierInvoiceApprovalMethodToJSON(value['approvalMethod']),
        'approvalReason': value['approvalReason'],
        'approverEmail': value['approverEmail'],
        'carrierIdentifier': value['carrierIdentifier'],
        'approvedTime': value['approvedTime'],
        'paymentPendingTime': value['paymentPendingTime'],
        'paymentConfirmedTime': value['paymentConfirmedTime'],
        'chequeSendAttempted': value['chequeSendAttempted'],
        'chequeSent': value['chequeSent'],
        'chequeBatchId': value['chequeBatchId'],
        'chequeNumber': value['chequeNumber'],
        'chequeErrorCode': value['chequeErrorCode'],
        'chequeErrorMessage': value['chequeErrorMessage'],
        'chequeCompany': value['chequeCompany'],
        'chequeAccountNumber': value['chequeAccountNumber'],
        'chequeAddress': value['chequeAddress'],
        'chequeTransactionId': value['chequeTransactionId'],
        'chequeAmount': value['chequeAmount'],
        'chequeSendBy': value['chequeSendBy'],
        'chequeUploadStatus': value['chequeUploadStatus'],
        'includesTax': value['includesTax'],
        'amountWithoutTax': value['amountWithoutTax'],
        'approvalType': CarrierInvoiceApprovalTypeToJSON(value['approvalType']),
        'problemsIdentified': value['problemsIdentified'],
    };
}

