/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AutochargeFrequency = {
    Daily: 'daily',
    Weekly: 'weekly'
} as const;
export type AutochargeFrequency = typeof AutochargeFrequency[keyof typeof AutochargeFrequency];


export function instanceOfAutochargeFrequency(value: any): boolean {
    return Object.values(AutochargeFrequency).includes(value);
}

export function AutochargeFrequencyFromJSON(json: any): AutochargeFrequency {
    return AutochargeFrequencyFromJSONTyped(json, false);
}

export function AutochargeFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutochargeFrequency {
    return json as AutochargeFrequency;
}

export function AutochargeFrequencyToJSON(value?: AutochargeFrequency | null): any {
    return value as any;
}

