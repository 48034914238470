import { ErrorInfo, ReactNode, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

interface ErrorBoundaryViewProps {
  children: ReactNode;
}

function generateFallbackRender(
  error: Error | undefined,
  errorInfo: ErrorInfo | undefined
) {
  return function fallbackRender() {
    if (error && errorInfo) {
      return (
        <div role="alert">
          <p>Error Message:</p>
          <pre style={{ color: "red" }}>{error.message}</pre>

          <p>Error Stack:</p>
          <pre style={{ color: "red" }}>{error.stack}</pre>

          <p>Component Stack:</p>
          <pre style={{ color: "red" }}>{errorInfo.componentStack}</pre>
        </div>
      );
    } else {
      return <div>Error but missing info</div>;
    }
  };
}

export function ErrorBoundaryView(props: ErrorBoundaryViewProps) {
  const [error, setError] = useState<Error>();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>();

  const fallbackRender = generateFallbackRender(error, errorInfo);

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onError={function (_error, _errorInfo) {
        setErrorInfo(_errorInfo);
        setError(_error);
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
