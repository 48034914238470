/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProNumberHistory
 */
export interface ProNumberHistory {
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    proNumberHistoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    proNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof ProNumberHistory
     */
    adminEmail?: string;
}

/**
 * Check if a given object implements the ProNumberHistory interface.
 */
export function instanceOfProNumberHistory(value: object): boolean {
    return true;
}

export function ProNumberHistoryFromJSON(json: any): ProNumberHistory {
    return ProNumberHistoryFromJSONTyped(json, false);
}

export function ProNumberHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProNumberHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'proNumberHistoryId': json['proNumberHistoryId'] == null ? undefined : json['proNumberHistoryId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'reason': json['reason'] == null ? undefined : json['reason'],
        'adminEmail': json['adminEmail'] == null ? undefined : json['adminEmail'],
    };
}

export function ProNumberHistoryToJSON(value?: ProNumberHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proNumberHistoryId': value['proNumberHistoryId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'proNumber': value['proNumber'],
        'reason': value['reason'],
        'adminEmail': value['adminEmail'],
    };
}

