/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ServiceProperties } from './ServiceProperties';
import {
    ServicePropertiesFromJSON,
    ServicePropertiesFromJSONTyped,
    ServicePropertiesToJSON,
} from './ServiceProperties';

/**
 * 
 * @export
 * @interface CarrierInfoResponseService
 */
export interface CarrierInfoResponseService {
    /**
     * 
     * @type {string}
     * @memberof CarrierInfoResponseService
     */
    serviceIdentifier: string;
    /**
     * 
     * @type {ServiceProperties}
     * @memberof CarrierInfoResponseService
     */
    serviceProperties: ServiceProperties;
}

/**
 * Check if a given object implements the CarrierInfoResponseService interface.
 */
export function instanceOfCarrierInfoResponseService(value: object): boolean {
    if (!('serviceIdentifier' in value)) return false;
    if (!('serviceProperties' in value)) return false;
    return true;
}

export function CarrierInfoResponseServiceFromJSON(json: any): CarrierInfoResponseService {
    return CarrierInfoResponseServiceFromJSONTyped(json, false);
}

export function CarrierInfoResponseServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInfoResponseService {
    if (json == null) {
        return json;
    }
    return {
        
        'serviceIdentifier': json['serviceIdentifier'],
        'serviceProperties': ServicePropertiesFromJSON(json['serviceProperties']),
    };
}

export function CarrierInfoResponseServiceToJSON(value?: CarrierInfoResponseService | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'serviceIdentifier': value['serviceIdentifier'],
        'serviceProperties': ServicePropertiesToJSON(value['serviceProperties']),
    };
}

