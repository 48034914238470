import { Button, message } from "antd";
import { useState } from "react";

interface DownloadCsvButtonProps {
  title: string;
  filename: string;
  callback: () => Promise<string>;
}

export function DownloadCsvButton(props: DownloadCsvButtonProps) {
  const [loading, setLoading] = useState(false);
  async function onClick() {
    try {
      setLoading(true);
      const s = await props.callback();
      const linkSource = `data:text/csv;charset=utf-8,${encodeURIComponent(s)}`;
      const downloadLink = document.createElement("a");
      const fileName = props.filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setLoading(false);
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
      setLoading(false);
    }
  }
  return (
    <Button loading={loading} onClick={onClick}>
      {props.title}
    </Button>
  );
}
