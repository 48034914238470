/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailQuoteRequest } from './EmailQuoteRequest';
import {
    EmailQuoteRequestFromJSON,
    EmailQuoteRequestFromJSONTyped,
    EmailQuoteRequestToJSON,
} from './EmailQuoteRequest';
import type { EmailQuoteResponse } from './EmailQuoteResponse';
import {
    EmailQuoteResponseFromJSON,
    EmailQuoteResponseFromJSONTyped,
    EmailQuoteResponseToJSON,
} from './EmailQuoteResponse';

/**
 * 
 * @export
 * @interface EmailQuoteRequestAndResponses
 */
export interface EmailQuoteRequestAndResponses {
    /**
     * 
     * @type {EmailQuoteRequest}
     * @memberof EmailQuoteRequestAndResponses
     */
    request: EmailQuoteRequest;
    /**
     * 
     * @type {Array<EmailQuoteResponse>}
     * @memberof EmailQuoteRequestAndResponses
     */
    responses: Array<EmailQuoteResponse>;
}

/**
 * Check if a given object implements the EmailQuoteRequestAndResponses interface.
 */
export function instanceOfEmailQuoteRequestAndResponses(value: object): boolean {
    if (!('request' in value)) return false;
    if (!('responses' in value)) return false;
    return true;
}

export function EmailQuoteRequestAndResponsesFromJSON(json: any): EmailQuoteRequestAndResponses {
    return EmailQuoteRequestAndResponsesFromJSONTyped(json, false);
}

export function EmailQuoteRequestAndResponsesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailQuoteRequestAndResponses {
    if (json == null) {
        return json;
    }
    return {
        
        'request': EmailQuoteRequestFromJSON(json['request']),
        'responses': ((json['responses'] as Array<any>).map(EmailQuoteResponseFromJSON)),
    };
}

export function EmailQuoteRequestAndResponsesToJSON(value?: EmailQuoteRequestAndResponses | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'request': EmailQuoteRequestToJSON(value['request']),
        'responses': ((value['responses'] as Array<any>).map(EmailQuoteResponseToJSON)),
    };
}

