import { Alert, Button, Popover } from "antd";
import moment from "moment";
import { Coupon } from "../generated-openapi-client";
import { capitalizeFirstLetter } from "../Helpers/capitalizeFirstLetter";

export function UsedCouponsAlert({ coupons }: { coupons: Coupon[] }) {
  function readableDate(dateStr: string | undefined) {
    if (dateStr === undefined) {
      return "";
    }
    return moment(dateStr).format("D MMMM YYYY");
  }

  if (coupons.length < 1) return <></>;

  return (
    <Alert
      message="Applied Coupons"
      description={`${
        coupons.length
      } coupons were applied with a total value of $${coupons.reduce(
        (sum, coupon) => sum + (coupon.amount || 0),
        0
      )}`}
      type="info"
      showIcon
      closable
      action={
        <Popover
          placement="bottomRight"
          trigger="click"
          content={
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "10px",
                rowGap: "10px",
              }}
            >
              {coupons.map((c) => (
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    width: "300px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "28pt" }}>${c.amount}</span>
                    <span style={{ fontWeight: "bold" }}>
                      {capitalizeFirstLetter(c.couponState)}
                    </span>
                  </div>
                  <div>
                    <div>
                      <span style={{ color: "gray" }}>Created at</span>{" "}
                      {readableDate(c.createdAt)}
                    </div>
                    <div>
                      <span style={{ color: "gray" }}>By </span>
                      {c.createdBy}
                    </div>
                    <div>
                      <span style={{ color: "gray" }}>Expires at</span>{" "}
                      {readableDate(c.expires)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
        >
          <Button type="primary">Detail</Button>
        </Popover>
      }
      style={{ marginBottom: "15px" }}
    />
  );
}
