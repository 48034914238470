import { Form, message, Switch, Tabs, Tag } from "antd";
import Search from "antd/lib/input/Search";
import TabPane from "antd/lib/tabs/TabPane";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import { Loading } from "../../Components/Loading";
import { Percentage } from "../../Components/Percentage";
import { ShipmentStatusTag } from "../../Components/ShipmentStatusTag";
import { ShowHideColumnsPopover } from "../../Components/ShowHideColumnsPopover";
import Stack from "../../Components/Stack";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import { Volume } from "../../Components/Volume";
import { Weight } from "../../Components/Weight";
import {
  ShipmentReport,
  ShipmentReportInvoiceWarningState,
} from "../../generated-openapi-client";
import { calculatePercentageOfTrailer } from "../../Helpers/calculatePercentageOfTrailer";
import { isNotBlank } from "../../Helpers/isNotBlank";
import { stringToColor } from "../../Helpers/stringToColor";
import { convertShipmentReportsToMapData } from "../../Hooks/useMapData";
import Spacer from "../../Spacer";
import { MapComponent } from "../MapScreen";
import { CompanyTabProps } from "../ViewCompanyScreen";
import {
  AndMore,
  MoreDimensionsInfo,
  QuoteSummaryComponent,
} from "../ViewQuotesScreen";

interface ShipmentReportsTableProps {
  data: Array<ShipmentReport>;
}

function ShipmentReportsMap(props: ShipmentReportsTableProps) {
  const [sidebarContent, setSidebarContent] = useState<string | undefined>();
  const data = convertShipmentReportsToMapData(props.data);

  return (
    <div>
      <MapComponent
        data={data}
        showSidebar={function (contents) {
          setSidebarContent(contents);
        }}
        hideSidebar={function () {
          setSidebarContent(undefined);
        }}
      />
      {sidebarContent && (
        <div
          style={{
            position: "fixed",
            left: "16px",
            top: "228px",
            backgroundColor: "#eeea",
            border: "1px solid #aaa",
            borderRadius: "10px",
            opacity: 1.0,
            width: "300px",
            height: "200px",
            padding: "16px",
          }}
          dangerouslySetInnerHTML={{ __html: sidebarContent }}
        ></div>
      )}
    </div>
  );
}

function ShipmentReportsTable(props: ShipmentReportsTableProps) {
  const [columns, setColumns] = useState<DataTableColumn<ShipmentReport>[]>([
    {
      key: "company",
      show: true,
      title: "Shipments",
      render: (o) => (
        <Stack
          align="left"
          style={{
            borderLeft: `8px solid ${stringToColor(o.shipmentHash!!)}`,
            paddingLeft: "16px",
          }}
        >
          <div style={{ fontWeight: 500 }}>
            <HorizontalStack>
              {o.companyName}
              <Spacer width={4} />
              <span>
                {o.insuranceRequested ? (
                  <Tag color="purple">Insurance</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.deletedDescription ? (
                  <Tag color="red">Deleted : {o.deletedDescription}</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.tags?.map((s) => (
                  <Tag color="orange">{s}</Tag>
                ))}
                {o.problems?.map((s) => (
                  <Tag color="magenta">🚩 {s}</Tag>
                ))}
              </span>
              <span>
                {o.manualQuotingRequested ? (
                  <Tag color="blue">🦸‍♀️ Manual Quoting Requested</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.invoiceWarningState !==
                ShipmentReportInvoiceWarningState.NoWarning ? (
                  <Tag color="red">{o.invoiceWarningState}</Tag>
                ) : (
                  ""
                )}
              </span>
            </HorizontalStack>
          </div>
          <div>
            {o.pickupCityState} to {o.deliveryCityState}{" "}
          </div>
          <Spacer height={4} />
          <ShipmentStatusTag status={o.state} />
          <Spacer height={16} />
          <HorizontalStack style={{ fontSize: "12px" }}>
            <div>Units:</div>
            <Spacer width={2} />
            <div>{o.totalHandlingUnits!!}</div>
            <Spacer width={8} />
            <div>Weight:</div>
            <Spacer width={2} />
            <Weight>{o.totalWeight!!}</Weight>
            <Spacer width={8} />
            <div>Volume:</div>
            <Spacer width={2} />
            <Volume>{o.totalVolume!!}</Volume>
            <Spacer width={8} />
            <div>Trailer:</div>
            <Percentage>
              {calculatePercentageOfTrailer(o.lineItems!!)}
            </Percentage>
            <MoreDimensionsInfo shipmentReport={o} />
          </HorizontalStack>
          <div>{o.lineItems!![0].description}</div>
          <Spacer height={8} />
          <div style={{ fontSize: "12px", color: Colors.LightText }}>
            <CreatedAt timestamp={o.quotedAt} />
          </div>
        </Stack>
      ),
      sorter: function (a, b) {
        return moment(a.quotedAt).valueOf() - moment(b.quotedAt).valueOf();
      },
    },
    {
      key: "quotes",
      show: true,
      title: "Quotes",
      render: (o) => (
        <HorizontalStack spacing={16} style={{ marginLeft: "-16px" }}>
          {o.quotes!!.length === 0 && <div>No quotes</div>}
          {o.quotes!!.slice(0, 4).map(function (q) {
            return <QuoteSummaryComponent quote={q} />;
          })}
          <AndMore quotes={o.quotes!!.slice(4)} />
        </HorizontalStack>
      ),
    },
    {
      key: "action",
      show: true,
      title: "Action",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!!} />
        </ButtonRow>
      ),
    },
  ]);

  return (
    <Stack align="left">
      <HorizontalStack align="spread" width="100%" verticalAlign="middle">
        <ShowHideColumnsPopover columns={columns} setColumns={setColumns}>
          Show/Hide Columns
          <span style={{ color: Colors.Gray[500], marginLeft: "8px" }}>
            ({columns.filter((c) => !c.show).length} hidden)
          </span>
        </ShowHideColumnsPopover>
      </HorizontalStack>
      <DataTable pagination={false} columns={columns} data={props.data} />
    </Stack>
  );
}

export function CompanyShipmentsTab(props: CompanyTabProps) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<ShipmentReport[]>();
  const companyId = props.data.company.companyId!!;
  const [onlyShowBooked, setOnlyShowBooked] = useState(false);
  const [onlyShowAdditionalCharges, setOnlyShowAdditionalCharges] =
    useState(false);
  const [filter, setFilter] = useState<string>();

  async function refresh() {
    setLoading(true);

    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.getShipmentReportsForCompany({
        companyId,
      });
      setReports(response);
    } catch (e) {
      message.error("Something went wrong");
    }
    setLoading(false);
  }

  useEffect(function () {
    refresh();
  }, []);

  if (loading || reports === undefined) {
    return <Loading />;
  }

  function filterReports(reports: ShipmentReport[]): ShipmentReport[] {
    function applyBookedFilter(r: ShipmentReport): boolean {
      if (onlyShowBooked) {
        return r.booked!!;
      } else {
        return true;
      }
    }

    function applyAdditionalChargeFilter(r: ShipmentReport): boolean {
      if (onlyShowAdditionalCharges) {
        return r.numberCustomerInvoices!! > 1;
      } else {
        return true;
      }
    }

    function applyGeneralFilter(r: ShipmentReport): boolean {
      if (filter && isNotBlank(filter)) {
        return JSON.stringify(r).includes(filter);
      } else {
        return true;
      }
    }

    return reports
      .filter(applyBookedFilter)
      .filter(applyAdditionalChargeFilter)
      .filter(applyGeneralFilter);
  }

  return (
    <Stack align="left">
      <HorizontalStack align="spread" verticalAlign="middle">
        <HorizontalStack style={{ marginRight: "30px" }}>
          <Form.Item label="Only show booked" style={{ marginBottom: 0 }}>
            <Switch checked={onlyShowBooked} onChange={setOnlyShowBooked} />
          </Form.Item>
          <Spacer width={20} />
          <Form.Item
            label="Only show additional charges"
            style={{ marginBottom: 0 }}
          >
            <Switch
              checked={onlyShowAdditionalCharges}
              onChange={setOnlyShowAdditionalCharges}
            />
          </Form.Item>
          <Spacer width={20} />
          <Form.Item label="Filter" style={{ marginBottom: 0 }}>
            <Search
              value={filter}
              placeholder="Filter by anything"
              allowClear
              onChange={function (e) {
                setFilter(e.target.value);
              }}
            />
          </Form.Item>
        </HorizontalStack>
      </HorizontalStack>

      <Tabs style={{ width: "100%" }}>
        <TabPane tab={`List Shipments`} key="listshipments">
          <ShipmentReportsTable data={filterReports(reports)} />
        </TabPane>
        <TabPane tab={`Map View`} key="shipmentsmap">
          <ShipmentReportsMap data={filterReports(reports)} />
        </TabPane>
      </Tabs>
    </Stack>
  );
}
