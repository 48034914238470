/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdditionalCharge } from './AdditionalCharge';
import {
    AdditionalChargeFromJSON,
    AdditionalChargeFromJSONTyped,
    AdditionalChargeToJSON,
} from './AdditionalCharge';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';

/**
 * 
 * @export
 * @interface CompanyAdditionalCharge
 */
export interface CompanyAdditionalCharge {
    /**
     * 
     * @type {Company}
     * @memberof CompanyAdditionalCharge
     */
    company: Company;
    /**
     * 
     * @type {AdditionalCharge}
     * @memberof CompanyAdditionalCharge
     */
    additionalCharge: AdditionalCharge;
}

/**
 * Check if a given object implements the CompanyAdditionalCharge interface.
 */
export function instanceOfCompanyAdditionalCharge(value: object): boolean {
    if (!('company' in value)) return false;
    if (!('additionalCharge' in value)) return false;
    return true;
}

export function CompanyAdditionalChargeFromJSON(json: any): CompanyAdditionalCharge {
    return CompanyAdditionalChargeFromJSONTyped(json, false);
}

export function CompanyAdditionalChargeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAdditionalCharge {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyFromJSON(json['company']),
        'additionalCharge': AdditionalChargeFromJSON(json['additionalCharge']),
    };
}

export function CompanyAdditionalChargeToJSON(value?: CompanyAdditionalCharge | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyToJSON(value['company']),
        'additionalCharge': AdditionalChargeToJSON(value['additionalCharge']),
    };
}

