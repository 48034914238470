/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentReport } from './ShipmentReport';
import {
    ShipmentReportFromJSON,
    ShipmentReportFromJSONTyped,
    ShipmentReportToJSON,
} from './ShipmentReport';

/**
 * 
 * @export
 * @interface CompanySalesReport
 */
export interface CompanySalesReport {
    /**
     * 
     * @type {Array<ShipmentReport>}
     * @memberof CompanySalesReport
     */
    lines: Array<ShipmentReport>;
}

/**
 * Check if a given object implements the CompanySalesReport interface.
 */
export function instanceOfCompanySalesReport(value: object): boolean {
    if (!('lines' in value)) return false;
    return true;
}

export function CompanySalesReportFromJSON(json: any): CompanySalesReport {
    return CompanySalesReportFromJSONTyped(json, false);
}

export function CompanySalesReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySalesReport {
    if (json == null) {
        return json;
    }
    return {
        
        'lines': ((json['lines'] as Array<any>).map(ShipmentReportFromJSON)),
    };
}

export function CompanySalesReportToJSON(value?: CompanySalesReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lines': ((value['lines'] as Array<any>).map(ShipmentReportToJSON)),
    };
}

