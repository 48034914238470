/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditContactInput
 */
export interface EditContactInput {
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    salesContactId: string;
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    phoneExtension: string;
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    mobilePhone: string;
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EditContactInput
     */
    description: string;
}

/**
 * Check if a given object implements the EditContactInput interface.
 */
export function instanceOfEditContactInput(value: object): boolean {
    if (!('salesContactId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('phoneExtension' in value)) return false;
    if (!('mobilePhone' in value)) return false;
    if (!('email' in value)) return false;
    if (!('description' in value)) return false;
    return true;
}

export function EditContactInputFromJSON(json: any): EditContactInput {
    return EditContactInputFromJSONTyped(json, false);
}

export function EditContactInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditContactInput {
    if (json == null) {
        return json;
    }
    return {
        
        'salesContactId': json['salesContactId'],
        'name': json['name'],
        'phone': json['phone'],
        'phoneExtension': json['phoneExtension'],
        'mobilePhone': json['mobilePhone'],
        'email': json['email'],
        'description': json['description'],
    };
}

export function EditContactInputToJSON(value?: EditContactInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'salesContactId': value['salesContactId'],
        'name': value['name'],
        'phone': value['phone'],
        'phoneExtension': value['phoneExtension'],
        'mobilePhone': value['mobilePhone'],
        'email': value['email'],
        'description': value['description'],
    };
}

