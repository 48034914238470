/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { InvoiceWarningState } from './InvoiceWarningState';
import {
    InvoiceWarningStateFromJSON,
    InvoiceWarningStateFromJSONTyped,
    InvoiceWarningStateToJSON,
} from './InvoiceWarningState';

/**
 * 
 * @export
 * @interface CompaniesReportInvoiceIssueRow
 */
export interface CompaniesReportInvoiceIssueRow {
    /**
     * 
     * @type {Company}
     * @memberof CompaniesReportInvoiceIssueRow
     */
    company: Company;
    /**
     * 
     * @type {InvoiceWarningState}
     * @memberof CompaniesReportInvoiceIssueRow
     */
    companyInvoiceWarningState: InvoiceWarningState;
}

/**
 * Check if a given object implements the CompaniesReportInvoiceIssueRow interface.
 */
export function instanceOfCompaniesReportInvoiceIssueRow(value: object): boolean {
    if (!('company' in value)) return false;
    if (!('companyInvoiceWarningState' in value)) return false;
    return true;
}

export function CompaniesReportInvoiceIssueRowFromJSON(json: any): CompaniesReportInvoiceIssueRow {
    return CompaniesReportInvoiceIssueRowFromJSONTyped(json, false);
}

export function CompaniesReportInvoiceIssueRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompaniesReportInvoiceIssueRow {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyFromJSON(json['company']),
        'companyInvoiceWarningState': InvoiceWarningStateFromJSON(json['companyInvoiceWarningState']),
    };
}

export function CompaniesReportInvoiceIssueRowToJSON(value?: CompaniesReportInvoiceIssueRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyToJSON(value['company']),
        'companyInvoiceWarningState': InvoiceWarningStateToJSON(value['companyInvoiceWarningState']),
    };
}

