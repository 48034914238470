/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    handlingUnitType?: string;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    numberHandlingUnits?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    weightPerHandlingUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    freightClass?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    temperatureHandling?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    isDangerous?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    nmfcItemNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    isStackable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousUnNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousClassification?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousPackingGroup?: string;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    dangerousNumberPackages?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousPackagingType?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousProperShippingName?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousTechnicalName?: string;
}

/**
 * Check if a given object implements the LineItem interface.
 */
export function instanceOfLineItem(value: object): boolean {
    return true;
}

export function LineItemFromJSON(json: any): LineItem {
    return LineItemFromJSONTyped(json, false);
}

export function LineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItem {
    if (json == null) {
        return json;
    }
    return {
        
        'handlingUnitType': json['handlingUnitType'] == null ? undefined : json['handlingUnitType'],
        'numberHandlingUnits': json['numberHandlingUnits'] == null ? undefined : json['numberHandlingUnits'],
        'description': json['description'] == null ? undefined : json['description'],
        'length': json['length'] == null ? undefined : json['length'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'weightPerHandlingUnit': json['weightPerHandlingUnit'] == null ? undefined : json['weightPerHandlingUnit'],
        'freightClass': json['freightClass'] == null ? undefined : json['freightClass'],
        'temperatureHandling': json['temperatureHandling'] == null ? undefined : json['temperatureHandling'],
        'isDangerous': json['isDangerous'] == null ? undefined : json['isDangerous'],
        'nmfcItemNumber': json['nmfcItemNumber'] == null ? undefined : json['nmfcItemNumber'],
        'isStackable': json['isStackable'] == null ? undefined : json['isStackable'],
        'dangerousUnNumber': json['dangerousUnNumber'] == null ? undefined : json['dangerousUnNumber'],
        'dangerousClassification': json['dangerousClassification'] == null ? undefined : json['dangerousClassification'],
        'dangerousPackingGroup': json['dangerousPackingGroup'] == null ? undefined : json['dangerousPackingGroup'],
        'dangerousNumberPackages': json['dangerousNumberPackages'] == null ? undefined : json['dangerousNumberPackages'],
        'dangerousPackagingType': json['dangerousPackagingType'] == null ? undefined : json['dangerousPackagingType'],
        'dangerousProperShippingName': json['dangerousProperShippingName'] == null ? undefined : json['dangerousProperShippingName'],
        'dangerousTechnicalName': json['dangerousTechnicalName'] == null ? undefined : json['dangerousTechnicalName'],
    };
}

export function LineItemToJSON(value?: LineItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'handlingUnitType': value['handlingUnitType'],
        'numberHandlingUnits': value['numberHandlingUnits'],
        'description': value['description'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'weightPerHandlingUnit': value['weightPerHandlingUnit'],
        'freightClass': value['freightClass'],
        'temperatureHandling': value['temperatureHandling'],
        'isDangerous': value['isDangerous'],
        'nmfcItemNumber': value['nmfcItemNumber'],
        'isStackable': value['isStackable'],
        'dangerousUnNumber': value['dangerousUnNumber'],
        'dangerousClassification': value['dangerousClassification'],
        'dangerousPackingGroup': value['dangerousPackingGroup'],
        'dangerousNumberPackages': value['dangerousNumberPackages'],
        'dangerousPackagingType': value['dangerousPackagingType'],
        'dangerousProperShippingName': value['dangerousProperShippingName'],
        'dangerousTechnicalName': value['dangerousTechnicalName'],
    };
}

