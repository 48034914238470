/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailDescription
 */
export interface EmailDescription {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailDescription
     */
    addresses: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailDescription
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDescription
     */
    emailBody: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDescription
     */
    fromAddress?: string;
}

/**
 * Check if a given object implements the EmailDescription interface.
 */
export function instanceOfEmailDescription(value: object): boolean {
    if (!('addresses' in value)) return false;
    if (!('subject' in value)) return false;
    if (!('emailBody' in value)) return false;
    return true;
}

export function EmailDescriptionFromJSON(json: any): EmailDescription {
    return EmailDescriptionFromJSONTyped(json, false);
}

export function EmailDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDescription {
    if (json == null) {
        return json;
    }
    return {
        
        'addresses': json['addresses'],
        'subject': json['subject'],
        'emailBody': json['emailBody'],
        'fromAddress': json['fromAddress'] == null ? undefined : json['fromAddress'],
    };
}

export function EmailDescriptionToJSON(value?: EmailDescription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'addresses': value['addresses'],
        'subject': value['subject'],
        'emailBody': value['emailBody'],
        'fromAddress': value['fromAddress'],
    };
}

