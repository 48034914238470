import { LineItem } from "../generated-openapi-client";

export function totalWeightOfLineItem(lineItem: LineItem): number {
  return lineItem.numberHandlingUnits!! * lineItem.weightPerHandlingUnit!!;
}

export function totalVolumeOfLineItem(lineItem: LineItem): number {
  return (
    (lineItem.numberHandlingUnits!! *
      lineItem.length!! *
      lineItem.width!! *
      lineItem.height!!) /
    (12 * 12 * 12)
  );
}

export function densityOfLineItem(lineItem: LineItem): number {
  const volume =
    (lineItem.length!! * lineItem.width!! * lineItem.height!!) / (12 * 12 * 12);
  const weight = lineItem.weightPerHandlingUnit!!;
  return weight / volume;
}
