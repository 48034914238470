/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AdditionalChargeState = {
    Proposed: 'proposed',
    Approved: 'approved',
    Paid: 'paid',
    Waived: 'waived'
} as const;
export type AdditionalChargeState = typeof AdditionalChargeState[keyof typeof AdditionalChargeState];


export function instanceOfAdditionalChargeState(value: any): boolean {
    return Object.values(AdditionalChargeState).includes(value);
}

export function AdditionalChargeStateFromJSON(json: any): AdditionalChargeState {
    return AdditionalChargeStateFromJSONTyped(json, false);
}

export function AdditionalChargeStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalChargeState {
    return json as AdditionalChargeState;
}

export function AdditionalChargeStateToJSON(value?: AdditionalChargeState | null): any {
    return value as any;
}

