/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyAdditionalCharge } from './CompanyAdditionalCharge';
import {
    CompanyAdditionalChargeFromJSON,
    CompanyAdditionalChargeFromJSONTyped,
    CompanyAdditionalChargeToJSON,
} from './CompanyAdditionalCharge';
import type { CompanyCustomerInvoice } from './CompanyCustomerInvoice';
import {
    CompanyCustomerInvoiceFromJSON,
    CompanyCustomerInvoiceFromJSONTyped,
    CompanyCustomerInvoiceToJSON,
} from './CompanyCustomerInvoice';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';

/**
 * 
 * @export
 * @interface CustomerInvoiceData
 */
export interface CustomerInvoiceData {
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof CustomerInvoiceData
     */
    shipmentsWithoutInvoices: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyAdditionalCharge>}
     * @memberof CustomerInvoiceData
     */
    proposedAdditionalCharges: Array<CompanyAdditionalCharge>;
    /**
     * 
     * @type {Array<CompanyCustomerInvoice>}
     * @memberof CustomerInvoiceData
     */
    issuedInvoices: Array<CompanyCustomerInvoice>;
    /**
     * 
     * @type {Array<CompanyCustomerInvoice>}
     * @memberof CustomerInvoiceData
     */
    settlementPending: Array<CompanyCustomerInvoice>;
    /**
     * 
     * @type {Array<CompanyCustomerInvoice>}
     * @memberof CustomerInvoiceData
     */
    settled: Array<CompanyCustomerInvoice>;
}

/**
 * Check if a given object implements the CustomerInvoiceData interface.
 */
export function instanceOfCustomerInvoiceData(value: object): boolean {
    if (!('shipmentsWithoutInvoices' in value)) return false;
    if (!('proposedAdditionalCharges' in value)) return false;
    if (!('issuedInvoices' in value)) return false;
    if (!('settlementPending' in value)) return false;
    if (!('settled' in value)) return false;
    return true;
}

export function CustomerInvoiceDataFromJSON(json: any): CustomerInvoiceData {
    return CustomerInvoiceDataFromJSONTyped(json, false);
}

export function CustomerInvoiceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerInvoiceData {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentsWithoutInvoices': ((json['shipmentsWithoutInvoices'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'proposedAdditionalCharges': ((json['proposedAdditionalCharges'] as Array<any>).map(CompanyAdditionalChargeFromJSON)),
        'issuedInvoices': ((json['issuedInvoices'] as Array<any>).map(CompanyCustomerInvoiceFromJSON)),
        'settlementPending': ((json['settlementPending'] as Array<any>).map(CompanyCustomerInvoiceFromJSON)),
        'settled': ((json['settled'] as Array<any>).map(CompanyCustomerInvoiceFromJSON)),
    };
}

export function CustomerInvoiceDataToJSON(value?: CustomerInvoiceData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentsWithoutInvoices': ((value['shipmentsWithoutInvoices'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'proposedAdditionalCharges': ((value['proposedAdditionalCharges'] as Array<any>).map(CompanyAdditionalChargeToJSON)),
        'issuedInvoices': ((value['issuedInvoices'] as Array<any>).map(CompanyCustomerInvoiceToJSON)),
        'settlementPending': ((value['settlementPending'] as Array<any>).map(CompanyCustomerInvoiceToJSON)),
        'settled': ((value['settled'] as Array<any>).map(CompanyCustomerInvoiceToJSON)),
    };
}

