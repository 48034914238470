import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CarrierAndServiceFilter } from "../Components/CarrierAndServiceFilter";
import CarrierLogo from "../Components/CarrierLogo";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Note, NoteType, QuoteLog } from "../generated-openapi-client";
import { NoteTypeDropdown } from "./ViewShipmentScreenComponents/NoteTypeDropdown";

interface QuoteLogsTableProps {
  quoteLogs: QuoteLog[];
}

export function QuoteLogsTable(props: QuoteLogsTableProps) {
  const columns: DataTableColumn<QuoteLog>[] = [];
  columns.push({
    title: "Carrier",
    render: (o) => (
      <CarrierLogo
        carrierIdentifier={o.carrierIdentifier}
        brokeredCarrierIdentifier={undefined}
        width={40}
        height={30}
      />
    ),
  });
  columns.push({
    title: "Service Identifier",
    render: (o) => <div>{o.serviceIdentifier}</div>,
  });

  columns.push({
    title: "Success?",
    render: (o) => <div>{o.success ? "✅" : "❌"}</div>,
  });

  columns.push({
    title: "Message",
    render: (o) => <div>{o.message}</div>,
  });

  columns.push({
    title: "Message",
    render: (o) => <CreatedAt timestamp={o.createdAt} />,
  });

  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <ViewShipmentButton shipmentId={o.shipmentId!!} />
      </ButtonRow>
    ),
  });

  return (
    <DataTable pagination={false} columns={columns} data={props.quoteLogs} />
  );
}

export function QuoteLogsByCarrierScreen() {
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >();
  const [serviceIdentifier, setServiceIdentifier] = useState<
    string | undefined
  >();
  const [quoteLogs, setQuoteLogs] = useState<Note[] | undefined>();
  const [errorHappened, setErrorHappened] = useState(false);
  const [noteTypeFilter, setNoteTypeFilter] = useState<NoteType | undefined>();
  const createQuotesApi = useQuotesApi();

  async function refresh() {
    if (carrierIdentifier === undefined || serviceIdentifier === undefined) {
      return;
    }

    try {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.getQuoteLogsForCarrier({
        carrierIdentifier,
        serviceIdentifier,
      });
      setQuoteLogs(response);
    } catch (e) {
      message.error(`Something went wrong : ${e}`);
      setErrorHappened(true);
    }
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [carrierIdentifier, serviceIdentifier]
  );

  return (
    <>
      <Page
        title={`Quote Logs`}
        tags={[]}
        stats={[]}
        extra={[
          <ButtonRow>
            <Form.Item label="Select Admin">
              <CarrierAndServiceFilter
                carrierIdentifier={carrierIdentifier}
                serviceIdentifier={serviceIdentifier}
                onChange={function (
                  carrierIdentifier: string,
                  serviceIdentifier: string
                ) {
                  setCarrierIdentifier(carrierIdentifier);
                  setServiceIdentifier(serviceIdentifier);
                }}
              />
            </Form.Item>
            <Form.Item label="Filter by note type">
              <NoteTypeDropdown
                value={noteTypeFilter}
                setValue={setNoteTypeFilter}
              />
            </Form.Item>
          </ButtonRow>,
        ]}
      >
        <PageTitle>{`Quote Logs`}</PageTitle>
        {errorHappened && <div>❌ Ooops error happened</div>}
        {quoteLogs !== undefined && <QuoteLogsTable quoteLogs={quoteLogs} />}
      </Page>
    </>
  );
}
