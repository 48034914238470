/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierRepInformation } from './CarrierRepInformation';
import {
    CarrierRepInformationFromJSON,
    CarrierRepInformationFromJSONTyped,
    CarrierRepInformationToJSON,
} from './CarrierRepInformation';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface CarrierBasicProperties
 */
export interface CarrierBasicProperties {
    /**
     * 
     * @type {string}
     * @memberof CarrierBasicProperties
     */
    displayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierBasicProperties
     */
    isCarrierPickupNumberSameAsProNumber: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarrierBasicProperties
     */
    mainTrackingNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CarrierBasicProperties
     */
    priceAdjustment: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarrierBasicProperties
     */
    carrierEmailDomains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CarrierBasicProperties
     */
    linearUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierBasicProperties
     */
    mainTrackingNumberIsQuoteNumber: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarrierBasicProperties
     */
    maxBillOfLadingLineItems: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierBasicProperties
     */
    doNotSendAmyEmails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierBasicProperties
     */
    doNotAlarmWhenCarrierPickupNumberIsMissing: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierBasicProperties
     */
    includeQuoteAmountOnPickupEmail: boolean;
    /**
     * 
     * @type {CountryCode}
     * @memberof CarrierBasicProperties
     */
    basedIn: CountryCode;
    /**
     * 
     * @type {CarrierRepInformation}
     * @memberof CarrierBasicProperties
     */
    carrierRepInformation: CarrierRepInformation;
}

/**
 * Check if a given object implements the CarrierBasicProperties interface.
 */
export function instanceOfCarrierBasicProperties(value: object): boolean {
    if (!('displayName' in value)) return false;
    if (!('isCarrierPickupNumberSameAsProNumber' in value)) return false;
    if (!('mainTrackingNumber' in value)) return false;
    if (!('priceAdjustment' in value)) return false;
    if (!('carrierEmailDomains' in value)) return false;
    if (!('linearUrl' in value)) return false;
    if (!('mainTrackingNumberIsQuoteNumber' in value)) return false;
    if (!('maxBillOfLadingLineItems' in value)) return false;
    if (!('doNotSendAmyEmails' in value)) return false;
    if (!('doNotAlarmWhenCarrierPickupNumberIsMissing' in value)) return false;
    if (!('includeQuoteAmountOnPickupEmail' in value)) return false;
    if (!('basedIn' in value)) return false;
    if (!('carrierRepInformation' in value)) return false;
    return true;
}

export function CarrierBasicPropertiesFromJSON(json: any): CarrierBasicProperties {
    return CarrierBasicPropertiesFromJSONTyped(json, false);
}

export function CarrierBasicPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierBasicProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'isCarrierPickupNumberSameAsProNumber': json['isCarrierPickupNumberSameAsProNumber'],
        'mainTrackingNumber': json['mainTrackingNumber'],
        'priceAdjustment': json['priceAdjustment'],
        'carrierEmailDomains': json['carrierEmailDomains'],
        'linearUrl': json['linearUrl'],
        'mainTrackingNumberIsQuoteNumber': json['mainTrackingNumberIsQuoteNumber'],
        'maxBillOfLadingLineItems': json['maxBillOfLadingLineItems'],
        'doNotSendAmyEmails': json['doNotSendAmyEmails'],
        'doNotAlarmWhenCarrierPickupNumberIsMissing': json['doNotAlarmWhenCarrierPickupNumberIsMissing'],
        'includeQuoteAmountOnPickupEmail': json['includeQuoteAmountOnPickupEmail'],
        'basedIn': CountryCodeFromJSON(json['basedIn']),
        'carrierRepInformation': CarrierRepInformationFromJSON(json['carrierRepInformation']),
    };
}

export function CarrierBasicPropertiesToJSON(value?: CarrierBasicProperties | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'displayName': value['displayName'],
        'isCarrierPickupNumberSameAsProNumber': value['isCarrierPickupNumberSameAsProNumber'],
        'mainTrackingNumber': value['mainTrackingNumber'],
        'priceAdjustment': value['priceAdjustment'],
        'carrierEmailDomains': value['carrierEmailDomains'],
        'linearUrl': value['linearUrl'],
        'mainTrackingNumberIsQuoteNumber': value['mainTrackingNumberIsQuoteNumber'],
        'maxBillOfLadingLineItems': value['maxBillOfLadingLineItems'],
        'doNotSendAmyEmails': value['doNotSendAmyEmails'],
        'doNotAlarmWhenCarrierPickupNumberIsMissing': value['doNotAlarmWhenCarrierPickupNumberIsMissing'],
        'includeQuoteAmountOnPickupEmail': value['includeQuoteAmountOnPickupEmail'],
        'basedIn': CountryCodeToJSON(value['basedIn']),
        'carrierRepInformation': CarrierRepInformationToJSON(value['carrierRepInformation']),
    };
}

