import { ViewJsonMenuItem } from "../../Components/ViewJsonMenuItem";
import { TabProps } from "./TabProps";

export function ViewPageLoadStatsMenuItem(props: TabProps) {
  return (
    <ViewJsonMenuItem
      data={props.shipmentData.durationInfo}
      title="View Page Load Stats"
    />
  );
}
