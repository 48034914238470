/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionReportLineItem } from './DimensionReportLineItem';
import {
    DimensionReportLineItemFromJSON,
    DimensionReportLineItemFromJSONTyped,
    DimensionReportLineItemToJSON,
} from './DimensionReportLineItem';

/**
 * 
 * @export
 * @interface DimensionReportSupportEmailInput
 */
export interface DimensionReportSupportEmailInput {
    /**
     * 
     * @type {string}
     * @memberof DimensionReportSupportEmailInput
     */
    carrierInvoiceId: string;
    /**
     * 
     * @type {Array<DimensionReportLineItem>}
     * @memberof DimensionReportSupportEmailInput
     */
    reportLineItems: Array<DimensionReportLineItem>;
    /**
     * 
     * @type {string}
     * @memberof DimensionReportSupportEmailInput
     */
    extraText: string;
}

/**
 * Check if a given object implements the DimensionReportSupportEmailInput interface.
 */
export function instanceOfDimensionReportSupportEmailInput(value: object): boolean {
    if (!('carrierInvoiceId' in value)) return false;
    if (!('reportLineItems' in value)) return false;
    if (!('extraText' in value)) return false;
    return true;
}

export function DimensionReportSupportEmailInputFromJSON(json: any): DimensionReportSupportEmailInput {
    return DimensionReportSupportEmailInputFromJSONTyped(json, false);
}

export function DimensionReportSupportEmailInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DimensionReportSupportEmailInput {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierInvoiceId': json['carrierInvoiceId'],
        'reportLineItems': ((json['reportLineItems'] as Array<any>).map(DimensionReportLineItemFromJSON)),
        'extraText': json['extraText'],
    };
}

export function DimensionReportSupportEmailInputToJSON(value?: DimensionReportSupportEmailInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierInvoiceId': value['carrierInvoiceId'],
        'reportLineItems': ((value['reportLineItems'] as Array<any>).map(DimensionReportLineItemToJSON)),
        'extraText': value['extraText'],
    };
}

