import { useMsal } from "@azure/msal-react";
import { Button, Form, Input, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import showdown from "showdown";
import { useTechnicalApi } from "../Apis/Apis";
import HorizontalStack from "../Components/HorizontalStack";
import Stack from "../Components/Stack";
import {
  EmailTemplate,
  EmailTemplateGroup,
  EmailTemplateImage,
} from "../generated-openapi-client";
import { addEmailSignature } from "../Helpers/addEmailSignature";
import { assertNever } from "../Helpers/assertNever";
import { isBlank } from "../Helpers/isNotBlank";
import Spacer from "../Spacer";
import { InsertImageButton } from "./SalessComponents/InsertImageButton";
import { emailSenders } from "./SalessComponents/SalesforceSendEmailButton";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";

interface AddEmailTemplateButtonProps {
  onRefresh: () => Promise<void>;

  // If we're editing then set this
  existingEmailTemplate?: EmailTemplate;

  images: EmailTemplateImage[];
}

export function describeEmailTemplateGroup(group: EmailTemplateGroup) {
  switch (group) {
    case EmailTemplateGroup.Accounts:
      return "Accounts";
    case EmailTemplateGroup.Basic:
      return "Basic";
    case EmailTemplateGroup.Booking:
      return "Booking";
    case EmailTemplateGroup.Finance:
      return "Finance";
    case EmailTemplateGroup.GeneralInfo:
      return "General Info";
    case EmailTemplateGroup.GettingStarted:
      return "Getting Started";
    case EmailTemplateGroup.Leads:
      return "Leads";
    case EmailTemplateGroup.Quoting:
      return "Quoting";
    case EmailTemplateGroup.Reengage:
      return "Re-engage";
    case EmailTemplateGroup.Tracking:
      return "Tracking";
    default:
      assertNever(group);
  }
}

export const EmailTemplateGroupDropdown = OptionalEnumDropdown<
  EmailTemplateGroup,
  typeof EmailTemplateGroup
>(EmailTemplateGroup, "Select a group", (group) =>
  describeEmailTemplateGroup(group)
);

export function AddEmailTemplateButton(props: AddEmailTemplateButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState<string>(
    props.existingEmailTemplate?.title ?? ""
  );
  const [subject, setSubject] = useState<string>(
    props.existingEmailTemplate?.subject ?? ""
  );
  const [body, setBody] = useState<string>(
    props.existingEmailTemplate?.body ?? ""
  );
  const [emailTemplateGroup, setEmailTemplateGroup] = useState<
    EmailTemplateGroup | undefined
  >(props.existingEmailTemplate?.emailTemplateGroup);
  const [cursor, setCursor] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const createTechnicalApi = useTechnicalApi();
  const converter = new showdown.Converter();

  const emailTemplateId = props.existingEmailTemplate?.emailTemplateId;

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const currentUserEmail = activeAccount!!.username;

  const sender = emailSenders.find((o) => o.email === currentUserEmail);

  const renderedEmailBody = addEmailSignature(
    converter.makeHtml(body),
    sender!!.firstName,
    sender!!.lastName,
    sender!!.phoneNumber,
    ""
  );

  const insertText = (textToInsert: string) => {
    console.log(`$$$$ insertText`, { textToInsert, cursor, body });
    const newText =
      body.substring(0, cursor) + textToInsert + body.substring(cursor);
    setBody(newText);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  function description() {
    if (props.existingEmailTemplate) {
      return "Edit Template";
    } else {
      return "Add Template";
    }
  }

  async function handleOk() {
    if (
      isBlank(subject) ||
      isBlank(title) ||
      isBlank(body) ||
      emailTemplateGroup === undefined
    ) {
      message.warn("Missing");
      return;
    }
    setLoading(true);
    try {
      const technicalApi = await createTechnicalApi();
      await technicalApi.upsertEmailTemplate({
        emailTemplateId,
        title,
        subject,
        body,
        emailTemplateGroup,
      });
      await props.onRefresh();
      message.success(`Success!`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={description()}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
        confirmLoading={loading}
        okText="Save"
      >
        <HorizontalStack style={{ minHeight: "700px" }} verticalAlign="top">
          <Form
            style={{ width: "800px" }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12, offset: 4 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Title">
              <Input
                value={title}
                onChange={function (e) {
                  setTitle(e.target.value);
                }}
                style={{ width: "400px" }}
              />
            </Form.Item>
            <Form.Item label="Subject">
              <Input
                value={subject}
                onChange={function (e) {
                  setSubject(e.target.value);
                }}
                style={{ width: "400px" }}
              />
            </Form.Item>
            <Form.Item label="Group">
              <EmailTemplateGroupDropdown
                value={emailTemplateGroup}
                setValue={setEmailTemplateGroup}
              />
            </Form.Item>
            <Form.Item label="Body">
              <TextArea
                style={{ width: "570px", height: "700px" }}
                value={body}
                onChange={function (e) {
                  setBody(e.target.value);
                }}
                onSelect={function (e) {
                  setCursor(e.currentTarget.selectionStart);
                }}
                placeholder="Type something here!"
              ></TextArea>
              <InsertImageButton
                images={props.images}
                onInsertText={(_text) => insertText(_text)}
              />
            </Form.Item>
          </Form>
          <Spacer width={32} />
          <Stack align="left">
            <h2>Preview:</h2>
            <div
              style={{
                border: "1px solid #eee",
                padding: "16px",
                width: "570px",
                height: "700px",
                overflow: "scroll",
              }}
            >
              <div
                title="Email Preview"
                dangerouslySetInnerHTML={{
                  __html: renderedEmailBody,
                }}
              ></div>
            </div>
          </Stack>
        </HorizontalStack>
      </Modal>
      <Button onClick={showModal}>{description()}</Button>
    </>
  );
}
