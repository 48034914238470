/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentType } from './DocumentType';
import {
    DocumentTypeFromJSON,
    DocumentTypeFromJSONTyped,
    DocumentTypeToJSON,
} from './DocumentType';
import type { ShipmentDocumentSource } from './ShipmentDocumentSource';
import {
    ShipmentDocumentSourceFromJSON,
    ShipmentDocumentSourceFromJSONTyped,
    ShipmentDocumentSourceToJSON,
} from './ShipmentDocumentSource';

/**
 * 
 * @export
 * @interface ShipmentDocument
 */
export interface ShipmentDocument {
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    shipmentDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    companyId?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof ShipmentDocument
     */
    documentType?: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    hash?: string;
    /**
     * 
     * @type {ShipmentDocumentSource}
     * @memberof ShipmentDocument
     */
    source?: ShipmentDocumentSource;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    frontUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentDocument
     */
    createdBy?: string;
}

/**
 * Check if a given object implements the ShipmentDocument interface.
 */
export function instanceOfShipmentDocument(value: object): boolean {
    return true;
}

export function ShipmentDocumentFromJSON(json: any): ShipmentDocument {
    return ShipmentDocumentFromJSONTyped(json, false);
}

export function ShipmentDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentDocumentId': json['shipmentDocumentId'] == null ? undefined : json['shipmentDocumentId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'documentType': json['documentType'] == null ? undefined : DocumentTypeFromJSON(json['documentType']),
        'description': json['description'] == null ? undefined : json['description'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'source': json['source'] == null ? undefined : ShipmentDocumentSourceFromJSON(json['source']),
        'frontUrl': json['frontUrl'] == null ? undefined : json['frontUrl'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

export function ShipmentDocumentToJSON(value?: ShipmentDocument | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentDocumentId': value['shipmentDocumentId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'companyId': value['companyId'],
        'documentType': DocumentTypeToJSON(value['documentType']),
        'description': value['description'],
        'hash': value['hash'],
        'source': ShipmentDocumentSourceToJSON(value['source']),
        'frontUrl': value['frontUrl'],
        'createdBy': value['createdBy'],
    };
}

