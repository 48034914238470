import { message, Tag } from "antd";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import {
  CarrierInvoice,
  CarrierInvoiceState,
} from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

interface RetryProcessPaymentMenuItemProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function RetryProcessPaymentMenuItem(
  props: RetryProcessPaymentMenuItemProps
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.retryProcessPayment({
        carrierInvoiceId,
      });
      message.success("Payment Processed");
      props.onRefresh();
    } catch (e) {
      message.error(
        `Oops. Something went wrong ${e}. Maybe a timeout? In Safari it times out after 30 seconds`
      );
    }
  }

  if (props.carrierInvoice.invoiceState !== CarrierInvoiceState.Approved) {
    return <></>;
  }

  if (!props.carrierInvoice.paymentMondayId) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question={"Are you sure you want to retry processing payment?"}
      okText={"Yes, retry process payment"}
      tooltip={
        "Let's say that there was a glitch/failure when processing the payment. This should only be used if there was a glitch"
      }
    >
      <Tag color="red">Advanced</Tag> Retry Process Payment
    </ConfirmMenuItem>
  );
}
