import { useEffect } from "react";

export function HelpCenterScreen() {
  useEffect(() => {
    // Create script element
    const script = document.createElement("script");
    script.innerHTML = `
      (function (w, d, s, o, f, js, fjs) {
        w.snazzyDocs = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
      }(window, document, 'script', 'snazzydocs', 'https://cdn-2.snazzydocs.com/js/snazzydocs.min.js?2.0.18'));

      snazzydocs('init', {
        id: 'cJmfPSh0rD21ENc8',
        path: '/help-center',
      });
    `;

    // Append script to document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
      <div id="sd_container"></div>
    </>
  );
}
