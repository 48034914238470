/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BookShipmentStrategy } from './BookShipmentStrategy';
import {
    BookShipmentStrategyFromJSON,
    BookShipmentStrategyFromJSONTyped,
    BookShipmentStrategyToJSON,
} from './BookShipmentStrategy';
import type { QuotingStrategy } from './QuotingStrategy';
import {
    QuotingStrategyFromJSON,
    QuotingStrategyFromJSONTyped,
    QuotingStrategyToJSON,
} from './QuotingStrategy';

/**
 * 
 * @export
 * @interface CarrierServiceInfo
 */
export interface CarrierServiceInfo {
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceInfo
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceInfo
     */
    serviceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceInfo
     */
    serviceDisplayName: string;
    /**
     * 
     * @type {QuotingStrategy}
     * @memberof CarrierServiceInfo
     */
    quotingStrategy: QuotingStrategy;
    /**
     * 
     * @type {BookShipmentStrategy}
     * @memberof CarrierServiceInfo
     */
    bookShipmentStrategy: BookShipmentStrategy;
    /**
     * 
     * @type {string}
     * @memberof CarrierServiceInfo
     */
    serviceAreaDescription: string;
    /**
     * 
     * @type {number}
     * @memberof CarrierServiceInfo
     */
    markupPercentage: number;
}

/**
 * Check if a given object implements the CarrierServiceInfo interface.
 */
export function instanceOfCarrierServiceInfo(value: object): boolean {
    if (!('carrierIdentifier' in value)) return false;
    if (!('serviceIdentifier' in value)) return false;
    if (!('serviceDisplayName' in value)) return false;
    if (!('quotingStrategy' in value)) return false;
    if (!('bookShipmentStrategy' in value)) return false;
    if (!('serviceAreaDescription' in value)) return false;
    if (!('markupPercentage' in value)) return false;
    return true;
}

export function CarrierServiceInfoFromJSON(json: any): CarrierServiceInfo {
    return CarrierServiceInfoFromJSONTyped(json, false);
}

export function CarrierServiceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierServiceInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierIdentifier': json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'],
        'serviceDisplayName': json['serviceDisplayName'],
        'quotingStrategy': QuotingStrategyFromJSON(json['quotingStrategy']),
        'bookShipmentStrategy': BookShipmentStrategyFromJSON(json['bookShipmentStrategy']),
        'serviceAreaDescription': json['serviceAreaDescription'],
        'markupPercentage': json['markupPercentage'],
    };
}

export function CarrierServiceInfoToJSON(value?: CarrierServiceInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
        'serviceDisplayName': value['serviceDisplayName'],
        'quotingStrategy': QuotingStrategyToJSON(value['quotingStrategy']),
        'bookShipmentStrategy': BookShipmentStrategyToJSON(value['bookShipmentStrategy']),
        'serviceAreaDescription': value['serviceAreaDescription'],
        'markupPercentage': value['markupPercentage'],
    };
}

