/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NoteLevel = {
    Info: 'info',
    Warning: 'warning',
    Error: 'error'
} as const;
export type NoteLevel = typeof NoteLevel[keyof typeof NoteLevel];


export function instanceOfNoteLevel(value: any): boolean {
    return Object.values(NoteLevel).includes(value);
}

export function NoteLevelFromJSON(json: any): NoteLevel {
    return NoteLevelFromJSONTyped(json, false);
}

export function NoteLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteLevel {
    return json as NoteLevel;
}

export function NoteLevelToJSON(value?: NoteLevel | null): any {
    return value as any;
}

