/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';

/**
 * 
 * @export
 * @interface UpcomingAmyShipmentTasks
 */
export interface UpcomingAmyShipmentTasks {
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    missedPickupsShipments: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    missedDeliveryShipments: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    trackingNotUpdatedShipents: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    expectedDeliveryInPastShipments: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    onHoldShipments: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    bookingRequestedShipments: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    bookingFailedShipments: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof UpcomingAmyShipmentTasks
     */
    bookedShipmentsMissingPickupNumber: Array<CompanyShipmentQuote>;
}

/**
 * Check if a given object implements the UpcomingAmyShipmentTasks interface.
 */
export function instanceOfUpcomingAmyShipmentTasks(value: object): boolean {
    if (!('missedPickupsShipments' in value)) return false;
    if (!('missedDeliveryShipments' in value)) return false;
    if (!('trackingNotUpdatedShipents' in value)) return false;
    if (!('expectedDeliveryInPastShipments' in value)) return false;
    if (!('onHoldShipments' in value)) return false;
    if (!('bookingRequestedShipments' in value)) return false;
    if (!('bookingFailedShipments' in value)) return false;
    if (!('bookedShipmentsMissingPickupNumber' in value)) return false;
    return true;
}

export function UpcomingAmyShipmentTasksFromJSON(json: any): UpcomingAmyShipmentTasks {
    return UpcomingAmyShipmentTasksFromJSONTyped(json, false);
}

export function UpcomingAmyShipmentTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpcomingAmyShipmentTasks {
    if (json == null) {
        return json;
    }
    return {
        
        'missedPickupsShipments': ((json['missedPickupsShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'missedDeliveryShipments': ((json['missedDeliveryShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'trackingNotUpdatedShipents': ((json['trackingNotUpdatedShipents'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'expectedDeliveryInPastShipments': ((json['expectedDeliveryInPastShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'onHoldShipments': ((json['onHoldShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'bookingRequestedShipments': ((json['bookingRequestedShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'bookingFailedShipments': ((json['bookingFailedShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'bookedShipmentsMissingPickupNumber': ((json['bookedShipmentsMissingPickupNumber'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
    };
}

export function UpcomingAmyShipmentTasksToJSON(value?: UpcomingAmyShipmentTasks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'missedPickupsShipments': ((value['missedPickupsShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'missedDeliveryShipments': ((value['missedDeliveryShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'trackingNotUpdatedShipents': ((value['trackingNotUpdatedShipents'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'expectedDeliveryInPastShipments': ((value['expectedDeliveryInPastShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'onHoldShipments': ((value['onHoldShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'bookingRequestedShipments': ((value['bookingRequestedShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'bookingFailedShipments': ((value['bookingFailedShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'bookedShipmentsMissingPickupNumber': ((value['bookedShipmentsMissingPickupNumber'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
    };
}

