import { Button, Form, Statistic, Table, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useProfitLossApi, useShipmentsApi } from "../Apis/Apis";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import { DownloadCsvButton } from "../Components/DownloadCsvButton";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { ShipmentStatusTag } from "../Components/ShipmentStatusTag";
import Stack from "../Components/Stack";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Weight } from "../Components/Weight";
import { safeColumns } from "../Helpers/safeColumns";
import Spacer from "../Spacer";
import { CompanyShipmentQuote, Shipment } from "../generated-openapi-client";

interface ShipmentTableProps {
  shipments: CompanyShipmentQuote[];
}

function ShipmentTable(props: ShipmentTableProps) {
  const shipments = props.shipments;

  return (
    <>
      <Table
        style={{
          width: "100%",
          border: "1px solid #ccc",
        }}
        columns={safeColumns<CompanyShipmentQuote>([
          {
            title: "Booking Company",
            dataIndex: ["company", "companyName"],
            key: "companyName",
            render: function (text, o) {
              return (
                <Stack align="left">
                  <div>{text}</div>
                  <Spacer height={8} />
                  {o.shipment.tags?.map((s) => (
                    <Tag color="orange">{s}</Tag>
                  ))}
                  {o.shipment.problems?.map((s) => (
                    <Tag color="magenta">🚩 {s}</Tag>
                  ))}
                  <ShipmentStatusTag status={o.shipment.state} />
                </Stack>
              );
            },
            width: "230px",
          },

          {
            title: "Carrier",
            dataIndex: ["quote", "carrierIdentifier"],
            key: "carrierIdentifier",
            render: (text, o) => (
              <CarrierLogo
                carrierIdentifier={text}
                brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
                width={80}
                height={30}
              />
            ),
            width: "100px",
          },
          {
            title: "Pro",
            dataIndex: ["shipment", "proNumber"],
            key: "proNumber",
            render: (text) => <div>{text}</div>,
            width: "100px",
          },
          {
            title: "Pickup Date",
            dataIndex: ["shipment", "pickupDate"],
            key: "pickupDate",
            render: (text) => <div>{text}</div>,
            width: "100px",
            sorter: function (
              a: CompanyShipmentQuote,
              b: CompanyShipmentQuote
            ) {
              return (
                moment(a.shipment.pickupDate).valueOf() -
                moment(b.shipment.pickupDate).valueOf()
              );
            },
            defaultSortOrder: "descend",
            sortDirections: ["descend", "ascend", "descend"],
          },
          {
            title: "Companies",
            dataIndex: ["shipment"],
            key: "companies",
            render: (shipment: Shipment) => (
              <Stack align="left">
                <div>From: {shipment.pickupLocation?.businessName}</div>
                <div>To: {shipment.deliveryLocation?.businessName}</div>
                <div>
                  {shipment.totalHandlingUnits} skids,{" "}
                  <Weight>{shipment.totalWeight!!}</Weight>
                </div>
              </Stack>
            ),
            width: "200px",
          },
          {
            title: "Actions",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipment.shipmentId",
            render: function (shipmentId) {
              return <ViewShipmentButton shipmentId={shipmentId} />;
            },
            width: "150px",
          },
        ])}
        dataSource={shipments}
        pagination={false}
      />
    </>
  );
}

export function ShipmentsPerCarrierScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [shipments, setShipments] = useState<
    CompanyShipmentQuote[] | undefined
  >(undefined);
  const [error, setError] = useState();
  const createProfitLossApi = useProfitLossApi();
  const [carrierIdentifier, setCarrierIdentifier] = useState<string>("fedex");
  const profitLossUrl = `/profit-loss?carrierIdentifier=${carrierIdentifier}`;
  const comparisonUrl = `/carrier-comparison-report?carrierIdentifier=${carrierIdentifier}`;

  async function load() {
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.getShipmentsPerCarrier({
        carrierIdentifier,
      });
      setShipments(response);
    } catch (e: any) {
      setError(e.toString());
    }
  }

  useEffect(
    function () {
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [carrierIdentifier]
  );

  if (error !== undefined) {
    return <>❌ An error occured : {JSON.stringify(error, null, "\t")}</>;
  } else if (shipments === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        {/* @ts-ignore */}
        <Page
          title="Carriers"
          tags={[]}
          extra={[
            <HorizontalStack align="right" width="100%" verticalAlign="middle">
              <Form.Item label="Filter by carrier">
                <CarrierFilter
                  allowClear={false}
                  onFilter={function (carrierIdentifier) {
                    setCarrierIdentifier(carrierIdentifier!!);
                  }}
                  carrierIdentifier={carrierIdentifier}
                />
              </Form.Item>
            </HorizontalStack>,
          ]}
          stats={
            <HorizontalStack>
              <Statistic
                title="Shipments"
                value={shipments.length}
                style={{
                  margin: "0 32px 0 0",
                }}
              />
            </HorizontalStack>
          }
        >
          <PageTitle>Carriers</PageTitle>

          <Spacer height={32} />
          <HorizontalStack>
            <Button href={profitLossUrl}>Profit/Loss Report</Button>
            <Spacer width={16} />
            <Button href={comparisonUrl}>Comparison Report</Button>
            <DownloadCsvButton
              callback={async function () {
                const profitLossApi = await createProfitLossApi();
                return await profitLossApi.generateCarrierComparisonReportCsv({
                  carrierIdentifier,
                });
              }}
              title="Download Comparison Report"
              filename={`${carrierIdentifier}-comparison.csv`}
            />
          </HorizontalStack>
          <Spacer height={64} />

          <ShipmentTable shipments={shipments} />
        </Page>
      </>
    );
  }
}
