/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeGraphPeriodData
 */
export interface TimeGraphPeriodData {
    /**
     * 
     * @type {string}
     * @memberof TimeGraphPeriodData
     */
    firstDate: string;
    /**
     * 
     * @type {number}
     * @memberof TimeGraphPeriodData
     */
    value: number;
}

/**
 * Check if a given object implements the TimeGraphPeriodData interface.
 */
export function instanceOfTimeGraphPeriodData(value: object): boolean {
    if (!('firstDate' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function TimeGraphPeriodDataFromJSON(json: any): TimeGraphPeriodData {
    return TimeGraphPeriodDataFromJSONTyped(json, false);
}

export function TimeGraphPeriodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeGraphPeriodData {
    if (json == null) {
        return json;
    }
    return {
        
        'firstDate': json['firstDate'],
        'value': json['value'],
    };
}

export function TimeGraphPeriodDataToJSON(value?: TimeGraphPeriodData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstDate': value['firstDate'],
        'value': value['value'],
    };
}

