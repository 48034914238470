import { Form, Statistic, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCompaniesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Dollar } from "../Components/Dollar";
import { LeadSourceSelect } from "../Components/LeadSourceSelect";
import { Loading } from "../Components/Loading";
import { MonthlyWeeklyRangePicker } from "../Components/MonthlyWeeklyRangePicker";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Percentage } from "../Components/Percentage";
import Stack from "../Components/Stack";
import { StatsRow } from "../Components/StatsRow";
import { ViewCompanyButton } from "../Components/ViewCompanyButton";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import { sum } from "../Helpers/sum";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { CompaniesBookedVsQuotedReport } from "../generated-openapi-client";
import { CompaniesBookedVsQuotedReportLine } from "../generated-openapi-client/models/CompaniesBookedVsQuotedReportLine";

const { Title } = Typography;

interface BookedVsQuotedControlBarProps {
  startDate: string;
  endDate: string;
  filterByDateRange: (
    startDate: string | undefined,
    endDate: string | undefined
  ) => void;
  leadSource: string | undefined;
  setLeadSource: (_: string | undefined) => void;
}

function BookedVsQuotedControlBar(props: BookedVsQuotedControlBarProps) {
  return (
    <ButtonRow>
      <MonthlyWeeklyRangePicker {...props} />
      <Form.Item label="Lead Source">
        <LeadSourceSelect
          leadSource={props.leadSource}
          setLeadSource={props.setLeadSource}
        />
      </Form.Item>
    </ButtonRow>
  );
}

interface BookedVsQuotedTableProps {
  data: CompaniesBookedVsQuotedReportLine[];
  startDate: string;
  endDate: string;
}

function missingUnknownOtherColor(leadSource: string): string | undefined {
  if (
    leadSource === "Unknown" ||
    leadSource === "Other" ||
    leadSource === "Missing"
  ) {
    return Colors.Red;
  } else {
    return undefined;
  }
}

function BookedVsQuotedTable(props: BookedVsQuotedTableProps) {
  const columns: DataTableColumn<CompaniesBookedVsQuotedReportLine>[] = [
    {
      title: "Company",
      render: (o) => <div>{o.companyName}</div>,
    },
    {
      title: "Lead Source",
      render: (o) => (
        <div style={{ color: missingUnknownOtherColor(o.leadSource) }}>
          {o.leadSource}
        </div>
      ),
    },
    {
      title: "Frequency",
      render: (o) => (
        <div style={{ color: missingUnknownOtherColor(o.shipmentFrequency) }}>
          {o.shipmentFrequency}
        </div>
      ),
    },
    {
      title: "Temperature",
      render: (o) => (
        <div style={{ color: missingUnknownOtherColor(o.temperature) }}>
          {o.temperature}
        </div>
      ),
    },
    {
      title: "Acceptance Rate",
      render: (o) => <Percentage>{o.acceptanceRate}</Percentage>,
    },
    {
      title: "Total Quotes",
      render: (o) => <div>{o.totalQuotes}</div>,
    },
    {
      title: "Total Unique Quotes",
      render: (o) => <div>{o.totalUniqueQuotes}</div>,
    },
    {
      title: "Total Booked",
      render: (o) => <div>{o.totalShipmentsBooked}</div>,
    },
    {
      title: "Revenue",
      render: (o) => <Dollar>{o.totalRevenue}</Dollar>,
    },
    {
      title: "Actions",
      render: function (o) {
        return (
          <ViewCompanyButton
            companyId={o.companyId}
            tab="quotes"
            startDate={props.startDate}
            endDate={props.endDate}
            openInNewTab
          />
        );
      },
    },
  ];

  return <DataTable pagination={false} columns={columns} data={props.data} />;
}

export function BookedVsQuotedReportScreen() {
  const navigate = useNavigate();
  const query = useQuery();
  const queryStartDate = query.startDate as string;
  const queryEndDate = query.endDate as string;

  function defaultStartDate() {
    if (queryStartDate) {
      return queryStartDate;
    }
    return moment().startOf("month").format("YYYY-MM-DD");
  }

  function defaultEndDate() {
    if (queryEndDate) {
      return queryEndDate;
    }
    return moment().endOf("month").format("YYYY-MM-DD");
  }

  const [startDate, setStartDate] = useState<string>(defaultStartDate());
  const [leadSource, setLeadSource] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string>(defaultEndDate());

  function updateUrl() {
    navigate(`/booked-vs-quoted?startDate=${startDate}&endDate=${endDate}`, {
      replace: true,
    });
  }

  useEffect(
    function () {
      updateUrl();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate]
  );

  console.log(`startDate,endDate`, { startDate, endDate });
  const [report, setReport] = useState<
    undefined | CompaniesBookedVsQuotedReport
  >();
  const createCompaniesApi = useCompaniesApi();

  async function refresh() {
    console.log("refreshing");
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.companiesBookedVsQuotedReport({
      startDate,
      endDate,
      leadSource,
    });
    setReport(response);
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, leadSource]
  );

  if (report === undefined) {
    return <Loading />;
  } else {
    const totalRevenue = sum(report.booked, (o) => o.totalRevenue);
    const totalShipmentsBooked = sum(
      report.booked,
      (o) => o.totalShipmentsBooked
    );
    const totalCompaniesBooked = report.booked.length;
    const totalCompaniesQuoted = report.quoted.length;

    return (
      <Page
        title="Booked vs Quoted Report"
        tags={[]}
        extra={[
          <BookedVsQuotedControlBar
            startDate={startDate}
            endDate={endDate}
            filterByDateRange={function (sd, ed) {
              console.log(`sd,ed = ${sd},${ed}`);
              setStartDate(sd || defaultStartDate());
              setEndDate(ed || defaultEndDate());
            }}
            leadSource={leadSource}
            setLeadSource={setLeadSource}
          />,
        ]}
      >
        <PageTitle>Booked vs Quoted Report</PageTitle>
        <Stack align="left">
          <StatsRow>
            <Statistic
              title="Total Revenue"
              value={formatAsCurrency(totalRevenue)}
            />
            <Statistic
              title="Total Shipments Booked"
              value={totalShipmentsBooked}
            />
            <Statistic title="Companies Booked" value={totalCompaniesBooked} />
            <Statistic
              title="Companies only quoted"
              value={totalCompaniesQuoted}
            />
          </StatsRow>
          <Spacer height={32} />
          <Title level={4}>Companies that booked</Title>
          <BookedVsQuotedTable
            data={report.booked}
            startDate={startDate}
            endDate={endDate}
          />
          <Spacer height={64} />
          <Title level={4}>Companies that quoted</Title>
          <BookedVsQuotedTable
            data={report.quoted}
            startDate={startDate}
            endDate={endDate}
          />
        </Stack>
      </Page>
    );
  }
}
