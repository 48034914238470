import { NoteCategory } from "../../generated-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import { EnumDropdown, OptionalEnumDropdown } from "./EnumDropdown";

export const NoteCategoryDropdown = EnumDropdown<
  NoteCategory,
  typeof NoteCategory
>(NoteCategory);

export const OptionalNoteCategoryDropdown = OptionalEnumDropdown<
  NoteCategory,
  typeof NoteCategory
>(NoteCategory, "No filter", function (nc) {
  switch (nc) {
    case NoteCategory.Billing:
      return "Billing";
    case NoteCategory.Booking:
      return "Booking";
    case NoteCategory.Claim:
      return "Claim";
    case NoteCategory.General:
      return "General";
    case NoteCategory.Quoting:
      return "Quoting";
    case NoteCategory.Tracking:
      return "Tracking";
    default:
      assertNever(nc);
  }
});
