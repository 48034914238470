import { LineItem } from "../generated-openapi-client";
import { sum } from "./sum";

export function calculatePercentageOfTrailer(lineItems: LineItem[]): number {
  const standardTrailerVolume = (53 * 12 * 96 * 96) / (12 * 12 * 12);

  const shipmentVolume = sum(lineItems, function (li) {
    const height = li.isStackable ? li.height : 96;
    const volume =
      (li.numberHandlingUnits!! * li.width!! * li.length!! * height!!) /
      (12 * 12 * 12);
    return volume;
  });

  return (shipmentVolume / standardTrailerVolume) * 100;
}
