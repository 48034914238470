/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { NameEmailPhone } from './NameEmailPhone';
import {
    NameEmailPhoneFromJSON,
    NameEmailPhoneFromJSONTyped,
    NameEmailPhoneToJSON,
} from './NameEmailPhone';

/**
 * 
 * @export
 * @interface CustomerContactsTabData
 */
export interface CustomerContactsTabData {
    /**
     * 
     * @type {NameEmailPhone}
     * @memberof CustomerContactsTabData
     */
    quoter: NameEmailPhone;
    /**
     * 
     * @type {NameEmailPhone}
     * @memberof CustomerContactsTabData
     */
    booker?: NameEmailPhone;
    /**
     * 
     * @type {NameEmailPhone}
     * @memberof CustomerContactsTabData
     */
    pickup?: NameEmailPhone;
    /**
     * 
     * @type {NameEmailPhone}
     * @memberof CustomerContactsTabData
     */
    delivery?: NameEmailPhone;
    /**
     * 
     * @type {Contact}
     * @memberof CustomerContactsTabData
     */
    emergency?: Contact;
}

/**
 * Check if a given object implements the CustomerContactsTabData interface.
 */
export function instanceOfCustomerContactsTabData(value: object): boolean {
    if (!('quoter' in value)) return false;
    return true;
}

export function CustomerContactsTabDataFromJSON(json: any): CustomerContactsTabData {
    return CustomerContactsTabDataFromJSONTyped(json, false);
}

export function CustomerContactsTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerContactsTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'quoter': NameEmailPhoneFromJSON(json['quoter']),
        'booker': json['booker'] == null ? undefined : NameEmailPhoneFromJSON(json['booker']),
        'pickup': json['pickup'] == null ? undefined : NameEmailPhoneFromJSON(json['pickup']),
        'delivery': json['delivery'] == null ? undefined : NameEmailPhoneFromJSON(json['delivery']),
        'emergency': json['emergency'] == null ? undefined : ContactFromJSON(json['emergency']),
    };
}

export function CustomerContactsTabDataToJSON(value?: CustomerContactsTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quoter': NameEmailPhoneToJSON(value['quoter']),
        'booker': NameEmailPhoneToJSON(value['booker']),
        'pickup': NameEmailPhoneToJSON(value['pickup']),
        'delivery': NameEmailPhoneToJSON(value['delivery']),
        'emergency': ContactToJSON(value['emergency']),
    };
}

