import { Form, Input, message, Modal } from "antd";

import MenuItem from "antd/lib/menu/MenuItem";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import { OptionalCountryCodeDropdown } from "../../Components/CountryCodeDropdown";
import Stack from "../../Components/Stack";
import { TextListInput } from "../../Components/TextListInput";
import { isNotBlank } from "../../Helpers/isNotBlank";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { ShipmentFrequencyDropdown } from "./ShipmentFrequencyDropdown";
import { TemperatureDropdown } from "./TemperatureDropdown";

export function EditCompanyMenuItem(props: CompanyTabProps) {
  const { company } = props.data;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyName, setCompanyName] = useState(company.companyName ?? "");
  const [phone, setPhone] = useState(company.phone ?? "");
  const [billingAddressLine, setBillingAddressLine] = useState(
    company.billingAddressLine ?? ""
  );
  const [billingCity, setBillingCity] = useState(company.billingCity ?? "");
  const [billingState, setBillingState] = useState(company.billingState ?? "");
  const [billingCountry, setBillingCountry] = useState(company.billingCountry);
  const [billingPostalCode, setBillingPostalCode] = useState(
    company.billingPostalCode ?? ""
  );

  const [leadSource, setLeadSource] = useState(company.leadSource ?? "");

  const [associatedProfessionalDomain, setAssociatedProfessionalDomain] =
    useState(company.associatedProfessionalDomain ?? []);

  const [knownGroupEmailAddresses, setKnownGroupEmailAddresses] = useState(
    company.knownGroupEmailAddresses ?? []
  );

  const [temperature, setTemperature] = useState(
    company.temperature ?? undefined
  );
  const [shipmentFrequency, setShipmentFrequency] = useState(
    company.shipmentFrequency ?? undefined
  );
  const [saving, setSaving] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    // If a temp was already set, then we should not mark it empty
    if (isNotBlank(company.temperature)) {
      if (temperature === "" || temperature === undefined) {
        message.warn("Temperature should not be empty");
        return;
      }
    }

    // If a frequency was already set, then we should not mark it empty
    if (isNotBlank(company.shipmentFrequency)) {
      if (shipmentFrequency === "" || shipmentFrequency === undefined) {
        message.warn("Freight volume should not be empty");
        return;
      }
    }

    if (companyName.trim() === "") {
      message.warn("Account must have a name");
      return;
    }

    setSaving(true);

    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.editCompany({
        editCompanyInput: {
          companyId: company.companyId!!,
          companyName,
          phone,
          billingAddressLine,
          billingCity,
          billingState,
          billingCountry,
          billingPostalCode,
          leadSource,
          temperature,
          shipmentFrequency,
          associatedProfessionalDomain,
          knownGroupEmailAddresses,
        },
      });
      await props.onRefresh();
      message.success("Saved!");
    } catch (e) {
      message.error("Eek. Something went wrong");
    }
    setSaving(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Edit Account"
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={saving}
        onCancel={handleCancel}
        width={1400}
      >
        <Form
          style={{ width: "1400px" }}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 10, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Account Name">
            <Input
              value={companyName}
              onChange={function (e) {
                setCompanyName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Account Phone">
            <Input
              value={phone}
              onChange={function (e) {
                setPhone(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Address Street">
            <Input
              value={billingAddressLine}
              onChange={function (e) {
                setBillingAddressLine(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Address City">
            <Input
              value={billingCity}
              onChange={function (e) {
                setBillingCity(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Address State">
            <Input
              value={billingState}
              onChange={function (e) {
                setBillingState(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Address Country">
            <OptionalCountryCodeDropdown
              value={billingCountry}
              setValue={setBillingCountry}
            />
          </Form.Item>
          <Form.Item label="Address Postal Code">
            <Input
              value={billingPostalCode}
              onChange={function (e) {
                setBillingPostalCode(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Lead Source">
            <Input
              value={leadSource}
              onChange={function (e) {
                setLeadSource(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Update Temperature">
            <TemperatureDropdown
              value={temperature}
              onChange={setTemperature}
            />
          </Form.Item>
          <Form.Item label="Update Freight Volume">
            <ShipmentFrequencyDropdown
              value={shipmentFrequency}
              onChange={setShipmentFrequency}
            />
          </Form.Item>
          <Form.Item label="Associated Professional Domain">
            <TextListInput
              list={associatedProfessionalDomain}
              setList={setAssociatedProfessionalDomain}
            />
          </Form.Item>
          <Form.Item
            label={
              <Stack align="right">
                <div>Known Group Email Addresses</div>
                <div style={{ color: Colors.LightText, fontSize: "12px" }}>
                  Separate addresses with comma
                </div>
              </Stack>
            }
          >
            <TextListInput
              list={knownGroupEmailAddresses}
              setList={setKnownGroupEmailAddresses}
            />
          </Form.Item>
        </Form>
      </Modal>
      <MenuItem onClick={showModal} style={{ width: "200px" }}>
        Edit Account
      </MenuItem>
    </>
  );
}
