import moment from "moment";

export function daysAgo(date: Date | string | undefined): number {
  // If no date is provided, assume current date
  const inputDate = date ? moment(date) : moment();

  // Calculate the difference in days
  const today = moment().startOf("day");
  const daysAgo = today.diff(inputDate.startOf("day"), "days");

  console.log(`daysAgo = ${daysAgo} inputDate=${date} today=${today}`);
  return daysAgo;
}
