import { Tag } from "antd";
import Colors from "./Colors";

interface CarrierInvoiceSourceTagProps {
  source: string;
}

function describe(state: string) {
  switch (state) {
    case "manually_uploaded":
      return "Manually Uploaded";
    case "processed_email":
      return "Processed Email";
    case "website_download":
      return "Website Download";
    case "edi":
      return "EDI";
    case "api":
      return "API";
  }
}

function color(state: string) {
  switch (state) {
    case "manually_uploaded":
      return Colors.Blue;
    case "processed_email":
      return Colors.Green;
    case "website_downloaded":
      return Colors.Gold;
    case "edi":
      return Colors.Red;
  }
}

function foregroundColor(state: string) {
  switch (state) {
    case "manually_uploaded":
      return "white";
    case "processed_email":
      return "white";
    case "website_downloaded":
      return "black";
    case "edi":
      return "white";
  }
}

export function CarrierInvoiceSourceTag(props: CarrierInvoiceSourceTagProps) {
  return (
    <Tag color={color(props.source)}>
      <div style={{ color: foregroundColor(props.source) }}>
        {describe(props.source)}
      </div>
    </Tag>
  );
}
