/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface CarrierChequeInformation
 */
export interface CarrierChequeInformation {
    /**
     * 
     * @type {string}
     * @memberof CarrierChequeInformation
     */
    makeOutTo: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierChequeInformation
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierChequeInformation
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierChequeInformation
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierChequeInformation
     */
    stateCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof CarrierChequeInformation
     */
    countryCode: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof CarrierChequeInformation
     */
    postalCode: string;
}

/**
 * Check if a given object implements the CarrierChequeInformation interface.
 */
export function instanceOfCarrierChequeInformation(value: object): boolean {
    if (!('makeOutTo' in value)) return false;
    if (!('address' in value)) return false;
    if (!('city' in value)) return false;
    if (!('state' in value)) return false;
    if (!('stateCode' in value)) return false;
    if (!('countryCode' in value)) return false;
    if (!('postalCode' in value)) return false;
    return true;
}

export function CarrierChequeInformationFromJSON(json: any): CarrierChequeInformation {
    return CarrierChequeInformationFromJSONTyped(json, false);
}

export function CarrierChequeInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierChequeInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'makeOutTo': json['makeOutTo'],
        'address': json['address'],
        'city': json['city'],
        'state': json['state'],
        'stateCode': json['stateCode'],
        'countryCode': CountryCodeFromJSON(json['countryCode']),
        'postalCode': json['postalCode'],
    };
}

export function CarrierChequeInformationToJSON(value?: CarrierChequeInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'makeOutTo': value['makeOutTo'],
        'address': value['address'],
        'city': value['city'],
        'state': value['state'],
        'stateCode': value['stateCode'],
        'countryCode': CountryCodeToJSON(value['countryCode']),
        'postalCode': value['postalCode'],
    };
}

