import { Form, Select, Switch, Tag, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useDebouncedCallback } from "use-debounce";
import { useCompaniesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import { ConfirmButtonWithTextArea } from "../Components/ConfirmButtonWithTextArea";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { MonthSelect } from "../Components/MonthSelect";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Panel } from "../Components/Panel";
import { ShowHideColumnsPopover } from "../Components/ShowHideColumnsPopover";
import Stack from "../Components/Stack";
import { ViewCompanyButton } from "../Components/ViewCompanyButton";
import { ViewJsonButton } from "../Components/ViewJsonButton";
import NonCorporateEmailData from "../Fixtures/NonCorporateEmailData.json";
import { assertNever } from "../Helpers/assertNever";
import { emojiForShipmentVolume } from "../Helpers/emojiForShipmentVolume";
import { isEmpty } from "../Helpers/isEmpty";
import { isBlank, isNotBlank } from "../Helpers/isNotBlank";
import { countIf, hasDuplicates, sum } from "../Helpers/sum";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";

import { removePrefix } from "../Helpers/removePrefix";
import {
  ApolloMapLocationFilterType,
  QualificationStatus,
} from "../generated-openapi-client";
import { ListCompaniesRow } from "../generated-openapi-client/models/ListCompaniesRow";
import { PeriodFormats } from "./FinanceComponents/generatePeriodInfo";
import { applyDuplicateDomains } from "./ListCompaniesScreen/helpers/applyDuplicateDomains";
import { LocationDropdown } from "./MapScreenComponents/LocationDropdown";
import { CompanySalesCallsSnoozedTag } from "./ViewCompanyScreenComponents/CompanySalesCallsSnoozedTag";
import { CompanyStarredTag } from "./ViewCompanyScreenComponents/CompanyStarredTag";
import { ValuesDropdown } from "./ViewShipmentScreenComponents/ValuesDropdown";

enum CompanyFilterType {
  Unqualified = "Unqualified",
  NeverQuoted = "NeverQuoted",
  Quoted = "Quoted",
  ShippingRookie = "ShippingRookie",
  RegularShipper = "RegularShipper",
  AtRisk = "AtRisk",
  Lost = "Lost",
  NeedsVerification = "NeedsVerification",
  RecentSignup = "RecentSignup",
  SalesCallsSnoozed = "SalesCallsSnoozed",
  OnCredit = "OnCredit",
  NeverCalled = "NeverCalled",
  NoSignificantCalls = "NoSignificantCalls",
  BrokerAccount = "BrokerAccount",
  PersonalAccount = "PersonalAccount",
  BusinessAccount = "CompanyAccount",

  // Problems
  NoSalesContacts = "NoSalesContacts",
  MissingDomain = "MissingDomain",
  DuplicateDomains = "DuplicateDomains",
  SalesContactsWithDuplicateEmails = "SalesContactsWithDuplicateEmails",
  SalesContactsWithDuplicateNames = "SalesContactsWithDuplicateNames",
  SalesContactsWithEmailsNotMatchingDomain = "SalesContactsWithNamesNotMatchingDomain",
  SalesContactEmailOnMultipleCompanies = "SalesContactEmailOnMultipleCompanies",
  DuplicateCompanyNames = "DuplicateCompanyNames",
  MissingAddress = "MissingAddress",
  MissingLatitudeLongitude = "MissingLatitudeLongitude",
  MissingPrimaryContact = "MissingPrimaryContact",
  MissingTemperature = "MissingTemperature",
  MissingFreightVolume = "MissingFreightVolume",
}

function isFilterAProblem(companyFilterType: CompanyFilterType): boolean {
  switch (companyFilterType) {
    case CompanyFilterType.Unqualified:
    case CompanyFilterType.NeverQuoted:
    case CompanyFilterType.Quoted:
    case CompanyFilterType.ShippingRookie:
    case CompanyFilterType.RegularShipper:
    case CompanyFilterType.AtRisk:
    case CompanyFilterType.Lost:
    case CompanyFilterType.NeedsVerification:
    case CompanyFilterType.RecentSignup:
    case CompanyFilterType.SalesCallsSnoozed:
    case CompanyFilterType.OnCredit:
    case CompanyFilterType.NeverCalled:
    case CompanyFilterType.NoSignificantCalls:
    case CompanyFilterType.BrokerAccount:
    case CompanyFilterType.PersonalAccount:
    case CompanyFilterType.BusinessAccount:
      return false;

    case CompanyFilterType.NoSalesContacts:
    case CompanyFilterType.MissingDomain:
    case CompanyFilterType.DuplicateDomains:
    case CompanyFilterType.SalesContactsWithDuplicateEmails:
    case CompanyFilterType.SalesContactsWithDuplicateNames:
    case CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain:
    case CompanyFilterType.SalesContactEmailOnMultipleCompanies:
    case CompanyFilterType.DuplicateCompanyNames:
    case CompanyFilterType.MissingAddress:
    case CompanyFilterType.MissingLatitudeLongitude:
    case CompanyFilterType.MissingPrimaryContact:
    case CompanyFilterType.MissingFreightVolume:
    case CompanyFilterType.MissingTemperature:
      return true;
    default:
      assertNever(companyFilterType);
  }
}

enum TemperatureFilter {
  All = "All",
  Hot = "Hot",
  Warm = "Warm",
  Cold = "Cold",
  Dead = "Dead",
}

function AddCompanyButton() {
  const createCompaniesApi = useCompaniesApi();
  const navigate = useNavigate();

  async function onConfirm(companyName: string) {
    const companiesApi = await createCompaniesApi();

    const response = await companiesApi.createCompany({
      companyName,
    });
    const url = `/view-company?companyId=${response.companyId}`;
    navigate(url);
  }

  return (
    <ConfirmButtonWithTextArea
      onConfirm={onConfirm}
      question="What is the name of the company you want to add?"
      okText="Create Company"
      tooltip="Create a new company"
      placeholder="Company Name"
    >
      Create Company
    </ConfirmButtonWithTextArea>
  );
}

const CellLabel = styled.div`
  color: ${Colors.LightText};
  font-size: 10px;
`;

const CellSubText = styled.div`
  color: ${Colors.LightText};
  font-size: 9px;
`;

function applyUnqualifiedFilter(c: ListCompaniesRow): boolean {
  return c.qualificationStatus === QualificationStatus.Unqualified;
}

function applyNeverQuotedFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified && c.quotesRan === 0
  );
}

function applyQuotedFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.quotesRan > 0 &&
    c.shipmentsBooked === 0
  );
}

function applyShippingRookieFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.shipmentsBooked > 0 &&
    c.shipmentsBooked < 5
  );
}

function applyRegularShipperFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.shipmentsBooked >= 5
  );
}

function applyNeedsVerificationFilter(c: ListCompaniesRow): boolean {
  return c.needsVerification && !c.verificationDenied;
}

function applyRecentSignupsFilter(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus === QualificationStatus.Qualified &&
    c.quotesRan > 0 &&
    moment(c.createdAt).isAfter(moment().subtract(30, "days"))
  );
}

function applySalesCallsSnoozedFilter(c: ListCompaniesRow): boolean {
  return c.snoozeSalesCallsUntil !== undefined;
}

function applyOnCreditFilter(c: ListCompaniesRow): boolean {
  return c.paymentTermsDays > 0;
}

function applyNeverCalledFilter(c: ListCompaniesRow): boolean {
  return c.lastCallDate === undefined;
}

function applyNoSignificantCallsFilter(c: ListCompaniesRow): boolean {
  return c.lastSignificantCallDate === undefined;
}

function applyNoSalesContactsFilter(c: ListCompaniesRow): boolean {
  return (
    c.salesContacts.length === 0 &&
    // Ignore FreightSimple Ops
    c.companyId !== "7c99c230-d780-476a-82d7-43f69f77653f"
  );
}

function applyDuplicateCompanyNames(
  companies: ListCompaniesRow[],
  c: ListCompaniesRow
): boolean {
  return countIf(companies, (cc) => c.companyName === cc.companyName) > 1;
}

function applyMissingAddress(c: ListCompaniesRow): boolean {
  if (
    c.isPersonal ||
    c.isBroker ||
    c.temperature === "☠️ Dead" ||
    c.shipmentFrequency === "SingleShipment"
  ) {
    return false;
  }

  return (
    isBlank(c.billingCity) ||
    isBlank(c.billingState) ||
    isBlank(c.billingAddressLine) ||
    isBlank(c.billingPostalCode) ||
    isBlank(c.billingCountry)
  );
}

function applyMissingLatitudeLongitude(c: ListCompaniesRow): boolean {
  if (
    c.isPersonal ||
    c.isBroker ||
    c.temperature === "☠️ Dead" ||
    c.shipmentFrequency === "SingleShipment"
  ) {
    return false;
  }

  return (
    c.billingLatitude === undefined ||
    c.billingLatitude === 0 ||
    c.billingLongitude === undefined ||
    c.billingLongitude === 0
  );
}

function applyMissingTemperature(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus == QualificationStatus.Qualified &&
    (isBlank(c.temperature) || c.temperature == "-")
  );
}

function applyMissingFreightVolume(c: ListCompaniesRow): boolean {
  return (
    c.qualificationStatus == QualificationStatus.Qualified &&
    (isBlank(c.shipmentFrequency) || c.shipmentFrequency == "-")
  );
}

function applyMissingPrimaryContact(c: ListCompaniesRow): boolean {
  return isBlank(c.defaultSalesContactId);
}

function applyAtRisk(c: ListCompaniesRow): boolean {
  return (
    moment(c.lastBookedDate).isBefore(moment().subtract(2, "months")) &&
    moment(c.lastBookedDate).isAfter(moment().subtract(3, "months"))
  );
}

function applyLost(c: ListCompaniesRow): boolean {
  return moment(c.lastBookedDate).isBefore(moment().subtract(3, "months"));
}

function applyMissingDomain(
  companies: ListCompaniesRow[],
  c: ListCompaniesRow
): boolean {
  return (
    isEmpty(c.associatedProfessionalDomain) &&
    !c.isPersonal &&
    !c.hasNonProfessionalDomain &&
    c.salesContacts.some((sc) => sc.email)
  );
}

function applySalesContactsWithDuplicateEmails(c: ListCompaniesRow): boolean {
  // We need to ignore the emails if they are known group emails

  const salesContactsWithoutKnownGroupEmails = c.salesContacts.filter(function (
    sc
  ) {
    if (
      sc.email !== undefined &&
      c.knownGroupEmailAddresses?.includes(sc.email)
    ) {
      return false;
    } else {
      return true;
    }
  });

  return hasDuplicates(salesContactsWithoutKnownGroupEmails, (sc) => sc.email);
}

function applySalesContactsWithDuplicateNames(c: ListCompaniesRow): boolean {
  return hasDuplicates(c.salesContacts, (sc) => sc.name);
}

function applySalesContactsWithEmailsNotMatchingDomain(
  companyRow: ListCompaniesRow
): boolean {
  const apds = companyRow.associatedProfessionalDomain;

  const salesContactEmailDomains = companyRow.salesContacts
    .filter((sc) => sc.email)
    .map((sc) => sc.email!.split("@")[1]);

  let permittedDomains = [...apds];
  if (companyRow.hasNonProfessionalDomain) {
    permittedDomains = [
      ...permittedDomains,
      ...NonCorporateEmailData.map((ed) => removePrefix(ed, "@")),
    ];
  }

  return salesContactEmailDomains.some((ed) => !permittedDomains.includes(ed));
}

function applySalesContactEmailOnMultipleCompanies(
  companies: ListCompaniesRow[],
  c: ListCompaniesRow
): boolean {
  function countCompaniesWithSalesContactEmails(email: string | undefined) {
    if (isBlank(email)) {
      return 0;
    }

    return sum(companies, (c) =>
      c.salesContacts.some((sc) => sc.email === email) ? 1 : 0
    );
  }

  return (
    countIf(
      c.salesContacts.filter((sc) => sc),
      function (sc) {
        return countCompaniesWithSalesContactEmails(sc.email) > 1;
      }
    ) > 0
  );
}

function applyBrokerAccount(c: ListCompaniesRow): boolean {
  return c.isBroker;
}

function applyBusinessAccount(c: ListCompaniesRow): boolean {
  return !c.isBroker && !c.isPersonal;
}

function applyPersonalAccount(c: ListCompaniesRow): boolean {
  return c.isPersonal;
}

function applyFilter(
  filter: CompanyFilterType,
  companies: ListCompaniesRow[],
  c: ListCompaniesRow
): boolean {
  if (filter === undefined) {
    return true;
  }

  switch (filter) {
    case CompanyFilterType.Unqualified:
      return applyUnqualifiedFilter(c);
    case CompanyFilterType.NeverQuoted:
      return applyNeverQuotedFilter(c);
    case CompanyFilterType.Quoted:
      return applyQuotedFilter(c);
    case CompanyFilterType.ShippingRookie:
      return applyShippingRookieFilter(c);
    case CompanyFilterType.RegularShipper:
      return applyRegularShipperFilter(c);
    case CompanyFilterType.AtRisk:
      return applyAtRisk(c);
    case CompanyFilterType.Lost:
      return applyLost(c);
    case CompanyFilterType.NeedsVerification:
      return applyNeedsVerificationFilter(c);
    case CompanyFilterType.RecentSignup:
      return applyRecentSignupsFilter(c);
    case CompanyFilterType.SalesCallsSnoozed:
      return applySalesCallsSnoozedFilter(c);
    case CompanyFilterType.OnCredit:
      return applyOnCreditFilter(c);
    case CompanyFilterType.NeverCalled:
      return applyNeverCalledFilter(c);
    case CompanyFilterType.NoSignificantCalls:
      return applyNoSignificantCallsFilter(c);
    case CompanyFilterType.NoSalesContacts:
      return applyNoSalesContactsFilter(c);
    case CompanyFilterType.MissingDomain:
      return applyMissingDomain(companies, c);
    case CompanyFilterType.DuplicateDomains:
      return applyDuplicateDomains(companies, c).length > 0;
    case CompanyFilterType.SalesContactsWithDuplicateEmails:
      return applySalesContactsWithDuplicateEmails(c);
    case CompanyFilterType.SalesContactsWithDuplicateNames:
      return applySalesContactsWithDuplicateNames(c);
    case CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain:
      return applySalesContactsWithEmailsNotMatchingDomain(c);
    case CompanyFilterType.DuplicateCompanyNames:
      return applyDuplicateCompanyNames(companies, c);
    case CompanyFilterType.SalesContactEmailOnMultipleCompanies:
      return applySalesContactEmailOnMultipleCompanies(companies, c);
    case CompanyFilterType.MissingAddress:
      return applyMissingAddress(c);
    case CompanyFilterType.MissingLatitudeLongitude:
      return applyMissingLatitudeLongitude(c);
    case CompanyFilterType.MissingPrimaryContact:
      return applyMissingPrimaryContact(c);
    case CompanyFilterType.MissingTemperature:
      return applyMissingTemperature(c);
    case CompanyFilterType.MissingFreightVolume:
      return applyMissingFreightVolume(c);
    case CompanyFilterType.BrokerAccount:
      return applyBrokerAccount(c);
    case CompanyFilterType.PersonalAccount:
      return applyPersonalAccount(c);
    case CompanyFilterType.BusinessAccount:
      return applyBusinessAccount(c);
    default:
      assertNever(filter);
  }
}

interface TemperatureFilterDropdownProps {
  value: TemperatureFilter | undefined;
  setValue: (_: TemperatureFilter | undefined) => void;
}

function TemperatureFilterDropdown(props: TemperatureFilterDropdownProps) {
  return (
    <Select
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "150px" }}
      placeholder="Hiding Dead"
      allowClear
    >
      <Select.OptGroup label="General">
        <Select.Option value={TemperatureFilter.All}>
          Include Dead
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Specific">
        <Select.Option value={TemperatureFilter.Hot}>🔥 Hot</Select.Option>
        <Select.Option value={TemperatureFilter.Warm}>🌤️ Warm</Select.Option>
        <Select.Option value={TemperatureFilter.Cold}>🥶 Cold</Select.Option>
        <Select.Option value={TemperatureFilter.Dead}>☠️ Dead</Select.Option>
      </Select.OptGroup>
    </Select>
  );
}

interface CompanyFilterDropdownProps {
  value: CompanyFilterType | undefined;
  setValue: (_: CompanyFilterType | undefined) => void;
  companies: ListCompaniesRow[];
}

function countForFilterType(
  companies: ListCompaniesRow[],
  filter: CompanyFilterType
): number {
  return companies.filter(function (c) {
    return applyFilter(filter, companies, c);
  }).length;
}

function countForAllProblemFilterTypes(companies: ListCompaniesRow[]): number {
  let total = 0;

  Object.entries(CompanyFilterType).forEach(function (value) {
    const filter = value[1];

    if (isFilterAProblem(filter)) {
      total += countForFilterType(companies, filter);
    }
  });

  return total;
}

function CompanyFilterDropdown(props: CompanyFilterDropdownProps) {
  const [cache, setCache] = useState<Map<CompanyFilterType, number>>(new Map());

  useEffect(
    function () {
      setCache(new Map());
    },
    [props.companies]
  );

  const countForFilter = function (filter: CompanyFilterType): number {
    if (cache.has(filter)) {
      return cache.get(filter)!!;
    } else {
      const value = countForFilterType(props.companies, filter);
      cache.set(filter, value);
      return value;
    }
  };

  return (
    <Select
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "480px" }}
      placeholder="No filter"
      allowClear
    >
      <Select.OptGroup label="Lifecycle">
        <Select.Option value={CompanyFilterType.Unqualified}>
          Lead ({countForFilter(CompanyFilterType.Unqualified)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.NeverQuoted}>
          Never Quoted ({countForFilter(CompanyFilterType.NeverQuoted)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.Quoted}>
          Quoted, never booked ({countForFilter(CompanyFilterType.Quoted)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.ShippingRookie}>
          Booked 1-4 Shipments (
          {countForFilter(CompanyFilterType.ShippingRookie)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.RegularShipper}>
          Booked 5+ Shipments (
          {countForFilter(CompanyFilterType.RegularShipper)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.AtRisk}>
          At Risk ({countForFilter(CompanyFilterType.AtRisk)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.Lost}>
          Lost ({countForFilter(CompanyFilterType.Lost)})
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Account Type">
        <Select.Option value={CompanyFilterType.BusinessAccount}>
          Business Accounts ({countForFilter(CompanyFilterType.BusinessAccount)}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.BrokerAccount}>
          Broker Accounts ({countForFilter(CompanyFilterType.BrokerAccount)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.PersonalAccount}>
          Personal Accounts ({countForFilter(CompanyFilterType.PersonalAccount)}
          )
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Other">
        <Select.Option value={CompanyFilterType.NeedsVerification}>
          Needs Verification (
          {countForFilter(CompanyFilterType.NeedsVerification)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.RecentSignup}>
          Recent Signup (Last 30 days) (
          {countForFilter(CompanyFilterType.RecentSignup)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.SalesCallsSnoozed}>
          Sales Calls Snoozed (
          {countForFilter(CompanyFilterType.SalesCallsSnoozed)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.OnCredit}>
          On Credit ({countForFilter(CompanyFilterType.OnCredit)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.NeverCalled}>
          Never Called ({countForFilter(CompanyFilterType.NeverCalled)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.NoSignificantCalls}>
          No Significant Calls (
          {countForFilter(CompanyFilterType.NoSignificantCalls)})
        </Select.Option>
      </Select.OptGroup>

      <Select.OptGroup label="Problems">
        <Select.Option value={CompanyFilterType.NoSalesContacts}>
          No Sales Contacts ({countForFilter(CompanyFilterType.NoSalesContacts)}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingDomain}>
          Missing Domain ({countForFilter(CompanyFilterType.MissingDomain)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.DuplicateDomains}>
          Duplicate Domains (
          {countForFilter(CompanyFilterType.DuplicateDomains)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.DuplicateCompanyNames}>
          Duplicate Company Names (
          {countForFilter(CompanyFilterType.DuplicateCompanyNames)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingAddress}>
          Incomplete Address ({countForFilter(CompanyFilterType.MissingAddress)}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingLatitudeLongitude}>
          Missing Latitude/Longitude (
          {countForFilter(CompanyFilterType.MissingLatitudeLongitude)})
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingPrimaryContact}>
          Missing Primary Contact (
          {countForFilter(CompanyFilterType.MissingPrimaryContact)})
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactsWithDuplicateEmails}
        >
          Sales Contacts with duplicate emails (
          {countForFilter(CompanyFilterType.SalesContactsWithDuplicateEmails)})
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactsWithDuplicateNames}
        >
          Sales Contacts with duplicate names (
          {countForFilter(CompanyFilterType.SalesContactsWithDuplicateNames)})
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactEmailOnMultipleCompanies}
        >
          Sales Contacts Email on Multiple Companies (
          {countForFilter(
            CompanyFilterType.SalesContactEmailOnMultipleCompanies
          )}
          )
        </Select.Option>
        <Select.Option
          value={CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain}
        >
          Sales Contacts with emails not matching domain (
          {countForFilter(
            CompanyFilterType.SalesContactsWithEmailsNotMatchingDomain
          )}
          )
        </Select.Option>
        <Select.Option value={CompanyFilterType.MissingFreightVolume}>
          Missing Freight Volume (
          {countForFilter(CompanyFilterType.MissingFreightVolume)})
        </Select.Option>

        <Select.Option value={CompanyFilterType.MissingTemperature}>
          Missing Temperature (
          {countForFilter(CompanyFilterType.MissingTemperature)})
        </Select.Option>
      </Select.OptGroup>
    </Select>
  );
}

interface CompaniesTableProps {
  allCompanies: ListCompaniesRow[];
  companies: Array<ListCompaniesRow>;
}

export function emojiForTemperature(temp: string) {
  if (temp === "-") {
    return "";
  }

  return temp.split(" ")[0];
}

function companyNameDescription(o: ListCompaniesRow) {
  const uq =
    o.qualificationStatus === QualificationStatus.Unqualified ? "🎈" : "";
  const sv = emojiForShipmentVolume(o.shipmentFrequency);
  const temp = emojiForTemperature(o.temperature);
  return [uq, temp, o.companyName, sv].filter((o) => o).join(" ");
}

interface CompaniesTableRowProps {
  allCompanies: ListCompaniesRow[];
  companies: Array<ListCompaniesRow>;
  currentCompany: ListCompaniesRow;
}

function CompaniesTableRow(props: CompaniesTableRowProps) {
  const [duplicatedDomains] = useState<string[]>(
    applyDuplicateDomains(props.allCompanies, props.currentCompany)
  );

  return (
    <Stack align="left">
      <div>
        <div style={{ width: "200px" }}>
          {companyNameDescription(props.currentCompany)}
        </div>
      </div>
      <Spacer height={4} />
      <Stack align="left" style={{ marginLeft: "-8px", gap: "4px" }}>
        <CompanyStarredTag companyId={props.currentCompany.companyId} />
        <CompanySalesCallsSnoozedTag
          snoozeSalesCallsUntil={props.currentCompany.snoozeSalesCallsUntil}
        />
        {duplicatedDomains.length > 0 && (
          <Tag color="orange">
            ⚠️ Duplicate Domains:
            {duplicatedDomains.map((d, i) =>
              i === 0 ? <span> {d} </span> : <span>- {d} </span>
            )}
          </Tag>
        )}
        {applyDuplicateCompanyNames(
          props.allCompanies,
          props.currentCompany
        ) && <Tag color="orange">⚠️ Duplicate Company Name</Tag>}
        {applyMissingDomain(props.allCompanies, props.currentCompany) && (
          <Tag color="orange">⚠️ Missing Email Domain</Tag>
        )}
        {applyNoSalesContactsFilter(props.currentCompany) && (
          <Tag color="orange">⚠️ No Sales Contacts</Tag>
        )}
        {applySalesContactsWithDuplicateEmails(props.currentCompany) && (
          <Tag color="orange">⚠️ Sales contacts with dupe emails</Tag>
        )}
        {applySalesContactsWithDuplicateNames(props.currentCompany) && (
          <Tag color="orange">⚠️ Sales contacts with dupe names</Tag>
        )}
        {applySalesContactsWithEmailsNotMatchingDomain(
          props.currentCompany
        ) && <Tag color="orange">⚠️ Sales contacts do not match domain</Tag>}
        {applySalesContactEmailOnMultipleCompanies(
          props.allCompanies,
          props.currentCompany
        ) && <Tag color="orange">⚠️ Sales contact on multiple company</Tag>}
        {applyMissingFreightVolume(props.currentCompany) && (
          <Tag color="orange">⚠️ Missing Freight Volume</Tag>
        )}
        {applyMissingTemperature(props.currentCompany) && (
          <Tag color="orange">⚠️ Missing Temperature</Tag>
        )}
        {applyMissingPrimaryContact(props.currentCompany) && (
          <Tag color="orange">⚠️ Missing Primary Contact</Tag>
        )}
        {applyLost(props.currentCompany) && <Tag color="red">❌ Lost</Tag>}
        {applyAtRisk(props.currentCompany) && (
          <Tag color="orange">🚨 At Risk</Tag>
        )}
        ,
      </Stack>
    </Stack>
  );
}

export function CompaniesTable(props: CompaniesTableProps) {
  const [columns, setColumns] = useState<DataTableColumn<ListCompaniesRow>[]>([
    {
      key: "companyName",
      show: true,
      title: "Company Name",
      render: (o) => (
        <CompaniesTableRow
          allCompanies={props.allCompanies}
          companies={props.companies}
          currentCompany={o}
        />
      ),
      sorter: function (a, b) {
        return b.companyName.localeCompare(a.companyName);
      },
    },
    {
      title: "Account Type",
      show: false,
      render: (o) => {
        if (o.isBroker) {
          return <Tag color="purple">Broker Account</Tag>;
        }
        if (o.isPersonal) {
          return <Tag color="magenta">Personal Account</Tag>;
        }
        return <Tag color="default">Business Account</Tag>;
      },
    },
    {
      title: "Lead Source",
      key: "leadSource",
      show: true,
      render: (o) => <span>{o.leadSource}</span>,
      sorter: function (a, b) {
        return b.leadSource.localeCompare(a.leadSource);
      },
    },
    {
      title: "# Quotes",
      key: "quotes",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.quotesRan > 0 && <CellLabel>Quotes:</CellLabel>}
          {o.quotesRan > 0 && <div>{o.quotesRan}</div>}
          {o.quotesRan === 0 && <div>-</div>}
        </Stack>
      ),
      sorter: function (a, b) {
        return b.quotesRan - a.quotesRan;
      },
    },
    {
      title: "# Shipments",
      key: "shipments",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.shipmentsBooked > 0 && <CellLabel>Booked:</CellLabel>}
          {o.shipmentsBooked > 0 && <div>{o.shipmentsBooked}</div>}
          {o.shipmentsBooked === 0 && <div>-</div>}
        </Stack>
      ),
      sorter: function (a, b) {
        return b.shipmentsBooked - a.shipmentsBooked;
      },
    },
    {
      title: "Created",
      key: "created",
      show: false,
      render: (o) => (
        <Stack align="left">
          {o.createdAt && <CellLabel>Created:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.createdAt} />
        </Stack>
      ),
      sorter: function (a, b) {
        return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
      },
    },
    {
      title: "First Quoted",
      key: "firstQuoted",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.firstQuoteDate && <CellLabel>First Quote:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.firstQuoteDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          moment(a.firstQuoteDate).valueOf() -
          moment(b.firstQuoteDate).valueOf()
        );
      },
    },
    {
      title: "Last Quoted",
      key: "lastQuoted",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastQuotedDate && <CellLabel>Last Quote:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.lastQuotedDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          moment(a.lastQuotedDate).valueOf() -
          moment(b.lastQuotedDate).valueOf()
        );
      },
    },
    {
      title: "First Booked",
      key: "firstBooked",
      show: false,
      render: (o) => (
        <Stack align="left">
          {o.firstBookedDate && <CellLabel>First Booked:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.firstBookedDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          moment(a.firstBookedDate).valueOf() -
          moment(b.firstBookedDate).valueOf()
        );
      },
    },
    {
      title: "Last Booked",
      key: "lastBooked",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastBookedDate && <CellLabel>Last Booked:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.lastBookedDate} />
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          moment(a.lastBookedDate).valueOf() -
          moment(b.lastBookedDate).valueOf()
        );
      },
    },
    {
      title: "Last Sales Call",
      key: "lastSalesCall",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastCallDate && <CellLabel>Last Sales Call:</CellLabel>}
          <CreatedAt days skipDelta timestamp={o.lastCallDate} />
          {o.lastCallBy && <CellSubText>{o.lastCallBy}</CellSubText>}
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          moment(a.lastCallDate).valueOf() - moment(b.lastCallDate).valueOf()
        );
      },
    },
    {
      title: "Last Significant Sales Call",
      key: "lastSignificantSalesCall",
      show: true,
      render: (o) => (
        <Stack align="left">
          {o.lastSignificantCallDate && (
            <CellLabel>Last Significant Call:</CellLabel>
          )}
          <HorizontalStack>
            {isNotBlank(o.lastSignificantCallDate) && (
              <CreatedAt days skipDelta timestamp={o.lastSignificantCallDate} />
            )}
            {isBlank(o.lastSignificantCallDate) && (
              <div>No Significant Calls </div>
            )}
            {o.overdueSalesCall && (
              <div style={{ marginLeft: "4px" }}>
                <Tooltip
                  title={
                    <Stack align="left">
                      <div style={{ fontWeight: "600" }}>
                        Overdue Significant Call
                      </div>
                      <div>
                        Rules: Must be 2 weeks since last call. Must be not
                        snoozed. Must be beyond the threshold weeks since
                        significant call (4 weeks for occasionals, 2-4 for
                        weekly and daily depending on where they are in the
                        sales cycle
                      </div>
                    </Stack>
                  }
                >
                  🚨
                </Tooltip>
              </div>
            )}
          </HorizontalStack>
          {o.lastSignificantCallBy && (
            <CellSubText>{o.lastSignificantCallBy}</CellSubText>
          )}
        </Stack>
      ),
      sorter: function (a, b) {
        return (
          moment(a.lastSignificantCallDate).valueOf() -
          moment(b.lastSignificantCallDate).valueOf()
        );
      },
    },
    {
      title: "Location",
      key: "location",
      show: true,
      render: (o) => (
        <div>
          {o.billingCity}, {o.billingState}
        </div>
      ),
      sorter: function (a, b) {
        return (
          moment(a.lastBookedDate).valueOf() -
          moment(b.lastBookedDate).valueOf()
        );
      },
    },
    {
      title: "Currency",
      key: "currency",
      show: false,
      render: (o) => <div>{o.currency}</div>,
      sorter: function (a, b) {
        return b.currency.localeCompare(a.currency);
      },
    },
    {
      title: "Actions",
      key: "actions",
      show: true,
      render: function (o) {
        return (
          <HorizontalStack>
            <ViewCompanyButton companyId={o.companyId} />
          </HorizontalStack>
        );
      },
    },
    {
      title: "Advanced Actions",
      key: "advancedActions",
      show: false,
      render: function (o) {
        return (
          <HorizontalStack>
            <ViewJsonButton data={o} title="View Raw" />
          </HorizontalStack>
        );
      },
    },
  ]);

  return (
    <div style={{ marginTop: "-15px" }}>
      <ShowHideColumnsPopover columns={columns} setColumns={setColumns}>
        Show/Hide Columns
        <span style={{ color: Colors.Gray[500], marginLeft: "8px" }}>
          ({columns.filter((c) => !c.show).length} hidden)
        </span>
      </ShowHideColumnsPopover>
      <DataTable pagination={{}} columns={columns} data={props.companies} />
    </div>
  );
}

export function ListCompaniesScreen() {
  const createCompaniesApi = useCompaniesApi();
  const urlQuery = useQuery();
  const [allCompanies, setAllCompanies] = useState<
    Array<ListCompaniesRow> | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState(
    (urlQuery.searchValue as string) ?? ""
  );
  // Combination of a debounced searchValue that is used for the filtering
  // and an instantly updated searchValueDisplay that is use to show what was typed
  // TODO: Would be better if we had a DebouncedInput component
  const [searchValueDisplay, setSearchValueDisplay] = useState(searchValue);
  const setSearchValueDebounced = useDebouncedCallback(
    // function
    (value) => {
      setSearchValue(value);
    },
    // delay in ms
    1000
  );
  const [locationFilter, setLocationFilter] = useState<
    ApolloMapLocationFilterType | undefined
  >((urlQuery.locationFilter as ApolloMapLocationFilterType) ?? undefined);
  const [frequencyFilter, setFrequencyFilter] = useState<string | undefined>(
    (urlQuery.frequencyFilter as string) ?? undefined
  );
  const [firstQuoteFilter, setFirstQuoteFilter] = useState<string | undefined>(
    (urlQuery.firstQuoteFilter as string) ?? undefined
  );
  const [temperatureFilter, setTemperatureFilter] = useState<
    TemperatureFilter | undefined
  >((urlQuery.temperatureFilter as TemperatureFilter) ?? undefined);
  const [onlyIncludeOverdueSalesCall, setOnlyIncludeOverdueSalesCall] =
    useState(urlQuery.onlyIncludeOverdueSalesCall === "true" ? true : false);
  const [hideUninterestingCompanies, setHideUninterestingCompanies] = useState(
    urlQuery.onlyIncludeOverdueSalesCall === "false" ? false : true
  );
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<CompanyFilterType | undefined>(
    (urlQuery.filter as CompanyFilterType) ?? undefined
  );
  const navigate = useNavigate();

  console.log(`list companies filter`, {
    filter,
    onlyIncludeOverdueSalesCall,
    temperatureFilter,
    firstQuoteFilter,
    frequencyFilter,
    locationFilter,
    searchValue,
  });
  async function refresh() {
    setLoading(true);
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.listCompanies({
      listCompaniesInput: {
        locationFilterType: locationFilter,
      },
    });
    setAllCompanies(response);
    setLoading(false);
  }

  useEffect(
    function () {
      let url = `/list-companies?`;
      if (searchValue !== "") {
        url += `&searchValue=${searchValue}`;
      }
      if (locationFilter) {
        url += `&locationFilter=${locationFilter}`;
      }
      if (frequencyFilter) {
        url += `&frequencyFilter=${frequencyFilter}`;
      }
      if (temperatureFilter) {
        url += `&temperatureFilter=${temperatureFilter}`;
      }
      if (firstQuoteFilter) {
        url += `&firstQuoteFilter=${firstQuoteFilter}`;
      }
      if (onlyIncludeOverdueSalesCall) {
        url += `&onlyIncludeOverdueSalesCall=${onlyIncludeOverdueSalesCall}`;
      }
      if (hideUninterestingCompanies === false) {
        url += `&hideUninterestingCompanies=${hideUninterestingCompanies}`;
      }
      if (filter) {
        url += `&filter=${filter}`;
      }

      navigate(url, {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      searchValue,
      locationFilter,
      frequencyFilter,
      temperatureFilter,
      onlyIncludeOverdueSalesCall,
      hideUninterestingCompanies,
      filter,
    ]
  );

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationFilter, onlyIncludeOverdueSalesCall]
  );

  function doGeneralFilter(c: ListCompaniesRow): boolean {
    if (allCompanies === undefined) {
      return true;
    }
    if (filter === undefined) {
      return true;
    }
    return applyFilter(filter, allCompanies, c);
  }

  const filteredByEverythingApartFromGeneralFilterAndFirstQuote = allCompanies
    ?.filter(function (c) {
      if (searchValue === "") {
        return true;
      }
      return (
        c.companyName.toLowerCase().includes(searchValue.toLowerCase()) ||
        (c.associatedProfessionalDomain &&
          c.associatedProfessionalDomain
            .map((d) => d.toLowerCase())
            .join()
            .includes(searchValue.toLowerCase()))
      );
    })
    .filter(function (c) {
      if (frequencyFilter !== undefined) {
        return c.shipmentFrequency === frequencyFilter;
      } else {
        return true;
      }
    })
    .filter(function (c) {
      if (hideUninterestingCompanies) {
        return (
          c.shipmentFrequency !== "SingleShipment" &&
          c.temperature !== "☠️ Dead" &&
          !c.isPersonal &&
          !c.isBroker
        );
      } else {
        return true;
      }
    })
    .filter(function (c) {
      switch (temperatureFilter) {
        case TemperatureFilter.All:
          return true;
        case TemperatureFilter.Hot:
          return c.temperature === "🔥 Hot";
        case TemperatureFilter.Warm:
          return c.temperature === "🌤 Warm";
        case TemperatureFilter.Cold:
          return c.temperature === "🥶 Cold";
        case TemperatureFilter.Dead:
          return c.temperature === "☠️ Dead";
        case undefined:
          return c.temperature !== "☠️ Dead";
        default:
          assertNever(temperatureFilter);
          return "";
      }
    })

    .filter(function (c) {
      if (onlyIncludeOverdueSalesCall) {
        return c.overdueSalesCall;
      } else {
        return true;
      }
    });

  const filteredByEverythingApartFromGeneralFilter =
    filteredByEverythingApartFromGeneralFilterAndFirstQuote?.filter(function (
      c
    ) {
      if (firstQuoteFilter !== undefined) {
        return (
          c.firstQuoteDate !== undefined &&
          moment(c.firstQuoteDate).format(PeriodFormats.Monthly) ===
            firstQuoteFilter
        );
      } else {
        return true;
      }
    });

  const filteredCompanies =
    filteredByEverythingApartFromGeneralFilter?.filter(doGeneralFilter);

  if (
    allCompanies === undefined ||
    filteredCompanies === undefined ||
    filteredByEverythingApartFromGeneralFilter === undefined
  ) {
    return <Loading />;
  } else {
    return (
      <>
        {/* @ts-ignore */}
        <Page
          title={
            <div>
              List Companies ({filteredCompanies.length}/{allCompanies.length})
            </div>
          }
          tags={[]}
          stats={
            <Stack align="left">
              <HorizontalStack>
                <Form.Item label="Name">
                  <Search
                    value={searchValueDisplay}
                    placeholder="Filter by company name"
                    allowClear
                    onChange={function (e) {
                      setSearchValueDebounced(e.target.value);
                      setSearchValueDisplay(e.target.value);
                    }}
                  />
                </Form.Item>
                <Spacer width={16} />

                <Form.Item label="Frequency">
                  <ValuesDropdown
                    value={frequencyFilter}
                    setValue={setFrequencyFilter}
                    values={allCompanies.map((c) => c.shipmentFrequency)}
                    width={130}
                    placeholder="No filter"
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Temperature">
                  <TemperatureFilterDropdown
                    value={temperatureFilter}
                    setValue={setTemperatureFilter}
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="First Quote">
                  {filteredByEverythingApartFromGeneralFilterAndFirstQuote && (
                    <MonthSelect
                      month={firstQuoteFilter}
                      setMonth={setFirstQuoteFilter}
                      counter={function (month: string) {
                        return countIf(
                          filteredByEverythingApartFromGeneralFilterAndFirstQuote,
                          (c) =>
                            c.firstQuoteDate !== undefined &&
                            moment(c.firstQuoteDate).format(
                              PeriodFormats.Monthly
                            ) === month
                        );
                      }}
                    />
                  )}
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Location">
                  <LocationDropdown
                    locationFilterType={locationFilter}
                    setLocationFilterType={setLocationFilter}
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Filter">
                  <CompanyFilterDropdown
                    value={filter}
                    setValue={setFilter}
                    companies={filteredByEverythingApartFromGeneralFilter}
                  />
                </Form.Item>
                <Spacer width={16} />
              </HorizontalStack>
              <HorizontalStack>
                <Form.Item label="Only Overdue Sales Call?">
                  <Switch
                    checked={onlyIncludeOverdueSalesCall}
                    onChange={setOnlyIncludeOverdueSalesCall}
                  />
                </Form.Item>
                <Spacer width={16} />
                <Form.Item label="Hide Uninteresting Companies">
                  <Switch
                    checked={hideUninterestingCompanies}
                    onChange={setHideUninterestingCompanies}
                  />
                </Form.Item>
              </HorizontalStack>
              <HorizontalStack>
                <div style={{ margin: "4px" }}>
                  <>
                    🎈 Lead:{" "}
                    {
                      filteredCompanies.filter(
                        (o) =>
                          o.qualificationStatus ===
                          QualificationStatus.Unqualified
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🔥 Hot:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.temperature === "🔥 Hot"
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🌤 Warm:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.temperature === "🌤 Warm"
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🥶 Cold:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.temperature === "🥶 Cold"
                      ).length
                    }{" "}
                  </>
                </div>
                <Spacer width={32} />
                <div style={{ margin: "4px" }}>
                  <>
                    🐳 Daily:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.shipmentFrequency === "Daily"
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🐬 Weekly:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.shipmentFrequency === "Weekly"
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🤙 Occasional:{" "}
                    {
                      filteredCompanies.filter(
                        (o) => o.shipmentFrequency === "Occasional"
                      ).length
                    }{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🚨 Problems:{" "}
                    {countForAllProblemFilterTypes(filteredCompanies)}{" "}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    ☎️ Significant Call:{" "}
                    {(
                      (100 *
                        countIf(filteredCompanies, (c) =>
                          isNotBlank(c.lastSignificantCallDate)
                        )) /
                      filteredCompanies.length
                    ).toFixed(0)}
                    {"%"}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    🚨 Overdue Call:{" "}
                    {countIf(filteredCompanies, (c) => c.overdueSalesCall)} /{" "}
                    {filteredCompanies.length}
                  </>
                </div>
                <div style={{ margin: "4px" }}>
                  <>
                    ✅ Booked:{" "}
                    {countIf(filteredCompanies, (c) => c.shipmentsBooked > 0)} /{" "}
                    {filteredCompanies.length}
                  </>
                </div>
              </HorizontalStack>
            </Stack>
          }
          extra={[
            <ButtonRow>
              <AddCompanyButton />
            </ButtonRow>,
          ]}
        >
          <PageTitle>List Companies</PageTitle>
          <Panel>
            {!loading && (
              <CompaniesTable
                allCompanies={allCompanies}
                companies={filteredCompanies}
              />
            )}
            {loading && <Loading />}
          </Panel>
        </Page>
      </>
    );
  }
}
