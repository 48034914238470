/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalendarDataResponse,
  CompanyCalendarDataResponse,
} from '../models/index';
import {
    CalendarDataResponseFromJSON,
    CalendarDataResponseToJSON,
    CompanyCalendarDataResponseFromJSON,
    CompanyCalendarDataResponseToJSON,
} from '../models/index';

export interface CompanyDataForCalendarsRequest {
    companyId: string;
}

export interface CompanyDataForQuotingCalendarRequest {
    companyId: string;
}

/**
 * 
 */
export class CalendarStatsApi extends runtime.BaseAPI {

    /**
     */
    async companyDataForCalendarsRaw(requestParameters: CompanyDataForCalendarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyCalendarDataResponse>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling companyDataForCalendars().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/calendar-stats/company-data-for-calendars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyCalendarDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async companyDataForCalendars(requestParameters: CompanyDataForCalendarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyCalendarDataResponse> {
        const response = await this.companyDataForCalendarsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyDataForQuotingCalendarRaw(requestParameters: CompanyDataForQuotingCalendarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarDataResponse>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling companyDataForQuotingCalendar().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/calendar-stats/company-data-for-quoting-calendar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async companyDataForQuotingCalendar(requestParameters: CompanyDataForQuotingCalendarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarDataResponse> {
        const response = await this.companyDataForQuotingCalendarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSignificantCallsCalendarRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarDataResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/calendar-stats/get-significant-calls-calendar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSignificantCallsCalendar(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarDataResponse> {
        const response = await this.getSignificantCallsCalendarRaw(initOverrides);
        return await response.value();
    }

}
