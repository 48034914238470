/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MissedPickupSupportEmailType = {
    NotReadyPickupRebooked: 'NotReadyPickupRebooked',
    NoDocumentsPickupRebooked: 'NoDocumentsPickupRebooked',
    NoCustomsDocumentsPickupRebooked: 'NoCustomsDocumentsPickupRebooked',
    BusinessClosedRebooked: 'BusinessClosedRebooked',
    BusinessClosedAmbiguousIfRebooked: 'BusinessClosedAmbiguousIfRebooked',
    BusinessClosedNotRebooked: 'BusinessClosedNotRebooked',
    NoFreightNotRebooked: 'NoFreightNotRebooked'
} as const;
export type MissedPickupSupportEmailType = typeof MissedPickupSupportEmailType[keyof typeof MissedPickupSupportEmailType];


export function instanceOfMissedPickupSupportEmailType(value: any): boolean {
    return Object.values(MissedPickupSupportEmailType).includes(value);
}

export function MissedPickupSupportEmailTypeFromJSON(json: any): MissedPickupSupportEmailType {
    return MissedPickupSupportEmailTypeFromJSONTyped(json, false);
}

export function MissedPickupSupportEmailTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissedPickupSupportEmailType {
    return json as MissedPickupSupportEmailType;
}

export function MissedPickupSupportEmailTypeToJSON(value?: MissedPickupSupportEmailType | null): any {
    return value as any;
}

