/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BookShipmentStrategyType } from './BookShipmentStrategyType';
import {
    BookShipmentStrategyTypeFromJSON,
    BookShipmentStrategyTypeFromJSONTyped,
    BookShipmentStrategyTypeToJSON,
} from './BookShipmentStrategyType';

/**
 * 
 * @export
 * @interface BookShipmentStrategy
 */
export interface BookShipmentStrategy {
    /**
     * 
     * @type {BookShipmentStrategyType}
     * @memberof BookShipmentStrategy
     */
    strategyType: BookShipmentStrategyType;
    /**
     * 
     * @type {string}
     * @memberof BookShipmentStrategy
     */
    notes: string;
}

/**
 * Check if a given object implements the BookShipmentStrategy interface.
 */
export function instanceOfBookShipmentStrategy(value: object): boolean {
    if (!('strategyType' in value)) return false;
    if (!('notes' in value)) return false;
    return true;
}

export function BookShipmentStrategyFromJSON(json: any): BookShipmentStrategy {
    return BookShipmentStrategyFromJSONTyped(json, false);
}

export function BookShipmentStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookShipmentStrategy {
    if (json == null) {
        return json;
    }
    return {
        
        'strategyType': BookShipmentStrategyTypeFromJSON(json['strategyType']),
        'notes': json['notes'],
    };
}

export function BookShipmentStrategyToJSON(value?: BookShipmentStrategy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'strategyType': BookShipmentStrategyTypeToJSON(value['strategyType']),
        'notes': value['notes'],
    };
}

