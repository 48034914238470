import { DataTable, DataTableColumn } from "../../Components/DataTable";

interface MapScreenLocationFrequencyTablePoint {
  location: string;
  count: number;
}

interface MapScreenLocationFrequencyTableProps {
  data: MapScreenLocationFrequencyTablePoint[];
}

export function MapScreenLocationFrequencyTable(
  props: MapScreenLocationFrequencyTableProps
) {
  const columns: DataTableColumn<MapScreenLocationFrequencyTablePoint>[] = [];
  columns.push({
    title: "Location",
    render: (o) => <div>{o.location}</div>,
  });
  columns.push({
    title: "Count",
    render: (o) => <div>{o.count}</div>,
  });

  return <DataTable pagination={false} columns={columns} data={props.data} />;
}
