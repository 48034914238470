/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClaudePrompt
 */
export interface ClaudePrompt {
    /**
     * 
     * @type {string}
     * @memberof ClaudePrompt
     */
    claudePromptId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaudePrompt
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaudePrompt
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaudePrompt
     */
    body?: string;
}

/**
 * Check if a given object implements the ClaudePrompt interface.
 */
export function instanceOfClaudePrompt(value: object): boolean {
    return true;
}

export function ClaudePromptFromJSON(json: any): ClaudePrompt {
    return ClaudePromptFromJSONTyped(json, false);
}

export function ClaudePromptFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaudePrompt {
    if (json == null) {
        return json;
    }
    return {
        
        'claudePromptId': json['claudePromptId'] == null ? undefined : json['claudePromptId'],
        'identifier': json['identifier'] == null ? undefined : json['identifier'],
        'title': json['title'] == null ? undefined : json['title'],
        'body': json['body'] == null ? undefined : json['body'],
    };
}

export function ClaudePromptToJSON(value?: ClaudePrompt | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'claudePromptId': value['claudePromptId'],
        'identifier': value['identifier'],
        'title': value['title'],
        'body': value['body'],
    };
}

