import { Button } from "antd";
import { useEffect, useState } from "react";
import { useCompaniesApi } from "../Apis/Apis";
import { CompanyData } from "../generated-openapi-client";
import { useDisplay } from "../Hooks/useDisplay";
import { PhoneOutlined } from "@ant-design/icons";
import { OutboundLogSalesCallModal } from "../Screens/ViewCompanyScreenComponents/LogSalesCall/OutboundLogSalesCall";
import { useCallContext } from "../Contexts/CallContext/CallContext";

export function SalesCallFloatingButton() {
  const display = useDisplay();
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [loading, setLoading] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  const { callData } = useCallContext();

  async function fetchCompanyData(companyId: string) {
    setLoading(true);
    try {
      const api = await createCompaniesApi();
      const response = await api.viewCompanyData({
        companyId,
      });
      setCompanyData(response);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!callData) {
      display.hide();
      return;
    }

    if (callData.companyId) {
      fetchCompanyData(callData.companyId);
    }
  }, [callData?.companyId]);

  useEffect(() => {
    if (!callData || !companyData) {
      return;
    }

    const selectedContact = callData.salesContactId;

    if (!selectedContact) {
      return;
    }

    const isInSalesContacts = companyData.salesContacts.some(
      (c) => c.salesContactId === selectedContact
    );

    if (!isInSalesContacts && callData.companyId) {
      if (!document.hasFocus()) {
        display.hide();
      }
      fetchCompanyData(callData.companyId);
    }
  }, [callData?.salesContactId]);

  if (!callData) {
    return null;
  }

  return (
    <>
      <Button
        loading={loading || callData.saving}
        icon={<PhoneOutlined />}
        shape="round"
        type="primary"
        style={{
          position: "fixed",
          bottom: "20px",
          left: "calc(100vw - 190px)",
          width: "160px",
          zIndex: "10",
        }}
        onClick={display.show}
      >
        Sales Call Notes
      </Button>
      {companyData && (
        <OutboundLogSalesCallModal
          company={companyData.company}
          salesContacts={companyData.salesContacts}
          display={display}
        />
      )}
    </>
  );
}
