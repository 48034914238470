/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LookupResultType } from './LookupResultType';
import {
    LookupResultTypeFromJSON,
    LookupResultTypeFromJSONTyped,
    LookupResultTypeToJSON,
} from './LookupResultType';

/**
 * 
 * @export
 * @interface LookupResult
 */
export interface LookupResult {
    /**
     * 
     * @type {LookupResultType}
     * @memberof LookupResult
     */
    resultType: LookupResultType;
    /**
     * 
     * @type {string}
     * @memberof LookupResult
     */
    resultId: string;
}

/**
 * Check if a given object implements the LookupResult interface.
 */
export function instanceOfLookupResult(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('resultId' in value)) return false;
    return true;
}

export function LookupResultFromJSON(json: any): LookupResult {
    return LookupResultFromJSONTyped(json, false);
}

export function LookupResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookupResult {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': LookupResultTypeFromJSON(json['resultType']),
        'resultId': json['resultId'],
    };
}

export function LookupResultToJSON(value?: LookupResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': LookupResultTypeToJSON(value['resultType']),
        'resultId': value['resultId'],
    };
}

