import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  AdditionalCharge,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { AdditionalChargeState } from "../../generated-openapi-client/models/AdditionalChargeState";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../../generated-openapi-client/models/SupportAgent";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailButton } from "./SupportEmailButton";

interface AdditionaChargeSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  additionalCharge: AdditionalCharge;
}

export function AdditionaChargeSupportEmailButton(
  props: AdditionaChargeSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const additionalCharge = props.additionalCharge;
  const additionalChargeId = additionalCharge.additionalChargeId!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  if (additionalCharge.state !== AdditionalChargeState.Approved) {
    return <></>;
  }

  const hash = JSON.stringify({
    additionalChargeId,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgent,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgent,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Additional Charge Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
    />
  );
}
