/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentsServiceSwitchQuotePaymentPlan } from './PaymentsServiceSwitchQuotePaymentPlan';
import {
    PaymentsServiceSwitchQuotePaymentPlanFromJSON,
    PaymentsServiceSwitchQuotePaymentPlanFromJSONTyped,
    PaymentsServiceSwitchQuotePaymentPlanToJSON,
} from './PaymentsServiceSwitchQuotePaymentPlan';
import type { Quote } from './Quote';
import {
    QuoteFromJSON,
    QuoteFromJSONTyped,
    QuoteToJSON,
} from './Quote';
import type { SelectQuotePlanAllowedToSwitch } from './SelectQuotePlanAllowedToSwitch';
import {
    SelectQuotePlanAllowedToSwitchFromJSON,
    SelectQuotePlanAllowedToSwitchFromJSONTyped,
    SelectQuotePlanAllowedToSwitchToJSON,
} from './SelectQuotePlanAllowedToSwitch';

/**
 * 
 * @export
 * @interface SelectQuotePlan
 */
export interface SelectQuotePlan {
    /**
     * 
     * @type {Quote}
     * @memberof SelectQuotePlan
     */
    originalQuote: Quote;
    /**
     * 
     * @type {Quote}
     * @memberof SelectQuotePlan
     */
    newQuote: Quote;
    /**
     * 
     * @type {SelectQuotePlanAllowedToSwitch}
     * @memberof SelectQuotePlan
     */
    allowedToSwitch: SelectQuotePlanAllowedToSwitch;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    sameCarrier: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willMarkShipmentOnHold: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willSendCancelPickupEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willMovePickupDateToToday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willSelectNewQuote: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willClearProNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willBookNewPickup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willRegenerateDocuments: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuotePlan
     */
    willHandleCustomsDocuments: boolean;
    /**
     * 
     * @type {PaymentsServiceSwitchQuotePaymentPlan}
     * @memberof SelectQuotePlan
     */
    paymentsPlan: PaymentsServiceSwitchQuotePaymentPlan;
}

/**
 * Check if a given object implements the SelectQuotePlan interface.
 */
export function instanceOfSelectQuotePlan(value: object): boolean {
    if (!('originalQuote' in value)) return false;
    if (!('newQuote' in value)) return false;
    if (!('allowedToSwitch' in value)) return false;
    if (!('sameCarrier' in value)) return false;
    if (!('willMarkShipmentOnHold' in value)) return false;
    if (!('willSendCancelPickupEmail' in value)) return false;
    if (!('willMovePickupDateToToday' in value)) return false;
    if (!('willSelectNewQuote' in value)) return false;
    if (!('willClearProNumber' in value)) return false;
    if (!('willBookNewPickup' in value)) return false;
    if (!('willRegenerateDocuments' in value)) return false;
    if (!('willHandleCustomsDocuments' in value)) return false;
    if (!('paymentsPlan' in value)) return false;
    return true;
}

export function SelectQuotePlanFromJSON(json: any): SelectQuotePlan {
    return SelectQuotePlanFromJSONTyped(json, false);
}

export function SelectQuotePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectQuotePlan {
    if (json == null) {
        return json;
    }
    return {
        
        'originalQuote': QuoteFromJSON(json['originalQuote']),
        'newQuote': QuoteFromJSON(json['newQuote']),
        'allowedToSwitch': SelectQuotePlanAllowedToSwitchFromJSON(json['allowedToSwitch']),
        'sameCarrier': json['sameCarrier'],
        'willMarkShipmentOnHold': json['willMarkShipmentOnHold'],
        'willSendCancelPickupEmail': json['willSendCancelPickupEmail'],
        'willMovePickupDateToToday': json['willMovePickupDateToToday'],
        'willSelectNewQuote': json['willSelectNewQuote'],
        'willClearProNumber': json['willClearProNumber'],
        'willBookNewPickup': json['willBookNewPickup'],
        'willRegenerateDocuments': json['willRegenerateDocuments'],
        'willHandleCustomsDocuments': json['willHandleCustomsDocuments'],
        'paymentsPlan': PaymentsServiceSwitchQuotePaymentPlanFromJSON(json['paymentsPlan']),
    };
}

export function SelectQuotePlanToJSON(value?: SelectQuotePlan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'originalQuote': QuoteToJSON(value['originalQuote']),
        'newQuote': QuoteToJSON(value['newQuote']),
        'allowedToSwitch': SelectQuotePlanAllowedToSwitchToJSON(value['allowedToSwitch']),
        'sameCarrier': value['sameCarrier'],
        'willMarkShipmentOnHold': value['willMarkShipmentOnHold'],
        'willSendCancelPickupEmail': value['willSendCancelPickupEmail'],
        'willMovePickupDateToToday': value['willMovePickupDateToToday'],
        'willSelectNewQuote': value['willSelectNewQuote'],
        'willClearProNumber': value['willClearProNumber'],
        'willBookNewPickup': value['willBookNewPickup'],
        'willRegenerateDocuments': value['willRegenerateDocuments'],
        'willHandleCustomsDocuments': value['willHandleCustomsDocuments'],
        'paymentsPlan': PaymentsServiceSwitchQuotePaymentPlanToJSON(value['paymentsPlan']),
    };
}

