import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { Loading } from "../../Components/Loading";
import { useOnce } from "../../Hooks/useOnce";
import {
  FreightClaim,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { TabProps } from "./TabProps";
import { ViewClaimButton } from "./ViewClaimButton";

interface ClaimsTableProps {
  claims: FreightClaim[];
  data: ViewShipmentDataForApollo;
}

function ClaimsTable(props: ClaimsTableProps) {
  const columns: DataTableColumn<FreightClaim>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier!!}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Created At",
      render: (o) => <CreatedAt timestamp={o.createdAt} />,
    },
    {
      title: "State",
      render: (o) => <>{o.freightClaimState}</>,
    },
    {
      title: "Pro Number",
      render: (o) => <>{o.proNumber}</>,
    },
    {
      title: "Description",
      render: (o) => <>{o.claimDescription}</>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewClaimButton freightClaim={o} data={props.data} />
        </ButtonRow>
      ),
    },
  ];
  return <DataTable pagination={false} columns={columns} data={props.claims} />;
}

export function ClaimsTab(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!!;
  const createShipmentApi = useShipmentsApi();
  const [claims, setClaims] = useState<undefined | FreightClaim[]>();

  useOnce(async function () {
    const shipmentApi = await createShipmentApi();
    const response = await shipmentApi.getShipmentClaimsTabById({ shipmentId });
    setClaims(response.claims);
  });

  if (claims === undefined) {
    return <Loading />;
  }

  return <ClaimsTable claims={claims} data={props.shipmentData} />;
}
