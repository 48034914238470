import { message } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import {
  InvoiceAuditTurn,
  OpenAuditLine,
} from "../../generated-openapi-client";

interface MarkWaitingForCarrierMenuItemProps {
  openAuditLine: OpenAuditLine;
  refresh: () => Promise<void>;
}

export function MarkWaitingForCarrierMenuItem(
  props: MarkWaitingForCarrierMenuItemProps
) {
  const [loading, setLoading] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const shipmentId = props.openAuditLine.csq.shipment.shipmentId!!;

  async function onClick() {
    setLoading(true);

    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.setInvoiceAuditCarrierTurn({
        shipmentId,
        turn: InvoiceAuditTurn.TheirTurn,
      });
      await props.refresh();
      message.success("Done");
    } catch (e) {
      message.error(`Oops - something went wrong`);
    }

    setLoading(false);
  }
  return (
    <ConfirmMenuItem
      loading={loading}
      onConfirm={onClick}
      question={
        "Are you sure you want to mark this waiting for carrier? This won't update the last contact time"
      }
      okText={"Yes, do it"}
      tooltip={
        "This will set it to be the carrier's turn. It won't update the last contact time."
      }
    >
      Mark Waiting for Carrier
    </ConfirmMenuItem>
  );
}
