/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogSalesCallInput
 */
export interface LogSalesCallInput {
    /**
     * 
     * @type {string}
     * @memberof LogSalesCallInput
     */
    companyNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof LogSalesCallInput
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof LogSalesCallInput
     */
    salesContactId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LogSalesCallInput
     */
    significant: boolean;
    /**
     * 
     * @type {string}
     * @memberof LogSalesCallInput
     */
    recap: string;
    /**
     * 
     * @type {string}
     * @memberof LogSalesCallInput
     */
    temperature?: string;
    /**
     * 
     * @type {string}
     * @memberof LogSalesCallInput
     */
    shipmentFrequency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LogSalesCallInput
     */
    shouldQualify: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LogSalesCallInput
     */
    shouldDisqualify: boolean;
}

/**
 * Check if a given object implements the LogSalesCallInput interface.
 */
export function instanceOfLogSalesCallInput(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('significant' in value)) return false;
    if (!('recap' in value)) return false;
    if (!('shouldQualify' in value)) return false;
    if (!('shouldDisqualify' in value)) return false;
    return true;
}

export function LogSalesCallInputFromJSON(json: any): LogSalesCallInput {
    return LogSalesCallInputFromJSONTyped(json, false);
}

export function LogSalesCallInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogSalesCallInput {
    if (json == null) {
        return json;
    }
    return {
        
        'companyNoteId': json['companyNoteId'] == null ? undefined : json['companyNoteId'],
        'companyId': json['companyId'],
        'salesContactId': json['salesContactId'] == null ? undefined : json['salesContactId'],
        'significant': json['significant'],
        'recap': json['recap'],
        'temperature': json['temperature'] == null ? undefined : json['temperature'],
        'shipmentFrequency': json['shipmentFrequency'] == null ? undefined : json['shipmentFrequency'],
        'shouldQualify': json['shouldQualify'],
        'shouldDisqualify': json['shouldDisqualify'],
    };
}

export function LogSalesCallInputToJSON(value?: LogSalesCallInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyNoteId': value['companyNoteId'],
        'companyId': value['companyId'],
        'salesContactId': value['salesContactId'],
        'significant': value['significant'],
        'recap': value['recap'],
        'temperature': value['temperature'],
        'shipmentFrequency': value['shipmentFrequency'],
        'shouldQualify': value['shouldQualify'],
        'shouldDisqualify': value['shouldDisqualify'],
    };
}

