/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HandlingUnitType = {
    Skid: 'Skid',
    Pallet: 'Pallet',
    Bin: 'Bin',
    Box: 'Box',
    Bundle: 'Bundle',
    Cage: 'Cage',
    Crate: 'Crate',
    Drum: 'Drum',
    Reel: 'Reel',
    Roll: 'Roll',
    Loose: 'Loose',
    Other: 'Other'
} as const;
export type HandlingUnitType = typeof HandlingUnitType[keyof typeof HandlingUnitType];


export function instanceOfHandlingUnitType(value: any): boolean {
    return Object.values(HandlingUnitType).includes(value);
}

export function HandlingUnitTypeFromJSON(json: any): HandlingUnitType {
    return HandlingUnitTypeFromJSONTyped(json, false);
}

export function HandlingUnitTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlingUnitType {
    return json as HandlingUnitType;
}

export function HandlingUnitTypeToJSON(value?: HandlingUnitType | null): any {
    return value as any;
}

