// Starred shipments are shipments that you, personally have decided to keep an eye on

export interface StarredShipment {
  shipmentId: string;
  reason: string;
}

export function isShipmentStarred(shipmentId: string): boolean {
  const starredShipments = loadStarredShipments();
  return starredShipments.some((o) => o.shipmentId === shipmentId);
}

export function useStarredShipments(): StarredShipment[] {
  // Gets a list of starred shipments from local storage
  return loadStarredShipments();
}

export function getStarredShipmentById(
  shipmentId: string
): StarredShipment | undefined {
  const starredShipments = loadStarredShipments();
  return starredShipments.find((o) => o.shipmentId === shipmentId);
}

export function unstarShipment(shipmentId: string) {
  const starredShipments = loadStarredShipments();
  const updatedStarredShipments = starredShipments.filter(
    (o) => o.shipmentId !== shipmentId
  );
  saveStarredShipments(updatedStarredShipments);
}

export function unstarAllShipments() {
  saveStarredShipments([]);
}

export function starShipment(shipmentId: string, reason: string) {
  // First make sure this shipment isn't starred
  unstarShipment(shipmentId);

  const starredShipments = loadStarredShipments();
  starredShipments.push({ shipmentId, reason });
  saveStarredShipments(starredShipments);
}

function saveStarredShipments(starredShipments: StarredShipment[]) {
  localStorage.setItem("starredShipments", JSON.stringify(starredShipments));
}

function loadStarredShipments(): StarredShipment[] {
  const raw = localStorage.getItem("starredShipments");
  if (!raw) {
    return [];
  }
  return JSON.parse(raw) as StarredShipment[];
}
