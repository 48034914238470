import { Link } from "react-router-dom";
import { ButtonRow } from "../../../Components/ButtonRow";
import { CollapsibleNote } from "../../../Components/NotesBase/CollapseNote";
import { NoteDetailsBox } from "../../../Components/NotesBase/NoteDetailsBox";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import { Location, Shipment } from "../../../generated-openapi-client";
import Spacer from "../../../Spacer";
import { CompanyNoteElementProps } from "../CompanyNote";

export function ShipmentBookedCompanyNoteElement({
  note,
  collapseActive,
}: CompanyNoteElementProps) {
  const shipment = JSON.parse(note.metadata!).data.shipment as Shipment;

  function describeAddress(shipmentLocation: Location | null): string {
    return `${shipmentLocation?.address?.city}, ${shipmentLocation?.address?.stateOrProvinceCode}, ${shipmentLocation?.address?.postalCode}`;
  }

  return (
    <>
      <CollapsibleNote
        collapseActive={collapseActive}
        header={
          <NoteAuthorHeadline headline={note.subject} author={note.author} />
        }
      >
        <NoteDetailsBox>
          <NoteDetailsBox.LeftRightDetails
            left="Pickup Date"
            right={shipment!.pickupDate}
          />

          <NoteDetailsBox.LeftRightDetails
            left="Pickup Address"
            right={describeAddress(shipment!.pickupLocation!)}
          />

          <NoteDetailsBox.LeftRightDetails
            left="Delivery Address"
            right={describeAddress(shipment!.deliveryLocation!)}
          />
        </NoteDetailsBox>
      </CollapsibleNote>
      <Spacer height={8} />
      <ButtonRow>
        {shipment && (
          <Link to={`/view-shipment?shipmentId=${shipment.shipmentId}`}>
            View Shipment
          </Link>
        )}
      </ButtonRow>
    </>
  );
}
