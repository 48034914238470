/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyQuotedShipmentRow
 */
export interface CompanyQuotedShipmentRow {
    /**
     * 
     * @type {string}
     * @memberof CompanyQuotedShipmentRow
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyQuotedShipmentRow
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyQuotedShipmentRow
     */
    pickupLocation: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyQuotedShipmentRow
     */
    deliveryLocation: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyQuotedShipmentRow
     */
    totalWeight: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyQuotedShipmentRow
     */
    totalHandlingUnits: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyQuotedShipmentRow
     */
    density: number;
}

/**
 * Check if a given object implements the CompanyQuotedShipmentRow interface.
 */
export function instanceOfCompanyQuotedShipmentRow(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('totalWeight' in value)) return false;
    if (!('totalHandlingUnits' in value)) return false;
    if (!('density' in value)) return false;
    return true;
}

export function CompanyQuotedShipmentRowFromJSON(json: any): CompanyQuotedShipmentRow {
    return CompanyQuotedShipmentRowFromJSONTyped(json, false);
}

export function CompanyQuotedShipmentRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyQuotedShipmentRow {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'pickupDate': json['pickupDate'],
        'pickupLocation': json['pickupLocation'],
        'deliveryLocation': json['deliveryLocation'],
        'totalWeight': json['totalWeight'],
        'totalHandlingUnits': json['totalHandlingUnits'],
        'density': json['density'],
    };
}

export function CompanyQuotedShipmentRowToJSON(value?: CompanyQuotedShipmentRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'pickupDate': value['pickupDate'],
        'pickupLocation': value['pickupLocation'],
        'deliveryLocation': value['deliveryLocation'],
        'totalWeight': value['totalWeight'],
        'totalHandlingUnits': value['totalHandlingUnits'],
        'density': value['density'],
    };
}

