/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceAuditProblemResolutionType = {
    CarrierCorrection: 'carrier_correction',
    AdditionalChargeIssued: 'additional_charge_issued',
    FreightsimpleAbsorbedCost: 'freightsimple_absorbed_cost',
    Minor: 'minor'
} as const;
export type InvoiceAuditProblemResolutionType = typeof InvoiceAuditProblemResolutionType[keyof typeof InvoiceAuditProblemResolutionType];


export function instanceOfInvoiceAuditProblemResolutionType(value: any): boolean {
    return Object.values(InvoiceAuditProblemResolutionType).includes(value);
}

export function InvoiceAuditProblemResolutionTypeFromJSON(json: any): InvoiceAuditProblemResolutionType {
    return InvoiceAuditProblemResolutionTypeFromJSONTyped(json, false);
}

export function InvoiceAuditProblemResolutionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditProblemResolutionType {
    return json as InvoiceAuditProblemResolutionType;
}

export function InvoiceAuditProblemResolutionTypeToJSON(value?: InvoiceAuditProblemResolutionType | null): any {
    return value as any;
}

