import {
  InvoiceAuditProblemType,
  ViewShipmentDataForApollo,
} from "../generated-openapi-client";
import { assertNever } from "../Helpers/assertNever";
import { sum } from "../Helpers/sum";
import { longestDimension } from "./longestDimension";

export function shipmentInfoMessageForInvoiceAuditType(
  type: InvoiceAuditProblemType | undefined,
  shipmentData: ViewShipmentDataForApollo
): string | undefined {
  switch (type) {
    case InvoiceAuditProblemType.PickupAddressChange:
      return undefined;
    case InvoiceAuditProblemType.PickupLocationTypeChange:
      return `Pickup location type requested by customer : ${shipmentData.shipment.quote.pickupLocationType}`;
    case InvoiceAuditProblemType.LiftgateAtPickup:
      return `Pickup liftgate requested by customer : ${
        shipmentData.shipment.quote.pickupAccessorials?.includes(
          "LOGISTICS_LIFT_GATE_REQUIRED"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.InsidePickup:
      return `Inside pickup requested by customer : ${
        shipmentData.shipment.quote.pickupAccessorials?.includes(
          "LOGISTICS_INSIDE"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.PickupAppointment:
      return `Pickup appointment requested by customer : ${
        shipmentData.shipment.quote.pickupAccessorials?.includes(
          "SCHEDULING_APPOINTMENT_REQUIRED"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.ResidentialPickup:
      return `Residential pickup requested by customer : ${
        shipmentData.shipment.quote.pickupLocationType === "Residential"
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.TradeshowPickup:
      return `Trade show pickup requested by customer : ${
        shipmentData.shipment.quote.pickupLocationType === "TradeShow"
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.DeliveryAddressChange:
      return undefined;
    case InvoiceAuditProblemType.DeliveryLocationTypeChange:
      return `Delivery location type requested by customer : ${shipmentData.shipment.quote.deliveryLocationType}`;
    case InvoiceAuditProblemType.LiftgateAtDelivery:
      return `Delivery liftgate requested by customer : ${
        shipmentData.shipment.quote.deliveryAccessorials?.includes(
          "LOGISTICS_LIFT_GATE_REQUIRED"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.InsideDelivery:
      return `Inside delivery requested by customer : ${
        shipmentData.shipment.quote.deliveryAccessorials?.includes(
          "LOGISTICS_INSIDE"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.DeliveryAppointment:
      return `Delivery appointment requested by customer : ${
        shipmentData.shipment.quote.deliveryAccessorials?.includes(
          "SCHEDULING_APPOINTMENT_REQUIRED"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.ResidentialDelivery:
      return `Residential delivery requested by customer : ${
        shipmentData.shipment.quote.deliveryLocationType === "Residential"
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.TradeshowDelivery:
      return `Trade show delivery requested by customer : ${
        shipmentData.shipment.quote.pickupLocationType === "TradeShow"
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.WeightChange:
      return `Total weight in quote ${sum(
        shipmentData.shipment.quote.lineItems!!,
        (li) => li.numberHandlingUnits!! * li.weightPerHandlingUnit!!
      )}lb`;
    case InvoiceAuditProblemType.DimensionChange:
      return `Total volume in quote ${sum(
        shipmentData.shipment.quote.lineItems!!,
        (li) =>
          (li.numberHandlingUnits!! * li.length!! * li.width!! * li.height!!) /
          (12 * 12 * 12)
      ).toFixed(1)}cuft, Dimensions: ${shipmentData.shipment.quote.lineItems
        ?.map((li) => `${li.length}x${li.width}x${li.height}`)
        .join(", ")}`;
    case InvoiceAuditProblemType.NumberHandlingUnitsChange:
      return `Total handling units in quote ${sum(
        shipmentData.shipment.quote.lineItems!!,
        (li) => li.numberHandlingUnits!!
      )}`;
    case InvoiceAuditProblemType.ConsideredNonStackable:
      return `In quote, stackable handling units: ${sum(
        shipmentData.shipment.quote.lineItems!!,
        (li) => (li.isStackable ? li.numberHandlingUnits!! : 0)
      )}, non-stackable/top loadable (check BOL) handling units: ${sum(
        shipmentData.shipment.quote.lineItems!!,
        (li) => (!li.isStackable ? li.numberHandlingUnits!! : 0)
      )}`;
    case InvoiceAuditProblemType.Dangerous:
      return `Customer requested dangerous : ${
        shipmentData.shipment.quote.lineItems!!.some((li) => li.isDangerous)
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.FreightClassChange:
      return undefined;
    case InvoiceAuditProblemType.CubingChange:
      return undefined;
    case InvoiceAuditProblemType.LinearFeetChange:
      return undefined;
    case InvoiceAuditProblemType.CubeToHeightRule:
      return undefined;
    case InvoiceAuditProblemType.ProtectFromFreezing:
      return `Customer requested protect from freezing/heated service : ${
        shipmentData.shipment.quote.lineItems!!.some(
          (li) => li.temperatureHandling === "ProtectFromFreezing"
        )
          ? "Yes"
          : "No"
      }`;
    case InvoiceAuditProblemType.TemperatureControlled:
      return undefined;
    case InvoiceAuditProblemType.Tarp:
      return undefined;
    case InvoiceAuditProblemType.PickupStraightTruck:
      return undefined;
    case InvoiceAuditProblemType.PickupFlatbed:
      return undefined;
    case InvoiceAuditProblemType.PickupOtherEquipment:
      return undefined;
    case InvoiceAuditProblemType.MissedPickup:
      return undefined;
    case InvoiceAuditProblemType.DetentionPickup:
      return undefined;
    case InvoiceAuditProblemType.DetentionWithoutPowerPickup:
      return undefined;
    case InvoiceAuditProblemType.AfterHoursPickup:
      return undefined;
    case InvoiceAuditProblemType.LimitedAccessPickup:
      return undefined;
    case InvoiceAuditProblemType.RuralRoutePickup:
      return undefined;
    case InvoiceAuditProblemType.PalletJackAtPickup:
      return undefined;
    case InvoiceAuditProblemType.DeliveryStraightTruck:
      return undefined;
    case InvoiceAuditProblemType.DeliveryFlatbed:
      return undefined;
    case InvoiceAuditProblemType.DeliveryOtherEquipment:
      return undefined;
    case InvoiceAuditProblemType.Redelivery:
      return undefined;
    case InvoiceAuditProblemType.DetentionDelivery:
      return undefined;
    case InvoiceAuditProblemType.DetentionWithoutPowerDelivery:
      return undefined;
    case InvoiceAuditProblemType.AfterHoursDelivery:
      return undefined;
    case InvoiceAuditProblemType.LimitedAccessDelivery:
      return undefined;
    case InvoiceAuditProblemType.DeliveryNotificationFee:
      return undefined;
    case InvoiceAuditProblemType.RuralRouteDelivery:
      return undefined;
    case InvoiceAuditProblemType.PalletJackAtDelivery:
      return undefined;
    case InvoiceAuditProblemType.Storage:
      return undefined;
    case InvoiceAuditProblemType.CustomsCharge:
      return undefined;
    case InvoiceAuditProblemType.Reconsignment:
      return undefined;
    case InvoiceAuditProblemType.AdditionalCarrierConverageRequested:
      return undefined;
    case InvoiceAuditProblemType.BillOfLadingChange:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleEnteredIncorrectCurrency:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleEnteredIncorrectAmount:
      return undefined;
    case InvoiceAuditProblemType.FreightsimpleTaxIncorrectlyApplied:
      return undefined;
    case InvoiceAuditProblemType.SkidSpotsIncorrect:
      return undefined;
    case InvoiceAuditProblemType.FuelSurchargeIncorrect:
      return undefined;
    case InvoiceAuditProblemType.IncorrectRateUsed:
      return undefined;
    case InvoiceAuditProblemType.QuoteNotApplied:
      return undefined;
    case InvoiceAuditProblemType.CodFee:
      return undefined;
    case InvoiceAuditProblemType.LumperFee:
      return undefined;
    case InvoiceAuditProblemType.SwamperFee:
      return undefined;
    case InvoiceAuditProblemType.DocumentFee:
      return undefined;
    case InvoiceAuditProblemType.CrossDockFee:
      return undefined;
    case InvoiceAuditProblemType.DropTrailerFee:
      return undefined;
    case InvoiceAuditProblemType.ExclusiveUseFee:
      return undefined;
    case InvoiceAuditProblemType.ExtraLaborFee:
      return undefined;
    case InvoiceAuditProblemType.OverlengthFee:
      return `Longest dimension in quote : ${longestDimension(
        shipmentData.shipment.quote.lineItems!!
      )}`;
    case InvoiceAuditProblemType.SortationAndSegragationFee:
      return undefined;
    case InvoiceAuditProblemType.PackagingFee:
      return undefined;
    case InvoiceAuditProblemType.WeekendPickupFee:
      return undefined;
    case InvoiceAuditProblemType.WeekendDeliveryFee:
      return undefined;
    case InvoiceAuditProblemType.HolidayPickupFee:
      return undefined;
    case InvoiceAuditProblemType.HolidayDeliveryFee:
      return undefined;
    case InvoiceAuditProblemType.CrossBorderFee:
      return undefined;
    case InvoiceAuditProblemType.PermitFee:
      return undefined;
    case InvoiceAuditProblemType.Other:
      return undefined;
    case undefined:
      return undefined;
    default:
      assertNever(type);
  }
}
