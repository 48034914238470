/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GenerateProfitLossReportFilterOptionsSettledStatus = {
    Everything: 'Everything',
    OnlyIncludeSettled: 'OnlyIncludeSettled',
    OnlyIncludeNonSettled: 'OnlyIncludeNonSettled'
} as const;
export type GenerateProfitLossReportFilterOptionsSettledStatus = typeof GenerateProfitLossReportFilterOptionsSettledStatus[keyof typeof GenerateProfitLossReportFilterOptionsSettledStatus];


export function instanceOfGenerateProfitLossReportFilterOptionsSettledStatus(value: any): boolean {
    return Object.values(GenerateProfitLossReportFilterOptionsSettledStatus).includes(value);
}

export function GenerateProfitLossReportFilterOptionsSettledStatusFromJSON(json: any): GenerateProfitLossReportFilterOptionsSettledStatus {
    return GenerateProfitLossReportFilterOptionsSettledStatusFromJSONTyped(json, false);
}

export function GenerateProfitLossReportFilterOptionsSettledStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateProfitLossReportFilterOptionsSettledStatus {
    return json as GenerateProfitLossReportFilterOptionsSettledStatus;
}

export function GenerateProfitLossReportFilterOptionsSettledStatusToJSON(value?: GenerateProfitLossReportFilterOptionsSettledStatus | null): any {
    return value as any;
}

