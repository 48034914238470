import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  FailedPaymentSupportEmailType,
  ShipmentState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { CustomerInvoice } from "../../generated-openapi-client/models/CustomerInvoice";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../../generated-openapi-client/models/SupportAgent";
import { EnumDropdown } from "./EnumDropdown";
import { ExtraTextButton } from "./ExtraTextButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

export const FailedPaymentSupportEmailTypeDropdown = EnumDropdown<
  FailedPaymentSupportEmailType,
  typeof FailedPaymentSupportEmailType
>(FailedPaymentSupportEmailType);

interface FailedPaymentSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  customerInvoice: CustomerInvoice;
}

export function FailedPaymentSupportEmailButton(
  props: FailedPaymentSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");
  const [failedPaymentSupportEmailType, setFailedPaymentSupportEmailType] =
    useState<FailedPaymentSupportEmailType>(
      FailedPaymentSupportEmailType.GenericCreditCard
    );

  const state = props.data.shipment.shipment.state;

  if (state !== ShipmentState.BookingConfirmed) {
    return <></>;
  }

  const input = {
    extraText,
    failedPaymentSupportEmailType,
  };

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewFailedPaymentEmail({
        ...input,
        shipmentId,
        customerInvoiceId,
        supportAgent,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendFailedPaymentEmail({
        ...input,
        shipmentId,
        customerInvoiceId,
        supportAgent,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Failed Payment Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Type">
            <FailedPaymentSupportEmailTypeDropdown
              value={failedPaymentSupportEmailType}
              setValue={setFailedPaymentSupportEmailType}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}
