import { Select } from "antd";

interface LeadSourceSelectProps {
  leadSource: string | null | undefined;
  setLeadSource: (_: string | undefined) => void;
  disabled?: boolean;
}

export function LeadSourceSelect(props: LeadSourceSelectProps) {
  const { leadSource, setLeadSource } = props;
  const leadSources = [
    "Other",
    "Unknown",
    "Natasha's Existing Contact",
    "Shipper/Receiver from Shipment",
    "Google Ads",
    "In-Person Cold Call",
    "Phone Cold Call",
  ];
  return (
    <Select
      disabled={props.disabled}
      allowClear
      value={leadSource}
      style={{ width: 220 }}
      onChange={function (e) {
        setLeadSource(e);
      }}
      placeholder="Filter by lead source"
    >
      {leadSources.map(function (ls) {
        return <Select.Option value={ls}>{ls}</Select.Option>;
      })}
    </Select>
  );
}
