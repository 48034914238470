/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { LocationContext } from './LocationContext';
import {
    LocationContextFromJSON,
    LocationContextFromJSONTyped,
    LocationContextToJSON,
} from './LocationContext';

/**
 * 
 * @export
 * @interface UpdateLocationBody
 */
export interface UpdateLocationBody {
    /**
     * 
     * @type {LocationContext}
     * @memberof UpdateLocationBody
     */
    context: LocationContext;
    /**
     * 
     * @type {Location}
     * @memberof UpdateLocationBody
     */
    location: Location;
    /**
     * 
     * @type {Contact}
     * @memberof UpdateLocationBody
     */
    contact: Contact;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    booth: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationBody
     */
    deadline?: string;
}

/**
 * Check if a given object implements the UpdateLocationBody interface.
 */
export function instanceOfUpdateLocationBody(value: object): boolean {
    if (!('context' in value)) return false;
    if (!('location' in value)) return false;
    if (!('contact' in value)) return false;
    if (!('reference' in value)) return false;
    if (!('booth' in value)) return false;
    return true;
}

export function UpdateLocationBodyFromJSON(json: any): UpdateLocationBody {
    return UpdateLocationBodyFromJSONTyped(json, false);
}

export function UpdateLocationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLocationBody {
    if (json == null) {
        return json;
    }
    return {
        
        'context': LocationContextFromJSON(json['context']),
        'location': LocationFromJSON(json['location']),
        'contact': ContactFromJSON(json['contact']),
        'reference': json['reference'],
        'booth': json['booth'],
        'deadline': json['deadline'] == null ? undefined : json['deadline'],
    };
}

export function UpdateLocationBodyToJSON(value?: UpdateLocationBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'context': LocationContextToJSON(value['context']),
        'location': LocationToJSON(value['location']),
        'contact': ContactToJSON(value['contact']),
        'reference': value['reference'],
        'booth': value['booth'],
        'deadline': value['deadline'],
    };
}

