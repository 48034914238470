/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { CompanyBookedShipmentRow } from './CompanyBookedShipmentRow';
import {
    CompanyBookedShipmentRowFromJSON,
    CompanyBookedShipmentRowFromJSONTyped,
    CompanyBookedShipmentRowToJSON,
} from './CompanyBookedShipmentRow';
import type { CompanyNote } from './CompanyNote';
import {
    CompanyNoteFromJSON,
    CompanyNoteFromJSONTyped,
    CompanyNoteToJSON,
} from './CompanyNote';
import type { CompanyPaymentMethodInformation } from './CompanyPaymentMethodInformation';
import {
    CompanyPaymentMethodInformationFromJSON,
    CompanyPaymentMethodInformationFromJSONTyped,
    CompanyPaymentMethodInformationToJSON,
} from './CompanyPaymentMethodInformation';
import type { CompanyQuotedShipmentRow } from './CompanyQuotedShipmentRow';
import {
    CompanyQuotedShipmentRowFromJSON,
    CompanyQuotedShipmentRowFromJSONTyped,
    CompanyQuotedShipmentRowToJSON,
} from './CompanyQuotedShipmentRow';
import type { CompanyStats } from './CompanyStats';
import {
    CompanyStatsFromJSON,
    CompanyStatsFromJSONTyped,
    CompanyStatsToJSON,
} from './CompanyStats';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { CustomerInvoice } from './CustomerInvoice';
import {
    CustomerInvoiceFromJSON,
    CustomerInvoiceFromJSONTyped,
    CustomerInvoiceToJSON,
} from './CustomerInvoice';
import type { InvoiceWarningState } from './InvoiceWarningState';
import {
    InvoiceWarningStateFromJSON,
    InvoiceWarningStateFromJSONTyped,
    InvoiceWarningStateToJSON,
} from './InvoiceWarningState';
import type { MonthlyChartLine } from './MonthlyChartLine';
import {
    MonthlyChartLineFromJSON,
    MonthlyChartLineFromJSONTyped,
    MonthlyChartLineToJSON,
} from './MonthlyChartLine';
import type { SalesContact } from './SalesContact';
import {
    SalesContactFromJSON,
    SalesContactFromJSONTyped,
    SalesContactToJSON,
} from './SalesContact';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CompanyData
 */
export interface CompanyData {
    /**
     * 
     * @type {Company}
     * @memberof CompanyData
     */
    company: Company;
    /**
     * 
     * @type {Array<User>}
     * @memberof CompanyData
     */
    users: Array<User>;
    /**
     * 
     * @type {Array<MonthlyChartLine>}
     * @memberof CompanyData
     */
    shipmentsBookedPerMonth: Array<MonthlyChartLine>;
    /**
     * 
     * @type {Array<MonthlyChartLine>}
     * @memberof CompanyData
     */
    shipmentsQuotedPerMonth: Array<MonthlyChartLine>;
    /**
     * 
     * @type {Array<CompanyBookedShipmentRow>}
     * @memberof CompanyData
     */
    bookedShipments: Array<CompanyBookedShipmentRow>;
    /**
     * 
     * @type {Array<CompanyQuotedShipmentRow>}
     * @memberof CompanyData
     */
    quotedShipments: Array<CompanyQuotedShipmentRow>;
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof CompanyData
     */
    coupons: Array<Coupon>;
    /**
     * 
     * @type {Coupon}
     * @memberof CompanyData
     */
    bestCoupon?: Coupon;
    /**
     * 
     * @type {CompanyStats}
     * @memberof CompanyData
     */
    companyStats: CompanyStats;
    /**
     * 
     * @type {CompanyPaymentMethodInformation}
     * @memberof CompanyData
     */
    paymentMethodInfo: CompanyPaymentMethodInformation;
    /**
     * 
     * @type {Array<CustomerInvoice>}
     * @memberof CompanyData
     */
    customerInvoices: Array<CustomerInvoice>;
    /**
     * 
     * @type {InvoiceWarningState}
     * @memberof CompanyData
     */
    invoiceWarningState: InvoiceWarningState;
    /**
     * 
     * @type {Array<CompanyNote>}
     * @memberof CompanyData
     */
    companyNotes: Array<CompanyNote>;
    /**
     * 
     * @type {Array<SalesContact>}
     * @memberof CompanyData
     */
    salesContacts: Array<SalesContact>;
}

/**
 * Check if a given object implements the CompanyData interface.
 */
export function instanceOfCompanyData(value: object): boolean {
    if (!('company' in value)) return false;
    if (!('users' in value)) return false;
    if (!('shipmentsBookedPerMonth' in value)) return false;
    if (!('shipmentsQuotedPerMonth' in value)) return false;
    if (!('bookedShipments' in value)) return false;
    if (!('quotedShipments' in value)) return false;
    if (!('coupons' in value)) return false;
    if (!('companyStats' in value)) return false;
    if (!('paymentMethodInfo' in value)) return false;
    if (!('customerInvoices' in value)) return false;
    if (!('invoiceWarningState' in value)) return false;
    if (!('companyNotes' in value)) return false;
    if (!('salesContacts' in value)) return false;
    return true;
}

export function CompanyDataFromJSON(json: any): CompanyData {
    return CompanyDataFromJSONTyped(json, false);
}

export function CompanyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyData {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyFromJSON(json['company']),
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
        'shipmentsBookedPerMonth': ((json['shipmentsBookedPerMonth'] as Array<any>).map(MonthlyChartLineFromJSON)),
        'shipmentsQuotedPerMonth': ((json['shipmentsQuotedPerMonth'] as Array<any>).map(MonthlyChartLineFromJSON)),
        'bookedShipments': ((json['bookedShipments'] as Array<any>).map(CompanyBookedShipmentRowFromJSON)),
        'quotedShipments': ((json['quotedShipments'] as Array<any>).map(CompanyQuotedShipmentRowFromJSON)),
        'coupons': ((json['coupons'] as Array<any>).map(CouponFromJSON)),
        'bestCoupon': json['bestCoupon'] == null ? undefined : CouponFromJSON(json['bestCoupon']),
        'companyStats': CompanyStatsFromJSON(json['companyStats']),
        'paymentMethodInfo': CompanyPaymentMethodInformationFromJSON(json['paymentMethodInfo']),
        'customerInvoices': ((json['customerInvoices'] as Array<any>).map(CustomerInvoiceFromJSON)),
        'invoiceWarningState': InvoiceWarningStateFromJSON(json['invoiceWarningState']),
        'companyNotes': ((json['companyNotes'] as Array<any>).map(CompanyNoteFromJSON)),
        'salesContacts': ((json['salesContacts'] as Array<any>).map(SalesContactFromJSON)),
    };
}

export function CompanyDataToJSON(value?: CompanyData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyToJSON(value['company']),
        'users': ((value['users'] as Array<any>).map(UserToJSON)),
        'shipmentsBookedPerMonth': ((value['shipmentsBookedPerMonth'] as Array<any>).map(MonthlyChartLineToJSON)),
        'shipmentsQuotedPerMonth': ((value['shipmentsQuotedPerMonth'] as Array<any>).map(MonthlyChartLineToJSON)),
        'bookedShipments': ((value['bookedShipments'] as Array<any>).map(CompanyBookedShipmentRowToJSON)),
        'quotedShipments': ((value['quotedShipments'] as Array<any>).map(CompanyQuotedShipmentRowToJSON)),
        'coupons': ((value['coupons'] as Array<any>).map(CouponToJSON)),
        'bestCoupon': CouponToJSON(value['bestCoupon']),
        'companyStats': CompanyStatsToJSON(value['companyStats']),
        'paymentMethodInfo': CompanyPaymentMethodInformationToJSON(value['paymentMethodInfo']),
        'customerInvoices': ((value['customerInvoices'] as Array<any>).map(CustomerInvoiceToJSON)),
        'invoiceWarningState': InvoiceWarningStateToJSON(value['invoiceWarningState']),
        'companyNotes': ((value['companyNotes'] as Array<any>).map(CompanyNoteToJSON)),
        'salesContacts': ((value['salesContacts'] as Array<any>).map(SalesContactToJSON)),
    };
}

