/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSalesContactResponse
 */
export interface CreateSalesContactResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesContactResponse
     */
    salesContactId: string;
}

/**
 * Check if a given object implements the CreateSalesContactResponse interface.
 */
export function instanceOfCreateSalesContactResponse(value: object): boolean {
    if (!('salesContactId' in value)) return false;
    return true;
}

export function CreateSalesContactResponseFromJSON(json: any): CreateSalesContactResponse {
    return CreateSalesContactResponseFromJSONTyped(json, false);
}

export function CreateSalesContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSalesContactResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'salesContactId': json['salesContactId'],
    };
}

export function CreateSalesContactResponseToJSON(value?: CreateSalesContactResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'salesContactId': value['salesContactId'],
    };
}

