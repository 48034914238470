/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const QuoteGenerationMethod = {
    Manual: 'manual',
    Ratesheet: 'ratesheet',
    Api: 'api',
    Scraping: 'scraping'
} as const;
export type QuoteGenerationMethod = typeof QuoteGenerationMethod[keyof typeof QuoteGenerationMethod];


export function instanceOfQuoteGenerationMethod(value: any): boolean {
    return Object.values(QuoteGenerationMethod).includes(value);
}

export function QuoteGenerationMethodFromJSON(json: any): QuoteGenerationMethod {
    return QuoteGenerationMethodFromJSONTyped(json, false);
}

export function QuoteGenerationMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteGenerationMethod {
    return json as QuoteGenerationMethod;
}

export function QuoteGenerationMethodToJSON(value?: QuoteGenerationMethod | null): any {
    return value as any;
}

