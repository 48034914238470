import { IPublicClientApplication } from "@azure/msal-browser";
// MSAL imports
import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import "./App.css";
import { SalesCallFloatingButton } from "./Components/SalesCallFloatingButton";
import { AppContextProvider } from "./Contexts/AppContext";
import { Environment } from "./Helpers/environment";
import { MenuBar } from "./Navigation/MenuBar";
import { AppRouter } from "./Routing/AppRouter";

type AppProps = {
  pca: IPublicClientApplication;
};

export function getAzureClientId(): string {
  return (
    process.env.REACT_APP_AZURE_CLIENT_ID ||
    "0a3192a3-2d90-41da-94a7-2f1237117a28"
  );
}

export function apiServerUrl(): string {
  return process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080";
}

export function redirectUrl(): string {
  return process.env.REACT_APP_API_REDIRECT || "http://localhost:3001";
}

export function getEnvironment(): string {
  return process.env.REACT_APP_FS_ENVIRONMENT || Environment.Local;
}

export function isAuthenticationDisabled(): boolean {
  return (
    process.env.REACT_APP_DISABLE_AUTHENTICATION === "true" &&
    getEnvironment() === Environment.Local
  );
}

export function getConfig() {
  return {
    basePath: apiServerUrl(),
  };
}

function App({ pca }: AppProps) {
  if (isAuthenticationDisabled()) {
    return (
      <>
        <MenuBar />
        <Layout className="layout">
          <Content>
            <div className="site-layout-content">
              <AppRouter />
            </div>
          </Content>
        </Layout>
      </>
    );
  }

  return (
    <MsalProvider instance={pca}>
      <MenuBar />
      <Layout className="layout">
        <Content>
          <div className="site-layout-content">
            <AuthenticatedTemplate>
              <AppContextProvider>
                <AppRouter />
                <SalesCallFloatingButton />
              </AppContextProvider>
            </AuthenticatedTemplate>
          </div>
        </Content>
      </Layout>
    </MsalProvider>
  );
}

export default App;
