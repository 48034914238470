/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TrackingSource = {
    Tracking: 'carrier_tracking',
    CustomerService: 'carrier_customer_service'
} as const;
export type TrackingSource = typeof TrackingSource[keyof typeof TrackingSource];


export function instanceOfTrackingSource(value: any): boolean {
    return Object.values(TrackingSource).includes(value);
}

export function TrackingSourceFromJSON(json: any): TrackingSource {
    return TrackingSourceFromJSONTyped(json, false);
}

export function TrackingSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingSource {
    return json as TrackingSource;
}

export function TrackingSourceToJSON(value?: TrackingSource | null): any {
    return value as any;
}

