import { Button, Form, InputNumber, message, Modal, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import { EquipmentTypeDropdown } from "../../Components/EquipmentTypeDropdown";
import { EquipmentType } from "../../generated-openapi-client/models/EquipmentType";
import { TabProps } from "./TabProps";

export function OpenForManualQuotingButton(props: TabProps) {
  console.log(`OpenForManualQuotingButton`);
  const createQuotesApi = useQuotesApi();

  const [equipmentType, setEquipmentType] = useState<EquipmentType | undefined>(
    props.shipmentData.shipment.shipment.equipmentType
  );
  const [exclusiveUse, setExclusiveUse] = useState(
    props.shipmentData.shipment.shipment.exclusiveUseNeeded!!
  );
  const [tarpRequired, setTarpRequired] = useState(
    props.shipmentData.shipment.shipment.tarpRequired!!
  );
  const [linearFeet, setLinearFeet] = useState(
    props.shipmentData.shipment.shipment.linearFeet!!
  );
  const [notes, setNotes] = useState(
    props.shipmentData.shipment.shipment.manualQuotingNotes ?? ""
  );

  const [saving, setSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    console.log(`!!!! handleOk`);
    if (!equipmentType) {
      console.log(`!!!! handleOk - b`);
      message.warn("Please specify equipment type");
      return;
    }

    console.log(`!!!! handleOk - a : ${notes}`);

    if (notes.trim() === "") {
      console.log(`!!!! handleOk - a2`);
      message.warn("Notes are empty. Put a dash if there really are no notes");
      return;
    }

    console.log(`!!!! handleOk - 2`);

    const shipmentId = props.shipmentData.shipmentId;
    const quotesApi = await createQuotesApi();

    setSaving(true);

    try {
      await quotesApi.openShipmentForManualQuotes({
        shipmentId,
        equipmentType,
        exclusiveUse,
        tarpRequired,
        linearFeet,
        notes,
      });
      await props.onRefresh();
      message.success(`Done`);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setSaving(false);
  }

  if (props.shipmentData.shipment.shipment.manualQuotingOpen) {
    return <></>;
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`Start Manual Quoting`}
        visible={isModalVisible}
        okText="Save changes"
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
        confirmLoading={saving}
        destroyOnClose={true}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Equipment Type">
            <EquipmentTypeDropdown
              value={equipmentType}
              setValue={setEquipmentType}
            />
          </Form.Item>
          <Form.Item label="Exclusive Use">
            <Switch checked={exclusiveUse} onChange={setExclusiveUse} />
          </Form.Item>
          <Form.Item label="Tarp Required">
            <Switch checked={tarpRequired} onChange={setTarpRequired} />
          </Form.Item>
          <Form.Item label="Linear Feet">
            <InputNumber value={linearFeet} onChange={setLinearFeet} />
          </Form.Item>
          <Form.Item label="Notes">
            <TextArea
              style={{ width: "600px", height: "100px" }}
              value={notes}
              onChange={function (e) {
                setNotes(e.target.value);
              }}
              placeholder="Enter any notes for manual quoting"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>Start Manual Quoting</Button>
    </>
  );
}
