import {
  ObjectDifference,
  objectDifferences,
  ObjectDifferencesConfig,
} from "./objectDifferences";

interface ItemDifference {
  index: number;
  differences: ObjectDifference[];
}

interface ItemAdditionDeletion {
  index: number;
  item: any;
}

export interface ArrayDifferences {
  changes: ItemDifference[];
  deletions: ItemAdditionDeletion[];
  additions: ItemAdditionDeletion[];
}

export function arrayDifferences<L extends object, R extends object>(
  left: L[],
  right: R[],
  config?: Partial<ObjectDifferencesConfig<L, R>>
): ArrayDifferences {
  const differences: ArrayDifferences = {
    changes: [],
    deletions: [],
    additions: [],
  };

  // if arrays are same length
  // - changed data
  // - compare one by one
  if (left.length === right.length) {
    for (let i = 0; i < left.length; i++) {
      const valueDifferences = objectDifferences(left[i], right[i], config);

      if (valueDifferences.length > 0) {
        differences.changes.push({
          index: i,
          differences: valueDifferences,
        });
      }
    }
  }

  // Deletions
  if (left.length > right.length) {
    for (let i = 0; i < right.length; i++) {
      const valueDifferences = objectDifferences(left[i], right[i], config);

      if (valueDifferences.length > 0) {
        differences.changes.push({
          index: i,
          differences: valueDifferences,
        });
      }
    }

    for (let i = right.length; i < left.length; i++) {
      differences.deletions.push({
        index: i,
        item: left[i],
      });
    }
  }

  // Additions
  if (left.length < right.length) {
    for (let i = 0; i < left.length; i++) {
      const valueDifferences = objectDifferences(left[i], right[i], config);

      if (valueDifferences.length > 0) {
        differences.changes.push({
          index: i,
          differences: valueDifferences,
        });
      }
    }

    for (let i = left.length; i < right.length; i++) {
      differences.additions.push({
        index: i,
        item: right[i],
      });
    }
  }

  return differences;
}
