import { Alert, Button, Form, InputNumber, message, Modal, Switch } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { EquipmentTypeDropdown } from "../../Components/EquipmentTypeDropdown";
import { EquipmentType } from "../../generated-openapi-client";
import { CurrencySelector } from "../CurrencySelector";
import { TabProps } from "./TabProps";

export function EditGeneralButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!!;
  const createShipmentApi = useShipmentsApi();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [equipmentType, setEquipmentType] = useState<EquipmentType | undefined>(
    props.shipmentData.shipment.shipment.equipmentType
  );
  const [exclusiveUseNeeded, setExclusiveUseNeeded] = useState(
    props.shipmentData.shipment.shipment.exclusiveUseNeeded!!
  );
  const [tarpRequired, setTarpRequired] = useState(
    props.shipmentData.shipment.shipment.tarpRequired!!
  );

  const [linearFeet, setLinearFeet] = useState(
    props.shipmentData.shipment.shipment.linearFeet!!
  );

  const [addInsuranceToShipment, setAddInsuranceToShipment] = useState(
    props.shipmentData.shipment.shipment.addInsuranceToShipment!!
  );
  const [insuranceAmount, setInsuranceAmount] = useState(
    props.shipmentData.shipment.shipment.insuranceAmount!!
  );
  const [insuranceCurrency, setInsuranceCurrency] = useState(
    props.shipmentData.shipment.shipment.insuranceCurrency!!
  );

  const [saving, setSaving] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (exclusiveUseNeeded && !equipmentType) {
      message.warn(
        "Please specify an equipment type if requesting exclusive use"
      );
      return;
    }

    setSaving(true);
    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.updateGeneralInfo({
        shipmentId,
        exclusiveUseNeeded,
        tarpRequired,
        linearFeet,
        equipmentType,
        addInsuranceToShipment,
        insuranceAmount,
        insuranceCurrency,
      });
      await props.onRefresh();
      setIsModalVisible(false);

      message.success(
        "Location updated! BOL/Pickup pack regenerated. Carrier was NOT notified."
      );
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`Edit General Info`}
        visible={isModalVisible}
        okText="Save changes"
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
        confirmLoading={saving}
        destroyOnClose={true}
      >
        <Alert
          type="warning"
          description="Modifying information here might require a new quote. For example changing the insurance amount"
          style={{ marginBottom: "40px" }}
        ></Alert>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Equipment Type">
            <EquipmentTypeDropdown
              value={equipmentType}
              setValue={setEquipmentType}
            />
          </Form.Item>
          <Form.Item label="Exclusive Use">
            <Switch
              checked={exclusiveUseNeeded}
              onChange={setExclusiveUseNeeded}
            />
          </Form.Item>
          <Form.Item label="Tarp Required">
            <Switch checked={tarpRequired} onChange={setTarpRequired} />
          </Form.Item>
          <Form.Item label="Linear Feet">
            <InputNumber
              value={linearFeet}
              onChange={setLinearFeet}
              prefix={"ft"}
            />
          </Form.Item>
          <Form.Item label="Add Insurance To Shipment">
            <Switch
              checked={addInsuranceToShipment}
              onChange={setAddInsuranceToShipment}
            />
          </Form.Item>
          <Form.Item label="Insurance Amount">
            <InputNumber
              value={insuranceAmount}
              onChange={setInsuranceAmount}
              prefix={"$"}
            />
          </Form.Item>
          <Form.Item label="Insurance Currency">
            <CurrencySelector
              currency={insuranceCurrency}
              setCurrency={setInsuranceCurrency}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>Edit</Button>
    </>
  );
}
