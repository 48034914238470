/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailTemplateImage
 */
export interface EmailTemplateImage {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateImage
     */
    emailTemplateImageId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateImage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateImage
     */
    url?: string;
}

/**
 * Check if a given object implements the EmailTemplateImage interface.
 */
export function instanceOfEmailTemplateImage(value: object): boolean {
    return true;
}

export function EmailTemplateImageFromJSON(json: any): EmailTemplateImage {
    return EmailTemplateImageFromJSONTyped(json, false);
}

export function EmailTemplateImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplateImage {
    if (json == null) {
        return json;
    }
    return {
        
        'emailTemplateImageId': json['emailTemplateImageId'] == null ? undefined : json['emailTemplateImageId'],
        'title': json['title'] == null ? undefined : json['title'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function EmailTemplateImageToJSON(value?: EmailTemplateImage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailTemplateImageId': value['emailTemplateImageId'],
        'title': value['title'],
        'url': value['url'],
    };
}

