/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActiveShipments,
  BookPickupOutput,
  Broker,
  CarrierPickupNumberHistory,
  CarrierRequestAudit,
  ChangePickupDateReason,
  ChangeProNumberResult,
  ClaimsTabData,
  CompanyShipment,
  CompanyShipmentQuote,
  ContactTabData,
  CurrencyCode,
  DocumentType,
  EmailDescription,
  EquipmentType,
  FedExAddressValidationInformation,
  GenerateScenarioCodeForShipmentResponse,
  GetShipmentsByIdsResponse,
  InterlineContext,
  InvoiceAuditProblem,
  InvoiceAuditTurn,
  LineItem,
  NoteCategory,
  PaymentMethodInformation,
  ProNumberHistory,
  ShipmentCompanyFreightClaim,
  ShipmentMondayStatus,
  ShipmentReport,
  UpdateLocationBody,
  ViewShipmentDataForApollo,
} from '../models/index';
import {
    ActiveShipmentsFromJSON,
    ActiveShipmentsToJSON,
    BookPickupOutputFromJSON,
    BookPickupOutputToJSON,
    BrokerFromJSON,
    BrokerToJSON,
    CarrierPickupNumberHistoryFromJSON,
    CarrierPickupNumberHistoryToJSON,
    CarrierRequestAuditFromJSON,
    CarrierRequestAuditToJSON,
    ChangePickupDateReasonFromJSON,
    ChangePickupDateReasonToJSON,
    ChangeProNumberResultFromJSON,
    ChangeProNumberResultToJSON,
    ClaimsTabDataFromJSON,
    ClaimsTabDataToJSON,
    CompanyShipmentFromJSON,
    CompanyShipmentToJSON,
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteToJSON,
    ContactTabDataFromJSON,
    ContactTabDataToJSON,
    CurrencyCodeFromJSON,
    CurrencyCodeToJSON,
    DocumentTypeFromJSON,
    DocumentTypeToJSON,
    EmailDescriptionFromJSON,
    EmailDescriptionToJSON,
    EquipmentTypeFromJSON,
    EquipmentTypeToJSON,
    FedExAddressValidationInformationFromJSON,
    FedExAddressValidationInformationToJSON,
    GenerateScenarioCodeForShipmentResponseFromJSON,
    GenerateScenarioCodeForShipmentResponseToJSON,
    GetShipmentsByIdsResponseFromJSON,
    GetShipmentsByIdsResponseToJSON,
    InterlineContextFromJSON,
    InterlineContextToJSON,
    InvoiceAuditProblemFromJSON,
    InvoiceAuditProblemToJSON,
    InvoiceAuditTurnFromJSON,
    InvoiceAuditTurnToJSON,
    LineItemFromJSON,
    LineItemToJSON,
    NoteCategoryFromJSON,
    NoteCategoryToJSON,
    PaymentMethodInformationFromJSON,
    PaymentMethodInformationToJSON,
    ProNumberHistoryFromJSON,
    ProNumberHistoryToJSON,
    ShipmentCompanyFreightClaimFromJSON,
    ShipmentCompanyFreightClaimToJSON,
    ShipmentMondayStatusFromJSON,
    ShipmentMondayStatusToJSON,
    ShipmentReportFromJSON,
    ShipmentReportToJSON,
    UpdateLocationBodyFromJSON,
    UpdateLocationBodyToJSON,
    ViewShipmentDataForApolloFromJSON,
    ViewShipmentDataForApolloToJSON,
} from '../models/index';

export interface AddInternalNoteRequest {
    shipmentId: string;
    note: string;
    category: NoteCategory;
}

export interface AddTrackingLineRequest {
    shipmentId: string;
    message: string;
}

export interface AdjustTrackingLineTimestampRequest {
    trackingLineId: string;
    newTimestamp: string;
}

export interface BookPickupRequest {
    shipmentId: string;
}

export interface CancelShipmentRequest {
    shipmentId: string;
    doNotCancelPickup: boolean;
    reason: string;
}

export interface CarrierRequestAuditsForShipmentRequest {
    shipmentId: string;
}

export interface ChangeCarrierPickupNumberRequest {
    shipmentId: string;
    carrierPickupNumber: string;
}

export interface ChangeDocumentTypeRequest {
    shipmentDocumentId: string;
    documentType: DocumentType;
}

export interface ChangeExpectedDeliveryDateRequest {
    shipmentId: string;
    newExpectedDeliveryDate: string;
}

export interface ChangePaymentMethodForShipmentRequest {
    shipmentId: string;
    stripePaymentMethodId: string;
}

export interface ChangePickupDateRequest {
    shipmentId: string;
    newPickupDate: string;
    reason: ChangePickupDateReason;
}

export interface ChangeProNumberRequest {
    shipmentId: string;
    proNumber: string;
}

export interface ClearCustomsUploadStateRequest {
    shipmentId: string;
}

export interface CloseCarrierInvoiceMondayTaskRequest {
    mondayId: string;
}

export interface CloseCustomerInvoiceMondayTaskRequest {
    mondayId: string;
}

export interface CloseShipmentMondayTaskRequest {
    mondayId: string;
}

export interface DeleteCarrierPickupNumberHistoryRequest {
    carrierPickupNumberHistoryId: string;
}

export interface DeleteDocumentRequest {
    shipmentDocumentId: string;
}

export interface DeleteProNumberHistoryRequest {
    proNumberHistoryId: string;
}

export interface DeleteTrackingLineRequest {
    trackingLineId: string;
}

export interface ForceDocumentsUpdateRequest {
    shipmentId: string;
}

export interface ForceTrackingUpdateRequest {
    shipmentId: string;
}

export interface GenerateClaudeAnalysisRequest {
    shipmentId: string;
}

export interface GenerateScenarioCodeForShipmentRequest {
    shipmentId: string;
}

export interface GenerateXeroClaimInvoicesRequest {
    shipmentId: string;
    moneyInAmount: number;
    moneyOutAmount: number;
    moneyInCurrencyCode: CurrencyCode;
    moneyOutCurrencyCode: CurrencyCode;
    claimInvoiceNumber: string;
    carrierIdentifier: string;
}

export interface GetFedExAddressValidationInformationRequest {
    shipmentId: string;
}

export interface GetShipmentByIdRequest {
    shipmentId: string;
}

export interface GetShipmentClaimsTabByIdRequest {
    shipmentId: string;
}

export interface GetShipmentContactsTabByIdRequest {
    shipmentId: string;
}

export interface GetShipmentMondayStatusRequest {
    shipmentId: string;
}

export interface GetShipmentPaymentMethodInfoByIdRequest {
    shipmentId: string;
}

export interface GetShipmentReportByIdRequest {
    shipmentId: string;
}

export interface GetShipmentsByIdsRequest {
    shipmentIds: Array<string>;
}

export interface GetShipmentsPerCarrierRequest {
    carrierIdentifier: string;
}

export interface InvoiceAuditPingCarrierRequest {
    shipmentId: string;
}

export interface InvoiceAuditReplyToCarrierRequest {
    shipmentId: string;
    message: string;
}

export interface MarkBookingConfirmedRequest {
    shipmentId: string;
}

export interface MarkCancelledRequest {
    shipmentId: string;
    reason: string;
}

export interface MarkChallengeAllInvoicesRequest {
    shipmentId: string;
    reason: string;
}

export interface MarkDeliveredRequest {
    shipmentId: string;
    actualDeliveryDate: string;
    actualDeliveryTime: string;
}

export interface MarkDoNotFetchFurtherCarrierInvoicesRequest {
    shipmentId: string;
}

export interface MarkFreightMissingRequest {
    shipmentId: string;
}

export interface MarkFreightNotMissingRequest {
    shipmentId: string;
}

export interface MarkHotFreightRequest {
    shipmentId: string;
}

export interface MarkInTransitRequest {
    shipmentId: string;
}

export interface MarkLostRequest {
    shipmentId: string;
}

export interface MarkNotOutForDeliveryRequest {
    shipmentId: string;
}

export interface MarkPickupBookedRequest {
    shipmentId: string;
}

export interface MarkQuotedRequest {
    shipmentId: string;
}

export interface MarkQuotingFlaggedRequest {
    shipmentId: string;
}

export interface MarkQuotingNotFlaggedRequest {
    shipmentId: string;
}

export interface MarkQuotingNotTriagedRequest {
    shipmentId: string;
}

export interface MarkQuotingTriagedRequest {
    shipmentId: string;
}

export interface MarkShipmentMondayTaskAsWaitingForCarrierRequest {
    mondayId: string;
}

export interface MarkShipmentOnHoldRequest {
    shipmentId: string;
    doNotCancelPickup: boolean;
}

export interface MoveShipmentToAnotherCompanyRequest {
    shipmentId: string;
    newCompanyId: string;
}

export interface PauseAmyEmailsUntilRequest {
    shipmentId: string;
    pauseAmyEmailsUntilDate: string;
}

export interface PreviewBookPickupEmailRequest {
    shipmentId: string;
}

export interface RecalculateShipmentStatsRequest {
    shipmentId: string;
}

export interface RegenerateBillOfLadingForShipmentRequest {
    shipmentId: string;
    sendNotificationEmails: boolean;
}

export interface RetryInvoiceApprovalRequest {
    shipmentId: string;
}

export interface SearchShipmentsRequest {
    query: string;
}

export interface SetAppointmentDateRequest {
    shipmentId: string;
    appointmentDate: string;
}

export interface SetInvoiceAuditAssigneeRequest {
    shipmentId: string;
    invoiceAuditAssignee: string;
}

export interface SetInvoiceAuditCarrierFrontConversationIdRequest {
    shipmentId: string;
    frontConversationId?: string;
}

export interface SetInvoiceAuditCarrierTurnRequest {
    shipmentId: string;
    turn?: InvoiceAuditTurn;
    date?: string;
}

export interface SetInvoiceAuditCustomerFrontConversationIdRequest {
    shipmentId: string;
    frontConversationId?: string;
}

export interface SetInvoiceAuditCustomerTurnRequest {
    shipmentId: string;
    turn?: InvoiceAuditTurn;
    date?: string;
}

export interface SnoozeInvoiceAuditUntilRequest {
    shipmentId: string;
    note: string;
    snoozeInvoiceAuditUntilDate?: string;
}

export interface ToggleDeletedRequest {
    shipmentId: string;
    deleted: boolean;
}

export interface ToggleInvoiceAuditOpenRequest {
    shipmentId: string;
    invoiceAuditOpen: boolean;
}

export interface UnpauseAmyEmailsRequest {
    shipmentId: string;
}

export interface UnsetAppointmentDateRequest {
    shipmentId: string;
}

export interface UpdateBookerRequest {
    shipmentId: string;
    name: string;
    email: string;
    phone: string;
}

export interface UpdateCustomsBrokerRequest {
    shipmentId: string;
    broker: Broker;
}

export interface UpdateEmergencyContactRequest {
    shipmentId: string;
    companyId: string;
    contactName: string;
    phoneNumber: string;
    phoneNumberExtension?: string;
}

export interface UpdateGeneralInfoRequest {
    shipmentId: string;
    exclusiveUseNeeded: boolean;
    tarpRequired: boolean;
    linearFeet: number;
    addInsuranceToShipment: boolean;
    insuranceAmount: number;
    insuranceCurrency: CurrencyCode;
    equipmentType?: EquipmentType;
}

export interface UpdateInterlineInformationRequest {
    shipmentId: string;
    interlineProNumber: string;
    interlineCarrierPickupNumber: string;
    interlineCarrierIdentifier: string;
    interlineCarrierDisplayName: string;
    interlineContext: InterlineContext;
}

export interface UpdateInvoiceAuditProblemsRequest {
    shipmentId: string;
    invoiceAuditProblem: Array<InvoiceAuditProblem>;
}

export interface UpdateLineItemsRequest {
    shipmentId: string;
    reason: string;
    lineItem: Array<LineItem>;
}

export interface UpdateLocationRequest {
    shipmentId: string;
    updateLocationBody: UpdateLocationBody;
}

export interface UpdateQuoterRequest {
    shipmentId: string;
    name: string;
    email: string;
    phone: string;
}

export interface UploadDocumentRequest {
    shipmentId: string;
    documentType: DocumentType;
    body: string;
}

export interface ViewCarrierPickupNumberHistoryRequest {
    shipmentId: string;
}

export interface ViewProNumberHistoryRequest {
    shipmentId: string;
}

/**
 * 
 */
export class ShipmentsApi extends runtime.BaseAPI {

    /**
     */
    async addInternalNoteRaw(requestParameters: AddInternalNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling addInternalNote().'
            );
        }

        if (requestParameters['note'] == null) {
            throw new runtime.RequiredError(
                'note',
                'Required parameter "note" was null or undefined when calling addInternalNote().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling addInternalNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['note'] != null) {
            queryParameters['note'] = requestParameters['note'];
        }

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/add-internal-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addInternalNote(requestParameters: AddInternalNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addInternalNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addTrackingLineRaw(requestParameters: AddTrackingLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling addTrackingLine().'
            );
        }

        if (requestParameters['message'] == null) {
            throw new runtime.RequiredError(
                'message',
                'Required parameter "message" was null or undefined when calling addTrackingLine().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/add-tracking-line`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addTrackingLine(requestParameters: AddTrackingLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addTrackingLineRaw(requestParameters, initOverrides);
    }

    /**
     */
    async adjustTrackingLineTimestampRaw(requestParameters: AdjustTrackingLineTimestampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['trackingLineId'] == null) {
            throw new runtime.RequiredError(
                'trackingLineId',
                'Required parameter "trackingLineId" was null or undefined when calling adjustTrackingLineTimestamp().'
            );
        }

        if (requestParameters['newTimestamp'] == null) {
            throw new runtime.RequiredError(
                'newTimestamp',
                'Required parameter "newTimestamp" was null or undefined when calling adjustTrackingLineTimestamp().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['trackingLineId'] != null) {
            queryParameters['trackingLineId'] = requestParameters['trackingLineId'];
        }

        if (requestParameters['newTimestamp'] != null) {
            queryParameters['newTimestamp'] = requestParameters['newTimestamp'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/adjust-tracking-line-timestamp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async adjustTrackingLineTimestamp(requestParameters: AdjustTrackingLineTimestampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.adjustTrackingLineTimestampRaw(requestParameters, initOverrides);
    }

    /**
     */
    async bookPickupRaw(requestParameters: BookPickupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BookPickupOutput>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling bookPickup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/book-pickup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookPickupOutputFromJSON(jsonValue));
    }

    /**
     */
    async bookPickup(requestParameters: BookPickupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BookPickupOutput> {
        const response = await this.bookPickupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cancelShipmentRaw(requestParameters: CancelShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling cancelShipment().'
            );
        }

        if (requestParameters['doNotCancelPickup'] == null) {
            throw new runtime.RequiredError(
                'doNotCancelPickup',
                'Required parameter "doNotCancelPickup" was null or undefined when calling cancelShipment().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling cancelShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['doNotCancelPickup'] != null) {
            queryParameters['doNotCancelPickup'] = requestParameters['doNotCancelPickup'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/cancel-shipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelShipment(requestParameters: CancelShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelShipmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async carrierRequestAuditsForShipmentRaw(requestParameters: CarrierRequestAuditsForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierRequestAudit>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling carrierRequestAuditsForShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/carrier-request-audits-for-shipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierRequestAuditFromJSON));
    }

    /**
     */
    async carrierRequestAuditsForShipment(requestParameters: CarrierRequestAuditsForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierRequestAudit>> {
        const response = await this.carrierRequestAuditsForShipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeCarrierPickupNumberRaw(requestParameters: ChangeCarrierPickupNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling changeCarrierPickupNumber().'
            );
        }

        if (requestParameters['carrierPickupNumber'] == null) {
            throw new runtime.RequiredError(
                'carrierPickupNumber',
                'Required parameter "carrierPickupNumber" was null or undefined when calling changeCarrierPickupNumber().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['carrierPickupNumber'] != null) {
            queryParameters['carrierPickupNumber'] = requestParameters['carrierPickupNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/change-carrier-pickup-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeCarrierPickupNumber(requestParameters: ChangeCarrierPickupNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeCarrierPickupNumberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeDocumentTypeRaw(requestParameters: ChangeDocumentTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentDocumentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentDocumentId',
                'Required parameter "shipmentDocumentId" was null or undefined when calling changeDocumentType().'
            );
        }

        if (requestParameters['documentType'] == null) {
            throw new runtime.RequiredError(
                'documentType',
                'Required parameter "documentType" was null or undefined when calling changeDocumentType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentDocumentId'] != null) {
            queryParameters['shipmentDocumentId'] = requestParameters['shipmentDocumentId'];
        }

        if (requestParameters['documentType'] != null) {
            queryParameters['documentType'] = requestParameters['documentType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/change-document-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeDocumentType(requestParameters: ChangeDocumentTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeDocumentTypeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeExpectedDeliveryDateRaw(requestParameters: ChangeExpectedDeliveryDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling changeExpectedDeliveryDate().'
            );
        }

        if (requestParameters['newExpectedDeliveryDate'] == null) {
            throw new runtime.RequiredError(
                'newExpectedDeliveryDate',
                'Required parameter "newExpectedDeliveryDate" was null or undefined when calling changeExpectedDeliveryDate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['newExpectedDeliveryDate'] != null) {
            queryParameters['newExpectedDeliveryDate'] = requestParameters['newExpectedDeliveryDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/change-expected-delivery-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeExpectedDeliveryDate(requestParameters: ChangeExpectedDeliveryDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeExpectedDeliveryDateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changePaymentMethodForShipmentRaw(requestParameters: ChangePaymentMethodForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling changePaymentMethodForShipment().'
            );
        }

        if (requestParameters['stripePaymentMethodId'] == null) {
            throw new runtime.RequiredError(
                'stripePaymentMethodId',
                'Required parameter "stripePaymentMethodId" was null or undefined when calling changePaymentMethodForShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['stripePaymentMethodId'] != null) {
            queryParameters['stripePaymentMethodId'] = requestParameters['stripePaymentMethodId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/change-payment-method-for-shipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePaymentMethodForShipment(requestParameters: ChangePaymentMethodForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePaymentMethodForShipmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changePickupDateRaw(requestParameters: ChangePickupDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling changePickupDate().'
            );
        }

        if (requestParameters['newPickupDate'] == null) {
            throw new runtime.RequiredError(
                'newPickupDate',
                'Required parameter "newPickupDate" was null or undefined when calling changePickupDate().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling changePickupDate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['newPickupDate'] != null) {
            queryParameters['newPickupDate'] = requestParameters['newPickupDate'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/change-pickup-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePickupDate(requestParameters: ChangePickupDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePickupDateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeProNumberRaw(requestParameters: ChangeProNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeProNumberResult>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling changeProNumber().'
            );
        }

        if (requestParameters['proNumber'] == null) {
            throw new runtime.RequiredError(
                'proNumber',
                'Required parameter "proNumber" was null or undefined when calling changeProNumber().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['proNumber'] != null) {
            queryParameters['proNumber'] = requestParameters['proNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/change-pro-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeProNumberResultFromJSON(jsonValue));
    }

    /**
     */
    async changeProNumber(requestParameters: ChangeProNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeProNumberResult> {
        const response = await this.changeProNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clearCustomsUploadStateRaw(requestParameters: ClearCustomsUploadStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling clearCustomsUploadState().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/clear-customs-upload-state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clearCustomsUploadState(requestParameters: ClearCustomsUploadStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearCustomsUploadStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async closeCarrierInvoiceMondayTaskRaw(requestParameters: CloseCarrierInvoiceMondayTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mondayId'] == null) {
            throw new runtime.RequiredError(
                'mondayId',
                'Required parameter "mondayId" was null or undefined when calling closeCarrierInvoiceMondayTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mondayId'] != null) {
            queryParameters['mondayId'] = requestParameters['mondayId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/close-carrier-invoice-monday-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async closeCarrierInvoiceMondayTask(requestParameters: CloseCarrierInvoiceMondayTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.closeCarrierInvoiceMondayTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async closeCustomerInvoiceMondayTaskRaw(requestParameters: CloseCustomerInvoiceMondayTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mondayId'] == null) {
            throw new runtime.RequiredError(
                'mondayId',
                'Required parameter "mondayId" was null or undefined when calling closeCustomerInvoiceMondayTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mondayId'] != null) {
            queryParameters['mondayId'] = requestParameters['mondayId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/close-customer-invoice-monday-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async closeCustomerInvoiceMondayTask(requestParameters: CloseCustomerInvoiceMondayTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.closeCustomerInvoiceMondayTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async closeShipmentMondayTaskRaw(requestParameters: CloseShipmentMondayTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mondayId'] == null) {
            throw new runtime.RequiredError(
                'mondayId',
                'Required parameter "mondayId" was null or undefined when calling closeShipmentMondayTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mondayId'] != null) {
            queryParameters['mondayId'] = requestParameters['mondayId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/close-shipment-monday-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async closeShipmentMondayTask(requestParameters: CloseShipmentMondayTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.closeShipmentMondayTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteCarrierPickupNumberHistoryRaw(requestParameters: DeleteCarrierPickupNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierPickupNumberHistoryId'] == null) {
            throw new runtime.RequiredError(
                'carrierPickupNumberHistoryId',
                'Required parameter "carrierPickupNumberHistoryId" was null or undefined when calling deleteCarrierPickupNumberHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierPickupNumberHistoryId'] != null) {
            queryParameters['carrierPickupNumberHistoryId'] = requestParameters['carrierPickupNumberHistoryId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/delete-carrier-pickup-number-history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCarrierPickupNumberHistory(requestParameters: DeleteCarrierPickupNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCarrierPickupNumberHistoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentDocumentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentDocumentId',
                'Required parameter "shipmentDocumentId" was null or undefined when calling deleteDocument().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentDocumentId'] != null) {
            queryParameters['shipmentDocumentId'] = requestParameters['shipmentDocumentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/delete-document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDocument(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProNumberHistoryRaw(requestParameters: DeleteProNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['proNumberHistoryId'] == null) {
            throw new runtime.RequiredError(
                'proNumberHistoryId',
                'Required parameter "proNumberHistoryId" was null or undefined when calling deleteProNumberHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['proNumberHistoryId'] != null) {
            queryParameters['proNumberHistoryId'] = requestParameters['proNumberHistoryId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/delete-pro-number-history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProNumberHistory(requestParameters: DeleteProNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProNumberHistoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTrackingLineRaw(requestParameters: DeleteTrackingLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['trackingLineId'] == null) {
            throw new runtime.RequiredError(
                'trackingLineId',
                'Required parameter "trackingLineId" was null or undefined when calling deleteTrackingLine().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['trackingLineId'] != null) {
            queryParameters['trackingLineId'] = requestParameters['trackingLineId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/delete-tracking-line`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTrackingLine(requestParameters: DeleteTrackingLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTrackingLineRaw(requestParameters, initOverrides);
    }

    /**
     */
    async forceDocumentsUpdateRaw(requestParameters: ForceDocumentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling forceDocumentsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/force-documents-update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forceDocumentsUpdate(requestParameters: ForceDocumentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forceDocumentsUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async forceTrackingUpdateRaw(requestParameters: ForceTrackingUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling forceTrackingUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/force-tracking-update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forceTrackingUpdate(requestParameters: ForceTrackingUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forceTrackingUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateClaudeAnalysisRaw(requestParameters: GenerateClaudeAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling generateClaudeAnalysis().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/generate-claude-analysis`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async generateClaudeAnalysis(requestParameters: GenerateClaudeAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateClaudeAnalysisRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateScenarioCodeForShipmentRaw(requestParameters: GenerateScenarioCodeForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateScenarioCodeForShipmentResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling generateScenarioCodeForShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/generate-scenario-code-for-shipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateScenarioCodeForShipmentResponseFromJSON(jsonValue));
    }

    /**
     */
    async generateScenarioCodeForShipment(requestParameters: GenerateScenarioCodeForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateScenarioCodeForShipmentResponse> {
        const response = await this.generateScenarioCodeForShipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateXeroClaimInvoicesRaw(requestParameters: GenerateXeroClaimInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        if (requestParameters['moneyInAmount'] == null) {
            throw new runtime.RequiredError(
                'moneyInAmount',
                'Required parameter "moneyInAmount" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        if (requestParameters['moneyOutAmount'] == null) {
            throw new runtime.RequiredError(
                'moneyOutAmount',
                'Required parameter "moneyOutAmount" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        if (requestParameters['moneyInCurrencyCode'] == null) {
            throw new runtime.RequiredError(
                'moneyInCurrencyCode',
                'Required parameter "moneyInCurrencyCode" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        if (requestParameters['moneyOutCurrencyCode'] == null) {
            throw new runtime.RequiredError(
                'moneyOutCurrencyCode',
                'Required parameter "moneyOutCurrencyCode" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        if (requestParameters['claimInvoiceNumber'] == null) {
            throw new runtime.RequiredError(
                'claimInvoiceNumber',
                'Required parameter "claimInvoiceNumber" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling generateXeroClaimInvoices().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['moneyInAmount'] != null) {
            queryParameters['moneyInAmount'] = requestParameters['moneyInAmount'];
        }

        if (requestParameters['moneyOutAmount'] != null) {
            queryParameters['moneyOutAmount'] = requestParameters['moneyOutAmount'];
        }

        if (requestParameters['moneyInCurrencyCode'] != null) {
            queryParameters['moneyInCurrencyCode'] = requestParameters['moneyInCurrencyCode'];
        }

        if (requestParameters['moneyOutCurrencyCode'] != null) {
            queryParameters['moneyOutCurrencyCode'] = requestParameters['moneyOutCurrencyCode'];
        }

        if (requestParameters['claimInvoiceNumber'] != null) {
            queryParameters['claimInvoiceNumber'] = requestParameters['claimInvoiceNumber'];
        }

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/generate-xero-claim-invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async generateXeroClaimInvoices(requestParameters: GenerateXeroClaimInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateXeroClaimInvoicesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getActiveShipmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveShipments>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-active-shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveShipmentsFromJSON(jsonValue));
    }

    /**
     */
    async getActiveShipments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveShipments> {
        const response = await this.getActiveShipmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCancelledShipmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyShipmentQuote>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-cancelled-shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyShipmentQuoteFromJSON));
    }

    /**
     */
    async getCancelledShipments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyShipmentQuote>> {
        const response = await this.getCancelledShipmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getClaimsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShipmentCompanyFreightClaim>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-claims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShipmentCompanyFreightClaimFromJSON));
    }

    /**
     */
    async getClaims(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShipmentCompanyFreightClaim>> {
        const response = await this.getClaimsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFedExAddressValidationInformationRaw(requestParameters: GetFedExAddressValidationInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FedExAddressValidationInformation>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getFedExAddressValidationInformation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/fedex-address-validation-information`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FedExAddressValidationInformationFromJSON(jsonValue));
    }

    /**
     */
    async getFedExAddressValidationInformation(requestParameters: GetFedExAddressValidationInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FedExAddressValidationInformation> {
        const response = await this.getFedExAddressValidationInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLostShipmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyShipmentQuote>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-lost-shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyShipmentQuoteFromJSON));
    }

    /**
     */
    async getLostShipments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyShipmentQuote>> {
        const response = await this.getLostShipmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentByIdRaw(requestParameters: GetShipmentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewShipmentDataForApollo>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipment-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewShipmentDataForApolloFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentById(requestParameters: GetShipmentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewShipmentDataForApollo> {
        const response = await this.getShipmentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentClaimsTabByIdRaw(requestParameters: GetShipmentClaimsTabByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClaimsTabData>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentClaimsTabById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipment-claims-tab-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaimsTabDataFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentClaimsTabById(requestParameters: GetShipmentClaimsTabByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClaimsTabData> {
        const response = await this.getShipmentClaimsTabByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentContactsTabByIdRaw(requestParameters: GetShipmentContactsTabByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactTabData>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentContactsTabById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipment-contacts-tab-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactTabDataFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentContactsTabById(requestParameters: GetShipmentContactsTabByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactTabData> {
        const response = await this.getShipmentContactsTabByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentMondayStatusRaw(requestParameters: GetShipmentMondayStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShipmentMondayStatus>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentMondayStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipment-monday-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentMondayStatusFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentMondayStatus(requestParameters: GetShipmentMondayStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShipmentMondayStatus> {
        const response = await this.getShipmentMondayStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentPaymentMethodInfoByIdRaw(requestParameters: GetShipmentPaymentMethodInfoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentMethodInformation>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentPaymentMethodInfoById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipment-payment-method-info-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodInformationFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentPaymentMethodInfoById(requestParameters: GetShipmentPaymentMethodInfoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentMethodInformation> {
        const response = await this.getShipmentPaymentMethodInfoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentReportByIdRaw(requestParameters: GetShipmentReportByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShipmentReport>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentReportById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipment-report-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentReportFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentReportById(requestParameters: GetShipmentReportByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShipmentReport> {
        const response = await this.getShipmentReportByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentsByIdsRaw(requestParameters: GetShipmentsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetShipmentsByIdsResponse>> {
        if (requestParameters['shipmentIds'] == null) {
            throw new runtime.RequiredError(
                'shipmentIds',
                'Required parameter "shipmentIds" was null or undefined when calling getShipmentsByIds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentIds'] != null) {
            queryParameters['shipmentIds'] = requestParameters['shipmentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipments-by-ids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetShipmentsByIdsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getShipmentsByIds(requestParameters: GetShipmentsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetShipmentsByIdsResponse> {
        const response = await this.getShipmentsByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentsPerCarrierRaw(requestParameters: GetShipmentsPerCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyShipmentQuote>>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling getShipmentsPerCarrier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/get-shipments-per-carrier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyShipmentQuoteFromJSON));
    }

    /**
     */
    async getShipmentsPerCarrier(requestParameters: GetShipmentsPerCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyShipmentQuote>> {
        const response = await this.getShipmentsPerCarrierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoiceAuditPingCarrierRaw(requestParameters: InvoiceAuditPingCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling invoiceAuditPingCarrier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/invoice-audit-ping-carrier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async invoiceAuditPingCarrier(requestParameters: InvoiceAuditPingCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoiceAuditPingCarrierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async invoiceAuditReplyToCarrierRaw(requestParameters: InvoiceAuditReplyToCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling invoiceAuditReplyToCarrier().'
            );
        }

        if (requestParameters['message'] == null) {
            throw new runtime.RequiredError(
                'message',
                'Required parameter "message" was null or undefined when calling invoiceAuditReplyToCarrier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/invoice-audit-reply-to-carrier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async invoiceAuditReplyToCarrier(requestParameters: InvoiceAuditReplyToCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoiceAuditReplyToCarrierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markBookingConfirmedRaw(requestParameters: MarkBookingConfirmedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markBookingConfirmed().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-booking-confirmed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markBookingConfirmed(requestParameters: MarkBookingConfirmedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markBookingConfirmedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markCancelledRaw(requestParameters: MarkCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markCancelled().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling markCancelled().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-cancelled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markCancelled(requestParameters: MarkCancelledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markCancelledRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markChallengeAllInvoicesRaw(requestParameters: MarkChallengeAllInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markChallengeAllInvoices().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling markChallengeAllInvoices().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-challenge-all-invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markChallengeAllInvoices(requestParameters: MarkChallengeAllInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markChallengeAllInvoicesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markDeliveredRaw(requestParameters: MarkDeliveredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markDelivered().'
            );
        }

        if (requestParameters['actualDeliveryDate'] == null) {
            throw new runtime.RequiredError(
                'actualDeliveryDate',
                'Required parameter "actualDeliveryDate" was null or undefined when calling markDelivered().'
            );
        }

        if (requestParameters['actualDeliveryTime'] == null) {
            throw new runtime.RequiredError(
                'actualDeliveryTime',
                'Required parameter "actualDeliveryTime" was null or undefined when calling markDelivered().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['actualDeliveryDate'] != null) {
            queryParameters['actualDeliveryDate'] = requestParameters['actualDeliveryDate'];
        }

        if (requestParameters['actualDeliveryTime'] != null) {
            queryParameters['actualDeliveryTime'] = requestParameters['actualDeliveryTime'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-delivered`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markDelivered(requestParameters: MarkDeliveredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markDeliveredRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markDoNotFetchFurtherCarrierInvoicesRaw(requestParameters: MarkDoNotFetchFurtherCarrierInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markDoNotFetchFurtherCarrierInvoices().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-do-not-fetch-further-carrier-invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markDoNotFetchFurtherCarrierInvoices(requestParameters: MarkDoNotFetchFurtherCarrierInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markDoNotFetchFurtherCarrierInvoicesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markFreightMissingRaw(requestParameters: MarkFreightMissingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markFreightMissing().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-freight-missing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markFreightMissing(requestParameters: MarkFreightMissingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markFreightMissingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markFreightNotMissingRaw(requestParameters: MarkFreightNotMissingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markFreightNotMissing().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-freight-not-missing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markFreightNotMissing(requestParameters: MarkFreightNotMissingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markFreightNotMissingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markHotFreightRaw(requestParameters: MarkHotFreightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markHotFreight().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-hot-freight`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markHotFreight(requestParameters: MarkHotFreightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markHotFreightRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markInTransitRaw(requestParameters: MarkInTransitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markInTransit().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-in-transit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markInTransit(requestParameters: MarkInTransitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markInTransitRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markLostRaw(requestParameters: MarkLostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markLost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-lost`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markLost(requestParameters: MarkLostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markLostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markNotOutForDeliveryRaw(requestParameters: MarkNotOutForDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markNotOutForDelivery().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-not-out-for-delivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markNotOutForDelivery(requestParameters: MarkNotOutForDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markNotOutForDeliveryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markPickupBookedRaw(requestParameters: MarkPickupBookedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markPickupBooked().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-pickup-booked`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markPickupBooked(requestParameters: MarkPickupBookedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markPickupBookedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuotedRaw(requestParameters: MarkQuotedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuoted().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-quoted`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuoted(requestParameters: MarkQuotedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuotedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuotingFlaggedRaw(requestParameters: MarkQuotingFlaggedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuotingFlagged().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-quoting-flagged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuotingFlagged(requestParameters: MarkQuotingFlaggedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuotingFlaggedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuotingNotFlaggedRaw(requestParameters: MarkQuotingNotFlaggedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuotingNotFlagged().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-quoting-not-flagged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuotingNotFlagged(requestParameters: MarkQuotingNotFlaggedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuotingNotFlaggedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuotingNotTriagedRaw(requestParameters: MarkQuotingNotTriagedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuotingNotTriaged().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-quoting-not-triaged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuotingNotTriaged(requestParameters: MarkQuotingNotTriagedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuotingNotTriagedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuotingTriagedRaw(requestParameters: MarkQuotingTriagedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuotingTriaged().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-quoting-triaged`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuotingTriaged(requestParameters: MarkQuotingTriagedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuotingTriagedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markShipmentMondayTaskAsWaitingForCarrierRaw(requestParameters: MarkShipmentMondayTaskAsWaitingForCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mondayId'] == null) {
            throw new runtime.RequiredError(
                'mondayId',
                'Required parameter "mondayId" was null or undefined when calling markShipmentMondayTaskAsWaitingForCarrier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mondayId'] != null) {
            queryParameters['mondayId'] = requestParameters['mondayId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-shipment-monday-task-as-waiting-for-carrier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markShipmentMondayTaskAsWaitingForCarrier(requestParameters: MarkShipmentMondayTaskAsWaitingForCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markShipmentMondayTaskAsWaitingForCarrierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markShipmentOnHoldRaw(requestParameters: MarkShipmentOnHoldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markShipmentOnHold().'
            );
        }

        if (requestParameters['doNotCancelPickup'] == null) {
            throw new runtime.RequiredError(
                'doNotCancelPickup',
                'Required parameter "doNotCancelPickup" was null or undefined when calling markShipmentOnHold().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['doNotCancelPickup'] != null) {
            queryParameters['doNotCancelPickup'] = requestParameters['doNotCancelPickup'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/mark-shipment-on-hold`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markShipmentOnHold(requestParameters: MarkShipmentOnHoldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markShipmentOnHoldRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moveShipmentToAnotherCompanyRaw(requestParameters: MoveShipmentToAnotherCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling moveShipmentToAnotherCompany().'
            );
        }

        if (requestParameters['newCompanyId'] == null) {
            throw new runtime.RequiredError(
                'newCompanyId',
                'Required parameter "newCompanyId" was null or undefined when calling moveShipmentToAnotherCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['newCompanyId'] != null) {
            queryParameters['newCompanyId'] = requestParameters['newCompanyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/move-shipment-to-another-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveShipmentToAnotherCompany(requestParameters: MoveShipmentToAnotherCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveShipmentToAnotherCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async pauseAmyEmailsUntilRaw(requestParameters: PauseAmyEmailsUntilRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling pauseAmyEmailsUntil().'
            );
        }

        if (requestParameters['pauseAmyEmailsUntilDate'] == null) {
            throw new runtime.RequiredError(
                'pauseAmyEmailsUntilDate',
                'Required parameter "pauseAmyEmailsUntilDate" was null or undefined when calling pauseAmyEmailsUntil().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['pauseAmyEmailsUntilDate'] != null) {
            queryParameters['pauseAmyEmailsUntilDate'] = requestParameters['pauseAmyEmailsUntilDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/pause-amy-emails-until`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pauseAmyEmailsUntil(requestParameters: PauseAmyEmailsUntilRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pauseAmyEmailsUntilRaw(requestParameters, initOverrides);
    }

    /**
     */
    async previewBookPickupEmailRaw(requestParameters: PreviewBookPickupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewBookPickupEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/preview-book-pickup-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewBookPickupEmail(requestParameters: PreviewBookPickupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewBookPickupEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recalculateShipmentStatsRaw(requestParameters: RecalculateShipmentStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling recalculateShipmentStats().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/recalculate-shipment-stats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async recalculateShipmentStats(requestParameters: RecalculateShipmentStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recalculateShipmentStatsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async recentManualQuotesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyShipment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/recent-manual-quotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyShipmentFromJSON));
    }

    /**
     */
    async recentManualQuotes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyShipment>> {
        const response = await this.recentManualQuotesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async regenerateBillOfLadingForShipmentRaw(requestParameters: RegenerateBillOfLadingForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling regenerateBillOfLadingForShipment().'
            );
        }

        if (requestParameters['sendNotificationEmails'] == null) {
            throw new runtime.RequiredError(
                'sendNotificationEmails',
                'Required parameter "sendNotificationEmails" was null or undefined when calling regenerateBillOfLadingForShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['sendNotificationEmails'] != null) {
            queryParameters['sendNotificationEmails'] = requestParameters['sendNotificationEmails'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/regenerate-bill-of-lading-for-shipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async regenerateBillOfLadingForShipment(requestParameters: RegenerateBillOfLadingForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.regenerateBillOfLadingForShipmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async retryInvoiceApprovalRaw(requestParameters: RetryInvoiceApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling retryInvoiceApproval().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/retry-invoice-approval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async retryInvoiceApproval(requestParameters: RetryInvoiceApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.retryInvoiceApprovalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchShipmentsRaw(requestParameters: SearchShipmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyShipmentQuote>>> {
        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling searchShipments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/search-shipments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyShipmentQuoteFromJSON));
    }

    /**
     */
    async searchShipments(requestParameters: SearchShipmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyShipmentQuote>> {
        const response = await this.searchShipmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAppointmentDateRaw(requestParameters: SetAppointmentDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling setAppointmentDate().'
            );
        }

        if (requestParameters['appointmentDate'] == null) {
            throw new runtime.RequiredError(
                'appointmentDate',
                'Required parameter "appointmentDate" was null or undefined when calling setAppointmentDate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['appointmentDate'] != null) {
            queryParameters['appointmentDate'] = requestParameters['appointmentDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/set-appointment-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setAppointmentDate(requestParameters: SetAppointmentDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setAppointmentDateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setInvoiceAuditAssigneeRaw(requestParameters: SetInvoiceAuditAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling setInvoiceAuditAssignee().'
            );
        }

        if (requestParameters['invoiceAuditAssignee'] == null) {
            throw new runtime.RequiredError(
                'invoiceAuditAssignee',
                'Required parameter "invoiceAuditAssignee" was null or undefined when calling setInvoiceAuditAssignee().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['invoiceAuditAssignee'] != null) {
            queryParameters['invoiceAuditAssignee'] = requestParameters['invoiceAuditAssignee'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/set-invoice-audit-assignee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setInvoiceAuditAssignee(requestParameters: SetInvoiceAuditAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setInvoiceAuditAssigneeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setInvoiceAuditCarrierFrontConversationIdRaw(requestParameters: SetInvoiceAuditCarrierFrontConversationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling setInvoiceAuditCarrierFrontConversationId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['frontConversationId'] != null) {
            queryParameters['frontConversationId'] = requestParameters['frontConversationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/set-invoice-audit-carrier-front-conversation-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setInvoiceAuditCarrierFrontConversationId(requestParameters: SetInvoiceAuditCarrierFrontConversationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setInvoiceAuditCarrierFrontConversationIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setInvoiceAuditCarrierTurnRaw(requestParameters: SetInvoiceAuditCarrierTurnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling setInvoiceAuditCarrierTurn().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['turn'] != null) {
            queryParameters['turn'] = requestParameters['turn'];
        }

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/set-invoice-audit-carrier-turn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setInvoiceAuditCarrierTurn(requestParameters: SetInvoiceAuditCarrierTurnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setInvoiceAuditCarrierTurnRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setInvoiceAuditCustomerFrontConversationIdRaw(requestParameters: SetInvoiceAuditCustomerFrontConversationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling setInvoiceAuditCustomerFrontConversationId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['frontConversationId'] != null) {
            queryParameters['frontConversationId'] = requestParameters['frontConversationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/set-invoice-audit-customer-front-conversation-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setInvoiceAuditCustomerFrontConversationId(requestParameters: SetInvoiceAuditCustomerFrontConversationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setInvoiceAuditCustomerFrontConversationIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setInvoiceAuditCustomerTurnRaw(requestParameters: SetInvoiceAuditCustomerTurnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling setInvoiceAuditCustomerTurn().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['turn'] != null) {
            queryParameters['turn'] = requestParameters['turn'];
        }

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/set-invoice-audit-customer-turn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setInvoiceAuditCustomerTurn(requestParameters: SetInvoiceAuditCustomerTurnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setInvoiceAuditCustomerTurnRaw(requestParameters, initOverrides);
    }

    /**
     */
    async snoozeInvoiceAuditUntilRaw(requestParameters: SnoozeInvoiceAuditUntilRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling snoozeInvoiceAuditUntil().'
            );
        }

        if (requestParameters['note'] == null) {
            throw new runtime.RequiredError(
                'note',
                'Required parameter "note" was null or undefined when calling snoozeInvoiceAuditUntil().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['snoozeInvoiceAuditUntilDate'] != null) {
            queryParameters['snoozeInvoiceAuditUntilDate'] = requestParameters['snoozeInvoiceAuditUntilDate'];
        }

        if (requestParameters['note'] != null) {
            queryParameters['note'] = requestParameters['note'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/snooze-invoice-audit-until`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async snoozeInvoiceAuditUntil(requestParameters: SnoozeInvoiceAuditUntilRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.snoozeInvoiceAuditUntilRaw(requestParameters, initOverrides);
    }

    /**
     */
    async toggleDeletedRaw(requestParameters: ToggleDeletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling toggleDeleted().'
            );
        }

        if (requestParameters['deleted'] == null) {
            throw new runtime.RequiredError(
                'deleted',
                'Required parameter "deleted" was null or undefined when calling toggleDeleted().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['deleted'] != null) {
            queryParameters['deleted'] = requestParameters['deleted'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/toggle-deleted`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async toggleDeleted(requestParameters: ToggleDeletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleDeletedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async toggleInvoiceAuditOpenRaw(requestParameters: ToggleInvoiceAuditOpenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling toggleInvoiceAuditOpen().'
            );
        }

        if (requestParameters['invoiceAuditOpen'] == null) {
            throw new runtime.RequiredError(
                'invoiceAuditOpen',
                'Required parameter "invoiceAuditOpen" was null or undefined when calling toggleInvoiceAuditOpen().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['invoiceAuditOpen'] != null) {
            queryParameters['invoiceAuditOpen'] = requestParameters['invoiceAuditOpen'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/toggle-invoice-audit-open`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async toggleInvoiceAuditOpen(requestParameters: ToggleInvoiceAuditOpenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleInvoiceAuditOpenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async unpauseAmyEmailsRaw(requestParameters: UnpauseAmyEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling unpauseAmyEmails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/unpause-amy-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unpauseAmyEmails(requestParameters: UnpauseAmyEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unpauseAmyEmailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async unsetAppointmentDateRaw(requestParameters: UnsetAppointmentDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling unsetAppointmentDate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/unset-appointment-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unsetAppointmentDate(requestParameters: UnsetAppointmentDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsetAppointmentDateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBookerRaw(requestParameters: UpdateBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateBooker().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling updateBooker().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling updateBooker().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling updateBooker().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/update-booker`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateBooker(requestParameters: UpdateBookerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateBookerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateCustomsBrokerRaw(requestParameters: UpdateCustomsBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateCustomsBroker().'
            );
        }

        if (requestParameters['broker'] == null) {
            throw new runtime.RequiredError(
                'broker',
                'Required parameter "broker" was null or undefined when calling updateCustomsBroker().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/shipments/update-customs-broker`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BrokerToJSON(requestParameters['broker']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCustomsBroker(requestParameters: UpdateCustomsBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomsBrokerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateEmergencyContactRaw(requestParameters: UpdateEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateEmergencyContact().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling updateEmergencyContact().'
            );
        }

        if (requestParameters['contactName'] == null) {
            throw new runtime.RequiredError(
                'contactName',
                'Required parameter "contactName" was null or undefined when calling updateEmergencyContact().'
            );
        }

        if (requestParameters['phoneNumber'] == null) {
            throw new runtime.RequiredError(
                'phoneNumber',
                'Required parameter "phoneNumber" was null or undefined when calling updateEmergencyContact().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['contactName'] != null) {
            queryParameters['contactName'] = requestParameters['contactName'];
        }

        if (requestParameters['phoneNumber'] != null) {
            queryParameters['phoneNumber'] = requestParameters['phoneNumber'];
        }

        if (requestParameters['phoneNumberExtension'] != null) {
            queryParameters['phoneNumberExtension'] = requestParameters['phoneNumberExtension'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/update-emergency-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateEmergencyContact(requestParameters: UpdateEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateEmergencyContactRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateGeneralInfoRaw(requestParameters: UpdateGeneralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateGeneralInfo().'
            );
        }

        if (requestParameters['exclusiveUseNeeded'] == null) {
            throw new runtime.RequiredError(
                'exclusiveUseNeeded',
                'Required parameter "exclusiveUseNeeded" was null or undefined when calling updateGeneralInfo().'
            );
        }

        if (requestParameters['tarpRequired'] == null) {
            throw new runtime.RequiredError(
                'tarpRequired',
                'Required parameter "tarpRequired" was null or undefined when calling updateGeneralInfo().'
            );
        }

        if (requestParameters['linearFeet'] == null) {
            throw new runtime.RequiredError(
                'linearFeet',
                'Required parameter "linearFeet" was null or undefined when calling updateGeneralInfo().'
            );
        }

        if (requestParameters['addInsuranceToShipment'] == null) {
            throw new runtime.RequiredError(
                'addInsuranceToShipment',
                'Required parameter "addInsuranceToShipment" was null or undefined when calling updateGeneralInfo().'
            );
        }

        if (requestParameters['insuranceAmount'] == null) {
            throw new runtime.RequiredError(
                'insuranceAmount',
                'Required parameter "insuranceAmount" was null or undefined when calling updateGeneralInfo().'
            );
        }

        if (requestParameters['insuranceCurrency'] == null) {
            throw new runtime.RequiredError(
                'insuranceCurrency',
                'Required parameter "insuranceCurrency" was null or undefined when calling updateGeneralInfo().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['equipmentType'] != null) {
            queryParameters['equipmentType'] = requestParameters['equipmentType'];
        }

        if (requestParameters['exclusiveUseNeeded'] != null) {
            queryParameters['exclusiveUseNeeded'] = requestParameters['exclusiveUseNeeded'];
        }

        if (requestParameters['tarpRequired'] != null) {
            queryParameters['tarpRequired'] = requestParameters['tarpRequired'];
        }

        if (requestParameters['linearFeet'] != null) {
            queryParameters['linearFeet'] = requestParameters['linearFeet'];
        }

        if (requestParameters['addInsuranceToShipment'] != null) {
            queryParameters['addInsuranceToShipment'] = requestParameters['addInsuranceToShipment'];
        }

        if (requestParameters['insuranceAmount'] != null) {
            queryParameters['insuranceAmount'] = requestParameters['insuranceAmount'];
        }

        if (requestParameters['insuranceCurrency'] != null) {
            queryParameters['insuranceCurrency'] = requestParameters['insuranceCurrency'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/update-general-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateGeneralInfo(requestParameters: UpdateGeneralInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGeneralInfoRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateInterlineInformationRaw(requestParameters: UpdateInterlineInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateInterlineInformation().'
            );
        }

        if (requestParameters['interlineProNumber'] == null) {
            throw new runtime.RequiredError(
                'interlineProNumber',
                'Required parameter "interlineProNumber" was null or undefined when calling updateInterlineInformation().'
            );
        }

        if (requestParameters['interlineCarrierPickupNumber'] == null) {
            throw new runtime.RequiredError(
                'interlineCarrierPickupNumber',
                'Required parameter "interlineCarrierPickupNumber" was null or undefined when calling updateInterlineInformation().'
            );
        }

        if (requestParameters['interlineCarrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'interlineCarrierIdentifier',
                'Required parameter "interlineCarrierIdentifier" was null or undefined when calling updateInterlineInformation().'
            );
        }

        if (requestParameters['interlineCarrierDisplayName'] == null) {
            throw new runtime.RequiredError(
                'interlineCarrierDisplayName',
                'Required parameter "interlineCarrierDisplayName" was null or undefined when calling updateInterlineInformation().'
            );
        }

        if (requestParameters['interlineContext'] == null) {
            throw new runtime.RequiredError(
                'interlineContext',
                'Required parameter "interlineContext" was null or undefined when calling updateInterlineInformation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['interlineProNumber'] != null) {
            queryParameters['interlineProNumber'] = requestParameters['interlineProNumber'];
        }

        if (requestParameters['interlineCarrierPickupNumber'] != null) {
            queryParameters['interlineCarrierPickupNumber'] = requestParameters['interlineCarrierPickupNumber'];
        }

        if (requestParameters['interlineCarrierIdentifier'] != null) {
            queryParameters['interlineCarrierIdentifier'] = requestParameters['interlineCarrierIdentifier'];
        }

        if (requestParameters['interlineCarrierDisplayName'] != null) {
            queryParameters['interlineCarrierDisplayName'] = requestParameters['interlineCarrierDisplayName'];
        }

        if (requestParameters['interlineContext'] != null) {
            queryParameters['interlineContext'] = requestParameters['interlineContext'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/update-interline-information`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateInterlineInformation(requestParameters: UpdateInterlineInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInterlineInformationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateInvoiceAuditProblemsRaw(requestParameters: UpdateInvoiceAuditProblemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateInvoiceAuditProblems().'
            );
        }

        if (requestParameters['invoiceAuditProblem'] == null) {
            throw new runtime.RequiredError(
                'invoiceAuditProblem',
                'Required parameter "invoiceAuditProblem" was null or undefined when calling updateInvoiceAuditProblems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/shipments/update-invoice-audit-problems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['invoiceAuditProblem']!.map(InvoiceAuditProblemToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateInvoiceAuditProblems(requestParameters: UpdateInvoiceAuditProblemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInvoiceAuditProblemsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLineItemsRaw(requestParameters: UpdateLineItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateLineItems().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling updateLineItems().'
            );
        }

        if (requestParameters['lineItem'] == null) {
            throw new runtime.RequiredError(
                'lineItem',
                'Required parameter "lineItem" was null or undefined when calling updateLineItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/shipments/update-line-items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['lineItem']!.map(LineItemToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLineItems(requestParameters: UpdateLineItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLineItemsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateLocation().'
            );
        }

        if (requestParameters['updateLocationBody'] == null) {
            throw new runtime.RequiredError(
                'updateLocationBody',
                'Required parameter "updateLocationBody" was null or undefined when calling updateLocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/shipments/update-location`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLocationBodyToJSON(requestParameters['updateLocationBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLocation(requestParameters: UpdateLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLocationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateQuoterRaw(requestParameters: UpdateQuoterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling updateQuoter().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling updateQuoter().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling updateQuoter().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling updateQuoter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/update-quoter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateQuoter(requestParameters: UpdateQuoterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateQuoterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async uploadDocumentRaw(requestParameters: UploadDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling uploadDocument().'
            );
        }

        if (requestParameters['documentType'] == null) {
            throw new runtime.RequiredError(
                'documentType',
                'Required parameter "documentType" was null or undefined when calling uploadDocument().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadDocument().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['documentType'] != null) {
            queryParameters['documentType'] = requestParameters['documentType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/pdf';

        const response = await this.request({
            path: `/apollo/shipments/upload-document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadDocument(requestParameters: UploadDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async viewCarrierPickupNumberHistoryRaw(requestParameters: ViewCarrierPickupNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierPickupNumberHistory>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling viewCarrierPickupNumberHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/view-carrier-pickup-number-history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierPickupNumberHistoryFromJSON));
    }

    /**
     */
    async viewCarrierPickupNumberHistory(requestParameters: ViewCarrierPickupNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierPickupNumberHistory>> {
        const response = await this.viewCarrierPickupNumberHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async viewProNumberHistoryRaw(requestParameters: ViewProNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProNumberHistory>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling viewProNumberHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/shipments/view-pro-number-history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProNumberHistoryFromJSON));
    }

    /**
     */
    async viewProNumberHistory(requestParameters: ViewProNumberHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProNumberHistory>> {
        const response = await this.viewProNumberHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
