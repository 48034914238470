/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Hours } from './Hours';
import {
    HoursFromJSON,
    HoursFromJSONTyped,
    HoursToJSON,
} from './Hours';
import type { LatitudeLongitude } from './LatitudeLongitude';
import {
    LatitudeLongitudeFromJSON,
    LatitudeLongitudeFromJSONTyped,
    LatitudeLongitudeToJSON,
} from './LatitudeLongitude';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    locationType?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    businessName?: string;
    /**
     * 
     * @type {Address}
     * @memberof Location
     */
    address?: Address;
    /**
     * 
     * @type {Hours}
     * @memberof Location
     */
    hours?: Hours;
    /**
     * 
     * @type {LatitudeLongitude}
     * @memberof Location
     */
    latitudeLongitude?: LatitudeLongitude;
    /**
     * 
     * @type {Array<string>}
     * @memberof Location
     */
    accessorials?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    notes?: string;
}

/**
 * Check if a given object implements the Location interface.
 */
export function instanceOfLocation(value: object): boolean {
    return true;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if (json == null) {
        return json;
    }
    return {
        
        'locationType': json['locationType'] == null ? undefined : json['locationType'],
        'businessName': json['businessName'] == null ? undefined : json['businessName'],
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
        'hours': json['hours'] == null ? undefined : HoursFromJSON(json['hours']),
        'latitudeLongitude': json['latitudeLongitude'] == null ? undefined : LatitudeLongitudeFromJSON(json['latitudeLongitude']),
        'accessorials': json['accessorials'] == null ? undefined : json['accessorials'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationType': value['locationType'],
        'businessName': value['businessName'],
        'address': AddressToJSON(value['address']),
        'hours': HoursToJSON(value['hours']),
        'latitudeLongitude': LatitudeLongitudeToJSON(value['latitudeLongitude']),
        'accessorials': value['accessorials'],
        'notes': value['notes'],
    };
}

