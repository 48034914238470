import { createContext, ReactNode } from "react";
import { useRefreshFunction } from "../../Hooks/useRefreshFunction";
import { initRefreshContext, RefreshContextProps } from "../RefreshContext";

export const CallRefreshContext = createContext<RefreshContextProps>({
  ...initRefreshContext,
});

export function CallRefreshContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { isRefreshing, setRefresh, refresh } = useRefreshFunction();

  return (
    <CallRefreshContext.Provider value={{ isRefreshing, setRefresh, refresh }}>
      {children}
    </CallRefreshContext.Provider>
  );
}
