/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BookShipmentStrategy } from './BookShipmentStrategy';
import {
    BookShipmentStrategyFromJSON,
    BookShipmentStrategyFromJSONTyped,
    BookShipmentStrategyToJSON,
} from './BookShipmentStrategy';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { QuotingStrategy } from './QuotingStrategy';
import {
    QuotingStrategyFromJSON,
    QuotingStrategyFromJSONTyped,
    QuotingStrategyToJSON,
} from './QuotingStrategy';
import type { ServiceLevel } from './ServiceLevel';
import {
    ServiceLevelFromJSON,
    ServiceLevelFromJSONTyped,
    ServiceLevelToJSON,
} from './ServiceLevel';
import type { TemperatureHandling } from './TemperatureHandling';
import {
    TemperatureHandlingFromJSON,
    TemperatureHandlingFromJSONTyped,
    TemperatureHandlingToJSON,
} from './TemperatureHandling';

/**
 * 
 * @export
 * @interface ServiceProperties
 */
export interface ServiceProperties {
    /**
     * 
     * @type {ServiceLevel}
     * @memberof ServiceProperties
     */
    serviceLevel: ServiceLevel;
    /**
     * 
     * @type {QuotingStrategy}
     * @memberof ServiceProperties
     */
    quotingStrategy: QuotingStrategy;
    /**
     * 
     * @type {object}
     * @memberof ServiceProperties
     */
    serviceArea: object;
    /**
     * 
     * @type {BookShipmentStrategy}
     * @memberof ServiceProperties
     */
    bookShipmentStrategy: BookShipmentStrategy;
    /**
     * 
     * @type {Array<TemperatureHandling>}
     * @memberof ServiceProperties
     */
    supportedTemperatureHandling: Array<TemperatureHandling>;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof ServiceProperties
     */
    supportedEquipmentTypes: Array<EquipmentType>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    supportsExclusiveUse: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    hasStagingEnvironment: boolean;
    /**
     * 
     * @type {object}
     * @memberof ServiceProperties
     */
    handlingUnitLimits: object;
    /**
     * 
     * @type {object}
     * @memberof ServiceProperties
     */
    overallLimits: object;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    supportsDangerousGoods: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    supportsTradeShows: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    supportsUNFI: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    allowsSameDayTransfers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    chargesTaxesOnInvoices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    chargesTaxesOnQuotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProperties
     */
    flagged: boolean;
}

/**
 * Check if a given object implements the ServiceProperties interface.
 */
export function instanceOfServiceProperties(value: object): boolean {
    if (!('serviceLevel' in value)) return false;
    if (!('quotingStrategy' in value)) return false;
    if (!('serviceArea' in value)) return false;
    if (!('bookShipmentStrategy' in value)) return false;
    if (!('supportedTemperatureHandling' in value)) return false;
    if (!('supportedEquipmentTypes' in value)) return false;
    if (!('supportsExclusiveUse' in value)) return false;
    if (!('hasStagingEnvironment' in value)) return false;
    if (!('handlingUnitLimits' in value)) return false;
    if (!('overallLimits' in value)) return false;
    if (!('supportsDangerousGoods' in value)) return false;
    if (!('supportsTradeShows' in value)) return false;
    if (!('supportsUNFI' in value)) return false;
    if (!('allowsSameDayTransfers' in value)) return false;
    if (!('flagged' in value)) return false;
    return true;
}

export function ServicePropertiesFromJSON(json: any): ServiceProperties {
    return ServicePropertiesFromJSONTyped(json, false);
}

export function ServicePropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'serviceLevel': ServiceLevelFromJSON(json['serviceLevel']),
        'quotingStrategy': QuotingStrategyFromJSON(json['quotingStrategy']),
        'serviceArea': json['serviceArea'],
        'bookShipmentStrategy': BookShipmentStrategyFromJSON(json['bookShipmentStrategy']),
        'supportedTemperatureHandling': ((json['supportedTemperatureHandling'] as Array<any>).map(TemperatureHandlingFromJSON)),
        'supportedEquipmentTypes': ((json['supportedEquipmentTypes'] as Array<any>).map(EquipmentTypeFromJSON)),
        'supportsExclusiveUse': json['supportsExclusiveUse'],
        'hasStagingEnvironment': json['hasStagingEnvironment'],
        'handlingUnitLimits': json['handlingUnitLimits'],
        'overallLimits': json['overallLimits'],
        'supportsDangerousGoods': json['supportsDangerousGoods'],
        'supportsTradeShows': json['supportsTradeShows'],
        'supportsUNFI': json['supportsUNFI'],
        'allowsSameDayTransfers': json['allowsSameDayTransfers'],
        'chargesTaxesOnInvoices': json['chargesTaxesOnInvoices'] == null ? undefined : json['chargesTaxesOnInvoices'],
        'chargesTaxesOnQuotes': json['chargesTaxesOnQuotes'] == null ? undefined : json['chargesTaxesOnQuotes'],
        'flagged': json['flagged'],
    };
}

export function ServicePropertiesToJSON(value?: ServiceProperties | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'serviceLevel': ServiceLevelToJSON(value['serviceLevel']),
        'quotingStrategy': QuotingStrategyToJSON(value['quotingStrategy']),
        'serviceArea': value['serviceArea'],
        'bookShipmentStrategy': BookShipmentStrategyToJSON(value['bookShipmentStrategy']),
        'supportedTemperatureHandling': ((value['supportedTemperatureHandling'] as Array<any>).map(TemperatureHandlingToJSON)),
        'supportedEquipmentTypes': ((value['supportedEquipmentTypes'] as Array<any>).map(EquipmentTypeToJSON)),
        'supportsExclusiveUse': value['supportsExclusiveUse'],
        'hasStagingEnvironment': value['hasStagingEnvironment'],
        'handlingUnitLimits': value['handlingUnitLimits'],
        'overallLimits': value['overallLimits'],
        'supportsDangerousGoods': value['supportsDangerousGoods'],
        'supportsTradeShows': value['supportsTradeShows'],
        'supportsUNFI': value['supportsUNFI'],
        'allowsSameDayTransfers': value['allowsSameDayTransfers'],
        'chargesTaxesOnInvoices': value['chargesTaxesOnInvoices'],
        'chargesTaxesOnQuotes': value['chargesTaxesOnQuotes'],
        'flagged': value['flagged'],
    };
}

