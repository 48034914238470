/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailQuoteRequestAndResponses } from './EmailQuoteRequestAndResponses';
import {
    EmailQuoteRequestAndResponsesFromJSON,
    EmailQuoteRequestAndResponsesFromJSONTyped,
    EmailQuoteRequestAndResponsesToJSON,
} from './EmailQuoteRequestAndResponses';
import type { Quote } from './Quote';
import {
    QuoteFromJSON,
    QuoteFromJSONTyped,
    QuoteToJSON,
} from './Quote';

/**
 * 
 * @export
 * @interface ManageQuotesTabData
 */
export interface ManageQuotesTabData {
    /**
     * 
     * @type {{ [key: string]: Array<Quote>; }}
     * @memberof ManageQuotesTabData
     */
    quotes: { [key: string]: Array<Quote>; };
    /**
     * 
     * @type {Quote}
     * @memberof ManageQuotesTabData
     */
    cheapestQuote?: Quote;
    /**
     * 
     * @type {Array<EmailQuoteRequestAndResponses>}
     * @memberof ManageQuotesTabData
     */
    emailQuoteRequests: Array<EmailQuoteRequestAndResponses>;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuotesTabData
     */
    manualQuoting: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManageQuotesTabData
     */
    manualQuotingOpen: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManageQuotesTabData
     */
    manualQuotingNotes: string;
}

/**
 * Check if a given object implements the ManageQuotesTabData interface.
 */
export function instanceOfManageQuotesTabData(value: object): boolean {
    if (!('quotes' in value)) return false;
    if (!('emailQuoteRequests' in value)) return false;
    if (!('manualQuoting' in value)) return false;
    if (!('manualQuotingOpen' in value)) return false;
    if (!('manualQuotingNotes' in value)) return false;
    return true;
}

export function ManageQuotesTabDataFromJSON(json: any): ManageQuotesTabData {
    return ManageQuotesTabDataFromJSONTyped(json, false);
}

export function ManageQuotesTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageQuotesTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'quotes': json['quotes'],
        'cheapestQuote': json['cheapestQuote'] == null ? undefined : QuoteFromJSON(json['cheapestQuote']),
        'emailQuoteRequests': ((json['emailQuoteRequests'] as Array<any>).map(EmailQuoteRequestAndResponsesFromJSON)),
        'manualQuoting': json['manualQuoting'],
        'manualQuotingOpen': json['manualQuotingOpen'],
        'manualQuotingNotes': json['manualQuotingNotes'],
    };
}

export function ManageQuotesTabDataToJSON(value?: ManageQuotesTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quotes': value['quotes'],
        'cheapestQuote': QuoteToJSON(value['cheapestQuote']),
        'emailQuoteRequests': ((value['emailQuoteRequests'] as Array<any>).map(EmailQuoteRequestAndResponsesToJSON)),
        'manualQuoting': value['manualQuoting'],
        'manualQuotingOpen': value['manualQuotingOpen'],
        'manualQuotingNotes': value['manualQuotingNotes'],
    };
}

