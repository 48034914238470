import { Button, Modal } from "antd";
import { ButtonType } from "antd/lib/button";
import { useState } from "react";

interface ViewHtmlButtonProps {
  data: string;
  title: string;
  type?: ButtonType;
}

export function ViewHtmlButton(props: ViewHtmlButtonProps) {
  const { title, data } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </Modal>
      <Button type={props.type ?? "default"} onClick={showModal}>
        {title}
      </Button>
    </>
  );
}
