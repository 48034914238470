/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierCreditNote } from './CarrierCreditNote';
import {
    CarrierCreditNoteFromJSON,
    CarrierCreditNoteFromJSONTyped,
    CarrierCreditNoteToJSON,
} from './CarrierCreditNote';
import type { CarrierInvoice } from './CarrierInvoice';
import {
    CarrierInvoiceFromJSON,
    CarrierInvoiceFromJSONTyped,
    CarrierInvoiceToJSON,
} from './CarrierInvoice';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';
import type { CustomerInvoice } from './CustomerInvoice';
import {
    CustomerInvoiceFromJSON,
    CustomerInvoiceFromJSONTyped,
    CustomerInvoiceToJSON,
} from './CustomerInvoice';
import type { Note } from './Note';
import {
    NoteFromJSON,
    NoteFromJSONTyped,
    NoteToJSON,
} from './Note';
import type { ShipmentReport } from './ShipmentReport';
import {
    ShipmentReportFromJSON,
    ShipmentReportFromJSONTyped,
    ShipmentReportToJSON,
} from './ShipmentReport';

/**
 * 
 * @export
 * @interface OpenAuditLine
 */
export interface OpenAuditLine {
    /**
     * 
     * @type {CompanyShipmentQuote}
     * @memberof OpenAuditLine
     */
    csq: CompanyShipmentQuote;
    /**
     * 
     * @type {string}
     * @memberof OpenAuditLine
     */
    diffCad: string;
    /**
     * 
     * @type {ShipmentReport}
     * @memberof OpenAuditLine
     */
    shipmentReport: ShipmentReport;
    /**
     * 
     * @type {Array<CustomerInvoice>}
     * @memberof OpenAuditLine
     */
    customerInvoices: Array<CustomerInvoice>;
    /**
     * 
     * @type {Array<CarrierInvoice>}
     * @memberof OpenAuditLine
     */
    carrierInvoices: Array<CarrierInvoice>;
    /**
     * 
     * @type {Array<CarrierCreditNote>}
     * @memberof OpenAuditLine
     */
    carrierCreditNotes: Array<CarrierCreditNote>;
    /**
     * 
     * @type {Array<Note>}
     * @memberof OpenAuditLine
     */
    notes: Array<Note>;
}

/**
 * Check if a given object implements the OpenAuditLine interface.
 */
export function instanceOfOpenAuditLine(value: object): boolean {
    if (!('csq' in value)) return false;
    if (!('diffCad' in value)) return false;
    if (!('shipmentReport' in value)) return false;
    if (!('customerInvoices' in value)) return false;
    if (!('carrierInvoices' in value)) return false;
    if (!('carrierCreditNotes' in value)) return false;
    if (!('notes' in value)) return false;
    return true;
}

export function OpenAuditLineFromJSON(json: any): OpenAuditLine {
    return OpenAuditLineFromJSONTyped(json, false);
}

export function OpenAuditLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenAuditLine {
    if (json == null) {
        return json;
    }
    return {
        
        'csq': CompanyShipmentQuoteFromJSON(json['csq']),
        'diffCad': json['diffCad'],
        'shipmentReport': ShipmentReportFromJSON(json['shipmentReport']),
        'customerInvoices': ((json['customerInvoices'] as Array<any>).map(CustomerInvoiceFromJSON)),
        'carrierInvoices': ((json['carrierInvoices'] as Array<any>).map(CarrierInvoiceFromJSON)),
        'carrierCreditNotes': ((json['carrierCreditNotes'] as Array<any>).map(CarrierCreditNoteFromJSON)),
        'notes': ((json['notes'] as Array<any>).map(NoteFromJSON)),
    };
}

export function OpenAuditLineToJSON(value?: OpenAuditLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'csq': CompanyShipmentQuoteToJSON(value['csq']),
        'diffCad': value['diffCad'],
        'shipmentReport': ShipmentReportToJSON(value['shipmentReport']),
        'customerInvoices': ((value['customerInvoices'] as Array<any>).map(CustomerInvoiceToJSON)),
        'carrierInvoices': ((value['carrierInvoices'] as Array<any>).map(CarrierInvoiceToJSON)),
        'carrierCreditNotes': ((value['carrierCreditNotes'] as Array<any>).map(CarrierCreditNoteToJSON)),
        'notes': ((value['notes'] as Array<any>).map(NoteToJSON)),
    };
}

