import { ListCompaniesRow } from "../../../generated-openapi-client";
import { isEmpty, isNotEmpty } from "../../../Helpers/isEmpty";
import { stringListsCommonElements } from "../../../Helpers/stringListCommonElements";

export function applyDuplicateDomains(
  companies: ListCompaniesRow[],
  pivotCompany: ListCompaniesRow
): string[] {
  if (isEmpty(pivotCompany.associatedProfessionalDomain)) {
    return [];
  }

  // List of domains of every company (array of arrays)
  // [ ["a.com", "b.com"], ["c.com", "d.com"] ]
  const allCompaniesDomains: string[][] = [];

  companies.forEach((company) => {
    // If the pivotCompany is inside the companies array don't include his domains
    if (company.companyId === pivotCompany.companyId) return;

    if (isNotEmpty(company.associatedProfessionalDomain)) {
      allCompaniesDomains.push(company.associatedProfessionalDomain!);
    }
  });

  // Flats the list
  // FROM (array of arrays): [ ["a.com", "b.com"], ["c.com", "d.com"] ]
  // TO (simple array):      [ "a.com", "b.com", "c.com", "d.com" ]
  const flatedCompaniesDomaines: string[] = allCompaniesDomains.flat();

  // Searches for common elements between flatedCompaniesDomains
  // and pivotCopmany.associatedProfessionalDomain
  return stringListsCommonElements(
    flatedCompaniesDomaines,
    pivotCompany.associatedProfessionalDomain!
  );
}
