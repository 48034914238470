/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface CarrierComparisonReportLine
 */
export interface CarrierComparisonReportLine {
    /**
     * 
     * @type {boolean}
     * @memberof CarrierComparisonReportLine
     */
    booked: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    pickupCity: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    pickupStateProvince: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    pickupPostalCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof CarrierComparisonReportLine
     */
    pickupCountry: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    deliveryCity: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    deliveryStateProvince: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    deliveryPostalCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof CarrierComparisonReportLine
     */
    deliveryCountry: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    totalWeight: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    totalCubicFeet: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    numberHandlingUnits: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    dimensions: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    freightClass: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    nmfc: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    pickupAccessorials: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    deliveryAccessorials: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    carrierRank: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    carrierPrice: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    carrierPriceBehindBy: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    carrierPriceBehindByPercentage: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    bestRate: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    secondBestRate: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    thirdBestRate: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierComparisonReportLine
     */
    billOfLadingNumber: string;
}

/**
 * Check if a given object implements the CarrierComparisonReportLine interface.
 */
export function instanceOfCarrierComparisonReportLine(value: object): boolean {
    if (!('booked' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('pickupCity' in value)) return false;
    if (!('pickupStateProvince' in value)) return false;
    if (!('pickupPostalCode' in value)) return false;
    if (!('pickupCountry' in value)) return false;
    if (!('deliveryCity' in value)) return false;
    if (!('deliveryStateProvince' in value)) return false;
    if (!('deliveryPostalCode' in value)) return false;
    if (!('deliveryCountry' in value)) return false;
    if (!('totalWeight' in value)) return false;
    if (!('totalCubicFeet' in value)) return false;
    if (!('numberHandlingUnits' in value)) return false;
    if (!('dimensions' in value)) return false;
    if (!('freightClass' in value)) return false;
    if (!('nmfc' in value)) return false;
    if (!('pickupAccessorials' in value)) return false;
    if (!('deliveryAccessorials' in value)) return false;
    if (!('carrierRank' in value)) return false;
    if (!('carrierPrice' in value)) return false;
    if (!('carrierPriceBehindBy' in value)) return false;
    if (!('carrierPriceBehindByPercentage' in value)) return false;
    if (!('bestRate' in value)) return false;
    if (!('secondBestRate' in value)) return false;
    if (!('thirdBestRate' in value)) return false;
    if (!('billOfLadingNumber' in value)) return false;
    return true;
}

export function CarrierComparisonReportLineFromJSON(json: any): CarrierComparisonReportLine {
    return CarrierComparisonReportLineFromJSONTyped(json, false);
}

export function CarrierComparisonReportLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierComparisonReportLine {
    if (json == null) {
        return json;
    }
    return {
        
        'booked': json['booked'],
        'pickupDate': json['pickupDate'],
        'pickupCity': json['pickupCity'],
        'pickupStateProvince': json['pickupStateProvince'],
        'pickupPostalCode': json['pickupPostalCode'],
        'pickupCountry': CountryCodeFromJSON(json['pickupCountry']),
        'deliveryCity': json['deliveryCity'],
        'deliveryStateProvince': json['deliveryStateProvince'],
        'deliveryPostalCode': json['deliveryPostalCode'],
        'deliveryCountry': CountryCodeFromJSON(json['deliveryCountry']),
        'totalWeight': json['totalWeight'],
        'totalCubicFeet': json['totalCubicFeet'],
        'numberHandlingUnits': json['numberHandlingUnits'],
        'dimensions': json['dimensions'],
        'freightClass': json['freightClass'],
        'nmfc': json['nmfc'],
        'pickupAccessorials': json['pickupAccessorials'],
        'deliveryAccessorials': json['deliveryAccessorials'],
        'carrierRank': json['carrierRank'],
        'carrierPrice': json['carrierPrice'],
        'carrierPriceBehindBy': json['carrierPriceBehindBy'],
        'carrierPriceBehindByPercentage': json['carrierPriceBehindByPercentage'],
        'bestRate': json['bestRate'],
        'secondBestRate': json['secondBestRate'],
        'thirdBestRate': json['thirdBestRate'],
        'billOfLadingNumber': json['billOfLadingNumber'],
    };
}

export function CarrierComparisonReportLineToJSON(value?: CarrierComparisonReportLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booked': value['booked'],
        'pickupDate': value['pickupDate'],
        'pickupCity': value['pickupCity'],
        'pickupStateProvince': value['pickupStateProvince'],
        'pickupPostalCode': value['pickupPostalCode'],
        'pickupCountry': CountryCodeToJSON(value['pickupCountry']),
        'deliveryCity': value['deliveryCity'],
        'deliveryStateProvince': value['deliveryStateProvince'],
        'deliveryPostalCode': value['deliveryPostalCode'],
        'deliveryCountry': CountryCodeToJSON(value['deliveryCountry']),
        'totalWeight': value['totalWeight'],
        'totalCubicFeet': value['totalCubicFeet'],
        'numberHandlingUnits': value['numberHandlingUnits'],
        'dimensions': value['dimensions'],
        'freightClass': value['freightClass'],
        'nmfc': value['nmfc'],
        'pickupAccessorials': value['pickupAccessorials'],
        'deliveryAccessorials': value['deliveryAccessorials'],
        'carrierRank': value['carrierRank'],
        'carrierPrice': value['carrierPrice'],
        'carrierPriceBehindBy': value['carrierPriceBehindBy'],
        'carrierPriceBehindByPercentage': value['carrierPriceBehindByPercentage'],
        'bestRate': value['bestRate'],
        'secondBestRate': value['secondBestRate'],
        'thirdBestRate': value['thirdBestRate'],
        'billOfLadingNumber': value['billOfLadingNumber'],
    };
}

