/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceChallengeType = {
    ApplyQuote: 'ApplyQuote',
    ApplyRate: 'ApplyRate',
    IncorrectFreightClass: 'IncorrectFreightClass',
    JustifyAdditionalWeight: 'JustifyAdditionalWeight',
    JustifyDimensions: 'JustifyDimensions',
    RemoveDeliveryAppointmentFee: 'RemoveDeliveryAppointmentFee',
    RemoveNotifyPriorToDeliveryFee: 'RemoveNotifyPriorToDeliveryFee',
    RemoveResidentialFee: 'RemoveResidentialFee',
    RemoveLimitedAccessPickupFee: 'RemoveLimitedAccessPickupFee',
    RemoveLimitedAccessDeliveryFee: 'RemoveLimitedAccessDeliveryFee',
    RemovePickupLiftgateFee: 'RemovePickupLiftgateFee',
    RemoveDeliveryLiftgateFee: 'RemoveDeliveryLiftgateFee',
    RemoveInsidePickupFee: 'RemoveInsidePickupFee',
    RemoveInsideDeliveryFee: 'RemoveInsideDeliveryFee',
    RemoveDetentionFee: 'RemoveDetentionFee',
    RemoveBondFee: 'RemoveBondFee',
    RemoveStorageFee: 'RemoveStorageFee',
    RemoveRedeliveryFee: 'RemoveRedeliveryFee',
    ProvidePhotos: 'ProvidePhotos',
    CarrierRequestAudit: 'CarrierRequestAudit',
    Generic: 'Generic'
} as const;
export type InvoiceChallengeType = typeof InvoiceChallengeType[keyof typeof InvoiceChallengeType];


export function instanceOfInvoiceChallengeType(value: any): boolean {
    return Object.values(InvoiceChallengeType).includes(value);
}

export function InvoiceChallengeTypeFromJSON(json: any): InvoiceChallengeType {
    return InvoiceChallengeTypeFromJSONTyped(json, false);
}

export function InvoiceChallengeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceChallengeType {
    return json as InvoiceChallengeType;
}

export function InvoiceChallengeTypeToJSON(value?: InvoiceChallengeType | null): any {
    return value as any;
}

