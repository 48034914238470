/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UpcomingAmyShipmentTasks,
} from '../models/index';
import {
    UpcomingAmyShipmentTasksFromJSON,
    UpcomingAmyShipmentTasksToJSON,
} from '../models/index';

export interface TriggerDeliveryDateUpdateTaskRequest {
    shipmentId: string;
}

export interface TriggerMissedDeliveryTaskRequest {
    shipmentId: string;
}

export interface TriggerMissedPickupTaskRequest {
    shipmentId: string;
}

export interface TriggerTrackingUpdateTaskRequest {
    shipmentId: string;
}

/**
 * 
 */
export class AmyApi extends runtime.BaseAPI {

    /**
     */
    async triggerDeliveryDateUpdateTaskRaw(requestParameters: TriggerDeliveryDateUpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling triggerDeliveryDateUpdateTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/amy/trigger-delivery-date-update-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async triggerDeliveryDateUpdateTask(requestParameters: TriggerDeliveryDateUpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerDeliveryDateUpdateTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async triggerMissedDeliveryTaskRaw(requestParameters: TriggerMissedDeliveryTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling triggerMissedDeliveryTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/amy/trigger-missed-delivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async triggerMissedDeliveryTask(requestParameters: TriggerMissedDeliveryTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerMissedDeliveryTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async triggerMissedPickupTaskRaw(requestParameters: TriggerMissedPickupTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling triggerMissedPickupTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/amy/trigger-missed-pickup-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async triggerMissedPickupTask(requestParameters: TriggerMissedPickupTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerMissedPickupTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async triggerTrackingUpdateTaskRaw(requestParameters: TriggerTrackingUpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling triggerTrackingUpdateTask().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/amy/trigger-tracking-update-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async triggerTrackingUpdateTask(requestParameters: TriggerTrackingUpdateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerTrackingUpdateTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async upcomingAmyShipmentTasksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpcomingAmyShipmentTasks>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/amy/upcoming-amy-shipment-tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpcomingAmyShipmentTasksFromJSON(jsonValue));
    }

    /**
     */
    async upcomingAmyShipmentTasks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpcomingAmyShipmentTasks> {
        const response = await this.upcomingAmyShipmentTasksRaw(initOverrides);
        return await response.value();
    }

}
