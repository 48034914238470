import { Spin } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import { ApolloMapQuery } from "../../generated-openapi-client/models/ApolloMapQuery";
import { MapData } from "../../Hooks/useMapData";
import { MapScreenDataButton } from "./MapScreenDataButton";
import { MapScreenFilterButton } from "./MapScreenFilterButton";
import { MapScreenHistogramsButton } from "./MapScreenHistogramsButton";
import { MapScreenQueryBar } from "./MapScreenQueryBar";

interface MapScreenHeaderProps {
  data: MapData;
  query: ApolloMapQuery;
  setQuery: (_: ApolloMapQuery) => void;
  loading: boolean;
}

export function MapScreenHeader(props: MapScreenHeaderProps) {
  return (
    <div
      style={{
        height: "32px",
        width: "100%",
        padding: "0 0 32px 0",
      }}
    >
      <HorizontalStack align="spread" style={{ width: "100%" }}>
        <HorizontalStack spacing={16} style={{ marginLeft: "-16px" }}>
          <MapScreenFilterButton
            data={props.data}
            query={props.query}
            setQuery={props.setQuery}
          />
          <MapScreenQueryBar query={props.query} />
          {props.loading && <Spin size="small" />}
        </HorizontalStack>
        <HorizontalStack>
          <MapScreenDataButton shipments={props.data.shipments} />
          <MapScreenHistogramsButton shipments={props.data.shipments} />
        </HorizontalStack>
      </HorizontalStack>
    </div>
  );
}
