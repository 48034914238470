/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CompanyNoteType = {
    Call: 'call',
    ApolloAction: 'apollo_action',
    UserAction: 'user_action',
    ShipmentBooking: 'shipment_booking',
    QuoteRun: 'quote_run',
    CreditModified: 'credit_modified',
    SystemEvent: 'system_event',
    DialpadRecording: 'dialpad_recording',
    InternalNote: 'internal_note'
} as const;
export type CompanyNoteType = typeof CompanyNoteType[keyof typeof CompanyNoteType];


export function instanceOfCompanyNoteType(value: any): boolean {
    return Object.values(CompanyNoteType).includes(value);
}

export function CompanyNoteTypeFromJSON(json: any): CompanyNoteType {
    return CompanyNoteTypeFromJSONTyped(json, false);
}

export function CompanyNoteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyNoteType {
    return json as CompanyNoteType;
}

export function CompanyNoteTypeToJSON(value?: CompanyNoteType | null): any {
    return value as any;
}

