/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentsServiceSwitchQuoteInvoiceAdjustmentType = {
    NoAdjustment: 'NoAdjustment',
    AdditionalCharge: 'AdditionalCharge',
    IncreaseIssuedShipmentInvoice: 'IncreaseIssuedShipmentInvoice',
    ReduceOrRefundShipmentInvoice: 'ReduceOrRefundShipmentInvoice'
} as const;
export type PaymentsServiceSwitchQuoteInvoiceAdjustmentType = typeof PaymentsServiceSwitchQuoteInvoiceAdjustmentType[keyof typeof PaymentsServiceSwitchQuoteInvoiceAdjustmentType];


export function instanceOfPaymentsServiceSwitchQuoteInvoiceAdjustmentType(value: any): boolean {
    return Object.values(PaymentsServiceSwitchQuoteInvoiceAdjustmentType).includes(value);
}

export function PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeFromJSON(json: any): PaymentsServiceSwitchQuoteInvoiceAdjustmentType {
    return PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeFromJSONTyped(json, false);
}

export function PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentsServiceSwitchQuoteInvoiceAdjustmentType {
    return json as PaymentsServiceSwitchQuoteInvoiceAdjustmentType;
}

export function PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeToJSON(value?: PaymentsServiceSwitchQuoteInvoiceAdjustmentType | null): any {
    return value as any;
}

