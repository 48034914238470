import { Menu, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { Json } from "../../Components/Json";
import Stack from "../../Components/Stack";
import { FedExAddressValidationInformation } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

export function FedExAddressValidationMenuItem(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [response, setResponse] = useState<FedExAddressValidationInformation>();
  const createShipmentApi = useShipmentsApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  async function refresh() {
    try {
      const shipmentApi = await createShipmentApi();
      const _response = await shipmentApi.getFedExAddressValidationInformation({
        shipmentId,
      });
      setResponse(_response);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  useEffect(
    function () {
      if (isModalVisible) {
        refresh();
      }
    },
    [isModalVisible]
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="FedEx Address Validation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        {response && (
          <HorizontalStack>
            <Stack
              align="left"
              style={{ maxWidth: "800px", overflow: "scroll" }}
            >
              <div>
                Pickup:{" "}
                {response.isPickupResidential
                  ? "Is Residential"
                  : "Is Not Residential"}
              </div>
              <Json data={response.rawPickupInformation} />
            </Stack>
            <Stack
              align="left"
              style={{ maxWidth: "800px", overflow: "scroll" }}
            >
              <div>
                Delivery:{" "}
                {response.isDeliveryResidential
                  ? "Is Residential"
                  : "Is Not Residential"}
              </div>
              <Json data={response.rawDeliveryInformation} />
            </Stack>
          </HorizontalStack>
        )}
      </Modal>
      <Menu.Item onClick={showModal}>FedEx Address Validation</Menu.Item>
    </>
  );
}
