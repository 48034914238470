import { Select } from "antd";
import { useEffect } from "react";
import { groupBy } from "../../Helpers/groupBy";
import { EmailTemplate } from "../../generated-openapi-client";

interface SalesEmailTemplateDropdownProps {
  companyId: string;
  onChangeTemplate: (
    emailIdentifier: string,
    subject: string,
    body: string
  ) => void;
  emailTemplates: EmailTemplate[];
}

export function SalesEmailTemplateDropdown(
  props: SalesEmailTemplateDropdownProps
) {
  const templates = props.emailTemplates;
  const groupedEmailTemplates = groupBy(templates, (t) => t.emailTemplateGroup);

  function onSelectTemplate(identifier: string) {
    const template = templates.find((t) => t.emailTemplateId === identifier);
    if (template === undefined) {
      console.error("Should not be here - missing template");
      throw new Error("Missing template");
    }

    props.onChangeTemplate(
      template.emailTemplateId!!,
      template.subject!!,
      template.body!!
    );
  }

  useEffect(
    function () {
      props.onChangeTemplate("blank", "", "");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.companyId]
  );

  return (
    <Select
      style={{ width: "300px" }}
      onChange={onSelectTemplate}
      defaultValue="blank"
      listHeight={1000}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
    >
      {groupedEmailTemplates.map(function (g) {
        return (
          <Select.OptGroup label={g.key} key={g.key}>
            {g.value.map(function (t) {
              return (
                <Select.Option value={t.emailTemplateId}>
                  {t.title}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        );
      })}
    </Select>
  );
}
