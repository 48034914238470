/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChangePickupDateReason = {
    CustomerRequested: 'CustomerRequested',
    ShipperNotReady: 'ShipperNotReady',
    CarrierMissedPickup: 'CarrierMissedPickup',
    Other: 'Other'
} as const;
export type ChangePickupDateReason = typeof ChangePickupDateReason[keyof typeof ChangePickupDateReason];


export function instanceOfChangePickupDateReason(value: any): boolean {
    return Object.values(ChangePickupDateReason).includes(value);
}

export function ChangePickupDateReasonFromJSON(json: any): ChangePickupDateReason {
    return ChangePickupDateReasonFromJSONTyped(json, false);
}

export function ChangePickupDateReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePickupDateReason {
    return json as ChangePickupDateReason;
}

export function ChangePickupDateReasonToJSON(value?: ChangePickupDateReason | null): any {
    return value as any;
}

