import { Form, Input, InputNumber, Radio, Select, Typography } from "antd";
import Colors from "../../Components/Colors";
import { Density } from "../../Components/Density";
import HorizontalStack from "../../Components/HorizontalStack";
import { Volume } from "../../Components/Volume";
import { Weight } from "../../Components/Weight";
import { LineItem } from "../../generated-openapi-client/models/LineItem";
import {
  densityOfLineItem,
  totalVolumeOfLineItem,
  totalWeightOfLineItem,
} from "../../Helpers/lineItemStats";
import Spacer from "../../Spacer";
import { FreightClassDropdown } from "./FreightClassDropdown";
const { Text } = Typography;

interface EditLineItemProps {
  lineItem: LineItem;
  setLineItem: React.Dispatch<React.SetStateAction<LineItem>>;
}

export function EditLineItem(props: EditLineItemProps) {
  const [form] = Form.useForm();
  const { lineItem, setLineItem } = props;

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const handlingUnitTypes = [
    "Skid",
    "Pallet",
    "Bin",
    "Box",
    "Bundle",
    "Cage",
    "Crate",
    "Drum",
    "Reel",
    "Roll",
    "Other",
  ];

  const temperatureHandlingTypes = [
    "KeepFrozen",
    "KeepRefrigerated",
    "ProtectFromFreezing",
    "NoSpecialHandling",
  ];

  function onChangeHandlingUnitType(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, handlingUnitType: value }));
  }

  function onChangeNumberHandlingUnits(value: number) {
    setLineItem((lineItem) => ({ ...lineItem, numberHandlingUnits: value }));
  }

  function onChangeDescription(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, description: value }));
  }

  function onChangeLength(value: number) {
    setLineItem((lineItem) => ({ ...lineItem, length: value }));
  }

  function onChangeWidth(value: number) {
    setLineItem((lineItem) => ({ ...lineItem, width: value }));
  }

  function onChangeHeight(value: number) {
    setLineItem((lineItem) => ({ ...lineItem, height: value }));
  }

  function onChangeWeightPerHandlingUnit(value: number) {
    setLineItem((lineItem) => ({ ...lineItem, weightPerHandlingUnit: value }));
  }

  function onChangeFreightClass(value: string | undefined) {
    setLineItem((lineItem) => ({ ...lineItem, freightClass: value }));
  }

  function onChangeNmfcItemNumber(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, nmfcItemNumber: value }));
  }

  function onChangeTemperatureHandling(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, temperatureHandling: value }));
  }

  function onChangeStackable(value: boolean) {
    setLineItem((lineItem) => ({ ...lineItem, isStackable: value }));
  }

  function onChangeDangerous(value: boolean) {
    setLineItem((lineItem) => ({ ...lineItem, isDangerous: value }));
  }

  function onChangeDangerousUnNumber(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, dangerousUnNumber: value }));
  }

  function onChangeDangerousClassification(value: string) {
    setLineItem((lineItem) => ({
      ...lineItem,
      dangerousClassification: value,
    }));
  }

  function onChangeDangerousPackingGroup(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, dangerousPackingGroup: value }));
  }

  function onChangeDangerousNumberPackages(value: number) {
    setLineItem((lineItem) => ({
      ...lineItem,
      dangerousNumberPackages: value,
    }));
  }

  function onChangeDangerousPackagingType(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, dangerousPackagingType: value }));
  }

  function onChangeDangerousProperShippingName(value: string) {
    setLineItem((lineItem) => ({
      ...lineItem,
      dangerousProperShippingName: value,
    }));
  }

  function onChangeDangerousTechnicalName(value: string) {
    setLineItem((lineItem) => ({ ...lineItem, dangerousTechnicalName: value }));
  }

  const totalWeight = totalWeightOfLineItem(lineItem);
  const totalVolume = totalVolumeOfLineItem(lineItem);
  const density = densityOfLineItem(lineItem);

  return (
    <>
      <Form {...formItemLayout} layout="horizontal" form={form}>
        <Form.Item label="Handling Unit Types">
          <Select
            value={lineItem.handlingUnitType}
            style={{ width: 120 }}
            onChange={onChangeHandlingUnitType}
          >
            {handlingUnitTypes.map(function (type) {
              return <Select.Option value={type}>{type}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Number Handling Units">
          <InputNumber
            value={lineItem.numberHandlingUnits}
            onChange={onChangeNumberHandlingUnits}
          />
        </Form.Item>

        <Form.Item label="Description">
          <Input
            value={lineItem.description}
            onChange={function (e) {
              onChangeDescription(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label="Length">
          <InputNumber
            value={lineItem.length}
            onChange={onChangeLength}
            addonAfter={`"`}
          />
        </Form.Item>
        <Form.Item label="Width">
          <InputNumber
            value={lineItem.width}
            onChange={onChangeWidth}
            addonAfter={`"`}
          />
        </Form.Item>
        <Form.Item label="Height">
          <InputNumber
            value={lineItem.height}
            onChange={onChangeHeight}
            addonAfter={`"`}
          />
        </Form.Item>
        <Form.Item label="Weight Per Handling Unit">
          <HorizontalStack>
            <InputNumber
              value={lineItem.weightPerHandlingUnit}
              onChange={onChangeWeightPerHandlingUnit}
              addonAfter={`lb`}
            />
            <Spacer width={16} />
            <Text style={{ color: Colors.LightText }}>
              Total Weight: <Weight>{totalWeight!!}</Weight>, Total Volume:{" "}
              <Volume>{totalVolume!!}</Volume>, Density:{" "}
              <Density>{density!!}</Density>
            </Text>
          </HorizontalStack>
        </Form.Item>
        <Form.Item label="Freight Class">
          <FreightClassDropdown
            lineItem={lineItem}
            onChangeFreightClass={onChangeFreightClass}
          />
        </Form.Item>
        <Form.Item label="NMFC Code">
          <Input
            value={lineItem.nmfcItemNumber}
            onChange={function (e) {
              onChangeNmfcItemNumber(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Temperature Handling">
          <Select
            value={lineItem.temperatureHandling}
            style={{ width: 240 }}
            onChange={onChangeTemperatureHandling}
          >
            {temperatureHandlingTypes.map(function (type) {
              return <Select.Option value={type}>{type}</Select.Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Stackable">
          <Radio.Group
            value={lineItem.isStackable}
            onChange={function (e) {
              onChangeStackable(e.target.value);
            }}
          >
            <Radio.Button value={false}>Not Stackable</Radio.Button>
            <Radio.Button value={true}>Stackable</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Dangerous">
          <Radio.Group
            value={lineItem.isDangerous}
            onChange={function (e) {
              onChangeDangerous(e.target.value);
            }}
          >
            <Radio.Button value={false}>Not Dangerous</Radio.Button>
            <Radio.Button value={true}>Dangerous</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {lineItem.isDangerous && (
          <>
            <Form.Item label="UN Number">
              <Input
                value={lineItem.dangerousUnNumber}
                onChange={function (e) {
                  onChangeDangerousUnNumber(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Hazard Class">
              <Input
                value={lineItem.dangerousClassification}
                onChange={function (e) {
                  onChangeDangerousClassification(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Packing Group">
              <Input
                value={lineItem.dangerousPackingGroup}
                onChange={function (e) {
                  onChangeDangerousPackingGroup(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item label="Number Packages">
              <InputNumber
                value={lineItem.dangerousNumberPackages}
                onChange={function (e) {
                  onChangeDangerousNumberPackages(e);
                }}
              />
            </Form.Item>

            <Form.Item label="Packaging Type">
              <Input
                value={lineItem.dangerousPackagingType}
                onChange={function (e) {
                  onChangeDangerousPackagingType(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item label="Proper Shipping Name">
              <Input
                value={lineItem.dangerousProperShippingName}
                onChange={function (e) {
                  onChangeDangerousProperShippingName(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item label="Technical Name">
              <Input
                value={lineItem.dangerousTechnicalName}
                onChange={function (e) {
                  onChangeDangerousTechnicalName(e.target.value);
                }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
}
