import { Calendar, useColorScale } from "@nivo/calendar";
import { Button, Modal, Tag, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { assertNever } from "../../Helpers/assertNever";
import { groupBy } from "../../Helpers/groupBy";
import { maxBy } from "../../Helpers/maxBy";
import { maxOf } from "../../Helpers/maxOf";
import { minBy } from "../../Helpers/minBy";
import { sum } from "../../Helpers/sum";
import { useOnce } from "../../Hooks/useOnce";
import Spacer from "../../Spacer";
import {
  CalendarDataPoint,
  ListCompaniesRow,
  ProfitLossReport,
  ShipmentReport,
} from "../../generated-openapi-client";
import { GenerateProfitLossReportFilterOptions } from "../../generated-openapi-client/models/GenerateProfitLossReportFilterOptions";
import { ProfitLossGroupType } from "./ProfitLossGroupType";

const { Title } = Typography;

export function countYears(d: CalendarDataPoint[]): number {
  const grouped = groupBy(d, function (point) {
    // extract the year from YYYY-MM-DD date
    return point.day.split("-")[0];
  });
  return grouped.length;
}

export function countMonths(d: ShipmentReport[]): number {
  const grouped = groupBy(d, function (point) {
    // extract the year from YYYY-MM-DD date
    return moment(point.bookedAt).startOf("day").format("YYYY-MM");
  });
  return grouped.length;
}

function GroupedView(props: ProfitLossViewCalendarButtonProps) {
  const data = props.report.shipmentLines;
  const groupedData = groupBy(data, function (line) {
    switch (props.groupBy) {
      case ProfitLossGroupType.Carrier:
        return line.carrierIdentifier;
      case ProfitLossGroupType.Company:
        return line.companyName;
      case ProfitLossGroupType.LeadSource:
        return line.companyLeadSource;
      case ProfitLossGroupType.Year:
        return moment(line.bookedAt).format("YYYY");
      case ProfitLossGroupType.Month:
        return moment(line.bookedAt).format("MMMM YYYY");
      case ProfitLossGroupType.Week:
        return moment(line.bookedAt).format("W YYYY");
      case ProfitLossGroupType.DayOfWeek:
        return moment(line.bookedAt).format("dddd");
      case undefined:
        throw new Error("Should not be here");
      default:
        assertNever(props.groupBy);
    }
  }).sort(function (a, b) {
    return (
      sum(b.value, (o) => o.moneyInCad!!) - sum(a.value, (o) => o.moneyInCad!!)
    );
  });

  return (
    <>
      {groupedData.map(function (g) {
        return (
          <>
            <Title level={4}>{g.key}</Title>
            <MoneyInCalendar shipmentLines={g.value} report={props.report} />
          </>
        );
      })}
    </>
  );
}

interface MoneyInCalendarProps {
  shipmentLines: ShipmentReport[];
  report: ProfitLossReport;
}

function MoneyInCalendar(props: MoneyInCalendarProps) {
  const moneyInData = groupBy(props.shipmentLines, function (sl) {
    return moment(sl.bookedAt).startOf("day").format("YYYY-MM-DD");
  }).map(function (g) {
    return {
      day: g.key,
      value: sum(g.value, (o) => o.moneyInCad!!),
    };
  });

  const moneyInMaxValue = maxOf(moneyInData, (o) => o.value);

  const moneyInColorScale = useColorScale({
    data: moneyInData || [],
    minValue: 0,
    maxValue: moneyInMaxValue,
    colors: [
      "#eeedff",
      "#dcdcff",
      "#c9cbff",
      "#b6baff",
      "#a1aaff",
      "#8a9aff",
      "#708aff",
      "#4e7bff",
      "#006cff",
    ],
  });

  function countRevenueByYearAndMonth(d: Date): number {
    const relevant = moneyInData.filter((o) =>
      o.day.startsWith(moment(d).format("YYYY-MM"))
    );
    return sum(relevant, (o) => o.value);
  }

  const fromDate = moment(
    minBy(props.report.shipmentLines, (o) => moment(o.bookedAt).valueOf())
      .bookedAt
  ).format("YYYY-MM-DD");
  const toDate = moment(
    maxBy(props.report.shipmentLines, (o) => moment(o.bookedAt).valueOf())
      .bookedAt
  ).format("YYYY-MM-DD");

  function moneyInMonthlyAverage() {
    const totalMoneyIn = sum(moneyInData, (o) => o.value);
    const numberMonths = countMonths(props.report.shipmentLines);
    return totalMoneyIn / numberMonths;
  }

  function moneyInLast90DayAverage(): number {
    const totalMoneyIn = sum(
      moneyInData.filter(function (o) {
        return moment(o.day).isAfter(moment(toDate).subtract(90, "days"));
      }),
      (o) => o.value
    );

    return (totalMoneyIn / 90) * 30;
  }

  function calculateMoneyInRetention() {
    return (moneyInLast90DayAverage() / moneyInMonthlyAverage()) * 100;
  }

  function moneyInRetentionColor() {
    const r = calculateMoneyInRetention();

    if (r > 100) {
      return "green";
    } else if (r > 85) {
      return "orange";
    } else {
      return "red";
    }
  }

  return (
    <>
      {" "}
      <HorizontalStack
        style={{ marginTop: "-8px", marginLeft: "-8px" }}
        spacing={8}
      >
        <Tag color="purple">
          Monthly Average : ${moneyInMonthlyAverage().toFixed(0)}
        </Tag>

        <Tag color="purple">
          Last 90-day Average : ${moneyInLast90DayAverage().toFixed(0)}
        </Tag>

        <Tag color={moneyInRetentionColor()}>
          Retention : {calculateMoneyInRetention().toFixed(0)}%
        </Tag>
      </HorizontalStack>
      <Spacer height={16} />
      <Calendar
        data={moneyInData}
        // Make sure we have enough height to render all the years of data
        height={countYears(moneyInData) * 250}
        width={1200}
        from={fromDate}
        monthSpacing={10}
        to={moment().format("YYYY-MM-DD")}
        colorScale={moneyInColorScale}
        emptyColor="#fff"
        monthLegend={function (year, month, date) {
          return (
            moment(date).format("MMM") +
            ` : $${countRevenueByYearAndMonth(date).toFixed(0)}`
          );
        }}
        legends={[
          {
            anchor: "top",
            direction: "row",
            itemWidth: 100,
            itemHeight: 30,
            itemCount: 5,
            translateY: -10,
          },
        ]}
      />
    </>
  );
}

function NonGroupedView(props: ProfitLossViewCalendarButtonProps) {
  const shipmentCountData = groupBy(props.report.shipmentLines, function (sl) {
    return moment(sl.bookedAt).startOf("day").format("YYYY-MM-DD");
  }).map(function (g) {
    return {
      day: g.key,
      value: sum(g.value, () => 1),
    };
  });

  const shipmentCountMaxValue = maxOf(shipmentCountData, (o) => o.value);

  const shipmentCountColorScale = useColorScale({
    data: shipmentCountData || [],
    minValue: 0,
    maxValue: shipmentCountMaxValue,
    colors: [
      "#eeedff",
      "#dcdcff",
      "#c9cbff",
      "#b6baff",
      "#a1aaff",
      "#8a9aff",
      "#708aff",
      "#4e7bff",
      "#006cff",
    ],
  });

  function countShipmentsByYearAndMonth(d: Date): number {
    const relevant = shipmentCountData.filter((o) =>
      o.day.startsWith(moment(d).format("YYYY-MM"))
    );
    return sum(relevant, (o) => o.value);
  }

  const fromDate = minBy(shipmentCountData, (o) => moment(o.day).valueOf()).day;
  const toDate = maxBy(shipmentCountData, (o) => moment(o.day).valueOf()).day;

  function shipmentCountMonthlyAverage() {
    const totalShipmentCount = sum(shipmentCountData, (o) => o.value);
    const numberMonths = countMonths(props.report.shipmentLines);
    return totalShipmentCount / numberMonths;
  }

  function shipmentCountLast90DayAverage(): number {
    const totalShipmentCount = sum(
      shipmentCountData.filter(function (o) {
        return moment(o.day).isAfter(moment(toDate).subtract(90, "days"));
      }),
      (o) => o.value
    );

    return (totalShipmentCount / 90) * 30;
  }

  function calculateShipmentCountRetention() {
    return (
      (shipmentCountLast90DayAverage() / shipmentCountMonthlyAverage()) * 100
    );
  }

  function shipmentCountRetentionColor() {
    const r = calculateShipmentCountRetention();

    if (r > 100) {
      return "green";
    } else if (r > 85) {
      return "orange";
    } else {
      return "red";
    }
  }

  return (
    <>
      <Title level={4}>Money In</Title>
      <MoneyInCalendar
        shipmentLines={props.report.shipmentLines}
        report={props.report}
      />

      <Title level={4}>Shipments</Title>
      <HorizontalStack
        style={{ marginTop: "-8px", marginLeft: "-8px" }}
        spacing={8}
      >
        <Tag color="purple">
          Monthly Average : {shipmentCountMonthlyAverage().toFixed(1)}
        </Tag>

        <Tag color="purple">
          Last 90-day Average : {shipmentCountLast90DayAverage().toFixed(0)}
        </Tag>

        <Tag color={shipmentCountRetentionColor()}>
          Retention : {calculateShipmentCountRetention().toFixed(0)}%
        </Tag>
      </HorizontalStack>
      <Spacer height={16} />
      <Calendar
        data={shipmentCountData}
        // Make sure we have enough height to render all the years of data
        height={countYears(shipmentCountData) * 250}
        width={1200}
        from={fromDate}
        monthSpacing={10}
        to={moment().format("YYYY-MM-DD")}
        colorScale={shipmentCountColorScale}
        emptyColor="#fff"
        legends={[
          {
            anchor: "top",
            direction: "row",
            itemWidth: 100,
            itemHeight: 30,
            itemCount: 5,
          },
        ]}
        monthLegend={function (year, month, date) {
          return (
            moment(date).format("MMM") +
            ` : ${countShipmentsByYearAndMonth(date)}`
          );
        }}
      />
    </>
  );
}

interface ProfitLossViewCalendarButtonProps {
  report: ProfitLossReport;
  groupBy: ProfitLossGroupType | undefined;
  filterOptions: GenerateProfitLossReportFilterOptions;
}

function FilterBar(props: ProfitLossViewCalendarButtonProps) {
  const { filterOptions } = props;
  const createCompaniesApi = useCompaniesApi();
  const [companies, setCompanies] = useState<ListCompaniesRow[] | undefined>();

  useOnce(async function () {
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.listAllCompanies();
    setCompanies(response);
  });

  function describeCompany() {
    if (
      companies === undefined ||
      props.filterOptions.companyId === undefined
    ) {
      return undefined;
    }

    return companies.find((c) => c.companyId === props.filterOptions.companyId)
      ?.companyName;
  }

  return (
    <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
      {filterOptions.campaign && (
        <Tag color="green">Campaign: {filterOptions.campaign}</Tag>
      )}
      {filterOptions.carrierIdentifier && (
        <Tag color="red">Carrier: {filterOptions.carrierIdentifier}</Tag>
      )}
      {describeCompany() && (
        <Tag color="purple">Company: {describeCompany()}</Tag>
      )}
      {filterOptions.startDate && (
        <Tag color="blue">Start Date: {filterOptions.startDate}</Tag>
      )}
      {filterOptions.endDate && (
        <Tag color="blue">End Date: {filterOptions.endDate}</Tag>
      )}
      {props.groupBy && <Tag color="orange">Group by: {props.groupBy}</Tag>}
    </HorizontalStack>
  );
}

export function ProfitLossViewCalendarButton(
  props: ProfitLossViewCalendarButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={
          <HorizontalStack>
            <>Calendar View</>
            <Spacer width={16} />
            <FilterBar {...props} />
          </HorizontalStack>
        }
        visible={isModalVisible}
        okText="Done"
        onOk={handleOk}
        onCancel={handleOk}
        width={1450}
        destroyOnClose
      >
        {!props.groupBy && <NonGroupedView {...props} />}
        {props.groupBy && <GroupedView {...props} />}
      </Modal>
      <Button type="link" onClick={showModal}>
        View Calendar
      </Button>
    </>
  );
}
