import { PaperClipOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";

interface CopyMenuItemProps {
  value: string;
  title: string;
  disabled?: boolean;
  hide?: boolean;
}

export function CopyMenuItem(props: CopyMenuItemProps) {
  const onCopy = useCopyToClipboard(props.value);

  if (props.hide) {
    return <></>;
  }

  return (
    <Menu.Item
      onClick={onCopy}
      disabled={props.disabled}
      icon={<PaperClipOutlined />}
    >
      {props.title}
    </Menu.Item>
  );
}
