/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface XeroCreditNote
 */
export interface XeroCreditNote {
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    creditNoteID: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    creditNoteNumber: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof XeroCreditNote
     */
    remainingCredit: number;
    /**
     * 
     * @type {number}
     * @memberof XeroCreditNote
     */
    appliedAmount: number;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    currencyCode: string;
    /**
     * 
     * @type {number}
     * @memberof XeroCreditNote
     */
    currencyRate: number;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCreditNote
     */
    status: string;
}

/**
 * Check if a given object implements the XeroCreditNote interface.
 */
export function instanceOfXeroCreditNote(value: object): boolean {
    if (!('creditNoteID' in value)) return false;
    if (!('creditNoteNumber' in value)) return false;
    if (!('reference' in value)) return false;
    if (!('remainingCredit' in value)) return false;
    if (!('appliedAmount' in value)) return false;
    if (!('currencyCode' in value)) return false;
    if (!('currencyRate' in value)) return false;
    if (!('type' in value)) return false;
    if (!('contactName' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function XeroCreditNoteFromJSON(json: any): XeroCreditNote {
    return XeroCreditNoteFromJSONTyped(json, false);
}

export function XeroCreditNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): XeroCreditNote {
    if (json == null) {
        return json;
    }
    return {
        
        'creditNoteID': json['creditNoteID'],
        'creditNoteNumber': json['creditNoteNumber'],
        'reference': json['reference'],
        'remainingCredit': json['remainingCredit'],
        'appliedAmount': json['appliedAmount'],
        'currencyCode': json['currencyCode'],
        'currencyRate': json['currencyRate'],
        'type': json['type'],
        'contactName': json['contactName'],
        'date': json['date'] == null ? undefined : json['date'],
        'dueDate': json['dueDate'] == null ? undefined : json['dueDate'],
        'status': json['status'],
    };
}

export function XeroCreditNoteToJSON(value?: XeroCreditNote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'creditNoteID': value['creditNoteID'],
        'creditNoteNumber': value['creditNoteNumber'],
        'reference': value['reference'],
        'remainingCredit': value['remainingCredit'],
        'appliedAmount': value['appliedAmount'],
        'currencyCode': value['currencyCode'],
        'currencyRate': value['currencyRate'],
        'type': value['type'],
        'contactName': value['contactName'],
        'date': value['date'],
        'dueDate': value['dueDate'],
        'status': value['status'],
    };
}

