export interface StarredCompany {
  companyId: string;
  reason: string;
}

export function isCompanyStarred(companyId: string): boolean {
  const starredCompanies = loadStarredCompanies();
  return starredCompanies.some((o) => o.companyId === companyId);
}

export function useStarredCompanies(): StarredCompany[] {
  // Gets a list of starred companies from local storage
  return loadStarredCompanies();
}

export function getStarredCompanyById(
  companyId: string
): StarredCompany | undefined {
  const starredCompanies = loadStarredCompanies();
  return starredCompanies.find((o) => o.companyId === companyId);
}

export function unstarCompany(companyId: string) {
  const starredCompanies = loadStarredCompanies();
  const updatedStarredCompanies = starredCompanies.filter(
    (o) => o.companyId !== companyId
  );
  saveStarredCompanies(updatedStarredCompanies);
}

export function unstarAllCompanies() {
  saveStarredCompanies([]);
}

export function starCompany(companyId: string, reason: string) {
  // First make sure this company isn't starred
  unstarCompany(companyId);

  const starredCompanies = loadStarredCompanies();
  starredCompanies.push({ companyId, reason });
  saveStarredCompanies(starredCompanies);
}

function saveStarredCompanies(starredCompanies: StarredCompany[]) {
  localStorage.setItem("starredCompanies", JSON.stringify(starredCompanies));
}

function loadStarredCompanies(): StarredCompany[] {
  const raw = localStorage.getItem("starredCompanies");
  if (!raw) {
    return [];
  }
  return JSON.parse(raw) as StarredCompany[];
}
