import { ReactNode, useContext } from "react";
import { CallDataContext, CallDataContextProvider } from "./CallDataContext";
import {
  CallRefreshContext,
  CallRefreshContextProvider,
} from "./CallRefreshContext";

export function useCallContext() {
  return useContext(CallDataContext);
}

export function useCallRefreshContext() {
  return useContext(CallRefreshContext);
}

export function CallContextProvider({ children }: { children: ReactNode }) {
  return (
    <CallRefreshContextProvider>
      <CallDataContextProvider>{children}</CallDataContextProvider>
    </CallRefreshContextProvider>
  );
}
