import { Button, notification } from "antd";
import { useCompaniesApi } from "../Apis/Apis";
import { dashboardBaseUrl } from "../Helpers/dashboardBaseUrl";

export function useAttach() {
  const createCompaniesApi = useCompaniesApi();

  async function attach(companyId: string) {
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.attachUserToCompany({ companyId });
      notification.success({
        message: "Attached",
        description: (
          <>
            Successfully attached. You can now go to{" "}
            <a target="_blank" rel="noreferrer" href={dashboardBaseUrl()}>
              https://dashboard.freightsimple.com
            </a>{" "}
            to log into the company
          </>
        ),
      });
    } catch (e) {
      notification.error({
        message: "Oops",
        description: <>Something went wrong</>,
      });
    }
  }

  return { attach };
}

interface AttachButtonProps {
  companyId: string;
}

export function AttachButton(props: AttachButtonProps) {
  const { attach } = useAttach();
  function onAttach() {
    attach(props.companyId);
  }
  return (
    <Button onClick={onAttach} key="attach">
      Attach
    </Button>
  );
}
