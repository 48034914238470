import moment from "moment";
import { Link } from "react-router-dom";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import Stack from "../../Components/Stack";
import { emojiForShipmentVolume } from "../../Helpers/emojiForShipmentVolume";
import { CompanyTabProps } from "../ViewCompanyScreen";

export function CompanyOverviewTab(props: CompanyTabProps) {
  const company = props.data.company;
  function primaryContactName() {
    if (props.data.company.defaultSalesContactId) {
      const sc = props.data.salesContacts.find(
        (o) => o.salesContactId === props.data.company.defaultSalesContactId
      );
      return sc?.name;
    } else {
      return "-";
    }
  }
  function leftSideKeyValues() {
    const data: { [key: string]: any } = {};

    data["Primary Contact"] = primaryContactName();
    data["Phone"] = company.phone;
    data["Address"] = (
      <Stack align="left">
        <div>{company.billingAddressLine}</div>
        <div>
          {company.billingCity}, {company.billingState}
        </div>
        <div>{company.billingPostalCode}</div>
        <div>{company.billingCountry}</div>
      </Stack>
    );
    data["Source"] = company.leadSource;
    data["Known Group Email Addresses"] =
      company.knownGroupEmailAddresses?.join(", ");

    if (company.referralCompanyId) {
      data["Referring Company"] = (
        <Link to={`/view-company?companyId=${company.referralCompanyId}`}>
          View Company
        </Link>
      );
    }

    if (company.referralShipmentId) {
      data["Referring Shipment"] = (
        <Link to={`/view-shipment?shipmentId=${company.referralShipmentId}`}>
          View Shipment
        </Link>
      );
    }

    return data;
  }

  return (
    <HorizontalStack verticalAlign="top">
      <div style={{ width: "50%" }}>
        <KeyValues data={leftSideKeyValues()} />
      </div>
      <div style={{ width: "50%" }}>
        <KeyValues
          data={{
            "Shipments Booked": company.shipmentsBooked,
            "Quotes Ran": company.quotesRan,
            "Created Date": company.createdAt
              ? moment(company.createdAt).format("D MMM YYYY")
              : "-",
            "Last Quoting Date": company.lastQuotedDate
              ? moment(company.lastQuotedDate).format("D MMM YYYY")
              : "-",
            "Last Booking Date": company.lastBookedDate
              ? moment(company.lastBookedDate).format("D MMM YYYY")
              : "-",

            Temperature: company.temperature,
            Volume: company.shipmentFrequency
              ? emojiForShipmentVolume(company.shipmentFrequency ?? "") +
                " " +
                company.shipmentFrequency
              : "-",
          }}
        />
      </div>
    </HorizontalStack>
  );
}
