/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailQuoteRequestState = {
    Sent: 'sent',
    PriceTooHigh: 'price_too_high',
    Converted: 'converted',
    Potential: 'potential',
    Replied: 'replied',
    NoService: 'no_service'
} as const;
export type EmailQuoteRequestState = typeof EmailQuoteRequestState[keyof typeof EmailQuoteRequestState];


export function instanceOfEmailQuoteRequestState(value: any): boolean {
    return Object.values(EmailQuoteRequestState).includes(value);
}

export function EmailQuoteRequestStateFromJSON(json: any): EmailQuoteRequestState {
    return EmailQuoteRequestStateFromJSONTyped(json, false);
}

export function EmailQuoteRequestStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailQuoteRequestState {
    return json as EmailQuoteRequestState;
}

export function EmailQuoteRequestStateToJSON(value?: EmailQuoteRequestState | null): any {
    return value as any;
}

