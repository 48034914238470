/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApolloMapLocationFilterType } from './ApolloMapLocationFilterType';
import {
    ApolloMapLocationFilterTypeFromJSON,
    ApolloMapLocationFilterTypeFromJSONTyped,
    ApolloMapLocationFilterTypeToJSON,
} from './ApolloMapLocationFilterType';
import type { ApolloMapQueryBookingStatus } from './ApolloMapQueryBookingStatus';
import {
    ApolloMapQueryBookingStatusFromJSON,
    ApolloMapQueryBookingStatusFromJSONTyped,
    ApolloMapQueryBookingStatusToJSON,
} from './ApolloMapQueryBookingStatus';

/**
 * 
 * @export
 * @interface ApolloMapQuery
 */
export interface ApolloMapQuery {
    /**
     * 
     * @type {ApolloMapQueryBookingStatus}
     * @memberof ApolloMapQuery
     */
    bookingStatus: ApolloMapQueryBookingStatus;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    endDate?: string;
    /**
     * 
     * @type {ApolloMapLocationFilterType}
     * @memberof ApolloMapQuery
     */
    pickupLocationFilterType?: ApolloMapLocationFilterType;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    pickupCity?: string;
    /**
     * 
     * @type {ApolloMapLocationFilterType}
     * @memberof ApolloMapQuery
     */
    deliveryLocationFilterType?: ApolloMapLocationFilterType;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    deliveryCity?: string;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    minimumShipmentWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    maximumShipmentWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    minimumNumberHandlingUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    maximumNumberHandlingUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    minimumVolume?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    maximumVolume?: number;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    inCarrierCoverageArea?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    compareWithCarrier?: string;
}

/**
 * Check if a given object implements the ApolloMapQuery interface.
 */
export function instanceOfApolloMapQuery(value: object): boolean {
    if (!('bookingStatus' in value)) return false;
    return true;
}

export function ApolloMapQueryFromJSON(json: any): ApolloMapQuery {
    return ApolloMapQueryFromJSONTyped(json, false);
}

export function ApolloMapQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapQuery {
    if (json == null) {
        return json;
    }
    return {
        
        'bookingStatus': ApolloMapQueryBookingStatusFromJSON(json['bookingStatus']),
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'endDate': json['endDate'] == null ? undefined : json['endDate'],
        'pickupLocationFilterType': json['pickupLocationFilterType'] == null ? undefined : ApolloMapLocationFilterTypeFromJSON(json['pickupLocationFilterType']),
        'pickupCity': json['pickupCity'] == null ? undefined : json['pickupCity'],
        'deliveryLocationFilterType': json['deliveryLocationFilterType'] == null ? undefined : ApolloMapLocationFilterTypeFromJSON(json['deliveryLocationFilterType']),
        'deliveryCity': json['deliveryCity'] == null ? undefined : json['deliveryCity'],
        'minimumShipmentWeight': json['minimumShipmentWeight'] == null ? undefined : json['minimumShipmentWeight'],
        'maximumShipmentWeight': json['maximumShipmentWeight'] == null ? undefined : json['maximumShipmentWeight'],
        'minimumNumberHandlingUnits': json['minimumNumberHandlingUnits'] == null ? undefined : json['minimumNumberHandlingUnits'],
        'maximumNumberHandlingUnits': json['maximumNumberHandlingUnits'] == null ? undefined : json['maximumNumberHandlingUnits'],
        'minimumVolume': json['minimumVolume'] == null ? undefined : json['minimumVolume'],
        'maximumVolume': json['maximumVolume'] == null ? undefined : json['maximumVolume'],
        'inCarrierCoverageArea': json['inCarrierCoverageArea'] == null ? undefined : json['inCarrierCoverageArea'],
        'compareWithCarrier': json['compareWithCarrier'] == null ? undefined : json['compareWithCarrier'],
    };
}

export function ApolloMapQueryToJSON(value?: ApolloMapQuery | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bookingStatus': ApolloMapQueryBookingStatusToJSON(value['bookingStatus']),
        'companyId': value['companyId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'startDate': value['startDate'],
        'endDate': value['endDate'],
        'pickupLocationFilterType': ApolloMapLocationFilterTypeToJSON(value['pickupLocationFilterType']),
        'pickupCity': value['pickupCity'],
        'deliveryLocationFilterType': ApolloMapLocationFilterTypeToJSON(value['deliveryLocationFilterType']),
        'deliveryCity': value['deliveryCity'],
        'minimumShipmentWeight': value['minimumShipmentWeight'],
        'maximumShipmentWeight': value['maximumShipmentWeight'],
        'minimumNumberHandlingUnits': value['minimumNumberHandlingUnits'],
        'maximumNumberHandlingUnits': value['maximumNumberHandlingUnits'],
        'minimumVolume': value['minimumVolume'],
        'maximumVolume': value['maximumVolume'],
        'inCarrierCoverageArea': value['inCarrierCoverageArea'],
        'compareWithCarrier': value['compareWithCarrier'],
    };
}

