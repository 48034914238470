import {
  CustomerInvoice,
  CustomerInvoiceState,
} from "../generated-openapi-client";
import moment from "moment";

export function isCustomerInvoiceOverdue(customerInvoice: CustomerInvoice) {
  const isInThePast = moment(customerInvoice.dueDate)
    .startOf("day")
    .isBefore(moment().startOf("day"));

  return (
    customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued &&
    isInThePast
  );
}
