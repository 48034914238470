import { ShipmentState } from "../../generated-openapi-client";
import { Shipment } from "../../generated-openapi-client/models/Shipment";
import { ShipmentApiActionButton } from "./ShipmentApiActionButton";

interface MarkFreightHotButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

export function MarkFreightHotButton(props: MarkFreightHotButtonProps) {
  const shipmentId = props.shipment.shipmentId!!;

  if (props.shipment.state === ShipmentState.Delivered) {
    return <></>;
  }

  return (
    <ShipmentApiActionButton
      name="🔥 Mark Freight Hot"
      tooltip="This will email the carrier at their standard tracking address and request the freight is marked hot"
      onClick={async function (shipmentApi) {
        await shipmentApi.markHotFreight({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={props.shipment.hotFreight!!}
      confirm={true}
    />
  );
}
