import { Form, Input, message, Modal, Tooltip, Typography } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useEffect, useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import {
  CarrierCreditNote,
  CarrierCreditNoteState,
  EmailDescription,
} from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

const { Text } = Typography;

interface SendOverchargeClaimEmailMenuItemProps extends TabProps {
  carrierCreditNote: CarrierCreditNote;
}

export function SendOverchargeClaimEmailMenuItem(
  props: SendOverchargeClaimEmailMenuItemProps
) {
  const carrierCreditNoteId = props.carrierCreditNote.carrierCreditNoteId!!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailDescription, setEmailDescription] = useState<
    EmailDescription | undefined
  >();
  const [sending, setSending] = useState(false);
  const [extraText, setExtraText] = useState("");

  async function fetchPreview() {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const response = await carrierInvoiceApi.previewEmailForOverchargeClaim({
      carrierCreditNoteId,
      extraText,
    });
    setEmailDescription(response);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        fetchPreview();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extraText, isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function handleOk() {
    setSending(true);
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.sendOverchargeClaimEmail({
        carrierCreditNoteId,
        extraText,
      });
      setIsModalVisible(false);
      message.success("Overcharge claim email sent!");
    } catch (e: any) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setSending(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (
    props.carrierCreditNote.carrierCreditNoteState !==
    CarrierCreditNoteState.Approved
  ) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Overcharge Claim"
        visible={isModalVisible}
        onOk={handleOk}
        okText="✉️ Send overcharge claim email"
        confirmLoading={sending}
        onCancel={handleCancel}
        width={1200}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          {emailDescription !== undefined && (
            <>
              <Form.Item label="Email Addresses">
                <Text>{emailDescription?.addresses.join(", ")}</Text>
              </Form.Item>
              <Form.Item label="Subject">
                <Text>{emailDescription?.subject}</Text>
              </Form.Item>
              <Form.Item label="Extra Text">
                <Input
                  value={extraText}
                  onChange={function (e) {
                    setExtraText(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Email Body">
                <div style={{ border: "1px solid #eee", padding: "16px" }}>
                  <div
                    title="Email Preview"
                    dangerouslySetInnerHTML={{
                      __html: emailDescription.emailBody,
                    }}
                  ></div>
                </div>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <Tooltip
        placement="left"
        title="This allows us to prepare an email to send to the carrier to make an overcharge claim"
      >
        <MenuItem onClick={showModal}>Send Overcharge Email</MenuItem>
      </Tooltip>
    </>
  );
}
