import { Button, Form, Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { CompanyTabProps } from "../ViewCompanyScreen";

interface EditContactButtonProps extends CompanyTabProps {
  salesContactId: string;
  onRefresh: () => Promise<void>;
}

export function EditContactButton(props: EditContactButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { salesContactId } = props;
  const contact = props.data.salesContacts.find(
    (o) => o.salesContactId === salesContactId
  )!!;
  const title = "Edit Sales Contact";
  const [name, setName] = useState(contact.name ?? "");
  const [phone, setPhone] = useState(contact.phone ?? "");
  const [phoneExtension, setPhoneExtension] = useState(
    contact.phoneExtension ?? ""
  );
  const [mobilePhone, setMobilePhone] = useState(contact.mobilePhone ?? "");
  const [email, setEmail] = useState(contact.email ?? "");
  const [description, setDescription] = useState(contact.description ?? "");
  const [saving, setSaving] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  useEffect(
    function () {
      const contact = props.data.salesContacts.find(
        (o) => o.salesContactId === salesContactId
      )!!;
      setName(contact.name ?? "");
      setPhone(contact.phone ?? "");
      setMobilePhone(contact.mobilePhone ?? "");
      setEmail(contact.email ?? "");
      setDescription(contact.description ?? "");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [salesContactId]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (name === "" && phone === "" && email === "" && mobilePhone === "") {
      message.warn("Please enter some info");
    }
    setSaving(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.editContact({
        editContactInput: {
          salesContactId,
          name,
          phone,
          phoneExtension,
          mobilePhone,
          email,
          description,
        },
      });
      await props.onRefresh();
      message.success(`Success`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Eek. Something went wrong`);
    }

    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={saving}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Name">
            <Input
              value={name}
              onChange={function (e) {
                setName(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item label="Phone Number">
            <Input
              value={phone}
              onChange={function (e) {
                setPhone(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item label="Phone Extension">
            <Input
              value={phoneExtension}
              onChange={function (e) {
                setPhoneExtension(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Mobile Number">
            <Input
              value={mobilePhone}
              onChange={function (e) {
                setMobilePhone(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              value={description}
              onChange={function (e) {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              value={email}
              onChange={function (e) {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
