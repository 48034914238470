/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CurrencyCode = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;
export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


export function instanceOfCurrencyCode(value: any): boolean {
    return Object.values(CurrencyCode).includes(value);
}

export function CurrencyCodeFromJSON(json: any): CurrencyCode {
    return CurrencyCodeFromJSONTyped(json, false);
}

export function CurrencyCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyCode {
    return json as CurrencyCode;
}

export function CurrencyCodeToJSON(value?: CurrencyCode | null): any {
    return value as any;
}

