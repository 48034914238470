import { Menu, Modal } from "antd";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import { Loading } from "../../Components/Loading";
import {
  Quote,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { CarrierRequestAudit } from "../../generated-openapi-client/models/CarrierRequestAudit";
import { AuditsTable } from "../CarrierRequestAuditsScreen";

interface ViewCarrierRequestAuditsForQuoteMenuItemProps {
  quote: Quote;
  data: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

export function ViewCarrierRequestAuditsForQuoteMenuItem(
  props: ViewCarrierRequestAuditsForQuoteMenuItemProps
) {
  const createQuotesApi = useQuotesApi();
  const [audits, setAudits] = useState<CarrierRequestAudit[] | undefined>();

  const [isModalVisible, setIsModalVisible] = useState(false);

  async function refresh() {
    const quoteId = props.quote.quoteId!!;
    const shipmentId = props.data.shipmentId;
    const quotesApi = await createQuotesApi();
    const response = await quotesApi.carrierRequestAuditsForQuote({
      quoteId,
      shipmentId,
    });
    setAudits(response);
  }

  useEffect(
    function () {
      if (!isModalVisible) {
        return;
      }
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.quote.quoteId, isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Audits for Quote"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1400}
        destroyOnClose
      >
        {audits === undefined && <Loading />}
        {audits !== undefined && <AuditsTable audits={audits} />}
      </Modal>
      <Menu.Item onClick={showModal}>View Carrier Request Audits</Menu.Item>
    </>
  );
}
