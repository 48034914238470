import moment from "moment";
import "moment-timezone";

interface CreatedAtProps {
  timestamp: string | undefined | null;
  days?: boolean;
  skipDelta?: boolean;
}

export function CreatedAt(props: CreatedAtProps) {
  if (!props.timestamp) {
    return <div>-</div>;
  }
  const m = moment.utc(props.timestamp).tz("America/Vancouver");
  const delta = m.fromNow();
  const formatted = props.days
    ? m.format("MMM Do YYYY")
    : m.format("dddd, MMMM Do YYYY, h:mm:ss a z");

  if (props.skipDelta) {
    return <div>{formatted}</div>;
  }

  return (
    <div>
      {formatted} ({delta})
    </div>
  );
}
