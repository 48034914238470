import { message, Modal, Tooltip, Typography } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import { useShipmentsApi } from "../Apis/Apis";
import HorizontalStack from "../Components/HorizontalStack";
import Stack from "../Components/Stack";
import Spacer from "../Spacer";
import { NoteCategory } from "../generated-openapi-client";

const { Text } = Typography;

interface AddBillingNoteMenuItemProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function AddBillingNoteMenuItem(props: AddBillingNoteMenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    try {
      const shipmentId = props.shipmentId;

      const shipmentsApi = await createShipmentsApi();

      await shipmentsApi.addInternalNote({
        shipmentId,
        note,
        category: NoteCategory.Billing,
      });
      await props.onRefresh();
      message.success(`Note added`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Add Billing Note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose
        confirmLoading={loading}
      >
        <Stack align="left">
          <Text>Add a quick billing note</Text>
          <Spacer height={32} />
          <HorizontalStack>
            <TextArea
              style={{ width: "540px", height: "150px" }}
              value={note}
              onChange={function (e) {
                setNote(e.target.value);
              }}
              placeholder="Enter the billing note here"
            />
          </HorizontalStack>
        </Stack>
      </Modal>
      <Tooltip
        placement="left"
        overlay="Use this to quickly add a billing note to the shipment notes"
      >
        <MenuItem onClick={showModal}>Add Billing Note</MenuItem>
      </Tooltip>
    </>
  );
}
