import { ColumnsType } from "antd/lib/table";
import { RenderedCell } from "rc-table/lib/interface";

// Use this to make sure exceptions don't leak beyond a single render function
// for a table cell

export function safeColumns<RecordType>(
  columns: ColumnsType<RecordType>
): ColumnsType<RecordType> {
  type RenderFunction = (
    value: any,
    record: RecordType,
    index: number
  ) => React.ReactNode | RenderedCell<RecordType>;

  function safetyWrapper(
    fn: RenderFunction | undefined
  ): RenderFunction | undefined {
    return function (value: any, record: RecordType, index: number) {
      if (fn === undefined) {
        return undefined;
      }
      try {
        return fn(value, record, index);
      } catch (e: any) {
        console.error(`‼️ Error : ${e}`);
        return <>‼️ Error</>;
      }
    };
  }

  return columns.map(function (value) {
    return { ...value, render: safetyWrapper(value.render) };
  });
}
