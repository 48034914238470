import { CSSProperties, ReactNode } from "react";
import Colors from "./Colors";
import { WithClipboardLink } from "./WithClipboardLink";

interface ContentProps {
  value: string | ReactNode | undefined;
  copyToClipboard?: boolean;
  renderEmptyAsUnknown?: boolean;
}

function Content(props: ContentProps) {
  if (props.value === undefined || props.value === null || props.value === "") {
    if (props.renderEmptyAsUnknown) {
      return <div style={{ color: Colors.Red }}>Unknown</div>;
    } else {
      return <>-</>;
    }
  }

  if (props.copyToClipboard) {
    return <WithClipboardLink>{props.value.toString()}</WithClipboardLink>;
  }

  return <>{props.value}</>;
}
interface StatisticTextProps extends ContentProps {
  title: ReactNode;
  style?: CSSProperties;
}

export function StatisticText(props: StatisticTextProps) {
  return (
    <div className="ant-statistic" style={props.style}>
      <div className="ant-statistic-title">{props.title}</div>
      <div className="ant-statistic-content">
        <span className="ant-statistic-content-value">
          <Content {...props} />
        </span>
      </div>
    </div>
  );
}
