import { useRef, useState } from "react";

export function useRefreshFunction(
  onRefresh: () => Promise<any> = async () => {
    console.log("useRefreshFunction - empty refresh");
  }
) {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshRef = useRef(onRefresh);

  function setRefresh(fn: () => Promise<any>) {
    refreshRef.current = fn;
  }

  async function refresh() {
    setIsRefreshing(true);
    await refreshRef.current();
    setIsRefreshing(false);
  }

  return {
    isRefreshing,
    setRefresh,
    refresh,
  };
}
