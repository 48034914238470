/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierRepInformation } from './CarrierRepInformation';
import {
    CarrierRepInformationFromJSON,
    CarrierRepInformationFromJSONTyped,
    CarrierRepInformationToJSON,
} from './CarrierRepInformation';
import type { CustomerContactsTabData } from './CustomerContactsTabData';
import {
    CustomerContactsTabDataFromJSON,
    CustomerContactsTabDataFromJSONTyped,
    CustomerContactsTabDataToJSON,
} from './CustomerContactsTabData';

/**
 * 
 * @export
 * @interface ContactTabData
 */
export interface ContactTabData {
    /**
     * 
     * @type {CustomerContactsTabData}
     * @memberof ContactTabData
     */
    customerContacts: CustomerContactsTabData;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContactTabData
     */
    carrierShippingContacts: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContactTabData
     */
    carrierBillingContacts: { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof ContactTabData
     */
    carrierCustomerServiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTabData
     */
    advanceCustomerServiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactTabData
     */
    beyondCustomerServiceNumber?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContactTabData
     */
    advanceShippingContacts?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContactTabData
     */
    beyondShippingContacts?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {CarrierRepInformation}
     * @memberof ContactTabData
     */
    carrierRepInformation?: CarrierRepInformation;
}

/**
 * Check if a given object implements the ContactTabData interface.
 */
export function instanceOfContactTabData(value: object): boolean {
    if (!('customerContacts' in value)) return false;
    if (!('carrierShippingContacts' in value)) return false;
    if (!('carrierBillingContacts' in value)) return false;
    return true;
}

export function ContactTabDataFromJSON(json: any): ContactTabData {
    return ContactTabDataFromJSONTyped(json, false);
}

export function ContactTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'customerContacts': CustomerContactsTabDataFromJSON(json['customerContacts']),
        'carrierShippingContacts': json['carrierShippingContacts'],
        'carrierBillingContacts': json['carrierBillingContacts'],
        'carrierCustomerServiceNumber': json['carrierCustomerServiceNumber'] == null ? undefined : json['carrierCustomerServiceNumber'],
        'advanceCustomerServiceNumber': json['advanceCustomerServiceNumber'] == null ? undefined : json['advanceCustomerServiceNumber'],
        'beyondCustomerServiceNumber': json['beyondCustomerServiceNumber'] == null ? undefined : json['beyondCustomerServiceNumber'],
        'advanceShippingContacts': json['advanceShippingContacts'] == null ? undefined : json['advanceShippingContacts'],
        'beyondShippingContacts': json['beyondShippingContacts'] == null ? undefined : json['beyondShippingContacts'],
        'carrierRepInformation': json['carrierRepInformation'] == null ? undefined : CarrierRepInformationFromJSON(json['carrierRepInformation']),
    };
}

export function ContactTabDataToJSON(value?: ContactTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerContacts': CustomerContactsTabDataToJSON(value['customerContacts']),
        'carrierShippingContacts': value['carrierShippingContacts'],
        'carrierBillingContacts': value['carrierBillingContacts'],
        'carrierCustomerServiceNumber': value['carrierCustomerServiceNumber'],
        'advanceCustomerServiceNumber': value['advanceCustomerServiceNumber'],
        'beyondCustomerServiceNumber': value['beyondCustomerServiceNumber'],
        'advanceShippingContacts': value['advanceShippingContacts'],
        'beyondShippingContacts': value['beyondShippingContacts'],
        'carrierRepInformation': CarrierRepInformationToJSON(value['carrierRepInformation']),
    };
}

