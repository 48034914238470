import { DatePicker, Form, message, Modal } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import moment from "moment";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";

interface AdjustTrackingLineTimestampMenuItemProps {
  trackingLineId: string;
  currentTimestamp: string;
  onRefresh: () => void;
}

export function AdjustTrackingLineTimestampMenuItem(
  props: AdjustTrackingLineTimestampMenuItemProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const [time, setTime] = useState(moment(props.currentTimestamp));
  const trackingLineId = props.trackingLineId;
  const newTimestamp = time.toISOString();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    const shipmentsApi = await createShipmentsApi();
    console.log({ newTimestamp });
    try {
      await shipmentsApi.adjustTrackingLineTimestamp({
        trackingLineId,
        newTimestamp,
      });
      await props.onRefresh();
      message.success("Timestamp updated");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Adjust Tracking Line Timestamp"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Time">
            <DatePicker
              value={time}
              showTime
              onChange={function (m) {
                if (m !== null) {
                  setTime(m);
                }
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <MenuItem onClick={showModal}>Adjust Timestamp</MenuItem>
    </>
  );
}
