import { Tag } from "antd";
import { EmailQuoteRequestState } from "../generated-openapi-client";
import Colors from "./Colors";

interface EmailQuoteRequestStateTagProps {
  status: EmailQuoteRequestState;
  unreadCount: number;
}

function describeState(
  state: EmailQuoteRequestState,
  unreadCount: number
): string {
  if (unreadCount > 0) {
    return `🔥 Unread (${unreadCount})`;
  }
  switch (state) {
    case EmailQuoteRequestState.Sent:
      return "Waiting";
    case EmailQuoteRequestState.Converted:
      return "Converted";
    case EmailQuoteRequestState.NoService:
      return "No Service";
    case EmailQuoteRequestState.PriceTooHigh:
      return "Price Too High";
    case EmailQuoteRequestState.Potential:
      return "Potential";
    default:
      return "Unknown";
  }
}

function colorForState(state: EmailQuoteRequestState, unreadCount: number) {
  if (unreadCount > 0) {
    return Colors.Gold;
  }

  switch (state) {
    case EmailQuoteRequestState.Sent:
      return Colors.Blue;
    case EmailQuoteRequestState.Converted:
      return Colors.DarkGreen;
    case EmailQuoteRequestState.NoService:
      return Colors.Red;
    case EmailQuoteRequestState.PriceTooHigh:
      return Colors.Red;
    case EmailQuoteRequestState.Potential:
      return Colors.Green;
  }
}

function foregroundColorForState(
  state: EmailQuoteRequestState,
  unreadCount: number
) {
  if (unreadCount > 0) {
    return "black";
  }

  switch (state) {
    case EmailQuoteRequestState.Sent:
      return "white";
    case EmailQuoteRequestState.Converted:
      return "white";
    case EmailQuoteRequestState.NoService:
      return "white";
    case EmailQuoteRequestState.PriceTooHigh:
      return "white";
    case EmailQuoteRequestState.Potential:
      return "black";
  }
}

export function EmailQuoteRequestStateTag(
  props: EmailQuoteRequestStateTagProps
) {
  return (
    <Tag color={colorForState(props.status, props.unreadCount)}>
      <div
        style={{
          color: foregroundColorForState(props.status, props.unreadCount),
        }}
      >
        {describeState(props.status, props.unreadCount)}
      </div>
    </Tag>
  );
}
