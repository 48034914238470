import NumberFormat from "react-number-format";

interface VolumeProps {
  children: number;
}

export function Volume(props: VolumeProps) {
  return (
    <NumberFormat
      value={props.children}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={0}
      suffix={"cuft"}
    />
  );
}
