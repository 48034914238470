import { Select } from "antd";
import { InvoiceChallengeType } from "../../generated-openapi-client/models/InvoiceChallengeType";

const { Option } = Select;

interface InvoiceChallengeTypeDropdownProps {
  onSelect: (invoiceChallengeType: InvoiceChallengeType) => void;
  invoiceChallengeType: InvoiceChallengeType;
}

function describeInvoiceChallengeType(
  invoiceChallengeType: InvoiceChallengeType
) {
  switch (invoiceChallengeType) {
    case InvoiceChallengeType.ApplyQuote:
      return "Apply Quote";
    case InvoiceChallengeType.ApplyRate:
      return "Apply Rate";
    case InvoiceChallengeType.IncorrectFreightClass:
      return "Incorrect Freight Class";
    case InvoiceChallengeType.JustifyAdditionalWeight:
      return "Justify Additional Weight";
    case InvoiceChallengeType.ProvidePhotos:
      return "Provide Photos";
    case InvoiceChallengeType.RemoveDeliveryAppointmentFee:
      return "Remove Delivery Appointment Fee";
    case InvoiceChallengeType.RemoveNotifyPriorToDeliveryFee:
      return "Remove Notify Prior To Delivery Fee";
    case InvoiceChallengeType.JustifyDimensions:
      return "Justify Dimensions";
    case InvoiceChallengeType.RemoveResidentialFee:
      return "Remove Residential Fee";
    case InvoiceChallengeType.RemovePickupLiftgateFee:
      return "Remove Liftgate Pickup Fee";
    case InvoiceChallengeType.RemoveDeliveryLiftgateFee:
      return "Remove Liftgate Delivery Fee";
    case InvoiceChallengeType.RemoveInsidePickupFee:
      return "Remove Inside Pickup Fee";
    case InvoiceChallengeType.RemoveInsideDeliveryFee:
      return "Remove Inside Delivery Fee";
    case InvoiceChallengeType.RemoveLimitedAccessPickupFee:
      return "Remove Limited Access Pickup Fee";
    case InvoiceChallengeType.RemoveLimitedAccessDeliveryFee:
      return "Remove Limited Access Delivery Fee";
    case InvoiceChallengeType.RemoveBondFee:
      return "Remove Bond Fee";
    case InvoiceChallengeType.RemoveStorageFee:
      return "Remove Storage Fee";
    case InvoiceChallengeType.RemoveRedeliveryFee:
      return "Remove Redelivery";
    case InvoiceChallengeType.RemoveDetentionFee:
      return "Remove Detention Fee";
    case InvoiceChallengeType.CarrierRequestAudit:
      return "Send Carrier Request Audit";
    case InvoiceChallengeType.Generic:
      return "Generic";
  }
}

export function InvoiceChallengeTypeDropdown(
  props: InvoiceChallengeTypeDropdownProps
) {
  return (
    <Select
      value={props.invoiceChallengeType}
      style={{ width: 300 }}
      onChange={props.onSelect}
      showSearch
    >
      {Object.entries(InvoiceChallengeType).map(function (value) {
        const v = value[1];
        const description = describeInvoiceChallengeType(v);
        return <Option value={v}>{description}</Option>;
      })}
    </Select>
  );
}
