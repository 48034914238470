/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdditionalCharge } from './AdditionalCharge';
import {
    AdditionalChargeFromJSON,
    AdditionalChargeFromJSONTyped,
    AdditionalChargeToJSON,
} from './AdditionalCharge';
import type { CarrierCreditNote } from './CarrierCreditNote';
import {
    CarrierCreditNoteFromJSON,
    CarrierCreditNoteFromJSONTyped,
    CarrierCreditNoteToJSON,
} from './CarrierCreditNote';
import type { CarrierInvoice } from './CarrierInvoice';
import {
    CarrierInvoiceFromJSON,
    CarrierInvoiceFromJSONTyped,
    CarrierInvoiceToJSON,
} from './CarrierInvoice';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { CustomerInvoice } from './CustomerInvoice';
import {
    CustomerInvoiceFromJSON,
    CustomerInvoiceFromJSONTyped,
    CustomerInvoiceToJSON,
} from './CustomerInvoice';

/**
 * 
 * @export
 * @interface BillingTabData
 */
export interface BillingTabData {
    /**
     * 
     * @type {Array<CarrierInvoice>}
     * @memberof BillingTabData
     */
    carrierInvoices: Array<CarrierInvoice>;
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof BillingTabData
     */
    couponsUsed: Array<Coupon>;
    /**
     * 
     * @type {Array<CarrierCreditNote>}
     * @memberof BillingTabData
     */
    carrierCreditNotes: Array<CarrierCreditNote>;
    /**
     * 
     * @type {Array<CustomerInvoice>}
     * @memberof BillingTabData
     */
    customerInvoices: Array<CustomerInvoice>;
    /**
     * 
     * @type {Array<AdditionalCharge>}
     * @memberof BillingTabData
     */
    additionalCharges: Array<AdditionalCharge>;
}

/**
 * Check if a given object implements the BillingTabData interface.
 */
export function instanceOfBillingTabData(value: object): boolean {
    if (!('carrierInvoices' in value)) return false;
    if (!('couponsUsed' in value)) return false;
    if (!('carrierCreditNotes' in value)) return false;
    if (!('customerInvoices' in value)) return false;
    if (!('additionalCharges' in value)) return false;
    return true;
}

export function BillingTabDataFromJSON(json: any): BillingTabData {
    return BillingTabDataFromJSONTyped(json, false);
}

export function BillingTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierInvoices': ((json['carrierInvoices'] as Array<any>).map(CarrierInvoiceFromJSON)),
        'couponsUsed': ((json['couponsUsed'] as Array<any>).map(CouponFromJSON)),
        'carrierCreditNotes': ((json['carrierCreditNotes'] as Array<any>).map(CarrierCreditNoteFromJSON)),
        'customerInvoices': ((json['customerInvoices'] as Array<any>).map(CustomerInvoiceFromJSON)),
        'additionalCharges': ((json['additionalCharges'] as Array<any>).map(AdditionalChargeFromJSON)),
    };
}

export function BillingTabDataToJSON(value?: BillingTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierInvoices': ((value['carrierInvoices'] as Array<any>).map(CarrierInvoiceToJSON)),
        'couponsUsed': ((value['couponsUsed'] as Array<any>).map(CouponToJSON)),
        'carrierCreditNotes': ((value['carrierCreditNotes'] as Array<any>).map(CarrierCreditNoteToJSON)),
        'customerInvoices': ((value['customerInvoices'] as Array<any>).map(CustomerInvoiceToJSON)),
        'additionalCharges': ((value['additionalCharges'] as Array<any>).map(AdditionalChargeToJSON)),
    };
}

