/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CalendarDataResponse } from './CalendarDataResponse';
import {
    CalendarDataResponseFromJSON,
    CalendarDataResponseFromJSONTyped,
    CalendarDataResponseToJSON,
} from './CalendarDataResponse';

/**
 * 
 * @export
 * @interface CompanyCalendarDataResponse
 */
export interface CompanyCalendarDataResponse {
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof CompanyCalendarDataResponse
     */
    bookedRevenue: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof CompanyCalendarDataResponse
     */
    numberBooked: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof CompanyCalendarDataResponse
     */
    numberQuotes: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof CompanyCalendarDataResponse
     */
    acceptanceRatesPerMonth: CalendarDataResponse;
}

/**
 * Check if a given object implements the CompanyCalendarDataResponse interface.
 */
export function instanceOfCompanyCalendarDataResponse(value: object): boolean {
    if (!('bookedRevenue' in value)) return false;
    if (!('numberBooked' in value)) return false;
    if (!('numberQuotes' in value)) return false;
    if (!('acceptanceRatesPerMonth' in value)) return false;
    return true;
}

export function CompanyCalendarDataResponseFromJSON(json: any): CompanyCalendarDataResponse {
    return CompanyCalendarDataResponseFromJSONTyped(json, false);
}

export function CompanyCalendarDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCalendarDataResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'bookedRevenue': CalendarDataResponseFromJSON(json['bookedRevenue']),
        'numberBooked': CalendarDataResponseFromJSON(json['numberBooked']),
        'numberQuotes': CalendarDataResponseFromJSON(json['numberQuotes']),
        'acceptanceRatesPerMonth': CalendarDataResponseFromJSON(json['acceptanceRatesPerMonth']),
    };
}

export function CompanyCalendarDataResponseToJSON(value?: CompanyCalendarDataResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bookedRevenue': CalendarDataResponseToJSON(value['bookedRevenue']),
        'numberBooked': CalendarDataResponseToJSON(value['numberBooked']),
        'numberQuotes': CalendarDataResponseToJSON(value['numberQuotes']),
        'acceptanceRatesPerMonth': CalendarDataResponseToJSON(value['acceptanceRatesPerMonth']),
    };
}

