import { Button, Select } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import { LineItem } from "../../generated-openapi-client";
import { ErrorMessageType } from "../../Types/ErrorMessageType";

const { Option } = Select;

interface FreightClassDropdownProps {
  lineItem: LineItem;
  onChangeFreightClass: (_: string | undefined) => void;
}

export function recommendFreightClassFromDimensions(
  weight: number,
  length: number,
  width: number,
  height: number
): { freightClass: string | undefined; errorMessage: ErrorMessageType } {
  if (length === 0 || width === 0 || height === 0) {
    return {
      freightClass: undefined,
      errorMessage: "Dimension must not be zero",
    };
  }

  const volumeInCubicInches = length * width * height;
  const volumeInCubicFeet = volumeInCubicInches / (12 * 12 * 12);
  const density = weight / volumeInCubicFeet;

  function fc(freightClass: string) {
    return { freightClass, errorMessage: undefined };
  }

  // See: https://www.fedex.com/en-us/shipping/freight/class-calculator.html

  if (density >= 50) {
    return fc("50");
  }

  if (density >= 35) {
    return fc("55");
  }

  if (density >= 30) {
    return fc("60");
  }

  if (density >= 22.5) {
    return fc("65");
  }

  if (density >= 15) {
    return fc("70");
  }

  if (density >= 13.5) {
    return fc("77.5");
  }

  if (density >= 12) {
    return fc("85");
  }

  if (density >= 10.5) {
    return fc("92.5");
  }

  if (density >= 9) {
    return fc("100");
  }

  if (density >= 8) {
    return fc("110");
  }

  if (density >= 7) {
    return fc("125");
  }

  if (density >= 6) {
    return fc("150");
  }

  if (density >= 5) {
    return fc("175");
  }

  if (density >= 4) {
    return fc("200");
  }

  if (density >= 3) {
    return fc("250");
  }

  if (density >= 2) {
    return fc("300");
  }

  if (density >= 1) {
    return fc("400");
  }

  return fc("500");
}

function generateFreightClassOption(freightClass: string) {
  return <Option value={freightClass}>Class {freightClass}</Option>;
}

const freightClasses = [
  "50",
  "55",
  "60",
  "65",
  "70",
  "77.5",
  "85",
  "92.5",
  "100",
  "110",
  "125",
  "150",
  "175",
  "200",
  "250",
  "300",
  "400",
  "500",
];

export function FreightClassDropdown(props: FreightClassDropdownProps) {
  const newClass = recommendFreightClassFromDimensions(
    props.lineItem.weightPerHandlingUnit!!,
    props.lineItem.length!!,
    props.lineItem.width!!,
    props.lineItem.height!!
  );

  const label = `Update Freight Class to match dimensions (Class ${newClass.freightClass})`;

  const currentFreightClassIsCorrect =
    newClass.freightClass === props.lineItem.freightClass;

  const updateDisabled =
    props.lineItem.weightPerHandlingUnit === undefined ||
    props.lineItem.length === undefined ||
    props.lineItem.width === undefined ||
    props.lineItem.height === undefined ||
    currentFreightClassIsCorrect;

  function updateFreightClass() {
    props.onChangeFreightClass(newClass.freightClass);
  }

  return (
    <HorizontalStack>
      <Select
        value={props.lineItem.freightClass}
        style={{ width: 200 }}
        onChange={props.onChangeFreightClass}
      >
        {freightClasses.map(generateFreightClassOption)}
      </Select>
      <Button
        type="link"
        onClick={updateFreightClass}
        disabled={updateDisabled}
      >
        {label}
      </Button>
    </HorizontalStack>
  );
}
