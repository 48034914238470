import { Button, Modal, Typography } from "antd";
import { useState } from "react";

import { sum } from "../../Helpers/sum";
import { ShipmentReport } from "../../generated-openapi-client";
import { MapScreenDataTable } from "./MapScreenDataTable";

const { Text } = Typography;

interface MapScreenDataButtonProps {
  shipments: ShipmentReport[];
}

export function MapScreenDataButton(props: MapScreenDataButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const totalCad = sum(props.shipments, (s) =>
    s.lowestQuote?.priceCad ? s.lowestQuote.priceCad : 0
  );

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Shipments"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <>
          <Text>Total: ${totalCad.toLocaleString()} CAD</Text>
          <MapScreenDataTable data={props.shipments} />
        </>
      </Modal>
      <Button type="link" onClick={showModal}>
        View Data
      </Button>
    </>
  );
}
