import { Tag, message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { ShipmentDocument } from "../../generated-openapi-client";

interface DeleteDocumentMenuItemProps {
  document: ShipmentDocument;
  onRefresh: () => Promise<void>;
}

export function DeleteDocumentMenuItem(props: DeleteDocumentMenuItemProps) {
  const createShipmentsApi = useShipmentsApi();
  const shipmentDocumentId = props.document.shipmentDocumentId!!;

  async function onClick() {
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.deleteDocument({
        shipmentDocumentId,
      });
      await props.onRefresh();
      message.success("Deleted!");
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  return (
    <ConfirmMenuItem
      onConfirm={onClick}
      question={`Are you sure you delete this document (${props.document.documentType})?`}
      okText={"Yes, permanently delete this"}
      tooltip={
        "Documents should only be deleted in rare cases. eg. if the wrong customs documents were uploaded"
      }
    >
      <Tag color="red">Advanced</Tag> Delete Document
    </ConfirmMenuItem>
  );
}
