import { CollapsibleNote } from "../../../Components/NotesBase/CollapseNote";
import { ModifiedGenericNote } from "../../../Components/NotesBase/ModifiedGenericNote";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import { NoteSubmessage } from "../../../Components/NotesBase/NoteSubmessage";
import { CompanyNoteElementProps } from "../CompanyNote";

export function CreditModifiedCompanyNoteElement(
  props: CompanyNoteElementProps
) {
  const { note } = props;

  const metadata = JSON.parse(note.metadata!);
  const data = metadata.data;

  return (
    <CollapsibleNote
      collapseActive={props.collapseActive}
      header={
        <div>
          <NoteAuthorHeadline headline={note.subject} author={note.author} />
          <NoteSubmessage>{note.subMessage}</NoteSubmessage>
        </div>
      }
    >
      <ModifiedGenericNote beforeData={data.before} afterData={data.after} />
    </CollapsibleNote>
  );
}
