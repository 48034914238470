/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClaudePrompt,
  EmailTemplateGroup,
  EmailTemplates,
} from '../models/index';
import {
    ClaudePromptFromJSON,
    ClaudePromptToJSON,
    EmailTemplateGroupFromJSON,
    EmailTemplateGroupToJSON,
    EmailTemplatesFromJSON,
    EmailTemplatesToJSON,
} from '../models/index';

export interface DeleteEmailTemplateRequest {
    emailTemplateId: string;
}

export interface DeleteEmailTemplateImageRequest {
    emailTemplateImageId: string;
}

export interface InsertEmailTemplateImageRequest {
    title: string;
    url: string;
}

export interface UpsertClaudePromptRequest {
    title: string;
    identifier: string;
    body: string;
    claudePromptId?: string;
}

export interface UpsertEmailTemplateRequest {
    title: string;
    subject: string;
    body: string;
    emailTemplateGroup: EmailTemplateGroup;
    emailTemplateId?: string;
}

/**
 * 
 */
export class TechnicalApi extends runtime.BaseAPI {

    /**
     */
    async deleteEmailTemplateRaw(requestParameters: DeleteEmailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailTemplateId'] == null) {
            throw new runtime.RequiredError(
                'emailTemplateId',
                'Required parameter "emailTemplateId" was null or undefined when calling deleteEmailTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailTemplateId'] != null) {
            queryParameters['emailTemplateId'] = requestParameters['emailTemplateId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/delete-email-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEmailTemplate(requestParameters: DeleteEmailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmailTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteEmailTemplateImageRaw(requestParameters: DeleteEmailTemplateImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailTemplateImageId'] == null) {
            throw new runtime.RequiredError(
                'emailTemplateImageId',
                'Required parameter "emailTemplateImageId" was null or undefined when calling deleteEmailTemplateImage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailTemplateImageId'] != null) {
            queryParameters['emailTemplateImageId'] = requestParameters['emailTemplateImageId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/delete-email-template-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEmailTemplateImage(requestParameters: DeleteEmailTemplateImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmailTemplateImageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllClaudePromptsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClaudePrompt>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/get-all-claude-prompts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClaudePromptFromJSON));
    }

    /**
     */
    async getAllClaudePrompts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClaudePrompt>> {
        const response = await this.getAllClaudePromptsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailTemplates>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/get-email-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTemplatesFromJSON(jsonValue));
    }

    /**
     */
    async getEmailTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailTemplates> {
        const response = await this.getEmailTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async insertEmailTemplateImageRaw(requestParameters: InsertEmailTemplateImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling insertEmailTemplateImage().'
            );
        }

        if (requestParameters['url'] == null) {
            throw new runtime.RequiredError(
                'url',
                'Required parameter "url" was null or undefined when calling insertEmailTemplateImage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['url'] != null) {
            queryParameters['url'] = requestParameters['url'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/insert-email-template-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async insertEmailTemplateImage(requestParameters: InsertEmailTemplateImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.insertEmailTemplateImageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendAmyEmailTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/send-amy-email-test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendAmyEmailTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendAmyEmailTestRaw(initOverrides);
    }

    /**
     */
    async sendSupportEmailTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/send-support-email-test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendSupportEmailTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendSupportEmailTestRaw(initOverrides);
    }

    /**
     */
    async upsertClaudePromptRaw(requestParameters: UpsertClaudePromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling upsertClaudePrompt().'
            );
        }

        if (requestParameters['identifier'] == null) {
            throw new runtime.RequiredError(
                'identifier',
                'Required parameter "identifier" was null or undefined when calling upsertClaudePrompt().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling upsertClaudePrompt().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['claudePromptId'] != null) {
            queryParameters['claudePromptId'] = requestParameters['claudePromptId'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['identifier'] != null) {
            queryParameters['identifier'] = requestParameters['identifier'];
        }

        if (requestParameters['body'] != null) {
            queryParameters['body'] = requestParameters['body'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/upsert-claude-prompt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async upsertClaudePrompt(requestParameters: UpsertClaudePromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.upsertClaudePromptRaw(requestParameters, initOverrides);
    }

    /**
     */
    async upsertEmailTemplateRaw(requestParameters: UpsertEmailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling upsertEmailTemplate().'
            );
        }

        if (requestParameters['subject'] == null) {
            throw new runtime.RequiredError(
                'subject',
                'Required parameter "subject" was null or undefined when calling upsertEmailTemplate().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling upsertEmailTemplate().'
            );
        }

        if (requestParameters['emailTemplateGroup'] == null) {
            throw new runtime.RequiredError(
                'emailTemplateGroup',
                'Required parameter "emailTemplateGroup" was null or undefined when calling upsertEmailTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailTemplateId'] != null) {
            queryParameters['emailTemplateId'] = requestParameters['emailTemplateId'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['subject'] != null) {
            queryParameters['subject'] = requestParameters['subject'];
        }

        if (requestParameters['body'] != null) {
            queryParameters['body'] = requestParameters['body'];
        }

        if (requestParameters['emailTemplateGroup'] != null) {
            queryParameters['emailTemplateGroup'] = requestParameters['emailTemplateGroup'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/technical/upsert-email-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async upsertEmailTemplate(requestParameters: UpsertEmailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.upsertEmailTemplateRaw(requestParameters, initOverrides);
    }

}
