/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierInvoice } from './CarrierInvoice';
import {
    CarrierInvoiceFromJSON,
    CarrierInvoiceFromJSONTyped,
    CarrierInvoiceToJSON,
} from './CarrierInvoice';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { Shipment } from './Shipment';
import {
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
} from './Shipment';

/**
 * 
 * @export
 * @interface CompanyShipmentCarrierInvoice
 */
export interface CompanyShipmentCarrierInvoice {
    /**
     * 
     * @type {Company}
     * @memberof CompanyShipmentCarrierInvoice
     */
    company: Company;
    /**
     * 
     * @type {Shipment}
     * @memberof CompanyShipmentCarrierInvoice
     */
    shipment: Shipment;
    /**
     * 
     * @type {CarrierInvoice}
     * @memberof CompanyShipmentCarrierInvoice
     */
    carrierInvoice: CarrierInvoice;
}

/**
 * Check if a given object implements the CompanyShipmentCarrierInvoice interface.
 */
export function instanceOfCompanyShipmentCarrierInvoice(value: object): boolean {
    if (!('company' in value)) return false;
    if (!('shipment' in value)) return false;
    if (!('carrierInvoice' in value)) return false;
    return true;
}

export function CompanyShipmentCarrierInvoiceFromJSON(json: any): CompanyShipmentCarrierInvoice {
    return CompanyShipmentCarrierInvoiceFromJSONTyped(json, false);
}

export function CompanyShipmentCarrierInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyShipmentCarrierInvoice {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyFromJSON(json['company']),
        'shipment': ShipmentFromJSON(json['shipment']),
        'carrierInvoice': CarrierInvoiceFromJSON(json['carrierInvoice']),
    };
}

export function CompanyShipmentCarrierInvoiceToJSON(value?: CompanyShipmentCarrierInvoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyToJSON(value['company']),
        'shipment': ShipmentToJSON(value['shipment']),
        'carrierInvoice': CarrierInvoiceToJSON(value['carrierInvoice']),
    };
}

