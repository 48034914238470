/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CarrierCreditNoteState = {
    Approved: 'approved',
    Paid: 'paid',
    Voided: 'voided'
} as const;
export type CarrierCreditNoteState = typeof CarrierCreditNoteState[keyof typeof CarrierCreditNoteState];


export function instanceOfCarrierCreditNoteState(value: any): boolean {
    return Object.values(CarrierCreditNoteState).includes(value);
}

export function CarrierCreditNoteStateFromJSON(json: any): CarrierCreditNoteState {
    return CarrierCreditNoteStateFromJSONTyped(json, false);
}

export function CarrierCreditNoteStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierCreditNoteState {
    return json as CarrierCreditNoteState;
}

export function CarrierCreditNoteStateToJSON(value?: CarrierCreditNoteState | null): any {
    return value as any;
}

