import { Button, Form, Modal, Select } from "antd";
import Input from "antd/lib/input/Input";
import { useState } from "react";
import { CarrierFilter } from "../../Components/CarrierFilter";
import { DateRangePicker } from "../../Components/DateRangePicker";
import HorizontalStack from "../../Components/HorizontalStack";
import { InfoBubble } from "../../Components/InfoBubble";
import { MapData } from "../../Hooks/useMapData";
import Spacer from "../../Spacer";
import { ApolloMapQuery } from "../../generated-openapi-client";
import { ApolloMapQueryBookingStatus } from "../../generated-openapi-client/models/ApolloMapQueryBookingStatus";
import { CompaniesDropdown } from "./CompaniesDropdown";
import { LocationDropdown } from "./LocationDropdown";

interface MapScreenFilterButtonProps {
  data: MapData;
  query: ApolloMapQuery;
  setQuery: (_: ApolloMapQuery) => void;
}

// The regular input number seems to lose the value when we click away
// Not seen that before, so build a new one for this screen

interface InputNumProps {
  value: number | undefined | null;
  onChange: (_: number) => void;
}
function InputNum(props: InputNumProps) {
  return (
    <Input
      value={props.value?.toString()}
      onChange={function (e) {
        const newValue = parseInt(e.target.value);
        props.onChange(newValue);
      }}
      style={{ width: 90 }}
    />
  );
}

export function MapScreenFilterButton(props: MapScreenFilterButtonProps) {
  const { data } = props;
  const total = data.bookedFeatures.length + data.notBookedFeatures.length;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useState({ ...props.query });

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    console.log(`#### Setting query to ${JSON.stringify(query)}`);
    props.setQuery(query);
    setIsModalVisible(false);
  }

  console.log(`#### weight is currently = ${query.minimumShipmentWeight}`);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Filter Shipments"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Filter"
        onCancel={handleCancel}
        width={900}
      >
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Pickup Location">
            <LocationDropdown
              locationFilterType={query.pickupLocationFilterType}
              setLocationFilterType={function (_locationFilterType) {
                setQuery({
                  ...query,
                  pickupLocationFilterType: _locationFilterType,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Pickup City">
            <Input
              value={query.pickupCity ?? undefined}
              onChange={function (e) {
                setQuery({
                  ...query,
                  pickupCity: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Delivery Location">
            <LocationDropdown
              locationFilterType={query.deliveryLocationFilterType}
              setLocationFilterType={function (_locationFilterType) {
                setQuery({
                  ...query,
                  deliveryLocationFilterType: _locationFilterType,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Delivery City">
            <Input
              value={query.deliveryCity ?? undefined}
              onChange={function (e) {
                setQuery({
                  ...query,
                  deliveryCity: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Include Only Booked?">
            <Select
              value={query.bookingStatus}
              style={{ width: 220 }}
              onChange={function (_bookingStatus) {
                setQuery({
                  ...query,
                  bookingStatus: _bookingStatus,
                });
              }}
            >
              <Select.Option value={ApolloMapQueryBookingStatus.Booked}>
                Booked
              </Select.Option>
              ;
              <Select.Option value={ApolloMapQueryBookingStatus.NotBooked}>
                Just Quoted
              </Select.Option>
              ;
              <Select.Option value={ApolloMapQueryBookingStatus.Any}>
                Booked &amp; Quoted
              </Select.Option>
              ;
            </Select>
          </Form.Item>
          <Form.Item label="Company">
            <CompaniesDropdown
              companyId={query.companyId}
              setCompanyId={function (_companyId) {
                setQuery({
                  ...query,
                  companyId: _companyId,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<>In Carrier Coverage Area?</>}>
            <CarrierFilter
              carrierIdentifier={query.inCarrierCoverageArea}
              onFilter={function (_inCarrierCoverageArea) {
                setQuery({
                  ...query,
                  inCarrierCoverageArea: _inCarrierCoverageArea,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<>Compare with Carrier Rates</>}>
            <CarrierFilter
              carrierIdentifier={query.compareWithCarrier}
              onFilter={function (_compareWithCarrier) {
                setQuery({
                  ...query,
                  compareWithCarrier: _compareWithCarrier,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <>Booked/Best Carrier</>
                <Spacer width={4} />
                <InfoBubble
                  overlay={
                    <>
                      This will only show either booked shipments were it was
                      booked with this carrier, or quoted shipments where this
                      carrier was the cheapest
                    </>
                  }
                />
              </>
            }
          >
            <CarrierFilter
              carrierIdentifier={query.carrierIdentifier}
              onFilter={function (_carrierIdentifier) {
                setQuery({
                  ...query,
                  carrierIdentifier: _carrierIdentifier,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Time Period">
            <DateRangePicker
              startDate={query.startDate}
              endDate={query.endDate}
              onClear={function () {
                setQuery({
                  ...query,
                  startDate: undefined,
                  endDate: undefined,
                });
              }}
              onChange={function (startDate, endDate) {
                console.log(`#### date changed to `, { startDate, endDate });
                setQuery({
                  ...query,
                  startDate: startDate,
                  endDate: endDate,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Shipment Weight">
            <HorizontalStack>
              <div>Min:</div>
              <Spacer width={8} />
              <InputNum
                value={props.query.minimumShipmentWeight}
                onChange={function (newValue) {
                  setQuery({
                    ...props.query,
                    minimumShipmentWeight: newValue,
                  });
                }}
              />

              <Spacer width={8} />
              <div>Max:</div>
              <Spacer width={8} />
              <InputNum
                value={props.query.maximumShipmentWeight}
                onChange={function (newValue) {
                  setQuery({
                    ...props.query,
                    maximumShipmentWeight: newValue,
                  });
                }}
              />
            </HorizontalStack>
          </Form.Item>
          <Form.Item label="Handling Units">
            <HorizontalStack>
              <div>Min:</div>
              <Spacer width={8} />
              <InputNum
                value={props.query.minimumNumberHandlingUnits}
                onChange={function (newValue) {
                  setQuery({
                    ...props.query,
                    minimumNumberHandlingUnits: newValue,
                  });
                }}
              />

              <Spacer width={8} />
              <div>Max:</div>
              <Spacer width={8} />
              <InputNum
                value={props.query.maximumNumberHandlingUnits}
                onChange={function (newValue) {
                  setQuery({
                    ...props.query,
                    maximumNumberHandlingUnits: newValue,
                  });
                }}
              />
            </HorizontalStack>
          </Form.Item>
          <Form.Item label="Volume">
            <HorizontalStack>
              <div>Min:</div>
              <Spacer width={8} />
              <InputNum
                value={props.query.minimumVolume}
                onChange={function (newValue) {
                  setQuery({
                    ...props.query,
                    minimumVolume: newValue,
                  });
                }}
              />

              <Spacer width={8} />
              <div>Max:</div>
              <Spacer width={8} />
              <InputNum
                value={props.query.maximumVolume}
                onChange={function (newValue) {
                  setQuery({
                    ...props.query,
                    maximumVolume: newValue,
                  });
                }}
              />
            </HorizontalStack>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="link">
        Modify Filter ({total})
      </Button>
    </>
  );
}
