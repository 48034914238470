/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeGraphPeriodData } from './TimeGraphPeriodData';
import {
    TimeGraphPeriodDataFromJSON,
    TimeGraphPeriodDataFromJSONTyped,
    TimeGraphPeriodDataToJSON,
} from './TimeGraphPeriodData';

/**
 * 
 * @export
 * @interface TimeGraphData
 */
export interface TimeGraphData {
    /**
     * 
     * @type {Array<TimeGraphPeriodData>}
     * @memberof TimeGraphData
     */
    yearly: Array<TimeGraphPeriodData>;
    /**
     * 
     * @type {Array<TimeGraphPeriodData>}
     * @memberof TimeGraphData
     */
    quarterly: Array<TimeGraphPeriodData>;
    /**
     * 
     * @type {Array<TimeGraphPeriodData>}
     * @memberof TimeGraphData
     */
    monthly: Array<TimeGraphPeriodData>;
}

/**
 * Check if a given object implements the TimeGraphData interface.
 */
export function instanceOfTimeGraphData(value: object): boolean {
    if (!('yearly' in value)) return false;
    if (!('quarterly' in value)) return false;
    if (!('monthly' in value)) return false;
    return true;
}

export function TimeGraphDataFromJSON(json: any): TimeGraphData {
    return TimeGraphDataFromJSONTyped(json, false);
}

export function TimeGraphDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeGraphData {
    if (json == null) {
        return json;
    }
    return {
        
        'yearly': ((json['yearly'] as Array<any>).map(TimeGraphPeriodDataFromJSON)),
        'quarterly': ((json['quarterly'] as Array<any>).map(TimeGraphPeriodDataFromJSON)),
        'monthly': ((json['monthly'] as Array<any>).map(TimeGraphPeriodDataFromJSON)),
    };
}

export function TimeGraphDataToJSON(value?: TimeGraphData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'yearly': ((value['yearly'] as Array<any>).map(TimeGraphPeriodDataToJSON)),
        'quarterly': ((value['quarterly'] as Array<any>).map(TimeGraphPeriodDataToJSON)),
        'monthly': ((value['monthly'] as Array<any>).map(TimeGraphPeriodDataToJSON)),
    };
}

