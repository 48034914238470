/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierServicePair } from './CarrierServicePair';
import {
    CarrierServicePairFromJSON,
    CarrierServicePairFromJSONTyped,
    CarrierServicePairToJSON,
} from './CarrierServicePair';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface RequestAdditionalQuotesBody
 */
export interface RequestAdditionalQuotesBody {
    /**
     * 
     * @type {string}
     * @memberof RequestAdditionalQuotesBody
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestAdditionalQuotesBody
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof RequestAdditionalQuotesBody
     */
    quoteGroupName: string;
    /**
     * 
     * @type {Location}
     * @memberof RequestAdditionalQuotesBody
     */
    pickupLocation: Location;
    /**
     * 
     * @type {Location}
     * @memberof RequestAdditionalQuotesBody
     */
    deliveryLocation: Location;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof RequestAdditionalQuotesBody
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAdditionalQuotesBody
     */
    allCarriers: boolean;
    /**
     * 
     * @type {Array<CarrierServicePair>}
     * @memberof RequestAdditionalQuotesBody
     */
    selectedCarriers: Array<CarrierServicePair>;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAdditionalQuotesBody
     */
    disableStandardLimits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAdditionalQuotesBody
     */
    addInsuranceToShipment: boolean;
    /**
     * 
     * @type {number}
     * @memberof RequestAdditionalQuotesBody
     */
    insuranceAmount: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof RequestAdditionalQuotesBody
     */
    insuranceCurrency: CurrencyCode;
}

/**
 * Check if a given object implements the RequestAdditionalQuotesBody interface.
 */
export function instanceOfRequestAdditionalQuotesBody(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('quoteGroupName' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('lineItems' in value)) return false;
    if (!('allCarriers' in value)) return false;
    if (!('selectedCarriers' in value)) return false;
    if (!('disableStandardLimits' in value)) return false;
    if (!('addInsuranceToShipment' in value)) return false;
    if (!('insuranceAmount' in value)) return false;
    if (!('insuranceCurrency' in value)) return false;
    return true;
}

export function RequestAdditionalQuotesBodyFromJSON(json: any): RequestAdditionalQuotesBody {
    return RequestAdditionalQuotesBodyFromJSONTyped(json, false);
}

export function RequestAdditionalQuotesBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestAdditionalQuotesBody {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'pickupDate': json['pickupDate'],
        'quoteGroupName': json['quoteGroupName'],
        'pickupLocation': LocationFromJSON(json['pickupLocation']),
        'deliveryLocation': LocationFromJSON(json['deliveryLocation']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'allCarriers': json['allCarriers'],
        'selectedCarriers': ((json['selectedCarriers'] as Array<any>).map(CarrierServicePairFromJSON)),
        'disableStandardLimits': json['disableStandardLimits'],
        'addInsuranceToShipment': json['addInsuranceToShipment'],
        'insuranceAmount': json['insuranceAmount'],
        'insuranceCurrency': CurrencyCodeFromJSON(json['insuranceCurrency']),
    };
}

export function RequestAdditionalQuotesBodyToJSON(value?: RequestAdditionalQuotesBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'pickupDate': value['pickupDate'],
        'quoteGroupName': value['quoteGroupName'],
        'pickupLocation': LocationToJSON(value['pickupLocation']),
        'deliveryLocation': LocationToJSON(value['deliveryLocation']),
        'lineItems': ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'allCarriers': value['allCarriers'],
        'selectedCarriers': ((value['selectedCarriers'] as Array<any>).map(CarrierServicePairToJSON)),
        'disableStandardLimits': value['disableStandardLimits'],
        'addInsuranceToShipment': value['addInsuranceToShipment'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyCodeToJSON(value['insuranceCurrency']),
    };
}

