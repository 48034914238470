/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HistoricLineItem
 */
export interface HistoricLineItem {
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    proNumber: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    pickupCityState: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    deliveryCityState: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof HistoricLineItem
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricLineItem
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricLineItem
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    handlingUnitType: string;
    /**
     * 
     * @type {number}
     * @memberof HistoricLineItem
     */
    numberHandlingUnits: number;
    /**
     * 
     * @type {number}
     * @memberof HistoricLineItem
     */
    weightPerHandlingUnit: number;
    /**
     * 
     * @type {boolean}
     * @memberof HistoricLineItem
     */
    stackable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HistoricLineItem
     */
    dangerous: boolean;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    freightClass: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricLineItem
     */
    nmfcCode: string;
}

/**
 * Check if a given object implements the HistoricLineItem interface.
 */
export function instanceOfHistoricLineItem(value: object): boolean {
    if (!('pickupDate' in value)) return false;
    if (!('proNumber' in value)) return false;
    if (!('carrierIdentifier' in value)) return false;
    if (!('shipmentId' in value)) return false;
    if (!('pickupCityState' in value)) return false;
    if (!('deliveryCityState' in value)) return false;
    if (!('description' in value)) return false;
    if (!('length' in value)) return false;
    if (!('width' in value)) return false;
    if (!('height' in value)) return false;
    if (!('handlingUnitType' in value)) return false;
    if (!('numberHandlingUnits' in value)) return false;
    if (!('weightPerHandlingUnit' in value)) return false;
    if (!('stackable' in value)) return false;
    if (!('dangerous' in value)) return false;
    if (!('freightClass' in value)) return false;
    if (!('nmfcCode' in value)) return false;
    return true;
}

export function HistoricLineItemFromJSON(json: any): HistoricLineItem {
    return HistoricLineItemFromJSONTyped(json, false);
}

export function HistoricLineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricLineItem {
    if (json == null) {
        return json;
    }
    return {
        
        'pickupDate': json['pickupDate'],
        'proNumber': json['proNumber'],
        'carrierIdentifier': json['carrierIdentifier'],
        'shipmentId': json['shipmentId'],
        'pickupCityState': json['pickupCityState'],
        'deliveryCityState': json['deliveryCityState'],
        'description': json['description'],
        'length': json['length'],
        'width': json['width'],
        'height': json['height'],
        'handlingUnitType': json['handlingUnitType'],
        'numberHandlingUnits': json['numberHandlingUnits'],
        'weightPerHandlingUnit': json['weightPerHandlingUnit'],
        'stackable': json['stackable'],
        'dangerous': json['dangerous'],
        'freightClass': json['freightClass'],
        'nmfcCode': json['nmfcCode'],
    };
}

export function HistoricLineItemToJSON(value?: HistoricLineItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pickupDate': value['pickupDate'],
        'proNumber': value['proNumber'],
        'carrierIdentifier': value['carrierIdentifier'],
        'shipmentId': value['shipmentId'],
        'pickupCityState': value['pickupCityState'],
        'deliveryCityState': value['deliveryCityState'],
        'description': value['description'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'handlingUnitType': value['handlingUnitType'],
        'numberHandlingUnits': value['numberHandlingUnits'],
        'weightPerHandlingUnit': value['weightPerHandlingUnit'],
        'stackable': value['stackable'],
        'dangerous': value['dangerous'],
        'freightClass': value['freightClass'],
        'nmfcCode': value['nmfcCode'],
    };
}

