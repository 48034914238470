/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface AddQuoteBody
 */
export interface AddQuoteBody {
    /**
     * 
     * @type {string}
     * @memberof AddQuoteBody
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof AddQuoteBody
     */
    quoteGroupName: string;
    /**
     * 
     * @type {Location}
     * @memberof AddQuoteBody
     */
    pickupLocation: Location;
    /**
     * 
     * @type {Location}
     * @memberof AddQuoteBody
     */
    deliveryLocation: Location;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof AddQuoteBody
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof AddQuoteBody
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof AddQuoteBody
     */
    serviceIdentifier: string;
    /**
     * 
     * @type {number}
     * @memberof AddQuoteBody
     */
    carrierPriceOriginalCurrency: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof AddQuoteBody
     */
    originalCurrency: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof AddQuoteBody
     */
    carrierQuoteReference: string;
    /**
     * 
     * @type {number}
     * @memberof AddQuoteBody
     */
    transitBusinessDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddQuoteBody
     */
    customerVisible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddQuoteBody
     */
    includesTax: boolean;
}

/**
 * Check if a given object implements the AddQuoteBody interface.
 */
export function instanceOfAddQuoteBody(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('quoteGroupName' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('lineItems' in value)) return false;
    if (!('carrierIdentifier' in value)) return false;
    if (!('serviceIdentifier' in value)) return false;
    if (!('carrierPriceOriginalCurrency' in value)) return false;
    if (!('originalCurrency' in value)) return false;
    if (!('carrierQuoteReference' in value)) return false;
    if (!('customerVisible' in value)) return false;
    if (!('includesTax' in value)) return false;
    return true;
}

export function AddQuoteBodyFromJSON(json: any): AddQuoteBody {
    return AddQuoteBodyFromJSONTyped(json, false);
}

export function AddQuoteBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddQuoteBody {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'quoteGroupName': json['quoteGroupName'],
        'pickupLocation': LocationFromJSON(json['pickupLocation']),
        'deliveryLocation': LocationFromJSON(json['deliveryLocation']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'carrierIdentifier': json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'],
        'carrierPriceOriginalCurrency': json['carrierPriceOriginalCurrency'],
        'originalCurrency': CurrencyCodeFromJSON(json['originalCurrency']),
        'carrierQuoteReference': json['carrierQuoteReference'],
        'transitBusinessDays': json['transitBusinessDays'] == null ? undefined : json['transitBusinessDays'],
        'customerVisible': json['customerVisible'],
        'includesTax': json['includesTax'],
    };
}

export function AddQuoteBodyToJSON(value?: AddQuoteBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'quoteGroupName': value['quoteGroupName'],
        'pickupLocation': LocationToJSON(value['pickupLocation']),
        'deliveryLocation': LocationToJSON(value['deliveryLocation']),
        'lineItems': ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
        'carrierPriceOriginalCurrency': value['carrierPriceOriginalCurrency'],
        'originalCurrency': CurrencyCodeToJSON(value['originalCurrency']),
        'carrierQuoteReference': value['carrierQuoteReference'],
        'transitBusinessDays': value['transitBusinessDays'],
        'customerVisible': value['customerVisible'],
        'includesTax': value['includesTax'],
    };
}

