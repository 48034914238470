import { Form, Switch, Typography } from "antd";
import { useState } from "react";
import { useAmyApi } from "../Apis/Apis";
import Colors from "../Components/Colors";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import { ShipmentsTable } from "../Components/ShipmentsTable";
import Stack from "../Components/Stack";
import { serviceProperties } from "../Helpers/serviceProperties";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import {
  CompanyShipmentQuote,
  UpcomingAmyShipmentTasks,
} from "../generated-openapi-client";

const { Title } = Typography;

interface SectionProps {
  title: string;
  shipments: CompanyShipmentQuote[];
  addPickupNumberColumn?: boolean;
  addOriginalPickupDateColumn?: boolean;
  hideQuoteAmount?: boolean;
  hidePickupDateColumn?: boolean;
  addEstimatedDeliveryDateColumn?: boolean;
  onlyShowFlagged: boolean;
}

function Section(props: SectionProps) {
  function applyFlaggedFilter(csq: CompanyShipmentQuote) {
    if (props.onlyShowFlagged === false) {
      return true;
    } else {
      const carrierIdentifier = csq.quote.carrierIdentifier!!;
      const serviceIdentifier = csq.quote.serviceIdentifier!!;

      const properties = serviceProperties(
        carrierIdentifier,
        serviceIdentifier
      );

      return properties.serviceProperties.flagged;
    }
  }

  const filteredShipments = props.shipments.filter(applyFlaggedFilter);

  if (filteredShipments.length === 0) {
    return <></>;
  }

  return (
    <>
      <Title level={4}>{props.title}</Title>
      <ShipmentsTable
        shipments={filteredShipments}
        addPickupNumberColumn={props.addPickupNumberColumn}
        addOriginalPickupDateColumn={props.addOriginalPickupDateColumn}
        hideQuoteAmount={props.hideQuoteAmount}
        hidePickupDateColumn={props.hidePickupDateColumn}
        addEstimatedDeliveryDateColumn={props.addEstimatedDeliveryDateColumn}
      />
      <Spacer height={32} />
    </>
  );
}

export function UpcomingAmyShipmentTasksScreen() {
  const createAmyApi = useAmyApi();

  const [summary, setSummary] = useState<UpcomingAmyShipmentTasks | undefined>(
    undefined
  );

  const [onlyShowFlagged, setOnlyShowFlagged] = useState(true);

  async function refresh() {
    const amyApi = await createAmyApi();
    const response = await amyApi.upcomingAmyShipmentTasks();
    setSummary(response);
  }

  useOnce(function () {
    refresh();
  });

  if (summary === undefined) {
    return <Loading></Loading>;
  }
  return (
    <>
      <Page
        title={
          <Stack align="left">
            <div>Upcoming Amy Shipment Tasks</div>
            <Spacer height={8} />
            <div style={{ fontSize: "16px", color: Colors.LightText }}>
              These are the shipments that Amy will ask for updates for on the
              next update.
            </div>
          </Stack>
        }
        tags={[]}
        stats={[]}
        extra={[
          <Form.Item label="Only show flagged?">
            <Switch checked={onlyShowFlagged} onChange={setOnlyShowFlagged} />
          </Form.Item>,
        ]}
      >
        <Stack align="left" width="100%">
          <Spacer height={16} />

          <Section
            title="Missed Pickups"
            shipments={summary.missedPickupsShipments}
            addPickupNumberColumn
            addOriginalPickupDateColumn
            hideQuoteAmount
            onlyShowFlagged={onlyShowFlagged}
          />
          <Section
            title="Missed Pickups (Probably Multiple Times)"
            shipments={summary.missedPickupsShipments.filter(function (s) {
              return s.shipment.pickupDate !== s.shipment.originalPickupDate;
            })}
            addPickupNumberColumn
            addOriginalPickupDateColumn
            hideQuoteAmount
            onlyShowFlagged={false}
          />
          <Section
            title="Missed Deliveries"
            shipments={summary.missedDeliveryShipments}
            hidePickupDateColumn
            addEstimatedDeliveryDateColumn
            onlyShowFlagged={onlyShowFlagged}
          />
          <Section
            title="Expected Delivery is in the Past"
            shipments={summary.expectedDeliveryInPastShipments}
            hidePickupDateColumn
            addEstimatedDeliveryDateColumn
            onlyShowFlagged={onlyShowFlagged}
          />
          <Section
            title="Tracking not Updated"
            shipments={summary.trackingNotUpdatedShipents}
            hidePickupDateColumn
            addEstimatedDeliveryDateColumn
            onlyShowFlagged={onlyShowFlagged}
          />
          <Section
            title="On hold shipments (not sending emails about)"
            shipments={summary.onHoldShipments}
            hidePickupDateColumn
            addEstimatedDeliveryDateColumn
            onlyShowFlagged={onlyShowFlagged}
          />
          <Section
            title="Booking Requested (not sending emails about) - This was not properly booked!"
            shipments={summary.bookingRequestedShipments}
            addEstimatedDeliveryDateColumn
            onlyShowFlagged={onlyShowFlagged}
          />

          <Section
            title="Booking Failed (not sending emails about) - This was not properly booked!"
            shipments={summary.bookingFailedShipments}
            onlyShowFlagged={onlyShowFlagged}
          />
          <Section
            title="Booked shipments missing pickup number (not sending emails about) - Suspicious!"
            shipments={summary.bookedShipmentsMissingPickupNumber}
            onlyShowFlagged={onlyShowFlagged}
          />
        </Stack>
      </Page>
    </>
  );
}
