/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceAuditTurn = {
    TheirTurn: 'their_turn',
    OurTurn: 'our_turn'
} as const;
export type InvoiceAuditTurn = typeof InvoiceAuditTurn[keyof typeof InvoiceAuditTurn];


export function instanceOfInvoiceAuditTurn(value: any): boolean {
    return Object.values(InvoiceAuditTurn).includes(value);
}

export function InvoiceAuditTurnFromJSON(json: any): InvoiceAuditTurn {
    return InvoiceAuditTurnFromJSONTyped(json, false);
}

export function InvoiceAuditTurnFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditTurn {
    return json as InvoiceAuditTurn;
}

export function InvoiceAuditTurnToJSON(value?: InvoiceAuditTurn | null): any {
    return value as any;
}

