/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CarrierInfoResponse,
  CarrierNameAndIdentifier,
  CarrierServiceInfo,
  ServiceProperties,
  UpcomingAmyShipmentTasks,
} from '../models/index';
import {
    CarrierInfoResponseFromJSON,
    CarrierInfoResponseToJSON,
    CarrierNameAndIdentifierFromJSON,
    CarrierNameAndIdentifierToJSON,
    CarrierServiceInfoFromJSON,
    CarrierServiceInfoToJSON,
    ServicePropertiesFromJSON,
    ServicePropertiesToJSON,
    UpcomingAmyShipmentTasksFromJSON,
    UpcomingAmyShipmentTasksToJSON,
} from '../models/index';

export interface GetCarrierInfoRequest {
    carrierIdentifier: string;
}

export interface GetServiceInfoRequest {
    carrierIdentifier: string;
    serviceIdentifier: string;
}

/**
 * 
 */
export class CarriersApi extends runtime.BaseAPI {

    /**
     */
    async getCarrierInfoRaw(requestParameters: GetCarrierInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CarrierInfoResponse>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling getCarrierInfo().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carriers/get-carrier-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CarrierInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async getCarrierInfo(requestParameters: GetCarrierInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CarrierInfoResponse> {
        const response = await this.getCarrierInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCarrierServicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierServiceInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carriers/get-carrier-services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierServiceInfoFromJSON));
    }

    /**
     */
    async getCarrierServices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierServiceInfo>> {
        const response = await this.getCarrierServicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getServiceInfoRaw(requestParameters: GetServiceInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceProperties>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling getServiceInfo().'
            );
        }

        if (requestParameters['serviceIdentifier'] == null) {
            throw new runtime.RequiredError(
                'serviceIdentifier',
                'Required parameter "serviceIdentifier" was null or undefined when calling getServiceInfo().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        if (requestParameters['serviceIdentifier'] != null) {
            queryParameters['serviceIdentifier'] = requestParameters['serviceIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carriers/get-service-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePropertiesFromJSON(jsonValue));
    }

    /**
     */
    async getServiceInfo(requestParameters: GetServiceInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceProperties> {
        const response = await this.getServiceInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listCarriersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierNameAndIdentifier>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carriers/list-carriers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierNameAndIdentifierFromJSON));
    }

    /**
     */
    async listCarriers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierNameAndIdentifier>> {
        const response = await this.listCarriersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async summarizeExpectedAmyTasksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpcomingAmyShipmentTasks>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carriers/summarize-expected-amy-tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpcomingAmyShipmentTasksFromJSON(jsonValue));
    }

    /**
     */
    async summarizeExpectedAmyTasks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpcomingAmyShipmentTasks> {
        const response = await this.summarizeExpectedAmyTasksRaw(initOverrides);
        return await response.value();
    }

}
