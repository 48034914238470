import { Form, Menu, message, Modal, Select } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { OpenAuditLine } from "../../generated-openapi-client";

interface AssignInvoiceAuditMenuItemProps {
  openAuditLine: OpenAuditLine;
  refresh: () => Promise<void>;
}

interface AssigneeDropdownProps {
  value: string | undefined;
  setValue: (_: string) => void;
  allowClear?: boolean;
}

export const invoiceAssignees = [
  { email: "chris@freightsimple.com", name: "Chris", color: "purple" },
  { email: "andrea@freightsimple.com", name: "Andrea", color: "green" },
];

export function nameForInvoiceAssigneeAddress(email: string): string {
  return invoiceAssignees.find((ia) => ia.email === email)!!.name;
}

export function colorForInvoiceAssigneeAddress(email: string): string {
  return invoiceAssignees.find((ia) => ia.email === email)!!.color;
}

export function AssigneeDropdown(props: AssigneeDropdownProps) {
  return (
    <Select
      value={props.value}
      onChange={props.setValue}
      style={{ width: "250px" }}
      allowClear={props.allowClear}
    >
      {invoiceAssignees.map(function (ia) {
        return <Select.Option value={ia.email}>{ia.name}</Select.Option>;
      })}
    </Select>
  );
}

export function AssignInvoiceAuditMenuItem(
  props: AssignInvoiceAuditMenuItemProps
) {
  const title = "Assign";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignee, setAssignee] = useState(
    props.openAuditLine.csq.shipment.invoiceAuditAssignee
  );
  const createShipmentsApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);
  const shipmentId = props.openAuditLine.csq.shipment.shipmentId!!;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (assignee === undefined) {
      message.warn("No assignee picked");
      return;
    }
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.setInvoiceAuditAssignee({
        shipmentId,
        invoiceAuditAssignee: assignee,
      });
      await props.refresh();
      setIsModalVisible(false);
    } catch (e) {
      message.error("oops. Something went wrong");
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Invoice Audit Assignee">
            <AssigneeDropdown value={assignee} setValue={setAssignee} />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>{title}</Menu.Item>
    </>
  );
}
