import { Button, Modal, Spin, Typography } from "antd";
import { useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { useOnce } from "../../Hooks/useOnce";
import Spacer from "../../Spacer";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client";
import { CarrierRequestAudit } from "../../generated-openapi-client/models/CarrierRequestAudit";
import { ViewAuditButton } from "../CarrierRequestAuditsScreen";

const { Text } = Typography;

interface ViewCarrierRequestAuditButtonProps {
  shipmentData: ViewShipmentDataForApollo;
}

interface CarrierRequestAuditsTableProps {
  carrierRequestAudits: Array<CarrierRequestAudit>;
}

export function CarrierRequestAuditsTable(
  props: CarrierRequestAuditsTableProps
) {
  const columns: DataTableColumn<CarrierRequestAudit>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Audit Type",
      render: (o) => <div>{o.auditType}</div>,
    },
    {
      title: "Subcommand",
      render: (o) => <div>{o.subcommand}</div>,
    },

    {
      title: "Actions",
      render: function (o) {
        return (
          <ButtonRow>
            <ViewAuditButton audit={o.requestString!!}>
              View Request
            </ViewAuditButton>
            <Spacer width={8} />
            <ViewAuditButton audit={o.responseString!!}>
              View Response
            </ViewAuditButton>
          </ButtonRow>
        );
      },
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.carrierRequestAudits}
    />
  );
}

export function ViewCarrierRequestAuditButton(
  props: ViewCarrierRequestAuditButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [carrierRequestAudits, setCarrierRequestAudits] = useState<
    Array<CarrierRequestAudit> | undefined
  >();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  useOnce(async function () {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const shipmentId = props.shipmentData.shipmentId;
    const response =
      await carrierInvoiceApi.getCarrierRequestAuditForSelectedQuote({
        shipmentId,
      });

    setCarrierRequestAudits(response);
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Carrier Request Audits"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
      >
        <Text>
          These are the carrier request audits captured when requesting a quote
        </Text>
        <Spacer height={32} />
        {!carrierRequestAudits && <Spin />}
        {carrierRequestAudits && (
          <>
            <CarrierRequestAuditsTable
              carrierRequestAudits={carrierRequestAudits}
            />
          </>
        )}
      </Modal>
      <Button onClick={showModal}>Audits</Button>
    </>
  );
}
