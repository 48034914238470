import { message } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItemWithTextArea } from "../../Components/ConfirmButtonWithTextArea";
import {
  CustomerInvoice,
  CustomerInvoiceState,
} from "../../generated-openapi-client";

interface MarkCustomerInvoiceSettledMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function MarkCustomerInvoiceSettledMenuItem(
  props: MarkCustomerInvoiceSettledMenuItemProps
) {
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;

  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function handleOk(reason: string) {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.markInvoiceSettled({
        customerInvoiceId,
        reason,
      });
      await props.onRefresh();
      message.success("Invoice marked settled");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    // This is needed becasue the invoice pdf doesn't reload, so would be stale otherwise
    props.onCloseParent();
  }

  if (
    props.customerInvoice.customerInvoiceState !==
    CustomerInvoiceState.SettlementPending
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItemWithTextArea
      onConfirm={handleOk}
      placeholder="Enter reason"
      question={
        "Are you sure you want to mark the invoice as settled? We should only do this when we have the payment in hand (ie. received the cheque or etransfer)"
      }
      okText={"Yes, we have the payment"}
      tooltip={
        "Use this once we have actually received the payment. This will then regenerate the customer invoice showing the bill is paid"
      }
    >
      Mark Customer Invoice Settled
    </ConfirmMenuItemWithTextArea>
  );
}
