import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import {
  DataTable,
  DataTableColumn,
  DataTableCsvColumn,
} from "../../Components/DataTable";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import { ShipmentReport } from "../../generated-openapi-client";

interface MapScreenDataTableProps {
  data: ShipmentReport[];
}

export function MapScreenDataTable(props: MapScreenDataTableProps) {
  const columns: DataTableColumn<ShipmentReport>[] = [];
  columns.push({
    title: "Booked",
    render: (o) => <>{o.booked ? "✅" : "❌"}</>,
  });
  columns.push({
    title: "Carrier",
    render: (o) => (
      <>
        {o.booked && o.bookedQuote?.carrierIdentifier && (
          <CarrierLogo
            carrierIdentifier={o.bookedQuote?.carrierIdentifier}
            brokeredCarrierIdentifier={o.bookedQuote.brokeredCarrierIdentifier}
            width={40}
            height={30}
          />
        )}
        {!o.booked && o.lowestQuote?.carrierIdentifier && (
          <CarrierLogo
            carrierIdentifier={o.lowestQuote?.carrierIdentifier}
            brokeredCarrierIdentifier={o.lowestQuote.brokeredCarrierIdentifier}
            width={40}
            height={30}
          />
        )}
      </>
    ),
  });
  columns.push({
    title: "Best Price",
    render: (o) => <div>${o.lowestQuote?.priceCad ?? "-"}</div>,
  });
  // if (props.data[0].comparisonCarrier !== undefined) {
  //   columns.push({
  //     title: "Comparison",
  //     render: (o) => (
  //       <>
  //         {o.comparisonCarrier && (
  //           <CarrierLogo
  //             carrierIdentifier={o.comparisonCarrier}
  //             width={40}
  //             height={30}
  //           />
  //         )}
  //       </>
  //     ),
  //   });
  //   columns.push({
  //     title: "Price",
  //     render: (o) => (
  //       <>
  //         {o.comparisonPrice && o.comparisonPrice !== "-" && (
  //           <div>${o.comparisonPrice}</div>
  //         )}
  //         {(!o.comparisonPrice || o.comparisonPrice === "-") && <div>-</div>}
  //       </>
  //     ),
  //   });
  //   columns.push({
  //     title: "Delta",
  //     render: (o) => (
  //       <>
  //         {o.comparisonPriceDelta && o.comparisonPriceDelta !== "-" && (
  //           <Dollar colored invert>
  //             {-parseFloat(o.comparisonPriceDelta)}
  //           </Dollar>
  //         )}
  //         {(!o.comparisonPriceDelta || o.comparisonPriceDelta === "-") && (
  //           <div>-</div>
  //         )}
  //       </>
  //     ),
  //   });
  //   columns.push({
  //     title: "Percentage",
  //     render: (o) => (
  //       <>
  //         {o.comparisonPercentageDelta && o.comparisonPercentageDelta !== "-" && (
  //           <Percentage colored invert>
  //             {-parseFloat(o.comparisonPercentageDelta)}
  //           </Percentage>
  //         )}
  //         {(!o.comparisonPercentageDelta ||
  //           o.comparisonPercentageDelta === "-") && <div>-</div>}
  //       </>
  //     ),
  //   });
  // }
  columns.push({
    title: "Company",
    render: (o) => <div>{o.companyName}</div>,
  });
  columns.push({
    title: "Pickup",
    render: (o) => (
      <div>
        {o.pickupCity}, {o.pickupState}
      </div>
    ),
  });
  columns.push({
    title: "Delivery",
    render: (o) => (
      <div>
        {o.deliveryCity}, {o.deliveryState}
      </div>
    ),
  });
  columns.push({
    title: "Weight (lb)",
    render: (o) => <div>{o.totalWeight}</div>,
  });
  columns.push({
    title: "Volume (cuft)",
    render: (o) => <div>{o.totalVolume!!}cuft</div>,
  });
  columns.push({
    title: "Density (pcf)",
    render: (o) => <div>{o.density!!.toFixed(1)}</div>,
  });
  columns.push({
    title: "Actions",
    render: function (o) {
      return (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!!} />
        </ButtonRow>
      );
    },
  });

  const csvColumns: DataTableCsvColumn<ShipmentReport>[] = [
    {
      title: "Shipment Id",
      render: (o) => o.shipmentId,
    },
    {
      title: "Company Name",
      render: (o) => o.companyName,
    },
    {
      title: "Booked",
      render: (o) => o.booked,
    },
    {
      title: "Pickup Date",
      render: (o) => o.pickupDateDescription,
    },
    {
      title: "Pickup City",
      render: (o) => o.pickupCity,
    },
    {
      title: "Pickup State",
      render: (o) => o.pickupState,
    },
    {
      title: "Delivery City",
      render: (o) => o.deliveryCity,
    },
    {
      title: "Delivery State",
      render: (o) => o.deliveryState,
    },
    {
      title: "Total Weight",
      render: (o) => o.totalWeight,
    },
    {
      title: "Total Cubage",
      render: (o) => o.totalVolume,
    },
    {
      title: "Density",
      render: (o) => o.density,
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.data}
      csvColumns={csvColumns}
    />
  );
}
