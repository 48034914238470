import { Form, Input, Menu, message, Modal } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import {
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "../../generated-openapi-client";
import { CustomerInvoice } from "../../generated-openapi-client/models/CustomerInvoice";

interface ChangeCustomerInvoiceDescriptionMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function ChangeCustomerInvoiceDescriptionMenuItem(
  props: ChangeCustomerInvoiceDescriptionMenuItemProps
) {
  const title = "Change Customer Invoice Description";
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [description, setDescription] = useState(
    props.customerInvoice.description!!
  );
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.changeDescription({
        customerInvoiceId,
        description,
      });
      await props.onRefresh();
      message.success("Invoice Description Changed");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
    props.onCloseParent();
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Only issued additional charges can have description modified
  if (
    props.customerInvoice.invoiceType !==
      CustomerInvoiceType.AdditionalCharge ||
    props.customerInvoice.customerInvoiceState !== CustomerInvoiceState.Issued
  ) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        destroyOnClose={true}
        confirmLoading={loading}
        width={800}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Description">
            <Input
              value={description}
              onChange={function (e) {
                setDescription(e.currentTarget.value);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>{title}</Menu.Item>
    </>
  );
}
