/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentsServiceSwitchQuotePaymentResult } from './PaymentsServiceSwitchQuotePaymentResult';
import {
    PaymentsServiceSwitchQuotePaymentResultFromJSON,
    PaymentsServiceSwitchQuotePaymentResultFromJSONTyped,
    PaymentsServiceSwitchQuotePaymentResultToJSON,
} from './PaymentsServiceSwitchQuotePaymentResult';

/**
 * 
 * @export
 * @interface SelectQuoteResult
 */
export interface SelectQuoteResult {
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didMarkShipmentOnHold: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didSendCancelPickupEmail: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectQuoteResult
     */
    updatedPickupDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didSelectNewQuote: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didClearProNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didBookNewPickup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didRegenerateDocuments: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    didHandleCustomsDocuments: boolean;
    /**
     * 
     * @type {PaymentsServiceSwitchQuotePaymentResult}
     * @memberof SelectQuoteResult
     */
    paymentsResult?: PaymentsServiceSwitchQuotePaymentResult;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteResult
     */
    success: boolean;
}

/**
 * Check if a given object implements the SelectQuoteResult interface.
 */
export function instanceOfSelectQuoteResult(value: object): boolean {
    if (!('didMarkShipmentOnHold' in value)) return false;
    if (!('didSendCancelPickupEmail' in value)) return false;
    if (!('didSelectNewQuote' in value)) return false;
    if (!('didClearProNumber' in value)) return false;
    if (!('didBookNewPickup' in value)) return false;
    if (!('didRegenerateDocuments' in value)) return false;
    if (!('didHandleCustomsDocuments' in value)) return false;
    if (!('success' in value)) return false;
    return true;
}

export function SelectQuoteResultFromJSON(json: any): SelectQuoteResult {
    return SelectQuoteResultFromJSONTyped(json, false);
}

export function SelectQuoteResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectQuoteResult {
    if (json == null) {
        return json;
    }
    return {
        
        'didMarkShipmentOnHold': json['didMarkShipmentOnHold'],
        'didSendCancelPickupEmail': json['didSendCancelPickupEmail'],
        'updatedPickupDate': json['updatedPickupDate'] == null ? undefined : json['updatedPickupDate'],
        'didSelectNewQuote': json['didSelectNewQuote'],
        'didClearProNumber': json['didClearProNumber'],
        'didBookNewPickup': json['didBookNewPickup'],
        'didRegenerateDocuments': json['didRegenerateDocuments'],
        'didHandleCustomsDocuments': json['didHandleCustomsDocuments'],
        'paymentsResult': json['paymentsResult'] == null ? undefined : PaymentsServiceSwitchQuotePaymentResultFromJSON(json['paymentsResult']),
        'success': json['success'],
    };
}

export function SelectQuoteResultToJSON(value?: SelectQuoteResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'didMarkShipmentOnHold': value['didMarkShipmentOnHold'],
        'didSendCancelPickupEmail': value['didSendCancelPickupEmail'],
        'updatedPickupDate': value['updatedPickupDate'],
        'didSelectNewQuote': value['didSelectNewQuote'],
        'didClearProNumber': value['didClearProNumber'],
        'didBookNewPickup': value['didBookNewPickup'],
        'didRegenerateDocuments': value['didRegenerateDocuments'],
        'didHandleCustomsDocuments': value['didHandleCustomsDocuments'],
        'paymentsResult': PaymentsServiceSwitchQuotePaymentResultToJSON(value['paymentsResult']),
        'success': value['success'],
    };
}

