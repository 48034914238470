/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectQuoteInput
 */
export interface SelectQuoteInput {
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteInput
     */
    shouldSendCancelPickupEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteInput
     */
    shouldBookNewPickup: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelectQuoteInput
     */
    shouldUpdateCustomerBilling: boolean;
}

/**
 * Check if a given object implements the SelectQuoteInput interface.
 */
export function instanceOfSelectQuoteInput(value: object): boolean {
    if (!('shouldSendCancelPickupEmail' in value)) return false;
    if (!('shouldBookNewPickup' in value)) return false;
    if (!('shouldUpdateCustomerBilling' in value)) return false;
    return true;
}

export function SelectQuoteInputFromJSON(json: any): SelectQuoteInput {
    return SelectQuoteInputFromJSONTyped(json, false);
}

export function SelectQuoteInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectQuoteInput {
    if (json == null) {
        return json;
    }
    return {
        
        'shouldSendCancelPickupEmail': json['shouldSendCancelPickupEmail'],
        'shouldBookNewPickup': json['shouldBookNewPickup'],
        'shouldUpdateCustomerBilling': json['shouldUpdateCustomerBilling'],
    };
}

export function SelectQuoteInputToJSON(value?: SelectQuoteInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shouldSendCancelPickupEmail': value['shouldSendCancelPickupEmail'],
        'shouldBookNewPickup': value['shouldBookNewPickup'],
        'shouldUpdateCustomerBilling': value['shouldUpdateCustomerBilling'],
    };
}

