/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BookShipmentStrategyType = {
    Automatic: 'Automatic',
    Email: 'Email',
    Manual: 'Manual'
} as const;
export type BookShipmentStrategyType = typeof BookShipmentStrategyType[keyof typeof BookShipmentStrategyType];


export function instanceOfBookShipmentStrategyType(value: any): boolean {
    return Object.values(BookShipmentStrategyType).includes(value);
}

export function BookShipmentStrategyTypeFromJSON(json: any): BookShipmentStrategyType {
    return BookShipmentStrategyTypeFromJSONTyped(json, false);
}

export function BookShipmentStrategyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookShipmentStrategyType {
    return json as BookShipmentStrategyType;
}

export function BookShipmentStrategyTypeToJSON(value?: BookShipmentStrategyType | null): any {
    return value as any;
}

