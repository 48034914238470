import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Select, Table, Tag } from "antd";
import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useShipmentsApi } from "../Apis/Apis";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import Colors from "../Components/Colors";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { ShipmentStatusTag } from "../Components/ShipmentStatusTag";
import Stack from "../Components/Stack";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Weight } from "../Components/Weight";
import { emojiForCountry } from "../Helpers/emojiForCountry";
import { isPhone } from "../Helpers/isPhone";
import { safeColumns } from "../Helpers/safeColumns";
import Spacer from "../Spacer";
import { CompanyShipment } from "../generated-openapi-client/models/CompanyShipment";
import { CompanyShipmentQuote } from "../generated-openapi-client/models/CompanyShipmentQuote";
import { Shipment } from "../generated-openapi-client/models/Shipment";
import { ShipmentState } from "../generated-openapi-client/models/ShipmentState";

const { Option } = Select;

interface ShipmentTableProps {
  shipments: CompanyShipmentQuote[];
  companyIdFilter: string | undefined;
  carrierIdentifierFilter: string | undefined;
  includePickupNumbers?: boolean;
}

interface CompanyFilterProps {
  shipments: CompanyShipmentQuote[];
  onFilter: (companyId: string | undefined) => void;
}

function getCompany(csq: CompanyShipmentQuote | CompanyShipment) {
  return { name: csq.company.companyName!!, id: csq.company.companyId!! };
}

function getCompanyNames(
  shipments: CompanyShipmentQuote[]
): Array<{ id: string; name: string }> {
  const companies = [...shipments.map((c) => getCompany(c))];

  const m = new Map<string, string>();
  companies.forEach(function (c) {
    m.set(c.id, c.name);
  });

  console.log(m);

  const results: Array<{ id: string; name: string }> = [];
  m.forEach(function (name, id) {
    results.push({ id, name });
  });

  return results;
}

function CompanyFilter(props: CompanyFilterProps) {
  return (
    <Select
      allowClear
      showSearch
      style={{ width: isPhone ? 170 : 220 }}
      placeholder="Company"
      optionFilterProp="children"
      onSelect={function (companyId: string) {
        props.onFilter(companyId);
      }}
      onClear={function () {
        props.onFilter(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-ignore
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-ignore
        optionA.children
          // @ts-ignore
          .toLowerCase()
          // @ts-ignore
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {getCompanyNames(props.shipments).map(function (c) {
        console.log({ c });
        return <Option value={c.id}>{c.name}</Option>;
      })}
    </Select>
  );
}

interface ShipmentCardProps {
  shipment: CompanyShipmentQuote;
}

function colorForState(state: ShipmentState): string {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return Colors.Gold;
    case ShipmentState.Delivered:
      return Colors.Green;
    case ShipmentState.InTransit:
      return Colors.Blue;
    case ShipmentState.Quoted:
      return Colors.DarkGray;
    default:
      return Colors.Red;
  }
}

function ShipmentCard(props: ShipmentCardProps) {
  const navigate = useNavigate();
  const shipmentId = props.shipment.shipment.shipmentId!!;
  const shipment = props.shipment.shipment;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const pickupAddress = props.shipment.shipment.pickupLocation?.address!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const deliveryAddress = props.shipment.shipment.deliveryLocation?.address!!;
  return (
    <Card
      onClick={function () {
        navigate(`/view-shipment?shipmentId=${shipmentId}`);
      }}
      size="small"
      title={
        <Stack align="left">
          <CarrierLogo
            carrierIdentifier={props.shipment.quote.carrierIdentifier}
            brokeredCarrierIdentifier={
              props.shipment.quote.brokeredCarrierIdentifier
            }
            width={60}
            height={40}
          />
          <strong>{props.shipment.company.companyName}</strong>
        </Stack>
      }
      style={{
        width: 340,
        marginBottom: "16px",
        borderColor: colorForState(shipment.state!!),
        borderWidth: "3px",
      }}
      extra={
        <>
          {shipment.tags?.map((s) => (
            <Tag color="orange">{s}</Tag>
          ))}
          {shipment.problems?.map((s) => (
            <Tag color="magenta">🚩 {s}</Tag>
          ))}
        </>
      }
    >
      <Stack align="left">
        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              width: "50px",
              paddingRight: "4px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            From:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.pickupLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {pickupAddress.city}, {pickupAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>

        <Spacer height={4} />

        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              paddingRight: "4px",
              width: "50px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            To:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.deliveryLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {deliveryAddress.city}, {deliveryAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>

        <Spacer height={4} />

        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              paddingRight: "4px",
              width: "50px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            Pro:
          </div>
          <div style={{ fontWeight: 500 }}>{shipment.proNumber}</div>
        </HorizontalStack>
        {shipment.state === ShipmentState.BookingConfirmed && (
          <>
            <Spacer height={4} />

            <HorizontalStack verticalAlign="middle">
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "4px",
                  width: "50px",
                  fontSize: "8px",
                  color: Colors.LightText,
                }}
              >
                Pickup #:
              </div>
              <div style={{ fontWeight: 500 }}>
                {shipment.carrierPickupNumber}
              </div>
            </HorizontalStack>
          </>
        )}
      </Stack>
    </Card>
  );
}

function filterActiveShipments(
  shipments: CompanyShipmentQuote[],
  companyIdFilter: string | undefined,
  carrierIdentifierFilter: string | undefined
) {
  return shipments
    .filter(function (c) {
      if (companyIdFilter !== undefined && companyIdFilter !== "") {
        return c.company.companyId === companyIdFilter;
      }

      return true;
    })

    .filter(function (c) {
      if (
        carrierIdentifierFilter !== undefined &&
        carrierIdentifierFilter !== ""
      ) {
        return c.quote.carrierIdentifier === carrierIdentifierFilter;
      }

      return true;
    });
}

function ShipmentTable(props: ShipmentTableProps) {
  const shipments = filterActiveShipments(
    props.shipments,
    props.companyIdFilter,
    props.carrierIdentifierFilter
  );

  if (shipments.length === 0) {
    return <></>;
  }

  if (isPhone) {
    return (
      <>
        <Stack align="left">
          {shipments.map(function (s) {
            return <ShipmentCard shipment={s} />;
          })}
        </Stack>
      </>
    );
  }

  return (
    <>
      <Table
        style={{
          width: "100%",
          border: "1px solid #ccc",
        }}
        columns={safeColumns<CompanyShipmentQuote>([
          {
            title: "Booking Company",
            dataIndex: ["company", "companyName"],
            key: "companyName",
            render: function (text, o) {
              return (
                <Stack align="left">
                  <div>{text}</div>
                  <Spacer height={8} />
                  {o.shipment.tags?.map((s) => (
                    <Tag color="orange">{s}</Tag>
                  ))}
                  {o.shipment.problems?.map((s) => (
                    <Tag color="magenta">🚩 {s}</Tag>
                  ))}
                  <ShipmentStatusTag status={o.shipment.state} />
                </Stack>
              );
            },
            width: "230px",
          },

          {
            title: "Carrier",
            dataIndex: ["quote", "carrierIdentifier"],
            key: "carrierIdentifier",
            render: (text, o) => (
              <Stack>
                <CarrierLogo
                  carrierIdentifier={text}
                  brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
                  width={80}
                  height={30}
                />
                <div
                  style={{
                    marginTop: "4px",
                    fontSize: "10px",
                    color: Colors.LightText,
                  }}
                >
                  {o.quote.serviceIdentifier}
                </div>
              </Stack>
            ),
            width: "100px",
          },
          {
            title: props.includePickupNumbers ? "Pickup Number" : "Pro",
            dataIndex: ["shipment", "proNumber"],
            key: "proNumber",
            render: function (text, csq) {
              const proNumber = csq.shipment.proNumber;
              const pickupNumber = csq.shipment.carrierPickupNumber;

              if (props.includePickupNumbers) {
                return <div>{pickupNumber}</div>;
              }
              return <div>{proNumber}</div>;
            },
            width: "100px",
          },
          {
            title: "Pickup Date",
            dataIndex: ["shipment", "pickupDate"],
            key: "pickupDate",
            render: (text) => <div>{text}</div>,
            width: "100px",
          },
          {
            title: "Details",
            dataIndex: ["shipment"],
            key: "companies",
            render: (shipment: Shipment) => (
              <>
                <Stack align="left">
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.pickupLocation?.address?.countryCode!!
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.pickupLocation?.businessName}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.pickupLocation?.address?.city},{" "}
                        {shipment.pickupLocation?.address?.stateOrProvinceCode}
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.deliveryLocation?.address?.countryCode!!
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.deliveryLocation?.businessName}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.deliveryLocation?.address?.city},{" "}
                        {
                          shipment.deliveryLocation?.address
                            ?.stateOrProvinceCode
                        }
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      📦
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.lineItems!![0].description}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.totalHandlingUnits} skids,{" "}
                        <Weight>{shipment.totalWeight!!}</Weight>
                      </div>
                    </Stack>
                  </HorizontalStack>
                </Stack>
              </>
            ),
            width: "200px",
          },
          {
            title: "Actions",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipment.shipmentId",
            render: function (shipmentId) {
              return <ViewShipmentButton shipmentId={shipmentId} />;
            },
            width: "150px",
          },
        ])}
        dataSource={shipments}
        pagination={false}
      />
    </>
  );
}

export function SearchShipmentsScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [shipments, setShipments] = useState<
    CompanyShipmentQuote[] | undefined
  >(undefined);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyIdFilter, setCompanyIdFilter] = useState<string | undefined>();
  const [carrierIdentifierFilter, setCarrierIdentifierFilter] = useState<
    string | undefined
  >();

  async function searchShipments() {
    setShipments(undefined);
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.searchShipments({
        query: searchQuery,
      });
      console.log(`!!!! results`, { response });
      setShipments(response);
    } catch (e: any) {
      setError(e);
    }
    setLoading(false);
  }

  function applyFilter(shipments: CompanyShipmentQuote[]) {
    return filterActiveShipments(
      shipments,
      companyIdFilter,
      carrierIdentifierFilter
    );
  }

  return (
    <>
      <Page
        title="🚚 Search Shipments"
        subtitle="Search any field in all booked shipments - eg. address, business names, contacts, reference numbers, commodity information"
        tags={[]}
        extra={[
          <HorizontalStack align="right" width="100%">
            <Form.Item label="Filter by carrier">
              <CarrierFilter
                onFilter={function (carrierIdentifier) {
                  setCarrierIdentifierFilter(carrierIdentifier);
                }}
                carrierIdentifier={carrierIdentifierFilter}
              />
            </Form.Item>
            <Spacer width={8} />
            {shipments !== undefined && (
              <>
                <Form.Item label="Filter by company">
                  <CompanyFilter
                    shipments={shipments}
                    onFilter={function (companyId) {
                      setCompanyIdFilter(companyId);
                    }}
                  />
                </Form.Item>
                <Spacer width={8} />
              </>
            )}
          </HorizontalStack>,
        ]}
      >
        <PageTitle>🚚 Search Shipments</PageTitle>
        <BrowserView>
          <Spacer height={32} />
        </BrowserView>

        <>
          <HorizontalStack verticalAlign="middle">
            <Form.Item label="Query">
              <Input
                value={searchQuery}
                onChange={function (e) {
                  setSearchQuery(e.target.value);
                }}
                style={{ width: "400px" }}
              />
            </Form.Item>
            <div
              style={{ position: "relative", marginLeft: "32px", top: "-13px" }}
            >
              <Button
                type="primary"
                icon={<SearchOutlined />}
                disabled={loading}
                onClick={searchShipments}
              >
                Search
              </Button>
            </div>
          </HorizontalStack>
          <Spacer height={32} />
          {error !== undefined && <div>❌ Error : {error}</div>}

          {shipments !== undefined && (
            <ShipmentTable
              shipments={applyFilter(shipments)}
              companyIdFilter={companyIdFilter}
              carrierIdentifierFilter={carrierIdentifierFilter}
            />
          )}
          {loading && <Loading />}
        </>
      </Page>
    </>
  );
}
