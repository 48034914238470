/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

export function propertyOf<T>(name: keyof T) {
  return name;
}

/**
 * Creates a function that returns the specified property name of an object.
 *
 * @param  [_Obj] An optional object to use for type inference.
 * @returns   A function that takes a property name and returns the same property name.
 *
 * @example
 * interface Person {
 *   name: string;
 *   age: number;
 *   email: string;
 * }
 *
 * const person: Person = {
 *   name: 'John Doe',
 *   age: 35,
 *   email: 'john.doe@example.com',
 * };
 *
 * const personProperty = propertiesOf(person);
 *
 * console.log(personProperty('name')); // Output: 'name'
 * console.log(personProperty('age')); // Output: 'age'
 * console.log(personProperty('email')); // Output: 'email'
 */
export function propertiesOf<T>(_Obj: T | undefined = undefined) {
  return function propertyOf(name: keyof T) {
    return name;
  };
}
