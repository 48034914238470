import { Button, Popconfirm } from "antd";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { useDisplay } from "../../Hooks/useDisplay";
import { InboundLogSalesCallModal } from "./LogSalesCall/InboundLogSalesCallModal";
import { useCallContext } from "../../Contexts/CallContext";

// We want to either log to a lead or log to an account/contact
export function LogSalesCallButton(props: CompanyTabProps) {
  const display = useDisplay();
  const { callData } = useCallContext();
  return (
    <>
      <InboundLogSalesCallModal
        company={props.data.company}
        salesContacts={props.data.salesContacts}
        onRefresh={props.onRefresh}
        display={display}
      />
      {callData ? (
        <Popconfirm
          title={
            <div>
              <span style={{ fontWeight: "500" }}>
                Are you sure you want to create a different sales call note?
              </span>
              <p>
                If you want to log notes of the current sales call please use
                the button in the lower right corner.
              </p>
            </div>
          }
          onConfirm={display.show}
          okText="Yes"
          cancelText="Cancel"
        >
          <Button>☎️ Log Sales Call</Button>
        </Popconfirm>
      ) : (
        <Button onClick={display.show}>☎️ Log Sales Call</Button>
      )}
    </>
  );
}
