/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameEmailPhone
 */
export interface NameEmailPhone {
    /**
     * 
     * @type {string}
     * @memberof NameEmailPhone
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NameEmailPhone
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof NameEmailPhone
     */
    phone: string;
}

/**
 * Check if a given object implements the NameEmailPhone interface.
 */
export function instanceOfNameEmailPhone(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('phone' in value)) return false;
    return true;
}

export function NameEmailPhoneFromJSON(json: any): NameEmailPhone {
    return NameEmailPhoneFromJSONTyped(json, false);
}

export function NameEmailPhoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameEmailPhone {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'],
    };
}

export function NameEmailPhoneToJSON(value?: NameEmailPhone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'email': value['email'],
        'phone': value['phone'],
    };
}

