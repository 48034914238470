import { Tag, Tooltip } from "antd";
import { isBlank } from "../../Helpers/isNotBlank";

interface CompanySalesCallsSnoozedTagProps {
  snoozeSalesCallsUntil: string | undefined | null;
}

export function CompanySalesCallsSnoozedTag(
  props: CompanySalesCallsSnoozedTagProps
) {
  if (isBlank(props.snoozeSalesCallsUntil)) {
    return <></>;
  }

  return (
    <Tooltip title={`Until : ${props.snoozeSalesCallsUntil}`}>
      <Tag color="purple">Sales Calls Snoozed</Tag>
    </Tooltip>
  );
}
