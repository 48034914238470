/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ApolloMapQueryBookingStatus = {
    Any: 'Any',
    NotBooked: 'NotBooked',
    Booked: 'Booked'
} as const;
export type ApolloMapQueryBookingStatus = typeof ApolloMapQueryBookingStatus[keyof typeof ApolloMapQueryBookingStatus];


export function instanceOfApolloMapQueryBookingStatus(value: any): boolean {
    return Object.values(ApolloMapQueryBookingStatus).includes(value);
}

export function ApolloMapQueryBookingStatusFromJSON(json: any): ApolloMapQueryBookingStatus {
    return ApolloMapQueryBookingStatusFromJSONTyped(json, false);
}

export function ApolloMapQueryBookingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapQueryBookingStatus {
    return json as ApolloMapQueryBookingStatus;
}

export function ApolloMapQueryBookingStatusToJSON(value?: ApolloMapQueryBookingStatus | null): any {
    return value as any;
}

