import { CopyOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";
import { SendMailLink } from "../Screens/ViewShipmentScreenComponents/GenericSupportEmailButton";
import { ViewShipmentDataForApollo } from "../generated-openapi-client";
import { ButtonRow } from "./ButtonRow";

interface EmailLinkProps {
  email: string;
  data: ViewShipmentDataForApollo;
}

export function EmailLink(props: EmailLinkProps) {
  const onCopy = useCopyToClipboard(props.email);

  function onSearchIntercom() {
    window.open(
      `https://app.intercom.com/a/apps/oj0p8brq/users/segments/all-users?searchTerm=${encodeURIComponent(
        props.email
      )}`,
      "_new"
    );
  }

  return (
    <Tooltip
      overlayInnerStyle={{ width: "400px" }}
      overlay={
        <ButtonRow>
          <SendMailLink data={props.data} email={props.email} />

          <Button
            onClick={onCopy}
            style={{ color: "white" }}
            type="link"
            icon={<CopyOutlined />}
          >
            Copy
          </Button>
          <Button
            onClick={onSearchIntercom}
            style={{ color: "white" }}
            type="link"
            icon={<SearchOutlined />}
          >
            Search Intercom
          </Button>
        </ButtonRow>
      }
    >
      {props.email}
    </Tooltip>
  );
}
