import { Button, message } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../Apis/Apis";
import { CompanyData, SalesContact } from "../generated-openapi-client";
import { isBlank } from "../Helpers/isNotBlank";
import { useDisplay } from "../Hooks/useDisplay";
import { useTelWithCompanyId } from "../Hooks/useTel";

import { LoadingOutlined } from "@ant-design/icons";
import Colors from "../Components/Colors";
import { OutboundLogSalesCallModal } from "./ViewCompanyScreenComponents/LogSalesCall/OutboundLogSalesCall";
import { useCallContext } from "../Contexts/CallContext";

export interface ClickToCallButtonProps {
  data: CompanyData;
  onRefresh: () => Promise<void>;
  salesContact: SalesContact;
  isMobile: boolean;
}

export function ClickToCallButton(props: ClickToCallButtonProps) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);

  const callContext = useCallContext();

  const display = useDisplay();

  const numberToCall = props.isMobile
    ? props.salesContact.mobilePhone
    : props.salesContact.phone;

  const onCall = useTelWithCompanyId(
    numberToCall ?? "",
    props.salesContact.companyId!!,
    props.salesContact.salesContactId,
    props.salesContact.name ?? ""
  );

  async function preSalesCall(e: any) {
    setLoading(true);
    if (callContext.callData) {
      message.error("Please save the current sales call notes first.");
      setLoading(false);
      return;
    }
    try {
      const companiesApi = await createCompaniesApi();

      const responseCompanyNoteId = await companiesApi.preLogSalesCall({
        companyId: props.salesContact.companyId!,
        salesContactId: props.salesContact.salesContactId!,
      });

      props.onRefresh();
      display.show();
      onCall(e, responseCompanyNoteId);
      callContext.startCall({
        temperature: props.data.company.temperature,
        shipmentFrequency: props.data.company.shipmentFrequency,
        companyId: props.salesContact.companyId,
        salesContactId: props.salesContact.salesContactId,
        companyNoteId: responseCompanyNoteId,
      });
    } catch (e) {
      props.onRefresh();
      message.warning(
        "Something went wrong while creating the sales call note, please remember to add the call notes in the notes tab",
        10
      );
      onCall(e, undefined);
    }
    setLoading(false);
  }

  if (numberToCall === undefined) {
    return <></>;
  }

  if (isBlank(numberToCall)) {
    return <></>;
  }

  return (
    <>
      <OutboundLogSalesCallModal
        company={props.data.company}
        salesContacts={props.data.salesContacts}
        display={display}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        <Button
          onClick={preSalesCall}
          type="text"
          style={{ color: Colors.Blue }}
        >
          {numberToCall}
        </Button>
        {loading && <LoadingOutlined />}
      </div>
    </>
  );
}
