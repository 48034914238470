/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentDocument } from './ShipmentDocument';
import {
    ShipmentDocumentFromJSON,
    ShipmentDocumentFromJSONTyped,
    ShipmentDocumentToJSON,
} from './ShipmentDocument';

/**
 * 
 * @export
 * @interface ShipmentDocumentVisibilty
 */
export interface ShipmentDocumentVisibilty {
    /**
     * 
     * @type {ShipmentDocument}
     * @memberof ShipmentDocumentVisibilty
     */
    shipmentDocument: ShipmentDocument;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentDocumentVisibilty
     */
    visibleToCustomer: boolean;
}

/**
 * Check if a given object implements the ShipmentDocumentVisibilty interface.
 */
export function instanceOfShipmentDocumentVisibilty(value: object): boolean {
    if (!('shipmentDocument' in value)) return false;
    if (!('visibleToCustomer' in value)) return false;
    return true;
}

export function ShipmentDocumentVisibiltyFromJSON(json: any): ShipmentDocumentVisibilty {
    return ShipmentDocumentVisibiltyFromJSONTyped(json, false);
}

export function ShipmentDocumentVisibiltyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentDocumentVisibilty {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentDocument': ShipmentDocumentFromJSON(json['shipmentDocument']),
        'visibleToCustomer': json['visibleToCustomer'],
    };
}

export function ShipmentDocumentVisibiltyToJSON(value?: ShipmentDocumentVisibilty | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentDocument': ShipmentDocumentToJSON(value['shipmentDocument']),
        'visibleToCustomer': value['visibleToCustomer'],
    };
}

