import { message, Tag, Tooltip } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import {
  CarrierCreditNote,
  CarrierCreditNoteState,
} from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

interface CheckIfCarrierCreditNoteIsPaidMenuItemProps extends TabProps {
  carrierCreditNote: CarrierCreditNote;
}

export function CheckIfCarrierCreditNoteIsPaidMenuItem(
  props: CheckIfCarrierCreditNoteIsPaidMenuItemProps
) {
  const carrierCreditNoteId = props.carrierCreditNote.carrierCreditNoteId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.checkIfCarrierCreditNoteIsPaid({
        carrierCreditNoteId,
      });
      message.success(
        "Checked and refreshing. Will be marked as paid now if it was paid"
      );
      props.onRefresh();
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  if (
    props.carrierCreditNote.carrierCreditNoteState !==
    CarrierCreditNoteState.Approved
  ) {
    return <></>;
  }

  return (
    <Tooltip
      placement="left"
      title="This will compare this credit note against the matching credit note in Xero. If the Xero credit note is paid, then this credit note will be marked as paid. There should not be a need to do this, as a background process runs hourly to do this automatically"
    >
      <MenuItem onClick={onConfirm}>
        <Tag color="red">Advanced</Tag> Check if credit note is paid
      </MenuItem>
    </Tooltip>
  );
}
