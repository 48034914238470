export function isNotBlank(s: string | undefined | null): boolean {
  if (s === null) {
    return false;
  }

  if (s === undefined) {
    return false;
  }

  if (s === "") {
    return false;
  }

  if (s.trim() === "") {
    return false;
  }

  return true;
}

export function isBlank(s: string | undefined | null): boolean {
  return !isNotBlank(s);
}
