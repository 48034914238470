/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TrackingSource } from './TrackingSource';
import {
    TrackingSourceFromJSON,
    TrackingSourceFromJSONTyped,
    TrackingSourceToJSON,
} from './TrackingSource';

/**
 * 
 * @export
 * @interface TrackingLine
 */
export interface TrackingLine {
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    trackingLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    message?: string;
    /**
     * 
     * @type {TrackingSource}
     * @memberof TrackingLine
     */
    trackingSource?: TrackingSource;
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    manualCreatedBy?: string;
}

/**
 * Check if a given object implements the TrackingLine interface.
 */
export function instanceOfTrackingLine(value: object): boolean {
    return true;
}

export function TrackingLineFromJSON(json: any): TrackingLine {
    return TrackingLineFromJSONTyped(json, false);
}

export function TrackingLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingLine {
    if (json == null) {
        return json;
    }
    return {
        
        'trackingLineId': json['trackingLineId'] == null ? undefined : json['trackingLineId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'message': json['message'] == null ? undefined : json['message'],
        'trackingSource': json['trackingSource'] == null ? undefined : TrackingSourceFromJSON(json['trackingSource']),
        'manualCreatedBy': json['manualCreatedBy'] == null ? undefined : json['manualCreatedBy'],
    };
}

export function TrackingLineToJSON(value?: TrackingLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'trackingLineId': value['trackingLineId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'timestamp': value['timestamp'],
        'message': value['message'],
        'trackingSource': TrackingSourceToJSON(value['trackingSource']),
        'manualCreatedBy': value['manualCreatedBy'],
    };
}

