export function stringListsCommonElements(
  list: string[],
  pivot: string[]
): string[] {
  const commonElements: string[] = [];
  pivot.forEach(function (element) {
    if (list.includes(element)) {
      commonElements.push(element);
    }
  });
  return commonElements;
}
