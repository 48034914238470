import {
  Alert,
  Button,
  Statistic,
  Tabs,
  Tag,
  message,
  Popover,
  Input,
  Tooltip,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCarrierInvoiceApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatsRow } from "../Components/StatsRow";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import { sum } from "../Helpers/sum";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import {
  CarrierCreditNote,
  CompanyShipmentCarrierInvoice,
  CurrencyCode,
  ResponseError,
} from "../generated-openapi-client";
import { CarrierInvoice } from "../generated-openapi-client/models/CarrierInvoice";
import { CarrierInvoiceData } from "../generated-openapi-client/models/CarrierInvoiceData";
import { CompanyShipmentQuote } from "../generated-openapi-client/models/CompanyShipmentQuote";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";

const { TabPane } = Tabs;

interface CheckIfInvoicesArePaidButtonProps {
  onRefresh: () => Promise<void>;
}

function CheckIfInvoicesArePaidButton(
  props: CheckIfInvoicesArePaidButtonProps
) {
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    const api = await createCarrierInvoiceApi();
    await api.checkIfInvoicesArePaid();
    message.success("Done!");
    await props.onRefresh();
    setLoading(false);
  }

  return (
    <Button onClick={onClick} loading={loading}>
      Update from Xero
    </Button>
  );
}

interface ShipmentsTableProps {
  shipments: CompanyShipmentQuote[];
}

function ShipmentsTable(props: ShipmentsTableProps) {
  const columns: DataTableColumn<CompanyShipmentQuote>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.quote.carrierIdentifier!!}
          brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.shipment.proNumber}</div>,
    },
    {
      title: "Quoted Price",
      render: (o) => (
        <div>{formatAsCurrency(o.quote.quotedPriceByCarrierCad, "CAD")}</div>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipment.shipmentId!!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <Stack>
      <DataTable pagination={false} columns={columns} data={props.shipments} />
    </Stack>
  );
}

interface CarrierInvoicesTableProps {
  carrierInvoices: CompanyShipmentCarrierInvoice[];
  includePaymentPendingDate?: boolean;
}

export function CarrierInvoicesTable(props: CarrierInvoicesTableProps) {
  const total = sum(props.carrierInvoices, (ci) => ci.carrierInvoice.amount!!);

  const columns: DataTableColumn<CompanyShipmentCarrierInvoice>[] = [];
  columns.push({
    title: "Carrier",
    render: (o) => (
      <Stack align="left">
        <CarrierLogo
          carrierIdentifier={o.carrierInvoice.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
        {o.shipment.invoiceAuditOpen && (
          <Tag color="red">🚩 Invoice Audit Open</Tag>
        )}
      </Stack>
    ),
  });
  columns.push({
    title: "Pro Number",
    render: (o) => <div>{o.carrierInvoice.invoiceIdentifier}</div>,
  });
  columns.push({
    title: "Pickup Date",
    render: (o) => (
      <div>{moment(o.shipment.pickupDate).format("MMM Do YYYY")}</div>
    ),
    sorter: function (a, b) {
      return (
        moment(a.shipment.pickupDate).valueOf() -
        moment(b.shipment.pickupDate).valueOf()
      );
    },
  });
  columns.push({
    title: "Delivery Date",
    render: (o) => (
      <div>{moment(o.shipment.actualDeliveryDate).format("MMM Do YYYY")}</div>
    ),
    sorter: function (a, b) {
      return (
        moment(a.shipment.actualDeliveryDate).valueOf() -
        moment(b.shipment.actualDeliveryDate).valueOf()
      );
    },
  });
  columns.push({
    title: "Amount",
    render: (o) => (
      <div>
        ${o.carrierInvoice.amount} {o.carrierInvoice.currency}
      </div>
    ),
  });
  if (props.includePaymentPendingDate) {
    columns.push({
      title: "Payment Pending Date",
      render: (o) => (
        <div>
          {moment(o.carrierInvoice.paymentPendingTime).format("MMM Do YYYY")}
        </div>
      ),
      sorter: function (a, b) {
        return (
          moment(a.carrierInvoice.paymentPendingTime).valueOf() -
          moment(b.carrierInvoice.paymentPendingTime).valueOf()
        );
      },
    });
  }
  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <ViewShipmentButton shipmentId={o.carrierInvoice.shipmentId!!} />
      </ButtonRow>
    ),
  });

  return (
    <Stack align="left">
      <div>Total: ${total.toLocaleString()}</div>
      <Spacer height={16} />
      <DataTable
        pagination={false}
        columns={columns}
        data={props.carrierInvoices}
      />
    </Stack>
  );
}

interface CarrierCreditNotesTableProps {
  carrierCreditNotes: CarrierCreditNote[];
}

export function CarrierCreditNotesTable(props: CarrierCreditNotesTableProps) {
  const columns: DataTableColumn<CarrierInvoice>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.invoiceIdentifier}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <div>
          ${o.amount} {o.currency}
        </div>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.carrierCreditNotes}
    />
  );
}

const CarrierInvoicesCurrencyDropdown = OptionalEnumDropdown<
  CurrencyCode,
  typeof CurrencyCode
>(CurrencyCode, "Currency", function (et) {
  switch (et) {
    case CurrencyCode.Cad:
      return "🇨🇦 CAD ";
    case CurrencyCode.Usd:
      return "🇺🇸 USD";
  }
});

export function CarrierInvoicesScreen() {
  const [carrierInvoices, setCarrierInvoices] = useState<
    CarrierInvoiceData | undefined
  >();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >();

  const [currencyFilter, setCurrencyFilter] = useState<CurrencyCode>();
  const [searchChequeNumber, setSearchChequeNumber] = useState("");
  const [searchChequeNumberLoading, setSearchChequeNumberLoading] =
    useState(false);

  async function refresh() {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const response = await carrierInvoiceApi.viewCarrierInvoices();
    setCarrierInvoices(response);
  }

  const query = useQuery();
  const navigate = useNavigate();

  const tab = query.tab as string;

  useOnce(refresh);

  function callback(key: string) {
    console.log(key);

    navigate(`/carrier-invoices?tab=${key}`, {
      replace: true,
    });
  }

  if (carrierInvoices === undefined) {
    return <Loading />;
  }

  const totalShipmentsWithoutInvoices = sum(
    carrierInvoices.shipmentsWithoutInvoices,
    function (s) {
      return s.quote.quotedPriceByCarrierCad!!;
    }
  );

  function invoicePriceInCad(i: CompanyShipmentCarrierInvoice) {
    if (i.carrierInvoice.currency === CurrencyCode.Cad)
      return i.carrierInvoice.amount!!;
    else return i.carrierInvoice.amount!! * 1.32;
  }

  const totalIssuedInvoices = sum(
    carrierInvoices.issuedInvoices,
    invoicePriceInCad
  );
  const totalApprovedInvoices = sum(
    carrierInvoices.approvedInvoices,
    invoicePriceInCad
  );
  const totalPaymentPendingInvoices = sum(
    carrierInvoices.paymentPendingInvoices,
    invoicePriceInCad
  );

  function applyCarrierIdentifierFilter(
    invoice: CompanyShipmentCarrierInvoice
  ) {
    if (carrierIdentifier === undefined) {
      return true;
    } else {
      return carrierIdentifier === invoice.carrierInvoice.carrierIdentifier;
    }
  }

  function applyCurrencyFilter(
    invoice: CompanyShipmentCarrierInvoice
  ): boolean {
    if (currencyFilter === undefined) {
      return true;
    }

    return invoice.carrierInvoice.currency === currencyFilter;
  }

  function applyFilters(invoices: CompanyShipmentCarrierInvoice[]) {
    return invoices
      .filter(applyCarrierIdentifierFilter)
      .filter(applyCurrencyFilter);
  }

  async function handleSearchByChequeNumber() {
    setSearchChequeNumberLoading(true);
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      const invoice = await carrierInvoiceApi.getCarrierInvoiceByChequeNumber({
        chequeNumber: searchChequeNumber,
      });
      if (invoice.shipmentId) {
        navigate(
          `/view-shipment?shipmentId=${invoice.shipmentId}&tab=carrierinvoices`
        );
      } else {
        message.error("Something went wrong!");
        console.error(invoice);
      }
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 404) {
        message.warn(
          "No carrier invoice found for the entered cheque number."
        );
      } else {
        message.error("Something went wrong!");
        console.error(e);
      }
    }
    setSearchChequeNumberLoading(false);
  }

  return (
    <>
      <Page
        title={`Carrier Invoices`}
        tags={[]}
        stats={
          <StatsRow>
            <Statistic
              title="Missing Invoices"
              value={formatAsCurrency(totalShipmentsWithoutInvoices)}
            />
            <Statistic
              title="Issued Invoices"
              value={formatAsCurrency(totalIssuedInvoices)}
            />
            <Statistic
              title="Approved Invoices"
              value={formatAsCurrency(totalApprovedInvoices)}
            />
            <Statistic
              title="Payment Pending Invoices"
              value={formatAsCurrency(totalPaymentPendingInvoices)}
            />
          </StatsRow>
        }
        extra={[
          <>
            <CheckIfInvoicesArePaidButton onRefresh={refresh} />
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              onFilter={setCarrierIdentifier}
            />
            <CarrierInvoicesCurrencyDropdown
              value={currencyFilter}
              setValue={setCurrencyFilter}
            />
            <Popover
              title="Search by Cheque Number"
              trigger="click"
              placement="bottom"
              content={
                <div style={{ textAlign: "end" }}>
                  <Input
                    value={searchChequeNumber}
                    onChange={(e) => setSearchChequeNumber(e.target.value)}
                    placeholder="Cheque number"
                  />
                  <Spacer height={8} />
                  <Button
                    type="primary"
                    onClick={handleSearchByChequeNumber}
                    loading={searchChequeNumberLoading}
                  >
                    Search
                  </Button>
                </div>
              }
            >
              <Tooltip
                placement="left"
                title="Search for a carrier invoice using the provided cheque number and navigate to its associated shipment details."
              >
                <Button>Search by Cheque Number</Button>
              </Tooltip>
            </Popover>
          </>,
        ]}
      >
        <PageTitle>{`Carrier Invoices`}</PageTitle>
        <Tabs
          defaultActiveKey={tab}
          onChange={callback}
          style={{ width: "100%" }}
        >
          {carrierInvoices.shipmentsWithoutInvoices.length > 0 && (
            <TabPane
              tab={`Shipments without Invoices  (${carrierInvoices?.shipmentsWithoutInvoices.length})`}
              key="shipmentsWithoutInvoices"
            >
              <ShipmentsTable
                shipments={carrierInvoices.shipmentsWithoutInvoices}
              />
            </TabPane>
          )}
          {carrierInvoices.shipmentsOverdueAnInvoice.length > 0 && (
            <TabPane
              tab={`Shipments Overdue an Invoice  (⚠️ ${carrierInvoices?.shipmentsOverdueAnInvoice.length})`}
              key="shipmentsOverdueAnInvoice"
            >
              <ShipmentsTable
                shipments={carrierInvoices.shipmentsOverdueAnInvoice}
              />
            </TabPane>
          )}
          <TabPane
            tab={`Issued Invoices  (${
              applyFilters(carrierInvoices.issuedInvoices).length
            })`}
            key="issuedInvoices"
          >
            <CarrierInvoicesTable
              carrierInvoices={applyFilters(carrierInvoices.issuedInvoices)}
            />
          </TabPane>
          <TabPane
            tab={`Approved Invoices (${
              applyFilters(carrierInvoices.approvedInvoices).length
            })`}
            key="approvedInvoices"
          >
            <CarrierInvoicesTable
              carrierInvoices={applyFilters(carrierInvoices.approvedInvoices)}
            />
          </TabPane>
          <TabPane
            tab={`Ready To Pay Invoices (${
              applyFilters(carrierInvoices.invoicesReadyToPay).length
            })`}
            key="readyToPayInvoices"
          >
            <Alert
              type="info"
              description="These are the invoices that are considered ready to pay. This should match the invoices with Payment tasks in Monday"
            />
            <Spacer height={8} />
            <CarrierInvoicesTable
              carrierInvoices={applyFilters(carrierInvoices.invoicesReadyToPay)}
            />
          </TabPane>
          <TabPane
            tab={`Payment Pending Invoices  (${
              applyFilters(carrierInvoices.paymentPendingInvoices).length
            })`}
            key="paymentPendingInvoices"
          >
            <CarrierInvoicesTable
              carrierInvoices={applyFilters(
                carrierInvoices.paymentPendingInvoices
              )}
              includePaymentPendingDate
            />
          </TabPane>
        </Tabs>
      </Page>
    </>
  );
}
