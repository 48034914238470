import { Select } from "antd";
import { SupportAgent } from "../../generated-openapi-client/models/SupportAgent";

const { Option } = Select;

interface SupportAgentDropdownProps {
  onSelect: (supportAgent: SupportAgent) => void;
  supportAgent: SupportAgent;
}

export function SupportAgentDropdown(props: SupportAgentDropdownProps) {
  return (
    <Select
      value={props.supportAgent}
      style={{ width: 100 }}
      onChange={props.onSelect}
    >
      {Object.entries(SupportAgent).map(function (value) {
        const v = value[1];
        return <Option value={v}>{v}</Option>;
      })}
    </Select>
  );
}
