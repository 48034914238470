import { Button, Form, message, Modal } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { NameEmailPhone } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";
import { UsersDropdown } from "./UsersDropdown";

export enum ChangeUserRole {
  Quoter = "Quoter",
  Booker = "Booker",
}

interface ChangeUserButtonProps extends TabProps {
  contact: NameEmailPhone;
  role: ChangeUserRole;
}

export function ChangeUserButton(props: ChangeUserButtonProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!!;
  const createShipmentApi = useShipmentsApi();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [name, setName] = useState<string>(props.contact.name);
  const [email, setEmail] = useState(props.contact.email ?? "");

  const [phone, setPhone] = useState(props.contact.phone);

  const [saving, setSaving] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (name === "" || email === "" || email) {
      message.warn("Please specify both fields");
    }

    setSaving(true);
    try {
      const shipmentApi = await createShipmentApi();
      if (props.role === ChangeUserRole.Quoter) {
        await shipmentApi.updateQuoter({
          shipmentId,
          name,
          email,
          phone,
        });
      }
      if (props.role === ChangeUserRole.Booker) {
        await shipmentApi.updateBooker({
          shipmentId,
          name,
          email,
          phone,
        });
      }
      await props.onRefresh();
      setIsModalVisible(false);

      message.success(`${props.role} updated`);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`Edit ${props.role}`}
        visible={isModalVisible}
        okText="Save changes"
        onOk={handleOk}
        onCancel={handleCancel}
        width={750}
        confirmLoading={saving}
        destroyOnClose={true}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Select User">
            <UsersDropdown
              companyId={props.shipmentData.shipment.company.companyId!!}
              onChange={function (selectedUser) {
                if (selectedUser) {
                  setName(selectedUser.name);
                  setEmail(selectedUser.email);
                  setPhone(selectedUser.phone);
                } else {
                  setName("");
                  setEmail("");
                  setPhone("");
                }
              }}
            />
          </Form.Item>

          <Form.Item label="Name">
            <div>{name}</div>
          </Form.Item>
          <Form.Item label="Email">
            <div>{email}</div>
          </Form.Item>
          <Form.Item label="Phone">
            <div>{phone}</div>
          </Form.Item>
        </Form>
      </Modal>
      <HorizontalStack style={{ marginLeft: "-16px" }}>
        <Button type="link" onClick={showModal}>
          Change
        </Button>
      </HorizontalStack>
    </>
  );
}
