import { Calendar, useColorScale } from "@nivo/calendar";

import { Line, Serie } from "@nivo/line";
import { Form } from "antd";
import moment from "moment";
import { useState } from "react";
import { assertNever } from "../Helpers/assertNever";
import { groupBy } from "../Helpers/groupBy";
import { sum } from "../Helpers/sum";
import {
  generateMonthInfo,
  generateQuarterInfo,
  generateYearInfo,
  PeriodFormats,
  PeriodInfo,
} from "../Screens/FinanceComponents/generatePeriodInfo";
import { EnumDropdown } from "../Screens/ViewShipmentScreenComponents/EnumDropdown";
import {
  Period,
  PeriodDropdown,
} from "../Screens/ViewShipmentScreenComponents/RecentCompanySalesReportScreen";
import Spacer from "../Spacer";
import { CalendarDataPoint } from "../generated-openapi-client";
import { CalendarDataResponse } from "../generated-openapi-client/models/CalendarDataResponse";
import { ButtonRow } from "./ButtonRow";
import { Loading } from "./Loading";
import Stack from "./Stack";

interface StatsCalendarTimeChartTabProps {
  data: CalendarDataPoint[];
  fromDate: string;
  prefix: string | undefined;
  period: Period;
}

interface CustomSymbolProps {
  size: number;
  color: string;
  borderWidth: number;
  borderColor: string;
}

export function CustomSymbol(props: CustomSymbolProps) {
  const { size, color, borderWidth, borderColor } = props;
  return (
    <g>
      <circle
        fill="#fff"
        r={size / 2}
        strokeWidth={borderWidth}
        stroke={borderColor}
      />
      <circle
        r={size / 5}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fill={color}
        fillOpacity={0.35}
      />
    </g>
  );
}

function StatsCalendarTimeChartTab(props: StatsCalendarTimeChartTabProps) {
  const { data } = props;

  function sumForReport(title: string, periodFormat: PeriodFormats) {
    const filtered = data.filter(
      (s) => moment(s.day).format(periodFormat) === title
    );

    return sum(filtered, (s) => s.value);
  }

  function generateSeries(
    periodInfo: PeriodInfo[],
    periodFormat: PeriodFormats
  ) {
    const data2 = periodInfo.map(function (pi) {
      return {
        x: pi.firstDate,
        y: Math.floor(sumForReport(pi.title, periodFormat)),
      };
    });

    console.log(`data2`, { data2 });
    const series: Serie[] = [
      {
        id: "test",
        data: data2,
      },
    ];
    return series;
  }

  function getDataForMonthly() {
    const periodInfo = generateMonthInfo();
    const series = generateSeries(periodInfo, PeriodFormats.Monthly);

    const xScalePrecision = "month";
    const axisBottom = {
      format: "%b %Y",
      tickValues: "every 1 month",
      tickRotation: -45,
      tickSize: 5,
      tickPadding: 10,
      legendOffset: -1,
    };

    return { series, xScalePrecision, axisBottom };
  }

  function getDataForQuarterly() {
    const periodInfo = generateQuarterInfo();
    const series = generateSeries(periodInfo, PeriodFormats.Quarterly);

    const xScalePrecision = "month";
    const axisBottom = {
      format: (value: any) => {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = date.getMonth();
        const quarter = Math.floor(month / 3) + 1;
        return `${year} Q${quarter}`;
      },
      tickValues: "every 3 month",
      tickRotation: -45,
      tickSize: 5,
      tickPadding: 10,
      legendOffset: -1,
    };

    return { series, xScalePrecision, axisBottom };
  }

  function getDataForYearly() {
    const periodInfo = generateYearInfo();
    const series = generateSeries(periodInfo, PeriodFormats.Yearly);

    const xScalePrecision = "year";
    const axisBottom = {
      format: "%Y",
      tickValues: "every 1 year",
      tickRotation: -45,
      tickSize: 5,
      tickPadding: 10,
      legendOffset: -1,
    };

    return { series, xScalePrecision, axisBottom };
  }

  function getDataForPeriod() {
    switch (props.period) {
      case Period.Monthly:
        return getDataForMonthly();
      case Period.Quarterly:
        return getDataForQuarterly();
      case Period.Yearly:
        return getDataForYearly();
      default:
        assertNever(props.period);
    }
  }

  const output = getDataForPeriod();

  return (
    <Line
      data={output.series}
      // Make sure we have enough height to render all the years of data
      height={350}
      width={1200}
      margin={{ top: 20, right: 20, bottom: 100, left: 80 }}
      animate={true}
      enableSlices={false}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
        // @ts-ignore
        precision: output.xScalePrecision,
      }}
      xFormat="time:%Y-%m"
      axisBottom={output.axisBottom}
      useMesh={true}
      enablePointLabel={true}
      pointSymbol={CustomSymbol}
      pointSize={16}
      pointBorderWidth={1}
      pointBorderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
      pointLabel={function (e) {
        return (props.prefix ?? "") + e.y.toLocaleString();
      }}
    />
  );
}

interface StatsCalendarCalendarTabProps {
  data: CalendarDataPoint[];
  fromDate: string;
  maxValue: number;
}

export function StatsCalendarCalendarTab(props: StatsCalendarCalendarTabProps) {
  const { data, fromDate } = props;

  const colorScale = useColorScale({
    data: data || [],
    minValue: 0,
    maxValue: props.maxValue,
    colors: [
      "#eeedff",
      "#dcdcff",
      "#c9cbff",
      "#b6baff",
      "#a1aaff",
      "#8a9aff",
      "#708aff",
      "#4e7bff",
      "#006cff",
    ],
  });

  if (!data || !fromDate) {
    return <Loading />;
  }

  function countYears(): number {
    if (data === undefined) {
      throw new Error("Should not be here");
    }

    const grouped = groupBy(data, function (point) {
      // extract the year from YYYY-MM-DD date
      return point.day.split("-")[0];
    });
    return grouped.length;
  }

  function sumByYearAndMonth(d: Date): number {
    if (data === undefined) {
      return 0;
    }
    const relevant = data.filter((o) =>
      o.day.startsWith(moment(d).format("YYYY-MM"))
    );
    return sum(relevant, (o) => o.value);
  }

  function generateLegend(year: number, month: number, date: Date) {
    return (
      moment(date).format("MMM") + ` : ${sumByYearAndMonth(date).toFixed(0)}`
    );
  }

  return (
    <Calendar
      data={data}
      // Make sure we have enough height to render all the years of data
      height={countYears() * 250}
      width={1200}
      from={fromDate}
      monthSpacing={10}
      to={moment().format("YYYY-MM-DD")}
      colorScale={colorScale}
      monthLegend={generateLegend}
    />
  );
}

interface StatsCalendarProps {
  data: CalendarDataResponse;
  maxValue: number;
  prefix?: string;
}

enum GraphType {
  Graph = "Graph",
  Calendar = "Calendar",
}

const GraphTypeDropdown = EnumDropdown<GraphType, typeof GraphType>(
  GraphType,
  function (type: GraphType) {
    switch (type) {
      case GraphType.Calendar:
        return "Calendar";
      case GraphType.Graph:
        return "Graph";
    }
  }
);

export function StatsCalendar(props: StatsCalendarProps) {
  const data = props.data.data;
  const fromDate = props.data.earliestDate;
  const [graphType, setGraphType] = useState<GraphType>(GraphType.Graph);
  const [period, setPeriod] = useState<Period>(Period.Monthly);

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item label="Type">
          <GraphTypeDropdown value={graphType} setValue={setGraphType} />
        </Form.Item>
        <Form.Item label="Type">
          <PeriodDropdown value={period} setValue={setPeriod} />
        </Form.Item>
      </ButtonRow>
      <Spacer height={4} />
      {graphType === GraphType.Calendar && (
        <StatsCalendarCalendarTab
          data={data}
          fromDate={fromDate}
          maxValue={props.maxValue}
        />
      )}
      {graphType === GraphType.Graph && (
        <StatsCalendarTimeChartTab
          data={data}
          fromDate={fromDate}
          prefix={props.prefix}
          period={period}
        />
      )}
    </Stack>
  );
}
