/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarrierServicePair
 */
export interface CarrierServicePair {
    /**
     * 
     * @type {string}
     * @memberof CarrierServicePair
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierServicePair
     */
    serviceIdentifier: string;
}

/**
 * Check if a given object implements the CarrierServicePair interface.
 */
export function instanceOfCarrierServicePair(value: object): boolean {
    if (!('carrierIdentifier' in value)) return false;
    if (!('serviceIdentifier' in value)) return false;
    return true;
}

export function CarrierServicePairFromJSON(json: any): CarrierServicePair {
    return CarrierServicePairFromJSONTyped(json, false);
}

export function CarrierServicePairFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierServicePair {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierIdentifier': json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'],
    };
}

export function CarrierServicePairToJSON(value?: CarrierServicePair | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
    };
}

