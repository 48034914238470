import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import MenuItem from "antd/lib/menu/MenuItem";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import {
  InvoiceAuditProblem,
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemResolutionType,
  InvoiceAuditProblemType,
  ShipmentDocument,
} from "../../generated-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import { capitalizeFirstLetter } from "../../Helpers/capitalizeFirstLetter";
import { isBlank } from "../../Helpers/isNotBlank";
import {
  describeLocationType,
  getLocationTypes,
} from "../../Helpers/locationTypes";
import { shipmentInfoMessageForInvoiceAuditType } from "../shipmentInfoMessageForInvoiceAuditType";
import { ManageAttachmentsButton } from "../ViewCompanyScreenComponents/ManageAttachmentsButton";
import { OptionalEnumDropdown } from "./EnumDropdown";
import {
  colorForInvoiceAuditProblemResolutionState,
  describeInvoiceAuditConfidence,
  describeInvoiceAuditProblemType,
  emojiForInvoiceAuditProblemFault,
  InvoiceAuditProblemConfidenceTag,
  isInvoiceAuditProblemTypeAllowedForAdditionalCharge,
} from "./InvoiceAuditProblemTypeDescription";
import { InvoiceAuditProblemTypeDropdown } from "./InvoiceAuditProblemTypeDropdown";
import { TabProps } from "./TabProps";
import { ViewDocumentButton } from "./ViewDocumentButton";
const { Title } = Typography;

const CellHeader = styled.div`
  font-size: 11px;
  margin-top: 8px;
  color: ${Colors.LightText};
`;

interface EditInvoiceAuditProblemProps extends TabProps {
  invoiceAuditProblem: InvoiceAuditProblem;
  setInvoiceAuditProblem: React.Dispatch<
    React.SetStateAction<InvoiceAuditProblem>
  >;
}

const InvoiceAuditProblemFaultDropdown = OptionalEnumDropdown<
  InvoiceAuditProblemFault,
  typeof InvoiceAuditProblemFault
>(InvoiceAuditProblemFault, "Unknown Fault", function (et) {
  function text() {
    switch (et) {
      case InvoiceAuditProblemFault.Carrier:
        return "Carrier";
      case InvoiceAuditProblemFault.Customer:
        return "Customer";
      case InvoiceAuditProblemFault.Freightsimple:
        return "FreightSimple";
      case InvoiceAuditProblemFault.Unknown:
        return "Unknown";
      default:
        assertNever(et);
    }
  }

  return `${emojiForInvoiceAuditProblemFault(et)} ${text()}`;
});

const InvoiceAuditProblemResolutionTypeDropdown = OptionalEnumDropdown<
  InvoiceAuditProblemResolutionType,
  typeof InvoiceAuditProblemResolutionType
>(InvoiceAuditProblemResolutionType, "Unknown Resolution Type", function (et) {
  switch (et) {
    case InvoiceAuditProblemResolutionType.AdditionalChargeIssued:
      return "Additional Charge Issued";
    case InvoiceAuditProblemResolutionType.CarrierCorrection:
      return "Carrier Correction";

    case InvoiceAuditProblemResolutionType.FreightsimpleAbsorbedCost:
      return "FreightSimple Absorbed Cost";

    case InvoiceAuditProblemResolutionType.Minor:
      return "Minor";

    default:
      assertNever(et);
  }
});

const InvoiceAuditProblemResolutionStateDropdown = OptionalEnumDropdown<
  InvoiceAuditProblemResolutionState,
  typeof InvoiceAuditProblemResolutionState
>(
  InvoiceAuditProblemResolutionState,
  "Unknown Resolution State",
  function (et) {
    switch (et) {
      case InvoiceAuditProblemResolutionState.Pending:
        return "Pending";
      case InvoiceAuditProblemResolutionState.Resolved:
        return "Resolved";
      case InvoiceAuditProblemResolutionState.Unresolved:
        return "Unresolved";

      default:
        assertNever(et);
    }
  }
);

interface InvoiceAuditProblemConfidenceDropdownProps {
  value: InvoiceAuditProblemConfidence | undefined;
  setValue: (_: InvoiceAuditProblemConfidence | undefined) => void;
  invoiceAuditFault: InvoiceAuditProblemFault | undefined;
}

const DropdownHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const DropdownDescription = styled.div`
  font-size: 13px;
  font-weight: 200;
  color: ${Colors.LightText};
`;

interface InvoiceAuditProblemConfidenceDropdownItemProps {
  header: string;
  description: string;
}

function InvoiceAuditProblemConfidenceDropdownItem(
  props: InvoiceAuditProblemConfidenceDropdownItemProps
) {
  return (
    <Stack align="left">
      <DropdownHeader>{props.header}</DropdownHeader>
      <DropdownDescription>{props.description}</DropdownDescription>
    </Stack>
  );
}

function InvoiceAuditProblemConfidenceDropdown(
  props: InvoiceAuditProblemConfidenceDropdownProps
) {
  if (props.invoiceAuditFault === undefined) {
    return <></>;
  }

  return (
    <Select
      value={props.value}
      onChange={props.setValue}
      style={{ width: "350px" }}
      allowClear={true}
      placeholder="No confidence set"
    >
      <Select.Option value={InvoiceAuditProblemConfidence.High}>
        <InvoiceAuditProblemConfidenceDropdownItem
          header="High"
          description={describeInvoiceAuditConfidence(
            InvoiceAuditProblemConfidence.High,
            props.invoiceAuditFault
          )}
        />
      </Select.Option>
      <Select.Option value={InvoiceAuditProblemConfidence.Medium}>
        <InvoiceAuditProblemConfidenceDropdownItem
          header="Medium"
          description={describeInvoiceAuditConfidence(
            InvoiceAuditProblemConfidence.Medium,
            props.invoiceAuditFault
          )}
        />
      </Select.Option>
      <Select.Option value={InvoiceAuditProblemConfidence.Low}>
        <InvoiceAuditProblemConfidenceDropdownItem
          header="Low"
          description={describeInvoiceAuditConfidence(
            InvoiceAuditProblemConfidence.Low,
            props.invoiceAuditFault
          )}
        />
      </Select.Option>
    </Select>
  );
}

function requiresCorrectedLocationTypeInfo(
  invoiceAuditProblem: InvoiceAuditProblem
): boolean {
  return (
    invoiceAuditProblem.type ===
      InvoiceAuditProblemType.PickupLocationTypeChange ||
    invoiceAuditProblem.type ===
      InvoiceAuditProblemType.DeliveryLocationTypeChange
  );
}

function EditInvoiceAuditProblem(props: EditInvoiceAuditProblemProps) {
  const [form] = Form.useForm();
  const { invoiceAuditProblem, setInvoiceAuditProblem } = props;
  const [attachments, setAttachments] = useState<ShipmentDocument[]>([]);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  function onChangeCustomerVisibleDetail(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, customerVisibleDetail: value }));
  }

  function onChangeCorrectedLocationType(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, correctedLocationType: value }));
  }

  function onChangeInternalVisibleDetail(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, internalVisibleDetail: value }));
  }

  function onChangeInternalEvidence(value: string) {
    setInvoiceAuditProblem((iap) => ({ ...iap, internalEvidence: value }));
  }

  function onChangeResolutionType(
    value: InvoiceAuditProblemResolutionType | undefined
  ) {
    setInvoiceAuditProblem((iap) => ({ ...iap, resolutionType: value }));
  }

  function onChangeResolutionState(
    value: InvoiceAuditProblemResolutionState | undefined
  ) {
    setInvoiceAuditProblem((iap) => ({ ...iap, resolutionState: value }));
  }

  function onChangeFault(value: InvoiceAuditProblemFault | undefined) {
    setInvoiceAuditProblem((iap) => ({ ...iap, fault: value }));
  }

  function onChangeConfidence(
    value: InvoiceAuditProblemConfidence | undefined
  ) {
    setInvoiceAuditProblem((iap) => ({ ...iap, confidence: value }));
  }

  function onChangeEstimatedCostDifference(value: number) {
    setInvoiceAuditProblem((iap) => ({
      ...iap,
      estimatedCostDifference: value,
    }));
  }

  function onChangeType(value: InvoiceAuditProblemType | undefined) {
    setInvoiceAuditProblem((iap) => ({ ...iap, type: value }));
  }

  function onChangeDocuments(value: ShipmentDocument[]) {
    const documentIds = value.map((o) => o.shipmentDocumentId!!);
    setInvoiceAuditProblem((iap) => ({
      ...iap,
      relatedDocumentIds: documentIds,
    }));
  }

  return (
    <>
      <Form {...formItemLayout} layout="horizontal" form={form}>
        <Form.Item label="Problem Type">
          <Stack align="left">
            <InvoiceAuditProblemTypeDropdown
              value={invoiceAuditProblem.type}
              setValue={onChangeType}
            />
            <div style={{ marginTop: "2px", color: Colors.LightText }}>
              {shipmentInfoMessageForInvoiceAuditType(
                invoiceAuditProblem.type,
                props.shipmentData
              )}
            </div>
          </Stack>
        </Form.Item>
        {requiresCorrectedLocationTypeInfo(invoiceAuditProblem) && (
          <Form.Item label="Corrected Location Type">
            <Select
              value={invoiceAuditProblem.correctedLocationType}
              style={{ width: 300 }}
              onChange={function (e) {
                onChangeCorrectedLocationType(e);
              }}
              showSearch
            >
              {getLocationTypes().map(function (type) {
                return (
                  <Select.Option value={type}>
                    {describeLocationType(type)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Fault">
          <InvoiceAuditProblemFaultDropdown
            value={invoiceAuditProblem.fault}
            setValue={onChangeFault}
          />
        </Form.Item>
        {invoiceAuditProblem.fault !== InvoiceAuditProblemFault.Unknown && (
          <Form.Item label="Confidence">
            <InvoiceAuditProblemConfidenceDropdown
              value={invoiceAuditProblem.confidence}
              setValue={onChangeConfidence}
              invoiceAuditFault={invoiceAuditProblem.fault}
            />
          </Form.Item>
        )}
        <Form.Item label="Customer Visible Detail">
          <Stack align="left">
            <div
              style={{
                marginTop: "5px",
                marginBottom: "2px",
                color: Colors.LightText,
              }}
            >
              This information will go on the invoice in case of additional
              charge
            </div>
            <Input
              value={invoiceAuditProblem.customerVisibleDetail}
              onChange={function (e) {
                onChangeCustomerVisibleDetail(e.target.value);
              }}
            />
          </Stack>
        </Form.Item>
        <Form.Item label="Internal Visible Notes">
          <Stack align="left">
            <div
              style={{
                marginTop: "5px",
                marginBottom: "2px",
                color: Colors.LightText,
              }}
            >
              Any other notes, thoughts, ideas about this problem. Like an
              internal scratch pad
            </div>
            <TextArea
              value={invoiceAuditProblem.internalVisibleDetail}
              onChange={function (e) {
                onChangeInternalVisibleDetail(e.target.value);
              }}
              style={{ height: "100px" }}
            />
          </Stack>
        </Form.Item>
        <Form.Item label="Internal Evidence">
          <Stack align="left">
            <div
              style={{
                marginTop: "5px",
                marginBottom: "2px",
                color: Colors.LightText,
              }}
            >
              Describe the evidence that we have supporting this
            </div>
            <TextArea
              value={invoiceAuditProblem.internalEvidence}
              onChange={function (e) {
                onChangeInternalEvidence(e.target.value);
              }}
              style={{ height: "100px" }}
            />
          </Stack>
        </Form.Item>

        <Form.Item label="Estimated Cost Difference">
          <InputNumber
            prefix="$"
            value={invoiceAuditProblem.estimatedCostDifference}
            onChange={onChangeEstimatedCostDifference}
          />
        </Form.Item>
        <Form.Item label="Related Documents">
          <ManageAttachmentsButton
            shipmentData={props.shipmentData}
            attachments={attachments}
            onUpdateAttachments={function (documents: ShipmentDocument[]) {
              onChangeDocuments(documents);
              setAttachments(documents);
            }}
          />
        </Form.Item>
        <Form.Item label="Resolution State">
          <InvoiceAuditProblemResolutionStateDropdown
            value={invoiceAuditProblem.resolutionState}
            setValue={onChangeResolutionState}
          />
        </Form.Item>
        {invoiceAuditProblem.resolutionState !==
          InvoiceAuditProblemResolutionState.Unresolved && (
          <Form.Item label="Resolution Type">
            <InvoiceAuditProblemResolutionTypeDropdown
              value={invoiceAuditProblem.resolutionType}
              setValue={onChangeResolutionType}
            />
          </Form.Item>
        )}
      </Form>
    </>
  );
}

interface EditInvoiceAuditProblemButtonProps extends TabProps {
  index: number;
}

function DeleteInvoiceAuditProblemButton(
  props: EditInvoiceAuditProblemButtonProps
) {
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    try {
      const shipmentId = props.shipmentData.shipmentId;
      const shipmentApi = await createShipmentApi();
      const updated = [
        ...props.shipmentData.shipment.shipment.invoiceAuditProblems!!,
      ];
      updated.splice(props.index, 1);
      await shipmentApi.updateInvoiceAuditProblems({
        shipmentId,
        invoiceAuditProblem: updated,
      });
      await props.onRefresh();
      message.success("Deleted");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
  }

  return (
    <ConfirmMenuItem
      loading={loading}
      onConfirm={onClick}
      question={"Are you sure you want to delete this problem?"}
      okText={"Yes, delete"}
      tooltip={"This will delete the problem"}
    >
      Delete
    </ConfirmMenuItem>
  );
}

export function EditInvoiceAuditProblemButton(
  props: EditInvoiceAuditProblemButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceAuditProblem, setInvoiceAuditProblem] = useState(
    props.shipmentData.shipment.shipment.invoiceAuditProblems!![props.index]
  );
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  useEffect(
    function () {
      if (isModalVisible) {
        // Refresh when the modal is reopened
        setInvoiceAuditProblem(
          props.shipmentData.shipment.shipment.invoiceAuditProblems!![
            props.index
          ]
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (!validateInvoiceAuditProblem(invoiceAuditProblem)) {
      return;
    }

    setLoading(true);
    try {
      const shipmentId = props.shipmentData.shipmentId;
      const shipmentApi = await createShipmentApi();

      const updatedProblems = [
        ...props.shipmentData.shipment.shipment.invoiceAuditProblems!!,
      ];
      updatedProblems[props.index] = invoiceAuditProblem;
      await shipmentApi.updateInvoiceAuditProblems({
        shipmentId,
        invoiceAuditProblem: updatedProblems,
      });

      await props.onRefresh();
      message.success("Saved!");
      setIsModalVisible(false);
    } catch (e) {
      message.error("Oops something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Edit Invoice Audit Problem"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
        confirmLoading={loading}
      >
        <EditInvoiceAuditProblem
          invoiceAuditProblem={invoiceAuditProblem}
          setInvoiceAuditProblem={setInvoiceAuditProblem}
          {...props}
        />
      </Modal>
      <MenuItem onClick={showModal}>Edit</MenuItem>
    </>
  );
}

function validateInvoiceAuditProblem(
  invoiceAuditProblem: InvoiceAuditProblem
): boolean {
  if (invoiceAuditProblem.type === undefined) {
    message.error("Missing type");
    return false;
  }

  if (invoiceAuditProblem.fault === undefined) {
    message.error("Missing fault");
    return false;
  }

  if (
    invoiceAuditProblem.fault !== InvoiceAuditProblemFault.Unknown &&
    invoiceAuditProblem.confidence === undefined
  ) {
    message.error("Missing confidence");
    return false;
  }

  if (
    invoiceAuditProblem.resolutionState !==
      InvoiceAuditProblemResolutionState.Unresolved &&
    invoiceAuditProblem.resolutionType === undefined
  ) {
    message.error("Missing resolution type");
    return false;
  }

  if (
    invoiceAuditProblem.resolutionState !==
      InvoiceAuditProblemResolutionState.Unresolved &&
    invoiceAuditProblem.fault === InvoiceAuditProblemFault.Unknown
  ) {
    message.error("Cannot resolve when fault is unknown");
    return false;
  }

  if (
    requiresCorrectedLocationTypeInfo(invoiceAuditProblem) &&
    isBlank(invoiceAuditProblem.correctedLocationType)
  ) {
    message.error("Corrected location type is required");
    return false;
  }

  return true;
}

function emptyInvoiceAuditProblem(): InvoiceAuditProblem {
  return {
    invoiceAuditProblemId: uuidv4(),
    type: undefined,
    confidence: undefined,
    fault: InvoiceAuditProblemFault.Unknown,
    customerVisibleDetail: "",
    internalVisibleDetail: "",
    estimatedCostDifference: 0,
    correctedLocationType: undefined,
    resolutionState: InvoiceAuditProblemResolutionState.Unresolved,
    resolutionType: undefined,
  };
}

export function AddInvoiceAuditProblemButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceAuditProblem, setInvoiceAuditProblem] = useState(
    emptyInvoiceAuditProblem()
  );
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (!validateInvoiceAuditProblem(invoiceAuditProblem)) {
      console.log("Validation issues");
      return;
    }

    setLoading(true);
    try {
      const shipmentId = props.shipmentData.shipmentId;
      const shipmentApi = await createShipmentApi();
      await shipmentApi.updateInvoiceAuditProblems({
        shipmentId,
        invoiceAuditProblem: [
          ...(props.shipmentData.shipment.shipment.invoiceAuditProblems ?? []),
          invoiceAuditProblem,
        ],
      });

      await props.onRefresh();
      message.success("Added");
      setInvoiceAuditProblem(emptyInvoiceAuditProblem());
      setIsModalVisible(false);
    } catch (e) {
      message.error("Ooops. Something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Add Invoice Audit Problem"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
        confirmLoading={loading}
      >
        <EditInvoiceAuditProblem
          invoiceAuditProblem={invoiceAuditProblem}
          setInvoiceAuditProblem={setInvoiceAuditProblem}
          {...props}
        />
      </Modal>
      <Button onClick={showModal} icon={<PlusOutlined />}>
        Add Invoice Audit Problem
      </Button>
    </>
  );
}

interface InvoiceAuditResolutionStateTagProps {
  resolutionState: InvoiceAuditProblemResolutionState | undefined;
  resolutionType: InvoiceAuditProblemResolutionType | undefined;
}

function describeInvoiceAuditResolutionState(
  resolutionState: InvoiceAuditProblemResolutionState
): string {
  switch (resolutionState) {
    case InvoiceAuditProblemResolutionState.Resolved:
      return "✅ Resolved";
    case InvoiceAuditProblemResolutionState.Pending:
      return "⌛ Pending";
    case InvoiceAuditProblemResolutionState.Unresolved:
      return "🤔 Unresolved";
    default:
      assertNever(resolutionState);
  }
}

function describeInvoiceAuditResolutionType(
  resolutionType: InvoiceAuditProblemResolutionType | undefined
): string {
  switch (resolutionType) {
    case InvoiceAuditProblemResolutionType.AdditionalChargeIssued:
      return "(Additional Charge Issued)";
    case InvoiceAuditProblemResolutionType.CarrierCorrection:
      return "(Carrier Correction)";
    case InvoiceAuditProblemResolutionType.FreightsimpleAbsorbedCost:
      return "(We Absorbed Cost)";
    case InvoiceAuditProblemResolutionType.Minor:
      return "(Minor)";
    case undefined:
      return "";
    default:
      assertNever(resolutionType);
  }
}

function InvoiceAuditResolutionStateTag(
  props: InvoiceAuditResolutionStateTagProps
) {
  if (props.resolutionState === undefined) {
    return <></>;
  }

  return (
    <Tag
      style={{
        textDecoration:
          props.resolutionState === InvoiceAuditProblemResolutionState.Resolved
            ? "line-through"
            : undefined,
      }}
      color={colorForInvoiceAuditProblemResolutionState(props.resolutionState)}
    >
      {describeInvoiceAuditResolutionState(props.resolutionState)}{" "}
      {describeInvoiceAuditResolutionType(props.resolutionType)}
    </Tag>
  );
}

interface InvoiceAuditProblemsTableProps extends TabProps {
  problems: InvoiceAuditProblem[];
  selected?: string[];
  onSelect?: (_: string[]) => void;
  isCheckboxDisabled?: (_: InvoiceAuditProblem) => boolean;
  isCheckboxDisabledDescription?: (
    _: InvoiceAuditProblem
  ) => string | undefined;
}

export function InvoiceAuditProblemsTable(
  props: InvoiceAuditProblemsTableProps
) {
  const columns: DataTableColumn<InvoiceAuditProblem>[] = [];

  columns.push({
    title: "Resolution",
    render: (o) => (
      <Stack>
        <InvoiceAuditResolutionStateTag
          resolutionState={o.resolutionState}
          resolutionType={o.resolutionType}
        />
      </Stack>
    ),
  });

  columns.push({
    title: "Fault",
    render: (o) => (
      <Stack align="center">
        <div>
          {emojiForInvoiceAuditProblemFault(o.fault!!)}{" "}
          {capitalizeFirstLetter(o.fault)}
        </div>
      </Stack>
    ),
  });

  columns.push({
    title: "Type",
    render: (o) => (
      <Stack align="center">
        <div>{describeInvoiceAuditProblemType(o)}</div>
        {!isInvoiceAuditProblemTypeAllowedForAdditionalCharge(o.type!!) && (
          <div>
            <Tag style={{ marginTop: "4px" }} color="orange">
              ⚠️ Type not allowed for additional charge
            </Tag>
          </div>
        )}
      </Stack>
    ),
  });

  columns.push({
    title: "Confidence",
    render: (o) => (
      <InvoiceAuditProblemConfidenceTag confidence={o.confidence!!} />
    ),
  });

  columns.push({
    title: "Detail",
    render: (o) => (
      <>
        {o.customerVisibleDetail && (
          <>
            <CellHeader>Customer Visible Detail</CellHeader>
            {o.customerVisibleDetail}
          </>
        )}
        {o.internalEvidence && (
          <>
            <CellHeader>Internal Evidence</CellHeader>
            {o.internalEvidence}
          </>
        )}
        {o.internalVisibleDetail && (
          <>
            <CellHeader>Internal Visible Notes</CellHeader>
            {o.internalVisibleDetail}
          </>
        )}
      </>
    ),
  });

  columns.push({
    title: "Related Documents",
    render: function (o) {
      return (
        <>
          {o.relatedDocumentIds?.map(function (documentId) {
            const document = props.shipmentData.documentsTab.documents.find(
              (d) => d.shipmentDocument.shipmentDocumentId === documentId
            )?.shipmentDocument;
            if (document !== undefined) {
              return (
                <ViewDocumentButton
                  includeTypeInButtonName
                  shipmentData={props.shipmentData}
                  document={document}
                />
              );
            } else {
              return <div>Missing document</div>;
            }
          })}
        </>
      );
    },
  });

  columns.push({
    title: "Estimated Cost",
    render: (o) => <>${o.estimatedCostDifference}</>,
  });

  columns.push({
    title: "Actions",
    render: (o, index) => (
      <ButtonRow
        extrasMenu={
          <Menu>
            <EditInvoiceAuditProblemButton index={index} {...props} />
            <DeleteInvoiceAuditProblemButton index={index} {...props} />
          </Menu>
        }
      >
        <></>
      </ButtonRow>
    ),
  });

  const getCheckboxProps = (record: InvoiceAuditProblem) => ({
    disabled:
      props.isCheckboxDisabled !== undefined
        ? props.isCheckboxDisabled(record)
        : false,
  });

  const renderCell = (
    checked: boolean,
    record: InvoiceAuditProblem,
    index: number,
    originNode: React.ReactNode
  ) => (
    <Tooltip
      title={
        props.isCheckboxDisabledDescription !== undefined
          ? props.isCheckboxDisabledDescription(record)
          : undefined
      }
    >
      {" "}
      {/* Assuming you have a 'tooltipText' property */}
      {originNode}
    </Tooltip>
  );

  return (
    <Stack align="left">
      <DataTable
        pagination={false}
        columns={columns}
        data={props.problems}
        rowKey={(p) => p.invoiceAuditProblemId!!}
        rowSelection={
          props.onSelect
            ? {
                type: "checkbox",
                selectedRowKeys: props.selected ?? [],
                onChange: (
                  _selectedRowKeys: React.Key[],
                  _selectedRows: InvoiceAuditProblem[]
                ) => {
                  console.log(
                    `selectedRowKeys: ${_selectedRowKeys}`,
                    "selectedRows: ",
                    _selectedRows
                  );
                  if (props.onSelect !== undefined)
                    props.onSelect(
                      _selectedRows.map(function (row) {
                        return row.invoiceAuditProblemId!!;
                      })
                    );
                },
                getCheckboxProps: getCheckboxProps,
                renderCell: renderCell,
              }
            : undefined
        }
      />
    </Stack>
  );
}

export function IdentifyInvoiceAuditProblemsSection(props: TabProps) {
  const noProblems =
    props.shipmentData.shipment.shipment.invoiceAuditProblems!!.length === 0;

  return (
    <>
      <HorizontalStack align="spread">
        <Title level={4}>Invoice Audit Problems</Title>
        <ButtonRow>
          <AddInvoiceAuditProblemButton {...props} />
        </ButtonRow>
      </HorizontalStack>
      {noProblems && (
        <div
          style={{
            backgroundColor: "#fafafa",
            marginTop: "16px",
            border: "1px solid #ddd",
            width: "100%",
            padding: "24px",
          }}
        >
          ⚠️ No problems identified
        </div>
      )}
      {!noProblems && (
        <InvoiceAuditProblemsTable
          problems={props.shipmentData.shipment.shipment.invoiceAuditProblems!!}
          {...props}
        />
      )}
    </>
  );
}
