/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApolloMapCompany
 */
export interface ApolloMapCompany {
    /**
     * 
     * @type {string}
     * @memberof ApolloMapCompany
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapCompany
     */
    companyId: string;
}

/**
 * Check if a given object implements the ApolloMapCompany interface.
 */
export function instanceOfApolloMapCompany(value: object): boolean {
    if (!('companyName' in value)) return false;
    if (!('companyId' in value)) return false;
    return true;
}

export function ApolloMapCompanyFromJSON(json: any): ApolloMapCompany {
    return ApolloMapCompanyFromJSONTyped(json, false);
}

export function ApolloMapCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapCompany {
    if (json == null) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyId': json['companyId'],
    };
}

export function ApolloMapCompanyToJSON(value?: ApolloMapCompany | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyName': value['companyName'],
        'companyId': value['companyId'],
    };
}

