import { LineItem } from "../../generated-openapi-client";
import { DangerousCell } from "../../Screens/ViewShipmentScreenComponents/LineItemsTable";
import { LeftRightDifference } from "./LeftRightDifference";
import { ChangesDataTable } from "./ChangesDataTable";
import { ItemListComparator } from "../comparators/ItemListComparator";
import { SimpleItemListComparator } from "../comparators/SimpleItemListComparator";
import { DataTableColumn } from "../DataTable";
import { Weight } from "../Weight";
import { ReactNode } from "react";

interface LineItemsChangesTableProps {
  left: Array<LineItem>;
  inBetween?: ReactNode;
  right: Array<LineItem>;
  data?: Array<LineItem>;
}

/**
 * @param left - list of line items
 * @param right - list of line items
 * @param data - (optional) data to pass to the data table (if this prop is not set 'left' list will be passed)
 */
export function LineItemsChangesTable({
  left,
  inBetween,
  right,
  data,
}: LineItemsChangesTableProps) {
  const isAddition = right.length > left.length;

  let defaultData = data;

  if (defaultData === undefined) {
    defaultData = isAddition ? right : left;
  }

  const columns: DataTableColumn<LineItem>[] = [
    {
      width: 150,
      title: "HUs",
      render: (o, i) => (
        <ItemListComparator
          index={i}
          compareFn={(left: LineItem, right: LineItem) =>
            left.numberHandlingUnits == right.numberHandlingUnits &&
            left.handlingUnitType == right.handlingUnitType
          }
          onEquals={(e: LineItem) =>
            `${e.numberHandlingUnits} x ${e.handlingUnitType}`
          }
          onNotFound={(e: LineItem) =>
            `${e.numberHandlingUnits} x ${e.handlingUnitType}`
          }
          onChanges={(left: LineItem, right: LineItem) => (
            <LeftRightDifference
              inBetween={inBetween}
              left={`${left.numberHandlingUnits} x ${left.handlingUnitType}`}
              right={`${right.numberHandlingUnits} x ${right.handlingUnitType}`}
            />
          )}
        />
      ),
    },
    {
      title: "Description",
      render: (o, i) => (
        <SimpleItemListComparator
          index={i}
          elementKey="description"
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Length",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="length"
          index={i}
          component={(value) => <>{value}"</>}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Width",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="width"
          index={i}
          component={(value) => <>{value}"</>}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Height",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="height"
          index={i}
          component={(value) => <>{value}"</>}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "lb/HU",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="weightPerHandlingUnit"
          index={i}
          component={(value) => <Weight>{value}</Weight>}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Total",
      render: (o, i) => (
        <ItemListComparator
          index={i}
          compareFn={(left: LineItem, right: LineItem) =>
            left.weightPerHandlingUnit!! * left.numberHandlingUnits!! ==
            right.weightPerHandlingUnit!! * right.numberHandlingUnits!!
          }
          onEquals={(e: LineItem) => (
            <Weight>
              {e.numberHandlingUnits!! * e.weightPerHandlingUnit!!}
            </Weight>
          )}
          onNotFound={(e: LineItem) => (
            <Weight>
              {e.numberHandlingUnits!! * e.weightPerHandlingUnit!!}
            </Weight>
          )}
          onChanges={(left: LineItem, right: LineItem) => (
            <LeftRightDifference
              left={left.weightPerHandlingUnit!! * left.numberHandlingUnits!!}
              right={
                right.weightPerHandlingUnit!! * right.numberHandlingUnits!!
              }
              inBetween={inBetween}
            />
          )}
        />
      ),
    },
    {
      title: "Class",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="freightClass"
          index={i}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Temp",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="temperatureHandling"
          index={i}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Dangerous",
      render: (o, i) => (
        <ItemListComparator
          index={i}
          compareFn={(left: LineItem, right: LineItem) =>
            left.isDangerous == right.isDangerous &&
            left.dangerousUnNumber == right.dangerousUnNumber &&
            left.dangerousClassification == right.dangerousClassification &&
            left.dangerousPackingGroup == right.dangerousPackingGroup &&
            left.dangerousNumberPackages == right.dangerousNumberPackages &&
            left.dangerousPackagingType == right.dangerousPackagingType &&
            left.dangerousProperShippingName ==
              right.dangerousProperShippingName &&
            left.dangerousTechnicalName == right.dangerousTechnicalName
          }
          onEquals={(e: LineItem) => <DangerousCell lineItem={e} />}
          onNotFound={(e: LineItem) => <DangerousCell lineItem={e} />}
          onChanges={(left: LineItem, right: LineItem) => (
            <LeftRightDifference
              left={<DangerousCell lineItem={left} />}
              right={<DangerousCell lineItem={right} />}
              inBetween={inBetween}
            />
          )}
        />
      ),
    },
    {
      title: "NMFC",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="nmfcItemNumber"
          index={i}
          inBetween={inBetween}
        />
      ),
    },
    {
      title: "Stackable",
      render: (o, i) => (
        <SimpleItemListComparator
          elementKey="isStackable"
          index={i}
          component={(value) => <span>{value ? "✅" : "❌"}</span>}
          inBetween={inBetween}
        />
      ),
    },
  ];

  if (!data) {
    data = left;
  }

  return (
    <ChangesDataTable
      left={left}
      right={right}
      pagination={false}
      columns={columns}
      data={defaultData}
    />
  );
}
