/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CarrierInvoiceApprovalMethod = {
    Automated: 'automated',
    Manual: 'manual'
} as const;
export type CarrierInvoiceApprovalMethod = typeof CarrierInvoiceApprovalMethod[keyof typeof CarrierInvoiceApprovalMethod];


export function instanceOfCarrierInvoiceApprovalMethod(value: any): boolean {
    return Object.values(CarrierInvoiceApprovalMethod).includes(value);
}

export function CarrierInvoiceApprovalMethodFromJSON(json: any): CarrierInvoiceApprovalMethod {
    return CarrierInvoiceApprovalMethodFromJSONTyped(json, false);
}

export function CarrierInvoiceApprovalMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInvoiceApprovalMethod {
    return json as CarrierInvoiceApprovalMethod;
}

export function CarrierInvoiceApprovalMethodToJSON(value?: CarrierInvoiceApprovalMethod | null): any {
    return value as any;
}

