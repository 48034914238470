import { Button, Modal } from "antd";
import { useState } from "react";
import { EmailTemplateImage } from "../../generated-openapi-client";

interface InsertImageButtonProps {
  onInsertText: (_text: string) => void;
  images: EmailTemplateImage[];
}

export function InsertImageButton(props: InsertImageButtonProps) {
  const images = props.images;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function onSelect(index: number) {
    props.onInsertText(
      `\n![${images[index].title}](${images[index].url} "${images[index].title}")`
    );
    setIsModalVisible(false);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Select Image"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
      >
        <div style={{ marginBottom: "8px" }}>
          To add more images, go to 'Technical', then 'Email Templates'
        </div>
        <div
          style={{
            display: "grid",
            gridGap: "16px",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          }}
        >
          {images.map(function (image, index) {
            return (
              <img
                src={image.url}
                style={{ cursor: "pointer", maxHeight: "100px" }}
                onClick={function () {
                  onSelect(index);
                }}
              />
            );
          })}
        </div>
      </Modal>
      <Button type="link" onClick={showModal}>
        Insert Image
      </Button>
    </>
  );
}
