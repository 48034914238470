import { CurrencyCode } from "../generated-openapi-client/models/CurrencyCode";

const USD_TO_CAD_EXCHANGE_RATE = 1.32;

export function convertToCad(amount: number, currency: CurrencyCode): number {
  if (currency === CurrencyCode.Cad) {
    return amount;
  } else if (currency === CurrencyCode.Usd) {
    return USD_TO_CAD_EXCHANGE_RATE * amount;
  }

  throw new Error("Should not be here");
}
