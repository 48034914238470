import NumberFormat from "react-number-format";

interface LinearFeetProps {
  linearFeet: number | undefined;
}

export function LinearFeet(props: LinearFeetProps) {
  if (props.linearFeet === undefined) {
    return <>-</>;
  }
  return (
    <>
      <NumberFormat
        value={props.linearFeet}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
        suffix={"'"}
      />
    </>
  );
}
