import { Select, SelectProps } from "antd";
import { ReactNode } from "react";

interface EnumDropdownProps<T> {
  value: T;
  setValue: (_: T) => void;
}

// This is a component generator
export function EnumDropdown<S, T extends { [name: string]: any }>(
  enumObject: T,
  describer?: (_: S) => ReactNode,
  width?: number
) {
  const concreteDescriber =
    describer ||
    function (x) {
      return <>{x}</>;
    };
  return function (props: EnumDropdownProps<S>) {
    return (
      <Select
        value={props.value}
        style={{ width: width ?? 220 }}
        onChange={function (e) {
          props.setValue(e);
        }}
      >
        {Object.entries(enumObject).map(function (value) {
          const v = value[1];
          return (
            <Select.Option value={v}>{concreteDescriber(v)}</Select.Option>
          );
        })}
      </Select>
    );
  };
}

type OptionalEnumDropdownProps<T> = {
  value: T | undefined;
  setValue: (_: T | undefined) => void;
  disabled?: boolean;
} & Pick<SelectProps, "mode">;

// This is a component generator
export function OptionalEnumDropdown<S, T extends { [name: string]: any }>(
  enumObject: T,
  placeholder: ReactNode,
  describer?: (_: S) => ReactNode | undefined,
  width?: number
) {
  const concreteDescriber =
    describer ||
    function (x) {
      return <>{x}</>;
    };
  return function (props: OptionalEnumDropdownProps<S>) {
    console.log(`enum`, { enum: Object.entries(enumObject) });
    return (
      <Select
        mode={props.mode}
        value={props.value}
        style={{ width: width ?? 220 }}
        onChange={function (e) {
          props.setValue(e);
        }}
        placeholder={placeholder}
        allowClear
        disabled={props.disabled}
      >
        {Object.entries(enumObject).map(function (value) {
          const v = value[1];
          const description = concreteDescriber(v);
          if (description === undefined) {
            return null;
          }
          return (
            <Select.Option key={v} value={v}>
              {description}
            </Select.Option>
          );
        })}
      </Select>
    );
  };
}
