/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarDataPoint
 */
export interface CalendarDataPoint {
    /**
     * 
     * @type {string}
     * @memberof CalendarDataPoint
     */
    day: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarDataPoint
     */
    value: number;
}

/**
 * Check if a given object implements the CalendarDataPoint interface.
 */
export function instanceOfCalendarDataPoint(value: object): boolean {
    if (!('day' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function CalendarDataPointFromJSON(json: any): CalendarDataPoint {
    return CalendarDataPointFromJSONTyped(json, false);
}

export function CalendarDataPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarDataPoint {
    if (json == null) {
        return json;
    }
    return {
        
        'day': json['day'],
        'value': json['value'],
    };
}

export function CalendarDataPointToJSON(value?: CalendarDataPoint | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'day': value['day'],
        'value': value['value'],
    };
}

