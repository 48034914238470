import { Cascader } from "antd";
import CarriersData from "../Fixtures/CarriersData.json";
import { groupBy } from "../Helpers/groupBy";

interface CarrierAndServiceFilterProps {
  carrierIdentifier: string | undefined;
  serviceIdentifier: string | undefined;
  onChange: (carrierIdentifier: string, serviceIdentifier: string) => void;
}

function convertCarriersToOptions() {
  return groupBy(CarriersData, (c) => c.carrierIdentifier).map(function (c) {
    return {
      value: c.key,
      label: c.key,
      children: c.value.map(function (s) {
        return {
          value: s.serviceIdentifier,
          label: s.serviceIdentifier,
        };
      }),
    };
  });
}

export function CarrierAndServiceFilter(props: CarrierAndServiceFilterProps) {
  return (
    <Cascader
      value={
        props.carrierIdentifier !== undefined &&
        props.serviceIdentifier !== undefined
          ? [props.carrierIdentifier, props.serviceIdentifier]
          : []
      }
      options={convertCarriersToOptions()}
      onChange={function (value: (string | number)[]) {
        console.log({ value });
        props.onChange(value[0].toString(), value[1].toString());
      }}
      placeholder="Please select"
    />
  );
}
