import { EmailLink } from "../../Components/EmailLink";
import { KeyValues } from "../../Components/KeyValues";
import { PhoneLink } from "../../Components/PhoneLink";
import Stack from "../../Components/Stack";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client";

interface CarrierContactsProps {
  data: { [key: string]: Array<string> };
  shipmentData: ViewShipmentDataForApollo;
}

export function CarrierContacts(props: CarrierContactsProps) {
  const data: Record<string, any> = {};
  const contacts = props.data;
  const entries = Object.entries(contacts);
  entries.forEach(function (entry) {
    data[entry[0]] = (
      <Stack align="left">
        {entry[1].map(function (email) {
          return <EmailLink email={email} data={props.shipmentData} />;
        })}
      </Stack>
    );
  });

  return <KeyValues data={data} />;
}

export function CarrierPhoneContacts(props: CarrierContactsProps) {
  const data: Record<string, any> = {};
  const contacts = props.data;
  const entries = Object.entries(contacts);
  entries.forEach(function (entry) {
    data[entry[0]] = entry[1].map(function (phone) {
      return (
        <PhoneLink
          phone={phone}
          shipmentId={props.shipmentData.shipmentId}
          description={entry[0]}
        />
      );
    });
  });

  return <KeyValues data={data} />;
}
