import { Form, Menu, message, Modal } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { DateInput } from "../../Components/DateInput";
import { CustomerInvoice } from "../../generated-openapi-client/models/CustomerInvoice";
import { CustomerInvoiceState } from "../../generated-openapi-client";

interface ChangeCustomerInvoiceDueDateMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function ChangeCustomerInvoiceDueDateMenuItem(
  props: ChangeCustomerInvoiceDueDateMenuItemProps
) {
  const title = "Change Customer Invoice Due Date";
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newDueDate, setNewDueDate] = useState(props.customerInvoice.dueDate!!);
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.changeDueDate({
        customerInvoiceId,
        newDueDate,
      });
      await props.onRefresh();
      message.success("Invoice Due Date Changed");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
    props.onCloseParent();
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (
    props.customerInvoice.customerInvoiceState !== CustomerInvoiceState.Issued
  ) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        destroyOnClose={true}
        confirmLoading={loading}
        width={800}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Due Date">
            <DateInput
              date={newDueDate}
              setDate={setNewDueDate}
              compactErrorsAndWarnings
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>{title}</Menu.Item>
    </>
  );
}
