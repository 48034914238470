import {
  Card,
  Form,
  Input,
  Select,
  Statistic,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useShipmentsApi } from "../Apis/Apis";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import Colors from "../Components/Colors";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Weight } from "../Components/Weight";
import { emojiForCountry } from "../Helpers/emojiForCountry";
import { isPhone } from "../Helpers/isPhone";
import { safeColumns } from "../Helpers/safeColumns";
import {
  StarredShipment,
  useStarredShipments,
} from "../Helpers/starredShipments";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import { ActiveShipments } from "../generated-openapi-client/models/ActiveShipments";
import { CompanyShipment } from "../generated-openapi-client/models/CompanyShipment";
import { CompanyShipmentQuote } from "../generated-openapi-client/models/CompanyShipmentQuote";
import { Shipment } from "../generated-openapi-client/models/Shipment";
import { ShipmentState } from "../generated-openapi-client/models/ShipmentState";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { describeState } from "../Helpers/describeState";
import moment from "moment-timezone";

const { Option } = Select;

const { Title } = Typography;

interface AdditionalColumns<T> {
  index: number;
  column: ColumnGroupType<T> | ColumnType<T>;
}

interface ShipmentTableProps {
  shipments: CompanyShipmentQuote[];
  title: string;
  companyIdFilter: string | undefined;
  carrierIdentifierFilter: string | undefined;
  onlyShowFlagged: boolean;
  includePickupNumbers?: boolean;
  starredShipments: StarredShipment[];
  additionalColumns?: AdditionalColumns<CompanyShipmentQuote>[];
  freeFilter: string;
}

interface QuotedShipmentTableProps {
  shipments: CompanyShipment[];
  title: string;
  companyIdFilter: string | undefined;
  carrierIdentifierFilter: string | undefined;
  onlyShowFlagged: boolean;
  starredShipments: StarredShipment[];
}

interface CompanyFilterProps {
  activeShipments: ActiveShipments;
  onFilter: (companyId: string | undefined) => void;
}

function getCompany(csq: CompanyShipmentQuote | CompanyShipment) {
  return { name: csq.company.companyName!!, id: csq.company.companyId!! };
}

function getCompanyNames(
  activeShipments: ActiveShipments
): Array<{ id: string; name: string }> {
  const companies = [
    ...activeShipments.manuallyQuoting.map((c) => getCompany(c)),
    ...activeShipments.unfinishedBooking.map((c) => getCompany(c)),
    ...activeShipments.onHold.map((c) => getCompany(c)),
    ...activeShipments.pickingUpSoon.map((c) => getCompany(c)),
    ...activeShipments.pickupToday.map((c) => getCompany(c)),
    ...activeShipments.pickupIsLate.map((c) => getCompany(c)),
    ...activeShipments.inTransit.map((c) => getCompany(c)),
    ...activeShipments.delivered.map((c) => getCompany(c)),
  ];

  const m = new Map<string, string>();
  companies.forEach(function (c) {
    m.set(c.id, c.name);
  });

  console.log(m);

  const results: Array<{ id: string; name: string }> = [];
  m.forEach(function (name, id) {
    results.push({ id, name });
  });

  return results;
}

function CompanyFilter(props: CompanyFilterProps) {
  return (
    <Select
      allowClear
      showSearch
      style={{ width: isPhone ? 170 : 220 }}
      placeholder="Company"
      optionFilterProp="children"
      onSelect={function (companyId: string) {
        props.onFilter(companyId);
      }}
      onClear={function () {
        props.onFilter(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-ignore
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-ignore
        optionA.children
          // @ts-ignore
          .toLowerCase()
          // @ts-ignore
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {getCompanyNames(props.activeShipments).map(function (c) {
        console.log({ c });
        return <Option value={c.id}>{c.name}</Option>;
      })}
    </Select>
  );
}

interface QuotedShipmentCardProps {
  shipment: CompanyShipment;
}

function QuotedShipmentCard(props: QuotedShipmentCardProps) {
  const navigate = useNavigate();
  const shipmentId = props.shipment.shipment.shipmentId!!;
  const shipment = props.shipment.shipment;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const pickupAddress = props.shipment.shipment.pickupLocation?.address!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const deliveryAddress = props.shipment.shipment.deliveryLocation?.address!!;
  return (
    <Card
      onClick={function () {
        navigate(`/view-shipment?shipmentId=${shipmentId}`);
      }}
      size="small"
      title={
        <Stack align="left">
          <strong>{props.shipment.company.companyName}</strong>
        </Stack>
      }
      style={{
        width: 340,
        marginBottom: "16px",
        borderColor: colorForState(shipment.state!!),
        borderWidth: "3px",
      }}
      extra={
        <>
          {shipment.tags?.map((s) => (
            <Tag color="orange">{s}</Tag>
          ))}
          {shipment.problems?.map((s) => (
            <Tag color="magenta">🚩 {s}</Tag>
          ))}
          {shipment.freightMissing && <Tag color="yellow">⚠️ Missing</Tag>}
        </>
      }
    >
      <Stack align="left">
        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              width: "50px",
              paddingRight: "4px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            From:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.pickupLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {pickupAddress.city}, {pickupAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>

        <Spacer height={4} />

        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              paddingRight: "4px",
              width: "50px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            To:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.deliveryLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {deliveryAddress.city}, {deliveryAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>
      </Stack>
    </Card>
  );
}

interface ShipmentCardProps {
  shipment: CompanyShipmentQuote;
}

function colorForState(state: ShipmentState): string {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return Colors.Gold;
    case ShipmentState.Delivered:
      return Colors.Green;
    case ShipmentState.InTransit:
      return Colors.Blue;
    case ShipmentState.Quoted:
      return Colors.DarkGray;
    default:
      return Colors.Red;
  }
}

function ShipmentCard(props: ShipmentCardProps) {
  const navigate = useNavigate();
  const shipmentId = props.shipment.shipment.shipmentId!!;
  const shipment = props.shipment.shipment;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const pickupAddress = props.shipment.shipment.pickupLocation?.address!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const deliveryAddress = props.shipment.shipment.deliveryLocation?.address!!;
  return (
    <Card
      onClick={function () {
        navigate(`/view-shipment?shipmentId=${shipmentId}`);
      }}
      size="small"
      title={
        <Stack align="left">
          <CarrierLogo
            carrierIdentifier={props.shipment.quote.carrierIdentifier}
            brokeredCarrierIdentifier={
              props.shipment.quote.brokeredCarrierIdentifier
            }
            width={60}
            height={40}
          />
          <strong>{props.shipment.company.companyName}</strong>
        </Stack>
      }
      style={{
        width: 340,
        marginBottom: "16px",
        borderColor: colorForState(shipment.state!!),
        borderWidth: "3px",
      }}
      extra={
        <>
          {shipment.tags?.map((s) => (
            <Tag color="orange">{s}</Tag>
          ))}
          {shipment.problems?.map((s) => (
            <Tag color="magenta">🚩 {s}</Tag>
          ))}
          {shipment.freightMissing && <Tag color="yellow">⚠️ Missing</Tag>}
        </>
      }
    >
      <Stack align="left">
        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              width: "50px",
              paddingRight: "4px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            From:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.pickupLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {pickupAddress.city}, {pickupAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>

        <Spacer height={4} />

        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              paddingRight: "4px",
              width: "50px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            To:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.deliveryLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {deliveryAddress.city}, {deliveryAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>

        <Spacer height={4} />

        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              paddingRight: "4px",
              width: "50px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            Pro:
          </div>
          <div style={{ fontWeight: 500 }}>{shipment.proNumber}</div>
        </HorizontalStack>
        {shipment.state === ShipmentState.BookingConfirmed && (
          <>
            <Spacer height={4} />

            <HorizontalStack verticalAlign="middle">
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "4px",
                  width: "50px",
                  fontSize: "8px",
                  color: Colors.LightText,
                }}
              >
                Pickup #:
              </div>
              <div style={{ fontWeight: 500 }}>
                {shipment.carrierPickupNumber}
              </div>
            </HorizontalStack>
          </>
        )}
      </Stack>
    </Card>
  );
}

function filterActiveShipments(
  shipments: CompanyShipmentQuote[],
  companyIdFilter: string | undefined,
  carrierIdentifierFilter: string | undefined,
  onlyShowFlagged: boolean,
  freeFilter: string
) {
  return shipments
    .filter(function (c) {
      if (companyIdFilter !== undefined && companyIdFilter !== "") {
        return c.company.companyId === companyIdFilter;
      }

      return true;
    })

    .filter(function (c) {
      if (
        carrierIdentifierFilter !== undefined &&
        carrierIdentifierFilter !== ""
      ) {
        return c.quote.carrierIdentifier === carrierIdentifierFilter;
      }

      return true;
    })

    .filter(function (c) {
      if (onlyShowFlagged) {
        return (
          (c.shipment.problems?.length ?? 0) > 0 ||
          (c.shipment.tags?.length ?? 0) > 0
        );
      }
      return true;
    })
    .filter(function (c) {
      return JSON.stringify(c).toLowerCase().includes(freeFilter.toLowerCase());
    });
}

function ShipmentTable(props: ShipmentTableProps) {
  const shipments = filterActiveShipments(
    props.shipments,
    props.companyIdFilter,
    props.carrierIdentifierFilter,
    props.onlyShowFlagged,
    props.freeFilter
  );

  if (shipments.length === 0) {
    return <></>;
  }

  if (isPhone) {
    return (
      <>
        <Title style={{ marginBottom: "16px" }} level={5}>
          {props.title}
        </Title>
        <Stack align="left">
          {shipments.map(function (s) {
            return <ShipmentCard shipment={s} />;
          })}
        </Stack>
      </>
    );
  }

  const columns = safeColumns<CompanyShipmentQuote>([
    {
      title: "Booking Company",
      dataIndex: ["company", "companyName"],
      key: "companyName",
      render: function (text, o) {
        const isStarred = props.starredShipments.find(
          (s) => s.shipmentId === o.shipment.shipmentId
        );
        return (
          <Stack align="left">
            <div>{text}</div>
            <Spacer height={8} />
            {o.shipment.tags?.map((s) => (
              <Tag color="orange">{s}</Tag>
            ))}
            {o.shipment.problems?.map((s) => (
              <Tag color="magenta">🚩 {s}</Tag>
            ))}
            {o.shipment.freightMissing && <Tag color="yellow">⚠️ Missing</Tag>}
            {isStarred && <Tag color="yellow">⭐️ Starred</Tag>}
          </Stack>
        );
      },
      width: "230px",
    },
    {
      title: "Carrier",
      dataIndex: ["quote", "carrierIdentifier"],
      key: "carrierIdentifier",
      render: (text, o) => (
        <Stack>
          <CarrierLogo
            carrierIdentifier={text}
            brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
            width={80}
            height={30}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "10px",
              color: Colors.LightText,
            }}
          >
            {o.quote.serviceIdentifier}
          </div>
        </Stack>
      ),
      width: "100px",
    },
    {
      title: props.includePickupNumbers ? "Pickup Number" : "Pro",
      dataIndex: ["shipment", "proNumber"],
      key: "proNumber",
      render: function (text, csq) {
        const proNumber = csq.shipment.proNumber;
        const pickupNumber = csq.shipment.carrierPickupNumber;

        if (props.includePickupNumbers) {
          return <div>{pickupNumber}</div>;
        }
        return <div>{proNumber}</div>;
      },
      width: "100px",
    },
    {
      title: "Pickup Date",
      dataIndex: ["shipment", "pickupDate"],
      key: "pickupDate",
      render: (text) => <div>{text}</div>,
      width: "100px",
    },
    {
      title: "Details",
      dataIndex: ["shipment"],
      key: "companies",
      render: (shipment: Shipment) => (
        <>
          <Stack align="left">
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                {emojiForCountry(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  shipment.pickupLocation?.address?.countryCode!!
                )}
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{shipment.pickupLocation?.businessName}</div>
                <div
                  style={{
                    marginTop: "-4px",
                    fontSize: "12px",
                    color: Colors.LightText,
                  }}
                >
                  {shipment.pickupLocation?.address?.city},{" "}
                  {shipment.pickupLocation?.address?.stateOrProvinceCode}
                </div>
              </Stack>
            </HorizontalStack>
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                {emojiForCountry(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  shipment.deliveryLocation?.address?.countryCode!!
                )}
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{shipment.deliveryLocation?.businessName}</div>
                <div
                  style={{
                    marginTop: "-4px",
                    fontSize: "12px",
                    color: Colors.LightText,
                  }}
                >
                  {shipment.deliveryLocation?.address?.city},{" "}
                  {shipment.deliveryLocation?.address?.stateOrProvinceCode}
                </div>
              </Stack>
            </HorizontalStack>
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                📦
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{shipment.lineItems!![0].description}</div>
                <div
                  style={{
                    marginTop: "-4px",
                    fontSize: "12px",
                    color: Colors.LightText,
                  }}
                >
                  {shipment.totalHandlingUnits} skids,{" "}
                  <Weight>{shipment.totalWeight!!}</Weight>
                </div>
              </Stack>
            </HorizontalStack>
          </Stack>
        </>
      ),
      width: "200px",
    },
    {
      title: "Actions",
      dataIndex: ["shipment", "shipmentId"],
      key: "shipment.shipmentId",
      render: function (shipmentId) {
        return <ViewShipmentButton shipmentId={shipmentId} />;
      },
      width: "150px",
    },
  ]);

  if (props.additionalColumns) {
    props.additionalColumns.forEach((additional) => {
      columns.splice(additional.index, 0, additional.column);
    });
  }

  return (
    <>
      <Title style={{ marginTop: "32px" }} level={4}>
        {props.title}
      </Title>
      <Table
        style={{
          width: "100%",
          border: "1px solid #ccc",
        }}
        columns={columns}
        dataSource={shipments}
        pagination={false}
      />
    </>
  );
}

function QuotedShipmentTable(props: QuotedShipmentTableProps) {
  let shipments = props.shipments;

  shipments = props.shipments.filter(function (c) {
    if (props.companyIdFilter !== undefined && props.companyIdFilter !== "") {
      return c.company.companyId === props.companyIdFilter;
    }

    return true;
  });

  // Hide if we are filtering by carrier - because these manual quoting ones are not
  // related to any particular carrier
  if (props.carrierIdentifierFilter !== undefined) {
    return <></>;
  }

  // Hide if only showing flagged shipments, since pre-booked shipments can't be flagged
  if (props.onlyShowFlagged) {
    return <></>;
  }

  if (shipments.length === 0) {
    return <></>;
  }

  if (isPhone) {
    return (
      <>
        <Title style={{ marginBottom: "16px" }} level={5}>
          {props.title}
        </Title>
        <Stack align="left">
          {shipments.map(function (s) {
            return <QuotedShipmentCard shipment={s} />;
          })}
        </Stack>
      </>
    );
  }

  return (
    <>
      <Title style={{ marginBottom: "32px" }} level={4}>
        {props.title}
      </Title>
      <Table
        style={{
          width: "100%",
          border: "1px solid #ccc",
        }}
        columns={safeColumns<CompanyShipment>([
          {
            title: "Quoting Company",
            dataIndex: ["company", "companyName"],
            key: "companyName",
            render: function (text, o) {
              const isStarred = props.starredShipments.find(
                (s) => s.shipmentId === o.shipment.shipmentId
              );
              return (
                <Stack align="left">
                  <div>{text}</div>
                  <Spacer height={8} />
                  {o.shipment.tags?.map((s) => (
                    <Tag color="orange">{s}</Tag>
                  ))}
                  {o.shipment.problems?.map((s) => (
                    <Tag color="magenta">🚩 {s}</Tag>
                  ))}
                  {o.shipment.freightMissing && (
                    <Tag color="yellow">⚠️ Missing</Tag>
                  )}
                  {isStarred && <Tag color="yellow">⭐️ Starred</Tag>}
                </Stack>
              );
            },
            width: "230px",
          },

          {
            title: "Pickup Date",
            dataIndex: ["shipment", "pickupDate"],
            key: "pickupDate",
            render: (text) => <div>{text}</div>,
            width: "100px",
          },
          {
            title: "Details",
            dataIndex: ["shipment"],
            key: "companies",
            render: (shipment: Shipment) => (
              <>
                <Stack align="left">
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.pickupLocation?.address?.countryCode!!
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>
                        {shipment.pickupLocation?.address?.city},{" "}
                        {shipment.pickupLocation?.address?.stateOrProvinceCode}
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.deliveryLocation?.address?.countryCode!!
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>
                        {shipment.deliveryLocation?.address?.city},{" "}
                        {
                          shipment.deliveryLocation?.address
                            ?.stateOrProvinceCode
                        }
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      📦
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.lineItems!![0].description}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.totalHandlingUnits} skids,{" "}
                        <Weight>{shipment.totalWeight!!}</Weight>
                      </div>
                    </Stack>
                  </HorizontalStack>
                </Stack>
              </>
            ),
            width: "200px",
          },
          {
            title: "Actions",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipment.shipmentId",
            render: function (shipmentId) {
              return <ViewShipmentButton shipmentId={shipmentId} />;
            },
            width: "150px",
          },
        ])}
        dataSource={shipments}
        pagination={false}
      />
    </>
  );
}

export function ActiveShipmentsScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [activeShipments, setActiveShipments] = useState<
    ActiveShipments | undefined
  >(undefined);
  const [error, setError] = useState();
  const [companyIdFilter, setCompanyIdFilter] = useState<string | undefined>();
  const [carrierIdentifierFilter, setCarrierIdentifierFilter] = useState<
    string | undefined
  >();
  const [onlyShowFlagged, setOnlyShowFlagged] = useState(false);
  const starredShipments = useStarredShipments();
  const [freeFilter, setFreeFilter] = useState("");

  useOnce(async function () {
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.getActiveShipments();
      setActiveShipments(response);
    } catch (e: any) {
      setError(e);
    }
  });

  function applyFilter(shipments: CompanyShipmentQuote[]) {
    return filterActiveShipments(
      shipments,
      companyIdFilter,
      carrierIdentifierFilter,
      onlyShowFlagged,
      freeFilter
    );
  }

  if (error !== undefined) {
    return <>❌ An error occured : {JSON.stringify(error, null, "\t")}</>;
  } else if (activeShipments === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title="🚚 Active Shipments"
          tags={[]}
          extra={[
            <HorizontalStack align="right" width="100%">
              <Form.Item label="Free filter">
                <Input
                  onChange={(e) => {
                    setFreeFilter(e.target.value);
                  }}
                  value={freeFilter}
                />
              </Form.Item>
              <Spacer width={8} />
              <Form.Item label="Filter by carrier">
                <CarrierFilter
                  onFilter={function (carrierIdentifier) {
                    setCarrierIdentifierFilter(carrierIdentifier);
                  }}
                  carrierIdentifier={carrierIdentifierFilter}
                />
              </Form.Item>
              <Spacer width={8} />
              <Form.Item label="Filter by company">
                <CompanyFilter
                  activeShipments={activeShipments}
                  onFilter={function (companyId) {
                    setCompanyIdFilter(companyId);
                  }}
                />
              </Form.Item>
              <Spacer width={8} />
              <Form.Item label="Only show flagged?">
                <Switch
                  checked={onlyShowFlagged}
                  onChange={setOnlyShowFlagged}
                />
              </Form.Item>
            </HorizontalStack>,
          ]}
          stats={
            !isPhone && (
              <HorizontalStack>
                <Statistic
                  title="Unfinished"
                  value={applyFilter(activeShipments.unfinishedBooking).length}
                  style={{
                    margin: "0 32px 0 0",
                  }}
                />

                <Statistic
                  title={`Pickup Is Late ${
                    activeShipments.pickupIsLate.length > 0 ? "⚠️" : ""
                  }`}
                  value={applyFilter(activeShipments.pickupIsLate).length}
                  style={{
                    margin: "0 32px",
                  }}
                />

                <Statistic
                  title="Pickup Today"
                  value={applyFilter(activeShipments.pickupToday).length}
                  style={{
                    margin: "0 32px",
                  }}
                />

                <Statistic
                  title="Picking Up Soon"
                  value={applyFilter(activeShipments.pickingUpSoon).length}
                  style={{
                    margin: "0 32px",
                  }}
                />

                <Statistic
                  title="In Transit"
                  value={applyFilter(activeShipments.inTransit).length}
                  style={{
                    margin: "0 32px",
                  }}
                />

                <Statistic
                  title="Delivered (Last 30 Days)"
                  value={applyFilter(activeShipments.delivered).length}
                  style={{
                    margin: "0 32px",
                  }}
                />
              </HorizontalStack>
            )
          }
        >
          <PageTitle>🚚 Active Shipments</PageTitle>
          <BrowserView>
            <Spacer height={32} />
          </BrowserView>

          <ShipmentTable
            title="🔴 Unfinished Booking"
            shipments={activeShipments.unfinishedBooking}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            starredShipments={starredShipments}
            freeFilter={freeFilter}
            additionalColumns={[
              {
                index: 1,
                column: {
                  title: "Status",
                  dataIndex: ["shipment"],
                  key: "companies",
                  render: (shipment: Shipment) => {
                    const date = moment(shipment.bookedAt);
                    return (
                      <Stack align="left">
                        {describeState(shipment.state!)}
                        <Typography.Text strong>
                          {date.fromNow()}
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          {date.format("MMMM Do YYYY")}
                        </Typography.Text>
                      </Stack>
                    );
                  },
                  width: "100px",
                },
              },
            ]}
          />

          <QuotedShipmentTable
            title="🔎 Manual Quoting"
            shipments={activeShipments.manuallyQuoting}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            starredShipments={starredShipments}
          />

          <ShipmentTable
            title="✋ On Hold"
            shipments={activeShipments.onHold}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            starredShipments={starredShipments}
            freeFilter={freeFilter}
          />

          <ShipmentTable
            title="⚠️ Pickup Is Late"
            shipments={activeShipments.pickupIsLate}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            includePickupNumbers
            starredShipments={starredShipments}
            freeFilter={freeFilter}
          />

          <ShipmentTable
            title="🟡 Picking Up Soon"
            shipments={activeShipments.pickingUpSoon}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            includePickupNumbers
            starredShipments={starredShipments}
            freeFilter={freeFilter}
          />
          <ShipmentTable
            title="🟡 Pickup Today"
            shipments={activeShipments.pickupToday}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            includePickupNumbers
            starredShipments={starredShipments}
            freeFilter={freeFilter}
          />

          <ShipmentTable
            title="🔵 In Transit"
            shipments={activeShipments.inTransit}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            starredShipments={starredShipments}
            freeFilter={freeFilter}
          />
          <ShipmentTable
            title="🟢 Delivered (Last 21 Days)"
            shipments={activeShipments.delivered}
            companyIdFilter={companyIdFilter}
            carrierIdentifierFilter={carrierIdentifierFilter}
            onlyShowFlagged={onlyShowFlagged}
            starredShipments={starredShipments}
            freeFilter={freeFilter}
          />
        </Page>
      </>
    );
  }
}
