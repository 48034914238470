import { Table } from "antd";
import { safeColumns } from "../Helpers/safeColumns";

interface KeyValuesProps {
  data: Record<any, any>;
}

export function KeyValues(props: KeyValuesProps) {
  const dataAsArray = Object.entries(props.data).map(function (entry) {
    return {
      key: entry[0],
      value: entry[1],
    };
  });

  return (
    <Table
      showHeader={false}
      columns={safeColumns<{ key: string; value: string }>([
        {
          title: "Key",
          dataIndex: "key",
          key: "key",
          render: (text: string) => (
            <strong>
              {text.split("\n").map((t) => (
                <>
                  {t}
                  <br />
                </>
              ))}
            </strong>
          ),
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
          render: (text: any) => <div>{text}</div>,
        },
      ])}
      dataSource={dataAsArray}
      pagination={false}
    />
  );
}
