import { Select } from "antd";
import InvoiceAuditProblemTypes from "../../Fixtures/InvoiceAuditProblemTypesGrouped.json";
import { InvoiceAuditProblemType } from "../../generated-openapi-client";

interface InvoiceAuditProblemTypeDropdownProps {
  value: InvoiceAuditProblemType | undefined;
  setValue: (_: InvoiceAuditProblemType | undefined) => void;
}

export function InvoiceAuditProblemTypeDropdown(
  props: InvoiceAuditProblemTypeDropdownProps
) {
  return (
    <Select
      showSearch
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "450px" }}
      placeholder="No problem selected"
      allowClear
    >
      {InvoiceAuditProblemTypes.map(function (group) {
        return (
          <Select.OptGroup label={group.name}>
            {group.items.map(function (item) {
              return (
                <Select.Option value={item.type}>{item.name}</Select.Option>
              );
            })}
          </Select.OptGroup>
        );
      })}
    </Select>
  );
}
