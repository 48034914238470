/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NoteCategory } from './NoteCategory';
import {
    NoteCategoryFromJSON,
    NoteCategoryFromJSONTyped,
    NoteCategoryToJSON,
} from './NoteCategory';
import type { NoteEmailAddress } from './NoteEmailAddress';
import {
    NoteEmailAddressFromJSON,
    NoteEmailAddressFromJSONTyped,
    NoteEmailAddressToJSON,
} from './NoteEmailAddress';
import type { NoteIntercomType } from './NoteIntercomType';
import {
    NoteIntercomTypeFromJSON,
    NoteIntercomTypeFromJSONTyped,
    NoteIntercomTypeToJSON,
} from './NoteIntercomType';
import type { NoteLevel } from './NoteLevel';
import {
    NoteLevelFromJSON,
    NoteLevelFromJSONTyped,
    NoteLevelToJSON,
} from './NoteLevel';
import type { NoteType } from './NoteType';
import {
    NoteTypeFromJSON,
    NoteTypeFromJSONTyped,
    NoteTypeToJSON,
} from './NoteType';

/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    noteId?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    author?: string;
    /**
     * 
     * @type {NoteCategory}
     * @memberof Note
     */
    category?: NoteCategory;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    lineNumber?: string;
    /**
     * 
     * @type {NoteLevel}
     * @memberof Note
     */
    noteLevel?: NoteLevel;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    headline?: string;
    /**
     * 
     * @type {NoteType}
     * @memberof Note
     */
    noteType?: NoteType;
    /**
     * 
     * @type {NoteEmailAddress}
     * @memberof Note
     */
    emailFrom?: NoteEmailAddress;
    /**
     * 
     * @type {Array<NoteEmailAddress>}
     * @memberof Note
     */
    emailTo?: Array<NoteEmailAddress>;
    /**
     * 
     * @type {Array<NoteEmailAddress>}
     * @memberof Note
     */
    emailCc?: Array<NoteEmailAddress>;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    bodyHtml?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    link?: string;
    /**
     * 
     * @type {NoteIntercomType}
     * @memberof Note
     */
    intercomType?: NoteIntercomType;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    emailBodyCleanedUp?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    beforeMetadata?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    afterMetadata?: string;
}

/**
 * Check if a given object implements the Note interface.
 */
export function instanceOfNote(value: object): boolean {
    return true;
}

export function NoteFromJSON(json: any): Note {
    return NoteFromJSONTyped(json, false);
}

export function NoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Note {
    if (json == null) {
        return json;
    }
    return {
        
        'noteId': json['noteId'] == null ? undefined : json['noteId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'message': json['message'] == null ? undefined : json['message'],
        'author': json['author'] == null ? undefined : json['author'],
        'category': json['category'] == null ? undefined : NoteCategoryFromJSON(json['category']),
        'filename': json['filename'] == null ? undefined : json['filename'],
        'lineNumber': json['lineNumber'] == null ? undefined : json['lineNumber'],
        'noteLevel': json['noteLevel'] == null ? undefined : NoteLevelFromJSON(json['noteLevel']),
        'body': json['body'] == null ? undefined : json['body'],
        'headline': json['headline'] == null ? undefined : json['headline'],
        'noteType': json['noteType'] == null ? undefined : NoteTypeFromJSON(json['noteType']),
        'emailFrom': json['emailFrom'] == null ? undefined : NoteEmailAddressFromJSON(json['emailFrom']),
        'emailTo': json['emailTo'] == null ? undefined : ((json['emailTo'] as Array<any>).map(NoteEmailAddressFromJSON)),
        'emailCc': json['emailCc'] == null ? undefined : ((json['emailCc'] as Array<any>).map(NoteEmailAddressFromJSON)),
        'bodyHtml': json['bodyHtml'] == null ? undefined : json['bodyHtml'],
        'link': json['link'] == null ? undefined : json['link'],
        'intercomType': json['intercomType'] == null ? undefined : NoteIntercomTypeFromJSON(json['intercomType']),
        'emailSubject': json['emailSubject'] == null ? undefined : json['emailSubject'],
        'emailBodyCleanedUp': json['emailBodyCleanedUp'] == null ? undefined : json['emailBodyCleanedUp'],
        'beforeMetadata': json['beforeMetadata'] == null ? undefined : json['beforeMetadata'],
        'afterMetadata': json['afterMetadata'] == null ? undefined : json['afterMetadata'],
    };
}

export function NoteToJSON(value?: Note | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'noteId': value['noteId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'message': value['message'],
        'author': value['author'],
        'category': NoteCategoryToJSON(value['category']),
        'filename': value['filename'],
        'lineNumber': value['lineNumber'],
        'noteLevel': NoteLevelToJSON(value['noteLevel']),
        'body': value['body'],
        'headline': value['headline'],
        'noteType': NoteTypeToJSON(value['noteType']),
        'emailFrom': NoteEmailAddressToJSON(value['emailFrom']),
        'emailTo': value['emailTo'] == null ? undefined : ((value['emailTo'] as Array<any>).map(NoteEmailAddressToJSON)),
        'emailCc': value['emailCc'] == null ? undefined : ((value['emailCc'] as Array<any>).map(NoteEmailAddressToJSON)),
        'bodyHtml': value['bodyHtml'],
        'link': value['link'],
        'intercomType': NoteIntercomTypeToJSON(value['intercomType']),
        'emailSubject': value['emailSubject'],
        'emailBodyCleanedUp': value['emailBodyCleanedUp'],
        'beforeMetadata': value['beforeMetadata'],
        'afterMetadata': value['afterMetadata'],
    };
}

