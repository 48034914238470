/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuoteContactsData
 */
export interface QuoteContactsData {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof QuoteContactsData
     */
    carrierShippingContacts: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof QuoteContactsData
     */
    carrierBillingContacts: { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof QuoteContactsData
     */
    carrierCustomerServiceNumber?: string;
}

/**
 * Check if a given object implements the QuoteContactsData interface.
 */
export function instanceOfQuoteContactsData(value: object): boolean {
    if (!('carrierShippingContacts' in value)) return false;
    if (!('carrierBillingContacts' in value)) return false;
    return true;
}

export function QuoteContactsDataFromJSON(json: any): QuoteContactsData {
    return QuoteContactsDataFromJSONTyped(json, false);
}

export function QuoteContactsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteContactsData {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierShippingContacts': json['carrierShippingContacts'],
        'carrierBillingContacts': json['carrierBillingContacts'],
        'carrierCustomerServiceNumber': json['carrierCustomerServiceNumber'] == null ? undefined : json['carrierCustomerServiceNumber'],
    };
}

export function QuoteContactsDataToJSON(value?: QuoteContactsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierShippingContacts': value['carrierShippingContacts'],
        'carrierBillingContacts': value['carrierBillingContacts'],
        'carrierCustomerServiceNumber': value['carrierCustomerServiceNumber'],
    };
}

