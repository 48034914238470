import { Collapse, Timeline } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { ShowGroup } from "../Helpers/groupCollapsibleElements";
import Colors from "./Colors";
import Spacer from "../Spacer";

interface CollapseShowGroupsTimelineItemsProps<T> {
  showGroups: ShowGroup<T>[];
  renderShowGroupHeader: (collapseGroup: ShowGroup<T>) => ReactNode;
  onTimelineItemRender: (element: T) => {
    dot: ReactNode;
    label: ReactNode;
    element: ReactNode;
  };
}

/**
 * Renders a collapsible if when the group should not be showned
 */
export function CollapseShowGroupsTimelineItems<T>({
  showGroups,
  renderShowGroupHeader,
  onTimelineItemRender,
}: CollapseShowGroupsTimelineItemsProps<T>) {
  const [states, setStates] = useState<boolean[]>(
    new Array(showGroups.length).fill(false)
  );

  function handleChange(e: string[] | string, i: number) {
    setStates((prev) => {
      const actual = [...prev];
      actual[i] = e.length > 0;
      return actual;
    });
  }

  useEffect(() => {
    setStates(new Array(showGroups.length).fill(false));
  }, [showGroups]);

  return (
    <>
      {showGroups.map((group, groupIndex) => {
        const timelineItems = group.elements.map((e, elementsIndex) => {
          const { dot, label, element } = onTimelineItemRender(e);
          return (
            <Timeline.Item
              dot={dot}
              label={label}
              key={`item-${elementsIndex}`}
            >
              {element}
            </Timeline.Item>
          );
        });

        if (group.show) {
          return timelineItems;
        }

        return (
          <Collapse
            ghost
            onChange={(event) => handleChange(event, groupIndex)}
            key={`group-${groupIndex}`}
          >
            <Collapse.Panel
              key={`group-panel-${groupIndex}`}
              showArrow={false}
              header={
                <CollapseGroupTimelineHeader active={states[groupIndex]}>
                  {renderShowGroupHeader(group)}
                </CollapseGroupTimelineHeader>
              }
            >
              <div style={{ opacity: "50%" }}>{timelineItems}</div>
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </>
  );
}

function CollapseGroupTimelineHeader({
  active,
  children,
}: {
  active: boolean;
  children: ReactNode;
}) {
  return (
    <Timeline.Item
      dot={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            color: Colors.LightText,
          }}
        >
          <RightOutlined
            style={{
              fontSize: "8pt",
              transform: active ? "rotate(90deg)" : "",
              transition: "transform 300ms ease",
            }}
          />
          {children}
        </div>
      }
    >
      <Spacer height={30} />
    </Timeline.Item>
  );
}
