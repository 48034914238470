/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierCreditNote } from './CarrierCreditNote';
import {
    CarrierCreditNoteFromJSON,
    CarrierCreditNoteFromJSONTyped,
    CarrierCreditNoteToJSON,
} from './CarrierCreditNote';
import type { CompanyShipmentCarrierInvoice } from './CompanyShipmentCarrierInvoice';
import {
    CompanyShipmentCarrierInvoiceFromJSON,
    CompanyShipmentCarrierInvoiceFromJSONTyped,
    CompanyShipmentCarrierInvoiceToJSON,
} from './CompanyShipmentCarrierInvoice';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';
import type { XeroCreditNote } from './XeroCreditNote';
import {
    XeroCreditNoteFromJSON,
    XeroCreditNoteFromJSONTyped,
    XeroCreditNoteToJSON,
} from './XeroCreditNote';
import type { XeroInvoice } from './XeroInvoice';
import {
    XeroInvoiceFromJSON,
    XeroInvoiceFromJSONTyped,
    XeroInvoiceToJSON,
} from './XeroInvoice';

/**
 * 
 * @export
 * @interface MismatchedInvoices
 */
export interface MismatchedInvoices {
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof MismatchedInvoices
     */
    shipmentsWithoutInvoices: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<XeroInvoice>}
     * @memberof MismatchedInvoices
     */
    outstandingXeroInvoices: Array<XeroInvoice>;
    /**
     * 
     * @type {Array<CompanyShipmentCarrierInvoice>}
     * @memberof MismatchedInvoices
     */
    outstandingDbInvoices: Array<CompanyShipmentCarrierInvoice>;
    /**
     * 
     * @type {Array<XeroCreditNote>}
     * @memberof MismatchedInvoices
     */
    outstandingXeroCreditNote: Array<XeroCreditNote>;
    /**
     * 
     * @type {Array<CarrierCreditNote>}
     * @memberof MismatchedInvoices
     */
    outstandingDbCreditNote: Array<CarrierCreditNote>;
    /**
     * 
     * @type {Array<XeroInvoice>}
     * @memberof MismatchedInvoices
     */
    xeroNonProvisionalInvoicesNotInDb: Array<XeroInvoice>;
    /**
     * 
     * @type {Array<CompanyShipmentCarrierInvoice>}
     * @memberof MismatchedInvoices
     */
    dbInvoicesNotInXero: Array<CompanyShipmentCarrierInvoice>;
    /**
     * 
     * @type {Array<XeroCreditNote>}
     * @memberof MismatchedInvoices
     */
    xeroCreditNotesNotInDb: Array<XeroCreditNote>;
    /**
     * 
     * @type {Array<CarrierCreditNote>}
     * @memberof MismatchedInvoices
     */
    dbCreditNotesNotInXero: Array<CarrierCreditNote>;
}

/**
 * Check if a given object implements the MismatchedInvoices interface.
 */
export function instanceOfMismatchedInvoices(value: object): boolean {
    if (!('shipmentsWithoutInvoices' in value)) return false;
    if (!('outstandingXeroInvoices' in value)) return false;
    if (!('outstandingDbInvoices' in value)) return false;
    if (!('outstandingXeroCreditNote' in value)) return false;
    if (!('outstandingDbCreditNote' in value)) return false;
    if (!('xeroNonProvisionalInvoicesNotInDb' in value)) return false;
    if (!('dbInvoicesNotInXero' in value)) return false;
    if (!('xeroCreditNotesNotInDb' in value)) return false;
    if (!('dbCreditNotesNotInXero' in value)) return false;
    return true;
}

export function MismatchedInvoicesFromJSON(json: any): MismatchedInvoices {
    return MismatchedInvoicesFromJSONTyped(json, false);
}

export function MismatchedInvoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MismatchedInvoices {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentsWithoutInvoices': ((json['shipmentsWithoutInvoices'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'outstandingXeroInvoices': ((json['outstandingXeroInvoices'] as Array<any>).map(XeroInvoiceFromJSON)),
        'outstandingDbInvoices': ((json['outstandingDbInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceFromJSON)),
        'outstandingXeroCreditNote': ((json['outstandingXeroCreditNote'] as Array<any>).map(XeroCreditNoteFromJSON)),
        'outstandingDbCreditNote': ((json['outstandingDbCreditNote'] as Array<any>).map(CarrierCreditNoteFromJSON)),
        'xeroNonProvisionalInvoicesNotInDb': ((json['xeroNonProvisionalInvoicesNotInDb'] as Array<any>).map(XeroInvoiceFromJSON)),
        'dbInvoicesNotInXero': ((json['dbInvoicesNotInXero'] as Array<any>).map(CompanyShipmentCarrierInvoiceFromJSON)),
        'xeroCreditNotesNotInDb': ((json['xeroCreditNotesNotInDb'] as Array<any>).map(XeroCreditNoteFromJSON)),
        'dbCreditNotesNotInXero': ((json['dbCreditNotesNotInXero'] as Array<any>).map(CarrierCreditNoteFromJSON)),
    };
}

export function MismatchedInvoicesToJSON(value?: MismatchedInvoices | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentsWithoutInvoices': ((value['shipmentsWithoutInvoices'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'outstandingXeroInvoices': ((value['outstandingXeroInvoices'] as Array<any>).map(XeroInvoiceToJSON)),
        'outstandingDbInvoices': ((value['outstandingDbInvoices'] as Array<any>).map(CompanyShipmentCarrierInvoiceToJSON)),
        'outstandingXeroCreditNote': ((value['outstandingXeroCreditNote'] as Array<any>).map(XeroCreditNoteToJSON)),
        'outstandingDbCreditNote': ((value['outstandingDbCreditNote'] as Array<any>).map(CarrierCreditNoteToJSON)),
        'xeroNonProvisionalInvoicesNotInDb': ((value['xeroNonProvisionalInvoicesNotInDb'] as Array<any>).map(XeroInvoiceToJSON)),
        'dbInvoicesNotInXero': ((value['dbInvoicesNotInXero'] as Array<any>).map(CompanyShipmentCarrierInvoiceToJSON)),
        'xeroCreditNotesNotInDb': ((value['xeroCreditNotesNotInDb'] as Array<any>).map(XeroCreditNoteToJSON)),
        'dbCreditNotesNotInXero': ((value['dbCreditNotesNotInXero'] as Array<any>).map(CarrierCreditNoteToJSON)),
    };
}

