/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentReport } from './ShipmentReport';
import {
    ShipmentReportFromJSON,
    ShipmentReportFromJSONTyped,
    ShipmentReportToJSON,
} from './ShipmentReport';

/**
 * 
 * @export
 * @interface ProfitLossReport
 */
export interface ProfitLossReport {
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    numberShipments: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    numberShipmentsWithAdditionalCustomerCharges: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    numberShipmentsWhereCarrierPriceWasGreaterThanQuote: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    numberShipmentsWhereCarrierPriceWasLessThanQuote: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    averageProfit: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    averageMarginPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    totalIncomingDollars: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitLossReport
     */
    totalOutgoingDollars: number;
    /**
     * 
     * @type {Array<ShipmentReport>}
     * @memberof ProfitLossReport
     */
    shipmentLines: Array<ShipmentReport>;
}

/**
 * Check if a given object implements the ProfitLossReport interface.
 */
export function instanceOfProfitLossReport(value: object): boolean {
    if (!('numberShipments' in value)) return false;
    if (!('numberShipmentsWithAdditionalCustomerCharges' in value)) return false;
    if (!('numberShipmentsWhereCarrierPriceWasGreaterThanQuote' in value)) return false;
    if (!('numberShipmentsWhereCarrierPriceWasLessThanQuote' in value)) return false;
    if (!('averageProfit' in value)) return false;
    if (!('averageMarginPercentage' in value)) return false;
    if (!('totalIncomingDollars' in value)) return false;
    if (!('totalOutgoingDollars' in value)) return false;
    if (!('shipmentLines' in value)) return false;
    return true;
}

export function ProfitLossReportFromJSON(json: any): ProfitLossReport {
    return ProfitLossReportFromJSONTyped(json, false);
}

export function ProfitLossReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfitLossReport {
    if (json == null) {
        return json;
    }
    return {
        
        'numberShipments': json['numberShipments'],
        'numberShipmentsWithAdditionalCustomerCharges': json['numberShipmentsWithAdditionalCustomerCharges'],
        'numberShipmentsWhereCarrierPriceWasGreaterThanQuote': json['numberShipmentsWhereCarrierPriceWasGreaterThanQuote'],
        'numberShipmentsWhereCarrierPriceWasLessThanQuote': json['numberShipmentsWhereCarrierPriceWasLessThanQuote'],
        'averageProfit': json['averageProfit'],
        'averageMarginPercentage': json['averageMarginPercentage'],
        'totalIncomingDollars': json['totalIncomingDollars'],
        'totalOutgoingDollars': json['totalOutgoingDollars'],
        'shipmentLines': ((json['shipmentLines'] as Array<any>).map(ShipmentReportFromJSON)),
    };
}

export function ProfitLossReportToJSON(value?: ProfitLossReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberShipments': value['numberShipments'],
        'numberShipmentsWithAdditionalCustomerCharges': value['numberShipmentsWithAdditionalCustomerCharges'],
        'numberShipmentsWhereCarrierPriceWasGreaterThanQuote': value['numberShipmentsWhereCarrierPriceWasGreaterThanQuote'],
        'numberShipmentsWhereCarrierPriceWasLessThanQuote': value['numberShipmentsWhereCarrierPriceWasLessThanQuote'],
        'averageProfit': value['averageProfit'],
        'averageMarginPercentage': value['averageMarginPercentage'],
        'totalIncomingDollars': value['totalIncomingDollars'],
        'totalOutgoingDollars': value['totalOutgoingDollars'],
        'shipmentLines': ((value['shipmentLines'] as Array<any>).map(ShipmentReportToJSON)),
    };
}

