/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailQuoteResponse
 */
export interface EmailQuoteResponse {
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    emailQuoteResponseId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    emailQuoteRequestId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    rawPlain?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    processed?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    fromAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailQuoteResponse
     */
    toAddresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    fromName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailQuoteResponse
     */
    uniqueId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailQuoteResponse
     */
    read?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailQuoteResponse
     */
    attachmentIds?: Array<string>;
}

/**
 * Check if a given object implements the EmailQuoteResponse interface.
 */
export function instanceOfEmailQuoteResponse(value: object): boolean {
    return true;
}

export function EmailQuoteResponseFromJSON(json: any): EmailQuoteResponse {
    return EmailQuoteResponseFromJSONTyped(json, false);
}

export function EmailQuoteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailQuoteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'emailQuoteResponseId': json['emailQuoteResponseId'] == null ? undefined : json['emailQuoteResponseId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'emailQuoteRequestId': json['emailQuoteRequestId'] == null ? undefined : json['emailQuoteRequestId'],
        'rawPlain': json['rawPlain'] == null ? undefined : json['rawPlain'],
        'processed': json['processed'] == null ? undefined : json['processed'],
        'fromAddress': json['fromAddress'] == null ? undefined : json['fromAddress'],
        'toAddresses': json['toAddresses'] == null ? undefined : json['toAddresses'],
        'fromName': json['fromName'] == null ? undefined : json['fromName'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'uniqueId': json['uniqueId'] == null ? undefined : json['uniqueId'],
        'read': json['read'] == null ? undefined : json['read'],
        'attachmentIds': json['attachmentIds'] == null ? undefined : json['attachmentIds'],
    };
}

export function EmailQuoteResponseToJSON(value?: EmailQuoteResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailQuoteResponseId': value['emailQuoteResponseId'],
        'createdAt': value['createdAt'],
        'emailQuoteRequestId': value['emailQuoteRequestId'],
        'rawPlain': value['rawPlain'],
        'processed': value['processed'],
        'fromAddress': value['fromAddress'],
        'toAddresses': value['toAddresses'],
        'fromName': value['fromName'],
        'subject': value['subject'],
        'uniqueId': value['uniqueId'],
        'read': value['read'],
        'attachmentIds': value['attachmentIds'],
    };
}

