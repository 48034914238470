import { CopyOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";
import { useTelWithShipmentId } from "../Hooks/useTel";
import { ButtonRow } from "./ButtonRow";

interface PhoneLinkProps {
  phone: string;
  description: string;
  shipmentId: string;
}

export function PhoneLink(props: PhoneLinkProps) {
  const onCopy = useCopyToClipboard(props.phone);
  const onCall = useTelWithShipmentId(
    props.phone,
    props.shipmentId,
    props.description
  );
  return (
    <Tooltip
      overlay={
        <ButtonRow>
          <Button
            onClick={onCall}
            style={{ color: "white" }}
            type="link"
            icon={<PhoneOutlined />}
          >
            Call Number
          </Button>
          <Button
            onClick={onCopy}
            style={{ color: "white" }}
            type="link"
            icon={<CopyOutlined />}
          >
            Copy
          </Button>
        </ButtonRow>
      }
    >
      {props.phone}
    </Tooltip>
  );
}
