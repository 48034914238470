import { message } from "antd";
import { useQuotesApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import { EmailQuoteRequestState } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";

interface MarkEmailQuoteRequestNoServiceButtonProps extends TabProps {
  emailQuoteRequestId: string;
  state: EmailQuoteRequestState;
}

export function MarkEmailQuoteRequestNoServiceButton(
  props: MarkEmailQuoteRequestNoServiceButtonProps
) {
  const emailQuoteRequestId = props.emailQuoteRequestId;
  const createQuotesApi = useQuotesApi();

  async function onConfirm() {
    const quotesApi = await createQuotesApi();
    try {
      quotesApi.markEmailQuoteRequestNoService({ emailQuoteRequestId });
      message.success("Marked No Service");
    } catch (e) {
      message.error(`Ooops. Something went wrong ${e}`);
    }
  }

  if (props.state !== EmailQuoteRequestState.Sent) {
    return <></>;
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question="Are you sure you want to make this request as 'No Service'"
      okText="Yes, no service"
      tooltip="This allows us to say we have had a response from the carrier and they cannot service it"
    >
      🚫 Mark No Service
    </ConfirmButton>
  );
}
