import { ReactNode } from "react";
import Colors from "../../Components/Colors";
import { NoteAuthorHeadline } from "../../Components/NotesBase/NoteHeadline";
import { NoteMessage } from "../../Components/NotesBase/NoteMessage";
import { NoteSubmessage } from "../../Components/NotesBase/NoteSubmessage";
import {
  CompanyData,
  CompanyNote,
  CompanyNoteType,
} from "../../generated-openapi-client";
import { ApolloActionCompanyNoteElement } from "./CompanyNotes/ApolloActionCompanyNoteElement";
import { CallCompanyNoteElement } from "./CompanyNotes/CallCompanyNoteElement";
import { CompanyNotesTimelineOrigin } from "./CompanyNotes/CompanyNotesTimeline";
import { CreditModifiedCompanyNoteElement } from "./CompanyNotes/CreditModifiedCompanyNote";
import { QuoteRunCompanyNoteElement } from "./CompanyNotes/QuoteRunCompanyNoteElement";
import { ShipmentBookedCompanyNoteElement } from "./CompanyNotes/ShipmentBookedCompanyNoteElement";
import { SystemCompanyNote } from "./CompanyNotes/SystemCompanyNote";
import { UserActionCompanyNoteElement } from "./CompanyNotes/UserActionCompanyNoteElement";

export interface CompanyNoteElementProps {
  data?: CompanyData;
  note: CompanyNote;
  onRefresh: () => Promise<void>;
  collapseActive: boolean;
  origin: CompanyNotesTimelineOrigin;
}

export function CompanyNoteElement(props: CompanyNoteElementProps) {
  const { note } = props;

  if (note.type == CompanyNoteType.ShipmentBooking) {
    return <ShipmentBookedCompanyNoteElement {...props} />;
  }

  if (note.type === CompanyNoteType.Call) {
    return <CallCompanyNoteElement {...props} />;
  }

  if (note.type === CompanyNoteType.QuoteRun) {
    return <QuoteRunCompanyNoteElement {...props} />;
  }

  if (note.type === CompanyNoteType.ApolloAction) {
    return <ApolloActionCompanyNoteElement {...props} />;
  }

  if (note.type === CompanyNoteType.UserAction) {
    return <UserActionCompanyNoteElement {...props} />;
  }

  if (note.type === CompanyNoteType.CreditModified) {
    return <CreditModifiedCompanyNoteElement {...props} />;
  }

  if (note.type === CompanyNoteType.SystemEvent) {
    return <SystemCompanyNote {...props} />;
  }

  return <BasicCompanyNoteElement {...props} />;
}

export function BasicCompanyNoteElement({ note }: CompanyNoteElementProps) {
  return (
    <>
      <NoteAuthorHeadline
        author={note.author}
        headline={
          <SignificantHeadline significant={note.significant}>
            {note.subject}
          </SignificantHeadline>
        }
      />
      <NoteMessage>{note.body}</NoteMessage>
      <NoteSubmessage>{note.subMessage}</NoteSubmessage>
    </>
  );
}

export function SignificantTag({
  significant,
}: {
  significant: boolean | undefined;
}) {
  if (!significant) {
    return <></>;
  }

  return (
    <div
      style={{
        fontSize: "12px",
        padding: "2px 8px",
        color: Colors.GreenT[600],
        background: Colors.GreenT[50],
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Significant
    </div>
  );
}

export function SignificantHeadline({
  children,
  significant,
}: {
  children: ReactNode;
  significant: boolean | undefined;
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "end",
        columnGap: "5px",
      }}
    >
      <SignificantTag significant={significant} />
      {children}
    </div>
  );
}
