/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvoiceAuditProblemConfidence } from './InvoiceAuditProblemConfidence';
import {
    InvoiceAuditProblemConfidenceFromJSON,
    InvoiceAuditProblemConfidenceFromJSONTyped,
    InvoiceAuditProblemConfidenceToJSON,
} from './InvoiceAuditProblemConfidence';
import type { InvoiceAuditProblemFault } from './InvoiceAuditProblemFault';
import {
    InvoiceAuditProblemFaultFromJSON,
    InvoiceAuditProblemFaultFromJSONTyped,
    InvoiceAuditProblemFaultToJSON,
} from './InvoiceAuditProblemFault';
import type { InvoiceAuditProblemResolutionState } from './InvoiceAuditProblemResolutionState';
import {
    InvoiceAuditProblemResolutionStateFromJSON,
    InvoiceAuditProblemResolutionStateFromJSONTyped,
    InvoiceAuditProblemResolutionStateToJSON,
} from './InvoiceAuditProblemResolutionState';
import type { InvoiceAuditProblemResolutionType } from './InvoiceAuditProblemResolutionType';
import {
    InvoiceAuditProblemResolutionTypeFromJSON,
    InvoiceAuditProblemResolutionTypeFromJSONTyped,
    InvoiceAuditProblemResolutionTypeToJSON,
} from './InvoiceAuditProblemResolutionType';
import type { InvoiceAuditProblemType } from './InvoiceAuditProblemType';
import {
    InvoiceAuditProblemTypeFromJSON,
    InvoiceAuditProblemTypeFromJSONTyped,
    InvoiceAuditProblemTypeToJSON,
} from './InvoiceAuditProblemType';

/**
 * 
 * @export
 * @interface InvoiceAuditProblem
 */
export interface InvoiceAuditProblem {
    /**
     * 
     * @type {InvoiceAuditProblemType}
     * @memberof InvoiceAuditProblem
     */
    type?: InvoiceAuditProblemType;
    /**
     * 
     * @type {InvoiceAuditProblemFault}
     * @memberof InvoiceAuditProblem
     */
    fault?: InvoiceAuditProblemFault;
    /**
     * 
     * @type {InvoiceAuditProblemConfidence}
     * @memberof InvoiceAuditProblem
     */
    confidence?: InvoiceAuditProblemConfidence;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditProblem
     */
    customerVisibleDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditProblem
     */
    internalVisibleDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditProblem
     */
    correctedLocationType?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceAuditProblem
     */
    estimatedCostDifference?: number;
    /**
     * 
     * @type {InvoiceAuditProblemResolutionState}
     * @memberof InvoiceAuditProblem
     */
    resolutionState?: InvoiceAuditProblemResolutionState;
    /**
     * 
     * @type {InvoiceAuditProblemResolutionType}
     * @memberof InvoiceAuditProblem
     */
    resolutionType?: InvoiceAuditProblemResolutionType;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceAuditProblem
     */
    relatedDocumentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditProblem
     */
    internalEvidence?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditProblem
     */
    invoiceAuditProblemId?: string;
}

/**
 * Check if a given object implements the InvoiceAuditProblem interface.
 */
export function instanceOfInvoiceAuditProblem(value: object): boolean {
    return true;
}

export function InvoiceAuditProblemFromJSON(json: any): InvoiceAuditProblem {
    return InvoiceAuditProblemFromJSONTyped(json, false);
}

export function InvoiceAuditProblemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditProblem {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : InvoiceAuditProblemTypeFromJSON(json['type']),
        'fault': json['fault'] == null ? undefined : InvoiceAuditProblemFaultFromJSON(json['fault']),
        'confidence': json['confidence'] == null ? undefined : InvoiceAuditProblemConfidenceFromJSON(json['confidence']),
        'customerVisibleDetail': json['customerVisibleDetail'] == null ? undefined : json['customerVisibleDetail'],
        'internalVisibleDetail': json['internalVisibleDetail'] == null ? undefined : json['internalVisibleDetail'],
        'correctedLocationType': json['correctedLocationType'] == null ? undefined : json['correctedLocationType'],
        'estimatedCostDifference': json['estimatedCostDifference'] == null ? undefined : json['estimatedCostDifference'],
        'resolutionState': json['resolutionState'] == null ? undefined : InvoiceAuditProblemResolutionStateFromJSON(json['resolutionState']),
        'resolutionType': json['resolutionType'] == null ? undefined : InvoiceAuditProblemResolutionTypeFromJSON(json['resolutionType']),
        'relatedDocumentIds': json['relatedDocumentIds'] == null ? undefined : json['relatedDocumentIds'],
        'internalEvidence': json['internalEvidence'] == null ? undefined : json['internalEvidence'],
        'invoiceAuditProblemId': json['invoiceAuditProblemId'] == null ? undefined : json['invoiceAuditProblemId'],
    };
}

export function InvoiceAuditProblemToJSON(value?: InvoiceAuditProblem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': InvoiceAuditProblemTypeToJSON(value['type']),
        'fault': InvoiceAuditProblemFaultToJSON(value['fault']),
        'confidence': InvoiceAuditProblemConfidenceToJSON(value['confidence']),
        'customerVisibleDetail': value['customerVisibleDetail'],
        'internalVisibleDetail': value['internalVisibleDetail'],
        'correctedLocationType': value['correctedLocationType'],
        'estimatedCostDifference': value['estimatedCostDifference'],
        'resolutionState': InvoiceAuditProblemResolutionStateToJSON(value['resolutionState']),
        'resolutionType': InvoiceAuditProblemResolutionTypeToJSON(value['resolutionType']),
        'relatedDocumentIds': value['relatedDocumentIds'],
        'internalEvidence': value['internalEvidence'],
        'invoiceAuditProblemId': value['invoiceAuditProblemId'],
    };
}

