/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ApolloMapLocationFilterType = {
    Canada: 'Canada',
    Usa: 'USA',
    PacificTimezone: 'PacificTimezone',
    MountainTimezone: 'MountainTimezone',
    CentralTimezone: 'CentralTimezone',
    EasternAtlanticTimezone: 'EasternAtlanticTimezone',
    WesternCanada: 'WesternCanada',
    Prairies: 'Prairies',
    EasternCanada: 'EasternCanada',
    NorthernTerritories: 'NorthernTerritories',
    Maritimes: 'Maritimes',
    NorthEast: 'NorthEast',
    Midwest: 'Midwest',
    South: 'South',
    West: 'West',
    Alberta: 'Alberta',
    BritishColumbia: 'BritishColumbia',
    Manitoba: 'Manitoba',
    NewBrunswick: 'NewBrunswick',
    Newfoundland: 'Newfoundland',
    NorthWestTerritories: 'NorthWestTerritories',
    NovaScotia: 'NovaScotia',
    Nunavut: 'Nunavut',
    Ontario: 'Ontario',
    PrinceEdwardIsland: 'PrinceEdwardIsland',
    Quebec: 'Quebec',
    Saskatchewan: 'Saskatchewan',
    Yukon: 'Yukon',
    Alabama: 'Alabama',
    Alaska: 'Alaska',
    Arizona: 'Arizona',
    Arkansas: 'Arkansas',
    California: 'California',
    Colorado: 'Colorado',
    Connecticut: 'Connecticut',
    Delaware: 'Delaware',
    DistrictofColumbia: 'DistrictofColumbia',
    Florida: 'Florida',
    Georgia: 'Georgia',
    Hawaii: 'Hawaii',
    Idaho: 'Idaho',
    Illinois: 'Illinois',
    Indiana: 'Indiana',
    Iowa: 'Iowa',
    Kansas: 'Kansas',
    Kentucky: 'Kentucky',
    Louisiana: 'Louisiana',
    Maine: 'Maine',
    Maryland: 'Maryland',
    Massachusetts: 'Massachusetts',
    Michigan: 'Michigan',
    Minnesota: 'Minnesota',
    Mississippi: 'Mississippi',
    Missouri: 'Missouri',
    Montana: 'Montana',
    Nebraska: 'Nebraska',
    Nevada: 'Nevada',
    NewHampshire: 'NewHampshire',
    NewJersey: 'NewJersey',
    NewMexico: 'NewMexico',
    NewYork: 'NewYork',
    NorthCarolina: 'NorthCarolina',
    NorthDakota: 'NorthDakota',
    Ohio: 'Ohio',
    Oklahoma: 'Oklahoma',
    Oregon: 'Oregon',
    Pennsylvania: 'Pennsylvania',
    PuertoRico: 'PuertoRico',
    RhodeIsland: 'RhodeIsland',
    SouthCarolina: 'SouthCarolina',
    SouthDakota: 'SouthDakota',
    Tennessee: 'Tennessee',
    Texas: 'Texas',
    Utah: 'Utah',
    Vermont: 'Vermont',
    Virginia: 'Virginia',
    Washington: 'Washington',
    WestVirginia: 'WestVirginia',
    Wisconsin: 'Wisconsin',
    Wyoming: 'Wyoming'
} as const;
export type ApolloMapLocationFilterType = typeof ApolloMapLocationFilterType[keyof typeof ApolloMapLocationFilterType];


export function instanceOfApolloMapLocationFilterType(value: any): boolean {
    return Object.values(ApolloMapLocationFilterType).includes(value);
}

export function ApolloMapLocationFilterTypeFromJSON(json: any): ApolloMapLocationFilterType {
    return ApolloMapLocationFilterTypeFromJSONTyped(json, false);
}

export function ApolloMapLocationFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapLocationFilterType {
    return json as ApolloMapLocationFilterType;
}

export function ApolloMapLocationFilterTypeToJSON(value?: ApolloMapLocationFilterType | null): any {
    return value as any;
}

