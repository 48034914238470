import { Collapse } from "antd";
import JSONPretty from "react-json-pretty";
import Colors from "../../Components/Colors";
import { CollapsibleNote } from "../../Components/NotesBase/CollapseNote";
import { NoteAuthorHeadline } from "../../Components/NotesBase/NoteHeadline";
import { NoteMessage } from "../../Components/NotesBase/NoteMessage";
import { NoteSubmessage } from "../../Components/NotesBase/NoteSubmessage";
import { CompanyNoteElementProps, SignificantHeadline } from "./CompanyNote";

export function ErrorCompanyNoteElement({
  collapseActive,
  note,
}: CompanyNoteElementProps) {
  let metadata: any;

  if (!note.metadata) {
    return (
      <>
        <NoteAuthorHeadline
          author={note.author}
          headline={
            <SignificantHeadline significant={note.significant}>
              {note.subject}
            </SignificantHeadline>
          }
        />
        <NoteMessage>{note.body}</NoteMessage>
        <NoteSubmessage>
          <div>{note.subMessage}</div>
          <div style={{ paddingBlock: "5px" }}>
            This note does not have additional information
          </div>
        </NoteSubmessage>
      </>
    );
  }

  try {
    metadata = JSON.parse(note.metadata);
  } catch (error) {
    return (
      <>
        <NoteAuthorHeadline author={note.author} headline={note.subject} />
        <NoteMessage>{note.body}</NoteMessage>
        <NoteSubmessage>
          <div>{note.subMessage}</div>
          <div style={{ color: Colors.Red }}>
            Something went wrong! Could not load additional information!
          </div>
        </NoteSubmessage>
      </>
    );
  }

  return (
    <>
      <CollapsibleNote
        collapseActive={collapseActive}
        header={
          <div>
            <div>
              <NoteAuthorHeadline
                author={note.author}
                headline={note.subject}
              />
            </div>
            <div>
              <NoteMessage>{note.body}</NoteMessage>
              <NoteSubmessage>
                <div>{note.subMessage}</div>
                <div style={{ color: Colors.Red }}>
                  Something went wrong with this note!
                </div>
              </NoteSubmessage>
            </div>
          </div>
        }
      >
        <Collapse ghost>
          <Collapse.Panel
            style={{ padding: 10 }}
            header="Raw metadata"
            key={"raw-metadata-panel"}
          >
            {metadata && (
              <div style={{ height: "500px", overflow: "auto" }}>
                <JSONPretty
                  id="json-pretty"
                  data={metadata}
                  theme={{
                    main: "font-size:12px;line-height:1.3;color:#111;overflow:auto;",
                    error: "line-height:1.3;color:#66d9ef;overflow:auto;",
                    key: "color:#6307AF;",
                    string: "color:#53AF07;",
                    value: "color:#AF07A7;",
                    boolean: "color:#0F07AF;",
                  }}
                ></JSONPretty>
              </div>
            )}
          </Collapse.Panel>
        </Collapse>
      </CollapsibleNote>
    </>
  );
}
