import { isBlank } from "../Helpers/isNotBlank";
import Stack from "./Stack";

interface CarrierLogoSquareProps {
  carrierIdentifier: string | undefined;
  brokeredCarrierIdentifier: string | undefined;
  width: number;
  height: number;
}

export function CarrierLogoSquare(props: CarrierLogoSquareProps) {
  const brokeredCarrierIdentifier = props.brokeredCarrierIdentifier;

  if (isBlank(brokeredCarrierIdentifier)) {
    return (
      <Stack align="center">
        <img
          style={{
            maxHeight: props.height + "px",
            maxWidth: props.width + "px",
          }}
          alt={props.carrierIdentifier}
          src={`https://dashboard.freightsimple.com/carrierLogosSquare/${props.carrierIdentifier}.png`}
        />
      </Stack>
    );
  } else {
    return (
      <Stack align="center">
        <img
          style={{
            maxHeight: props.height + "px",
            maxWidth: props.width + "px",
          }}
          alt={props.brokeredCarrierIdentifier}
          src={`https://dashboard.freightsimple.com/carrierLogosSquare/${props.brokeredCarrierIdentifier}.png`}
        />
        <img
          style={{
            maxHeight: "20px",
            maxWidth: "30px",
            position: "relative",
            top: "-18px",
          }}
          alt={props.carrierIdentifier}
          src={`https://dashboard.freightsimple.com/carrierLogos/${props.carrierIdentifier}.png`}
        />
      </Stack>
    );
  }
}
