import { CSSProperties } from "react";
import NumberFormat from "react-number-format";
import { CurrencyCode } from "../generated-openapi-client/models/CurrencyCode";
import Colors from "./Colors";
interface DollarProps {
  currency?: CurrencyCode;
  children: number;
  style?: CSSProperties;
  colored?: boolean;
  invert?: boolean;
}

export function Dollar(props: DollarProps) {
  const style = props.style || {};

  if (props.colored) {
    if (props.invert) {
      if (props.children > 0) {
        style.color = Colors.Red;
      } else if (props.children < 0) {
        style.color = Colors.DarkGreen;
      }
    } else {
      if (props.children > 0) {
        style.color = Colors.DarkGreen;
      } else if (props.children < 0) {
        style.color = Colors.Red;
      }
    }
  }

  return (
    <NumberFormat
      value={props.children}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={"$"}
      suffix={props.currency ? " " + props.currency : ""}
      style={style}
    />
  );
}
