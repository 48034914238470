/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CustomsDocsState = {
    NotRequired: 'not_required',
    Missing: 'missing',
    Uploaded: 'uploaded',
    SentToCarrier: 'sent_to_carrier',
    Acknowledged: 'acknowledged'
} as const;
export type CustomsDocsState = typeof CustomsDocsState[keyof typeof CustomsDocsState];


export function instanceOfCustomsDocsState(value: any): boolean {
    return Object.values(CustomsDocsState).includes(value);
}

export function CustomsDocsStateFromJSON(json: any): CustomsDocsState {
    return CustomsDocsStateFromJSONTyped(json, false);
}

export function CustomsDocsStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomsDocsState {
    return json as CustomsDocsState;
}

export function CustomsDocsStateToJSON(value?: CustomsDocsState | null): any {
    return value as any;
}

