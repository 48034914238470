import { Typography } from "antd";
import { useState } from "react";
import { useCalendarStatsApi } from "../Apis/Apis";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatsCalendar } from "../Components/StatsCalendar";
import { useOnce } from "../Hooks/useOnce";
import { CalendarDataResponse } from "../generated-openapi-client";

const { Title } = Typography;

export function SalesStatsScreen() {
  const createCalendarStatsApi = useCalendarStatsApi();
  const [data, setData] = useState<CalendarDataResponse | undefined>();

  async function refresh() {
    const calendarStatsApi = await createCalendarStatsApi();
    const response = await calendarStatsApi.getSignificantCallsCalendar();
    setData(response);
  }

  useOnce(refresh);

  if (data === undefined) {
    return <Loading />;
  }

  return (
    <>
      <Page title={<>Sales Stats</>} tags={[]} stats={<></>} extra={[]}>
        <PageTitle>{`Sales Stats`}</PageTitle>
        <Stack>
          <Title level={4}>Significant Activities</Title>
          <StatsCalendar data={data} maxValue={12} />
        </Stack>
      </Page>
    </>
  );
}
