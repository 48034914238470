/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Signals
 */
export interface Signals {
    /**
     * 
     * @type {string}
     * @memberof Signals
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Signals
     */
    lastSuccessTimestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof Signals
     */
    errorThresholdHours?: number;
    /**
     * 
     * @type {string}
     * @memberof Signals
     */
    alarmSilencedUntilTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof Signals
     */
    requiresActiveShipmentsCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Signals
     */
    requiresActiveShipmentsServiceIdentifier?: string;
}

/**
 * Check if a given object implements the Signals interface.
 */
export function instanceOfSignals(value: object): boolean {
    return true;
}

export function SignalsFromJSON(json: any): Signals {
    return SignalsFromJSONTyped(json, false);
}

export function SignalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Signals {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'lastSuccessTimestamp': json['lastSuccessTimestamp'] == null ? undefined : json['lastSuccessTimestamp'],
        'errorThresholdHours': json['errorThresholdHours'] == null ? undefined : json['errorThresholdHours'],
        'alarmSilencedUntilTimestamp': json['alarmSilencedUntilTimestamp'] == null ? undefined : json['alarmSilencedUntilTimestamp'],
        'requiresActiveShipmentsCarrierIdentifier': json['requiresActiveShipmentsCarrierIdentifier'] == null ? undefined : json['requiresActiveShipmentsCarrierIdentifier'],
        'requiresActiveShipmentsServiceIdentifier': json['requiresActiveShipmentsServiceIdentifier'] == null ? undefined : json['requiresActiveShipmentsServiceIdentifier'],
    };
}

export function SignalsToJSON(value?: Signals | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'lastSuccessTimestamp': value['lastSuccessTimestamp'],
        'errorThresholdHours': value['errorThresholdHours'],
        'alarmSilencedUntilTimestamp': value['alarmSilencedUntilTimestamp'],
        'requiresActiveShipmentsCarrierIdentifier': value['requiresActiveShipmentsCarrierIdentifier'],
        'requiresActiveShipmentsServiceIdentifier': value['requiresActiveShipmentsServiceIdentifier'],
    };
}

