/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarrierPickupNumberHistory
 */
export interface CarrierPickupNumberHistory {
    /**
     * 
     * @type {string}
     * @memberof CarrierPickupNumberHistory
     */
    carrierPickupNumberHistoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierPickupNumberHistory
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierPickupNumberHistory
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierPickupNumberHistory
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierPickupNumberHistory
     */
    carrierPickupNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierPickupNumberHistory
     */
    isPickupBooked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarrierPickupNumberHistory
     */
    adminEmail?: string;
}

/**
 * Check if a given object implements the CarrierPickupNumberHistory interface.
 */
export function instanceOfCarrierPickupNumberHistory(value: object): boolean {
    return true;
}

export function CarrierPickupNumberHistoryFromJSON(json: any): CarrierPickupNumberHistory {
    return CarrierPickupNumberHistoryFromJSONTyped(json, false);
}

export function CarrierPickupNumberHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierPickupNumberHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierPickupNumberHistoryId': json['carrierPickupNumberHistoryId'] == null ? undefined : json['carrierPickupNumberHistoryId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'carrierPickupNumber': json['carrierPickupNumber'] == null ? undefined : json['carrierPickupNumber'],
        'isPickupBooked': json['isPickupBooked'] == null ? undefined : json['isPickupBooked'],
        'adminEmail': json['adminEmail'] == null ? undefined : json['adminEmail'],
    };
}

export function CarrierPickupNumberHistoryToJSON(value?: CarrierPickupNumberHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierPickupNumberHistoryId': value['carrierPickupNumberHistoryId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'carrierPickupNumber': value['carrierPickupNumber'],
        'isPickupBooked': value['isPickupBooked'],
        'adminEmail': value['adminEmail'],
    };
}

