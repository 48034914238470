/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { QualificationStatus } from './QualificationStatus';
import {
    QualificationStatusFromJSON,
    QualificationStatusFromJSONTyped,
    QualificationStatusToJSON,
} from './QualificationStatus';
import type { SalesContact } from './SalesContact';
import {
    SalesContactFromJSON,
    SalesContactFromJSONTyped,
    SalesContactToJSON,
} from './SalesContact';

/**
 * 
 * @export
 * @interface ListCompaniesRow
 */
export interface ListCompaniesRow {
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    leadSource: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    utmSource: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    utmMedium: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    utmCampaign: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    paymentTermsDays: number;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    shipmentFrequency: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    temperature: string;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    quotesRan: number;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    firstQuoteDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    lastQuotedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    shipmentsBooked: number;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    firstBookedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    lastBookedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    acceptanceRate: number;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    totalCadSales: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListCompaniesRow
     */
    needsVerification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListCompaniesRow
     */
    verificationDenied: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    lastCallDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    lastCallBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    lastSignificantCallDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    lastSignificantCallBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    billingAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    billingCity?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    billingState?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    billingPostalCode?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof ListCompaniesRow
     */
    billingCountry?: CountryCode;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    billingLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ListCompaniesRow
     */
    billingLongitude?: number;
    /**
     * 
     * @type {QualificationStatus}
     * @memberof ListCompaniesRow
     */
    qualificationStatus: QualificationStatus;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    snoozeSalesCallsUntil?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListCompaniesRow
     */
    associatedProfessionalDomain: Array<string>;
    /**
     * 
     * @type {Array<SalesContact>}
     * @memberof ListCompaniesRow
     */
    salesContacts: Array<SalesContact>;
    /**
     * 
     * @type {boolean}
     * @memberof ListCompaniesRow
     */
    overdueSalesCall: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListCompaniesRow
     */
    isPersonal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListCompaniesRow
     */
    hasNonProfessionalDomain: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListCompaniesRow
     */
    defaultSalesContactId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListCompaniesRow
     */
    knownGroupEmailAddresses?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ListCompaniesRow
     */
    isBroker: boolean;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof ListCompaniesRow
     */
    currency: CurrencyCode;
}

/**
 * Check if a given object implements the ListCompaniesRow interface.
 */
export function instanceOfListCompaniesRow(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('companyName' in value)) return false;
    if (!('leadSource' in value)) return false;
    if (!('utmSource' in value)) return false;
    if (!('utmMedium' in value)) return false;
    if (!('utmCampaign' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('paymentTermsDays' in value)) return false;
    if (!('shipmentFrequency' in value)) return false;
    if (!('temperature' in value)) return false;
    if (!('quotesRan' in value)) return false;
    if (!('shipmentsBooked' in value)) return false;
    if (!('acceptanceRate' in value)) return false;
    if (!('totalCadSales' in value)) return false;
    if (!('needsVerification' in value)) return false;
    if (!('verificationDenied' in value)) return false;
    if (!('qualificationStatus' in value)) return false;
    if (!('associatedProfessionalDomain' in value)) return false;
    if (!('salesContacts' in value)) return false;
    if (!('overdueSalesCall' in value)) return false;
    if (!('isPersonal' in value)) return false;
    if (!('hasNonProfessionalDomain' in value)) return false;
    if (!('isBroker' in value)) return false;
    if (!('currency' in value)) return false;
    return true;
}

export function ListCompaniesRowFromJSON(json: any): ListCompaniesRow {
    return ListCompaniesRowFromJSONTyped(json, false);
}

export function ListCompaniesRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCompaniesRow {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'companyName': json['companyName'],
        'leadSource': json['leadSource'],
        'utmSource': json['utmSource'],
        'utmMedium': json['utmMedium'],
        'utmCampaign': json['utmCampaign'],
        'createdAt': json['createdAt'],
        'paymentTermsDays': json['paymentTermsDays'],
        'shipmentFrequency': json['shipmentFrequency'],
        'temperature': json['temperature'],
        'quotesRan': json['quotesRan'],
        'firstQuoteDate': json['firstQuoteDate'] == null ? undefined : json['firstQuoteDate'],
        'lastQuotedDate': json['lastQuotedDate'] == null ? undefined : json['lastQuotedDate'],
        'shipmentsBooked': json['shipmentsBooked'],
        'firstBookedDate': json['firstBookedDate'] == null ? undefined : json['firstBookedDate'],
        'lastBookedDate': json['lastBookedDate'] == null ? undefined : json['lastBookedDate'],
        'acceptanceRate': json['acceptanceRate'],
        'totalCadSales': json['totalCadSales'],
        'needsVerification': json['needsVerification'],
        'verificationDenied': json['verificationDenied'],
        'lastCallDate': json['lastCallDate'] == null ? undefined : json['lastCallDate'],
        'lastCallBy': json['lastCallBy'] == null ? undefined : json['lastCallBy'],
        'lastSignificantCallDate': json['lastSignificantCallDate'] == null ? undefined : json['lastSignificantCallDate'],
        'lastSignificantCallBy': json['lastSignificantCallBy'] == null ? undefined : json['lastSignificantCallBy'],
        'billingAddressLine': json['billingAddressLine'] == null ? undefined : json['billingAddressLine'],
        'billingCity': json['billingCity'] == null ? undefined : json['billingCity'],
        'billingState': json['billingState'] == null ? undefined : json['billingState'],
        'billingPostalCode': json['billingPostalCode'] == null ? undefined : json['billingPostalCode'],
        'billingCountry': json['billingCountry'] == null ? undefined : CountryCodeFromJSON(json['billingCountry']),
        'billingLatitude': json['billingLatitude'] == null ? undefined : json['billingLatitude'],
        'billingLongitude': json['billingLongitude'] == null ? undefined : json['billingLongitude'],
        'qualificationStatus': QualificationStatusFromJSON(json['qualificationStatus']),
        'snoozeSalesCallsUntil': json['snoozeSalesCallsUntil'] == null ? undefined : json['snoozeSalesCallsUntil'],
        'associatedProfessionalDomain': json['associatedProfessionalDomain'],
        'salesContacts': ((json['salesContacts'] as Array<any>).map(SalesContactFromJSON)),
        'overdueSalesCall': json['overdueSalesCall'],
        'isPersonal': json['isPersonal'],
        'hasNonProfessionalDomain': json['hasNonProfessionalDomain'],
        'defaultSalesContactId': json['defaultSalesContactId'] == null ? undefined : json['defaultSalesContactId'],
        'knownGroupEmailAddresses': json['knownGroupEmailAddresses'] == null ? undefined : json['knownGroupEmailAddresses'],
        'isBroker': json['isBroker'],
        'currency': CurrencyCodeFromJSON(json['currency']),
    };
}

export function ListCompaniesRowToJSON(value?: ListCompaniesRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'companyName': value['companyName'],
        'leadSource': value['leadSource'],
        'utmSource': value['utmSource'],
        'utmMedium': value['utmMedium'],
        'utmCampaign': value['utmCampaign'],
        'createdAt': value['createdAt'],
        'paymentTermsDays': value['paymentTermsDays'],
        'shipmentFrequency': value['shipmentFrequency'],
        'temperature': value['temperature'],
        'quotesRan': value['quotesRan'],
        'firstQuoteDate': value['firstQuoteDate'],
        'lastQuotedDate': value['lastQuotedDate'],
        'shipmentsBooked': value['shipmentsBooked'],
        'firstBookedDate': value['firstBookedDate'],
        'lastBookedDate': value['lastBookedDate'],
        'acceptanceRate': value['acceptanceRate'],
        'totalCadSales': value['totalCadSales'],
        'needsVerification': value['needsVerification'],
        'verificationDenied': value['verificationDenied'],
        'lastCallDate': value['lastCallDate'],
        'lastCallBy': value['lastCallBy'],
        'lastSignificantCallDate': value['lastSignificantCallDate'],
        'lastSignificantCallBy': value['lastSignificantCallBy'],
        'billingAddressLine': value['billingAddressLine'],
        'billingCity': value['billingCity'],
        'billingState': value['billingState'],
        'billingPostalCode': value['billingPostalCode'],
        'billingCountry': CountryCodeToJSON(value['billingCountry']),
        'billingLatitude': value['billingLatitude'],
        'billingLongitude': value['billingLongitude'],
        'qualificationStatus': QualificationStatusToJSON(value['qualificationStatus']),
        'snoozeSalesCallsUntil': value['snoozeSalesCallsUntil'],
        'associatedProfessionalDomain': value['associatedProfessionalDomain'],
        'salesContacts': ((value['salesContacts'] as Array<any>).map(SalesContactToJSON)),
        'overdueSalesCall': value['overdueSalesCall'],
        'isPersonal': value['isPersonal'],
        'hasNonProfessionalDomain': value['hasNonProfessionalDomain'],
        'defaultSalesContactId': value['defaultSalesContactId'],
        'knownGroupEmailAddresses': value['knownGroupEmailAddresses'],
        'isBroker': value['isBroker'],
        'currency': CurrencyCodeToJSON(value['currency']),
    };
}

