import { createContext, ReactNode, useEffect, useState } from "react";
import { useCallRefreshContext } from "./CallContext";
import { LogSalesCallFormValues } from "../../Screens/ViewCompanyScreenComponents/LogSalesCall/LogSalesCallForm";

const CALL_DATA_STORAGE_KEY = "ON_CALL_DATA";

interface CallData extends LogSalesCallFormValues {
  state: "saved" | "edited" | "init";
  companyId: string | undefined;
  companyNoteId: string | undefined;
  saving: boolean | undefined;
}

const initLogCallData: CallData = {
  state: "init",
  companyId: undefined,
  companyNoteId: undefined,
  salesContactId: undefined,

  significant: true,
  whatHappend: "",
  notes: "",

  temperature: undefined,
  shipmentFrequency: undefined,

  controlFreight: undefined,
  saving: false,
};

interface CallDataContextProps {
  callData: CallData | null;
  startCall: (_?: Partial<CallData>) => void;
  endCall: () => void;
  setSaving: (_: boolean) => void;
  handleLogCallDataChange: (key: keyof CallData, value: any) => void;
}

export const CallDataContext = createContext<CallDataContextProps>({
  callData: null,
  startCall: () => {},
  endCall: () => {},
  handleLogCallDataChange: () => {},
  setSaving: () => {},
});

export function CallDataContextProvider({ children }: { children: ReactNode }) {
  const [callData, setCallData] = useState<CallData | null>(null);

  const { refresh } = useCallRefreshContext();

  function startCall(values?: Partial<CallData>) {
    const data: CallData = {
      ...initLogCallData,
      ...values,
    };
    localStorage.setItem(CALL_DATA_STORAGE_KEY, JSON.stringify(data));
    setCallData(data);
  }

  function endCall() {
    localStorage.removeItem(CALL_DATA_STORAGE_KEY);
    setCallData(null);
    refresh();
  }

  function handleLogCallDataChange(key: keyof CallData, value: any) {
    setCallData((prevInput) => {
      if (!prevInput) return null;
      const updates: CallData = {
        ...prevInput,
        [key]: value,
        state: "edited",
      };
      localStorage.setItem(CALL_DATA_STORAGE_KEY, JSON.stringify(updates));
      return updates;
    });
  }

  function setSaving(value: boolean) {
    handleLogCallDataChange("saving", value);
  }

  /**
   * From [MDN](https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#responding_to_storage_changes_with_the_storageevent)
   * The StorageEvent is fired whenever a change is made to the Storage object.
   * This won't work on the same page that is making the changes -
   * it is really a way for other pages on the domain using the storage to sync any changes that are made。
   *
   * This is fine, this way it does not set the log call data twice.
   */
  function handleStoredDataChange() {
    window.addEventListener("storage", async function (event) {
      if (event.key === CALL_DATA_STORAGE_KEY) {
        /*
         *  If the `newValue` is null it means that the item was removed (call ended)
         *  Then we refresh the page
         */
        if (!event.newValue) {
          await refresh();
          setCallData(null);
          return;
        }

        /*
         * Else just parse it and load it to state
         */
        setCallData(JSON.parse(event.newValue));
      }
    });
  }

  function fetchData() {
    const stored = localStorage.getItem(CALL_DATA_STORAGE_KEY);

    if (!stored) {
      return;
    }

    const parsed = JSON.parse(stored) as CallData;
    setCallData(parsed);
  }

  useEffect(() => {
    fetchData();
    handleStoredDataChange();
  }, []);

  return (
    <CallDataContext.Provider
      value={{
        callData,
        startCall,
        endCall,
        handleLogCallDataChange,
        setSaving,
      }}
    >
      {children}
    </CallDataContext.Provider>
  );
}
