import MenuItem from "antd/lib/menu/MenuItem";
import { useState } from "react";
import { ConfirmMenuItemWithTextArea } from "../Components/ConfirmButtonWithTextArea";
import {
  isShipmentStarred,
  starShipment,
  unstarShipment,
} from "../Helpers/starredShipments";
import { TabProps } from "./ViewShipmentScreenComponents/TabProps";

export function StarShipmentMenuItem(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const [, setLastAlterTime] = useState(new Date().valueOf());

  const isStarred = isShipmentStarred(shipmentId);

  if (isStarred) {
    return (
      <MenuItem
        onClick={function () {
          unstarShipment(shipmentId);
          setLastAlterTime(new Date().valueOf());
          props.onRefresh();
        }}
      >
        Unstar Shipment
      </MenuItem>
    );
  } else {
    return (
      <ConfirmMenuItemWithTextArea
        onConfirm={async function (reason) {
          starShipment(shipmentId, reason);
          setLastAlterTime(new Date().valueOf());
          props.onRefresh();
        }}
        placeholder="Enter reason you are starring this"
        question="Are you sure you want to star this?"
        okText="Yes, star it"
        tooltip="This will star the shipment for you on your local browser. No one else will see this"
      >
        Star Shipment
      </ConfirmMenuItemWithTextArea>
    );
  }
}
