import NumberFormat from "react-number-format";
import { CurrencyCode } from "../generated-openapi-client";

interface PriceProps {
  children: any;
  currency: CurrencyCode;
}

export function Price(props: PriceProps) {
  return (
    <NumberFormat
      value={props.children}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={`${props.currency}$`}
    />
  );
}
