import { Card, Form, Tag, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useShipmentsApi } from "../Apis/Apis";
import CarrierLogo from "../Components/CarrierLogo";
import Colors from "../Components/Colors";
import { CompanyFilter } from "../Components/CompanyFilter";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Weight } from "../Components/Weight";
import { emojiForCountry } from "../Helpers/emojiForCountry";
import { isPhone } from "../Helpers/isPhone";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import {
  FreightClaimState,
  ShipmentCompanyFreightClaim,
} from "../generated-openapi-client";
import { CompanyShipment } from "../generated-openapi-client/models/CompanyShipment";
import { ShipmentState } from "../generated-openapi-client/models/ShipmentState";
import { calculateFreightClaimTotal } from "./ViewShipmentScreenComponents/ViewClaimButton";

const { Title } = Typography;

interface ClaimsTableProps {
  shipments: ShipmentCompanyFreightClaim[];
  title: string;
  companyIdFilter: string | undefined;
  onlyShowFlagged: boolean;
}

interface QuotedShipmentCardProps {
  shipment: CompanyShipment;
}

function QuotedShipmentCard(props: QuotedShipmentCardProps) {
  const navigate = useNavigate();
  const shipmentId = props.shipment.shipment.shipmentId!!;
  const shipment = props.shipment.shipment;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const pickupAddress = props.shipment.shipment.pickupLocation?.address!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const deliveryAddress = props.shipment.shipment.deliveryLocation?.address!!;
  return (
    <Card
      onClick={function () {
        navigate(`/view-shipment?shipmentId=${shipmentId}`);
      }}
      size="small"
      title={
        <Stack align="left">
          <strong>{props.shipment.company.companyName}</strong>
        </Stack>
      }
      style={{
        width: 340,
        marginBottom: "16px",
        borderColor: colorForState(shipment.state!!),
        borderWidth: "3px",
      }}
      extra={
        <>
          {shipment.tags?.map((s) => (
            <Tag color="orange">{s}</Tag>
          ))}
          {shipment.problems?.map((s) => (
            <Tag color="magenta">🚩 {s}</Tag>
          ))}
        </>
      }
    >
      <Stack align="left">
        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              width: "50px",
              paddingRight: "4px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            From:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.pickupLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {pickupAddress.city}, {pickupAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>

        <Spacer height={4} />

        <HorizontalStack verticalAlign="middle">
          <div
            style={{
              textAlign: "right",
              paddingRight: "4px",
              width: "50px",
              fontSize: "8px",
              color: Colors.LightText,
            }}
          >
            To:
          </div>
          <div style={{ fontWeight: 500 }}>
            {props.shipment.shipment.deliveryLocation?.businessName}
          </div>
          <Spacer width={4} />
          <div style={{ color: Colors.LightText, fontSize: "10px" }}>
            {deliveryAddress.city}, {deliveryAddress.stateOrProvinceCode}
          </div>
        </HorizontalStack>
      </Stack>
    </Card>
  );
}

function colorForState(state: ShipmentState): string {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return Colors.Gold;
    case ShipmentState.Delivered:
      return Colors.Green;
    case ShipmentState.InTransit:
      return Colors.Blue;
    case ShipmentState.Quoted:
      return Colors.DarkGray;
    default:
      return Colors.Red;
  }
}

function ClaimsTable(props: ClaimsTableProps) {
  let shipments = props.shipments;

  shipments = props.shipments.filter(function (c) {
    if (props.companyIdFilter !== undefined && props.companyIdFilter !== "") {
      return c.company.companyId === props.companyIdFilter;
    }

    return true;
  });

  // Hide if only showing flagged shipments, since pre-booked shipments can't be flagged
  if (props.onlyShowFlagged) {
    return <></>;
  }

  if (shipments.length === 0) {
    return <></>;
  }

  if (isPhone) {
    return (
      <>
        <Title style={{ marginBottom: "16px" }} level={5}>
          {props.title}
        </Title>
        <Stack align="left">
          {shipments.map(function (s) {
            return <QuotedShipmentCard shipment={s} />;
          })}
        </Stack>
      </>
    );
  }

  const columns: DataTableColumn<ShipmentCompanyFreightClaim>[] = [
    {
      title: "Company",
      render: (o) => (
        <Stack align="left">
          <div>{o.company.companyName}</div>
          <Spacer height={8} />
          {o.shipment.tags?.map((s) => (
            <Tag color="orange">{s}</Tag>
          ))}
          {o.shipment.problems?.map((s) => (
            <Tag color="magenta">🚩 {s}</Tag>
          ))}
        </Stack>
      ),
      width: 230,
    },
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.freightClaim.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={60}
          height={40}
        />
      ),
    },
    {
      title: "Status",
      render: (o) => <div>{o.freightClaim.freightClaimState}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <div>
          ${calculateFreightClaimTotal(o.freightClaim)}{" "}
          {o.freightClaim.claimCurrency}
        </div>
      ),
    },
    {
      title: "Booked At",
      render: (o) => <div>{o.shipment.bookedAt}</div>,
      sorter: function (a: CompanyShipment, b: CompanyShipment) {
        return (
          moment(a.shipment.bookedAt).valueOf() -
          moment(b.shipment.bookedAt).valueOf()
        );
      },
      width: 150,
    },
    {
      title: "Details",
      width: 300,
      render: (o: CompanyShipment) => (
        <>
          <Stack align="left">
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                {emojiForCountry(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  o.shipment.pickupLocation?.address?.countryCode!!
                )}
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>
                  {o.shipment.pickupLocation?.address?.city},{" "}
                  {o.shipment.pickupLocation?.address?.stateOrProvinceCode}
                </div>
              </Stack>
            </HorizontalStack>
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                {emojiForCountry(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  o.shipment.deliveryLocation?.address?.countryCode!!
                )}
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>
                  {o.shipment.deliveryLocation?.address?.city},{" "}
                  {o.shipment.deliveryLocation?.address?.stateOrProvinceCode}
                </div>
              </Stack>
            </HorizontalStack>
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                📦
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{o.shipment.lineItems!![0].description}</div>
                <div
                  style={{
                    marginTop: "-4px",
                    fontSize: "12px",
                    color: Colors.LightText,
                  }}
                >
                  {o.shipment.totalHandlingUnits} skids,{" "}
                  <Weight>{o.shipment.totalWeight!!}</Weight>
                </div>
              </Stack>
            </HorizontalStack>
          </Stack>
        </>
      ),
    },
    {
      title: "Actions",
      render: function (o) {
        return <ViewShipmentButton shipmentId={o.shipment.shipmentId!!} />;
      },
      width: 150,
    },
  ];

  return (
    <>
      <Title style={{ marginBottom: "16px", marginTop: "32px" }} level={4}>
        {props.title}
      </Title>
      <DataTable columns={columns} data={shipments} pagination={false} />
    </>
  );
}

function getCompany(scfc: ShipmentCompanyFreightClaim) {
  return { name: scfc.company.companyName!!, id: scfc.company.companyId!! };
}

function getCompanyNames(
  shipments: ShipmentCompanyFreightClaim[]
): Array<{ id: string; name: string }> {
  const companies = shipments.map((c) => getCompany(c));

  const m = new Map<string, string>();
  companies.forEach(function (c) {
    m.set(c.id, c.name);
  });

  console.log(m);

  const results: Array<{ id: string; name: string }> = [];
  m.forEach(function (name, id) {
    results.push({ id, name });
  });

  return results;
}

export function ViewClaimsScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [claims, setClaims] = useState<
    ShipmentCompanyFreightClaim[] | undefined
  >(undefined);
  const [error, setError] = useState();
  const [companyIdFilter, setCompanyIdFilter] = useState<string | undefined>();

  function byDate(
    a: ShipmentCompanyFreightClaim,
    b: ShipmentCompanyFreightClaim
  ) {
    return (
      moment(a.shipment.createdAt).valueOf() -
      moment(b.shipment.createdAt).valueOf()
    );
  }

  useOnce(async function () {
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.getClaims();
      console.log(`!!!!response`, { response });
      setClaims(response);
    } catch (e: any) {
      setError(e);
    }
  });

  if (error !== undefined) {
    return <>❌ An error occured : {JSON.stringify(error, null, "\t")}</>;
  } else if (claims === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title="💥 Claims"
          tags={[]}
          extra={[
            <HorizontalStack align="right" width="100%">
              <Form.Item label="Filter by company">
                <CompanyFilter
                  companies={getCompanyNames(claims)}
                  onFilter={function (companyId) {
                    setCompanyIdFilter(companyId);
                  }}
                />
              </Form.Item>
            </HorizontalStack>,
          ]}
          stats={<></>}
        >
          <PageTitle>💥 Claims</PageTitle>
          <BrowserView>
            <Spacer height={32} />
          </BrowserView>

          <ClaimsTable
            title="🔎 Non-Cancelled Claims"
            shipments={claims
              .filter(
                (o) =>
                  o.freightClaim.freightClaimState !==
                  FreightClaimState.Cancelled
              )
              .sort(byDate)}
            companyIdFilter={companyIdFilter}
            onlyShowFlagged={false}
          />
          <ClaimsTable
            title="🔎 Cancelled Claims"
            shipments={claims
              .filter(
                (o) =>
                  o.freightClaim.freightClaimState ===
                  FreightClaimState.Cancelled
              )
              .sort(byDate)}
            companyIdFilter={companyIdFilter}
            onlyShowFlagged={false}
          />
        </Page>
      </>
    );
  }
}
