/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FedExAddressValidationInformation
 */
export interface FedExAddressValidationInformation {
    /**
     * 
     * @type {boolean}
     * @memberof FedExAddressValidationInformation
     */
    isPickupResidential: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FedExAddressValidationInformation
     */
    isDeliveryResidential: boolean;
    /**
     * 
     * @type {string}
     * @memberof FedExAddressValidationInformation
     */
    rawPickupInformation: string;
    /**
     * 
     * @type {string}
     * @memberof FedExAddressValidationInformation
     */
    rawDeliveryInformation: string;
}

/**
 * Check if a given object implements the FedExAddressValidationInformation interface.
 */
export function instanceOfFedExAddressValidationInformation(value: object): boolean {
    if (!('isPickupResidential' in value)) return false;
    if (!('isDeliveryResidential' in value)) return false;
    if (!('rawPickupInformation' in value)) return false;
    if (!('rawDeliveryInformation' in value)) return false;
    return true;
}

export function FedExAddressValidationInformationFromJSON(json: any): FedExAddressValidationInformation {
    return FedExAddressValidationInformationFromJSONTyped(json, false);
}

export function FedExAddressValidationInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FedExAddressValidationInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'isPickupResidential': json['isPickupResidential'],
        'isDeliveryResidential': json['isDeliveryResidential'],
        'rawPickupInformation': json['rawPickupInformation'],
        'rawDeliveryInformation': json['rawDeliveryInformation'],
    };
}

export function FedExAddressValidationInformationToJSON(value?: FedExAddressValidationInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isPickupResidential': value['isPickupResidential'],
        'isDeliveryResidential': value['isDeliveryResidential'],
        'rawPickupInformation': value['rawPickupInformation'],
        'rawDeliveryInformation': value['rawDeliveryInformation'],
    };
}

