import {
  Alert,
  Button,
  Col,
  Menu,
  message,
  Modal,
  Row,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { CarrierInvoiceSourceTag } from "../../Components/CarrierInvoiceSourceTag";
import { LineItemsChangesTable } from "../../Components/changes/LineItemsChangesTable";
import Colors from "../../Components/Colors";
import { ConfirmButton, ConfirmMenuItem } from "../../Components/ConfirmButton";
import { ConfirmButtonWithTextArea } from "../../Components/ConfirmButtonWithTextArea";
import { CopyMenuItem } from "../../Components/CopyMenuItem";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import { Pdf } from "../../Components/Pdf";
import Stack from "../../Components/Stack";
import { StatisticText } from "../../Components/StatisticText";
import { UrlMenuItem } from "../../Components/UrlMenuItem";
import { ViewHtmlButton } from "../../Components/ViewHtmlButton";
import { ViewRawButton } from "../../Components/ViewRawButton";
import {
  CarrierInvoice,
  CurrencyCode,
  DocumentType,
  InvoiceAuditProblemResolutionState,
  Location,
  Shipment,
} from "../../generated-openapi-client";
import { CarrierInvoiceState } from "../../generated-openapi-client/models/CarrierInvoiceState";
import { CarrierPaymentProperties } from "../../generated-openapi-client/models/CarrierPaymentProperties";
import { getQuoteShipmentKeyDifferences } from "../../Helpers/getQuoteShipmentKeyDifferences";
import { isBlank } from "../../Helpers/isNotBlank";
import { getMapUrlForAddress } from "../../Helpers/mapUtils";
import { formatAsCurrency } from "../../Helpers/numberFormatting";
import { ObjectDifference } from "../../Helpers/objectDifferences";
import { carrierPropertiesAnyService } from "../../Helpers/serviceProperties";
import { useDisplay } from "../../Hooks/useDisplay";
import Spacer from "../../Spacer";
import { AddCarrierCreditNoteButton } from "./AddCarrierCreditNote";
import { describeCarrierInvoiceState } from "./CarrierInvoicesTab";
import { ChallengeCarrierInvoiceButton2 } from "./ChallengeCarrierInvoiceButton2";
import { CheckIfCarrierInvoiceIsPaidMenuItem } from "./CheckIfCarrierInvoiceIsPaidMenuItem";
import { DimensionReportSupportEmailButton } from "./DimensionReportSupportEmailButton";
import {
  AddInvoiceAuditProblemButton,
  InvoiceAuditProblemsTable,
} from "./IdentifyInvoiceAuditProblemsSection";
import { IsolatedHTML } from "./IsolatedHtml";
import { describeAccessorials } from "./LocationInfo";
import { LineItemsSummary } from "./ManagePickupTab";
import {
  MarkAllProblemsInInvoiceIdentifiedButton,
  MarkProblemsNotIdentifiedMenuItem,
} from "./MarkAllProblemsInInvoiceIdentifiedButton";
import { MarkApprovedWhenPaymentPendingCarrierInvoiceMenuItem } from "./MarkApprovedWhenPaymentPendingCarrierInvoiceMenuItem";
import { QuoteShipmentDifferencesModal } from "./QuoteShipmentDifferences";
import { RetryProcessPaymentMenuItem } from "./RetryProcessPaymentMenuItem";
import { TabProps } from "./TabProps";
import { ViewCarrierInvoiceInXeroMenuItem } from "./ViewCarrierInvoiceInXeroMenuItem";
import { ViewCarrierRequestAuditButton } from "./ViewCarrierRequestAuditButton";
import { ViewDocumentButton } from "./ViewDocumentButton";
import { ViewHistoricLineItemsButton } from "./ViewHistoricLineItemsButton";
import { VoidPaidCarrierInvoiceMenuItem } from "./VoidPaidCarrierInvoiceMenuItem";
import { WeightReportSupportEmailButton } from "./WeightReportSupportEmailButton";
import { CloseOutlined } from "@ant-design/icons";
import { describeEquipmentType } from "../../Helpers/describeEquipmentType";

const { Title } = Typography;

function VoidInvoiceButton(props: ViewCarrierInvoiceButtonProps) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function onConfirm(voidReason: string) {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markCarrierInvoiceVoided({
        carrierInvoiceId,
        voidReason,
      });
      await props.onRefresh();
      message.success("Voided!");
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
  }

  if (
    props.carrierInvoice.invoiceState !== CarrierInvoiceState.Issued &&
    props.carrierInvoice.invoiceState !== CarrierInvoiceState.Approved
  ) {
    return <></>;
  }

  return (
    <ConfirmButtonWithTextArea
      onConfirm={onConfirm}
      placeholder="Enter void reason"
      question="Are you sure you want to void this invoice?"
      okText="Yes, void the invoice"
      tooltip="Voiding the invoice will also remove it from Xero"
    >
      🚫 Void Invoice
    </ConfirmButtonWithTextArea>
  );
}

function ApproveInvoiceButton(props: ViewCarrierInvoiceButtonProps) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleShow = () => {
    setIsModalVisible(true);
  };

  async function onConfirm() {
    setConfirming(true);
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markCarrierInvoiceApproved({
        carrierInvoiceId,
        approvalReason: "",
      });
      await props.onRefresh();
      message.success("Approved!");

      setIsModalVisible(false);
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
    setConfirming(false);
  }

  if (props.carrierInvoice.invoiceState !== CarrierInvoiceState.Issued) {
    return <></>;
  }

  function generateDisabledMessage(): string | undefined {
    if (!props.carrierInvoice.problemsIdentified) {
      return "Check you have identified all problems first?";
    }

    if (
      props.shipmentData.shipment.shipment.invoiceAuditProblems!!.length === 0
    ) {
      return "No problems have been identified. Can't approve";
    }

    console.log(
      `^^^^ invoiceAuditProblems`,
      props.shipmentData.shipment.shipment.invoiceAuditProblems
    );

    const anyUnresolvedIssues =
      props.shipmentData.shipment.shipment.invoiceAuditProblems!!.some(
        function (iap) {
          return (
            iap.resolutionState ===
            InvoiceAuditProblemResolutionState.Unresolved
          );
        }
      );

    if (anyUnresolvedIssues) {
      return "There are unresolved problems - we cannot approve this";
    }

    return undefined;
  }

  const disabledMessage = generateDisabledMessage();

  return (
    <ConfirmButton
      type="default"
      loading={confirming}
      tooltip={disabledMessage ?? "You can approve this invoice"}
      okText="Yes, approve the invoice."
      onConfirm={onConfirm}
      question="Are you sure you want to approve this invoice?"
      disabled={disabledMessage !== undefined}
    >
      👍 Approve Invoice
    </ConfirmButton>
  );

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Invoice Approval"
        visible={isModalVisible}
        onOk={onConfirm}
        okText="👍 Approve this invoice"
        onCancel={handleCancel}
        confirmLoading={confirming}
        width={600}
      ></Modal>
      <Tooltip title={disabledMessage}>
        <Button disabled={disabledMessage !== undefined} onClick={handleShow}>
          👍 Approve Invoice
        </Button>
      </Tooltip>
    </>
  );
}

function MarkInvoicePaymentPendingButton(props: ViewCarrierInvoiceButtonProps) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markCarrierInvoicePaymentPending({
        carrierInvoiceId,
      });
      await props.onRefresh();
      message.success("Done!");
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
  }

  if (props.carrierInvoice.invoiceState !== CarrierInvoiceState.Approved) {
    return <></>;
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question="Are you sure you want to mark this invoice payment pending? Typically you don't need to do this. Marking the payment task done in Monday will automatically mark it as payment pending"
      okText="Yes, mark as payment pending"
      tooltip="You probably don't need to do this. You can mark an invoice as payment pending. Do this if the invoice was already paid before a Monday payment task was created for it"
    >
      Mark Payment Pending
    </ConfirmButton>
  );
}

function ClearChequeSendAttemptedMenuItem(
  props: ViewCarrierInvoiceButtonProps
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.clearChequeSendAttempted({
        carrierInvoiceId,
      });
      await props.onRefresh();
      message.success("Done! You can now try to send a cheque again");
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
  }

  if (props.carrierInvoice.invoiceState !== CarrierInvoiceState.Approved) {
    return <></>;
  }

  if (!props.carrierInvoice.chequeSendAttempted) {
    return <></>;
  }

  if (props.carrierInvoice.chequeSent) {
    return <></>;
  }

  const properties = carrierPropertiesAnyService(
    props.carrierInvoice.carrierIdentifier!!
  );

  const chequeInformation =
    props.carrierInvoice.currency === CurrencyCode.Cad
      ? properties.paymentProperties.chequeInformationCad
      : properties.paymentProperties.chequeInformationUsd;

  if (chequeInformation === undefined) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question={`Are you sure you want to send clear cheque send attempted. This will allow another sending attempt. Please understand why it failed first`}
      okText="Yes, clear"
      tooltip="If cheque sending failed. It's blocked until we understand why it failed as a guard to avoid double sending"
    >
      <Tag color="red">Advanced</Tag> Clear sending cheque
    </ConfirmMenuItem>
  );
}

function ViewChequeMenuItem(props: ViewCarrierInvoiceButtonProps) {
  if (props.carrierInvoice.chequeBatchId === undefined) {
    return <></>;
  }

  return (
    <UrlMenuItem
      title="View Cheque in Checkflo"
      url={`https://my.checkflo.com/web/batchReport.spr?action=batchListDetails&bid=${props.carrierInvoice.chequeBatchId}`}
      disabled={isBlank(props.carrierInvoice.paymentMondayId)}
    />
  );
}

function PayCarrierInvoiceByChequeMenuItem(
  props: ViewCarrierInvoiceButtonProps
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.payCarrierInvoiceByCheque({
        carrierInvoiceId,
      });
      await props.onRefresh();
      message.success("Done!");
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
  }

  if (props.carrierInvoice.invoiceState !== CarrierInvoiceState.Approved) {
    return <></>;
  }

  if (props.carrierInvoice.chequeSendAttempted) {
    return <></>;
  }

  const properties = carrierPropertiesAnyService(
    props.carrierInvoice.carrierIdentifier!!
  );

  const chequeInformation =
    props.carrierInvoice.currency === CurrencyCode.Cad
      ? properties.paymentProperties.chequeInformationCad
      : properties.paymentProperties.chequeInformationUsd;

  if (chequeInformation === undefined) {
    return <></>;
  }

  const { makeOutTo, address, city, state, postalCode, countryCode } =
    chequeInformation;
  const chequeAddress = `${makeOutTo}, ${address}, ${city}, ${state}, ${postalCode}, ${countryCode}`;

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question={`Are you sure you want to send a cheque to pay for this shipment? ${chequeAddress}`}
      okText="Yes, send a cheque"
      tooltip="This requires cheque sending to be already set up for this carrier"
    >
      <Tag color="red">Advanced</Tag> Send Cheque to Carrier
    </ConfirmMenuItem>
  );
}

function StartRequestPaymentFlowButton(props: ViewCarrierInvoiceButtonProps) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [carrierPaymentProperties, setCarrierPaymentProperties] = useState<
    CarrierPaymentProperties | undefined
  >();

  async function loadPaymentInstructions() {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const response = await carrierInvoiceApi.getPaymentProperties({
      carrierInvoiceId,
    });
    setCarrierPaymentProperties(response);
  }

  useEffect(
    function () {
      loadPaymentInstructions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.carrierInvoice.carrierInvoiceId]
  );

  function paymentDescription() {
    if (!carrierPaymentProperties) {
      return `Loading...`;
    } else {
      return `Strategy: ${carrierPaymentProperties.invoicePaymentStrategy}, Instructions: ${carrierPaymentProperties.paymentProcessingInstructions}`;
    }
  }

  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.requestPayCarrierInvoice({ carrierInvoiceId });
      await props.onRefresh();
      message.success("Done!");
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
  }

  if (props.carrierInvoice.invoiceState !== CarrierInvoiceState.Approved) {
    return <></>;
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question={
        <div>
          <div>Are you sure you want to start request payment flow?</div>
          <Spacer height={8} />
          <div>{paymentDescription()}</div>
        </div>
      }
      okText="Yes, start the flow"
      tooltip={`The request payment flow will create a Monday task for paying. ${paymentDescription()}`}
    >
      Start Payment Flow
    </ConfirmButton>
  );
}

interface ViewCarrierInvoiceButtonProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function ViewCarrierInvoiceButton(props: ViewCarrierInvoiceButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const quote = props.shipmentData.shipment.quote;
  const carrierIdentifier = quote.carrierIdentifier;
  const proNumber = props.shipmentData.shipment.shipment.proNumber;
  const filename = `${carrierIdentifier}-invoice-${proNumber}.pdf`;

  const hasWeightReports = props.shipmentData.documentsTab.documents.some(
    function (d) {
      return (
        d.shipmentDocument.documentType === DocumentType.CarrierWeightReport
      );
    }
  );

  const hasAdditionalFees = (quote.additionalCarrierFeesCad ?? 0) > 0;

  const weightReports = props.shipmentData.documentsTab.documents.filter(
    function (d) {
      return (
        d.shipmentDocument.documentType === DocumentType.CarrierWeightReport
      );
    }
  );

  const quoteShipmentDifferences = getQuoteShipmentKeyDifferences(
    props.shipmentData.shipment.quote,
    props.shipmentData.shipment.shipment
  );

  const differencesDisplay = useDisplay();

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`Carrier Invoice : ${props.carrierInvoice.invoiceIdentifier!!}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <Stack align="left" width="100%">
          <HorizontalStack spacing={32} style={{ marginLeft: "-32px" }}>
            <StatisticText
              title="Invoice State"
              value={describeCarrierInvoiceState(
                props.carrierInvoice.invoiceState!!
              )}
            />

            <StatisticText
              title="Amount"
              value={formatAsCurrency(
                props.carrierInvoice.amount,
                props.carrierInvoice.currency
              )}
            />

            {props.shipmentData.shipment.quote
              .quotedPriceByCarrierOriginalCurrency && (
              <StatisticText
                title="Quote Amount"
                value={formatAsCurrency(
                  props.shipmentData.shipment.quote
                    .quotedPriceByCarrierOriginalCurrency,
                  props.shipmentData.shipment.quote.originalCurrency
                )}
              />
            )}

            {props.shipmentData.shipment.quote.originalCurrency ===
              CurrencyCode.Usd &&
              props.carrierInvoice.currency === CurrencyCode.Usd && (
                <StatisticText
                  title="Difference"
                  value={formatAsCurrency(
                    Math.abs(
                      props.shipmentData.shipment.quote
                        .quotedPriceByCarrierOriginalCurrency!! -
                        props.carrierInvoice.amount!!
                    ),
                    "USD"
                  )}
                />
              )}
            {props.carrierInvoice.currency === CurrencyCode.Cad && (
              <StatisticText
                title="Difference"
                value={formatAsCurrency(
                  Math.abs(
                    props.shipmentData.shipment.quote
                      .quotedPriceByCarrierCad!! - props.carrierInvoice.amount!!
                  ),
                  "CAD"
                )}
              />
            )}

            <StatisticText
              title="Source"
              value={
                <div style={{ position: "relative", top: "-4px" }}>
                  <CarrierInvoiceSourceTag
                    source={props.carrierInvoice.source!!}
                  ></CarrierInvoiceSourceTag>
                </div>
              }
            />

            <StatisticText
              title="Quote #"
              value={props.shipmentData.shipment.quote.carrierQuoteReference}
            />
            <StatisticText
              title="Service"
              value={props.shipmentData.shipment.quote.serviceIdentifier}
            />
          </HorizontalStack>
          <Spacer height={32} />
          <ButtonRow
            style={{ flexWrap: "wrap", rowGap: "8px" }}
            extrasMenu={
              <Menu>
                <VoidPaidCarrierInvoiceMenuItem {...props} />
                <MarkApprovedWhenPaymentPendingCarrierInvoiceMenuItem
                  {...props}
                />
                <Menu.Divider />
                <ViewCarrierInvoiceInXeroMenuItem
                  xeroInvoiceId={props.carrierInvoice.xeroInvoiceId}
                />
                <Menu.Divider />
                <CopyMenuItem
                  title="Copy carrierInvoiceId"
                  value={props.carrierInvoice.carrierInvoiceId!!}
                />
                <Menu.Divider />
                <UrlMenuItem
                  title="Monday Approval Task"
                  url={`https://freightsimple.monday.com/boards/1259362202/views/41386415/pulses/${props.carrierInvoice.mondayId}`}
                  disabled={isBlank(props.carrierInvoice.mondayId)}
                />
                <UrlMenuItem
                  title="Monday Payment Task"
                  url={`https://freightsimple.monday.com/boards/1259362202/views/41386415/pulses/${props.carrierInvoice.paymentMondayId}`}
                  disabled={isBlank(props.carrierInvoice.paymentMondayId)}
                />
                <Menu.Divider />
                <CheckIfCarrierInvoiceIsPaidMenuItem {...props} />
                <Menu.Divider />
                <RetryProcessPaymentMenuItem {...props} />
                <Menu.Divider />
                <PayCarrierInvoiceByChequeMenuItem {...props} />
                <ClearChequeSendAttemptedMenuItem {...props} />
                <ViewChequeMenuItem {...props} />
                <MarkProblemsNotIdentifiedMenuItem {...props} />
              </Menu>
            }
          >
            <VoidInvoiceButton {...props} />
            <ApproveInvoiceButton {...props} />
            <MarkInvoicePaymentPendingButton {...props} />
            <StartRequestPaymentFlowButton {...props} />
            <ChallengeCarrierInvoiceButton2
              {...props}
              data={props.shipmentData}
              refresh={props.onRefresh}
            />
            {props.shipmentData.shipment.quote
              .carrierPriceExplanationInternal !== undefined && (
              <ViewHtmlButton
                data={
                  props.shipmentData.shipment.quote
                    .carrierPriceExplanationInternal
                }
                title="Explain Quote (Internal)"
              />
            )}
            {props.shipmentData.shipment.quote
              .carrierPriceExplanationExternal !== undefined && (
              <ViewHtmlButton
                data={
                  props.shipmentData.shipment.quote
                    .carrierPriceExplanationExternal
                }
                title="Explain Quote (External)"
              />
            )}
            <ViewRawButton data={props.carrierInvoice} />
            <ViewHistoricLineItemsButton shipmentData={props.shipmentData} />
            <ViewCarrierRequestAuditButton shipmentData={props.shipmentData} />
            <WeightReportSupportEmailButton
              data={props.shipmentData}
              carrierInvoice={props.carrierInvoice}
            />
            <DimensionReportSupportEmailButton
              data={props.shipmentData}
              carrierInvoice={props.carrierInvoice}
            />
            <AddCarrierCreditNoteButton {...props} />
          </ButtonRow>
        </Stack>
        {hasWeightReports && (
          <>
            <Spacer height={16} />
            <Alert
              message="This shipment has weight/dimension report(s)"
              type="error"
              action={
                <HorizontalStack>
                  {weightReports.map(function (wr) {
                    return (
                      <ViewDocumentButton
                        document={wr.shipmentDocument}
                        shipmentData={props.shipmentData}
                      />
                    );
                  })}
                </HorizontalStack>
              }
            />
          </>
        )}
        {hasAdditionalFees && (
          <>
            <Spacer height={16} />
            <Alert
              message={`Our system added additional fees that were not included in the quote that we got from the carrier (likely as the carrier cannot quote for them in their system) : $${quote.additionalCarrierFeesCad?.toFixed(
                2
              )} CAD due to ${quote.additionalCarrierFeesNotes}`}
              type="warning"
            />
          </>
        )}
        <Spacer height={16} />
        <HorizontalStack align="spread">
          <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
            <Title level={4}>Invoice Audit Problems</Title>
            {props.carrierInvoice.problemsIdentified && (
              <Tag color="green">✅ All Problems Identified</Tag>
            )}
          </HorizontalStack>
          <ButtonRow>
            <MarkAllProblemsInInvoiceIdentifiedButton {...props} />
            <AddInvoiceAuditProblemButton {...props} />
          </ButtonRow>
        </HorizontalStack>
        {props.shipmentData.shipment.shipment.invoiceAuditProblems!!.length !==
          0 && (
          <InvoiceAuditProblemsTable
            problems={
              props.shipmentData.shipment.shipment.invoiceAuditProblems!!
            }
            {...props}
          />
        )}
        {props.shipmentData.shipment.shipment.invoiceAuditProblems!!.length ===
          0 && (
          <div
            style={{
              backgroundColor: "#fafafa",
              marginTop: "16px",
              border: "1px solid #ddd",
              width: "100%",
              padding: "24px",
            }}
          >
            ⚠️ No problems identified
          </div>
        )}
        <Spacer height={64} />
        <Title level={4}>Shipment Overview</Title>
        <div style={{ border: "1px solid #ccc", padding: "16px" }}>
          {quoteShipmentDifferences.totalDifferencesCount > 0 && (
            <Alert
              type="warning"
              showIcon
              icon={<span>⚠️</span>}
              message={
                <span>
                  <strong style={{ fontSize: "16px" }}>
                    {quoteShipmentDifferences.totalDifferencesCount}
                  </strong>{" "}
                  key difference
                  {quoteShipmentDifferences.totalDifferencesCount > 1 &&
                    "s"}{" "}
                  between the quote and the shipment.
                  <Button onClick={differencesDisplay.show} type="link">
                    See differences
                  </Button>
                  <QuoteShipmentDifferencesModal
                    quote={props.shipmentData.shipment.quote}
                    shipment={props.shipmentData.shipment.shipment}
                    differences={quoteShipmentDifferences}
                    display={differencesDisplay}
                  />
                </span>
              }
            />
          )}
          <Spacer height={12} />
          <Row justify="space-evenly" gutter={[12, 12]}>
            <Col flex={1}>
              <GeneralInfoOverview
                shipment={props.shipmentData.shipment.shipment}
                differences={quoteShipmentDifferences.generalDifferences}
              />
            </Col>
            <Col flex={1}>
              <LocationOverview
                location={props.shipmentData.shipment.shipment.pickupLocation!!}
                context="Pickup"
                differences={quoteShipmentDifferences.pickupDifferences}
              />
            </Col>
            <Col flex={1}>
              <LocationOverview
                location={
                  props.shipmentData.shipment.shipment.deliveryLocation!!
                }
                context="Delivery"
                differences={quoteShipmentDifferences.deliveryDifferences}
              />
            </Col>
          </Row>
          <Spacer height={32} />
          <Stack align="left">
            <HorizontalStack width="100%" align="spread">
              <HorizontalStack
                style={{ columnGap: "8px" }}
                verticalAlign="middle"
              >
                <DifferenceWarning
                  isDifferent={
                    quoteShipmentDifferences.lineItemsDifferencesCount > 0
                  }
                >
                  <Title level={4}>Line Items</Title>
                </DifferenceWarning>
                {quoteShipmentDifferences.lineItemsDifferencesCount > 0 && (
                  <>
                    <Spacer width={8} />
                    <Tag color="orange">Quote</Tag>
                    <CloseOutlined />
                    <Tag color="green">Shipment</Tag>
                  </>
                )}
              </HorizontalStack>
              <HorizontalStack>
                <LineItemsSummary
                  shipment={props.shipmentData.shipment.shipment}
                />
                <Spacer width={16} />
              </HorizontalStack>
            </HorizontalStack>
            <LineItemsChangesTable
              inBetween={<CloseOutlined />}
              left={props.shipmentData.shipment.quote.lineItems!}
              right={props.shipmentData.shipment.shipment.lineItems!}
            />
          </Stack>
        </div>
        <Spacer height={32} />
        {!isBlank(
          props.shipmentData.shipment.quote.carrierPriceExplanationInternal
        ) && (
          <HorizontalStack verticalAlign="top">
            <div>
              <Title level={4}>Carrier Quote Explanation (Internal)</Title>
              <IsolatedHTML
                html={
                  props.shipmentData.shipment.quote
                    .carrierPriceExplanationInternal ?? ""
                }
              />
            </div>
            <div>
              <Title level={4}>Invoice Document</Title>
              <Pdf
                filename={filename}
                documentS3Path={props.carrierInvoice.s3Key!!}
                companyId={props.shipmentData.shipment.company.companyId}
                shipmentId={props.shipmentData.shipmentId}
                onRefresh={props.onRefresh}
                width={700}
              />
            </div>
          </HorizontalStack>
        )}
        {isBlank(
          props.shipmentData.shipment.quote.carrierPriceExplanationInternal
        ) && (
          <div>
            <Title level={4}>Invoice Document</Title>
            <Pdf
              filename={filename}
              documentS3Path={props.carrierInvoice.s3Key!!}
              companyId={props.shipmentData.shipment.company.companyId}
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
            />
          </div>
        )}
      </Modal>
      <Button onClick={showModal}>View</Button>
    </>
  );
}

interface LocationInfo {
  location: Location;
  context: string;
  differences: ObjectDifference[];
}

function LocationOverview(props: LocationInfo) {
  console.log(`hours`, { hours: props.location.hours });

  const { address } = props.location;
  const cityStatePostalCode = `${address?.city}, ${address?.stateOrProvinceCode}, ${address?.postalCode}`;

  const locationDiff = ["city", "stateOrProvinceCode", "postalCode"]
    .map((i) => props.differences.find((d) => d.key === i))
    .filter((i) => !!i);

  const accessorialsDiff = props.differences.find(
    (d) => d.key === "accessorials"
  );

  const locationTypeDiff = props.differences.find(
    (d) => d.key === "locationType"
  );

  try {
    return (
      <KeyValues
        data={{
          [`${props.context} Location`]: (
            <>
              <DifferenceWarning
                isDifferent={locationDiff.length > 0}
                quoteValue={locationDiff.map((d) => d?.left).join(", ")}
              >
                {cityStatePostalCode}
              </DifferenceWarning>
              <Spacer height={4} />
              <Button
                style={{ padding: 0 }}
                href={getMapUrlForAddress(address!)}
                type="link"
                target="_blank"
              >
                View Map
              </Button>
            </>
          ),
          [`${props.context} Accessorials`]: (
            <DifferenceWarning
              isDifferent={!!accessorialsDiff}
              quoteValue={describeAccessorials(accessorialsDiff?.left ?? [])}
            >
              {describeAccessorials(props.location.accessorials ?? [])}
            </DifferenceWarning>
          ),
          [`${props.context} Location Type`]: (
            <DifferenceWarning
              isDifferent={!!locationTypeDiff}
              quoteValue={locationTypeDiff?.left}
            >
              {props.location.locationType}
            </DifferenceWarning>
          ),
        }}
      />
    );
  } catch (e: any) {
    return <div>Oops. Somethign went wrong {e.toString()}</div>;
  }
}

function GeneralInfoOverview({
  shipment,
  differences,
}: {
  shipment: Shipment;
  differences: ObjectDifference[];
}) {
  const [
    exclusiveDiff,
    equipmentDiff,
    addInsuranceDiff,
    insuranceAmountDiff,
    insuranceCurrencyDiff,
  ] = [
    "exclusiveUseNeeded",
    "equipmentType",
    "addInsuranceToShipment",
    "insuranceAmount",
    "insuranceCurrency",
  ].map((i) => differences.find((d) => d.key === i));

  return (
    <KeyValues
      data={{
        ["Exclusive Use Needed"]: (
          <DifferenceWarning
            isDifferent={!!exclusiveDiff}
            quoteValue={exclusiveDiff?.left}
          >
            {shipment.exclusiveUseNeeded ? "Yes" : "No"}
          </DifferenceWarning>
        ),
        ["Equipment Type"]: (
          <DifferenceWarning
            isDifferent={!!equipmentDiff}
            quoteValue={describeEquipmentType(equipmentDiff?.left)}
          >
            {describeEquipmentType(shipment.equipmentType)}
          </DifferenceWarning>
        ),
        ["Add Insurance To Shipment"]: (
          <DifferenceWarning
            isDifferent={!!addInsuranceDiff}
            quoteValue={addInsuranceDiff?.left}
          >
            {shipment.addInsuranceToShipment ? "Yes" : "No"}
          </DifferenceWarning>
        ),
        ["Insurance Amount"]: (
          <DifferenceWarning
            isDifferent={!!insuranceAmountDiff}
            quoteValue={insuranceAmountDiff?.left}
          >
            {shipment.insuranceAmount}
          </DifferenceWarning>
        ),
        ["Insurance Currency"]: (
          <DifferenceWarning
            isDifferent={!!insuranceCurrencyDiff}
            quoteValue={insuranceCurrencyDiff?.left}
          >
            {shipment.insuranceCurrency}
          </DifferenceWarning>
        ),
      }}
    />
  );
}

function DifferenceWarning({
  isDifferent,
  quoteValue,
  children,
}: {
  isDifferent: boolean;
  quoteValue?: ReactNode;
  children: ReactNode;
}) {
  if (!isDifferent) {
    return <>{children}</>;
  }

  return (
    <HorizontalStack>
      {children}
      <Spacer width={16} />
      <Tooltip
        color={"white"}
        overlayInnerStyle={{ color: Colors.NormalText }}
        title={
          quoteValue !== undefined && (
            <div>
              <span
                style={{
                  color: Colors.LightText,
                  fontWeight: "400",
                  fontSize: "12px",
                  display: "block",
                }}
              >
                Quote
              </span>{" "}
              {quoteValue}
            </div>
          )
        }
        style={{ display: "flex" }}
      >
        <span>⚠️ </span>
        <span style={{ color: Colors.LightText, fontSize: "12px" }}>
          Differs from quote
        </span>
      </Tooltip>
    </HorizontalStack>
  );
}
