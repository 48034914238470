import { Tag } from "antd";
import moment from "moment";
import { useState } from "react";
import { useMapsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { useOnce } from "../../Hooks/useOnce";
import { ApolloMapCompany } from "../../generated-openapi-client";
import { ApolloMapQuery } from "../../generated-openapi-client/models/ApolloMapQuery";

interface MapScreenQueryBarProps {
  query: ApolloMapQuery;
}

export function MapScreenQueryBar(props: MapScreenQueryBarProps) {
  const { query } = props;
  const createMapsApi = useMapsApi();
  const [companies, setCompanies] = useState<ApolloMapCompany[] | undefined>();

  useOnce(async function () {
    const mapsApi = await createMapsApi();
    const response = await mapsApi.getCompanies();
    setCompanies(response);
  });

  function lookupCompany() {
    if (companies === undefined) {
      return "Loading";
    }
    const foundCompany = companies.find(function (c) {
      return c.companyId === query.companyId;
    });

    if (foundCompany === undefined) {
      return "Unknown";
    }

    return foundCompany?.companyName;
  }

  const queryCompanyName = query.companyId ? lookupCompany() : undefined;

  return (
    <ButtonRow>
      {query.pickupLocationFilterType && (
        <Tag color="blue">From: {query.pickupLocationFilterType}</Tag>
      )}
      {query.pickupCity && (
        <Tag color="blue">From City: {query.pickupCity}</Tag>
      )}
      {query.deliveryLocationFilterType && (
        <Tag color="blue">To: {query.deliveryLocationFilterType}</Tag>
      )}
      {query.deliveryCity && (
        <Tag color="blue">To City: {query.deliveryCity}</Tag>
      )}
      {query.carrierIdentifier && (
        <Tag color="purple">Carrier: {query.carrierIdentifier}</Tag>
      )}
      {query.companyId && (
        <Tag color="geekblue">Company: {queryCompanyName}</Tag>
      )}
      {query.compareWithCarrier && (
        <Tag color="geekblue">Compare: {query.compareWithCarrier}</Tag>
      )}

      {query.bookingStatus && (
        <Tag color="geekblue">Booking Status: {query.bookingStatus}</Tag>
      )}

      {query.startDate && (
        <Tag color="green">
          Start Date: {moment(query.startDate).format("dddd, MMMM Do YYYY")}
        </Tag>
      )}
      {query.endDate && (
        <Tag color="green">
          End Date: {moment(query.endDate).format("dddd, MMMM Do YYYY")}
        </Tag>
      )}

      {query.minimumShipmentWeight && (
        <Tag color="green">Min Weight: {query.minimumShipmentWeight}lb</Tag>
      )}
      {query.maximumShipmentWeight && (
        <Tag color="green">Max Weight: {query.maximumShipmentWeight}lb</Tag>
      )}

      {query.minimumNumberHandlingUnits && (
        <Tag color="green">Min HU: {query.minimumNumberHandlingUnits}lb</Tag>
      )}
      {query.maximumNumberHandlingUnits && (
        <Tag color="green">Min HU: {query.maximumNumberHandlingUnits}lb</Tag>
      )}

      {query.minimumVolume && (
        <Tag color="green">Min HU: {query.minimumVolume}cuft</Tag>
      )}
      {query.maximumVolume && (
        <Tag color="green">Min HU: {query.maximumVolume}cuft</Tag>
      )}

      {query.inCarrierCoverageArea && (
        <Tag color="green">In Carrier Coverage Area</Tag>
      )}

      {query.compareWithCarrier && (
        <Tag color="green">Comparing: {query.compareWithCarrier}</Tag>
      )}
    </ButtonRow>
  );
}
