import moment from "moment";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { Quote, Shipment } from "../../generated-openapi-client";
import { ShipmentState } from "../../generated-openapi-client/models/ShipmentState";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client/models/ViewShipmentDataForApollo";

interface ExpectedDeliveryDateOverlayProps {
  data: ViewShipmentDataForApollo;
}

export function isInDeliveryWindow(shipment: Shipment): boolean {
  const latest = moment(shipment.latestPredictedDeliveryDateAtPickup).startOf(
    "day"
  );

  if (shipment.state === ShipmentState.Delivered) {
    const actualDeliveryDate = moment(shipment.actualDeliveryDate).startOf(
      "day"
    );
    return actualDeliveryDate.isSameOrBefore(latest);
  } else {
    const estimatedDeliveryDate = moment(shipment.expectedDeliveryDate).startOf(
      "day"
    );
    const today = moment().startOf("day");

    if (estimatedDeliveryDate.isBefore(today)) {
      return today.isSameOrBefore(latest);
    } else {
      return estimatedDeliveryDate.isSameOrBefore(latest);
    }
  }
}

export function ExpectedDeliveryDateOverlay(
  props: ExpectedDeliveryDateOverlayProps
) {
  const shipment: Shipment = props.data.shipment.shipment!!;
  const quote: Quote = props.data.shipment.quote!!;
  const estimatedDeliveryDate = shipment.expectedDeliveryDate;
  const actualDeliveryDate = shipment.actualDeliveryDate;
  const actualDeliveryTime = shipment.actualDeliveryTime;
  const latest = shipment.latestPredictedDeliveryDateAtPickup;
  const earliest = shipment.expectedDeliveryDateAtPickup;
  const leastTransitDays = quote.transitBusinessDays;
  const mostTransitDays = quote.latestTransitBusinessDays;
  const originalPickupDate = shipment.originalPickupDate;
  const pickupDate = shipment.pickupDate;

  const isDelivered = shipment.state === ShipmentState.Delivered;

  function describeTransitDays() {
    if (leastTransitDays === undefined) {
      return "Unknown";
    }

    return `${leastTransitDays}-${mostTransitDays}`;
  }

  return (
    <Stack align="left" style={{ padding: "16px", width: "400px" }}>
      {!isInDeliveryWindow(shipment) && (
        <>
          <strong>❌ Outside of predicted delivery window</strong>
          <Spacer height={16} />
        </>
      )}

      {originalPickupDate !== pickupDate && (
        <>
          <div>
            <strong>Original Pickup:</strong> {originalPickupDate}
          </div>
          <Spacer height={8} />
        </>
      )}

      <div>
        <strong>Pickup:</strong> {pickupDate}
      </div>
      <Spacer height={8} />
      {!isDelivered && (
        <div>
          <strong>Current Earliest:</strong> {estimatedDeliveryDate}
        </div>
      )}
      {isDelivered && (
        <div>
          <strong>🟢 Actual Delivery Date:</strong> {actualDeliveryDate}{" "}
          {actualDeliveryTime}
        </div>
      )}
      <Spacer height={8} />
      <div>
        <strong>Earliest at Pickup:</strong> {earliest || "Unknown"}
      </div>
      <Spacer height={8} />
      <div>
        <strong>Latest at Pickup:</strong> {latest || "Unknown"}
      </div>
      <Spacer height={8} />
      <div>
        <strong>Transit days:</strong> {describeTransitDays()}
      </div>
    </Stack>
  );
}
