/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApolloMapLocationFilterType } from './ApolloMapLocationFilterType';
import {
    ApolloMapLocationFilterTypeFromJSON,
    ApolloMapLocationFilterTypeFromJSONTyped,
    ApolloMapLocationFilterTypeToJSON,
} from './ApolloMapLocationFilterType';

/**
 * 
 * @export
 * @interface ListCompaniesInput
 */
export interface ListCompaniesInput {
    /**
     * 
     * @type {ApolloMapLocationFilterType}
     * @memberof ListCompaniesInput
     */
    locationFilterType?: ApolloMapLocationFilterType;
}

/**
 * Check if a given object implements the ListCompaniesInput interface.
 */
export function instanceOfListCompaniesInput(value: object): boolean {
    return true;
}

export function ListCompaniesInputFromJSON(json: any): ListCompaniesInput {
    return ListCompaniesInputFromJSONTyped(json, false);
}

export function ListCompaniesInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCompaniesInput {
    if (json == null) {
        return json;
    }
    return {
        
        'locationFilterType': json['locationFilterType'] == null ? undefined : ApolloMapLocationFilterTypeFromJSON(json['locationFilterType']),
    };
}

export function ListCompaniesInputToJSON(value?: ListCompaniesInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationFilterType': ApolloMapLocationFilterTypeToJSON(value['locationFilterType']),
    };
}

