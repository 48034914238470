import { CSVLink } from "react-csv";
import {
  CountryCode,
  ShipmentReport,
  ShipmentReportQuoteSummary,
} from "../generated-openapi-client";

interface DownloadCarrierComparisonLinkProps {
  quotes: ShipmentReport[];
  carrierIdentifier: string | undefined;
}

export function DownloadCarrierComparisonLink(
  props: DownloadCarrierComparisonLinkProps
) {
  if (props.carrierIdentifier === undefined) {
    return <></>;
  }

  const data = props.quotes.map(function (sr) {
    function carrierPriceFromQuote(srqs: ShipmentReportQuoteSummary): number {
      return srqs.priceCad!! / 1.2;
    }

    // We are for now going to just remove 20% to remove our margin so we have a rate to show carriers
    // Doesn't need to be perfectly precise to be effective
    function rateFromQuote(
      srqs: ShipmentReportQuoteSummary | undefined
    ): string {
      if (srqs === undefined) {
        return "-";
      } else {
        return carrierPriceFromQuote(srqs).toFixed(2);
      }
    }

    function rank(): string {
      const index = sr.quotes!!.findIndex(
        (srqs) => srqs.carrierIdentifier === props.carrierIdentifier
      );
      if (index === undefined) {
        return "-";
      }

      return (index + 1).toString();
    }

    function delta(
      lowest: ShipmentReportQuoteSummary | undefined,
      carrierQuote: ShipmentReportQuoteSummary | undefined
    ) {
      if (lowest === undefined || carrierQuote === undefined) {
        return "-";
      }

      return (
        "$" +
        (
          carrierPriceFromQuote(carrierQuote) - carrierPriceFromQuote(lowest)
        ).toFixed(2)
      );
    }

    function percentage(
      lowest: ShipmentReportQuoteSummary | undefined,
      carrierQuote: ShipmentReportQuoteSummary | undefined
    ) {
      if (lowest === undefined || carrierQuote === undefined) {
        return "-";
      }

      return (
        (
          100 *
            (carrierPriceFromQuote(carrierQuote) /
              carrierPriceFromQuote(lowest)) -
          100
        ).toFixed(0) + "%"
      );
    }

    const o = {} as {
      [key: string]: string | undefined | CountryCode | number;
    };

    const carrierQuote = sr.quotes!!.find(
      (srqs) => srqs.carrierIdentifier === props.carrierIdentifier
    );
    o["Pickup City"] = sr.pickupCity;
    o["Pickup State"] = sr.pickupState;
    o["Pickup Country"] = sr.pickupCountry;
    o["Delivery City"] = sr.deliveryCity;
    o["Delivery State"] = sr.deliveryState;
    o["Delivery Country"] = sr.deliveryCountry;
    o["⭐ Lowest Rate"] = "$" + rateFromQuote(sr.lowestQuote);
    o[`🚚 ${props.carrierIdentifier} Rate`] = "$" + rateFromQuote(carrierQuote);
    o[`🚚 ${props.carrierIdentifier} Rank`] = rank();
    o[`🚚 ${props.carrierIdentifier} Delta`] = delta(
      sr.lowestQuote,
      carrierQuote
    );
    o[`🚚 ${props.carrierIdentifier} Percentage Off`] = percentage(
      sr.lowestQuote,
      carrierQuote
    );

    o["Number Skids"] = sr.totalHandlingUnits;
    o["Total Weight (lb)"] = sr.totalWeight;
    o["Total Volume (cuft)"] = sr.totalVolume;
    o["Density"] = sr.density?.toFixed(1);
    o["Residential Pickup"] = sr.needsResidentialPickup ? "Yes" : "-";
    o["Residential Delivery"] = sr.needsResidentialDelivery ? "Yes" : "-";
    o["Liftgate Pickup"] = sr.needsLiftgatePickup ? "Yes" : "-";
    o["Liftgate Delivery"] = sr.needsLiftgateDelivery ? "Yes" : "-";
    o["Inside Pickup"] = sr.needsInsidePickup ? "Yes" : "-";
    o["Inside Delivery"] = sr.needsInsideDelivery ? "Yes" : "-";

    return o;
  });

  if (data.length === 0) {
    // No data so no comparison possible
    return <></>;
  }

  const columns = Object.keys(data[0]);

  const filename = `${props.carrierIdentifier} Rates Comparison.csv`;
  console.log(`csv comparison filename = ${filename}`);

  return (
    <CSVLink data={data} filename={filename} headers={columns}>
      Download Carrier Comparison CSV
    </CSVLink>
  );
}
