import { Address, Shipment, ShipmentReport } from "../generated-openapi-client";

export function getMapUrlForAddress(address: Address) {
  const encoded = encodeURIComponent(
    `${address.addressLine},${address.city},${address.stateOrProvinceCode},${address.postalCode}`
  );
  return `https://www.google.com/maps/search/?api=1&map_action=pano&query=${encoded}`;
}

export function getMapUrlForLatitudeLongitude(lat: number, lng: number) {
  const encoded = encodeURIComponent(`${lat},${lng}`);
  return `https://www.google.com/maps/search/?api=1&map_action=pano&query=${encoded}`;
}

export function getMapUrlForViewQuoteScreenDataRow(vqsdr: ShipmentReport) {
  const pickupEncoded = encodeURIComponent(vqsdr.pickupCityState!!);
  const deliveryEncoded = encodeURIComponent(vqsdr.deliveryCityState!!);
  return `https://www.google.com/maps/dir/?api=1&origin=${pickupEncoded}&destination=${deliveryEncoded}`;
}

export function getMapUrlForShipment(shipment: Shipment) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const pickupAddress = shipment.pickupLocation?.address!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const deliveryAddress = shipment.deliveryLocation?.address!!;

  if (shipment.bookedAt === undefined) {
    const pickupEncoded = encodeURIComponent(
      `${pickupAddress.city},${pickupAddress.stateOrProvinceCode},${pickupAddress.postalCode}`
    );
    const deliveryEncoded = encodeURIComponent(
      `${deliveryAddress.city},${deliveryAddress.stateOrProvinceCode},${deliveryAddress.postalCode}`
    );

    return `https://www.google.com/maps/dir/?api=1&origin=${pickupEncoded}&destination=${deliveryEncoded}`;
  } else {
    const pickupEncoded = encodeURIComponent(
      `${pickupAddress.addressLine},${pickupAddress.city},${pickupAddress.stateOrProvinceCode},${pickupAddress.postalCode}`
    );
    const deliveryEncoded = encodeURIComponent(
      `${deliveryAddress.addressLine},${deliveryAddress.city},${deliveryAddress.stateOrProvinceCode},${deliveryAddress.postalCode}`
    );

    return `https://www.google.com/maps/dir/?api=1&origin=${pickupEncoded}&destination=${deliveryEncoded}`;
  }
}
