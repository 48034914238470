import { CountryCode } from "../generated-openapi-client";
import {
  canadianProvinceCodeMap,
  usStateCodeMap,
} from "freightsimple-utilities";
import { Select, SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { isEmpty } from "../Helpers/isEmpty";

interface StateOrProvinceDropdownProps extends SelectProps {
  countries: (CountryCode | undefined)[];
  value: string | undefined;
  onChange: (stateOrProvinceCode: string | undefined) => void;
}

interface Option {
  label: string;
  options: { label: string; value: string }[];
}

export function StateOrProvinceDropdown({
  value,
  onChange,
  countries,
  ...rest
}: StateOrProvinceDropdownProps) {
  const options: Option[] = [];

  const validCountries = countries.filter((c) => c !== undefined);

  const isValidCountriesEmpty = isEmpty(validCountries);

  function buildLabel(abbrv: string, name: string): string {
    return `${name}, ${abbrv}`;
  }

  if (validCountries.includes(CountryCode.Ca)) {
    options.push({
      label: "Canada",
      options: Object.entries(canadianProvinceCodeMap).map(
        ([abbreviation, provinceName]) => ({
          label: buildLabel(abbreviation, provinceName),
          value: abbreviation,
        })
      ),
    });
  }

  if (validCountries.includes(CountryCode.Us)) {
    options.push({
      label: "United States",
      options: Object.entries(usStateCodeMap).map(
        ([abbreviation, stateName]) => ({
          label: buildLabel(abbreviation, stateName),
          value: abbreviation,
        })
      ),
    });
  }

  function filterFunction(
    input: string,
    option: DefaultOptionType | undefined
  ): boolean {
    if (option?.options) {
      return false;
    }
    return (
      option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ??
      false
    );
  }

  return (
    <Select
      disabled={isValidCountriesEmpty}
      value={isValidCountriesEmpty ? "No valid countries" : value}
      style={{ width: 200 }}
      onChange={(e) => {
        onChange(e);
      }}
      options={options}
      showSearch
      filterOption={filterFunction}
      {...rest}
    />
  );
}
