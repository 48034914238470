/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierBasicProperties } from './CarrierBasicProperties';
import {
    CarrierBasicPropertiesFromJSON,
    CarrierBasicPropertiesFromJSONTyped,
    CarrierBasicPropertiesToJSON,
} from './CarrierBasicProperties';
import type { CarrierInfoResponseService } from './CarrierInfoResponseService';
import {
    CarrierInfoResponseServiceFromJSON,
    CarrierInfoResponseServiceFromJSONTyped,
    CarrierInfoResponseServiceToJSON,
} from './CarrierInfoResponseService';
import type { CarrierPaymentProperties } from './CarrierPaymentProperties';
import {
    CarrierPaymentPropertiesFromJSON,
    CarrierPaymentPropertiesFromJSONTyped,
    CarrierPaymentPropertiesToJSON,
} from './CarrierPaymentProperties';

/**
 * 
 * @export
 * @interface CarrierInfoResponse
 */
export interface CarrierInfoResponse {
    /**
     * 
     * @type {CarrierBasicProperties}
     * @memberof CarrierInfoResponse
     */
    carrierBasicProperties: CarrierBasicProperties;
    /**
     * 
     * @type {CarrierPaymentProperties}
     * @memberof CarrierInfoResponse
     */
    carrierPaymentStrategy: CarrierPaymentProperties;
    /**
     * 
     * @type {Array<CarrierInfoResponseService>}
     * @memberof CarrierInfoResponse
     */
    services: Array<CarrierInfoResponseService>;
}

/**
 * Check if a given object implements the CarrierInfoResponse interface.
 */
export function instanceOfCarrierInfoResponse(value: object): boolean {
    if (!('carrierBasicProperties' in value)) return false;
    if (!('carrierPaymentStrategy' in value)) return false;
    if (!('services' in value)) return false;
    return true;
}

export function CarrierInfoResponseFromJSON(json: any): CarrierInfoResponse {
    return CarrierInfoResponseFromJSONTyped(json, false);
}

export function CarrierInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierBasicProperties': CarrierBasicPropertiesFromJSON(json['carrierBasicProperties']),
        'carrierPaymentStrategy': CarrierPaymentPropertiesFromJSON(json['carrierPaymentStrategy']),
        'services': ((json['services'] as Array<any>).map(CarrierInfoResponseServiceFromJSON)),
    };
}

export function CarrierInfoResponseToJSON(value?: CarrierInfoResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierBasicProperties': CarrierBasicPropertiesToJSON(value['carrierBasicProperties']),
        'carrierPaymentStrategy': CarrierPaymentPropertiesToJSON(value['carrierPaymentStrategy']),
        'services': ((value['services'] as Array<any>).map(CarrierInfoResponseServiceToJSON)),
    };
}

