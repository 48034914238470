import { Statistic } from "antd";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import Stack from "../../Components/Stack";
import { StatsRow } from "../../Components/StatsRow";
import { CompanyPaymentMethodItem } from "../../generated-openapi-client";
import { CompanyData } from "../../generated-openapi-client/models/CompanyData";
import Spacer from "../../Spacer";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { ModifyPermittedPaymentOptionsButton } from "./ModifyPermittedPaymentOptionsButton";

interface PaymentMethodsTableProps {
  data: CompanyData;
}

function PaymentMethodsTable(props: PaymentMethodsTableProps) {
  const columns: DataTableColumn<CompanyPaymentMethodItem>[] = [
    {
      title: "Brand",
      render: (o) => <div>{o.brand}</div>,
    },
    {
      title: "Cardholder Name",
      render: (o) => <div>{o.cardholderName}</div>,
    },
    {
      title: "Is Company Default?",
      render: (o) => <div>{o.companyDefault ? "✅" : "❌"}</div>,
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.data.paymentMethodInfo.paymentMethods}
    />
  );
}

export function CompanyPaymentMethodsTab(props: CompanyTabProps) {
  const companyData = props.data;
  return (
    <Stack align="left">
      <StatsRow>
        <Statistic
          title="Cheques Permitted?"
          value={
            companyData.company.chequePaymentMethodPermitted ? "Yes" : "No"
          }
          style={{ marginLeft: "24px" }}
        />
        <Statistic title="eTransfer Permitted?" value="Yes" />
        <Statistic title="EFT Permitted?" value="Yes" />
        <Statistic
          title="Wire Permitted?"
          value={companyData.company.wirePaymentMethodPermitted ? "Yes" : "No"}
        />
        <Statistic
          title="Will Auto-Charge Overdue?"
          value={
            companyData.company.doNotAutochargeOverdueInvoices ? "No ‼️" : "Yes"
          }
        />
        <Spacer width={16} />
        <ModifyPermittedPaymentOptionsButton {...props} />
      </StatsRow>
      <Spacer height={48} />
      <PaymentMethodsTable data={companyData} />
    </Stack>
  );
}
