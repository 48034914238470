import { Button, message } from "antd";
import { useTechnicalApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { Page } from "../Components/Page";

function TestAmyEmailButton() {
  const createTechnicalApi = useTechnicalApi();

  async function onClick() {
    const technicalApi = await createTechnicalApi();
    await technicalApi.sendAmyEmailTest();
    message.success("Sent!");
  }

  return <Button onClick={onClick}>Test Amy Email</Button>;
}

function TestAmySupportButton() {
  const createTechnicalApi = useTechnicalApi();

  async function onClick() {
    const technicalApi = await createTechnicalApi();
    await technicalApi.sendSupportEmailTest();
    message.success("Sent!");
  }

  return <Button onClick={onClick}>Test Support Email</Button>;
}

export function TestingScreen() {
  return (
    <Page title={`Testing`} tags={[]} extra={[]}>
      <ButtonRow>
        <TestAmyEmailButton />
        <TestAmySupportButton />
      </ButtonRow>
    </Page>
  );
}
