import { CSSProperties } from "react";
import NumberFormat from "react-number-format";
import Colors from "./Colors";
interface PercentageProps {
  children: number;
  style?: CSSProperties;
  colored?: boolean;
  invert?: boolean;
  simple?: boolean;
}

export function Percentage(props: PercentageProps) {
  const style = props.style || {};

  if (props.simple) {
    if (props.colored) {
      if (props.invert) {
        if (props.children < -20) {
          style.color = Colors.DarkGreen;
        } else if (props.children < 0) {
          style.color = Colors.Orange;
        } else if (props.children > 0) {
          style.color = Colors.Red;
        }
      } else {
        if (props.children >= 19.9) {
          style.color = Colors.DarkGreen;
        } else if (props.children > 0) {
          style.color = Colors.Orange;
        } else if (props.children < 0) {
          style.color = Colors.Red;
        }
      }
    }
  } else {
    if (props.colored) {
      if (props.invert) {
        if (props.children < -20) {
          style.color = Colors.DarkGreen;
        } else if (props.children < 0) {
          style.color = Colors.Orange;
        } else if (props.children > 0) {
          style.color = Colors.Red;
        }
      } else {
        if (props.children >= 19.9) {
          style.color = Colors.DarkGreen;
        } else if (props.children > 0) {
          style.color = Colors.Orange;
        } else if (props.children < 0) {
          style.color = Colors.Red;
        }
      }
    }
  }

  console.log(`!!!! Percentage`, { props });

  if (isNaN(props.children) || !isFinite(props.children)) {
    return <>-</>;
  }

  return (
    <NumberFormat
      value={props.children}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={1}
      fixedDecimalScale={true}
      suffix={"%"}
      style={style}
    />
  );
}
