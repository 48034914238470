/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethodItem
 */
export interface PaymentMethodItem {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodItem
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodItem
     */
    cardholderName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodItem
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodItem
     */
    lastFourDigits: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodItem
     */
    companyDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodItem
     */
    currentlySelectedForShipment: boolean;
}

/**
 * Check if a given object implements the PaymentMethodItem interface.
 */
export function instanceOfPaymentMethodItem(value: object): boolean {
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('cardholderName' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('lastFourDigits' in value)) return false;
    if (!('companyDefault' in value)) return false;
    if (!('currentlySelectedForShipment' in value)) return false;
    return true;
}

export function PaymentMethodItemFromJSON(json: any): PaymentMethodItem {
    return PaymentMethodItemFromJSONTyped(json, false);
}

export function PaymentMethodItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodItem {
    if (json == null) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'cardholderName': json['cardholderName'],
        'brand': json['brand'],
        'lastFourDigits': json['lastFourDigits'],
        'companyDefault': json['companyDefault'],
        'currentlySelectedForShipment': json['currentlySelectedForShipment'],
    };
}

export function PaymentMethodItemToJSON(value?: PaymentMethodItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'cardholderName': value['cardholderName'],
        'brand': value['brand'],
        'lastFourDigits': value['lastFourDigits'],
        'companyDefault': value['companyDefault'],
        'currentlySelectedForShipment': value['currentlySelectedForShipment'],
    };
}

