/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShipmentReportSettlementStatus = {
    Projected: 'projected',
    Open: 'open',
    Approved: 'approved',
    Settled: 'settled'
} as const;
export type ShipmentReportSettlementStatus = typeof ShipmentReportSettlementStatus[keyof typeof ShipmentReportSettlementStatus];


export function instanceOfShipmentReportSettlementStatus(value: any): boolean {
    return Object.values(ShipmentReportSettlementStatus).includes(value);
}

export function ShipmentReportSettlementStatusFromJSON(json: any): ShipmentReportSettlementStatus {
    return ShipmentReportSettlementStatusFromJSONTyped(json, false);
}

export function ShipmentReportSettlementStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReportSettlementStatus {
    return json as ShipmentReportSettlementStatus;
}

export function ShipmentReportSettlementStatusToJSON(value?: ShipmentReportSettlementStatus | null): any {
    return value as any;
}

