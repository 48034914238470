import { EquipmentType } from "../generated-openapi-client/models/EquipmentType";
import { OptionalEnumDropdown } from "../Screens/ViewShipmentScreenComponents/EnumDropdown";

export const EquipmentTypeDropdown = OptionalEnumDropdown<
  EquipmentType,
  typeof EquipmentType
>(EquipmentType, "Any Equipment Type", function (et) {
  switch (et) {
    case EquipmentType.DryVan:
      return "Dry Van";
    case EquipmentType.Flatbed:
      return "Flatbed";
    case EquipmentType.Reefer:
      return "Reefer";
  }
});
