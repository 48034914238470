import { Select } from "antd";
import { GenerateProfitLossReportFilterOptionsSettledStatus } from "../generated-openapi-client/models/GenerateProfitLossReportFilterOptionsSettledStatus";

interface SettledStatusSelectProps {
  settledStatus: GenerateProfitLossReportFilterOptionsSettledStatus;
  setSettledStatus: (
    _: GenerateProfitLossReportFilterOptionsSettledStatus
  ) => void;
  disabled?: boolean;
}

export function SettledStatusSelect(props: SettledStatusSelectProps) {
  const { settledStatus, setSettledStatus } = props;

  return (
    <Select
      disabled={props.disabled}
      allowClear
      value={settledStatus}
      style={{ width: 220 }}
      onChange={function (e) {
        setSettledStatus(e);
      }}
    >
      <Select.Option
        value={GenerateProfitLossReportFilterOptionsSettledStatus.Everything}
      >
        All Shipments
      </Select.Option>
      ;
      <Select.Option
        value={
          GenerateProfitLossReportFilterOptionsSettledStatus.OnlyIncludeNonSettled
        }
      >
        Only non-settled
      </Select.Option>
      ;
      <Select.Option
        value={
          GenerateProfitLossReportFilterOptionsSettledStatus.OnlyIncludeSettled
        }
      >
        Only settled
      </Select.Option>
      ;
    </Select>
  );
}
