import { Button, DatePicker, Form, Input, PageHeader } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCompaniesApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { Loading } from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import { Panel } from "../Components/Panel";
import Stack from "../Components/Stack";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { CompanyNote } from "../generated-openapi-client";
import { CompanyNoteAuthorDropdown } from "./ViewCompanyScreenComponents/CompanyNoteAuthorDropdown";
import { CompanyNotesTimeline } from "./ViewCompanyScreenComponents/CompanyNotes/CompanyNotesTimeline";
import { CompanyNotesSignificantFilter } from "./ViewCompanyScreenComponents/CompanyNotesSignificantDropdown";
import { useCallRefreshContext } from "../Contexts/CallContext";

export function ListSalesCallsScreen() {
  const urlQuery = useQuery();
  const navigate = useNavigate();
  const createCompaniesApi = useCompaniesApi();
  const [collapse, setCollapse] = useState(false);
  const [salesCalls, setSalesCalls] = useState<CompanyNote[]>();
  const [noteAuthorFilter, setNoteAuthorFilter] = useState<string | undefined>(
    (urlQuery.noteAuthorFilter as string) ?? undefined
  );
  const [significantFilter, setSignificantFilter] = useState<boolean>();
  const [date, setDate] = useState(
    (urlQuery.date as string) ?? moment().format("YYYY-MM-DD")
  );
  const [freeFilter, setFreeFilter] = useState<string>(
    (urlQuery.freeFilter as string) ?? undefined
  );
  const { setRefresh } = useCallRefreshContext();

  async function refresh() {
    const companiesApi = await createCompaniesApi();

    const response = await companiesApi.listSalesCalls({ date });
    setSalesCalls(response);
  }

  useEffect(
    function () {
      refresh();
      setRefresh(refresh);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  useEffect(
    function () {
      let url = `/list-sales-calls?`;
      if (noteAuthorFilter) {
        url += `&noteAuthorFilter=${noteAuthorFilter}`;
      }

      if (date !== moment().format("YYYY-MM-DD")) {
        url += `&date=${date}`;
      }

      if (freeFilter) {
        url += `&freeFilter=${freeFilter}`;
      }

      navigate(url, {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noteAuthorFilter, date, freeFilter]
  );

  if (salesCalls === undefined) {
    return <Loading />;
  }

  return (
    <>
      {/* @ts-ignore */}
      <PageHeader title={`Sales Calls (${salesCalls.length})`}>
        <PageTitle>Sales Calls</PageTitle>
        <Panel>
          <Stack align="left">
            <ButtonRow>
              <Form.Item>
                <Button
                  onClick={() => {
                    setCollapse((prev) => !prev);
                  }}
                >
                  Toggle Collapse
                </Button>
              </Form.Item>
              <Form.Item label="Date">
                <DatePicker
                  style={{ width: "200px" }}
                  // This is tricky because we need it to represent the date in PST
                  // but if you give moment a raw date it assumes that it is GMT
                  value={moment(date)}
                  onChange={async function (
                    newMomentDate: moment.Moment | null
                  ) {
                    console.log(`onChange ${newMomentDate}`);
                    if (newMomentDate !== null) {
                      const newDate = newMomentDate.format("YYYY-MM-DD");
                      setDate(newDate);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Filter">
                <Input
                  value={freeFilter}
                  onChange={function (e) {
                    setFreeFilter(e.currentTarget.value);
                  }}
                ></Input>
              </Form.Item>

              <Form.Item label="Filter by significant notes">
                <CompanyNotesSignificantFilter
                  value={significantFilter}
                  setValue={setSignificantFilter}
                />
              </Form.Item>

              <Form.Item label="Filter by author">
                <CompanyNoteAuthorDropdown
                  value={noteAuthorFilter}
                  setValue={setNoteAuthorFilter}
                  notes={salesCalls}
                />
              </Form.Item>
            </ButtonRow>
            <Spacer height={16} />
          </Stack>

          <Spacer height={16} />
          <CompanyNotesTimeline
            origin="sales-call-list"
            collapse={collapse}
            onRefresh={refresh}
            notes={salesCalls}
            noteTypeFilter={undefined}
            noteAuthorFilter={noteAuthorFilter}
            significantFilter={significantFilter}
            freeFilter={freeFilter}
            includeViewCompanyLinks
          />
        </Panel>
      </PageHeader>
    </>
  );
}
