import { OptionalEnumDropdown } from "../Screens/ViewShipmentScreenComponents/EnumDropdown";
import { GeoFilter } from "../generated-openapi-client";

export const GeoFilterDropdown = OptionalEnumDropdown<
  GeoFilter,
  typeof GeoFilter
>(GeoFilter, "Do not Filter", function (et) {
  switch (et) {
    case GeoFilter.DomesticCanada:
      return "Domestic Canada";
    case GeoFilter.DomesticUsa:
      return "Domestic USA";
    case GeoFilter.CrossBorder:
      return "Cross-Border";
    case GeoFilter.CrossBorderSouth:
      return "Cross-Border (South)";
    case GeoFilter.CrossBorderNorth:
      return "Cross-Border (North)";
  }
});
