/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompaniesBookedVsQuotedReportLine
 */
export interface CompaniesBookedVsQuotedReportLine {
    /**
     * 
     * @type {string}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    companyCreated: string;
    /**
     * 
     * @type {number}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    acceptanceRate: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    totalRevenue: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    totalUniqueQuotes: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    totalQuotes: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    totalShipmentsBooked: number;
    /**
     * 
     * @type {string}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    leadSource: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    shipmentFrequency: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesBookedVsQuotedReportLine
     */
    temperature: string;
}

/**
 * Check if a given object implements the CompaniesBookedVsQuotedReportLine interface.
 */
export function instanceOfCompaniesBookedVsQuotedReportLine(value: object): boolean {
    if (!('companyName' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('companyCreated' in value)) return false;
    if (!('acceptanceRate' in value)) return false;
    if (!('totalRevenue' in value)) return false;
    if (!('totalUniqueQuotes' in value)) return false;
    if (!('totalQuotes' in value)) return false;
    if (!('totalShipmentsBooked' in value)) return false;
    if (!('leadSource' in value)) return false;
    if (!('shipmentFrequency' in value)) return false;
    if (!('temperature' in value)) return false;
    return true;
}

export function CompaniesBookedVsQuotedReportLineFromJSON(json: any): CompaniesBookedVsQuotedReportLine {
    return CompaniesBookedVsQuotedReportLineFromJSONTyped(json, false);
}

export function CompaniesBookedVsQuotedReportLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompaniesBookedVsQuotedReportLine {
    if (json == null) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyId': json['companyId'],
        'companyCreated': json['companyCreated'],
        'acceptanceRate': json['acceptanceRate'],
        'totalRevenue': json['totalRevenue'],
        'totalUniqueQuotes': json['totalUniqueQuotes'],
        'totalQuotes': json['totalQuotes'],
        'totalShipmentsBooked': json['totalShipmentsBooked'],
        'leadSource': json['leadSource'],
        'shipmentFrequency': json['shipmentFrequency'],
        'temperature': json['temperature'],
    };
}

export function CompaniesBookedVsQuotedReportLineToJSON(value?: CompaniesBookedVsQuotedReportLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyName': value['companyName'],
        'companyId': value['companyId'],
        'companyCreated': value['companyCreated'],
        'acceptanceRate': value['acceptanceRate'],
        'totalRevenue': value['totalRevenue'],
        'totalUniqueQuotes': value['totalUniqueQuotes'],
        'totalQuotes': value['totalQuotes'],
        'totalShipmentsBooked': value['totalShipmentsBooked'],
        'leadSource': value['leadSource'],
        'shipmentFrequency': value['shipmentFrequency'],
        'temperature': value['temperature'],
    };
}

