/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayInvoiceResponse
 */
export interface PayInvoiceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PayInvoiceResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayInvoiceResponse
     */
    message?: string;
}

/**
 * Check if a given object implements the PayInvoiceResponse interface.
 */
export function instanceOfPayInvoiceResponse(value: object): boolean {
    if (!('success' in value)) return false;
    return true;
}

export function PayInvoiceResponseFromJSON(json: any): PayInvoiceResponse {
    return PayInvoiceResponseFromJSONTyped(json, false);
}

export function PayInvoiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayInvoiceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function PayInvoiceResponseToJSON(value?: PayInvoiceResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'message': value['message'],
    };
}

