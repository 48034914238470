/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApolloMapLocationFilter } from './ApolloMapLocationFilter';
import {
    ApolloMapLocationFilterFromJSON,
    ApolloMapLocationFilterFromJSONTyped,
    ApolloMapLocationFilterToJSON,
} from './ApolloMapLocationFilter';
import type { ApolloMapLocationFilterGroups } from './ApolloMapLocationFilterGroups';
import {
    ApolloMapLocationFilterGroupsFromJSON,
    ApolloMapLocationFilterGroupsFromJSONTyped,
    ApolloMapLocationFilterGroupsToJSON,
} from './ApolloMapLocationFilterGroups';

/**
 * 
 * @export
 * @interface ApolloMapLocationFilterGroup
 */
export interface ApolloMapLocationFilterGroup {
    /**
     * 
     * @type {ApolloMapLocationFilterGroups}
     * @memberof ApolloMapLocationFilterGroup
     */
    type: ApolloMapLocationFilterGroups;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapLocationFilterGroup
     */
    description: string;
    /**
     * 
     * @type {Array<ApolloMapLocationFilter>}
     * @memberof ApolloMapLocationFilterGroup
     */
    filters: Array<ApolloMapLocationFilter>;
}

/**
 * Check if a given object implements the ApolloMapLocationFilterGroup interface.
 */
export function instanceOfApolloMapLocationFilterGroup(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('description' in value)) return false;
    if (!('filters' in value)) return false;
    return true;
}

export function ApolloMapLocationFilterGroupFromJSON(json: any): ApolloMapLocationFilterGroup {
    return ApolloMapLocationFilterGroupFromJSONTyped(json, false);
}

export function ApolloMapLocationFilterGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapLocationFilterGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ApolloMapLocationFilterGroupsFromJSON(json['type']),
        'description': json['description'],
        'filters': ((json['filters'] as Array<any>).map(ApolloMapLocationFilterFromJSON)),
    };
}

export function ApolloMapLocationFilterGroupToJSON(value?: ApolloMapLocationFilterGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ApolloMapLocationFilterGroupsToJSON(value['type']),
        'description': value['description'],
        'filters': ((value['filters'] as Array<any>).map(ApolloMapLocationFilterToJSON)),
    };
}

