/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface CompanyBookedShipmentRow
 */
export interface CompanyBookedShipmentRow {
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {ShipmentState}
     * @memberof CompanyBookedShipmentRow
     */
    shipmentState: ShipmentState;
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    proNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyBookedShipmentRow
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    pickupLocation: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBookedShipmentRow
     */
    deliveryLocation: string;
}

/**
 * Check if a given object implements the CompanyBookedShipmentRow interface.
 */
export function instanceOfCompanyBookedShipmentRow(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('carrierIdentifier' in value)) return false;
    if (!('shipmentState' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('price' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    return true;
}

export function CompanyBookedShipmentRowFromJSON(json: any): CompanyBookedShipmentRow {
    return CompanyBookedShipmentRowFromJSONTyped(json, false);
}

export function CompanyBookedShipmentRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyBookedShipmentRow {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'carrierIdentifier': json['carrierIdentifier'],
        'shipmentState': ShipmentStateFromJSON(json['shipmentState']),
        'pickupDate': json['pickupDate'],
        'deliveryDate': json['deliveryDate'] == null ? undefined : json['deliveryDate'],
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'price': json['price'],
        'pickupLocation': json['pickupLocation'],
        'deliveryLocation': json['deliveryLocation'],
    };
}

export function CompanyBookedShipmentRowToJSON(value?: CompanyBookedShipmentRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'shipmentState': ShipmentStateToJSON(value['shipmentState']),
        'pickupDate': value['pickupDate'],
        'deliveryDate': value['deliveryDate'],
        'proNumber': value['proNumber'],
        'price': value['price'],
        'pickupLocation': value['pickupLocation'],
        'deliveryLocation': value['deliveryLocation'],
    };
}

