/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarrierNameAndIdentifier
 */
export interface CarrierNameAndIdentifier {
    /**
     * 
     * @type {string}
     * @memberof CarrierNameAndIdentifier
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CarrierNameAndIdentifier
     */
    identifier: string;
}

/**
 * Check if a given object implements the CarrierNameAndIdentifier interface.
 */
export function instanceOfCarrierNameAndIdentifier(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('identifier' in value)) return false;
    return true;
}

export function CarrierNameAndIdentifierFromJSON(json: any): CarrierNameAndIdentifier {
    return CarrierNameAndIdentifierFromJSONTyped(json, false);
}

export function CarrierNameAndIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierNameAndIdentifier {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'identifier': json['identifier'],
    };
}

export function CarrierNameAndIdentifierToJSON(value?: CarrierNameAndIdentifier | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'identifier': value['identifier'],
    };
}

