import { Row } from "antd";
import { useState } from "react";
import { useProfitLossApi } from "../Apis/Apis";
import CarrierLogo from "../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { CarrierComparisonReportLine } from "../generated-openapi-client/models/CarrierComparisonReportLine";

interface CarrierComparisonReportTableProps {
  report: CarrierComparisonReportLine[];
}

function CarrierComparisonReportTable(
  props: CarrierComparisonReportTableProps
) {
  const columns: DataTableColumn<CarrierComparisonReportLine>[] = [
    {
      title: "Pickup Date",
      render: (o) => <div>{o.pickupDate}</div>,
    },
    {
      title: "Pickup City",
      render: (o) => <div>{o.pickupCity}</div>,
    },
    {
      title: "Pickup State",
      render: (o) => <div>{o.pickupStateProvince}</div>,
    },
    {
      title: "Pickup Postal Code",
      render: (o) => <div>{o.pickupPostalCode}</div>,
    },
    {
      title: "Delivery City",
      render: (o) => <div>{o.deliveryCity}</div>,
    },
    {
      title: "Delivery State",
      render: (o) => <div>{o.deliveryStateProvince}</div>,
    },
    {
      title: "Delivery Postal Code",
      render: (o) => <div>{o.deliveryPostalCode}</div>,
    },
    {
      title: "Total Weight",
      render: (o) => <div>{o.totalWeight}</div>,
    },
    {
      title: "Total Cubic Feet",
      render: (o) => <div>{o.totalCubicFeet}</div>,
    },
    {
      title: "Pickup Accessorial",
      render: (o) => <div>{o.pickupAccessorials}</div>,
    },
    {
      title: "Delivery Accessorial",
      render: (o) => <div>{o.deliveryAccessorials}</div>,
    },
    {
      title: "Carrier Rank",
      render: (o) => <div>{o.carrierRank}</div>,
    },
    {
      title: "Carrier Price",
      render: (o) => <div>{o.carrierPrice}</div>,
    },
    {
      title: "Best Rate",
      render: (o) => <div>{o.bestRate}</div>,
    },
    {
      title: "2nd Best Rate",
      render: (o) => <div>{o.secondBestRate}</div>,
    },
    {
      title: "3rd Best Rate",
      render: (o) => <div>{o.thirdBestRate}</div>,
    },
    {
      title: "Behind by %",
      render: (o) => <div>{o.carrierPriceBehindByPercentage}</div>,
    },
  ];

  return <DataTable pagination={false} columns={columns} data={props.report} />;
}

export function CarrierComparisonReportScreen() {
  const query = useQuery();
  const carrierIdentifier = query.carrierIdentifier as string;
  const [report, setReport] = useState<
    CarrierComparisonReportLine[] | undefined
  >();

  const createProfitLossApi = useProfitLossApi();

  useOnce(async function () {
    const profitLossApi = await createProfitLossApi();
    const response = await profitLossApi.generateCarrierComparisonReport({
      carrierIdentifier,
    });
    setReport(response);
  });

  if (report === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title={
            <HorizontalStack>
              <CarrierLogo
                carrierIdentifier={carrierIdentifier}
                brokeredCarrierIdentifier={undefined}
                width={80}
                height={40}
              />
              <Spacer width={16} />
              <div>Comparison Report</div>
            </HorizontalStack>
          }
          tags={[]}
          extra={[]}
        >
          <PageTitle>{`Comparison Report for ${carrierIdentifier}`}</PageTitle>
          <Row>
            <Stack align="left">
              <CarrierComparisonReportTable report={report} />
            </Stack>
          </Row>
        </Page>
      </>
    );
  }
}
