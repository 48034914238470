import { Button, Modal } from "antd";
import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { EmailQuoteRequestAndResponses } from "../../generated-openapi-client/models/EmailQuoteRequestAndResponses";
import { EmailQuoteRequestState } from "../../generated-openapi-client/models/EmailQuoteRequestState";
import { EmailQuoteResponse } from "../../generated-openapi-client/models/EmailQuoteResponse";
import { AddQuoteButton } from "./AddQuoteButton";
import { MarkEmailQuoteRequestNoServiceButton } from "./MarkEmailQuoteRequestNoServiceButton";
import { MarkEmailQuoteRequestPricePotentialButton } from "./MarkEmailQuoteRequestPricePotentialButton";
import { MarkEmailQuoteRequestPriceTooHighButton } from "./MarkEmailQuoteRequestPriceTooHighButton";
import { TabProps } from "./TabProps";

interface ViewEmailQuoteResponsesButtonProps extends TabProps {
  emailQuoteRequestAndResponses: EmailQuoteRequestAndResponses;
  state: EmailQuoteRequestState;
}

interface CreatedAtProps {
  timestamp: string | undefined | null;
  skipDelta?: boolean;
}

function CreatedAt(props: CreatedAtProps) {
  if (!props.timestamp) {
    return <div>-</div>;
  }
  const m = moment.utc(props.timestamp).tz("America/Vancouver");
  const delta = m.fromNow();
  const formatted = m.format("dddd, MMMM Do, h:mm a");

  if (props.skipDelta) {
    return <div>{formatted}</div>;
  }

  return (
    <div>
      {formatted} ({delta})
    </div>
  );
}

export function ViewEmailQuoteResponsesButton(
  props: ViewEmailQuoteResponsesButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const request = props.emailQuoteRequestAndResponses.request;
  const createQuotesApi = useQuotesApi();

  async function markAsRead() {
    const quotesApi = await createQuotesApi();
    await quotesApi.markEmailQuoteResponsesRead({
      emailQuoteRequestId:
        props.emailQuoteRequestAndResponses.request.emailQuoteRequestId!!,
    });
  }

  useEffect(
    function () {
      if (isModalVisible) {
        markAsRead();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: DataTableColumn<EmailQuoteResponse>[] = [
    {
      title: "Received",
      render: (o) => <CreatedAt skipDelta timestamp={o.createdAt!!} />,
    },
    {
      title: "To",
      render: function (o) {
        return (
          <Stack align="left">
            {o.toAddresses?.map(function (m) {
              return <div>{m}</div>;
            })}
          </Stack>
        );
      },
    },
    {
      title: "From",
      render: (o) => (
        <Stack align="left">
          <div style={{ fontWeight: "400", fontSize: "14px" }}>
            {o.fromName}
          </div>
          <div style={{ fontWeight: "300", fontSize: "12px" }}>
            {o.fromAddress}
          </div>
        </Stack>
      ),
    },
    {
      title: "Body",
      width: 600,
      render: (o) => <div>{o.processed}</div>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://app.frontapp.com/open/${o.uniqueId}`}
          >
            View Email
          </a>
        </ButtonRow>
      ),
    },
  ];

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={<>{request.carrierIdentifier} Responses</>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <ButtonRow>
          <AddQuoteButton
            {...props}
            quoteGroupName="Email Quote"
            carrierServicePair={{
              carrierIdentifier: request.carrierIdentifier!!,
              serviceIdentifier: request.serviceIdentifier!!,
            }}
          />
          <MarkEmailQuoteRequestNoServiceButton
            emailQuoteRequestId={request.emailQuoteRequestId!!}
            {...props}
          />
          <MarkEmailQuoteRequestPriceTooHighButton
            emailQuoteRequestId={request.emailQuoteRequestId!!}
            {...props}
          />
          <MarkEmailQuoteRequestPricePotentialButton
            emailQuoteRequestId={request.emailQuoteRequestId!!}
            {...props}
          />
        </ButtonRow>
        <Spacer height={32} />
        <DataTable
          pagination={false}
          columns={columns}
          data={props.emailQuoteRequestAndResponses.responses}
        />
      </Modal>
      <Button onClick={showModal}>
        View Responses ({props.emailQuoteRequestAndResponses.responses.length})
      </Button>
    </>
  );
}
