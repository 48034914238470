/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DurationInfo
 */
export interface DurationInfo {
    /**
     * 
     * @type {number}
     * @memberof DurationInfo
     */
    longestMs: number;
    /**
     * 
     * @type {string}
     * @memberof DurationInfo
     */
    longestLabel: string;
    /**
     * 
     * @type {number}
     * @memberof DurationInfo
     */
    durationMs: number;
}

/**
 * Check if a given object implements the DurationInfo interface.
 */
export function instanceOfDurationInfo(value: object): boolean {
    if (!('longestMs' in value)) return false;
    if (!('longestLabel' in value)) return false;
    if (!('durationMs' in value)) return false;
    return true;
}

export function DurationInfoFromJSON(json: any): DurationInfo {
    return DurationInfoFromJSONTyped(json, false);
}

export function DurationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DurationInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'longestMs': json['longestMs'],
        'longestLabel': json['longestLabel'],
        'durationMs': json['durationMs'],
    };
}

export function DurationInfoToJSON(value?: DurationInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'longestMs': value['longestMs'],
        'longestLabel': value['longestLabel'],
        'durationMs': value['durationMs'],
    };
}

