import { Form, Menu, Modal, Switch, Typography, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import CarrierLogo from "../../Components/CarrierLogo";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import { Loading } from "../../Components/Loading";
import { Price } from "../../Components/Price";
import Stack from "../../Components/Stack";
import { ViewRawButton } from "../../Components/ViewRawButton";
import { sum } from "../../Helpers/sum";
import Spacer from "../../Spacer";
import {
  CurrencyCode,
  Quote,
  SelectQuotePlan,
  SelectQuoteResult,
  ShipmentState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";

const { Title, Text } = Typography;

interface DescribeBooleanProps {
  value: boolean;
}

function DescribeBoolean(props: DescribeBooleanProps) {
  if (props.value === true) {
    return <div>✅ Yes</div>;
  } else {
    return <div>❌ No</div>;
  }
}

interface ViewQuoteProps {
  quote: Quote;
  shipmentData: ViewShipmentDataForApollo;
}

function ViewQuote(props: ViewQuoteProps) {
  const data: Record<string, any> = {};

  data["Carrier"] = (
    <CarrierLogo
      carrierIdentifier={props.quote.carrierIdentifier}
      brokeredCarrierIdentifier={props.quote.brokeredCarrierIdentifier}
      width={60}
      height={30}
    />
  );
  data["Service"] = props.quote.serviceIdentifier;
  data["Price"] =
    props.shipmentData.shipment.company.currency === CurrencyCode.Cad ? (
      <Price currency={CurrencyCode.Cad}>{props.quote.priceCad}</Price>
    ) : (
      <Price currency={CurrencyCode.Usd}>{props.quote.priceUsd}</Price>
    );

  data["Weight"] = `${sum(
    props.quote.lineItems!!,
    (li) => li.numberHandlingUnits!! * li.weightPerHandlingUnit!!
  )}lb`;

  const volume = sum(
    props.quote.lineItems!!,
    (li) =>
      (li.numberHandlingUnits!! * li.length!! * li.width!! * li.height!!) /
      (12 * 12 * 12)
  ).toLocaleString();
  data["Volume"] = `${volume}cuft`;

  const numHandlingUnits = sum(
    props.quote.lineItems!!,
    (li) => li.numberHandlingUnits!!
  );
  data["Handling Units"] = numHandlingUnits;
  return <KeyValues data={data} />;
}

interface ViewSelectQuotePlanProps {
  data: SelectQuotePlan;
  shipmentData: ViewShipmentDataForApollo;
}

function ViewSelectQuotePlan(props: ViewSelectQuotePlanProps) {
  const data: Record<string, any> = {};

  if (!props.data.allowedToSwitch.allowedToSwitch) {
    data["Allowed to Switch"] =
      props.data.allowedToSwitch.allowedToSwitchReason;
  }

  if (props.data.allowedToSwitch.allowedToSwitch) {
    data["Mark on hold"] = (
      <DescribeBoolean value={props.data.willMarkShipmentOnHold} />
    );

    data[
      `Send cancel pickup email to ${props.data.originalQuote.carrierIdentifier}`
    ] = <DescribeBoolean value={props.data.willSendCancelPickupEmail} />;
    data[`Move pickup date to today`] = (
      <DescribeBoolean value={props.data.willMovePickupDateToToday} />
    );

    data["Select new quote"] = (
      <DescribeBoolean value={props.data.willSelectNewQuote} />
    );

    data["Will clear old pro number"] = (
      <DescribeBoolean value={props.data.willClearProNumber} />
    );

    data["Will handle customer billing?"] = (
      <DescribeBoolean value={props.data.paymentsPlan.canSuccessfullyHandle} />
    );
    data["Will regenerate BOL/pickup package?"] = (
      <DescribeBoolean value={props.data.willRegenerateDocuments} />
    );

    const isCrossBorder =
      props.shipmentData.shipment.shipment.pickupLocation?.address
        ?.countryCode !==
      props.shipmentData.shipment.shipment.deliveryLocation?.address
        ?.countryCode;

    if (isCrossBorder) {
      data["Will resend customs documents?"] = (
        <DescribeBoolean value={props.data.willHandleCustomsDocuments} />
      );
    }

    data["Customer billing change plan"] = props.data.paymentsPlan.message;
  }

  return <KeyValues data={data} />;
}

interface ViewSelectQuoteResultProps {
  plan: SelectQuotePlan;
  result: SelectQuoteResult;
}

// The idea is
// - if we said we would do something, and we did it, show it
// - if we said we would do it, but we didn't, show it
// - if we said we WOULD NOT do it, but we did it anyway, show it
// only exclude the case when we said we wouldn't and we didn't
function shouldShow(will: boolean, did: boolean): boolean {
  if (will === false && did === false) {
    return false;
  } else {
    return true;
  }
}

function ViewSelectQuoteResult(props: ViewSelectQuoteResultProps) {
  const data: Record<string, any> = {};

  const { result, plan } = props;

  const didMarkShipmentOnHold = result.didMarkShipmentOnHold!!;
  const didSendCancelPickupEmail = result.didSendCancelPickupEmail!!;
  const didSelectNewQuote = result.didSelectNewQuote!!;
  const didClearProNumber = result.didClearProNumber!!;
  const didBookNewPickup = result.didBookNewPickup!!;
  const didRegenerateDocuments = result.didRegenerateDocuments!!;
  const updatedPickupDate = result.updatedPickupDate!!;
  const didHandleCustomsDocuments = result.didHandleCustomsDocuments!!;
  const paymentsResult = result.paymentsResult!!;
  const success = result.success!!;

  const {
    willMarkShipmentOnHold,
    willSendCancelPickupEmail,
    willSelectNewQuote,
    willClearProNumber,
    willBookNewPickup,
    willRegenerateDocuments,
    willHandleCustomsDocuments,
    paymentsPlan,
  } = plan;

  if (shouldShow(willMarkShipmentOnHold, didMarkShipmentOnHold)) {
    data["Did Mark Shipment On Hold"] = (
      <DescribeBoolean value={props.result.didMarkShipmentOnHold!!} />
    );
  }

  if (shouldShow(willSendCancelPickupEmail, didSendCancelPickupEmail)) {
    data["Sent email to cancel"] = (
      <DescribeBoolean value={props.result.didSendCancelPickupEmail!!} />
    );
  }

  if (shouldShow(willClearProNumber, didClearProNumber)) {
    data["Pro Number Cleared"] = (
      <DescribeBoolean value={props.result.didClearProNumber!!} />
    );
  }

  data["Updated pickup date"] = (
    <div>{moment(updatedPickupDate).format("MMM Do YYYY")}</div>
  );
  if (shouldShow(willSelectNewQuote, didSelectNewQuote)) {
    data["Quote Switched"] = (
      <DescribeBoolean value={props.result.didSelectNewQuote!!} />
    );
  }

  if (shouldShow(willBookNewPickup, didBookNewPickup)) {
    data["Pickup Booked"] = (
      <DescribeBoolean value={props.result.didBookNewPickup!!} />
    );
  }

  if (shouldShow(willRegenerateDocuments, didRegenerateDocuments)) {
    data["BOL/Pickup Package Regenerated"] = (
      <DescribeBoolean value={didRegenerateDocuments!!} />
    );
  }

  if (shouldShow(willHandleCustomsDocuments, didHandleCustomsDocuments)) {
    data["Handled Customs Documents"] = (
      <DescribeBoolean value={didHandleCustomsDocuments!!} />
    );
  }

  if (
    shouldShow(paymentsPlan.canSuccessfullyHandle, paymentsResult !== undefined)
  ) {
    data["Payments"] =
      paymentsResult === undefined ? "Missing Info" : paymentsResult!!.message;
  }

  data["Overall Success"] = <DescribeBoolean value={success!!} />;

  return <KeyValues data={data} />;
}

interface SelectQuoteMenuItemProps {
  quote: Quote;
  data: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

export function SelectQuoteMenuItem(props: SelectQuoteMenuItemProps) {
  const title = `Select quote`;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createQuotesApi = useQuotesApi();
  const state = props.data.shipment.shipment.state!!;
  const [shouldSendCancelPickupEmail, setShouldSendCancelPickupEmail] =
    useState(true);
  const [shouldBookNewPickup, setShouldBookNewPickup] = useState(true);
  const [shouldUpdateCustomerBilling, setShouldUpdateCustomerBilling] =
    useState(true);
  const [selectQuotePlan, setSelectQuotePlan] = useState<SelectQuotePlan>();
  const [selectQuoteResult, setSelectQuoteResult] =
    useState<SelectQuoteResult>();
  const [submittedForProcessing, setSubmittedForProcessing] = useState(false);
  const [selectingQuote, setSelectingQuote] = useState(false);

  async function selectQuote() {
    if (submittedForProcessing) {
      return;
    }

    setSelectingQuote(true);

    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!!;
    try {
      const response = await quotesApi.selectQuote({
        quoteId,
        selectQuoteInput: {
          shouldSendCancelPickupEmail,
          shouldBookNewPickup,
          shouldUpdateCustomerBilling,
        },
      });
      setSelectQuoteResult(response);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }

    setSelectingQuote(false);
  }

  async function previewSelectQuote() {
    const quotesApi = await createQuotesApi();
    const quoteId = props.quote.quoteId!!;
    try {
      const response = await quotesApi.previewSelectQuote({
        quoteId,
        selectQuoteInput: {
          shouldSendCancelPickupEmail,
          shouldBookNewPickup,
          shouldUpdateCustomerBilling,
        },
      });
      setSelectQuotePlan(response);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  useEffect(
    function () {
      if (isModalVisible) {
        setSelectQuotePlan(undefined);
        setSelectQuoteResult(undefined);
        setSubmittedForProcessing(false);
        previewSelectQuote();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible]
  );

  function okText() {
    if (submittedForProcessing) {
      return "Done";
    } else {
      return "Process Quote Change";
    }
  }

  useEffect(
    function () {
      if (isModalVisible) {
        previewSelectQuote();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shouldSendCancelPickupEmail,
      shouldBookNewPickup,
      shouldUpdateCustomerBilling,
    ]
  );

  function allowedToSwitch(): boolean {
    // This is for before booking
    switch (state) {
      case ShipmentState.QuoteRequested:
      case ShipmentState.Quoted:
        return false;
      case ShipmentState.BookingRequested:
      case ShipmentState.BookingConfirmed:
      case ShipmentState.BookingFailed:
      case ShipmentState.OnHold:
      case ShipmentState.InTransit:
      case ShipmentState.Cancelled:
      case ShipmentState.Lost:
      case ShipmentState.Delivered:
        return true;
    }
  }

  if (!allowedToSwitch()) {
    return <></>;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (!submittedForProcessing) {
      setSubmittedForProcessing(true);
      selectQuote();
    } else {
      await props.onRefresh();
      setIsModalVisible(false);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`Since the shipment is booked, selecting a new quote is quite complicated. This will walk you through the process.`}
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{
          loading: selectingQuote,
          disabled: !selectQuotePlan?.allowedToSwitch.allowedToSwitch,
        }}
        okText={okText()}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <HorizontalStack verticalAlign="top">
          {selectQuotePlan && (
            <>
              <Stack align="left">
                <HorizontalStack>
                  <Stack align="left">
                    <Title level={5}>Original Quote</Title>
                    <ViewQuote
                      quote={selectQuotePlan.originalQuote}
                      shipmentData={props.data}
                    />
                  </Stack>
                  <Spacer width={32} />
                  <Stack align="left">
                    <Title level={5}>New Quote</Title>
                    <ViewQuote
                      quote={selectQuotePlan.newQuote}
                      shipmentData={props.data}
                    />
                  </Stack>
                </HorizontalStack>
                <Spacer height={32} />
                <Title level={5}>Control Options</Title>
                <Text>
                  Use these options to control the process. If you need to
                  prevent certain steps from happening
                </Text>
                <Spacer height={16} />
                <Form
                  style={{ width: "300px" }}
                  labelCol={{ span: 20 }}
                  wrapperCol={{ span: 10, offset: 1 }}
                  layout="horizontal"
                  colon={false}
                >
                  <Form.Item
                    label="Potentially, Send Cancel Pickup Email?"
                    tooltip="If you've already cancelled the pickup, say, on the phone, then no need to send an email cancelling it"
                  >
                    <Switch
                      checked={shouldSendCancelPickupEmail}
                      onChange={setShouldSendCancelPickupEmail}
                      disabled={submittedForProcessing}
                    />
                  </Form.Item>
                  <Form.Item label="Potentially, Book New Pickup?">
                    <Switch
                      checked={shouldBookNewPickup}
                      onChange={setShouldBookNewPickup}
                      disabled={submittedForProcessing}
                    />
                  </Form.Item>
                  <Form.Item label="Potentially, Update Customer Billing?">
                    <Switch
                      checked={shouldUpdateCustomerBilling}
                      onChange={setShouldUpdateCustomerBilling}
                      disabled={submittedForProcessing}
                    />
                  </Form.Item>
                </Form>
              </Stack>
              <Spacer width={96} />
            </>
          )}
          <div style={{ width: "800px" }}>
            {selectQuotePlan && (
              <>
                <Title level={5}>Proposed Plan</Title>
                <Text>These are the steps that will be performed</Text>
                <Stack align="left">
                  <ViewSelectQuotePlan
                    data={selectQuotePlan}
                    shipmentData={props.data}
                  />
                  <ViewRawButton data={selectQuotePlan} />
                </Stack>
              </>
            )}
          </div>
          <div style={{ width: "800px" }}>
            {(selectQuoteResult || selectingQuote) && (
              <>
                <Title level={5}>Result</Title>
                <Stack align="left">
                  {selectingQuote && <Loading />}
                  {selectQuoteResult && selectQuotePlan && (
                    <>
                      <ViewSelectQuoteResult
                        result={selectQuoteResult}
                        plan={selectQuotePlan}
                      />
                      <ViewRawButton data={selectQuoteResult} />
                    </>
                  )}
                </Stack>
              </>
            )}
          </div>
        </HorizontalStack>
      </Modal>
      <Menu.Item onClick={showModal}>{title}</Menu.Item>
    </>
  );
}
