/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyCustomerInvoice } from './CompanyCustomerInvoice';
import {
    CompanyCustomerInvoiceFromJSON,
    CompanyCustomerInvoiceFromJSONTyped,
    CompanyCustomerInvoiceToJSON,
} from './CompanyCustomerInvoice';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';
import type { XeroInvoice } from './XeroInvoice';
import {
    XeroInvoiceFromJSON,
    XeroInvoiceFromJSONTyped,
    XeroInvoiceToJSON,
} from './XeroInvoice';

/**
 * 
 * @export
 * @interface MismatchedCustomerInvoices
 */
export interface MismatchedCustomerInvoices {
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof MismatchedCustomerInvoices
     */
    shipmentsWithoutCustomerInvoices: Array<CompanyShipmentQuote>;
    /**
     * 
     * @type {Array<XeroInvoice>}
     * @memberof MismatchedCustomerInvoices
     */
    xeroInvoicesNotInDb: Array<XeroInvoice>;
    /**
     * 
     * @type {Array<CompanyCustomerInvoice>}
     * @memberof MismatchedCustomerInvoices
     */
    dbInvoicesNotInXero: Array<CompanyCustomerInvoice>;
    /**
     * 
     * @type {Array<XeroInvoice>}
     * @memberof MismatchedCustomerInvoices
     */
    outstandingXeroInvoices: Array<XeroInvoice>;
    /**
     * 
     * @type {Array<CompanyCustomerInvoice>}
     * @memberof MismatchedCustomerInvoices
     */
    outstandingDbInvoices: Array<CompanyCustomerInvoice>;
}

/**
 * Check if a given object implements the MismatchedCustomerInvoices interface.
 */
export function instanceOfMismatchedCustomerInvoices(value: object): boolean {
    if (!('shipmentsWithoutCustomerInvoices' in value)) return false;
    if (!('xeroInvoicesNotInDb' in value)) return false;
    if (!('dbInvoicesNotInXero' in value)) return false;
    if (!('outstandingXeroInvoices' in value)) return false;
    if (!('outstandingDbInvoices' in value)) return false;
    return true;
}

export function MismatchedCustomerInvoicesFromJSON(json: any): MismatchedCustomerInvoices {
    return MismatchedCustomerInvoicesFromJSONTyped(json, false);
}

export function MismatchedCustomerInvoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MismatchedCustomerInvoices {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentsWithoutCustomerInvoices': ((json['shipmentsWithoutCustomerInvoices'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
        'xeroInvoicesNotInDb': ((json['xeroInvoicesNotInDb'] as Array<any>).map(XeroInvoiceFromJSON)),
        'dbInvoicesNotInXero': ((json['dbInvoicesNotInXero'] as Array<any>).map(CompanyCustomerInvoiceFromJSON)),
        'outstandingXeroInvoices': ((json['outstandingXeroInvoices'] as Array<any>).map(XeroInvoiceFromJSON)),
        'outstandingDbInvoices': ((json['outstandingDbInvoices'] as Array<any>).map(CompanyCustomerInvoiceFromJSON)),
    };
}

export function MismatchedCustomerInvoicesToJSON(value?: MismatchedCustomerInvoices | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentsWithoutCustomerInvoices': ((value['shipmentsWithoutCustomerInvoices'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
        'xeroInvoicesNotInDb': ((value['xeroInvoicesNotInDb'] as Array<any>).map(XeroInvoiceToJSON)),
        'dbInvoicesNotInXero': ((value['dbInvoicesNotInXero'] as Array<any>).map(CompanyCustomerInvoiceToJSON)),
        'outstandingXeroInvoices': ((value['outstandingXeroInvoices'] as Array<any>).map(XeroInvoiceToJSON)),
        'outstandingDbInvoices': ((value['outstandingDbInvoices'] as Array<any>).map(CompanyCustomerInvoiceToJSON)),
    };
}

