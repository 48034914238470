import { Button } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { CustomerInvoiceState } from "../../generated-openapi-client";
import { CustomerInvoice } from "../../generated-openapi-client/models/CustomerInvoice";
interface ToggleCustomerInvoiceDisputedButtonProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

export function ToggleCustomerInvoiceDisputedButton(
  props: ToggleCustomerInvoiceDisputedButtonProps
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  if (
    props.customerInvoice.customerInvoiceState !== CustomerInvoiceState.Issued
  ) {
    return <></>;
  }

  async function toggleDisputed() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    await customerInvoiceApi.markInvoiceDisputed({
      customerInvoiceId: props.customerInvoice.customerInvoiceId!!,
      disputed: !props.customerInvoice.disputed,
    });
    await props.onRefresh();
  }

  if (props.customerInvoice.disputed) {
    return <Button onClick={toggleDisputed}>Mark Not Disputed</Button>;
  } else {
    return <Button onClick={toggleDisputed}>Mark Disputed</Button>;
  }
}
