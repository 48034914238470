import { Button, Row } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { MondayStatus } from "../../generated-openapi-client";
import { ShipmentMondayStatus } from "../../generated-openapi-client/models/ShipmentMondayStatus";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client/models/ViewShipmentDataForApollo";

function useCloseShipmentMondayTask(
  mondayId: string,
  onRefresh: () => Promise<void>,
  treatAsCarrierInvoiceTask = false
) {
  const createShipmentApi = useShipmentsApi();
  const [closeLoading, setCloseLoading] = useState(false);
  const [markLoading, setMarkLoading] = useState(false);

  return {
    closeLoading,
    markLoading,
    close: async function () {
      setCloseLoading(true);
      const shipmentApi = await createShipmentApi();
      if (treatAsCarrierInvoiceTask) {
        await shipmentApi.closeCarrierInvoiceMondayTask({ mondayId });
      } else {
        await shipmentApi.closeShipmentMondayTask({ mondayId });
      }

      await onRefresh();
      setCloseLoading(false);
    },
    markWaitingForCarrier: async function () {
      setMarkLoading(true);
      const shipmentApi = await createShipmentApi();
      await shipmentApi.markShipmentMondayTaskAsWaitingForCarrier({ mondayId });
      await onRefresh();
      setMarkLoading(false);
    },
    link: `https://freightsimple.monday.com/boards/1183357790/views/41589222/pulses/${mondayId}`,
  };
}

interface MondayResolutionWidgetProps {
  onRefresh: () => Promise<void>;
  shipmentData: ViewShipmentDataForApollo;
  shipmentMondayStatus: ShipmentMondayStatus | undefined;
}

interface MondayResolutionWidgetBaseProps {
  title: string;
  close: () => Promise<void>;
  markWaitingForCarrier: () => Promise<void>;
  closeLoading: boolean;
  markLoading: boolean;
  backgroundColor?: string;
  link: string;
}

function MondayResolutionWidgetBase(props: MondayResolutionWidgetBaseProps) {
  return (
    <Row
      style={{
        padding: "16px",
        backgroundColor: props.backgroundColor ?? Colors.Gold,
      }}
    >
      <HorizontalStack align="spread" style={{ width: "100%" }}>
        <div>
          <strong>
            {props.title} <a href={`${props.link}`}>View</a>
          </strong>
        </div>
        <ButtonRow>
          <Stack>
            <Button
              onClick={props.close}
              type="primary"
              loading={props.closeLoading}
            >
              Close Monday Task
            </Button>
            <Button
              onClick={props.markWaitingForCarrier}
              type="link"
              size="small"
              loading={props.markLoading}
            >
              Mark Waiting for Carrier
            </Button>
          </Stack>
        </ButtonRow>
      </HorizontalStack>
    </Row>
  );
}

function MissedPickupResolutionWidget(props: MondayResolutionWidgetProps) {
  const taskProps = useCloseShipmentMondayTask(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    props.shipmentMondayStatus?.missedPickupMondayId!!,
    props.onRefresh
  );

  return (
    <MondayResolutionWidgetBase title="Missed Pickup Task" {...taskProps} />
  );
}

function MissedDeliveryResolutionWidget(props: MondayResolutionWidgetProps) {
  const taskProps = useCloseShipmentMondayTask(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    props.shipmentMondayStatus?.missedDeliveryMondayId!!,
    props.onRefresh
  );
  return (
    <MondayResolutionWidgetBase title="Missed Delivery Task" {...taskProps} />
  );
}

function TrackingNotUpdatedResolutionWidget(
  props: MondayResolutionWidgetProps
) {
  const taskProps = useCloseShipmentMondayTask(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    props.shipmentMondayStatus?.trackingNotUpdatedMondayId!!,
    props.onRefresh
  );
  return (
    <MondayResolutionWidgetBase
      title="Tracking Not Updated Task"
      {...taskProps}
    />
  );
}

function BookPickupResolutionWidget(props: MondayResolutionWidgetProps) {
  const taskProps = useCloseShipmentMondayTask(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    props.shipmentMondayStatus?.bookPickupMondayId!!,
    props.onRefresh
  );
  return <MondayResolutionWidgetBase title="Book Pickup Task" {...taskProps} />;
}

function DeliveryDateResolutionWidget(props: MondayResolutionWidgetProps) {
  const taskProps = useCloseShipmentMondayTask(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    props.shipmentMondayStatus?.deliveryDateMondayId!!,
    props.onRefresh
  );
  return (
    <MondayResolutionWidgetBase title="Delivery Date Task" {...taskProps} />
  );
}

export function MondayResolutionWidget(props: MondayResolutionWidgetProps) {
  const { shipmentMondayStatus } = props;

  if (
    shipmentMondayStatus?.missedPickupMondayId &&
    shipmentMondayStatus.missedPickupMondayStatus !== MondayStatus.Done
  ) {
    return <MissedPickupResolutionWidget {...props} />;
  }

  if (
    shipmentMondayStatus?.missedDeliveryMondayId &&
    shipmentMondayStatus.missedDeliveryMondayStatus !== MondayStatus.Done
  ) {
    return <MissedDeliveryResolutionWidget {...props} />;
  }

  if (
    shipmentMondayStatus?.deliveryDateMondayId &&
    shipmentMondayStatus.deliveryDateMondayStatus !== MondayStatus.Done
  ) {
    return <DeliveryDateResolutionWidget {...props} />;
  }

  if (
    shipmentMondayStatus?.trackingNotUpdatedMondayId &&
    shipmentMondayStatus.trackingNotUpdatedMondayStatus !== MondayStatus.Done
  ) {
    return <TrackingNotUpdatedResolutionWidget {...props} />;
  }

  if (
    shipmentMondayStatus?.bookPickupMondayId &&
    shipmentMondayStatus.bookPickupMondayStatus !== MondayStatus.Done
  ) {
    return <BookPickupResolutionWidget {...props} />;
  }

  return <></>;
}
