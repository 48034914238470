import { Select } from "antd";
import { useState } from "react";
import HorizontalStack from "../../Components/HorizontalStack";
import Spacer from "../../Spacer";
import { Company, SalesContact } from "../../generated-openapi-client";
import { AddSalesContactButton } from "../ViewCompanyScreenComponents/AddSalesContactButton";

interface SalesContactsDropdownProps {
  contactId: string | undefined;
  setContactId: (_: string) => void;
  company: Company;
  salesContacts: SalesContact[];
}

export function SalesContactsDropdown(props: SalesContactsDropdownProps) {
  const companyId = props.company.companyId!!;
  const [salesContacts, setSalesContacts] = useState(props.salesContacts);

  return (
    <HorizontalStack>
      <Select
        style={{ width: "200px" }}
        value={props.contactId}
        onChange={function (newContactId) {
          props.setContactId(newContactId);
        }}
      >
        {salesContacts.map(function (c) {
          return (
            <Select.Option value={c.salesContactId} key={c.salesContactId}>
              {c.name}
            </Select.Option>
          );
        })}
        <Select.Option value={undefined}>
          No body / Doesn't matter
        </Select.Option>
      </Select>
      <Spacer width={8} />
      <AddSalesContactButton
        companyId={companyId}
        onContactAdded={async function (newSalesContact: SalesContact) {
          setSalesContacts((sc) => [...sc, newSalesContact]);
          props.setContactId(newSalesContact.salesContactId!!);
        }}
      />
    </HorizontalStack>
  );
}
