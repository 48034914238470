/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CarrierInvoiceApprovalType = {
    Approved: 'Approved',
    NotApprovedTooExpensive: 'NotApprovedTooExpensive',
    NotApprovedInvalidCurrency: 'NotApprovedInvalidCurrency',
    Duplicate: 'Duplicate',
    Zero: 'Zero',
    NoInvoices: 'NoInvoices',
    TaxMismatch: 'TaxMismatch',
    NotApprovedCancelled: 'NotApprovedCancelled',
    NotApprovedChallengeAllInvoices: 'NotApprovedChallengeAllInvoices'
} as const;
export type CarrierInvoiceApprovalType = typeof CarrierInvoiceApprovalType[keyof typeof CarrierInvoiceApprovalType];


export function instanceOfCarrierInvoiceApprovalType(value: any): boolean {
    return Object.values(CarrierInvoiceApprovalType).includes(value);
}

export function CarrierInvoiceApprovalTypeFromJSON(json: any): CarrierInvoiceApprovalType {
    return CarrierInvoiceApprovalTypeFromJSONTyped(json, false);
}

export function CarrierInvoiceApprovalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInvoiceApprovalType {
    return json as CarrierInvoiceApprovalType;
}

export function CarrierInvoiceApprovalTypeToJSON(value?: CarrierInvoiceApprovalType | null): any {
    return value as any;
}

