import { Statistic } from "antd";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Components/Loading";
import { StatsRow } from "../Components/StatsRow";
import { MapData, useMapData } from "../Hooks/useMapData";
import useQuery from "../Hooks/useQuery";
import { ApolloMapQueryBookingStatus } from "../generated-openapi-client";
import { ApolloMapLocationFilterType } from "../generated-openapi-client/models/ApolloMapLocationFilterType";
import { ApolloMapQuery } from "../generated-openapi-client/models/ApolloMapQuery";
import { MapScreenHeader } from "./MapScreenComponents/MapScreenHeader";

// https://github.com/alex3165/react-mapbox-gl/issues/931
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// This is our public key, so not a big deal being in the code
// since it's public on the website anyway
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2hyaXN0b3BoZXJzdG90dCIsImEiOiJjazdmZnZ1eDIwMmR0M25wcnMxOWMzNTIzIn0.LBCxe4yd1d0SohHTSvzSmg";

interface MapComponentProps {
  data: MapData;
  showSidebar: (_: string) => void;
  hideSidebar: () => void;
}

export function MapComponent(props: MapComponentProps) {
  const { showSidebar } = props;
  const data = props.data;
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    async function initializeMap() {
      const currentMapContainer = mapContainer.current;
      if (currentMapContainer == null) {
        return;
      }

      const mapToInitialize = new mapboxgl.Map({
        container: currentMapContainer,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [-90, 44],
        zoom: 3,
      });

      mapToInitialize.resize();

      mapToInitialize.addControl(new mapboxgl.NavigationControl());

      setMapLoaded(true);
      setMap(mapToInitialize);

      // @ts-ignore
      function onClick(e) {
        // @ts-ignore
        const url = e.features[0].properties.url;
        window.open(url, "_new");
      }

      // @ts-ignore
      function onMouseEnter(e) {
        const description = e.features[0].properties.description;
        showSidebar(description);
        // Change the cursor style as a UI indicator.
        mapToInitialize.getCanvas().style.cursor = "pointer";

        // Change the cursor style as a UI indicator.
        // mapToInitialize.getCanvas().style.cursor = "pointer";
        // // Copy coordinates array.
        // // @ts-ignore
        // const coordinates = e.lngLat;
        // // @ts-ignore
        // const description = e.features[0].properties.description;
        // console.log(`!!! onMouseEnter`, { e, coordinates, description });
        // //   // Populate the popup and set its coordinates
        // //   // based on the feature found.
        // _popup
        //   .setLngLat(coordinates)
        //   .setHTML(description)
        //   .addTo(mapToInitialize);
      }
      // @ts-ignore
      function onMouseLeave() {
        //hideSidebar();
        mapToInitialize.getCanvas().style.cursor = "";
        //popup.remove();
      }

      mapToInitialize.on("mouseenter", "bookedPoints", onMouseEnter);
      mapToInitialize.on("mouseenter", "notBookedPoints", onMouseEnter);
      mapToInitialize.on("mouseleave", "bookedPoints", onMouseLeave);
      mapToInitialize.on("mouseleave", "notBookedPoints", onMouseLeave);
      mapToInitialize.on("click", "bookedPoints", onClick);
      mapToInitialize.on("click", "notBookedPoints", onClick);
    }

    if (map === undefined) {
      initializeMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setupLocations(map: mapboxgl.Map, data: MapData) {
    const {
      bookedFeatures,
      notBookedFeatures,
      bookedFeaturesCirclePickup,
      notBookedFeaturesCirclePickup,
      bookedFeaturesCircleDelivery,
      notBookedFeaturesCircleDelivery,
    } = data;
    addLayer("bookedPoints", bookedFeatures, "green", 1.0);
    addLayer("notBookedPoints", notBookedFeatures, "red", 0.3);
    addCirclesLayer(
      "bookedPointsCirclesPickup",
      bookedFeaturesCirclePickup,
      "blue"
    );
    addCirclesLayer(
      "notBookedPointsCirclesPickup",
      notBookedFeaturesCirclePickup,
      "blue"
    );
    addCirclesLayer(
      "bookedPointsCirclesDelivery",
      bookedFeaturesCircleDelivery,
      "black"
    );
    addCirclesLayer(
      "notBookedPointsCirclesDelivery",
      notBookedFeaturesCircleDelivery,
      "black"
    );

    // setPickupLocation(map, pickup, shipmentState);
    // setDeliveryLocation(map, delivery, shipmentState);

    // renderPopups(map, pickup, delivery);

    // setupArc(map, pickup, delivery, shipmentState);

    // zoomMap(map, pickup, delivery);

    // setupMarker(map, pickup, delivery, shipmentState, requestRef);
  }

  async function setupLocationsWrapper(map: mapboxgl.Map) {
    let remaining = 3;

    // We sometimes get exceptions that the style is not loaded. I can't find a way to wait for mapbox to properly have styles loaded
    // So let's just catch and retry to work around it
    function retry(f: () => void) {
      try {
        f();
      } catch (e) {
        console.log(`!!!! got error ${e}`);
        remaining--;
        if (remaining > 0) {
          setTimeout(function () {
            retry(f);
          }, 500);
        } else {
          console.error("Ran out of retries");
        }
      }
    }

    retry(function () {
      setupLocations(map, data);
    });
  }

  function addLayer(id: string, features: any, color: string, opacity: number) {
    const sourceId = `${id}Source`;
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }

    if (map?.getSource(sourceId)) {
      map?.removeSource(sourceId);
    }

    map?.addSource(sourceId, {
      type: "geojson",
      lineMetrics: true,
      data: {
        type: "FeatureCollection",
        // @ts-ignore
        features: features,
      },
    });

    map?.addLayer({
      id: id,
      type: "line",
      source: sourceId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
        "line-opacity": opacity,
        "line-gradient": [
          "interpolate",
          ["linear"],
          ["line-progress"],
          0,
          "#ddd",
          1,
          color,
        ],
      },
    });
  }

  function addCirclesLayer(id: string, features: any, color: string) {
    const sourceId = `${id}Source`;
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }

    if (map?.getSource(sourceId)) {
      map?.removeSource(sourceId);
    }

    map?.addSource(sourceId, {
      type: "geojson",
      lineMetrics: true,
      data: {
        type: "FeatureCollection",
        // @ts-ignore
        features: features,
      },
    });

    map?.addLayer({
      id: id,
      type: "circle",
      source: sourceId,
      paint: {
        "circle-color": color,
        "circle-radius": 2,
      },
    });
  }

  useEffect(() => {
    if (map === undefined) {
      console.log(`!!!! no map yet`);
      return;
    }

    if (!mapLoaded) {
      map.on("load", function () {
        setupLocationsWrapper(map);
        setMapLoaded(true);
      });
    } else {
      setupLocationsWrapper(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), map]);

  return (
    <div
      ref={mapContainer}
      style={{
        width: "100vw",
        height: "calc(100vh - 96px)",
        position: "absolute",
      }}
    ></div>
  );
}

export function MapScreen() {
  const urlQuery = useQuery();

  function potentiallyParseInt(s: string): number | null {
    const result = parseInt(s);
    if (isNaN(result)) {
      return null;
    } else {
      return result;
    }
  }

  function defaultQuery(): ApolloMapQuery {
    return {
      bookingStatus:
        (urlQuery.bookingStatus as ApolloMapQueryBookingStatus) ??
        ApolloMapQueryBookingStatus.Booked,
      companyId: (urlQuery.companyId as string) ?? null,
      carrierIdentifier: (urlQuery.carrierIdentifier as string) ?? null,
      inCarrierCoverageArea: (urlQuery.inCarrierCoverageArea as string) ?? null,
      compareWithCarrier: (urlQuery.compareWithCarrier as string) ?? null,
      startDate:
        (urlQuery.startDate as string) ??
        moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: (urlQuery.endDate as string) ?? moment().format("YYYY-MM-DD"),
      pickupLocationFilterType:
        (urlQuery.pickupLocationFilterType as ApolloMapLocationFilterType) ??
        null,
      deliveryLocationFilterType:
        (urlQuery.deliveryLocationFilterType as ApolloMapLocationFilterType) ??
        null,
      pickupCity: (urlQuery.pickupCity as string) ?? null,
      deliveryCity: (urlQuery.deliveryCity as string) ?? null,
      minimumShipmentWeight:
        potentiallyParseInt(urlQuery.minimumShipmentWeight as string) ??
        undefined,
      maximumShipmentWeight:
        potentiallyParseInt(urlQuery.maximumShipmentWeight as string) ??
        undefined,
      minimumNumberHandlingUnits:
        potentiallyParseInt(urlQuery.minimumNumberHandlingUnits as string) ??
        undefined,
      maximumNumberHandlingUnits:
        potentiallyParseInt(urlQuery.maximumNumberHandlingUnits as string) ??
        undefined,
      minimumVolume:
        potentiallyParseInt(urlQuery.minimumVolume as string) ?? undefined,
      maximumVolume:
        potentiallyParseInt(urlQuery.maximumVolume as string) ?? undefined,
    };
  }

  const [query, setQuery] = useState(defaultQuery());
  console.log(`#### Query is`, { query });
  const { data, loading } = useMapData(query);
  const [sidebarContent, setSidebarContent] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(
    function () {
      let url = `/map?`;
      url += `bookingStatus=${query.bookingStatus}`;
      if (query.companyId) {
        url += `&companyId=${query.companyId}`;
      }
      if (query.carrierIdentifier) {
        url += `&carrierIdentifier=${query.carrierIdentifier}`;
      }
      if (query.pickupLocationFilterType) {
        url += `&pickupLocationFilterType=${query.pickupLocationFilterType}`;
      }
      if (query.deliveryLocationFilterType) {
        url += `&deliveryLocationFilterType=${query.deliveryLocationFilterType}`;
      }
      if (query.pickupCity) {
        url += `&pickupCity=${query.pickupCity}`;
      }
      if (query.deliveryCity) {
        url += `&deliveryCity=${query.deliveryCity}`;
      }
      if (query.startDate) {
        url += `&startDate=${query.startDate}`;
      }
      if (query.endDate) {
        url += `&endDate=${query.endDate}`;
      }
      if (query.inCarrierCoverageArea) {
        url += `&inCarrierCoverageArea=${query.inCarrierCoverageArea}`;
      }
      if (query.compareWithCarrier) {
        url += `&compareWithCarrier=${query.compareWithCarrier}`;
      }
      if (query.minimumShipmentWeight) {
        url += `&minimumShipmentWeight=${query.minimumShipmentWeight}`;
      }
      if (query.maximumShipmentWeight) {
        url += `&maximumShipmentWeight=${query.maximumShipmentWeight}`;
      }
      if (query.minimumNumberHandlingUnits) {
        url += `&minimumNumberHandlingUnits=${query.minimumNumberHandlingUnits}`;
      }
      if (query.maximumNumberHandlingUnits) {
        url += `&maximumNumberHandlingUnits=${query.maximumNumberHandlingUnits}`;
      }
      if (query.minimumVolume) {
        url += `&minimumVolume=${query.minimumVolume}`;
      }
      if (query.maximumVolume) {
        url += `&maximumVolume=${query.maximumVolume}`;
      }

      navigate(url, {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(query)]
  );
  if (data === undefined) {
    return <Loading />;
  }
  return (
    <div>
      <MapScreenHeader
        data={data}
        query={query}
        setQuery={setQuery}
        loading={loading}
      />
      <div>
        <MapComponent
          data={data}
          showSidebar={function (contents) {
            setSidebarContent(contents);
          }}
          hideSidebar={function () {
            setSidebarContent(undefined);
          }}
        />
        {query.bookingStatus === ApolloMapQueryBookingStatus.Any && (
          <div
            style={{
              position: "fixed",
              left: "16px",
              top: "112px",
              backgroundColor: "#eeea",
              border: "1px solid #aaa",
              borderRadius: "10px",
              opacity: 1.0,
              width: "300px",
              height: "100px",
              padding: "16px",
            }}
          >
            <StatsRow>
              <Statistic title="Booked" value={data.bookedFeatures.length} />
              <Statistic
                title="Not Booked"
                value={data.notBookedFeatures.length}
              />
              <Statistic
                title="Ratio"
                value={
                  (
                    (data.bookedFeatures.length /
                      (data.bookedFeatures.length +
                        data.notBookedFeatures.length)) *
                    100
                  ).toFixed(0) + "%"
                }
              />
            </StatsRow>
          </div>
        )}
        {sidebarContent && (
          <div
            style={{
              position: "fixed",
              left: "16px",
              top: "400px",
              backgroundColor: "#eeea",
              border: "1px solid #aaa",
              borderRadius: "10px",
              opacity: 1.0,
              width: "300px",
              height: "200px",
              padding: "16px",
            }}
            dangerouslySetInnerHTML={{ __html: sidebarContent }}
          ></div>
        )}
      </div>
    </div>
  );
}
