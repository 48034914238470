import { Button, message, Row, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components/macro";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import {
  InvoiceAuditTurn,
  Note,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import Spacer from "../../Spacer";
import { InvoiceAuditContext } from "../OpenInvoiceAuditsScreen";
import { suggestNextStepForInvoiceAudit } from "../suggestNextStepForInvoiceAudit";
import { AuditNotesModal } from "./AuditNotesModal";

interface InvoiceApprovalResolutionWidget {
  shipmentData: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

const WidgetTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const WidgetSubtitle = styled.div`
  font-size: 12px;
`;

interface InvoiceAuditTurnTagProps {
  turn: InvoiceAuditTurn | undefined;
  turnTime: string | undefined;
  frontConversationId: string | undefined;
  notes: Note[];
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

function InvoiceAuditCustomerTurnTag(props: InvoiceAuditTurnTagProps) {
  return (
    <AuditNotesModal
      shipmentId={props.shipmentId}
      onRefresh={props.onRefresh}
      turnTime={props.turnTime}
      turn={props.turn}
      notes={props.notes}
      turnContext={InvoiceAuditContext.Customer}
      frontConversationId={props.frontConversationId}
    />
  );
}

function InvoiceAuditCarrierTurnTag(props: InvoiceAuditTurnTagProps) {
  return (
    <AuditNotesModal
      shipmentId={props.shipmentId}
      onRefresh={props.onRefresh}
      turnTime={props.turnTime}
      turn={props.turn}
      notes={props.notes}
      turnContext={InvoiceAuditContext.Carrier}
      frontConversationId={props.frontConversationId}
    />
  );
}

export function InvoiceApprovalResolutionWidget(
  props: InvoiceApprovalResolutionWidget
) {
  const [closeLoading, setCloseLoading] = useState(false);
  const createShipmentApi = useShipmentsApi();

  function errorForClosingInvoiceAudit(): string | undefined {
    const nextStep = suggestNextStepForInvoiceAudit(
      props.shipmentData.shipment,
      props.shipmentData.billingTab.carrierInvoices,
      props.shipmentData.billingTab.carrierCreditNotes,
      props.shipmentData.billingTab.customerInvoices
    );
    if (!nextStep.allowedToCloseAudit) {
      return `You can't close the audit : ${nextStep.description}`;
    }
  }

  const error = errorForClosingInvoiceAudit();

  async function onCloseAudit() {
    if (error !== undefined) {
      message.warn(error);
      return;
    }

    setCloseLoading(true);
    try {
      const shipmentApi = await createShipmentApi();
      const shipmentId = props.shipmentData.shipmentId;

      await shipmentApi.toggleInvoiceAuditOpen({
        shipmentId,
        invoiceAuditOpen: false,
      });
      await props.onRefresh();

      message.success("Closed");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
    setCloseLoading(false);
  }

  if (!props.shipmentData.shipment.shipment.invoiceAuditOpen) {
    return <></>;
  }

  const nextStep = suggestNextStepForInvoiceAudit(
    props.shipmentData.shipment,
    props.shipmentData.billingTab.carrierInvoices,
    props.shipmentData.billingTab.carrierCreditNotes,
    props.shipmentData.billingTab.customerInvoices
  );
  return (
    <Row
      style={{
        padding: "16px",
        backgroundColor: nextStep.requiresAction
          ? Colors.LightRed
          : Colors.LightOrange,
      }}
    >
      <HorizontalStack align="spread" style={{ width: "100%" }}>
        <HorizontalStack>
          <Stack align="left">
            <WidgetTitle>Invoice Audit Open</WidgetTitle>
            <WidgetSubtitle>
              Audit Opened :{" "}
              {moment(
                props.shipmentData.shipment.shipment.invoiceAuditFirstOpenedDate
              ).format("dddd, MMM Do YYYY")}
            </WidgetSubtitle>
            <WidgetSubtitle>
              {nextStep.requiresAction
                ? "Requires Action"
                : "No action required"}{" "}
              : {nextStep.description}
            </WidgetSubtitle>
          </Stack>
          <Spacer width={64} />
          <Stack align="left">
            <WidgetSubtitle>Customer Turn</WidgetSubtitle>
            <InvoiceAuditCustomerTurnTag
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
              turn={
                props.shipmentData.shipment.shipment.invoiceAuditCustomerTurn
              }
              turnTime={
                props.shipmentData.shipment.shipment
                  .invoiceAuditCustomerTurnTime
              }
              frontConversationId={
                props.shipmentData.shipment.shipment
                  .invoiceAuditCustomerFrontConversationId
              }
              notes={props.shipmentData.notesTab.notes}
            />
            <Spacer height={2} />
            <WidgetSubtitle>Carrier Turn</WidgetSubtitle>
            <InvoiceAuditCarrierTurnTag
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
              turn={
                props.shipmentData.shipment.shipment.invoiceAuditCarrierTurn
              }
              turnTime={
                props.shipmentData.shipment.shipment.invoiceAuditCarrierTurnTime
              }
              frontConversationId={
                props.shipmentData.shipment.shipment
                  .invoiceAuditCarrierFrontConversationId
              }
              notes={props.shipmentData.notesTab.notes}
            />
          </Stack>
        </HorizontalStack>

        <ButtonRow>
          <Stack>
            <Tooltip title={error}>
              <Button
                disabled={error !== undefined}
                onClick={onCloseAudit}
                type="primary"
                loading={closeLoading}
              >
                Close Audit
              </Button>
            </Tooltip>
          </Stack>
        </ButtonRow>
      </HorizontalStack>
    </Row>
  );
}
