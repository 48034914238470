import { Tag } from "antd";
import InvoiceAuditProblemTypes from "../../Fixtures/InvoiceAuditProblemTypes.json";
import {
  InvoiceAuditProblem,
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemType,
} from "../../generated-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import { capitalizeFirstLetter } from "../../Helpers/capitalizeFirstLetter";
import { isNotBlank } from "../../Helpers/isNotBlank";
import { describeLocationType } from "../../Helpers/locationTypes";

export function describeInvoiceAuditConfidence(
  confidence: InvoiceAuditProblemConfidence,
  fault: InvoiceAuditProblemFault
): string {
  switch (fault) {
    case InvoiceAuditProblemFault.Carrier: {
      switch (confidence) {
        case InvoiceAuditProblemConfidence.High:
          return "Carrier has admitted fault";
        case InvoiceAuditProblemConfidence.Medium:
          return "We have evidence the carrier is at fault";
        case InvoiceAuditProblemConfidence.Low:
          return "No evidence, but suspect the carrier is at fault";
        case undefined:
          return "gray";
        default:
          assertNever(confidence);
      }
      break;
    }

    case InvoiceAuditProblemFault.Customer: {
      switch (confidence) {
        case InvoiceAuditProblemConfidence.High:
          return "Sufficient Evidence for Additional Charge";
        case InvoiceAuditProblemConfidence.Medium:
          return "Possible Additional Charge, but needs more evidence";
        case InvoiceAuditProblemConfidence.Low:
          return "Gather more evidence";
        case undefined:
          return "gray";
        default:
          assertNever(confidence);
      }
      break;
    }

    case InvoiceAuditProblemFault.Freightsimple: {
      switch (confidence) {
        case InvoiceAuditProblemConfidence.High:
          return "Definitely us";
        case InvoiceAuditProblemConfidence.Medium:
          return "Probably us";
        case InvoiceAuditProblemConfidence.Low:
          return "Maybe us?";
        case undefined:
          return "gray";
        default:
          assertNever(confidence);
      }
      break;
    }

    case InvoiceAuditProblemFault.Unknown: {
      return "";
    }

    default:
      assertNever(fault);
  }
}

export function describeInvoiceAuditProblemType(
  iap: InvoiceAuditProblem
): string {
  const type = iap.type!!;
  const description = InvoiceAuditProblemTypes.find((o) => o.type === type)!!
    .name;
  if (
    isNotBlank(iap.correctedLocationType) &&
    (type === InvoiceAuditProblemType.PickupLocationTypeChange ||
      type === InvoiceAuditProblemType.DeliveryLocationTypeChange)
  ) {
    return `${description} (${describeLocationType(
      iap.correctedLocationType!!
    )})`;
  } else {
    return description;
  }
}

export function emojiForInvoiceAuditProblemFault(
  fault: InvoiceAuditProblemFault
): string {
  switch (fault) {
    case InvoiceAuditProblemFault.Carrier:
      return "🚚";
    case InvoiceAuditProblemFault.Customer:
      return "🤦🏻";
    case InvoiceAuditProblemFault.Freightsimple:
      return "🏢";
    case InvoiceAuditProblemFault.Unknown:
      return "❓";
    default:
      assertNever(fault);
  }
}

interface InvoiceAuditProblemConfidenceTagProps {
  confidence: InvoiceAuditProblemConfidence;
}

export function colorForInvoiceAuditProblemConfidence(
  confidence: InvoiceAuditProblemConfidence | undefined
): string {
  switch (confidence) {
    case InvoiceAuditProblemConfidence.High:
      return "#444";
    case InvoiceAuditProblemConfidence.Medium:
      return "#999";
    case InvoiceAuditProblemConfidence.Low:
      return "#bbb";
    case undefined:
      return "#eee";
    default:
      assertNever(confidence);
  }
}

export function colorForInvoiceAuditProblemResolutionState(
  resolutionState: InvoiceAuditProblemResolutionState | undefined
): string {
  switch (resolutionState) {
    case InvoiceAuditProblemResolutionState.Resolved:
      return "green";
    case InvoiceAuditProblemResolutionState.Pending:
      return "yellow";
    case InvoiceAuditProblemResolutionState.Unresolved:
      return "red";
    case undefined:
      return "gray";
    default:
      assertNever(resolutionState);
  }
}

export function InvoiceAuditProblemConfidenceTag(
  props: InvoiceAuditProblemConfidenceTagProps
) {
  return (
    <Tag color={colorForInvoiceAuditProblemConfidence(props.confidence)}>
      {capitalizeFirstLetter(props.confidence ?? "Unknown")}
    </Tag>
  );
}

export function isInvoiceAuditProblemTypeAllowedForAdditionalCharge(
  type: InvoiceAuditProblemType
): boolean {
  return InvoiceAuditProblemTypes.find((o) => o.type === type)!!
    .allowedForAdditionalCharges;
}
