import { ArrowRightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { CSSProperties, ReactNode } from "react";
import { isEmpty } from "../../Helpers/isEmpty";
import { objectDifferences } from "../../Helpers/objectDifferences";
import { spaceOutCamelCase } from "../../Helpers/spaceOutCamelCase";
import Colors from "../Colors";
import { NoteDetailsBox } from "./NoteDetailsBox";

interface ModifiedGenericNoteProps<L, R> {
  beforeData: L;
  afterData: R;
  ignoreFields?: string[];
  fields?: string[] | undefined;
  boxStyle?: CSSProperties;
  title?: ReactNode;
  customTextRender?: (text: any, key: string) => ReactNode | undefined;
}

export function ModifiedGenericNote<L extends object, R extends object>({
  beforeData,
  afterData,
  ignoreFields = [],
  fields = undefined,
  boxStyle,
  title,
  customTextRender,
}: ModifiedGenericNoteProps<L, R>) {
  const differencesResponse = objectDifferences(beforeData, afterData);

  let differences = differencesResponse;

  if (fields) {
    differences = differences.filter((d) => fields.includes(d.key));
  }

  differences = differences.filter((d) => !ignoreFields.includes(d.key));

  if (differences.length === 0) {
    return <></>;
  }

  function text(value: any, key: string): ReactNode {
    let render: ReactNode = String(value);

    if (isEmpty(value)) {
      render = "Undefined";
    }

    if (typeof value === "boolean") {
      render = value ? "Yes" : "No";
    }

    if (Array.isArray(value)) {
      render = value.join(", ");
    }

    if (customTextRender) {
      const result = customTextRender(value, key);
      if (result) {
        render = result;
      }
    }

    return render;
  }

  return (
    <NoteDetailsBox style={boxStyle}>
      {title ? title : <Typography.Title level={3}>Changes</Typography.Title>}
      {differences.map((d) => (
        <NoteDetailsBox.LeftRightDetails
          key={d.key}
          left={spaceOutCamelCase(d.key)}
          right={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: Colors.Gray[400] }}>
                {text(d.left, d.key)}
              </span>
              <ArrowRightOutlined
                style={{
                  fontSize: "10px",
                  marginRight: "8px",
                  marginLeft: "8px",
                }}
              />
              <span style={{ color: Colors.GreenT[600] }}>
                {text(d.right, d.key)}
              </span>
            </div>
          }
        />
      ))}
    </NoteDetailsBox>
  );
}
