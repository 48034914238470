/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AdditionalChargeWaivedCause = {
    Bug: 'bug',
    Grace: 'grace',
    ChallengedWithCarrier: 'challenged_with_carrier'
} as const;
export type AdditionalChargeWaivedCause = typeof AdditionalChargeWaivedCause[keyof typeof AdditionalChargeWaivedCause];


export function instanceOfAdditionalChargeWaivedCause(value: any): boolean {
    return Object.values(AdditionalChargeWaivedCause).includes(value);
}

export function AdditionalChargeWaivedCauseFromJSON(json: any): AdditionalChargeWaivedCause {
    return AdditionalChargeWaivedCauseFromJSONTyped(json, false);
}

export function AdditionalChargeWaivedCauseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalChargeWaivedCause {
    return json as AdditionalChargeWaivedCause;
}

export function AdditionalChargeWaivedCauseToJSON(value?: AdditionalChargeWaivedCause | null): any {
    return value as any;
}

