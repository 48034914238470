/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LookupResultType = {
    Shipment: 'Shipment',
    Company: 'Company'
} as const;
export type LookupResultType = typeof LookupResultType[keyof typeof LookupResultType];


export function instanceOfLookupResultType(value: any): boolean {
    return Object.values(LookupResultType).includes(value);
}

export function LookupResultTypeFromJSON(json: any): LookupResultType {
    return LookupResultTypeFromJSONTyped(json, false);
}

export function LookupResultTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookupResultType {
    return json as LookupResultType;
}

export function LookupResultTypeToJSON(value?: LookupResultType | null): any {
    return value as any;
}

