import { Select } from "antd";
interface AdminsDropdownProps {
  email: string | undefined;
  setEmail: (_: string | undefined) => void;
}

const salesReps = [
  { name: "Natasha", email: "natasha@freightsimple.com" },
  { name: "Chris", email: "chris@freightsimple.com" },
  { name: "Lucia", email: "lucia@freightsimple.com" },
  { name: "Amy", email: "amy@freightsimple.com" },
];

export function lookupSalesRepNameByEmail(email: string): string {
  return salesReps.find((sr) => sr.email === email)!!.name;
}

export function getSalesRepsData() {
  return salesReps;
}

export function AdminsDropdown(props: AdminsDropdownProps) {
  return (
    <Select
      value={props.email}
      onChange={props.setEmail}
      style={{ width: "150px" }}
    >
      {salesReps.map(function (sr) {
        return <Select.Option value={sr.email}>{sr.name}</Select.Option>;
      })}
    </Select>
  );
}
