/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Note } from './Note';
import {
    NoteFromJSON,
    NoteFromJSONTyped,
    NoteToJSON,
} from './Note';

/**
 * 
 * @export
 * @interface NotesTabData
 */
export interface NotesTabData {
    /**
     * 
     * @type {Array<Note>}
     * @memberof NotesTabData
     */
    notes: Array<Note>;
}

/**
 * Check if a given object implements the NotesTabData interface.
 */
export function instanceOfNotesTabData(value: object): boolean {
    if (!('notes' in value)) return false;
    return true;
}

export function NotesTabDataFromJSON(json: any): NotesTabData {
    return NotesTabDataFromJSONTyped(json, false);
}

export function NotesTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotesTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'notes': ((json['notes'] as Array<any>).map(NoteFromJSON)),
    };
}

export function NotesTabDataToJSON(value?: NotesTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notes': ((value['notes'] as Array<any>).map(NoteToJSON)),
    };
}

