import { DocumentType } from "../generated-openapi-client";
import {
  EnumDropdown,
  OptionalEnumDropdown,
} from "../Screens/ViewShipmentScreenComponents/EnumDropdown";

export function describeDocumentType(
  documentType: DocumentType
): string | undefined {
  // Certain types are old, but removing from Postgres is annoying, so just
  // hiding here by returning undefined + we can easily bring them back
  switch (documentType) {
    case DocumentType.BillOfLading:
      return "Generated Bill of lading";
    case DocumentType.CargoClaimsForm:
      return "Cargo Claims Form";
    case DocumentType.CarrierScannedBillOfLading:
      return "Carrier Scanned Bill of Lading";
    case DocumentType.CarrierWeightReport:
      return "Carrier Weight Report";
    case DocumentType.CertificateOfOrigin:
      return undefined;
    case DocumentType.CommercialInvoice:
      return undefined;
    case DocumentType.CustomsDocument:
      return "Customs Document";
    case DocumentType.DangerousGoodsForm:
      return undefined;
    case DocumentType.ExportLicense:
      return undefined;
    case DocumentType.Image:
      return "Image";
    case DocumentType.InsuranceCertificate:
      return "Insurance Certificate";
    case DocumentType.PackingList:
      return undefined;
    case DocumentType.ProofOfDelivery:
      return "Proof of Delivery";
    case DocumentType.ShipperPhoto:
      return "Shipper Photo";
    case DocumentType.PurchaseOrder:
      return "Purchase Order";
    case DocumentType.PickupPackage:
      return "Pickup Package";
    case DocumentType.Unknown:
      return "Unknown";
    case DocumentType.ClaimsOriginalInvoice:
      return "Claim: Original Invoice";
    case DocumentType.ClaimsPhoto:
      return "Claim: Photo";
    case DocumentType.ClaimsOtherDocument:
      return "Claim: Other Document";
    case DocumentType.InterlineBillOfLading:
      return "Interline Bill of Lading";
    case DocumentType.EmailAttachment:
      return "Email Attachment";
    case DocumentType.CustomerEmailAttachment:
      return "Customer Email Attachment";
    case DocumentType.CarrierEmailAttachment:
      return "Carrier Email Attachment";
    case DocumentType.SentEmailAttachment:
      return "Sent Email Attachment";
  }
}

export const DocumentTypeDropdown = OptionalEnumDropdown<
  DocumentType,
  typeof DocumentType
>(DocumentType, "No document type selected", describeDocumentType);

export const NonOptionalDocumentTypeDropdown = EnumDropdown<
  DocumentType,
  typeof DocumentType
>(DocumentType, describeDocumentType);
