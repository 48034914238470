import { getEnvironment } from "../App";
import { Environment } from "./environment";

export function dashboardBaseUrl(): string {
  let url = "http://localhost:3000";
  const current = getEnvironment();
  switch (current) {
    case Environment.Local:
      url = "http://localhost:3000";
      break;
    case Environment.Preprod:
      url = "https://dashboard.fspreprod.com";
      break;
    case Environment.Demo:
      url = "https://dashboard.freightsimpledemo.com";
      break;
    case Environment.Production:
      url = "https://dashboard.freightsimple.com";
      break;
    default:
      throw new Error("No environment match");
  }
  return url;
}
