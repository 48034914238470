/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlingUnitType } from './HandlingUnitType';
import {
    HandlingUnitTypeFromJSON,
    HandlingUnitTypeFromJSONTyped,
    HandlingUnitTypeToJSON,
} from './HandlingUnitType';

/**
 * 
 * @export
 * @interface DimensionReportLineItem
 */
export interface DimensionReportLineItem {
    /**
     * 
     * @type {string}
     * @memberof DimensionReportLineItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof DimensionReportLineItem
     */
    numberHandlingUnits: number;
    /**
     * 
     * @type {HandlingUnitType}
     * @memberof DimensionReportLineItem
     */
    handlingUnitType: HandlingUnitType;
    /**
     * 
     * @type {number}
     * @memberof DimensionReportLineItem
     */
    totalWeight: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionReportLineItem
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionReportLineItem
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionReportLineItem
     */
    height: number;
}

/**
 * Check if a given object implements the DimensionReportLineItem interface.
 */
export function instanceOfDimensionReportLineItem(value: object): boolean {
    if (!('description' in value)) return false;
    if (!('numberHandlingUnits' in value)) return false;
    if (!('handlingUnitType' in value)) return false;
    if (!('totalWeight' in value)) return false;
    if (!('length' in value)) return false;
    if (!('width' in value)) return false;
    if (!('height' in value)) return false;
    return true;
}

export function DimensionReportLineItemFromJSON(json: any): DimensionReportLineItem {
    return DimensionReportLineItemFromJSONTyped(json, false);
}

export function DimensionReportLineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): DimensionReportLineItem {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'numberHandlingUnits': json['numberHandlingUnits'],
        'handlingUnitType': HandlingUnitTypeFromJSON(json['handlingUnitType']),
        'totalWeight': json['totalWeight'],
        'length': json['length'],
        'width': json['width'],
        'height': json['height'],
    };
}

export function DimensionReportLineItemToJSON(value?: DimensionReportLineItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'numberHandlingUnits': value['numberHandlingUnits'],
        'handlingUnitType': HandlingUnitTypeToJSON(value['handlingUnitType']),
        'totalWeight': value['totalWeight'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
    };
}

