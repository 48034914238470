/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyShipment } from './CompanyShipment';
import {
    CompanyShipmentFromJSON,
    CompanyShipmentFromJSONTyped,
    CompanyShipmentToJSON,
} from './CompanyShipment';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';

/**
 * 
 * @export
 * @interface GetShipmentsByIdsResponse
 */
export interface GetShipmentsByIdsResponse {
    /**
     * 
     * @type {Array<CompanyShipment>}
     * @memberof GetShipmentsByIdsResponse
     */
    quotedShipments: Array<CompanyShipment>;
    /**
     * 
     * @type {Array<CompanyShipmentQuote>}
     * @memberof GetShipmentsByIdsResponse
     */
    bookedShipments: Array<CompanyShipmentQuote>;
}

/**
 * Check if a given object implements the GetShipmentsByIdsResponse interface.
 */
export function instanceOfGetShipmentsByIdsResponse(value: object): boolean {
    if (!('quotedShipments' in value)) return false;
    if (!('bookedShipments' in value)) return false;
    return true;
}

export function GetShipmentsByIdsResponseFromJSON(json: any): GetShipmentsByIdsResponse {
    return GetShipmentsByIdsResponseFromJSONTyped(json, false);
}

export function GetShipmentsByIdsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetShipmentsByIdsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'quotedShipments': ((json['quotedShipments'] as Array<any>).map(CompanyShipmentFromJSON)),
        'bookedShipments': ((json['bookedShipments'] as Array<any>).map(CompanyShipmentQuoteFromJSON)),
    };
}

export function GetShipmentsByIdsResponseToJSON(value?: GetShipmentsByIdsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quotedShipments': ((value['quotedShipments'] as Array<any>).map(CompanyShipmentToJSON)),
        'bookedShipments': ((value['bookedShipments'] as Array<any>).map(CompanyShipmentQuoteToJSON)),
    };
}

