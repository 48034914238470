/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentsServiceSwitchQuoteInvoiceAdjustmentType } from './PaymentsServiceSwitchQuoteInvoiceAdjustmentType';
import {
    PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeFromJSON,
    PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeFromJSONTyped,
    PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeToJSON,
} from './PaymentsServiceSwitchQuoteInvoiceAdjustmentType';

/**
 * 
 * @export
 * @interface PaymentsServiceSwitchQuotePaymentPlan
 */
export interface PaymentsServiceSwitchQuotePaymentPlan {
    /**
     * 
     * @type {string}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    originalQuoteId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    newQuoteId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    newCarrierDisplayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    canSuccessfullyHandle: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    message: string;
    /**
     * 
     * @type {PaymentsServiceSwitchQuoteInvoiceAdjustmentType}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    invoiceAdjustmentType: PaymentsServiceSwitchQuoteInvoiceAdjustmentType;
    /**
     * 
     * @type {number}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentsServiceSwitchQuotePaymentPlan
     */
    shipmentInvoiceId?: string;
}

/**
 * Check if a given object implements the PaymentsServiceSwitchQuotePaymentPlan interface.
 */
export function instanceOfPaymentsServiceSwitchQuotePaymentPlan(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('originalQuoteId' in value)) return false;
    if (!('newQuoteId' in value)) return false;
    if (!('newCarrierDisplayName' in value)) return false;
    if (!('canSuccessfullyHandle' in value)) return false;
    if (!('message' in value)) return false;
    if (!('invoiceAdjustmentType' in value)) return false;
    return true;
}

export function PaymentsServiceSwitchQuotePaymentPlanFromJSON(json: any): PaymentsServiceSwitchQuotePaymentPlan {
    return PaymentsServiceSwitchQuotePaymentPlanFromJSONTyped(json, false);
}

export function PaymentsServiceSwitchQuotePaymentPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentsServiceSwitchQuotePaymentPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'originalQuoteId': json['originalQuoteId'],
        'newQuoteId': json['newQuoteId'],
        'newCarrierDisplayName': json['newCarrierDisplayName'],
        'canSuccessfullyHandle': json['canSuccessfullyHandle'],
        'message': json['message'],
        'invoiceAdjustmentType': PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeFromJSON(json['invoiceAdjustmentType']),
        'amount': json['amount'] == null ? undefined : json['amount'],
        'shipmentInvoiceId': json['shipmentInvoiceId'] == null ? undefined : json['shipmentInvoiceId'],
    };
}

export function PaymentsServiceSwitchQuotePaymentPlanToJSON(value?: PaymentsServiceSwitchQuotePaymentPlan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'originalQuoteId': value['originalQuoteId'],
        'newQuoteId': value['newQuoteId'],
        'newCarrierDisplayName': value['newCarrierDisplayName'],
        'canSuccessfullyHandle': value['canSuccessfullyHandle'],
        'message': value['message'],
        'invoiceAdjustmentType': PaymentsServiceSwitchQuoteInvoiceAdjustmentTypeToJSON(value['invoiceAdjustmentType']),
        'amount': value['amount'],
        'shipmentInvoiceId': value['shipmentInvoiceId'],
    };
}

