/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InterlineContext = {
    Advance: 'Advance',
    Beyond: 'Beyond'
} as const;
export type InterlineContext = typeof InterlineContext[keyof typeof InterlineContext];


export function instanceOfInterlineContext(value: any): boolean {
    return Object.values(InterlineContext).includes(value);
}

export function InterlineContextFromJSON(json: any): InterlineContext {
    return InterlineContextFromJSONTyped(json, false);
}

export function InterlineContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterlineContext {
    return json as InterlineContext;
}

export function InterlineContextToJSON(value?: InterlineContext | null): any {
    return value as any;
}

