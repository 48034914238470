/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Note,
} from '../models/index';
import {
    NoteFromJSON,
    NoteToJSON,
} from '../models/index';

export interface GetLogsForAdminRequest {
    adminEmail: string;
}

/**
 * 
 */
export class AdminLogsApi extends runtime.BaseAPI {

    /**
     */
    async getLogsForAdminRaw(requestParameters: GetLogsForAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Note>>> {
        if (requestParameters['adminEmail'] == null) {
            throw new runtime.RequiredError(
                'adminEmail',
                'Required parameter "adminEmail" was null or undefined when calling getLogsForAdmin().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['adminEmail'] != null) {
            queryParameters['adminEmail'] = requestParameters['adminEmail'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/admin-logs/logs-for-admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NoteFromJSON));
    }

    /**
     */
    async getLogsForAdmin(requestParameters: GetLogsForAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Note>> {
        const response = await this.getLogsForAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
