/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompaniesReportInvoiceIssueRow } from './CompaniesReportInvoiceIssueRow';
import {
    CompaniesReportInvoiceIssueRowFromJSON,
    CompaniesReportInvoiceIssueRowFromJSONTyped,
    CompaniesReportInvoiceIssueRowToJSON,
} from './CompaniesReportInvoiceIssueRow';

/**
 * 
 * @export
 * @interface CompaniesReport
 */
export interface CompaniesReport {
    /**
     * 
     * @type {Array<CompaniesReportInvoiceIssueRow>}
     * @memberof CompaniesReport
     */
    companiesWithInvoiceIssues: Array<CompaniesReportInvoiceIssueRow>;
}

/**
 * Check if a given object implements the CompaniesReport interface.
 */
export function instanceOfCompaniesReport(value: object): boolean {
    if (!('companiesWithInvoiceIssues' in value)) return false;
    return true;
}

export function CompaniesReportFromJSON(json: any): CompaniesReport {
    return CompaniesReportFromJSONTyped(json, false);
}

export function CompaniesReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompaniesReport {
    if (json == null) {
        return json;
    }
    return {
        
        'companiesWithInvoiceIssues': ((json['companiesWithInvoiceIssues'] as Array<any>).map(CompaniesReportInvoiceIssueRowFromJSON)),
    };
}

export function CompaniesReportToJSON(value?: CompaniesReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companiesWithInvoiceIssues': ((value['companiesWithInvoiceIssues'] as Array<any>).map(CompaniesReportInvoiceIssueRowToJSON)),
    };
}

