import { InputNumber, message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  CarrierInvoice,
  CarrierInvoiceState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../../generated-openapi-client/models/SupportAgent";
import { ExtraTextButton } from "./ExtraTextButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

interface WeightReportSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  carrierInvoice: CarrierInvoice;
}

export function WeightReportSupportEmailButton(
  props: WeightReportSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const carrierInvoice = props.carrierInvoice;
  const carrierInvoiceId = carrierInvoice.carrierInvoiceId!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [reportedWeight, setReportedWeight] = useState(0);
  const [extraText, setExtraText] = useState("");

  if (carrierInvoice.invoiceState === CarrierInvoiceState.Voided) {
    return <></>;
  }

  const hash = JSON.stringify({
    carrierInvoiceId,
    reportedWeight,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewWeightReportEmail({
        shipmentId,
        carrierInvoiceId,
        reportedWeight,
        supportAgent,
        extraText,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    if (reportedWeight === 0) {
      message.warn(`Reported weight is zero`);
      return false;
    }

    if (attachmentDocumentIds.length === 0) {
      message.warn(`No attachments. Please attach weight report`);
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendWeightReportEmail({
        shipmentId,
        carrierInvoiceId,
        reportedWeight,
        supportAgent,
        attachmentDocumentIds,
        extraText,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Weight Report Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Reported Weight">
            <InputNumber value={reportedWeight} onChange={setReportedWeight} />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}
