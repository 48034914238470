/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import type { LatitudeLongitude } from './LatitudeLongitude';
import {
    LatitudeLongitudeFromJSON,
    LatitudeLongitudeFromJSONTyped,
    LatitudeLongitudeToJSON,
} from './LatitudeLongitude';

/**
 * 
 * @export
 * @interface RunQuoteInputLocation
 */
export interface RunQuoteInputLocation {
    /**
     * 
     * @type {string}
     * @memberof RunQuoteInputLocation
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RunQuoteInputLocation
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RunQuoteInputLocation
     */
    stateOrProvinceCode?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof RunQuoteInputLocation
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {LatitudeLongitude}
     * @memberof RunQuoteInputLocation
     */
    latitudeLongitude?: LatitudeLongitude;
}

/**
 * Check if a given object implements the RunQuoteInputLocation interface.
 */
export function instanceOfRunQuoteInputLocation(value: object): boolean {
    return true;
}

export function RunQuoteInputLocationFromJSON(json: any): RunQuoteInputLocation {
    return RunQuoteInputLocationFromJSONTyped(json, false);
}

export function RunQuoteInputLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunQuoteInputLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'city': json['city'] == null ? undefined : json['city'],
        'stateOrProvinceCode': json['stateOrProvinceCode'] == null ? undefined : json['stateOrProvinceCode'],
        'countryCode': json['countryCode'] == null ? undefined : CountryCodeFromJSON(json['countryCode']),
        'latitudeLongitude': json['latitudeLongitude'] == null ? undefined : LatitudeLongitudeFromJSON(json['latitudeLongitude']),
    };
}

export function RunQuoteInputLocationToJSON(value?: RunQuoteInputLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'postalCode': value['postalCode'],
        'city': value['city'],
        'stateOrProvinceCode': value['stateOrProvinceCode'],
        'countryCode': CountryCodeToJSON(value['countryCode']),
        'latitudeLongitude': LatitudeLongitudeToJSON(value['latitudeLongitude']),
    };
}

