import { ReactNode } from "react";
import { LeftRightDifference } from "../changes/LeftRightDifference";

import { ItemListComparator } from "./ItemListComparator";

interface SimpleListComparatorProps<T> {
  elementKey: string;
  index: number;
  component?: (value: T[keyof T]) => ReactNode;
  inBetween?: ReactNode;
}

/**
 * Wrapper for {@link ItemListComparator<T>}
 * @param elementKey key to access the property we want to compare in both elements
 * @param index position of the elements in the list
 * @param component a function to render a component in all cases
 *
 * @example
 * <SimpleItemListComparator
 *   elementKey="length"
 *   index={i}
 *   component={(value) => <>{value}"</>}
 * />
 */
export function SimpleItemListComparator<T>({
  elementKey,
  index,
  component = (value: T[keyof T]) => <>{value}</>,
  inBetween,
}: SimpleListComparatorProps<T>) {
  const key = elementKey as keyof T;

  return (
    <ItemListComparator
      onNotFound={(e: T) => <>{component(e[key])}</>}
      index={index}
      compareFn={(left: T, right: T) => left[key] == right[key]}
      onEquals={(e: T) => component(e[key])}
      onChanges={(left: T, right: T) => (
        <LeftRightDifference
          left={<>{component(left[key])}</>}
          right={<>{component(right[key])}</>}
          inBetween={inBetween}
        />
      )}
    />
  );
}
