import { Button, message, Modal } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { CarrierPickupNumberHistory } from "../../generated-openapi-client";
import { useDisplay } from "../../Hooks/useDisplay";

interface DeleteCarrierPickupNumberHistoryModalProps {
  carrierPickupNumberHistory: CarrierPickupNumberHistory;
  onRefresh: () => Promise<void>;
}

export function DeleteCarrierPickupNumberHistoryModal({
  carrierPickupNumberHistory,
  onRefresh,
}: DeleteCarrierPickupNumberHistoryModalProps) {
  const display = useDisplay();

  const createShipmentApi = useShipmentsApi();

  async function handleOk() {
    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.deleteCarrierPickupNumberHistory({
        carrierPickupNumberHistoryId:
          carrierPickupNumberHistory.carrierPickupNumberHistoryId!,
      });
      onRefresh();
      display.hide();
      message.success("Carrier Pickup Number History Line deleted");
    } catch (e) {
      message.error("Oops");
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Delete Carrier Pickup Number History"
        visible={display.status}
        onOk={handleOk}
        onCancel={display.hide}
        destroyOnClose={true}
        width={600}
      >
        Are you sure to delete this carrier pickup number history line?
      </Modal>
      <Button danger type="primary" onClick={display.show}>
        Delete
      </Button>
    </>
  );
}
