/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FreightClaim } from './FreightClaim';
import {
    FreightClaimFromJSON,
    FreightClaimFromJSONTyped,
    FreightClaimToJSON,
} from './FreightClaim';

/**
 * 
 * @export
 * @interface ClaimsTabData
 */
export interface ClaimsTabData {
    /**
     * 
     * @type {Array<FreightClaim>}
     * @memberof ClaimsTabData
     */
    claims: Array<FreightClaim>;
}

/**
 * Check if a given object implements the ClaimsTabData interface.
 */
export function instanceOfClaimsTabData(value: object): boolean {
    if (!('claims' in value)) return false;
    return true;
}

export function ClaimsTabDataFromJSON(json: any): ClaimsTabData {
    return ClaimsTabDataFromJSONTyped(json, false);
}

export function ClaimsTabDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimsTabData {
    if (json == null) {
        return json;
    }
    return {
        
        'claims': ((json['claims'] as Array<any>).map(FreightClaimFromJSON)),
    };
}

export function ClaimsTabDataToJSON(value?: ClaimsTabData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'claims': ((value['claims'] as Array<any>).map(FreightClaimToJSON)),
    };
}

