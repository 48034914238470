/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentMethodItem } from './PaymentMethodItem';
import {
    PaymentMethodItemFromJSON,
    PaymentMethodItemFromJSONTyped,
    PaymentMethodItemToJSON,
} from './PaymentMethodItem';

/**
 * 
 * @export
 * @interface PaymentMethodInformation
 */
export interface PaymentMethodInformation {
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodInformation
     */
    paymentMethodIsCurrent: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodInformation
     */
    currentPaymentMethod?: string;
    /**
     * 
     * @type {Array<PaymentMethodItem>}
     * @memberof PaymentMethodInformation
     */
    paymentMethods: Array<PaymentMethodItem>;
}

/**
 * Check if a given object implements the PaymentMethodInformation interface.
 */
export function instanceOfPaymentMethodInformation(value: object): boolean {
    if (!('paymentMethodIsCurrent' in value)) return false;
    if (!('paymentMethods' in value)) return false;
    return true;
}

export function PaymentMethodInformationFromJSON(json: any): PaymentMethodInformation {
    return PaymentMethodInformationFromJSONTyped(json, false);
}

export function PaymentMethodInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentMethodIsCurrent': json['paymentMethodIsCurrent'],
        'currentPaymentMethod': json['currentPaymentMethod'] == null ? undefined : json['currentPaymentMethod'],
        'paymentMethods': ((json['paymentMethods'] as Array<any>).map(PaymentMethodItemFromJSON)),
    };
}

export function PaymentMethodInformationToJSON(value?: PaymentMethodInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentMethodIsCurrent': value['paymentMethodIsCurrent'],
        'currentPaymentMethod': value['currentPaymentMethod'],
        'paymentMethods': ((value['paymentMethods'] as Array<any>).map(PaymentMethodItemToJSON)),
    };
}

