/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CarrierInvoiceState = {
    Voided: 'voided',
    Issued: 'issued',
    Approved: 'approved',
    PaymentPending: 'payment_pending',
    PaymentConfirmed: 'payment_confirmed'
} as const;
export type CarrierInvoiceState = typeof CarrierInvoiceState[keyof typeof CarrierInvoiceState];


export function instanceOfCarrierInvoiceState(value: any): boolean {
    return Object.values(CarrierInvoiceState).includes(value);
}

export function CarrierInvoiceStateFromJSON(json: any): CarrierInvoiceState {
    return CarrierInvoiceStateFromJSONTyped(json, false);
}

export function CarrierInvoiceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierInvoiceState {
    return json as CarrierInvoiceState;
}

export function CarrierInvoiceStateToJSON(value?: CarrierInvoiceState | null): any {
    return value as any;
}

