import { UrlMenuItem } from "../../Components/UrlMenuItem";
import {
  CustomerInvoice,
  CustomerInvoiceType,
} from "../../generated-openapi-client";

interface ViewCustomerInvoiceInXeroMenuItemProps {
  customerInvoice: CustomerInvoice;
}

export function ViewCustomerInvoiceInXeroMenuItem(
  props: ViewCustomerInvoiceInXeroMenuItemProps
) {
  const disabled = props.customerInvoice.xeroInvoiceId === undefined;
  const url =
    props.customerInvoice.invoiceType === CustomerInvoiceType.ShipmentCharge ||
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge
      ? `https://invoicing.xero.com/view/${props.customerInvoice.xeroInvoiceId}`
      : `https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=${props.customerInvoice.xeroInvoiceId}`;

  return <UrlMenuItem title="View in Xero" url={url} disabled={disabled} />;
}
