/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Signals,
} from '../models/index';
import {
    SignalsFromJSON,
    SignalsToJSON,
} from '../models/index';

export interface DeleteSignalRequest {
    signalName: string;
}

/**
 * 
 */
export class SignalsApi extends runtime.BaseAPI {

    /**
     */
    async deleteSignalRaw(requestParameters: DeleteSignalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['signalName'] == null) {
            throw new runtime.RequiredError(
                'signalName',
                'Required parameter "signalName" was null or undefined when calling deleteSignal().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['signalName'] != null) {
            queryParameters['signalName'] = requestParameters['signalName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/signals/delete-signal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSignal(requestParameters: DeleteSignalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSignalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getSignalsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Signals>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/signals/get-signals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignalsFromJSON));
    }

    /**
     */
    async getSignals(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Signals>> {
        const response = await this.getSignalsRaw(initOverrides);
        return await response.value();
    }

}
