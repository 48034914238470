/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CalendarDataResponse } from './CalendarDataResponse';
import {
    CalendarDataResponseFromJSON,
    CalendarDataResponseFromJSONTyped,
    CalendarDataResponseToJSON,
} from './CalendarDataResponse';
import type { TimeGraphData } from './TimeGraphData';
import {
    TimeGraphDataFromJSON,
    TimeGraphDataFromJSONTyped,
    TimeGraphDataToJSON,
} from './TimeGraphData';

/**
 * 
 * @export
 * @interface KpiGraphsReport
 */
export interface KpiGraphsReport {
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    significantCalls: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    revenue: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    shipmentsQuoted: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    shipmentsBooked: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    interestingRevenue: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    interestingShipmentsQuoted: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    interestingShipmentsBooked: CalendarDataResponse;
    /**
     * 
     * @type {TimeGraphData}
     * @memberof KpiGraphsReport
     */
    companiesQuoted: TimeGraphData;
    /**
     * 
     * @type {TimeGraphData}
     * @memberof KpiGraphsReport
     */
    companiesBooked: TimeGraphData;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    companiesFirstQuote: CalendarDataResponse;
    /**
     * 
     * @type {CalendarDataResponse}
     * @memberof KpiGraphsReport
     */
    companiesFirstBooking: CalendarDataResponse;
    /**
     * 
     * @type {TimeGraphData}
     * @memberof KpiGraphsReport
     */
    companiesNotFirstQuote: TimeGraphData;
    /**
     * 
     * @type {TimeGraphData}
     * @memberof KpiGraphsReport
     */
    companiesNotFirstBooking: TimeGraphData;
}

/**
 * Check if a given object implements the KpiGraphsReport interface.
 */
export function instanceOfKpiGraphsReport(value: object): boolean {
    if (!('significantCalls' in value)) return false;
    if (!('revenue' in value)) return false;
    if (!('shipmentsQuoted' in value)) return false;
    if (!('shipmentsBooked' in value)) return false;
    if (!('interestingRevenue' in value)) return false;
    if (!('interestingShipmentsQuoted' in value)) return false;
    if (!('interestingShipmentsBooked' in value)) return false;
    if (!('companiesQuoted' in value)) return false;
    if (!('companiesBooked' in value)) return false;
    if (!('companiesFirstQuote' in value)) return false;
    if (!('companiesFirstBooking' in value)) return false;
    if (!('companiesNotFirstQuote' in value)) return false;
    if (!('companiesNotFirstBooking' in value)) return false;
    return true;
}

export function KpiGraphsReportFromJSON(json: any): KpiGraphsReport {
    return KpiGraphsReportFromJSONTyped(json, false);
}

export function KpiGraphsReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiGraphsReport {
    if (json == null) {
        return json;
    }
    return {
        
        'significantCalls': CalendarDataResponseFromJSON(json['significantCalls']),
        'revenue': CalendarDataResponseFromJSON(json['revenue']),
        'shipmentsQuoted': CalendarDataResponseFromJSON(json['shipmentsQuoted']),
        'shipmentsBooked': CalendarDataResponseFromJSON(json['shipmentsBooked']),
        'interestingRevenue': CalendarDataResponseFromJSON(json['interestingRevenue']),
        'interestingShipmentsQuoted': CalendarDataResponseFromJSON(json['interestingShipmentsQuoted']),
        'interestingShipmentsBooked': CalendarDataResponseFromJSON(json['interestingShipmentsBooked']),
        'companiesQuoted': TimeGraphDataFromJSON(json['companiesQuoted']),
        'companiesBooked': TimeGraphDataFromJSON(json['companiesBooked']),
        'companiesFirstQuote': CalendarDataResponseFromJSON(json['companiesFirstQuote']),
        'companiesFirstBooking': CalendarDataResponseFromJSON(json['companiesFirstBooking']),
        'companiesNotFirstQuote': TimeGraphDataFromJSON(json['companiesNotFirstQuote']),
        'companiesNotFirstBooking': TimeGraphDataFromJSON(json['companiesNotFirstBooking']),
    };
}

export function KpiGraphsReportToJSON(value?: KpiGraphsReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'significantCalls': CalendarDataResponseToJSON(value['significantCalls']),
        'revenue': CalendarDataResponseToJSON(value['revenue']),
        'shipmentsQuoted': CalendarDataResponseToJSON(value['shipmentsQuoted']),
        'shipmentsBooked': CalendarDataResponseToJSON(value['shipmentsBooked']),
        'interestingRevenue': CalendarDataResponseToJSON(value['interestingRevenue']),
        'interestingShipmentsQuoted': CalendarDataResponseToJSON(value['interestingShipmentsQuoted']),
        'interestingShipmentsBooked': CalendarDataResponseToJSON(value['interestingShipmentsBooked']),
        'companiesQuoted': TimeGraphDataToJSON(value['companiesQuoted']),
        'companiesBooked': TimeGraphDataToJSON(value['companiesBooked']),
        'companiesFirstQuote': CalendarDataResponseToJSON(value['companiesFirstQuote']),
        'companiesFirstBooking': CalendarDataResponseToJSON(value['companiesFirstBooking']),
        'companiesNotFirstQuote': TimeGraphDataToJSON(value['companiesNotFirstQuote']),
        'companiesNotFirstBooking': TimeGraphDataToJSON(value['companiesNotFirstBooking']),
    };
}

