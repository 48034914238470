import { message } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import {
  CarrierCreditNote,
  CarrierCreditNoteState,
} from "../../generated-openapi-client";

interface MarkCarrierCreditNoteRefundRequestedMenuItemProps {
  carrierCreditNote: CarrierCreditNote;
  onRefresh: () => Promise<void>;
}

export function MarkCarrierCreditNoteRefundRequestedMenuItem(
  props: MarkCarrierCreditNoteRefundRequestedMenuItemProps
) {
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const refundRequested = props.carrierCreditNote.refundRequested!!;

  async function onClick() {
    try {
      const carrierCreditNoteId = props.carrierCreditNote.carrierCreditNoteId!!;

      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markCarrierCreditNoteRefundRequested({
        carrierCreditNoteId,
        refundRequested: !refundRequested,
      });
      if (!refundRequested) {
        message.success("Marked Refund Requested");
      } else {
        message.success("Marked Refund Not Requested");
      }

      await props.onRefresh();
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
  }

  if (
    props.carrierCreditNote.carrierCreditNoteState !==
    CarrierCreditNoteState.Approved
  ) {
    return <></>;
  }

  if (refundRequested) {
    return <MenuItem onClick={onClick}>Set Refund Not Requested</MenuItem>;
  } else {
    return <MenuItem onClick={onClick}>Set Refund Requested</MenuItem>;
  }
}
