/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CarrierPaymentStrategy = {
    ProcessPaymentViaCode: 'ProcessPaymentViaCode',
    EmailAskingToChargeCardOnFile: 'EmailAskingToChargeCardOnFile',
    Eft: 'EFT',
    Interac: 'Interac',
    CarrierPaymentPortal: 'CarrierPaymentPortal',
    PayByCheque: 'PayByCheque',
    Other: 'Other'
} as const;
export type CarrierPaymentStrategy = typeof CarrierPaymentStrategy[keyof typeof CarrierPaymentStrategy];


export function instanceOfCarrierPaymentStrategy(value: any): boolean {
    return Object.values(CarrierPaymentStrategy).includes(value);
}

export function CarrierPaymentStrategyFromJSON(json: any): CarrierPaymentStrategy {
    return CarrierPaymentStrategyFromJSONTyped(json, false);
}

export function CarrierPaymentStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierPaymentStrategy {
    return json as CarrierPaymentStrategy;
}

export function CarrierPaymentStrategyToJSON(value?: CarrierPaymentStrategy | null): any {
    return value as any;
}

