import { LinkOutlined } from "@ant-design/icons";
import { Menu } from "antd";

interface UrlMenuItemProps {
  url: string;
  title: string;
  disabled?: boolean;
}

export function UrlMenuItem(props: UrlMenuItemProps) {
  return (
    <Menu.Item
      disabled={props.disabled}
      key={props.url}
      icon={<LinkOutlined />}
    >
      <a target="_blank" href={props.url} rel="noreferrer">
        {props.title}
      </a>
    </Menu.Item>
  );
}
