/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompaniesBookedVsQuotedReportLine } from './CompaniesBookedVsQuotedReportLine';
import {
    CompaniesBookedVsQuotedReportLineFromJSON,
    CompaniesBookedVsQuotedReportLineFromJSONTyped,
    CompaniesBookedVsQuotedReportLineToJSON,
} from './CompaniesBookedVsQuotedReportLine';

/**
 * 
 * @export
 * @interface CompaniesBookedVsQuotedReport
 */
export interface CompaniesBookedVsQuotedReport {
    /**
     * 
     * @type {Array<CompaniesBookedVsQuotedReportLine>}
     * @memberof CompaniesBookedVsQuotedReport
     */
    quoted: Array<CompaniesBookedVsQuotedReportLine>;
    /**
     * 
     * @type {Array<CompaniesBookedVsQuotedReportLine>}
     * @memberof CompaniesBookedVsQuotedReport
     */
    booked: Array<CompaniesBookedVsQuotedReportLine>;
}

/**
 * Check if a given object implements the CompaniesBookedVsQuotedReport interface.
 */
export function instanceOfCompaniesBookedVsQuotedReport(value: object): boolean {
    if (!('quoted' in value)) return false;
    if (!('booked' in value)) return false;
    return true;
}

export function CompaniesBookedVsQuotedReportFromJSON(json: any): CompaniesBookedVsQuotedReport {
    return CompaniesBookedVsQuotedReportFromJSONTyped(json, false);
}

export function CompaniesBookedVsQuotedReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompaniesBookedVsQuotedReport {
    if (json == null) {
        return json;
    }
    return {
        
        'quoted': ((json['quoted'] as Array<any>).map(CompaniesBookedVsQuotedReportLineFromJSON)),
        'booked': ((json['booked'] as Array<any>).map(CompaniesBookedVsQuotedReportLineFromJSON)),
    };
}

export function CompaniesBookedVsQuotedReportToJSON(value?: CompaniesBookedVsQuotedReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quoted': ((value['quoted'] as Array<any>).map(CompaniesBookedVsQuotedReportLineToJSON)),
        'booked': ((value['booked'] as Array<any>).map(CompaniesBookedVsQuotedReportLineToJSON)),
    };
}

