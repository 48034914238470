/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';

/**
 * 
 * @export
 * @interface SendSalesEmailInput
 */
export interface SendSalesEmailInput {
    /**
     * 
     * @type {string}
     * @memberof SendSalesEmailInput
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof SendSalesEmailInput
     */
    salesContactId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendSalesEmailInput
     */
    emailAddresses: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendSalesEmailInput
     */
    ccEmailAddresses: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendSalesEmailInput
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SendSalesEmailInput
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof SendSalesEmailInput
     */
    unprocessedBody: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof SendSalesEmailInput
     */
    attachments: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof SendSalesEmailInput
     */
    emailIdentifier: string;
}

/**
 * Check if a given object implements the SendSalesEmailInput interface.
 */
export function instanceOfSendSalesEmailInput(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('salesContactId' in value)) return false;
    if (!('emailAddresses' in value)) return false;
    if (!('ccEmailAddresses' in value)) return false;
    if (!('subject' in value)) return false;
    if (!('body' in value)) return false;
    if (!('unprocessedBody' in value)) return false;
    if (!('attachments' in value)) return false;
    if (!('emailIdentifier' in value)) return false;
    return true;
}

export function SendSalesEmailInputFromJSON(json: any): SendSalesEmailInput {
    return SendSalesEmailInputFromJSONTyped(json, false);
}

export function SendSalesEmailInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendSalesEmailInput {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'salesContactId': json['salesContactId'],
        'emailAddresses': json['emailAddresses'],
        'ccEmailAddresses': json['ccEmailAddresses'],
        'subject': json['subject'],
        'body': json['body'],
        'unprocessedBody': json['unprocessedBody'],
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'emailIdentifier': json['emailIdentifier'],
    };
}

export function SendSalesEmailInputToJSON(value?: SendSalesEmailInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'salesContactId': value['salesContactId'],
        'emailAddresses': value['emailAddresses'],
        'ccEmailAddresses': value['ccEmailAddresses'],
        'subject': value['subject'],
        'body': value['body'],
        'unprocessedBody': value['unprocessedBody'],
        'attachments': ((value['attachments'] as Array<any>).map(AttachmentToJSON)),
        'emailIdentifier': value['emailIdentifier'],
    };
}

