/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutochargeFrequency } from './AutochargeFrequency';
import {
    AutochargeFrequencyFromJSON,
    AutochargeFrequencyFromJSONTyped,
    AutochargeFrequencyToJSON,
} from './AutochargeFrequency';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { CustomerInvoiceState } from './CustomerInvoiceState';
import {
    CustomerInvoiceStateFromJSON,
    CustomerInvoiceStateFromJSONTyped,
    CustomerInvoiceStateToJSON,
} from './CustomerInvoiceState';
import type { CustomerInvoiceType } from './CustomerInvoiceType';
import {
    CustomerInvoiceTypeFromJSON,
    CustomerInvoiceTypeFromJSONTyped,
    CustomerInvoiceTypeToJSON,
} from './CustomerInvoiceType';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface CustomerInvoice
 */
export interface CustomerInvoice {
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    customerInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    shipmentId?: string;
    /**
     * 
     * @type {CustomerInvoiceType}
     * @memberof CustomerInvoice
     */
    invoiceType?: CustomerInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    taxDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    taxDescription2?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoice
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoice
     */
    taxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoice
     */
    taxAmount2?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof CustomerInvoice
     */
    currency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    invoiceS3Key?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    xeroInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    stripePaymentIntent?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    paymentMethodBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastFourDigits?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoice
     */
    paymentTermsDays?: number;
    /**
     * 
     * @type {CustomerInvoiceState}
     * @memberof CustomerInvoice
     */
    customerInvoiceState?: CustomerInvoiceState;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    invoiceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    paymentReceivedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerInvoice
     */
    paymentReceivedAmount?: number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof CustomerInvoice
     */
    paymentMethod?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    cardholderName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastPaymentFailureCardholderName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastPaymentFailureLastFourDigits?: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof CustomerInvoice
     */
    lastPaymentFailurePaymentMethod?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastPaymentFailurePaymentMethodBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastPaymentFailureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastPaymentFailureMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    lastPaymentDetailedDeclineCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    additionalChargeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    paymentReference?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInvoice
     */
    settlementPendingTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerInvoice
     */
    disputed?: boolean;
    /**
     * 
     * @type {AutochargeFrequency}
     * @memberof CustomerInvoice
     */
    autochargeFrequency?: AutochargeFrequency;
}

/**
 * Check if a given object implements the CustomerInvoice interface.
 */
export function instanceOfCustomerInvoice(value: object): boolean {
    return true;
}

export function CustomerInvoiceFromJSON(json: any): CustomerInvoice {
    return CustomerInvoiceFromJSONTyped(json, false);
}

export function CustomerInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerInvoice {
    if (json == null) {
        return json;
    }
    return {
        
        'customerInvoiceId': json['customerInvoiceId'] == null ? undefined : json['customerInvoiceId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'invoiceType': json['invoiceType'] == null ? undefined : CustomerInvoiceTypeFromJSON(json['invoiceType']),
        'description': json['description'] == null ? undefined : json['description'],
        'taxDescription': json['taxDescription'] == null ? undefined : json['taxDescription'],
        'taxDescription2': json['taxDescription2'] == null ? undefined : json['taxDescription2'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'taxAmount': json['taxAmount'] == null ? undefined : json['taxAmount'],
        'taxAmount2': json['taxAmount2'] == null ? undefined : json['taxAmount2'],
        'currency': json['currency'] == null ? undefined : CurrencyCodeFromJSON(json['currency']),
        'invoiceS3Key': json['invoiceS3Key'] == null ? undefined : json['invoiceS3Key'],
        'xeroInvoiceId': json['xeroInvoiceId'] == null ? undefined : json['xeroInvoiceId'],
        'stripePaymentIntent': json['stripePaymentIntent'] == null ? undefined : json['stripePaymentIntent'],
        'paymentMethodBrand': json['paymentMethodBrand'] == null ? undefined : json['paymentMethodBrand'],
        'lastFourDigits': json['lastFourDigits'] == null ? undefined : json['lastFourDigits'],
        'paymentTermsDays': json['paymentTermsDays'] == null ? undefined : json['paymentTermsDays'],
        'customerInvoiceState': json['customerInvoiceState'] == null ? undefined : CustomerInvoiceStateFromJSON(json['customerInvoiceState']),
        'invoiceIdentifier': json['invoiceIdentifier'] == null ? undefined : json['invoiceIdentifier'],
        'dueDate': json['dueDate'] == null ? undefined : json['dueDate'],
        'paymentReceivedDate': json['paymentReceivedDate'] == null ? undefined : json['paymentReceivedDate'],
        'paymentReceivedAmount': json['paymentReceivedAmount'] == null ? undefined : json['paymentReceivedAmount'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : PaymentMethodFromJSON(json['paymentMethod']),
        'cardholderName': json['cardholderName'] == null ? undefined : json['cardholderName'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'lastPaymentFailureCardholderName': json['lastPaymentFailureCardholderName'] == null ? undefined : json['lastPaymentFailureCardholderName'],
        'lastPaymentFailureLastFourDigits': json['lastPaymentFailureLastFourDigits'] == null ? undefined : json['lastPaymentFailureLastFourDigits'],
        'lastPaymentFailurePaymentMethod': json['lastPaymentFailurePaymentMethod'] == null ? undefined : PaymentMethodFromJSON(json['lastPaymentFailurePaymentMethod']),
        'lastPaymentFailurePaymentMethodBrand': json['lastPaymentFailurePaymentMethodBrand'] == null ? undefined : json['lastPaymentFailurePaymentMethodBrand'],
        'lastPaymentFailureCode': json['lastPaymentFailureCode'] == null ? undefined : json['lastPaymentFailureCode'],
        'lastPaymentFailureMessage': json['lastPaymentFailureMessage'] == null ? undefined : json['lastPaymentFailureMessage'],
        'lastPaymentDetailedDeclineCode': json['lastPaymentDetailedDeclineCode'] == null ? undefined : json['lastPaymentDetailedDeclineCode'],
        'additionalChargeId': json['additionalChargeId'] == null ? undefined : json['additionalChargeId'],
        'paymentReference': json['paymentReference'] == null ? undefined : json['paymentReference'],
        'settlementPendingTime': json['settlementPendingTime'] == null ? undefined : json['settlementPendingTime'],
        'disputed': json['disputed'] == null ? undefined : json['disputed'],
        'autochargeFrequency': json['autochargeFrequency'] == null ? undefined : AutochargeFrequencyFromJSON(json['autochargeFrequency']),
    };
}

export function CustomerInvoiceToJSON(value?: CustomerInvoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerInvoiceId': value['customerInvoiceId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'invoiceType': CustomerInvoiceTypeToJSON(value['invoiceType']),
        'description': value['description'],
        'taxDescription': value['taxDescription'],
        'taxDescription2': value['taxDescription2'],
        'amount': value['amount'],
        'taxAmount': value['taxAmount'],
        'taxAmount2': value['taxAmount2'],
        'currency': CurrencyCodeToJSON(value['currency']),
        'invoiceS3Key': value['invoiceS3Key'],
        'xeroInvoiceId': value['xeroInvoiceId'],
        'stripePaymentIntent': value['stripePaymentIntent'],
        'paymentMethodBrand': value['paymentMethodBrand'],
        'lastFourDigits': value['lastFourDigits'],
        'paymentTermsDays': value['paymentTermsDays'],
        'customerInvoiceState': CustomerInvoiceStateToJSON(value['customerInvoiceState']),
        'invoiceIdentifier': value['invoiceIdentifier'],
        'dueDate': value['dueDate'],
        'paymentReceivedDate': value['paymentReceivedDate'],
        'paymentReceivedAmount': value['paymentReceivedAmount'],
        'paymentMethod': PaymentMethodToJSON(value['paymentMethod']),
        'cardholderName': value['cardholderName'],
        'companyId': value['companyId'],
        'lastPaymentFailureCardholderName': value['lastPaymentFailureCardholderName'],
        'lastPaymentFailureLastFourDigits': value['lastPaymentFailureLastFourDigits'],
        'lastPaymentFailurePaymentMethod': PaymentMethodToJSON(value['lastPaymentFailurePaymentMethod']),
        'lastPaymentFailurePaymentMethodBrand': value['lastPaymentFailurePaymentMethodBrand'],
        'lastPaymentFailureCode': value['lastPaymentFailureCode'],
        'lastPaymentFailureMessage': value['lastPaymentFailureMessage'],
        'lastPaymentDetailedDeclineCode': value['lastPaymentDetailedDeclineCode'],
        'additionalChargeId': value['additionalChargeId'],
        'paymentReference': value['paymentReference'],
        'settlementPendingTime': value['settlementPendingTime'],
        'disputed': value['disputed'],
        'autochargeFrequency': AutochargeFrequencyToJSON(value['autochargeFrequency']),
    };
}

