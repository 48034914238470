import { ShipmentApiActionMenuItem } from "./ShipmentApiActionButton";

interface ForceDocumentsUpdateMenuItemProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function ForceDocumentsUpdateMenuItem(
  props: ForceDocumentsUpdateMenuItemProps
) {
  const { shipmentId } = props;
  return (
    <ShipmentApiActionMenuItem
      name="Force Documents Update"
      onClick={async function (shipmentApi) {
        await shipmentApi.forceDocumentsUpdate({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={false}
    />
  );
}
