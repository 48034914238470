import { ReactNode } from "react";
import { LineItem, Note } from "../../../generated-openapi-client";
import { arrayDifferences } from "../../../Helpers/arrayDifferences";
import { LineItemsChangesTable } from "../../changes/LineItemsChangesTable";
import Colors from "../../Colors";
import HorizontalStack from "../../HorizontalStack";
import { CollapsibleNote } from "../CollapseNote";
import { NoteAuthorHeadline } from "../NoteHeadline";
import { NoteMessage } from "../NoteMessage";

interface ShipmentLineItemsMetadataDifference {
  note: Note;
  beforeData: Array<LineItem> | string;
  afterData: Array<LineItem> | string;
}

export function ShipmentModifiedLineItemsNote({
  note,
  beforeData,
  afterData,
}: ShipmentLineItemsMetadataDifference) {
  if (typeof beforeData === "string") {
    beforeData = JSON.parse(beforeData) as LineItem[];
  }

  if (typeof afterData === "string") {
    afterData = JSON.parse(afterData) as LineItem[];
  }

  /*
   * Need to improve this
   */
  const differences = arrayDifferences(
    beforeData as LineItem[],
    afterData as LineItem[]
  );
  const changes = differences.changes.map((c) => c.differences.length);
  const additions = differences.additions.map((a) => a.item);
  const deletions = differences.deletions.map((d) => d.item);

  /*
   * If the left data (before changes) has less elements
   * than the right data (after changes) it means that
   * some elements were added in the data changes
   * Ex:
   * left (before) [1, 2, 3]
   * right (after) [1, 2, 3, 4]
   */
  const isAddition = beforeData.length < afterData.length;

  /**
   * We want to send to the data table the array with more elements
   */
  const data = isAddition ? afterData : beforeData;

  return (
    <CollapsibleNote
      collapseActive={false}
      header={
        <div>
          <NoteAuthorHeadline
            author={note.author}
            headline={
              <HorizontalStack style={{ display: "flex", columnGap: "10px" }}>
                <div> {note.headline} </div>
                <HorizontalStack style={{gap: "8px"}}>
                  {changes.length > 0 && (
                    <MutationInfo
                      small={true}
                      color={Colors.OrangeT[600]}
                      background={Colors.OrangeT[100]}
                    >
                      {changes.length}{" "}
                      {deletions.length > 1 ? "modifications" : "modification"}
                    </MutationInfo>
                  )}
                  {additions.length > 0 && (
                    <MutationInfo
                      small={true}
                      color={Colors.GreenT[600]}
                      background={Colors.GreenT[100]}
                    >
                      {additions.length}{" "}
                      {additions.length > 1 ? "additions" : "addition"}
                    </MutationInfo>
                  )}
                  {deletions.length > 0 && (
                    <MutationInfo
                      small={true}
                      color={Colors.RedT[500]}
                      background={Colors.RedT[100]}
                    >
                      {deletions.length}{" "}
                      {deletions.length > 1 ? "deletions" : "deletion"}
                    </MutationInfo>
                  )}
                </HorizontalStack>
              </HorizontalStack>
            }
          />
          <NoteMessage>{note.message}</NoteMessage>
        </div>
      }
    >
      <div
        style={{
          paddingBlock: "15px",
          paddingInline: "30px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "10px",
            marginBottom: "20px",
          }}
        >
          {changes.length > 0 && (
            <MutationInfo
              color={Colors.OrangeT[600]}
              background={Colors.OrangeT[100]}
            >
              Modified {changes.length} line{" "}
              {changes.length > 1 ? "items" : "item"}
            </MutationInfo>
          )}
          {additions.length > 0 && (
            <MutationInfo
              color={Colors.GreenT[600]}
              background={Colors.GreenT[100]}
            >
              Added {additions.length} line{" "}
              {additions.length > 1 ? "items" : "item"}
            </MutationInfo>
          )}
          {deletions.length > 0 && (
            <MutationInfo
              color={Colors.RedT[500]}
              background={Colors.RedT[100]}
            >
              Deleted {deletions.length} line{" "}
              {deletions.length > 1 ? "items" : "item"}
            </MutationInfo>
          )}
        </div>
        <LineItemsChangesTable
          left={beforeData}
          right={afterData}
          data={data}
        />
      </div>
    </CollapsibleNote>
  );
}

interface MutationInfoProps {
  color: string;
  background: string;
  small?: boolean;
  children: ReactNode;
}

export function MutationInfo({
  color,
  background,
  children,
  small = false,
}: MutationInfoProps) {
  return (
    <div
      style={
        !small
          ? {
              padding: "4px",
              paddingInline: "12px",
              color: color,
              background: background,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : {
              fontSize: "8pt",
              padding: "4px",
              paddingInline: "6px",
              color: color,
              background: background,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
      }
    >
      {children}
    </div>
  );
}
