import { Button, Form, message, Modal, Switch } from "antd";

import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { CompanyTabProps } from "../ViewCompanyScreen";

export function ModifyPermittedPaymentOptionsButton(props: CompanyTabProps) {
  const companyId = props.data.company.companyId!!;
  const createCompaniesApi = useCompaniesApi();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);

  const [chequePaymentMethodPermitted, setChequePaymentMethodPermitted] =
    useState(props.data.company.chequePaymentMethodPermitted!!);

  const [wirePaymentMethodPermitted, setWirePaymentMethodPermitted] = useState(
    props.data.company.wirePaymentMethodPermitted!!
  );
  const [doNotAutochargeOverdueInvoices, setDoNotAutochargeOverdueInvoices] =
    useState(props.data.company.doNotAutochargeOverdueInvoices!!);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setSaving(true);
    try {
      const companiesApi = await createCompaniesApi();

      await companiesApi.setPermittedAdditionalPaymentMethods({
        companyId,
        isChequePaymentPermitted: chequePaymentMethodPermitted,
        isWirePaymentPermitted: wirePaymentMethodPermitted,
        doNotAutochargeOverdueInvoices: doNotAutochargeOverdueInvoices,
      });
      await props.onRefresh();
      setIsModalVisible(false);
      message.success("Saved");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Modify Permitted Payment Options"
        visible={isModalVisible}
        okText="Save"
        onOk={handleOk}
        confirmLoading={saving}
        onCancel={handleCancel}
        width={550}
        destroyOnClose
      >
        <Form
          style={{ width: "400px" }}
          labelCol={{ span: 20 }}
          wrapperCol={{ span: 16, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Cheque Permitted?">
            <Switch
              checked={chequePaymentMethodPermitted}
              onChange={setChequePaymentMethodPermitted}
            />
          </Form.Item>
          <Form.Item label="Wire Permitted?">
            <Switch
              checked={wirePaymentMethodPermitted}
              onChange={setWirePaymentMethodPermitted}
            />
          </Form.Item>
          <Form.Item label="Do not auto-charge overdue invoices?">
            <Switch
              checked={doNotAutochargeOverdueInvoices}
              onChange={setDoNotAutochargeOverdueInvoices}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="ghost">
        Modify Permitted Payment Options
      </Button>
    </>
  );
}
