/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailTemplate } from './EmailTemplate';
import {
    EmailTemplateFromJSON,
    EmailTemplateFromJSONTyped,
    EmailTemplateToJSON,
} from './EmailTemplate';
import type { EmailTemplateImage } from './EmailTemplateImage';
import {
    EmailTemplateImageFromJSON,
    EmailTemplateImageFromJSONTyped,
    EmailTemplateImageToJSON,
} from './EmailTemplateImage';

/**
 * 
 * @export
 * @interface EmailTemplates
 */
export interface EmailTemplates {
    /**
     * 
     * @type {Array<EmailTemplate>}
     * @memberof EmailTemplates
     */
    emailTemplates: Array<EmailTemplate>;
    /**
     * 
     * @type {Array<EmailTemplateImage>}
     * @memberof EmailTemplates
     */
    emailTemplateImages: Array<EmailTemplateImage>;
}

/**
 * Check if a given object implements the EmailTemplates interface.
 */
export function instanceOfEmailTemplates(value: object): boolean {
    if (!('emailTemplates' in value)) return false;
    if (!('emailTemplateImages' in value)) return false;
    return true;
}

export function EmailTemplatesFromJSON(json: any): EmailTemplates {
    return EmailTemplatesFromJSONTyped(json, false);
}

export function EmailTemplatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplates {
    if (json == null) {
        return json;
    }
    return {
        
        'emailTemplates': ((json['emailTemplates'] as Array<any>).map(EmailTemplateFromJSON)),
        'emailTemplateImages': ((json['emailTemplateImages'] as Array<any>).map(EmailTemplateImageFromJSON)),
    };
}

export function EmailTemplatesToJSON(value?: EmailTemplates | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailTemplates': ((value['emailTemplates'] as Array<any>).map(EmailTemplateToJSON)),
        'emailTemplateImages': ((value['emailTemplateImages'] as Array<any>).map(EmailTemplateImageToJSON)),
    };
}

