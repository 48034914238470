/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyNoteType } from './CompanyNoteType';
import {
    CompanyNoteTypeFromJSON,
    CompanyNoteTypeFromJSONTyped,
    CompanyNoteTypeToJSON,
} from './CompanyNoteType';

/**
 * 
 * @export
 * @interface CreateCompanyNoteInput
 */
export interface CreateCompanyNoteInput {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyNoteInput
     */
    companyId: string;
    /**
     * 
     * @type {CompanyNoteType}
     * @memberof CreateCompanyNoteInput
     */
    type: CompanyNoteType;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyNoteInput
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyNoteInput
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyNoteInput
     */
    submessage?: string;
}

/**
 * Check if a given object implements the CreateCompanyNoteInput interface.
 */
export function instanceOfCreateCompanyNoteInput(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('subject' in value)) return false;
    if (!('body' in value)) return false;
    return true;
}

export function CreateCompanyNoteInputFromJSON(json: any): CreateCompanyNoteInput {
    return CreateCompanyNoteInputFromJSONTyped(json, false);
}

export function CreateCompanyNoteInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyNoteInput {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'type': CompanyNoteTypeFromJSON(json['type']),
        'subject': json['subject'],
        'body': json['body'],
        'submessage': json['submessage'] == null ? undefined : json['submessage'],
    };
}

export function CreateCompanyNoteInputToJSON(value?: CreateCompanyNoteInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'type': CompanyNoteTypeToJSON(value['type']),
        'subject': value['subject'],
        'body': value['body'],
        'submessage': value['submessage'],
    };
}

