/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { FreightClaim } from './FreightClaim';
import {
    FreightClaimFromJSON,
    FreightClaimFromJSONTyped,
    FreightClaimToJSON,
} from './FreightClaim';
import type { Shipment } from './Shipment';
import {
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
} from './Shipment';

/**
 * 
 * @export
 * @interface ShipmentCompanyFreightClaim
 */
export interface ShipmentCompanyFreightClaim {
    /**
     * 
     * @type {Shipment}
     * @memberof ShipmentCompanyFreightClaim
     */
    shipment: Shipment;
    /**
     * 
     * @type {Company}
     * @memberof ShipmentCompanyFreightClaim
     */
    company: Company;
    /**
     * 
     * @type {FreightClaim}
     * @memberof ShipmentCompanyFreightClaim
     */
    freightClaim: FreightClaim;
}

/**
 * Check if a given object implements the ShipmentCompanyFreightClaim interface.
 */
export function instanceOfShipmentCompanyFreightClaim(value: object): boolean {
    if (!('shipment' in value)) return false;
    if (!('company' in value)) return false;
    if (!('freightClaim' in value)) return false;
    return true;
}

export function ShipmentCompanyFreightClaimFromJSON(json: any): ShipmentCompanyFreightClaim {
    return ShipmentCompanyFreightClaimFromJSONTyped(json, false);
}

export function ShipmentCompanyFreightClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentCompanyFreightClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'shipment': ShipmentFromJSON(json['shipment']),
        'company': CompanyFromJSON(json['company']),
        'freightClaim': FreightClaimFromJSON(json['freightClaim']),
    };
}

export function ShipmentCompanyFreightClaimToJSON(value?: ShipmentCompanyFreightClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipment': ShipmentToJSON(value['shipment']),
        'company': CompanyToJSON(value['company']),
        'freightClaim': FreightClaimToJSON(value['freightClaim']),
    };
}

