/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesContact
 */
export interface SalesContact {
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    salesContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    legacySalesforceContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    legacySalesforceAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    creationInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesContact
     */
    phoneExtension?: string;
}

/**
 * Check if a given object implements the SalesContact interface.
 */
export function instanceOfSalesContact(value: object): boolean {
    return true;
}

export function SalesContactFromJSON(json: any): SalesContact {
    return SalesContactFromJSONTyped(json, false);
}

export function SalesContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesContact {
    if (json == null) {
        return json;
    }
    return {
        
        'salesContactId': json['salesContactId'] == null ? undefined : json['salesContactId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'name': json['name'] == null ? undefined : json['name'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'email': json['email'] == null ? undefined : json['email'],
        'legacySalesforceContactId': json['legacySalesforceContactId'] == null ? undefined : json['legacySalesforceContactId'],
        'legacySalesforceAccountId': json['legacySalesforceAccountId'] == null ? undefined : json['legacySalesforceAccountId'],
        'description': json['description'] == null ? undefined : json['description'],
        'mobilePhone': json['mobilePhone'] == null ? undefined : json['mobilePhone'],
        'creationInfo': json['creationInfo'] == null ? undefined : json['creationInfo'],
        'phoneExtension': json['phoneExtension'] == null ? undefined : json['phoneExtension'],
    };
}

export function SalesContactToJSON(value?: SalesContact | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'salesContactId': value['salesContactId'],
        'createdAt': value['createdAt'],
        'companyId': value['companyId'],
        'name': value['name'],
        'phone': value['phone'],
        'email': value['email'],
        'legacySalesforceContactId': value['legacySalesforceContactId'],
        'legacySalesforceAccountId': value['legacySalesforceAccountId'],
        'description': value['description'],
        'mobilePhone': value['mobilePhone'],
        'creationInfo': value['creationInfo'],
        'phoneExtension': value['phoneExtension'],
    };
}

