import { Form, InputNumber, Menu, message, Modal, Typography } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { CompanyData } from "../../generated-openapi-client";
import Spacer from "../../Spacer";

const { Text } = Typography;

interface SetPaymentTermsForCompanyMenuItemProps {
  data: CompanyData;
  onRefresh: () => Promise<void>;
}

export function SetPaymentTermsForCompanyMenuItem(
  props: SetPaymentTermsForCompanyMenuItemProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentTermsDays, setPaymentTermsDays] = useState(
    props.data.company.paymentTermsDays || 0
  );
  const createCompaniesApi = useCompaniesApi();
  const companyId = props.data.company.companyId!!;
  const [loading, setLoading] = useState(false);
  const [creditLimitAmount, setCreditLimitAmount] = useState(
    props.data.company.creditLimit || 0
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (paymentTermsDays < 0) {
      return message.error("Days is negative");
    }

    if (paymentTermsDays > 90) {
      return message.error("Days is too long");
    }

    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.setCreditTermsForCompany({
        companyId,
        paymentTermsDays,
        creditLimitAmount,
      });
      await props.onRefresh();
      message.success(`Set Payment Terms Days to ${paymentTermsDays}`);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Set Payment Terms Days"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={900}
      >
        <Form
          style={{ width: "1000px" }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Stack align="left" style={{ width: "600px" }}>
            <Text>
              If we put a company on credit, they are given a certain number of
              days to pay an invoice. Typically 7, 14, 30. 0 days means they
              must pay each invoice immediately.
            </Text>
          </Stack>
          <Spacer height={32} />
          <Form.Item label="Payment Terms Days">
            <InputNumber
              value={paymentTermsDays}
              onChange={setPaymentTermsDays}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item label="Credit Limit Amount">
            <HorizontalStack>
              <CurrencyInput
                amount={creditLimitAmount}
                setAmount={setCreditLimitAmount}
                currency={props.data.company.currency!!}
              />
            </HorizontalStack>
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>Set Credit Terms</Menu.Item>
    </>
  );
}
