import { Button, DatePicker, message, Modal, Tooltip, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { Shipment, ShipmentState } from "../../generated-openapi-client";
import Spacer from "../../Spacer";

const { Text } = Typography;

interface PauseAmyEmailsButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

export function PauseAmyEmailsButton(props: PauseAmyEmailsButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const [pauseAmyEmailsUntilDateMoment, setPauseAmyEmailsUntilDateMoment] =
    useState(moment());

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    try {
      const shipmentId = props.shipment.shipmentId!!;
      const pauseAmyEmailsUntilDate =
        pauseAmyEmailsUntilDateMoment.format("YYYY-MM-DD");

      const shipmentApi = await createShipmentApi();

      await shipmentApi.pauseAmyEmailsUntil({
        shipmentId,
        pauseAmyEmailsUntilDate,
      });
      await props.onRefresh();
      message.success(
        `Amy emails paused until ${pauseAmyEmailsUntilDate}. Expected delivery date possibly updated.`
      );
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (props.shipment.state !== ShipmentState.InTransit) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Pause Amy Tracking Update Emails"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            Normally Amy will email every ~3 days to make sure we have a recent
            tracking update. Sometimes we know the status - eg if it's
            deliberately sitting because the appointment isn't for another week.
            So it's weird to send another email.
          </Text>
          <Spacer height={8} />
          <Text>
            This will pause Amy tracking update emails until after this date
          </Text>
          <Spacer height={32} />
          <HorizontalStack>
            <DatePicker
              style={{ width: "200px" }}
              value={pauseAmyEmailsUntilDateMoment}
              onChange={function (m) {
                if (m !== null) {
                  setPauseAmyEmailsUntilDateMoment(m);
                }
              }}
            />
          </HorizontalStack>
        </Stack>
      </Modal>
      <Tooltip overlay="Specifically this will pause the Amy tracking update emails (when it has been more than 3 days since an update). She will still ask when the expected delivery date is in the past or missed deliveries. This will also update the expected delivery date if it is earlier than this pause date">
        <Button onClick={showModal}>🦸‍♀️ Pause Amy</Button>
      </Tooltip>
    </>
  );
}
