import { message } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import {
  AdditionalCharge,
  AdditionalChargeState,
} from "../../generated-openapi-client";

interface DeleteAdditionalChargeButtonProps {
  additionalCharge: AdditionalCharge;
  onRefresh: () => Promise<void>;
}

export function DeleteAdditionalChargeButton(
  props: DeleteAdditionalChargeButtonProps
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  async function onDelete() {
    const additionalChargeId = props.additionalCharge.additionalChargeId!!;

    try {
      const customerInvoiceApi = await createCustomerInvoiceApi();
      customerInvoiceApi.deleteAdditionalCharge({
        additionalChargeId,
      });
      await props.onRefresh();
      message.success("Deleted");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }
  if (props.additionalCharge.state !== AdditionalChargeState.Proposed) {
    return <></>;
  }
  return (
    <ConfirmButton
      onConfirm={onDelete}
      question={`Are you sure you want to delete the additional charge?`}
      okText="Yes, delete additional charge"
      tooltip="This will delete the additional charge"
    >
      ❌ Delete
    </ConfirmButton>
  );
}
