import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { CSSProperties, ReactNode } from "react";
import HorizontalStack from "./HorizontalStack";
interface ButtonRowProps {
  children: ReactNode;
  extrasMenu?: ReactNode;
  style?: CSSProperties;
}

function ExtrasMenu(props: ButtonRowProps) {
  if (props.extrasMenu === undefined) {
    return <></>;
  }

  return (
    // @ts-ignore
    <Dropdown overlay={props.extrasMenu}>
      <Button>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}

export function ButtonRow(props: ButtonRowProps) {
  return (
    <HorizontalStack
      spacing={8}
      verticalAlign="middle"
      style={{ ...props.style, marginLeft: "-8px" }}
    >
      {props.children}
      <ExtrasMenu {...props} />
    </HorizontalStack>
  );
}
