import { ReactNode, useContext } from "react";
import Colors from "../../Components/Colors";

import { Comparator } from "./Comparator";
import { ListComparatorContext } from "./ListComparatorContext";

interface ListComparatorProps<T> {
  index: number;
  compareFn: (left: T, right: T) => boolean;
  onEquals: (element: T) => ReactNode;
  onChanges: (left: T, right: T) => ReactNode;
  onNotFound: (element: T) => ReactNode;
}

/**
 * Compares two elements of a list based on its index and renders different components based on the comparison.
 *
 * Requires a context to be set, it is used to get the rightList and leftList
 * @see {@link ListComparatorContext}
 *
 * @param index position of the elements in the list
 * @param compareFn function that compares the left and right elements (it must return a boolean)
 *    return `true` if `left` and `right` elements are equal
 *    return `false` if `left` and `right` elements are different
 * @param onEquals function that renders a component when the left and right elements are equal
 * @param onChanges function that renders a component when the left and right elements are not equal (changes)
 * @param onNotFound function that renders a component when an element is not found in the lists
 *
 * @example
 * <ItemListComparator
 *  index={i}
 *  compareFn={(left: Element, right: Element) =>
 *    left.prop1 == right.prop1 && left.prop2 == right.prop2
 *  }
 *  onEquals={(e: Element) => (
 *    <div>{left.name} is the same as {right.name}</div>
 *  )}
 *  onNotFound={(e: Element) => <div>{e.name} was deleted</div> }
 *  onChanges={(left: Element, right: Element) => (
 *    <div>{left.name} is different from {right.name}</div>
 *  )}
 * />
 */
export function ItemListComparator<T>({
  index,
  compareFn,
  onEquals,
  onChanges,
  onNotFound,
}: ListComparatorProps<T>) {
  const context = useContext(ListComparatorContext);

  /*
   * Get the leftElement and rightElement of the left and right lists
   */
  const leftElement = context.leftList[index] as T;
  const rightElement = context.rightList[index] as T;

  /*
   * If the left element is undefined it will render
   * the right element with the function onNotFound
   *
   * The leftElement can be interpreted as the element before changes.
   *
   * When the left element is undefined we can interpret
   * that the right element in that index is a new element.
   *
   * Example:
   * leftList = [1, 2]
   * rightList = [1, 2, 3]
   *
   * leftElement = leftList[2]
   * rightElement = rightList[2]
   *
   * The element at index 2 in leftList will be undefined.
   * The element at index 2 in rightList will be '3'.
   *
   * rightList has more elements than leftList, this could mean that there was an addition.
   * The added element will be rendered it in a span tag with green text
   *
   */
  if (leftElement === undefined) {
    return (
      <span style={{ color: Colors.GreenT[600], marginInline: "5px" }}>
        {onNotFound(rightElement)}
      </span>
    );
  }

  /*
   * If the right element is undefined it will render
   * the left element with the function onNotFound
   *
   * The rightElement can be interpreted as the element after changes.
   *
   * When the right element is undefined we can interpret
   * that the left element in that index was deleted.
   *
   * Example:
   * leftList = [1, 2, 3]
   * rightList = [1, 2]
   *
   * leftElement = leftList[2]
   * rightElement = rightList[2]
   *
   * The element at index 2 in leftList will be '3'.
   * The element at index 2 in rightList will be undefined.
   *
   * leftList has more elements than rightList, this could mean that there was a deletion.
   * The deleted element will be rendered it in a span tag with red text and a line-through text decoration
   *
   */
  if (rightElement === undefined) {
    return (
      <span
        style={{
          color: Colors.RedT[500],
          marginInline: "5px",
          textDecoration: "line-through",
        }}
      >
        {onNotFound(leftElement)}
      </span>
    );
  }

  /*
   * if the two elements are present in the lists they will be compared with a comparator
   * <Comparator .../>
   */
  return (
    <Comparator
      left={leftElement}
      right={rightElement}
      compareFn={compareFn}
      onEquals={onEquals}
      onChanges={onChanges}
    />
  );
}
