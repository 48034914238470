/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GeoFilter = {
    DomesticCanada: 'DomesticCanada',
    DomesticUsa: 'DomesticUSA',
    CrossBorder: 'CrossBorder',
    CrossBorderSouth: 'CrossBorderSouth',
    CrossBorderNorth: 'CrossBorderNorth'
} as const;
export type GeoFilter = typeof GeoFilter[keyof typeof GeoFilter];


export function instanceOfGeoFilter(value: any): boolean {
    return Object.values(GeoFilter).includes(value);
}

export function GeoFilterFromJSON(json: any): GeoFilter {
    return GeoFilterFromJSONTyped(json, false);
}

export function GeoFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoFilter {
    return json as GeoFilter;
}

export function GeoFilterToJSON(value?: GeoFilter | null): any {
    return value as any;
}

