import { Tag } from "antd";
import { CustomerInvoice } from "../generated-openapi-client";
import { CustomerInvoiceState } from "../generated-openapi-client/models/CustomerInvoiceState";
import { isCustomerInvoiceOverdue } from "../Helpers/isCustomerInvoiceOverdue";
import Colors from "./Colors";

interface CustomerInvoiceStateTagProps {
  customerInvoice: CustomerInvoice;
}

function describeCustomerInvoiceState(state: CustomerInvoiceState): string {
  switch (state) {
    case CustomerInvoiceState.Voided:
      return "Voided";
    case CustomerInvoiceState.Issued:
      return "Issued";
    case CustomerInvoiceState.SettlementPending:
      return "Settlement Pending";
    case CustomerInvoiceState.Settled:
      return "Settled";
    case CustomerInvoiceState.Reconciled:
      return "Reconciled";
    case CustomerInvoiceState.Deleted:
      return "Deleted";
  }
}

export function colorForCustomerInvoiceState(state: CustomerInvoiceState) {
  switch (state) {
    case CustomerInvoiceState.Voided:
      return "black";
    case CustomerInvoiceState.Issued:
      return Colors.LightGray;
    case CustomerInvoiceState.SettlementPending:
      return Colors.Blue;
    case CustomerInvoiceState.Settled:
      return Colors.Gold;
    case CustomerInvoiceState.Reconciled:
      return Colors.DarkGreen;
  }
}

export function foregroundColorForCustomerInvoiceState(
  state: CustomerInvoiceState
) {
  switch (state) {
    case CustomerInvoiceState.Voided:
      return "white";
    case CustomerInvoiceState.Issued:
      return "black";
    case CustomerInvoiceState.SettlementPending:
      return "white";
    case CustomerInvoiceState.Settled:
      return "black";
    case CustomerInvoiceState.Reconciled:
      return "white";
  }
}

export function CustomerInvoiceStateTag(props: CustomerInvoiceStateTagProps) {
  if (
    props.customerInvoice.disputed &&
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued
  ) {
    return (
      <Tag color={Colors.Orange}>
        <div style={{ color: "black" }}>Disputed</div>
      </Tag>
    );
  }

  if (
    isCustomerInvoiceOverdue(props.customerInvoice) &&
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued
  ) {
    return (
      <Tag color={Colors.Red}>
        <div style={{ color: "white" }}>Overdue</div>
      </Tag>
    );
  }

  return (
    <Tag
      color={colorForCustomerInvoiceState(
        props.customerInvoice.customerInvoiceState!!
      )}
    >
      <div
        style={{
          color: foregroundColorForCustomerInvoiceState(
            props.customerInvoice.customerInvoiceState!!
          ),
        }}
      >
        {describeCustomerInvoiceState(
          props.customerInvoice.customerInvoiceState!!
        )}
      </div>
    </Tag>
  );
}
