/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface LookUpPostalCodeResponse
 */
export interface LookUpPostalCodeResponse {
    /**
     * 
     * @type {CountryCode}
     * @memberof LookUpPostalCodeResponse
     */
    countryCode: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof LookUpPostalCodeResponse
     */
    stateOrProvinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof LookUpPostalCodeResponse
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof LookUpPostalCodeResponse
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof LookUpPostalCodeResponse
     */
    latitude: number;
}

/**
 * Check if a given object implements the LookUpPostalCodeResponse interface.
 */
export function instanceOfLookUpPostalCodeResponse(value: object): boolean {
    if (!('countryCode' in value)) return false;
    if (!('stateOrProvinceCode' in value)) return false;
    if (!('city' in value)) return false;
    if (!('longitude' in value)) return false;
    if (!('latitude' in value)) return false;
    return true;
}

export function LookUpPostalCodeResponseFromJSON(json: any): LookUpPostalCodeResponse {
    return LookUpPostalCodeResponseFromJSONTyped(json, false);
}

export function LookUpPostalCodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookUpPostalCodeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': CountryCodeFromJSON(json['countryCode']),
        'stateOrProvinceCode': json['stateOrProvinceCode'],
        'city': json['city'],
        'longitude': json['longitude'],
        'latitude': json['latitude'],
    };
}

export function LookUpPostalCodeResponseToJSON(value?: LookUpPostalCodeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': CountryCodeToJSON(value['countryCode']),
        'stateOrProvinceCode': value['stateOrProvinceCode'],
        'city': value['city'],
        'longitude': value['longitude'],
        'latitude': value['latitude'],
    };
}

