/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceAuditProblemResolutionState = {
    Unresolved: 'unresolved',
    Pending: 'pending',
    Resolved: 'resolved'
} as const;
export type InvoiceAuditProblemResolutionState = typeof InvoiceAuditProblemResolutionState[keyof typeof InvoiceAuditProblemResolutionState];


export function instanceOfInvoiceAuditProblemResolutionState(value: any): boolean {
    return Object.values(InvoiceAuditProblemResolutionState).includes(value);
}

export function InvoiceAuditProblemResolutionStateFromJSON(json: any): InvoiceAuditProblemResolutionState {
    return InvoiceAuditProblemResolutionStateFromJSONTyped(json, false);
}

export function InvoiceAuditProblemResolutionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditProblemResolutionState {
    return json as InvoiceAuditProblemResolutionState;
}

export function InvoiceAuditProblemResolutionStateToJSON(value?: InvoiceAuditProblemResolutionState | null): any {
    return value as any;
}

