import { Button } from "antd";
import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { useCompaniesApi } from "../Apis/Apis";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import {
  unstarAllCompanies,
  useStarredCompanies,
} from "../Helpers/starredCompanies";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import { ListCompaniesRow } from "../generated-openapi-client";
import { CompaniesTable } from "./ListCompaniesScreen";

export function StarredCompaniesScreen() {
  const createCompaniesApi = useCompaniesApi();
  const [companies, setCompanies] = useState<ListCompaniesRow[] | undefined>(
    undefined
  );
  const [error, setError] = useState();
  const starredCompanies = useStarredCompanies();
  const [, setLastAlterTime] = useState(new Date().valueOf());

  useOnce(async function () {
    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.listCompaniesByIds({
        companyIds: starredCompanies.map((o) => o.companyId),
      });
      setCompanies(response);
    } catch (e: any) {
      setError(e);
    }
  });

  if (error !== undefined) {
    return <>❌ An error occured : {JSON.stringify(error, null, "\t")}</>;
  } else if (companies === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title="⭐️ Starred Companies"
          subtitle="These are companies that you have starred. This list is only visible to you. You might want to star a company to remember to follow up, or not forget about an issue."
          tags={[]}
          extra={[
            <HorizontalStack align="right" width="100%">
              <Button
                disabled={starredCompanies.length === 0}
                onClick={function () {
                  unstarAllCompanies();
                  setLastAlterTime(new Date().valueOf());
                }}
              >
                ❌ Unstar All
              </Button>
            </HorizontalStack>,
          ]}
        >
          <PageTitle>⭐️ Starred Companies</PageTitle>
          <BrowserView>
            <Spacer height={32} />
          </BrowserView>

          <CompaniesTable allCompanies={companies} companies={companies} />
        </Page>
      </>
    );
  }
}
