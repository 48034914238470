import { Select } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../Apis/Apis";
import { ListCompaniesRow } from "../generated-openapi-client/models/ListCompaniesRow";
import { useOnce } from "../Hooks/useOnce";

const { Option } = Select;

interface CompaniesDropdownProps {
  companyId: string | null | undefined;
  setCompanyId: (_: string | undefined) => void;
  disabled?: boolean;
}

export function CompaniesDropdown(props: CompaniesDropdownProps) {
  const createCompaniesApi = useCompaniesApi();
  const [companies, setCompanies] = useState<ListCompaniesRow[] | undefined>();
  const { companyId } = props;
  const disabled = props.disabled;

  useOnce(async function () {
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.listAllCompanies();
    setCompanies(response);
  });

  if (companies === undefined) {
    return <>Loading...</>;
  }

  return (
    <Select
      disabled={disabled}
      allowClear
      showSearch
      style={{ width: 220 }}
      placeholder="Company"
      value={companyId}
      optionFilterProp="children"
      onSelect={function (companyId: string) {
        props.setCompanyId(companyId);
      }}
      onClear={function () {
        props.setCompanyId(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-ignore
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-ignore
        optionA.children
          // @ts-ignore
          .toLowerCase()
          // @ts-ignore
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {companies.map(function (c) {
        return (
          <Option key={c.companyId} value={c.companyId}>
            {c.companyName}
          </Option>
        );
      })}
    </Select>
  );
}
