import { InputNumber } from "antd";
import { CurrencyCode } from "../generated-openapi-client";

interface CurrencyInputProps {
  amount: number;
  setAmount: (_: number) => void;
  currency: CurrencyCode;
}

export function CurrencyInput(props: CurrencyInputProps) {
  return (
    <InputNumber
      prefix={`${props.currency}$`}
      value={props.amount}
      onChange={props.setAmount}
      style={{ width: "250px" }}
    />
  );
}
