/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QualificationStatus } from './QualificationStatus';
import {
    QualificationStatusFromJSON,
    QualificationStatusFromJSONTyped,
    QualificationStatusToJSON,
} from './QualificationStatus';

/**
 * 
 * @export
 * @interface ListSalesContactsRow
 */
export interface ListSalesContactsRow {
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    salesContactId: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    temperature?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    shipmentFrequency?: string;
    /**
     * 
     * @type {QualificationStatus}
     * @memberof ListSalesContactsRow
     */
    qualificationStatus: QualificationStatus;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalesContactsRow
     */
    description?: string;
}

/**
 * Check if a given object implements the ListSalesContactsRow interface.
 */
export function instanceOfListSalesContactsRow(value: object): boolean {
    if (!('salesContactId' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('companyName' in value)) return false;
    if (!('qualificationStatus' in value)) return false;
    return true;
}

export function ListSalesContactsRowFromJSON(json: any): ListSalesContactsRow {
    return ListSalesContactsRowFromJSONTyped(json, false);
}

export function ListSalesContactsRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSalesContactsRow {
    if (json == null) {
        return json;
    }
    return {
        
        'salesContactId': json['salesContactId'],
        'companyId': json['companyId'],
        'companyName': json['companyName'],
        'temperature': json['temperature'] == null ? undefined : json['temperature'],
        'shipmentFrequency': json['shipmentFrequency'] == null ? undefined : json['shipmentFrequency'],
        'qualificationStatus': QualificationStatusFromJSON(json['qualificationStatus']),
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'mobilePhone': json['mobilePhone'] == null ? undefined : json['mobilePhone'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function ListSalesContactsRowToJSON(value?: ListSalesContactsRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'salesContactId': value['salesContactId'],
        'companyId': value['companyId'],
        'companyName': value['companyName'],
        'temperature': value['temperature'],
        'shipmentFrequency': value['shipmentFrequency'],
        'qualificationStatus': QualificationStatusToJSON(value['qualificationStatus']),
        'name': value['name'],
        'email': value['email'],
        'phone': value['phone'],
        'mobilePhone': value['mobilePhone'],
        'description': value['description'],
    };
}

