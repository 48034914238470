/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdditionalCharge,
  AdditionalChargeWaivedCause,
  AutochargeFrequency,
  CurrencyCode,
  CustomerInvoiceData,
  MismatchedCustomerInvoices,
  PayInvoiceResponse,
  PaymentMethod,
} from '../models/index';
import {
    AdditionalChargeFromJSON,
    AdditionalChargeToJSON,
    AdditionalChargeWaivedCauseFromJSON,
    AdditionalChargeWaivedCauseToJSON,
    AutochargeFrequencyFromJSON,
    AutochargeFrequencyToJSON,
    CurrencyCodeFromJSON,
    CurrencyCodeToJSON,
    CustomerInvoiceDataFromJSON,
    CustomerInvoiceDataToJSON,
    MismatchedCustomerInvoicesFromJSON,
    MismatchedCustomerInvoicesToJSON,
    PayInvoiceResponseFromJSON,
    PayInvoiceResponseToJSON,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
} from '../models/index';

export interface ApproveAdditionalChargeRequest {
    additionalChargeId: string;
    reason: string;
    amount: number;
    associatedProblemIds: Array<string>;
}

export interface CalculateAdditionalChargeRequest {
    shipmentId: string;
    amount: number;
    currency: CurrencyCode;
}

export interface ChangeAmountRequest {
    customerInvoiceId: string;
    amount: number;
}

export interface ChangeDescriptionRequest {
    customerInvoiceId: string;
    description: string;
}

export interface ChangeDueDateRequest {
    customerInvoiceId: string;
    newDueDate: string;
}

export interface CreateInvoiceRequest {
    shipmentId: string;
    reason: string;
    amount: number;
    processPaymentImmediately: boolean;
    markDisputed: boolean;
}

export interface CreateXeroInvoiceRequest {
    customerInvoiceId: string;
}

export interface DeleteAdditionalChargeRequest {
    additionalChargeId: string;
}

export interface DeleteVoidedCustomerInvoiceRequest {
    customerInvoiceId: string;
}

export interface GenerateAndStoreInvoicePdfRequest {
    customerInvoiceId: string;
}

export interface InvoiceAndPotentiallyChargeCustomerForShipmentRequest {
    shipmentId: string;
}

export interface MarkInvoiceDisputedRequest {
    customerInvoiceId: string;
    disputed: boolean;
}

export interface MarkInvoiceSettledRequest {
    customerInvoiceId: string;
    reason: string;
}

export interface MarkInvoiceSettlementPendingByOtherMethodRequest {
    customerInvoiceId: string;
    paymentMethod: PaymentMethod;
    paymentReference: string;
}

export interface MarkIssuedSettlementPendingInvoiceRequest {
    customerInvoiceId: string;
    reason: string;
}

export interface PayInvoiceRequest {
    customerInvoiceId: string;
}

export interface ReduceOrRefundPartialInvoiceRequest {
    customerInvoiceId: string;
    amount: number;
}

export interface SendInvoiceCreatedNotificationRequest {
    customerInvoiceId: string;
}

export interface SendOverdueInvoiceNotificationRequest {
    customerInvoiceId: string;
}

export interface SetAutoChargeFrequencyRequest {
    customerInvoiceId: string;
    autochargeFrequency: AutochargeFrequency;
}

export interface VoidOrRefundFullInvoiceRequest {
    customerInvoiceId: string;
}

export interface VoidSettlementPendingInvoiceRequest {
    customerInvoiceId: string;
    reason: string;
}

export interface WaiveAdditionalChargeRequest {
    additionalChargeId: string;
    reason: string;
    waivedReason: string;
    waivedCause: AdditionalChargeWaivedCause;
}

/**
 * 
 */
export class CustomerInvoiceApi extends runtime.BaseAPI {

    /**
     */
    async approveAdditionalChargeRaw(requestParameters: ApproveAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling approveAdditionalCharge().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling approveAdditionalCharge().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling approveAdditionalCharge().'
            );
        }

        if (requestParameters['associatedProblemIds'] == null) {
            throw new runtime.RequiredError(
                'associatedProblemIds',
                'Required parameter "associatedProblemIds" was null or undefined when calling approveAdditionalCharge().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['associatedProblemIds'] != null) {
            queryParameters['associatedProblemIds'] = requestParameters['associatedProblemIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/approve-additional-charge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async approveAdditionalCharge(requestParameters: ApproveAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveAdditionalChargeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async calculateAdditionalChargeRaw(requestParameters: CalculateAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling calculateAdditionalCharge().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling calculateAdditionalCharge().'
            );
        }

        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling calculateAdditionalCharge().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['currency'] != null) {
            queryParameters['currency'] = requestParameters['currency'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/calculate-additional-charge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async calculateAdditionalCharge(requestParameters: CalculateAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.calculateAdditionalChargeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAmountRaw(requestParameters: ChangeAmountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling changeAmount().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling changeAmount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/change-amount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeAmount(requestParameters: ChangeAmountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeAmountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeDescriptionRaw(requestParameters: ChangeDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling changeDescription().'
            );
        }

        if (requestParameters['description'] == null) {
            throw new runtime.RequiredError(
                'description',
                'Required parameter "description" was null or undefined when calling changeDescription().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/change-description`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeDescription(requestParameters: ChangeDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeDescriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeDueDateRaw(requestParameters: ChangeDueDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling changeDueDate().'
            );
        }

        if (requestParameters['newDueDate'] == null) {
            throw new runtime.RequiredError(
                'newDueDate',
                'Required parameter "newDueDate" was null or undefined when calling changeDueDate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['newDueDate'] != null) {
            queryParameters['newDueDate'] = requestParameters['newDueDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/change-due-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeDueDate(requestParameters: ChangeDueDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeDueDateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createInvoiceRaw(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling createInvoice().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling createInvoice().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling createInvoice().'
            );
        }

        if (requestParameters['processPaymentImmediately'] == null) {
            throw new runtime.RequiredError(
                'processPaymentImmediately',
                'Required parameter "processPaymentImmediately" was null or undefined when calling createInvoice().'
            );
        }

        if (requestParameters['markDisputed'] == null) {
            throw new runtime.RequiredError(
                'markDisputed',
                'Required parameter "markDisputed" was null or undefined when calling createInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['processPaymentImmediately'] != null) {
            queryParameters['processPaymentImmediately'] = requestParameters['processPaymentImmediately'];
        }

        if (requestParameters['markDisputed'] != null) {
            queryParameters['markDisputed'] = requestParameters['markDisputed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/create-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createInvoice(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createXeroInvoiceRaw(requestParameters: CreateXeroInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling createXeroInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/create-xero-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createXeroInvoice(requestParameters: CreateXeroInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createXeroInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteAdditionalChargeRaw(requestParameters: DeleteAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling deleteAdditionalCharge().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/delete-additional-charge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAdditionalCharge(requestParameters: DeleteAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdditionalChargeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteVoidedCustomerInvoiceRaw(requestParameters: DeleteVoidedCustomerInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling deleteVoidedCustomerInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/delete-voided-customer-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteVoidedCustomerInvoice(requestParameters: DeleteVoidedCustomerInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVoidedCustomerInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateAndStoreInvoicePdfRaw(requestParameters: GenerateAndStoreInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling generateAndStoreInvoicePdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/generate-and-store-invoice-pdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async generateAndStoreInvoicePdf(requestParameters: GenerateAndStoreInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateAndStoreInvoicePdfRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getMismatchedCustomerInvoicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MismatchedCustomerInvoices>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/get-mismatched-customer-invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MismatchedCustomerInvoicesFromJSON(jsonValue));
    }

    /**
     */
    async getMismatchedCustomerInvoices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MismatchedCustomerInvoices> {
        const response = await this.getMismatchedCustomerInvoicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpenAdditionalChargesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdditionalCharge>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/get-open-additional-charges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdditionalChargeFromJSON));
    }

    /**
     */
    async getOpenAdditionalCharges(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdditionalCharge>> {
        const response = await this.getOpenAdditionalChargesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async invoiceAndPotentiallyChargeCustomerForShipmentRaw(requestParameters: InvoiceAndPotentiallyChargeCustomerForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling invoiceAndPotentiallyChargeCustomerForShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/invoice-and-potentially-charge-customer-for-shipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async invoiceAndPotentiallyChargeCustomerForShipment(requestParameters: InvoiceAndPotentiallyChargeCustomerForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invoiceAndPotentiallyChargeCustomerForShipmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markInvoiceDisputedRaw(requestParameters: MarkInvoiceDisputedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling markInvoiceDisputed().'
            );
        }

        if (requestParameters['disputed'] == null) {
            throw new runtime.RequiredError(
                'disputed',
                'Required parameter "disputed" was null or undefined when calling markInvoiceDisputed().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['disputed'] != null) {
            queryParameters['disputed'] = requestParameters['disputed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/mark-invoice-disputed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markInvoiceDisputed(requestParameters: MarkInvoiceDisputedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markInvoiceDisputedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markInvoiceSettledRaw(requestParameters: MarkInvoiceSettledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling markInvoiceSettled().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling markInvoiceSettled().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/mark-invoice-settled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markInvoiceSettled(requestParameters: MarkInvoiceSettledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markInvoiceSettledRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markInvoiceSettlementPendingByOtherMethodRaw(requestParameters: MarkInvoiceSettlementPendingByOtherMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling markInvoiceSettlementPendingByOtherMethod().'
            );
        }

        if (requestParameters['paymentMethod'] == null) {
            throw new runtime.RequiredError(
                'paymentMethod',
                'Required parameter "paymentMethod" was null or undefined when calling markInvoiceSettlementPendingByOtherMethod().'
            );
        }

        if (requestParameters['paymentReference'] == null) {
            throw new runtime.RequiredError(
                'paymentReference',
                'Required parameter "paymentReference" was null or undefined when calling markInvoiceSettlementPendingByOtherMethod().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['paymentMethod'] != null) {
            queryParameters['paymentMethod'] = requestParameters['paymentMethod'];
        }

        if (requestParameters['paymentReference'] != null) {
            queryParameters['paymentReference'] = requestParameters['paymentReference'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/mark-invoice-settlement-pending-by-other-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markInvoiceSettlementPendingByOtherMethod(requestParameters: MarkInvoiceSettlementPendingByOtherMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markInvoiceSettlementPendingByOtherMethodRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markIssuedSettlementPendingInvoiceRaw(requestParameters: MarkIssuedSettlementPendingInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling markIssuedSettlementPendingInvoice().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling markIssuedSettlementPendingInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/mark-issued-settlement-pending-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markIssuedSettlementPendingInvoice(requestParameters: MarkIssuedSettlementPendingInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markIssuedSettlementPendingInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async payInvoiceRaw(requestParameters: PayInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayInvoiceResponse>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling payInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/pay-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayInvoiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async payInvoice(requestParameters: PayInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayInvoiceResponse> {
        const response = await this.payInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reduceOrRefundPartialInvoiceRaw(requestParameters: ReduceOrRefundPartialInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling reduceOrRefundPartialInvoice().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling reduceOrRefundPartialInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/reduce-or-refund-partial-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reduceOrRefundPartialInvoice(requestParameters: ReduceOrRefundPartialInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reduceOrRefundPartialInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendInvoiceCreatedNotificationRaw(requestParameters: SendInvoiceCreatedNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling sendInvoiceCreatedNotification().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/send-invoice-created-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendInvoiceCreatedNotification(requestParameters: SendInvoiceCreatedNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendInvoiceCreatedNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendOverdueInvoiceNotificationRaw(requestParameters: SendOverdueInvoiceNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling sendOverdueInvoiceNotification().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/send-overdue-invoice-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendOverdueInvoiceNotification(requestParameters: SendOverdueInvoiceNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendOverdueInvoiceNotificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setAutoChargeFrequencyRaw(requestParameters: SetAutoChargeFrequencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling setAutoChargeFrequency().'
            );
        }

        if (requestParameters['autochargeFrequency'] == null) {
            throw new runtime.RequiredError(
                'autochargeFrequency',
                'Required parameter "autochargeFrequency" was null or undefined when calling setAutoChargeFrequency().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['autochargeFrequency'] != null) {
            queryParameters['autochargeFrequency'] = requestParameters['autochargeFrequency'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/set-autocharge-frwquency`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setAutoChargeFrequency(requestParameters: SetAutoChargeFrequencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setAutoChargeFrequencyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async viewCustomerInvoicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerInvoiceData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/view-customer-invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerInvoiceDataFromJSON(jsonValue));
    }

    /**
     */
    async viewCustomerInvoices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerInvoiceData> {
        const response = await this.viewCustomerInvoicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async voidOrRefundFullInvoiceRaw(requestParameters: VoidOrRefundFullInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling voidOrRefundFullInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/void-or-refund-full-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async voidOrRefundFullInvoice(requestParameters: VoidOrRefundFullInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voidOrRefundFullInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async voidSettlementPendingInvoiceRaw(requestParameters: VoidSettlementPendingInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling voidSettlementPendingInvoice().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling voidSettlementPendingInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/void-settlement-pending-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async voidSettlementPendingInvoice(requestParameters: VoidSettlementPendingInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voidSettlementPendingInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async waiveAdditionalChargeRaw(requestParameters: WaiveAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling waiveAdditionalCharge().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling waiveAdditionalCharge().'
            );
        }

        if (requestParameters['waivedReason'] == null) {
            throw new runtime.RequiredError(
                'waivedReason',
                'Required parameter "waivedReason" was null or undefined when calling waiveAdditionalCharge().'
            );
        }

        if (requestParameters['waivedCause'] == null) {
            throw new runtime.RequiredError(
                'waivedCause',
                'Required parameter "waivedCause" was null or undefined when calling waiveAdditionalCharge().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        if (requestParameters['waivedReason'] != null) {
            queryParameters['waivedReason'] = requestParameters['waivedReason'];
        }

        if (requestParameters['waivedCause'] != null) {
            queryParameters['waivedCause'] = requestParameters['waivedCause'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/customer-invoice/waive-additional-charge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async waiveAdditionalCharge(requestParameters: WaiveAdditionalChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.waiveAdditionalChargeRaw(requestParameters, initOverrides);
    }

}
