import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { Contact } from "../../generated-openapi-client";
import { propertiesOf } from "../../Helpers/propertyOf";
import { useDisplay } from "../../Hooks/useDisplay";

interface EditEmergencyContactButtonProps {
  contact: Contact;
  shipmentId: string;
  companyId: string;
  onRefresh: () => Promise<void>;
}

export function EditEmergencyContactButton({
  contact,
  shipmentId,
  companyId,
  onRefresh,
}: EditEmergencyContactButtonProps) {
  const getName = propertiesOf(contact);
  const [form] = Form.useForm<Contact>();
  const [loading, setLoading] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const display = useDisplay();

  async function updateEmergencyContact(values: Contact) {
    if (!values.contactName || !values.phoneNumber) {
      message.error("Something went wrong!");
      throw Error("Contact Name/Phone Number should not be undefined");
    }

    setLoading(true);
    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.updateEmergencyContact({
        shipmentId,
        companyId,
        phoneNumberExtension: values.phoneNumberExtension,
        phoneNumber: values.phoneNumber,
        contactName: values.contactName,
      });
      await onRefresh();
      display.hide();
      message.success("Emergency contact updated!");
    } catch (e) {
      message.error("Something went wrong!");
      console.error(e);
    }
    setLoading(false);
  }

  function handleOk() {
    form
      .validateFields()
      .then((values) => updateEmergencyContact(values))
      .catch((e) => console.error(e));
  }

  function handleCancel() {
    form.resetFields();
    display.hide();
  }

  return (
    <>
      <Modal
        open={display.status}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        okText="Save"
        title="Edit Emergency Contact"
        destroyOnClose
        width={800}
      >
        <Form
          style={{ width: "700px" }}
          form={form}
          initialValues={{ ...contact }}
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 32, offset: 1 }}
        >
          <Form.Item
            name={getName("contactName")}
            label="Contact Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input the contact name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={getName("phoneNumber")}
            label="Phone Number"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input the phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={getName("phoneNumberExtension")}
            label="Phone Number Extension"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        onClick={display.show}
        type="link"
        style={{ margin: 0, padding: 0 }}
      >
        Change
      </Button>
    </>
  );
}
