/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CompaniesBookedVsQuotedReport,
  CompaniesReport,
  Company,
  CompanyData,
  CompanyNote,
  CompanyQuoteReportLine,
  CompanyShipmentQuote,
  CreateCompanyNoteInput,
  CreateCompanyResponse,
  CreateSalesContactInput,
  CreateSalesContactResponse,
  EditCompanyInput,
  EditContactInput,
  IndivualShipmentLine,
  Invitation,
  ListCompaniesInput,
  ListCompaniesRow,
  ListSalesContactsRow,
  LogSalesCallInput,
  ProvisionDashboardUserResponse,
  SendSalesEmailInput,
  SendSalesEmailResponse,
  ShipmentReport,
  User,
} from '../models/index';
import {
    CompaniesBookedVsQuotedReportFromJSON,
    CompaniesBookedVsQuotedReportToJSON,
    CompaniesReportFromJSON,
    CompaniesReportToJSON,
    CompanyFromJSON,
    CompanyToJSON,
    CompanyDataFromJSON,
    CompanyDataToJSON,
    CompanyNoteFromJSON,
    CompanyNoteToJSON,
    CompanyQuoteReportLineFromJSON,
    CompanyQuoteReportLineToJSON,
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteToJSON,
    CreateCompanyNoteInputFromJSON,
    CreateCompanyNoteInputToJSON,
    CreateCompanyResponseFromJSON,
    CreateCompanyResponseToJSON,
    CreateSalesContactInputFromJSON,
    CreateSalesContactInputToJSON,
    CreateSalesContactResponseFromJSON,
    CreateSalesContactResponseToJSON,
    EditCompanyInputFromJSON,
    EditCompanyInputToJSON,
    EditContactInputFromJSON,
    EditContactInputToJSON,
    IndivualShipmentLineFromJSON,
    IndivualShipmentLineToJSON,
    InvitationFromJSON,
    InvitationToJSON,
    ListCompaniesInputFromJSON,
    ListCompaniesInputToJSON,
    ListCompaniesRowFromJSON,
    ListCompaniesRowToJSON,
    ListSalesContactsRowFromJSON,
    ListSalesContactsRowToJSON,
    LogSalesCallInputFromJSON,
    LogSalesCallInputToJSON,
    ProvisionDashboardUserResponseFromJSON,
    ProvisionDashboardUserResponseToJSON,
    SendSalesEmailInputFromJSON,
    SendSalesEmailInputToJSON,
    SendSalesEmailResponseFromJSON,
    SendSalesEmailResponseToJSON,
    ShipmentReportFromJSON,
    ShipmentReportToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface AttachUserToCompanyRequest {
    companyId: string;
}

export interface ClearSalesCallRequest {
    companyNoteId: string;
}

export interface CompaniesBookedVsQuotedReportRequest {
    startDate: string;
    endDate: string;
    leadSource?: string;
}

export interface CompanyQuoteReportRequest {
    companyId: string;
    startDate: string;
    endDate: string;
}

export interface CreateCompanyRequest {
    companyName: string;
}

export interface CreateCompanyNoteRequest {
    createCompanyNoteInput: CreateCompanyNoteInput;
}

export interface CreateCouponRequest {
    companyId: string;
    amount: number;
}

export interface CreateSalesContactRequest {
    createSalesContactInput: CreateSalesContactInput;
}

export interface DeleteCompanyRequest {
    companyId: string;
}

export interface DeleteCompanyNoteRequest {
    companyNoteId: string;
}

export interface DeleteSalesContactRequest {
    salesContactId: string;
}

export interface DeleteUserRequest {
    userEmail: string;
}

export interface EditCompanyRequest {
    editCompanyInput: EditCompanyInput;
}

export interface EditContactRequest {
    editContactInput: EditContactInput;
}

export interface GenerateClaudeCompanyAnalysisRequest {
    companyId: string;
}

export interface GetBookedShipmentsForCompanyRequest {
    companyId: string;
}

export interface GetCompanyRequest {
    companyId: string;
}

export interface GetCompanyUsersRequest {
    companyId: string;
}

export interface GetShipmentReportsForCompanyRequest {
    companyId: string;
}

export interface ListCompaniesRequest {
    listCompaniesInput: ListCompaniesInput;
}

export interface ListCompaniesByIdsRequest {
    companyIds: Array<string>;
}

export interface ListInvitationsByCompanyRequest {
    companyId: string;
}

export interface ListSalesCallsRequest {
    date: string;
}

export interface LogSalesCallRequest {
    logSalesCallInput: LogSalesCallInput;
}

export interface MoveAllCompanyNotesToAnotherCompanyRequest {
    originalCompanyId: string;
    newCompanyId: string;
}

export interface MoveAllShipmentToAnotherCompanyRequest {
    originalCompanyId: string;
    newCompanyId: string;
}

export interface MoveSalesContactToDifferentCompanyRequest {
    salesContactId: string;
    newCompanyId: string;
}

export interface MoveUserToDifferentCompanyRequest {
    userEmail: string;
    newCompanyId: string;
}

export interface PreLogSalesCallRequest {
    companyId: string;
    salesContactId: string;
}

export interface ProvisionDashboardUserRequest {
    salesContactId: string;
}

export interface RenameCompanyRequest {
    companyId: string;
    newCompanyName: string;
}

export interface RevokeInvitationRequest {
    invitationToken: string;
}

export interface SendEmailRequest {
    sendSalesEmailInput: SendSalesEmailInput;
}

export interface SetAccountTypeForCompanyRequest {
    companyId: string;
    isPersonal: boolean;
    isBroker: boolean;
}

export interface SetCreditTermsForCompanyRequest {
    companyId: string;
    paymentTermsDays: number;
    creditLimitAmount: number;
}

export interface SetDiscountAndFixedMarkupRequest {
    companyId: string;
    discountPercentage: number;
    fixedMarkup: number;
}

export interface SetDoNotAutoChargeRequest {
    companyId: string;
    doNotAutocharge: boolean;
}

export interface SetHasNonProfessionalDomainRequest {
    companyId: string;
    hasNonProfessionalDomain: boolean;
}

export interface SetNeedsVerificationRequest {
    companyId: string;
    needsVerification: boolean;
}

export interface SetPermittedAdditionalPaymentMethodsRequest {
    companyId: string;
    isChequePaymentPermitted: boolean;
    isWirePaymentPermitted: boolean;
    doNotAutochargeOverdueInvoices: boolean;
}

export interface SetQuotingDisabledForCompanyRequest {
    companyId: string;
    quotingDisabled: boolean;
}

export interface SetVerificationDeniedRequest {
    companyId: string;
    verificationDenied: boolean;
}

export interface SnoozeSalesCallsUntilRequest {
    companyId: string;
    snoozeSalesCallsUntilDate: string;
}

export interface UpdateInvitationExpirationDateRequest {
    invitationToken: string;
    expirationDate: string;
}

export interface UpdatePrimaryContactForCompanyRequest {
    companyId: string;
    salesContactId: string;
}

export interface ViewCompanyDataRequest {
    companyId: string;
}

export interface ViewIndividualShipmentsRequest {
    companyId: string;
    shipmentHash: string;
}

export interface VoidCouponRequest {
    couponId: string;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     */
    async attachUserToCompanyRaw(requestParameters: AttachUserToCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling attachUserToCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/attach-user-to-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async attachUserToCompany(requestParameters: AttachUserToCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.attachUserToCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async clearSalesCallRaw(requestParameters: ClearSalesCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyNoteId'] == null) {
            throw new runtime.RequiredError(
                'companyNoteId',
                'Required parameter "companyNoteId" was null or undefined when calling clearSalesCall().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyNoteId'] != null) {
            queryParameters['companyNoteId'] = requestParameters['companyNoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/clear-sales-call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clearSalesCall(requestParameters: ClearSalesCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearSalesCallRaw(requestParameters, initOverrides);
    }

    /**
     */
    async companiesBookedVsQuotedReportRaw(requestParameters: CompaniesBookedVsQuotedReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompaniesBookedVsQuotedReport>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling companiesBookedVsQuotedReport().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling companiesBookedVsQuotedReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['leadSource'] != null) {
            queryParameters['leadSource'] = requestParameters['leadSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/companies-booked-vs-quoted-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompaniesBookedVsQuotedReportFromJSON(jsonValue));
    }

    /**
     */
    async companiesBookedVsQuotedReport(requestParameters: CompaniesBookedVsQuotedReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompaniesBookedVsQuotedReport> {
        const response = await this.companiesBookedVsQuotedReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companiesReportRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompaniesReport>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/companies-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompaniesReportFromJSON(jsonValue));
    }

    /**
     */
    async companiesReport(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompaniesReport> {
        const response = await this.companiesReportRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async companyQuoteReportRaw(requestParameters: CompanyQuoteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyQuoteReportLine>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling companyQuoteReport().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling companyQuoteReport().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling companyQuoteReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/company-quote-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyQuoteReportLineFromJSON));
    }

    /**
     */
    async companyQuoteReport(requestParameters: CompanyQuoteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyQuoteReportLine>> {
        const response = await this.companyQuoteReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCompanyResponse>> {
        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling createCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyName'] != null) {
            queryParameters['companyName'] = requestParameters['companyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/create-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCompanyResponseFromJSON(jsonValue));
    }

    /**
     */
    async createCompany(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCompanyResponse> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCompanyNoteRaw(requestParameters: CreateCompanyNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createCompanyNoteInput'] == null) {
            throw new runtime.RequiredError(
                'createCompanyNoteInput',
                'Required parameter "createCompanyNoteInput" was null or undefined when calling createCompanyNote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/create-company-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyNoteInputToJSON(requestParameters['createCompanyNoteInput']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createCompanyNote(requestParameters: CreateCompanyNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCompanyNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createCouponRaw(requestParameters: CreateCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling createCoupon().'
            );
        }

        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling createCoupon().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/create-coupon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createCoupon(requestParameters: CreateCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCouponRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createSalesContactRaw(requestParameters: CreateSalesContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSalesContactResponse>> {
        if (requestParameters['createSalesContactInput'] == null) {
            throw new runtime.RequiredError(
                'createSalesContactInput',
                'Required parameter "createSalesContactInput" was null or undefined when calling createSalesContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/create-sales-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSalesContactInputToJSON(requestParameters['createSalesContactInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSalesContactResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSalesContact(requestParameters: CreateSalesContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSalesContactResponse> {
        const response = await this.createSalesContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling deleteCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/delete-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteCompanyNoteRaw(requestParameters: DeleteCompanyNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyNoteId'] == null) {
            throw new runtime.RequiredError(
                'companyNoteId',
                'Required parameter "companyNoteId" was null or undefined when calling deleteCompanyNote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyNoteId'] != null) {
            queryParameters['companyNoteId'] = requestParameters['companyNoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/delete-company-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompanyNote(requestParameters: DeleteCompanyNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyNoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSalesContactRaw(requestParameters: DeleteSalesContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['salesContactId'] == null) {
            throw new runtime.RequiredError(
                'salesContactId',
                'Required parameter "salesContactId" was null or undefined when calling deleteSalesContact().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['salesContactId'] != null) {
            queryParameters['salesContactId'] = requestParameters['salesContactId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/delete-sales-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSalesContact(requestParameters: DeleteSalesContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSalesContactRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userEmail'] == null) {
            throw new runtime.RequiredError(
                'userEmail',
                'Required parameter "userEmail" was null or undefined when calling deleteUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userEmail'] != null) {
            queryParameters['userEmail'] = requestParameters['userEmail'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/delete-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editCompanyRaw(requestParameters: EditCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['editCompanyInput'] == null) {
            throw new runtime.RequiredError(
                'editCompanyInput',
                'Required parameter "editCompanyInput" was null or undefined when calling editCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/edit-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditCompanyInputToJSON(requestParameters['editCompanyInput']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async editCompany(requestParameters: EditCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editContactRaw(requestParameters: EditContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['editContactInput'] == null) {
            throw new runtime.RequiredError(
                'editContactInput',
                'Required parameter "editContactInput" was null or undefined when calling editContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/edit-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditContactInputToJSON(requestParameters['editContactInput']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async editContact(requestParameters: EditContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editContactRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateClaudeCompanyAnalysisRaw(requestParameters: GenerateClaudeCompanyAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling generateClaudeCompanyAnalysis().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/generate-claude-company-analysis`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async generateClaudeCompanyAnalysis(requestParameters: GenerateClaudeCompanyAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.generateClaudeCompanyAnalysisRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getBookedShipmentsForCompanyRaw(requestParameters: GetBookedShipmentsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyShipmentQuote>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getBookedShipmentsForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/get-booked-shipments-for-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyShipmentQuoteFromJSON));
    }

    /**
     */
    async getBookedShipmentsForCompany(requestParameters: GetBookedShipmentsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyShipmentQuote>> {
        const response = await this.getBookedShipmentsForCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/get-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompanyUsersRaw(requestParameters: GetCompanyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getCompanyUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/get-company-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async getCompanyUsers(requestParameters: GetCompanyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.getCompanyUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getShipmentReportsForCompanyRaw(requestParameters: GetShipmentReportsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShipmentReport>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getShipmentReportsForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/get-shipment-reports-for-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShipmentReportFromJSON));
    }

    /**
     */
    async getShipmentReportsForCompany(requestParameters: GetShipmentReportsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShipmentReport>> {
        const response = await this.getShipmentReportsForCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listAllCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListCompaniesRow>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/list-all-companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListCompaniesRowFromJSON));
    }

    /**
     */
    async listAllCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListCompaniesRow>> {
        const response = await this.listAllCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listCompaniesRaw(requestParameters: ListCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListCompaniesRow>>> {
        if (requestParameters['listCompaniesInput'] == null) {
            throw new runtime.RequiredError(
                'listCompaniesInput',
                'Required parameter "listCompaniesInput" was null or undefined when calling listCompanies().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/list-companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListCompaniesInputToJSON(requestParameters['listCompaniesInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListCompaniesRowFromJSON));
    }

    /**
     */
    async listCompanies(requestParameters: ListCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListCompaniesRow>> {
        const response = await this.listCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listCompaniesByIdsRaw(requestParameters: ListCompaniesByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListCompaniesRow>>> {
        if (requestParameters['companyIds'] == null) {
            throw new runtime.RequiredError(
                'companyIds',
                'Required parameter "companyIds" was null or undefined when calling listCompaniesByIds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyIds'] != null) {
            queryParameters['companyIds'] = requestParameters['companyIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/list-companies-by-ids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListCompaniesRowFromJSON));
    }

    /**
     */
    async listCompaniesByIds(requestParameters: ListCompaniesByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListCompaniesRow>> {
        const response = await this.listCompaniesByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listInvitationsByCompanyRaw(requestParameters: ListInvitationsByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invitation>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling listInvitationsByCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/list-invitations-by-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvitationFromJSON));
    }

    /**
     */
    async listInvitationsByCompany(requestParameters: ListInvitationsByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invitation>> {
        const response = await this.listInvitationsByCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSalesCallsRaw(requestParameters: ListSalesCallsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyNote>>> {
        if (requestParameters['date'] == null) {
            throw new runtime.RequiredError(
                'date',
                'Required parameter "date" was null or undefined when calling listSalesCalls().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/list-sales-calls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyNoteFromJSON));
    }

    /**
     */
    async listSalesCalls(requestParameters: ListSalesCallsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyNote>> {
        const response = await this.listSalesCallsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listSalesContactsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListSalesContactsRow>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/list-sales-contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListSalesContactsRowFromJSON));
    }

    /**
     */
    async listSalesContacts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListSalesContactsRow>> {
        const response = await this.listSalesContactsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async logSalesCallRaw(requestParameters: LogSalesCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['logSalesCallInput'] == null) {
            throw new runtime.RequiredError(
                'logSalesCallInput',
                'Required parameter "logSalesCallInput" was null or undefined when calling logSalesCall().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/log-sales-call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogSalesCallInputToJSON(requestParameters['logSalesCallInput']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logSalesCall(requestParameters: LogSalesCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logSalesCallRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moveAllCompanyNotesToAnotherCompanyRaw(requestParameters: MoveAllCompanyNotesToAnotherCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['originalCompanyId'] == null) {
            throw new runtime.RequiredError(
                'originalCompanyId',
                'Required parameter "originalCompanyId" was null or undefined when calling moveAllCompanyNotesToAnotherCompany().'
            );
        }

        if (requestParameters['newCompanyId'] == null) {
            throw new runtime.RequiredError(
                'newCompanyId',
                'Required parameter "newCompanyId" was null or undefined when calling moveAllCompanyNotesToAnotherCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['originalCompanyId'] != null) {
            queryParameters['originalCompanyId'] = requestParameters['originalCompanyId'];
        }

        if (requestParameters['newCompanyId'] != null) {
            queryParameters['newCompanyId'] = requestParameters['newCompanyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/move-all-company-notes-to-another-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveAllCompanyNotesToAnotherCompany(requestParameters: MoveAllCompanyNotesToAnotherCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveAllCompanyNotesToAnotherCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moveAllShipmentToAnotherCompanyRaw(requestParameters: MoveAllShipmentToAnotherCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['originalCompanyId'] == null) {
            throw new runtime.RequiredError(
                'originalCompanyId',
                'Required parameter "originalCompanyId" was null or undefined when calling moveAllShipmentToAnotherCompany().'
            );
        }

        if (requestParameters['newCompanyId'] == null) {
            throw new runtime.RequiredError(
                'newCompanyId',
                'Required parameter "newCompanyId" was null or undefined when calling moveAllShipmentToAnotherCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['originalCompanyId'] != null) {
            queryParameters['originalCompanyId'] = requestParameters['originalCompanyId'];
        }

        if (requestParameters['newCompanyId'] != null) {
            queryParameters['newCompanyId'] = requestParameters['newCompanyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/move-all-shipment-to-another-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveAllShipmentToAnotherCompany(requestParameters: MoveAllShipmentToAnotherCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveAllShipmentToAnotherCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moveSalesContactToDifferentCompanyRaw(requestParameters: MoveSalesContactToDifferentCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['salesContactId'] == null) {
            throw new runtime.RequiredError(
                'salesContactId',
                'Required parameter "salesContactId" was null or undefined when calling moveSalesContactToDifferentCompany().'
            );
        }

        if (requestParameters['newCompanyId'] == null) {
            throw new runtime.RequiredError(
                'newCompanyId',
                'Required parameter "newCompanyId" was null or undefined when calling moveSalesContactToDifferentCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['salesContactId'] != null) {
            queryParameters['salesContactId'] = requestParameters['salesContactId'];
        }

        if (requestParameters['newCompanyId'] != null) {
            queryParameters['newCompanyId'] = requestParameters['newCompanyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/move-sales-contact-to-different-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveSalesContactToDifferentCompany(requestParameters: MoveSalesContactToDifferentCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveSalesContactToDifferentCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moveUserToDifferentCompanyRaw(requestParameters: MoveUserToDifferentCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userEmail'] == null) {
            throw new runtime.RequiredError(
                'userEmail',
                'Required parameter "userEmail" was null or undefined when calling moveUserToDifferentCompany().'
            );
        }

        if (requestParameters['newCompanyId'] == null) {
            throw new runtime.RequiredError(
                'newCompanyId',
                'Required parameter "newCompanyId" was null or undefined when calling moveUserToDifferentCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userEmail'] != null) {
            queryParameters['userEmail'] = requestParameters['userEmail'];
        }

        if (requestParameters['newCompanyId'] != null) {
            queryParameters['newCompanyId'] = requestParameters['newCompanyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/move-user-to-different-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveUserToDifferentCompany(requestParameters: MoveUserToDifferentCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moveUserToDifferentCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async preLogSalesCallRaw(requestParameters: PreLogSalesCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling preLogSalesCall().'
            );
        }

        if (requestParameters['salesContactId'] == null) {
            throw new runtime.RequiredError(
                'salesContactId',
                'Required parameter "salesContactId" was null or undefined when calling preLogSalesCall().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['salesContactId'] != null) {
            queryParameters['salesContactId'] = requestParameters['salesContactId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/pre-log-sales-call`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async preLogSalesCall(requestParameters: PreLogSalesCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.preLogSalesCallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async provisionDashboardUserRaw(requestParameters: ProvisionDashboardUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProvisionDashboardUserResponse>> {
        if (requestParameters['salesContactId'] == null) {
            throw new runtime.RequiredError(
                'salesContactId',
                'Required parameter "salesContactId" was null or undefined when calling provisionDashboardUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['salesContactId'] != null) {
            queryParameters['salesContactId'] = requestParameters['salesContactId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/provision-dashboard-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProvisionDashboardUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async provisionDashboardUser(requestParameters: ProvisionDashboardUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProvisionDashboardUserResponse> {
        const response = await this.provisionDashboardUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async renameCompanyRaw(requestParameters: RenameCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling renameCompany().'
            );
        }

        if (requestParameters['newCompanyName'] == null) {
            throw new runtime.RequiredError(
                'newCompanyName',
                'Required parameter "newCompanyName" was null or undefined when calling renameCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['newCompanyName'] != null) {
            queryParameters['newCompanyName'] = requestParameters['newCompanyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/rename-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async renameCompany(requestParameters: RenameCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.renameCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async revokeInvitationRaw(requestParameters: RevokeInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationToken'] == null) {
            throw new runtime.RequiredError(
                'invitationToken',
                'Required parameter "invitationToken" was null or undefined when calling revokeInvitation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['invitationToken'] != null) {
            queryParameters['invitationToken'] = requestParameters['invitationToken'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/revoke-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revokeInvitation(requestParameters: RevokeInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendEmailRaw(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendSalesEmailResponse>> {
        if (requestParameters['sendSalesEmailInput'] == null) {
            throw new runtime.RequiredError(
                'sendSalesEmailInput',
                'Required parameter "sendSalesEmailInput" was null or undefined when calling sendEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/companies/send-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendSalesEmailInputToJSON(requestParameters['sendSalesEmailInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendSalesEmailResponseFromJSON(jsonValue));
    }

    /**
     */
    async sendEmail(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendSalesEmailResponse> {
        const response = await this.sendEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAccountTypeForCompanyRaw(requestParameters: SetAccountTypeForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setAccountTypeForCompany().'
            );
        }

        if (requestParameters['isPersonal'] == null) {
            throw new runtime.RequiredError(
                'isPersonal',
                'Required parameter "isPersonal" was null or undefined when calling setAccountTypeForCompany().'
            );
        }

        if (requestParameters['isBroker'] == null) {
            throw new runtime.RequiredError(
                'isBroker',
                'Required parameter "isBroker" was null or undefined when calling setAccountTypeForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['isPersonal'] != null) {
            queryParameters['isPersonal'] = requestParameters['isPersonal'];
        }

        if (requestParameters['isBroker'] != null) {
            queryParameters['isBroker'] = requestParameters['isBroker'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-account-type-for-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setAccountTypeForCompany(requestParameters: SetAccountTypeForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setAccountTypeForCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setCreditTermsForCompanyRaw(requestParameters: SetCreditTermsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setCreditTermsForCompany().'
            );
        }

        if (requestParameters['paymentTermsDays'] == null) {
            throw new runtime.RequiredError(
                'paymentTermsDays',
                'Required parameter "paymentTermsDays" was null or undefined when calling setCreditTermsForCompany().'
            );
        }

        if (requestParameters['creditLimitAmount'] == null) {
            throw new runtime.RequiredError(
                'creditLimitAmount',
                'Required parameter "creditLimitAmount" was null or undefined when calling setCreditTermsForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['paymentTermsDays'] != null) {
            queryParameters['paymentTermsDays'] = requestParameters['paymentTermsDays'];
        }

        if (requestParameters['creditLimitAmount'] != null) {
            queryParameters['creditLimitAmount'] = requestParameters['creditLimitAmount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-credit-terms-for-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setCreditTermsForCompany(requestParameters: SetCreditTermsForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setCreditTermsForCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setDiscountAndFixedMarkupRaw(requestParameters: SetDiscountAndFixedMarkupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setDiscountAndFixedMarkup().'
            );
        }

        if (requestParameters['discountPercentage'] == null) {
            throw new runtime.RequiredError(
                'discountPercentage',
                'Required parameter "discountPercentage" was null or undefined when calling setDiscountAndFixedMarkup().'
            );
        }

        if (requestParameters['fixedMarkup'] == null) {
            throw new runtime.RequiredError(
                'fixedMarkup',
                'Required parameter "fixedMarkup" was null or undefined when calling setDiscountAndFixedMarkup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['discountPercentage'] != null) {
            queryParameters['discountPercentage'] = requestParameters['discountPercentage'];
        }

        if (requestParameters['fixedMarkup'] != null) {
            queryParameters['fixedMarkup'] = requestParameters['fixedMarkup'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-discount-and-fixed-markup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setDiscountAndFixedMarkup(requestParameters: SetDiscountAndFixedMarkupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDiscountAndFixedMarkupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setDoNotAutoChargeRaw(requestParameters: SetDoNotAutoChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setDoNotAutoCharge().'
            );
        }

        if (requestParameters['doNotAutocharge'] == null) {
            throw new runtime.RequiredError(
                'doNotAutocharge',
                'Required parameter "doNotAutocharge" was null or undefined when calling setDoNotAutoCharge().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['doNotAutocharge'] != null) {
            queryParameters['doNotAutocharge'] = requestParameters['doNotAutocharge'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-do-not-autocharge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setDoNotAutoCharge(requestParameters: SetDoNotAutoChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDoNotAutoChargeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setHasNonProfessionalDomainRaw(requestParameters: SetHasNonProfessionalDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setHasNonProfessionalDomain().'
            );
        }

        if (requestParameters['hasNonProfessionalDomain'] == null) {
            throw new runtime.RequiredError(
                'hasNonProfessionalDomain',
                'Required parameter "hasNonProfessionalDomain" was null or undefined when calling setHasNonProfessionalDomain().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['hasNonProfessionalDomain'] != null) {
            queryParameters['hasNonProfessionalDomain'] = requestParameters['hasNonProfessionalDomain'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-has-non-professional-domain`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setHasNonProfessionalDomain(requestParameters: SetHasNonProfessionalDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setHasNonProfessionalDomainRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setNeedsVerificationRaw(requestParameters: SetNeedsVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setNeedsVerification().'
            );
        }

        if (requestParameters['needsVerification'] == null) {
            throw new runtime.RequiredError(
                'needsVerification',
                'Required parameter "needsVerification" was null or undefined when calling setNeedsVerification().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['needsVerification'] != null) {
            queryParameters['needsVerification'] = requestParameters['needsVerification'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-needs-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setNeedsVerification(requestParameters: SetNeedsVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setNeedsVerificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setPermittedAdditionalPaymentMethodsRaw(requestParameters: SetPermittedAdditionalPaymentMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setPermittedAdditionalPaymentMethods().'
            );
        }

        if (requestParameters['isChequePaymentPermitted'] == null) {
            throw new runtime.RequiredError(
                'isChequePaymentPermitted',
                'Required parameter "isChequePaymentPermitted" was null or undefined when calling setPermittedAdditionalPaymentMethods().'
            );
        }

        if (requestParameters['isWirePaymentPermitted'] == null) {
            throw new runtime.RequiredError(
                'isWirePaymentPermitted',
                'Required parameter "isWirePaymentPermitted" was null or undefined when calling setPermittedAdditionalPaymentMethods().'
            );
        }

        if (requestParameters['doNotAutochargeOverdueInvoices'] == null) {
            throw new runtime.RequiredError(
                'doNotAutochargeOverdueInvoices',
                'Required parameter "doNotAutochargeOverdueInvoices" was null or undefined when calling setPermittedAdditionalPaymentMethods().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['isChequePaymentPermitted'] != null) {
            queryParameters['isChequePaymentPermitted'] = requestParameters['isChequePaymentPermitted'];
        }

        if (requestParameters['isWirePaymentPermitted'] != null) {
            queryParameters['isWirePaymentPermitted'] = requestParameters['isWirePaymentPermitted'];
        }

        if (requestParameters['doNotAutochargeOverdueInvoices'] != null) {
            queryParameters['doNotAutochargeOverdueInvoices'] = requestParameters['doNotAutochargeOverdueInvoices'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-permitted-additional-payment-methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setPermittedAdditionalPaymentMethods(requestParameters: SetPermittedAdditionalPaymentMethodsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPermittedAdditionalPaymentMethodsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setQuotingDisabledForCompanyRaw(requestParameters: SetQuotingDisabledForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setQuotingDisabledForCompany().'
            );
        }

        if (requestParameters['quotingDisabled'] == null) {
            throw new runtime.RequiredError(
                'quotingDisabled',
                'Required parameter "quotingDisabled" was null or undefined when calling setQuotingDisabledForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['quotingDisabled'] != null) {
            queryParameters['quotingDisabled'] = requestParameters['quotingDisabled'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-quoting-disabled-for-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setQuotingDisabledForCompany(requestParameters: SetQuotingDisabledForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setQuotingDisabledForCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setVerificationDeniedRaw(requestParameters: SetVerificationDeniedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling setVerificationDenied().'
            );
        }

        if (requestParameters['verificationDenied'] == null) {
            throw new runtime.RequiredError(
                'verificationDenied',
                'Required parameter "verificationDenied" was null or undefined when calling setVerificationDenied().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['verificationDenied'] != null) {
            queryParameters['verificationDenied'] = requestParameters['verificationDenied'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/set-verification-denied`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setVerificationDenied(requestParameters: SetVerificationDeniedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setVerificationDeniedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async snoozeSalesCallsUntilRaw(requestParameters: SnoozeSalesCallsUntilRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling snoozeSalesCallsUntil().'
            );
        }

        if (requestParameters['snoozeSalesCallsUntilDate'] == null) {
            throw new runtime.RequiredError(
                'snoozeSalesCallsUntilDate',
                'Required parameter "snoozeSalesCallsUntilDate" was null or undefined when calling snoozeSalesCallsUntil().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['snoozeSalesCallsUntilDate'] != null) {
            queryParameters['snoozeSalesCallsUntilDate'] = requestParameters['snoozeSalesCallsUntilDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/snooze-sales-calls-until`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async snoozeSalesCallsUntil(requestParameters: SnoozeSalesCallsUntilRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.snoozeSalesCallsUntilRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateInvitationExpirationDateRaw(requestParameters: UpdateInvitationExpirationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationToken'] == null) {
            throw new runtime.RequiredError(
                'invitationToken',
                'Required parameter "invitationToken" was null or undefined when calling updateInvitationExpirationDate().'
            );
        }

        if (requestParameters['expirationDate'] == null) {
            throw new runtime.RequiredError(
                'expirationDate',
                'Required parameter "expirationDate" was null or undefined when calling updateInvitationExpirationDate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['invitationToken'] != null) {
            queryParameters['invitationToken'] = requestParameters['invitationToken'];
        }

        if (requestParameters['expirationDate'] != null) {
            queryParameters['expirationDate'] = requestParameters['expirationDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/update-invitation-expiration-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateInvitationExpirationDate(requestParameters: UpdateInvitationExpirationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInvitationExpirationDateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updatePrimaryContactForCompanyRaw(requestParameters: UpdatePrimaryContactForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling updatePrimaryContactForCompany().'
            );
        }

        if (requestParameters['salesContactId'] == null) {
            throw new runtime.RequiredError(
                'salesContactId',
                'Required parameter "salesContactId" was null or undefined when calling updatePrimaryContactForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['salesContactId'] != null) {
            queryParameters['salesContactId'] = requestParameters['salesContactId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/update-primary-contact-for-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePrimaryContactForCompany(requestParameters: UpdatePrimaryContactForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePrimaryContactForCompanyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async viewCompanyDataRaw(requestParameters: ViewCompanyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyData>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling viewCompanyData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/view-company-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDataFromJSON(jsonValue));
    }

    /**
     */
    async viewCompanyData(requestParameters: ViewCompanyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyData> {
        const response = await this.viewCompanyDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async viewIndividualShipmentsRaw(requestParameters: ViewIndividualShipmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IndivualShipmentLine>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling viewIndividualShipments().'
            );
        }

        if (requestParameters['shipmentHash'] == null) {
            throw new runtime.RequiredError(
                'shipmentHash',
                'Required parameter "shipmentHash" was null or undefined when calling viewIndividualShipments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['shipmentHash'] != null) {
            queryParameters['shipmentHash'] = requestParameters['shipmentHash'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/view-individual-shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IndivualShipmentLineFromJSON));
    }

    /**
     */
    async viewIndividualShipments(requestParameters: ViewIndividualShipmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IndivualShipmentLine>> {
        const response = await this.viewIndividualShipmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async voidCouponRaw(requestParameters: VoidCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['couponId'] == null) {
            throw new runtime.RequiredError(
                'couponId',
                'Required parameter "couponId" was null or undefined when calling voidCoupon().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['couponId'] != null) {
            queryParameters['couponId'] = requestParameters['couponId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/companies/void-coupon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async voidCoupon(requestParameters: VoidCouponRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voidCouponRaw(requestParameters, initOverrides);
    }

}
