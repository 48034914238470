import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { useShipmentsApi } from "../Apis/Apis";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { ShipmentsTable } from "../Components/ShipmentsTable";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import { CompanyShipmentQuote } from "../generated-openapi-client";

export function ViewCancelledShipmentsScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [shipments, setShipments] = useState<
    CompanyShipmentQuote[] | undefined
  >(undefined);
  const [error, setError] = useState();

  useOnce(async function () {
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.getCancelledShipments();
      setShipments(response);
    } catch (e: any) {
      setError(e);
    }
  });

  if (error !== undefined) {
    return <>❌ An error occured : {JSON.stringify(error, null, "\t")}</>;
  } else if (shipments === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title="❌ Cancelled Shipments"
          subtitle="This is a list of all the shipments that have been marked as cancelled"
          tags={[]}
          extra={[]}
          stats={<></>}
        >
          <PageTitle>❌ Cancelled Shipments</PageTitle>
          <BrowserView>
            <Spacer height={32} />
          </BrowserView>

          <ShipmentsTable shipments={shipments} />
        </Page>
      </>
    );
  }
}
