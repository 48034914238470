import { Button } from "antd";
import { UrlMenuItem } from "../../Components/UrlMenuItem";

interface ViewCarrierInvoiceInXeroMenuItemProps {
  xeroInvoiceId: string | undefined;
}

export function ViewCarrierInvoiceInXeroMenuItem(
  props: ViewCarrierInvoiceInXeroMenuItemProps
) {
  const disabled = props.xeroInvoiceId === undefined;
  const url = `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${props.xeroInvoiceId}`;

  return <UrlMenuItem title="View in Xero" url={url} disabled={disabled} />;
}

export function ViewCarrierInvoiceInXeroButton(
  props: ViewCarrierInvoiceInXeroMenuItemProps
) {
  const disabled = props.xeroInvoiceId === undefined;
  const url = `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${props.xeroInvoiceId}`;

  return (
    <Button href={url} disabled={disabled} target="_blank">
      View in Xero
    </Button>
  );
}

interface ViewCarrierCreditNoteInXeroButtonProps {
  xeroCreditNoteId: string | undefined;
}

export function ViewCarrierCreditNoteInXeroButton(
  props: ViewCarrierCreditNoteInXeroButtonProps
) {
  const disabled = props.xeroCreditNoteId === undefined;
  const url = `https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=${props.xeroCreditNoteId}`;

  return (
    <Button href={url} disabled={disabled} target="_blank">
      View in Xero
    </Button>
  );
}
