/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShipmentReportInvoiceWarningState = {
    BookingSuspendedDueToOverdueInvoices: 'booking_suspended_due_to_overdue_invoices',
    BookingSuspendedDueToOverCreditLimit: 'booking_suspended_due_to_over_credit_limit',
    BookingWarningInvoicesCloseToOverdue: 'booking_warning_invoices_close_to_overdue',
    BookingWarningCloseToCreditLimit: 'booking_warning_close_to_credit_limit',
    NoWarning: 'no_warning',
    BookingSuspendedNeedsAccountVerification: 'booking_suspended_needs_account_verification'
} as const;
export type ShipmentReportInvoiceWarningState = typeof ShipmentReportInvoiceWarningState[keyof typeof ShipmentReportInvoiceWarningState];


export function instanceOfShipmentReportInvoiceWarningState(value: any): boolean {
    return Object.values(ShipmentReportInvoiceWarningState).includes(value);
}

export function ShipmentReportInvoiceWarningStateFromJSON(json: any): ShipmentReportInvoiceWarningState {
    return ShipmentReportInvoiceWarningStateFromJSONTyped(json, false);
}

export function ShipmentReportInvoiceWarningStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReportInvoiceWarningState {
    return json as ShipmentReportInvoiceWarningState;
}

export function ShipmentReportInvoiceWarningStateToJSON(value?: ShipmentReportInvoiceWarningState | null): any {
    return value as any;
}

