/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface XeroInvoice
 */
export interface XeroInvoice {
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    invoiceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    amountDue: number;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    amountCredited: number;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    amountPaid: number;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    currencyCode: string;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    currencyRate: number;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    status: string;
}

/**
 * Check if a given object implements the XeroInvoice interface.
 */
export function instanceOfXeroInvoice(value: object): boolean {
    if (!('invoiceId' in value)) return false;
    if (!('invoiceNumber' in value)) return false;
    if (!('reference' in value)) return false;
    if (!('amountDue' in value)) return false;
    if (!('amountCredited' in value)) return false;
    if (!('amountPaid' in value)) return false;
    if (!('currencyCode' in value)) return false;
    if (!('currencyRate' in value)) return false;
    if (!('type' in value)) return false;
    if (!('contactName' in value)) return false;
    if (!('date' in value)) return false;
    if (!('dueDate' in value)) return false;
    if (!('url' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function XeroInvoiceFromJSON(json: any): XeroInvoice {
    return XeroInvoiceFromJSONTyped(json, false);
}

export function XeroInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): XeroInvoice {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'invoiceNumber': json['invoiceNumber'],
        'reference': json['reference'],
        'amountDue': json['amountDue'],
        'amountCredited': json['amountCredited'],
        'amountPaid': json['amountPaid'],
        'currencyCode': json['currencyCode'],
        'currencyRate': json['currencyRate'],
        'type': json['type'],
        'contactName': json['contactName'],
        'date': json['date'],
        'dueDate': json['dueDate'],
        'url': json['url'],
        'status': json['status'],
    };
}

export function XeroInvoiceToJSON(value?: XeroInvoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceId': value['invoiceId'],
        'invoiceNumber': value['invoiceNumber'],
        'reference': value['reference'],
        'amountDue': value['amountDue'],
        'amountCredited': value['amountCredited'],
        'amountPaid': value['amountPaid'],
        'currencyCode': value['currencyCode'],
        'currencyRate': value['currencyRate'],
        'type': value['type'],
        'contactName': value['contactName'],
        'date': value['date'],
        'dueDate': value['dueDate'],
        'url': value['url'],
        'status': value['status'],
    };
}

