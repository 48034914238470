import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

export interface ListComparatorContextI<T> {
  leftList: T[];
  rightList: T[];
  changes: number;
  deletions: number;
  additions: number;
  setChanges: Dispatch<SetStateAction<number>>;
  setDeletions: Dispatch<SetStateAction<number>>;
  setAdditions: Dispatch<SetStateAction<number>>;
}

export const ListComparatorContext = createContext<ListComparatorContextI<any>>(
  {
    leftList: [],
    rightList: [],
    changes: 0,
    deletions: 0,
    additions: 0,
    setChanges: () => {},
    setDeletions: () => {},
    setAdditions: () => {},
  }
);

interface ListComparatorContextProviderProps<T> {
  left: T[];
  right: T[];
  changes?: number;
  deletions?: number;
  additions?: number;
  children: ReactNode;
}

export function ListComparatorContextProvider<T>(
  context: ListComparatorContextProviderProps<T>
) {
  const [changes, setChanges] = useState<number>(context.changes ?? 0);
  const [deletions, setDeletions] = useState<number>(context.deletions ?? 0);
  const [additions, setAdditions] = useState<number>(context.additions ?? 0);

  return (
    <ListComparatorContext.Provider
      value={{
        leftList: context.left,
        rightList: context.right,
        changes: changes,
        setChanges: setChanges,
        deletions: deletions,
        setDeletions: setDeletions,
        additions: additions,
        setAdditions: setAdditions,
      }}
    >
      {context.children}
    </ListComparatorContext.Provider>
  );
}
