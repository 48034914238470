/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCompanyResponse
 */
export interface CreateCompanyResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyResponse
     */
    companyId: string;
}

/**
 * Check if a given object implements the CreateCompanyResponse interface.
 */
export function instanceOfCreateCompanyResponse(value: object): boolean {
    if (!('companyId' in value)) return false;
    return true;
}

export function CreateCompanyResponseFromJSON(json: any): CreateCompanyResponse {
    return CreateCompanyResponseFromJSONTyped(json, false);
}

export function CreateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
    };
}

export function CreateCompanyResponseToJSON(value?: CreateCompanyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
    };
}

