import { message } from "antd";
import { useShipmentsApi } from "../Apis/Apis";
import { ConfirmMenuItem } from "../Components/ConfirmButton";

interface PingCarrierMenuItemProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function PingCarrierMenuItem(props: PingCarrierMenuItemProps) {
  const createShipmentsApi = useShipmentsApi();
  const shipmentId = props.shipmentId;

  async function onClick() {
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.invoiceAuditPingCarrier({
        shipmentId,
      });
      await props.onRefresh();
      message.success("Pinged! Email Sent!");
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  return (
    <ConfirmMenuItem
      onConfirm={onClick}
      question={`Are you sure you want to ping the invoice audit email chain with the carrier?`}
      okText={"Yes, send a ping email"}
      tooltip={
        "Allows you to quickly ping a carrier to get an update about an invoice audit"
      }
    >
      Ping Carrier
    </ConfirmMenuItem>
  );
}
