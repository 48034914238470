import { Button, Modal, Table } from "antd";
import { useState } from "react";
import { CreatedAt } from "../../Components/CreatedAt";
import { safeColumns } from "../../Helpers/safeColumns";
import {
  DocumentType,
  ShipmentDocument,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { DocumentTypeTag } from "../ViewShipmentScreenComponents/DocumentTypeTag";
import { ViewDocumentButton } from "../ViewShipmentScreenComponents/ViewDocumentButton";

interface ManageAttachmentsButtonProps {
  shipmentData: ViewShipmentDataForApollo;
  attachments: ShipmentDocument[];
  onUpdateAttachments: (documents: ShipmentDocument[]) => void;
}

function DocumentsTable(props: ManageAttachmentsButtonProps) {
  return (
    <Table
      pagination={false}
      rowKey={function (row) {
        return row.shipmentDocumentId!!;
      }}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys: props.attachments.map((a) => a.shipmentDocumentId!!),
        onChange: (
          _selectedRowKeys: React.Key[],
          _selectedRows: ShipmentDocument[]
        ) => {
          console.log(
            `selectedRowKeys: ${_selectedRowKeys}`,
            "selectedRows: ",
            _selectedRows
          );
          props.onUpdateAttachments(_selectedRows);
          //   setSelectedS3Keys(
          //     _selectedRows.map(function (row) {
          //       return row.s3Key!!;
          //     })
          //   );
        },
      }}
      columns={safeColumns<ShipmentDocument>([
        {
          title: "Document Type",
          dataIndex: "documentType",
          key: "documentType",
          render: (documentType: DocumentType) => (
            <DocumentTypeTag documentType={documentType} />
          ),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (createdAt) => <CreatedAt timestamp={createdAt} />,
        },
        {
          title: "Actions",
          dataIndex: "shipmentDocumentId",
          key: "shipmentDocumentId",
          render: function (documentId: string, document: ShipmentDocument) {
            return (
              <ViewDocumentButton
                document={document}
                shipmentData={props.shipmentData}
              />
            );
          },
        },
      ])}
      dataSource={props.shipmentData.documentsTab.documents.map(
        (o) => o.shipmentDocument
      )}
    />
  );
}

export function ManageAttachmentsButton(props: ManageAttachmentsButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={"Manage Attachments"}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Done"
        width={900}
        cancelText={undefined}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <DocumentsTable
          shipmentData={props.shipmentData}
          attachments={props.attachments}
          onUpdateAttachments={props.onUpdateAttachments}
        />
      </Modal>
      <Button onClick={showModal} type="link">
        Manage Attachments ({props.attachments.length})
      </Button>
    </>
  );
}
